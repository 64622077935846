.plcc-cc span.img {
    background: url("../../images/payment-sprite-image.png") no-repeat;
    width: 35px;
    height: 24px;
    display: inline-block;

    &.amex {
        background-position: -107px -3px;
        width: 37px;
    }
    &.applepay {
        background-position: -249px -3px;
    }
    &.discover {
        background-position: -157px -3px;
    }
    &.mastercard {
        background-position: -59px -3px;
    }
    &.paypal {
        background-position: -203px -3px;
    }
    &.visa {
        background-position: -9px -3px;
    }

    &.bh_plcc, &.BH_PLCC {
        background-position: -246px -34px;
        width: 37px;
    }
    &.ca_plcc, &.CA_PLCC {
        background-position: -157px -34px;
    }
    &.fb_plcc, &.FB_PLCC {
        background-position: -9px -34px;
    }
    &.jl_plcc, &.JL_PLCC {
        background-position: -203px -34px;
    }
    &.ks_plcc, &.KS_PLCC {
        background-position: -290px -34px;
    }
    &.rm_plcc, &.RM_PLCC {
        background-position: -109px -34px;
    }
    &.ww_plcc, &.WW_PLCC {
        background-position: -60px -34px;
    }
}