.mainBanner {
	position: relative;
	text-align: center;
	.mainBanner__image {
		width: 100%;
		margin-bottom: 29px;
	}

	h1 {
		font-size: 50px;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.content-asset p {
		font-size: 13px;
		color: $dim-gray;
		margin: 0;
	}
	@media screen and (min-width: 768px) {
		h1 {
			font-size: 60px;
		}
	}
}
.content-asset {
	letter-spacing: 0.025em;
}
.dialog-tool-tip-content {
	.content-asset {
		line-height: 17px;
		padding: 0 5px;
	}
}
