.grid-tile {
	&:hover {
		.quickview {
			display: block;
			&.hoverQuickView {
				display: none;
			}
		}
	}
}
.product-tile {
	.product-name-image-container {
		position: relative;
	}

	&:not(.product-height) {
		height: auto !important;
	}
	color: $nobel;
	position: relative;
	text-align: center;
	width: 245px;
	margin: 0 auto;
	border: 1px solid transparent;
	padding-bottom: 17px;
	letter-spacing: 0.05em;
	letter-spacing: 1px;
	box-sizing: border-box;
	img {
		max-width: 100%;
	}
	.name-link {
		display: inline-block;
		width: 100%;
		overflow: visible;
		outline-offset: -3px;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			width: 97%;
		}
	}
	.product-image {
		height: auto;
		position: relative;
		img {
			width: 240px;
			height: 346px;
			text-indent: -9999px;
		}
	}
	.product-name {
		font-size: 1.2em;
		margin-top: 20px;
		height: auto;
	}
	.product-brand {
		@include WorkSansRegular($WorkSans);
		text-transform: uppercase;
		font-size: $base-font - 2px;
		color: $brand-name-color;
		letter-spacing: 0.2px;
		margin-top: 0.5em;
	}
	.jessicalondon {
		color: $brand-name-color;
	}
	.product-pricing {
		margin: 0.5em 0;
		.product-standard-price {
			text-decoration: line-through;
			color: $night-rider;
		}
		.product-sales-price {
			margin: 0 4px;
			color: $light-red;

			&.product-actual-price {
				color: $night-rider;
			}
		}
	}
	.product-swatches-all,
	.product-swatches-all-showless {
		cursor: pointer;
		font-size: 0.9em;
	}
	.product-swatches {
		display: none;
		ul {
			width: 100%;
			margin: 0 auto;
			padding: 0;
			list-style: none outside none;
			overflow: hidden;
			li {
				display: inline-table;
				float: none;
				&.hideswatch {
					display: none;
				}
			}
			&.swatch-toggle {
				background: none repeat scroll 0 0 $white;
				display: none;
				position: absolute;
			}
		}

		.pt_product-search-result &,
		.pt_product-compare & {
			display: block;
		}

		&:hover ul.swatch-toggle {
			display: block;
		}
		li {
			float: left;
		}
		.swatch {
			display: block;
			float: left;
			width: 29px;
			min-width: 29px;
			padding: 1px;
			margin-right: 5px;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;

			&.selected,
			&:hover,
			&:focus {
				border: 1px solid $black;
			}

			img {
				height: 100%;
				width: 100%;
				padding: 0;
				margin-right: 8px;
				border: 1px solid $mercuryy;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				text-indent: -9999px;
			}
		}
		.hideswatch {
			position: relative;
		}
		&.show-color {
			.product-swatches-all {
				visibility: hidden;
			}
			.hideswatch {
				display: inline-block;
			}
			.swatches-six {
				.product-swatches-all {
					visibility: hidden !important;
				}
				.product-swatches-all-showless {
					visibility: visible !important;
				}
			}
		}
		.product-swatches-all,
		.product-swatches-all-showless {
			font-family: $Helvetica;
			font-size: $base-font - 1px;
			letter-spacing: 1px;
			text-transform: uppercase;
			display: inline;
		}
		.product-swatches-all-showless {
			visibility: hidden;
		}
		.product-swatches-all {
			position: relative;
			top: 35px;
		}
		.swatches-five {
			display: none;
		}
		.swatches-four {
			display: none;
		}
		li.hideswatch:nth-child(-n + 6) {
			display: inline-block;
		}
		&.desktop-top-buffer {
			margin-top: -7px;
		}
	}
	.swatch-list {
		font-size: 0;
	}
	.product-promo {
		font-size: 0.8em;
		margin: 0.5em 0;
		text-transform: uppercase;

		.clearance {
			&.promotional-message {
				padding-bottom: 4px;
				color: $salered;
				line-height: normal;
				letter-spacing: 0.5px;
				@media screen and (max-width: 767px) {
					text-align: center;
					font-size: $base-font - 1px;
					letter-spacing: -0.1px;
				}
			}
		}
		.promotional-message {
			font-family: $HelveticaBold;
			color: $light-red;
			font-size: $base-font;
		}
	}
	.clearence-msg {
		font-family: $HelveticaBold;
		color: $light-red;
		font-size: $base-font;
		text-transform: capitalize;
	}
	.product-feature-messages {
		font-family: $Helvetica;
		color: $night-rider;
		font-size: $base-font - 2px;
		text-transform: uppercase;
		word-break: break-word;
	}
	.product-special-messages {
		color: $special-product-messages-color;
		font-size: $base-font;
		text-transform: capitalize;
		font-family: $HelveticaBold;
	}
	.product-compare {
		float: left;
		line-height: 11px;
		label {
			font-weight: 400;
			line-height: 1;
			padding: 0 0 0 0.5em;
			width: auto;
		}
		input {
			float: left;
			margin: 0;
		}
	}
	.product-review {
		margin-bottom: 1em;

		.rating-title {
			display: inline-block;
			margin-right: 1em;
		}
		.rating {
			display: inline-block;
		}
	}
	.wide-tiles & {
		height: auto !important; // overwrite synced heights set on element
		.product-image {
			float: left;
			padding-right: 2em;
		}
		.product-name,
		.product-pricing,
		.product-promo,
		.product-swatches,
		.product-review {
			float: left;
			text-align: left;
			width: 65%;
		}
		.product-name {
			font-size: 1.5em;
			height: auto;
			margin-top: 0;
		}
		.product-pricing {
			font-size: 1.3em;
		}
		.product-swatches {
			margin-bottom: 1em;
		}
	}

	.quickview {
		display: none;
		background-color: $white;
		color: $dim-gray;
		font-size: $base-font + 2px;
		text-transform: uppercase;
		box-sizing: border-box;
		border: 1px solid $dim-gray;
		width: 90%;
		width: calc(100% - 20px);
		bottom: 10px;
		left: 50%;
		padding: 10px;
		position: absolute;
		transform: translate(-50%);
		cursor: pointer;

		&.active {
			display: block;
		}

		i {
			margin-left: 0.5em;
			display: none;
		}
	}

	.b_product_badge {
		position: absolute;
		width: 100%;
		text-align: left;
		top: 0;

		span {
			display: inline-block;
		}

		img {
			width: auto;
			height: 100%;
		}
	}

	.thumb-link {
		display: block;
	}

	&.marketplace-product .product-image {
		.thumb-link {
			position: relative;
			padding-bottom: 144%;
	
			img {
				position: absolute;
				bottom: 0;
				top: 0;
				left: 0;
				right: 0;
				margin: auto;
				max-height: 100%;
				max-width: 100%;
				height: auto;
				width: auto;
			}
		}

		&.product-brylane .thumb-link {
			padding-bottom: 100%;
		}
	}
}

.external-search-values {
	display: none;
}

.capture-product-id {
	left: -999em;
	position: absolute;
}

.p-image-rollover {
	display: none;
	text-indent: -9999px;
}

.search-result-items {
	.grid-tile {
		&.two-space-tile {
			width: 50%;
			padding: 0;
			position: relative;

			&:hover {
				box-shadow: none;
			}
		}

		&.one-space-tile {
			background: $light-skygrey;
			text-align: center;

			.onespace-tile-content {
				margin-top: 41%;
			}

			span {
				font-size: $base-font + 1px;
				font-family: $HelveticaBold;
			}

			h1 {
				text-transform: uppercase;
			}

			p {
				font-size: $base-font + 1px;
				line-height: 20px;
				width: 90%;
				margin: auto;
			}

			button {
				font-size: $base-font + 3px;
				text-transform: uppercase;
				background: $wine-berry;
				color: $white;
				letter-spacing: 1.5px;
				width: 75%;
				padding: 11px 0px;
				border: 0;
				margin-top: 31px;
			}

			&:hover {
				box-shadow: none;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.product-tile {
		.product-swatches {
			ul {
				width: 100%;
				max-width: 190px;
			}

			li.hideswatch:nth-child(-n + 6) {
				display: none;
			}

			.swatches-six {
				display: none;
			}

			.swatches-five {
				display: inline-table;
			}

			&.show-color {
				.swatches-five {
					.product-swatches-all {
						visibility: hidden !important;
					}
					.product-swatches-all-showless {
						visibility: visible !important;
					}
				}
			}

			.swatches-four {
				display: none;
			}

			li.hideswatch:nth-child(-n + 5) {
				display: inline-block;
			}

			&.show-color {
				li.hideswatch:nth-child(-n + 6) {
					display: inline-block;
				}
			}

			&.tablet-top-buffer {
				margin-top: -7px !important;
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	.product-tile {
		.product-swatches {
			&.show-color {
				.product-swatches-all-showless {
					display: inline-table;
				}
			}
			.swatches-six {
				display: inline-table;
			}
			.swatches-five {
				display: none;
			}
			.swatches-four {
				display: none;
			}
			li.hideswatch:nth-child(-n + 5) {
				display: inline-table;
			}
			li.hideswatch:nth-child(6) {
				display: none;
			}
			&.show-color {
				li.hideswatch:nth-child(6) {
					display: inline-table;
				}
			}
		}
	}
	ul {
		&.search-result-items {
			.grid-tile {
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.product-tile {
		max-width: 96%;

		.product-image {
			img {
				width: 100%;
				max-width: 240px;
				text-indent: -9999px;
				height: auto;
			}
		}
		.product-name {
			font-size: $base-font;
		}
		.product-swatches {
			ul {
				width: 100%;
				max-width: 150px;
				margin: 0 auto;
			}

			.swatch {
				width: 25px;
				max-width: 25px;
			}
			.swatches-six {
				display: none;
			}
			.swatches-five {
				display: none;
			}
			.swatches-four {
				display: inline-table;
			}
			&.show-color {
				.swatches-four {
					.product-swatches-all {
						visibility: hidden !important;
					}
					.product-swatches-all-showless {
						visibility: visible !important;
					}
				}
			}
			li.hideswatch:nth-child(-n + 6) {
				display: none;
			}

			li.hideswatch:nth-child(-n + 5) {
				display: none;
			}
			li.hideswatch:nth-child(5) {
				display: none;
			}
			&.show-color {
				li.hideswatch:nth-child(5) {
					display: inline-block;
				}
			}

			&.mobile-top-buffer {
				margin-top: -7px !important;
			}
		}
	}

	//content tile design
	.search-result-items {
		.grid-tile {
			&.two-space-tile {
				width: 100%;
				img {
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.search-result-items {
		.grid-tile {
			&.one-space-tile {
				span {
					font-size: $base-font - 1px;
					letter-spacing: 1px;
				}

				h1 {
					font-size: 30px;
					margin: 10px 0;
					line-height: 26px;
				}

				p {
					line-height: 15px;
					width: 90%;
				}

				button {
					letter-spacing: 1px;
					width: 94%;
					padding: 10px;
					margin-top: 20px;
				}

				&:hover {
					box-shadow: none;
				}
			}
		}
	}
}

.product-list-page {
	.product-tile {
		.product-promo {
			.clearance {
				&.promotional-message {
					padding-bottom: 4px;
					color: #af3434;
					line-height: normal;
					letter-spacing: 0.5px;
					font-size: 13px;
					text-align: center;
					font-weight: 600;
					@media screen and (max-width: 767px) {
						font-size: 11px;
					}
				}
			}
		}
	}
}

/* WCAG keyboard Focus */

.recommendations-h,
.bh-recommendations,
.last-visited-section {
	.product-tile .name-link {
		width: auto;
		margin-top: 3px;
	}
}

a.product-image.main-image {
	display: block;
	overflow: visible;
	margin-top: 3px;
}

.product-detail .wishlist a {
	display: inline-block;
	overflow: visible;
}

//new class added for Mobile swatch load issue
.plpnoswatch-img{
	background-repeat:no-repeat!important;
	background-position:center!important;
}

  
