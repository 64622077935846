/* Body */
body {
	background: none repeat scroll 0 0 $white;
	color: $black;
	font-family: $sans-serif;
	font-size: $base-font;
	&.overflow-hidden {
		overflow: hidden;
	}
}
#wrapper {
	&.hide-content {
		display: none !important;
	}
}

/* Anchor */
a:not(.link-ds) {
	color: $black;
	overflow: hidden;
	text-decoration: none;
	:hover {
		cursor: pointer;
	}
}

a:focus, button:not(.btn-ds):focus, input:focus {
	outline: 2px solid var(--color-btn-focus);
}

button:not(.btn-ds):not([class*="tt-o-button"]),
input,
textarea,
.global-focus a.button {
	@include focus-outline();
}

button.chat-client-bubble {
	color: $white;
}

// Focus for Quick view modal dialog
.ui-dialog-content {
	.prod-detail-page {
		.focus-outline {
			outline-offset: -3px;
			//WCAG focus outline issues on quick modal dialog custom select
			&.custom-select {
				outline-offset: 0;
			}
		}
	}
}

/* Form */
.form-row {
	@extend %clearfix;
	margin: 0;
	padding: 0;
}

fieldset {
	border: 0 none;
	margin: 0;
	padding: 0;
}

input[type="checkbox"] {
	display: inline-block;
	width: 18px;
	height: 18px;
	border: 2px solid $white;
	vertical-align: top;
	float: left;
	margin: 0 13px 0 0;
	outline: 2px solid $black;
	appearance: none;

	&:checked {
		background: url("../../../../images/check.svg") no-repeat center;
	}
}

input[type="text"]::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}

input[type="text"]::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

textarea {
	resize: none;
}

/* Table */
table {
	width: 100%;
}

th {
	text-align: left;
}

td {
	padding: 1em;
	vertical-align: top;
}

/* CTA */
button:not(.btn-ds):not([class*="tt-o-button"]),
.button,
.buttonstyle,
input[type="button"] {
	background: transparent;
	border-style: solid;
	border-width: 2px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	transition: all 0.3s ease;
	text-transform: uppercase;
	outline: 0;
	&.oAuthIcon {
		border-top-left-radius: 0;
		border-bottom-right-radius: 0;
		color: $black !important;
		background: $white;
		padding: 12.5px;
		text-transform: unset;
		border-radius: 0.1875rem;
		border-color: $dim-gray !important;
		svg {
			display: inline-block;
    		width: 1.5rem;
		}
	}
	&.large {
		font-size: 1.35em;
		text-transform: uppercase;
	}
	&.raised {
		border-width: 0;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		&:hover {
			background-color: darken($citrus, 10%);
			color: $white;
		}
	}
	&.simple {
		background-color: $white;
		color: darken($citrus, 10%);
	}
	&.btn-outline-primary,
	&.secondary {
		background-color: $white;
		border: 1px solid $dim-gray;
		color: $dim-gray;
		&:hover {
			@extend .secondary;
		}
	}
	&[disabled],
	&.disabled,
	&.atb-disabled {
		background-color: $wcag-grey !important;
		border-color: $wcag-grey !important;
		color: $dim-gray;
		pointer-events: none;
	}
	&.solid-wine-berry {
		background: $wine-berry;
		border-color: $wine-berry;
		color: $white;
		&:hover {
			@extend .solid-wine-berry;
		}
	}
	a {
		color: $white;
		&:hover {
			color: $citrus;
		}
	}
}
.visible-hidden {
	visibility: hidden !important;
}
.js-show {
	display: block;
}
.js-hide {
	display: none;
}
.button-text {
	background-color: transparent;
	border-width: 0;
	padding: 0;
}

.button-full-width {
	padding-left: 0;
	padding-right: 0;
	text-align: center;
	width: 100%;
}

button.visually-hidden {
	display: none;
}

/* Unordered List */
ul li {
	list-style-type: none;
}

/* Image */
img {
	max-width: 100%;
}

/* Back To Top */
.disable-scroll {
	.pt_product-search-result {
		a {
			&.back-to-top {
				@media only screen and (max-width: 767px) {
					opacity: 0;
				}
			}
		}
	}
}

.pt_product-search-result,
.pt_product-search-noresult {
	a {
		&.back-to-top {
			display: none;
			width: 50px;
			height: 48px;
			text-indent: -9999px;
			position: fixed;
			z-index: 10;
			right: 15px;
			bottom: 25%;
			background: url("../../../images/ww/back-to-top.svg") no-repeat center 43% transparent;
			background-size: 100%;
		}
	}
}

@media only screen and (max-width: 767px) {
	button:not(.btn-ds):not([class*="tt-o-button"]),
	.button,
	.buttonstyle,
	input[type="button"] {
		&.solid-wine-berry,
		&.transperent-site-specific {
			padding-left: 0;
			padding-right: 0;
			width: 100%;
		}
	}
	.width-full {
		&.md-width-auto {
			width: auto;
		}
	}
}
//clas to add margin to offset anchor tag, used on CQO edit
:target:before {
	content: "";
	display: block;
}

//class add to view more and less toggle
.morecontent span {
	display: none;
}
.morelink {
	display: block;
}
.orderLookupNarvar,
.internationaLookupButton {
	margin-top: 20px !important;
	display: flex;
	.button {
		margin: 0 2px;
		width: auto;
		flex: 1;
		@media only screen and (max-width: 420px) {
			padding-left: 0;
			padding-right: 0;
			text-align: center;
		}
	}
}

.orderLookupDefaultForm {
	.orderLookupDefaultFormButtons {
		display: flex;
		justify-content: flex-end;
		align-items:center;
		
		.button {
			display: inline;
			margin: 0 2px;
			flex:2;
		}
		.buttonCancel {
			background-color: transparent;
			color: black;
			border: none;
			padding: 12.5px 17px;
			outline-offset: -2px;
			margin-right: 5px;
		}
		button {
			flex: 3;
			
		}
	}
}

@media only screen and (max-width: 420px) {
    .check-order.inner-block .login-order-track .form-row-button.orderLookupDefaultFormButtons {
        .buttonCancel,
        button {
            padding-left: 0;
            padding-right: 0;
            text-align: center;
			flex: 1;
        }
    }
}

// iOS safari browser only - for form elements zoom out issue
@include mobile {
	#wrapper input[type="text"],
	#wrapper input[type="password"],
	#wrapper input[type="tel"],
	#wrapper select,
	#wrapper.pt_checkout .form-row div.field-wrapper input[type="text"],
	#wrapper.pt_checkout .form-row div.field-wrapper input[type="password"],
	#wrapper.pt_checkout .form-row div.field-wrapper input[type="tel"],
	#wrapper.pt_checkout .form-row div.field-wrapper select,
	#wrapper .footer-container .footer-email-signup .email-signup .form-row input[type="text"],
	.email-popup input[type="email"], .email-popup input[type="text"],
	#wrapper.pt_cart_template_b .coupon-code {
		@include iOS-safari {
			font-size: 16px;
		}
	}
}

.hide-recommendation-heading {
	display: none !important;
}

.text-black {
    color: $black !important;
}

.tt-c-multi-item-search__select.tt-o-button--toggle-icon-chip {
	padding: 0;
}