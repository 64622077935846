footer {
	background-color: transparent;
	clear: both;
	letter-spacing: 1px;
	width: 100%;
	overflow: hidden;
}
.footer-container {
	border-top: 1px solid $alto;
	margin: auto;
	width: 1260px;

	&.success-message {
		.footer-email-signup-message {
			display: none;
		}

		.footer-email-signup {
			width: auto;
		}

		.email-signup {
			&.make-label-absolute {
				margin: 40px 0;
			}
		}

		.email-signup-footer-success {
			text-align: center;

			.email-signup-footer-success-heading {
				font-size: $base-font + 12px;
				margin: 0 0 20px 0;
				width: 100%;
				float: left;
				text-transform: uppercase;
			}

			.promo-code {
				font-size: $base-font + 1px;
				font-family: $Helvetica;
				margin: 0 0 15px 0;
				width: 100%;
				float: left;
			}

			p {
				font-size: $base-font + 1px;
				font-family: $Helvetica;
				color: $dim-gray;
				width: 100%;
				float: left;
			}
		}
	}
	.footer-email-signup-message {
		float: left;
		width: 100%;
		.footer-email-heading {
			float: left;
			width: 100%;
			text-align: center;
			font-size: $base-font + 4px;
			font-weight: normal;
			letter-spacing: 0;
			margin: 25px 0 8px 0;
			text-transform: uppercase;
		}
		p {
			float: left;
			width: 100%;
			text-align: center;
			color: $dim-gray;
			font-size: $base-font + 1px;
			letter-spacing: 0;
		}
	}
	.footer-email-signup {
		display: table;
		margin: auto;
		width: 31%;
		.email-signup {
			float: left;
			display: inline-table;
			margin: 20px 0;
			width: 100%;
			.form-row {
				float: left;
				margin-bottom: 0;
				width: 63%;
				input[type="text"] {
					width: 100%;
					height: 50px;
					padding: 15px;
					border: 1px solid $dusty-gray;
					border-right: none;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: none;
					border-radius: 0px;
				}
				.error {
					clear: both;
					float: left;
					padding-top: 5px;

					@media screen and (max-width: 440px) {
						font-size: $base-font - 2px;
						letter-spacing: 0.1px;
					}
				}
			}
			.form-row-button {
				clear: none;
				margin-bottom: 0;
				width: 30%;
				button {
					border: medium none;
					border-radius: 0;
					font-size: $base-font + 3px;
					height: 50px;
					width: 138px;
					color: $white;
					padding: 0;
					background-color: $wine-berry;
					text-transform: uppercase;
					&:hover {
						color: $white;
					}
				}
			}
			.email-signup-footer-success,
			.email-signup-footer-error,
			.email-signup-footer-success-international {
				display: none;
			}
		}
	}
	ul {
		margin: 0;
		padding: 0;
		li {
			list-style: outside none none;
			margin: 0;
			padding: 0;
			line-height: normal;
			a {
				margin: 0;
				padding: 0;
			}
			#chat-list-item-div {
				a.focus-outline {
					display: block;
				}
			}
		}
	}
	p {
		margin: 0;
		padding: 0;
		line-height: normal;
	}
	.content-asset ul li {
		list-style: outside none none;
	}
	.footer-social-icon {
		position: relative;
		margin-bottom: 35px;
		float: left;
		width: 100%;
		.fo-border {
			border-bottom: 1px solid $alto;
			bottom: 21px;
			float: left;
			position: absolute;
			width: 100%;
			z-index: 0;
		}
		.fo-social {
			background-color: $white;
			display: table;
			margin: auto;
			position: relative;
			z-index: 0;
			ul {
				li {
					float: left;
					margin: 0 9px 0 0;
					width: auto;
					a {
						display: block;
						width: 44px;
						height: 44px;
						position: relative;
						overflow: hidden;
						font-size: $base-font + 6px;
						color: $black;
						.icon {
							display: block;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							-webkit-transform: translate(-50%, -50%);
							-moz-transform: translate(-50%, -50%);
							-o-transform: translate(-50%, -50%);
							-ms-transform: translate(-50%, -50%);
						}
						span {
							display: inline-block;
							text-indent: 100%;
							overflow: hidden;
						}
					}
					&.instagram {
						a {
							background-position: -17px -16px;
						}
					}
					&.pintrest {
						a {
							background-position: -53px -15px;
						}
					}
					&.facebook {
						a {
							background-position: -83px -16px;
						}
					}
					&.twitter {
						a {
							background-position: -114px -16px;
						}
					}
					&.youtube {
						a {
							background-position: -157px -14px;
						}
					}
				}
			}
		}
	}
	.footer-links {
		float: left;
		width: 100%;
		border-bottom: 1px solid $alto;
		.footer_list_wrapper {
			margin: auto;
			width: 100%;
			.footer_item {
				float: left;
				width: 25%;
				ul {
					margin: 0 0 32px;
					padding: 0;
					li {
						padding-top: 8px;
					}
				}
				.footer-heading {
					text-transform: uppercase;
					font-size: $base-font;
					font-weight: normal;
					margin: 0;
					a {
						line-height: 20px;
					}
				}
				a {
					text-transform: uppercase;
					font-size: $base-font - 1px;
					font-weight: 400;
					&:hover {
						color: $dim-gray;
					}
				}
			}
		}
	}
	.footer-item {
		box-sizing: border-box;
		display: block;
		padding: 0.5em 1.5em;
		width: 100%;
		@media screen and (min-width: 768px) {
			display: table-cell;
			padding: 1.5em;
			width: 25%;
		}
	}
	h3 {
		text-transform: uppercase;
	}
	.menu-footer {
		float: none;
		margin: 0.5em;
		padding: 0;
		@media screen and (min-width: 768px) {
			margin: 1em 0;
		}
		li {
			float: none;
			line-height: 2.5em;
			list-style: none !important;
		}
	}
	a {
		&:hover {
			color: $very-light-gray;
			text-decoration: none;
		}
	}
	.footer-brand-selector {
		float: left;
		width: 100%;
		.footer-brand-heading {
			font-size: $base-font + 2px;
			letter-spacing: 3px;
			margin: 0;
			padding-bottom: 23px;
			padding-top: 36px;
			text-align: center;
			width: 100%;
			color: $black;
			text-transform: uppercase;
		}
		.brands-list {
			display: table;
			margin: auto;
			text-align: center;
			&.hide-desktop {
				display: none;
			}
			ul {
				li {
					margin: 0 17px 0 0;
					display: inline-block;
					width: 11%;
					@media screen and (max-width: 480px) {
						width: 30%;
					}
					&:last-child {
						margin-right: 0px;
					}
					img {
						max-width: 100%;
					}
				}
			}
		}
	}

	.footer-legal-copyrights {
		float: left;
		width: 100%;
		text-align: center;
		margin-bottom: 25px;
		.static-links {
			display: table;
			margin: auto auto 15px;
			width: 100%;
			text-align: center;

			ul {
				li {
					display: inline-block;
					margin-right: 10px;
					a {
						text-decoration: underline;
						display: inline-block;
						&:hover {
							color: $dim-gray;
						}
					}
					img {
						display: inline-block !important;
					}
				}
			}
		}
		.copyrights-wrapper {
			float: left;
			width: 100%;
			margin-bottom: 0px !important;
			p {
				float: left;
				text-align: center;
				width: 100%;
				color: $dim-gray;
				font-size: $base-font !important;
				letter-spacing: 0;
				color: #525252 !important;
			}
			a {
				font-size: $base-font !important;
				color: #525252 !important;
				vertical-align: middle;
				&:hover {
					color: #000 !important;
					text-decoration: underline;
				}
			}
			.copy-arr {
				margin-bottom: 7px;
			}
		}
	}
}

.footer-container {
	&.footer-top {
		@media screen and (min-width: 1024px) {
			max-width: 1260px;
			width: calc(100% - 40px);
		}
	}
	&.footer-down {
		border-top: inherit;
		float: left;
		width: 100%;
		margin: 40px auto auto;
		background-color: $wild-sand;
		.footer-down-inner {
			width: 1260px;
			margin: 40px auto auto;
			@include tablet-up {
				a {
					display: inline-block;
				}
			}
		}
	}
}
.footer-email-signup {
	.email-signup-footer-success,
	.email-signup-footer-error,
	.email-signup-footer-success-international {
		display: none;
	}
}
.footer-plcc {
	width: 100%;
	float: left;
	.footer-plcc-banner {
		margin: 0 auto;
		display: table;
		width: 100%;
		max-width: 1310px;
		border-collapse: separate;
		border-spacing: 25px 0;
		a.learn-more {
			display: inline-flex;
		}
	}
	.footer-plcc-heading {
		text-transform: uppercase;
		font-size: $base-font + 6px;
		letter-spacing: 0;
	}
	p {
		font-size: $base-font + 1px;
		font-family: $Helvetica;
		line-height: 20px;
		letter-spacing: 0;
		color: $dim-gray;
	}
	.section {
		width: 50%;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		background: $white;
		border: 1px solid $alto;
		display: table-cell;
		padding: 10px;
		vertical-align: middle;
		.image-section {
			display: table-cell;
			max-width: 50%;
			padding: 0 10px;
			vertical-align: middle;
			img {
				vertical-align: middle;
				padding: 0 5px;
			}
		}
		.text-section {
			display: table-cell;
			max-width: 50%;
			text-align: center;
			vertical-align: middle;
			padding: 0 10px;
			a span {
				display: inline-block;
				padding: 0;
			}
		}
	}
	.left-section {
		.footer-plcc-heading {
			margin: 0 0 7px;
		}
		p {
			margin: 0 auto;
		}
	}
	.right-section {
		.footer-plcc-heading {
			margin: 0 0 6px;
		}
		p {
			margin: 0;
		}
		a {
			span {
				padding: 0 0 2px 0;
				margin: 0;
				border-bottom: 1px solid $wine-berry;
			}
		}
	}

	@media screen and (min-width: 1024px) {
		width: calc(100% - 40px);
		float: none;
		margin: 0 auto;
	}
}
.social-links {
	padding-left: 0;
	li {
		display: inline;
		float: none;
		padding-right: 0.5em;
	}
}

#email-alert-signup {
	white-space: nowrap;
	input[type="text"] {
		border: none;
		margin-left: 0;
		width: 15em;
	}
	button {
		background-color: $dark-gray;
		border: none;
		color: $white;
		height: 31px;
		padding: 0;
		width: 31px;
	}
}
.copyright {
	background-color: $citrus;
	color: $white;
	text-align: center;
}
.postscript {
	background-color: $citrus;
	color: $white;
	font-style: italic;
	text-align: center;
}

@media screen and (max-width: 1260px) {
	footer {
		.footer-container {
			width: 96%;
			.footer-email-signup {
				width: 50%;
			}
			&.footer-down {
				.footer-down-inner {
					width: auto;
					margin: 38px 20px 0;
					.footer-links {
						.footer_list_wrapper {
							width: 96%;
						}
					}
					.brands-list {
						ul {
							li {
								margin: 0 1% 0 0;
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1260px) {
	.footer-container {
		.footer-links {
			.footer_list_wrapper {
				.footer_item {
					&:nth-child(1) {
						margin-left: 113px;
						width: 283px;
					}
					&:nth-child(2) {
						width: 303px;
					}
					&:nth-child(3) {
						width: 250px;
					}
					&:nth-child(4) {
						width: auto;
					}
				}
			}
		}
	}
}

.social-links {
	padding-left: 0;
	li {
		display: inline;
		float: none;
		padding-right: 0.5em;
	}
}

#email-alert-signup {
	white-space: nowrap;
	input[type="text"] {
		border: none;
		margin-left: 0;
		width: 15em;
	}
	button {
		background-color: $dark-gray;
		border: none;
		color: $white;
		height: 31px;
		padding: 0;
		width: 31px;
	}
}

.email-signup,
.gc-balance {
	&.make-label-absolute {
		.form-row {
			position: relative;
			label {
				font-weight: normal;
				font-size: 12px;
				padding: 0;
				position: absolute;
				-webkit-transform: translate(5px, 20px);
				-ms-transform: translate(5px, 20px);
				transform: translate(5px, 20px);
				left: 16px;
				margin: 0;
				top: -3px;
				width: auto;
				color: $dim-gray;
				z-index: 1;
				-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
				transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
				-ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
				&.input-focus {
					-webkit-transform: translate(5px, 6px);
					-ms-transform: translate(5px, 6px);
					transform: translate(5px, 6px);
				}
				span {
					font-size: 12px;
					&.error {
						color: $RedRibbon;
						padding-top: 0 !important;
						line-height: normal;
					}
					&.required-indicator {
						display: none;
					}
				}
			}
			&.error-handle {
				label {
					span {
						&.label-text {
							display: none;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	footer {
		.footer-container {
			.footer-links {
				.footer_list_wrapper {
					text-align: center;
					.footer_item {
						width: 100%;
						.footer-heading {
							text-align: center;
						}
						li {
							text-align: center;
						}
					}
				}
			}
			.footer-brand-selector {
				.brands-list {
					&.hide-mobile {
						display: none;
					}
					&.hide-desktop {
						display: block;
					}
				}
			}
			.footer-legal-copyrights {
				.static-links {
					margin: 15px auto;
				}
			}
			.footer-social-icon {
				.fo-border {
					display: none;
				}
			}
		}
	}
	.footer-plcc {
		.section {
			.image-section {
				.image-section-align {
					margin: 0 auto;
					width: 100%;
					max-width: 70%;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	footer {
		.footer-container {
			.footer-links {
				.footer_list_wrapper {
					.footer_item {
						width: 100%;
						.footer-heading {
							text-align: center;
						}
						li {
							text-align: center;
						}
					}
				}
			}
			.footer-email-signup {
				width: 100%;
				.email-signup {
					width: 100%;
					.form-row {
						width: 65%;
						input[type="text"] {
							width: 100%;
						}
						button {
							width: 100px;
							font-size: $base-font + 3px;
						}
					}
					.form-row-button {
						width: 35%;
						button {
							width: 100%;
						}
					}
				}
			}
			.footer-brand-selector {
				.footer-brand-heading {
					font-size: 16px;
					letter-spacing: 1px;
				}
				.brd_main_wrapper {
					float: left;
					width: 100%;
					.brd_logo_container {
						float: left;
						width: 100%;
						display: table;
						text-align: center;
						margin-bottom: 25px;
						a {
							display: table-cell;
							vertical-align: middle;
							img {
								max-width: 75%;
							}
						}
					}
				}
			}
			.footer-legal-copyrights {
				.static-links {
					margin: 0px 10px 15px 0px;
					ul {
						li {
							margin-right: 3px;
						}
					}
				}
				.copyrights-wrapper {
					.copy-arr {
						margin-bottom: 2px;
					}
				}
			}
			.footer-social-icon {
				.fo-border {
					display: none;
				}
			}
		}
	}
}

.footer-container .footer-brand-selector .brands-list {
	display: block;
	margin: auto;
	text-align: center;
	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 4px 0 15px 0;
		li {
			margin: 0;
			width: auto;
			a {
				display: block;
				img {
					max-width: 100%;
					&[alt="fullbeauty"] {
						width: 120px;
						height: 34px;
					}
					&[alt="womanwithin"] {
						width: 131px;
						height: 24px;
					}
					&[alt="jessicalondon"] {
						width: 115px;
						height: 14px;
					}
					&[alt="Roamans"],
					&[alt="romans"] {
						width: 88px;
						height: 20px;
					}
					&[alt="ellos"] {
						width: 58px;
						height: 25px;
					}
					&[alt="swimsuits"] {
						width: 125px;
						height: 21px;
					}
					&[alt="kingsize"] {
						width: 80px;
						height: 18px;
					}
					&[alt="brylanehome"] {
						width: 148px;
						height: 32px;
					}
					&[alt="bco"] {
						width: 81px;
						height: 43px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.footer-container {
		&.footer-down {
			.footer-links {
				float: none;
				margin: 0 auto;
				width: auto;
				@extend .clearfix;
				.footer_list_wrapper {
					width: 100% !important;
				}
			}
			.footer-brand-selector .brands-list {
				ul {
					justify-content: space-between;
					li {
						a img {
							display: block;
							&[alt="fullbeauty"] {
								width: 73px;
								height: 21px;
							}
							&[alt="womanwithin"] {
								width: 88px;
								height: 16px;
							}
							&[alt="Roamans"],
							&[alt="romans"] {
								width: 54px;
								height: 13px;
							}
							&[alt="ellos"] {
								width: 32px;
								height: 14px;
							}
							&[alt="swimsuits"] {
								width: 76px;
								height: 13px;
							}
							&[alt="kingsize"] {
								width: 49px;
								height: 11px;
							}
							&[alt="brylanehome"] {
								width: 89px;
								height: 20px;
							}
							&[alt="bco"] {
								width: 50px;
								height: 26px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	footer {
		.footer-container {
			&.footer-down {
				.footer-down-inner {
					margin-right: 5px;
					margin-left: 5px;
					margin-top: 29px;
				}
				.footer-brand-selector .brands-list {
					ul {
						flex-flow: row wrap;
						justify-content: space-around;
						margin: 0 10px 25px;
						&:before,
						&:after {
							content: "";
							width: 100%;
							order: 1;
						}
						li {
							&:nth-child(n + 3) {
								order: 1;
							}
							&:nth-child(5n) {
								padding: 20px 0;
							}
							&:nth-child(n + 6) {
								order: 2;
							}
							a img {
								display: block;
								&[alt="fullbeauty"] {
									width: (217px/2);
									height: (58px/2);
								}
								&[alt="womanwithin"] {
									width: (256px/2);
									height: (48px/2);
								}
								&[alt="Roamans"],
								&[alt="romans"] {
									width: (160px/2);
									height: (38px/2);
								}
								&[alt="ellos"] {
									width: (81px/2);
									height: (33px/2);
								}
								&[alt="swimsuits"] {
									width: (179px/2);
									height: (30px/2);
								}
								&[alt="kingsize"] {
									width: (128px/2);
									height: (26px/2);
								}
								&[alt="brylanehome"] {
									width: (215px/2);
									height: (46px/2);
								}
								&[alt="bco"] {
									width: (116px/2);
									height: (61px/2);
								}
							}
						}
					}
				}
			}
		}
	}
	.menu-footer {
		.livechat-icon {
			&.boldchat-loaded {
				display: block;
			}
			a img,
			.bcStatic {
				display: none;
			}
			.bcText {
				text-align: left;
				text-transform: uppercase;
				margin-bottom: 20px;
			}
		}
	}
	.hamburger-keyboard {
		.menu-footer {
			.livechat-icon .bcText {
				a {
					display: inline-block;
				}
			}
		}
	}
}
/* Focus */
.ui-dialog,
.legacy-sitegen {
	.focus-outline {
		outline: 3px solid #0000ff !important;
	}
}