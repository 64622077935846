.error-content-wrapper {
	margin-top: 70px;

	@media screen and (max-width: 1023px) {
		margin-top: 170px;
	}

	@media screen and (max-width: 767px) {
		margin: 100px 35px;
	}
	@media screen and (max-width: 767px) and (orientation: landscape) {
		margin: 50px 25px;
	}
	@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
		margin: 50px 25px;
		height: 100%;
	}
	.copyrights-wrapper {
		position: fixed;
		background: $black;
		color: $white;
		bottom: 0;
		right: 0;
		left: 0;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 0.57;
		letter-spacing: normal;
		text-align: center;
		padding: 35px 0;
		@media screen and (max-width: 767px) {
			padding: 0;
		}
		p {
			font-family: $Helvetica;
			font-size: 14px;
			margin: 20px 0;
			@media screen and (max-width: 767px) {
				font-size: 10px;
				line-height: 1.2;
				margin: 10px;
			}
		}
	}
	.error-content {
		text-align: center;
		max-width: 687px;
		margin: 0 auto;
		@media screen and (max-width: 1023px) {
			text-align: center;
			margin: 0 auto;
			position: fixed;
			width: 90%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
		}
		img {
			margin: 45px 0 0;
			width: 100%;
			@media screen and (max-width: 1023px) {
				margin: 20px 0 0;
			}
			@media screen and (max-width: 767px) {
				margin: 20px 0 0;
				width: 80%;
			}
			@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
				max-width: 267px;
			}
		}
		.sub-heading {
			margin-bottom: 5px;
			font-family: $Helvetica;
			font-size: 36px;
			font-weight: normal;
			line-height: normal;
			letter-spacing: normal;
			margin: 45px 0px;
			@media screen and (max-width: 767px) {
				margin: 45px auto 65px;
				font-size: 20px;
				line-height: 1.5;
				max-width: 265px;
			}
			@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
				margin: 25px auto;
				font-size: 20px;
				line-height: 1.5;
				max-width: 450px;
			}
		}
		.call-us,
		.contact-us {
			font-family: $Helvetica;
			font-size: 24px;
			margin: 1% 0;
			font-weight: 100;
			letter-spacing: 0.025em;
			@media screen and (max-width: 1023px) {
				margin: 1% 0;
			}
			@media screen and (max-width: 767px) {
				margin: 10px 0px;
				font-size: 14px;
				line-height: 1.5;
			}
		}
		.contact-us {
			a {
				color: $sky-blue;
			}
		}
		.call-us {
			a {
				@media screen and (max-width: 767px) {
					color: $sky-blue;
					display: block;
					margin-top: 10px;
				}
				@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
					display: inline;
				}
			}
		}
	}
}
