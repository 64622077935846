.pt_lookbooklanding {
	//Recommendations
	.you-may-like {
		.lookbooks-recommendations-tiles {
			max-width: 870px;
			margin: 0 auto;
		}

		.product-tile {
			padding: 0 20px;
			max-width: inherit;
			width: 25%;
			float: left;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;

			@media screen and (max-width: 1023px) {
				padding: 0 10px;
			}

			.product-image {
				img {
					width: 188px;
					height: 246px;
					margin: 0 auto;

					@media screen and (max-width: 767px) {
						width: 100%;
						max-width: 240px;
						height: 200px;
					}
				}
			}

			.p-image-rollover {
				display: none;
			}
		}

		button {
			border: none;
			border-radius: 0;
			color: transparent;
			display: inline-block;
			padding: 0;
			margin: 0 auto;
			position: absolute;
			width: 23px;
			height: 23px;
			transform: translateY(-50%);
			top: 35%;

			&.slick-arrow {
				margin: 0 auto;
				padding: 14px;

				&.slick-next {
					left: 100%;
					background: url("../../../../images/carat-right_Black.svg") no-repeat center / contain;
					margin: 0 auto;

					&.slick-disabled {
						background: url("../../../../images/carat-right_Grey.svg") no-repeat center/contain;
					}
				}

				&.slick-prev {
					right: 100%;
					background: url("../../../../images/carat-left_Black.svg") no-repeat center/contain;
					margin: 0 auto;

					&.slick-disabled {
						background: url("../../../../images/carat-left_Grey.svg") no-repeat center/contain;
					}
				}
			}
		}
	}

	.primary-content {
		width: 100%;
	}

	.banner {
		position: relative;
		margin: 0 auto 60px;

		.text-align,
		.hotspot {
			position: absolute;
		}

		.heading {
			font-family: $BauerBodoniRegular;
			font-style: italic;
			margin: 0;
		}

		p {
			font-family: $Helvetica;
		}

		a {
			&.shop-now {
				font-family: $HelveticaBold;
				text-transform: uppercase;
				padding-bottom: 5px;
				border-bottom: 2px solid $black;
				letter-spacing: 1px;
			}
		}
	}

	.banner-slot-1 {
		margin: 0 0 60px 0;

		.text-align {
			max-width: 420px;
			top: 15%;
			left: 10%;
			transform: translate(-10%, -15%);
			-ms-transform: translate(-10%, -15%);
			-moz-transform: translate(-10%, -15%);
			-o-transform: translate(-10%, -15%);
			-webkit-transform: translate(-10%, -15%);
		}

		.heading {
			font-size: $base-font + 48px;
		}

		p {
			font-size: $base-font + 6px;
			margin: 30px 0;
			line-height: 30px;
		}

		a {
			&.shop-now {
				font-size: $base-font + 8px;
			}
		}

		.hotspot-1 {
			top: 35.3%;
			right: 20px;
		}

		.hotspot-2 {
			bottom: 24.8%;
			left: 39%;
		}
	}

	.banner-slot-2 {
		max-width: 980px;

		.text-align {
			bottom: 10%;
			right: 0;
			max-width: 265px;
		}

		.heading {
			font-size: $base-font + 24px;
		}

		p {
			font-size: $base-font + 2px;
			margin: 25px 0;
			line-height: 30px;
			padding-left: 60px;
		}

		a {
			&.shop-now {
				font-size: $base-font + 6px;
				margin-left: 60px;
			}
		}

		.hotspot-1 {
			left: 59.2%;
			bottom: 60px;
		}

		.hotspot-2 {
			top: 46%;
			left: 90px;
		}
	}

	.banner-slot-3 {
		max-width: 980px;

		.hotspot-1 {
			top: 30%;
			right: 30.4%;
		}

		.hotspot-2 {
			bottom: 31%;
			right: 21%;
		}
	}

	.banner-slot-4 {
		max-width: 980px;

		.hotspot-1 {
			top: 34%;
			left: 32%;
		}

		.hotspot-2 {
			top: 40%;
			right: 8%;
		}

		.hotspot-3 {
			bottom: 12%;
			left: 4%;
		}
	}

	.banner-slot-5 {
		max-width: 980px;

		.text-align {
			top: 8%;
			left: 13%;
			max-width: 260px;
		}

		.heading {
			font-size: $base-font + 24px;
		}

		p {
			font-size: $base-font + 2px;
			margin: 25px 0 25px 25px;
			line-height: 20px;
		}

		a {
			&.shop-now {
				font-size: $base-font + 6px;
				margin-left: 25px;
			}
		}

		.hotspot-1 {
			top: 49%;
			left: 47%;
		}

		.hotspot-2 {
			bottom: 30px;
			right: 20px;
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_lookbooklanding {
		.banner {
			margin: 0 auto 40px;
			text-align: center;
			max-width: 740px;

			.text-align {
				text-align: left;
			}
		}

		.banner-slot-1 {
			margin: 0 auto 40px;
			max-width: 840px;

			.text-align {
				max-width: 380px;
				top: 15%;
				left: 10%;
				width: 60%;
			}

			p {
				margin: 20px 20px 20px 0;
			}

			.hotspot-1 {
				top: 30%;
				right: 9%;
			}

			.hotspot-2 {
				bottom: 16.8%;
			}
		}

		.banner-slot-2 {
			.text-align {
				bottom: 5%;
				right: 0;
				max-width: 190px;
				width: 60%;
			}

			p {
				margin: 15px 0;
				line-height: 25px;
				padding-left: 40px;
			}

			a {
				&.shop-now {
					float: left;
					margin: 0 40px;
				}
			}
		}

		.banner-slot-5 {
			.text-align {
				top: 7%;
			}

			p {
				margin: 15px 0 15px 25px;
				line-height: 25px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_lookbooklanding {
		.banner {
			margin: 0 auto 20px;
			text-align: center;
			max-width: 640px;

			.text-align {
				text-align: left;
				position: relative;
				top: 0;
				left: 0;
				transform: translate(0, 0);
				-ms-transform: translate(0, 0);
				-webkit-transform: translate(0, 0);
				-moz-transform: translate(0, 0);
				-o-transform: translate(0, 0);
				max-width: 100%;
				width: 100%;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}

			.image-position {
				position: relative;
			}

			p {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				font-size: $base-font;
			}

			a {
				&.shop-now {
					font-size: $base-font;
				}
			}
		}

		.banner-slot-1 {
			margin: 0 auto 20px;
			max-width: 640px;

			.text-align {
				padding: 20px 10px;

				.heading {
					font-size: $base-font + 12px;
				}
			}

			p {
				padding: 10px 10px 10px 0;
				line-height: 20px;
				margin: 0;
			}

			.hotspot-1 {
				top: 30%;
				right: 0;
			}

			.hotspot-2 {
				bottom: 20%;
				left: 5%;
			}
		}

		.banner-slot-2 {
			.text-align {
				padding: 15px 10px;

				.heading {
					font-size: $base-font + 9px;
				}
			}

			p {
				margin: 15px 0;
				line-height: 20px;
				padding-left: 15px;
			}

			a {
				&.shop-now {
					float: none;
					margin: 0 0 0 15px;
				}
			}

			.hotspot-1 {
				left: 56%;
				bottom: 10%;
			}

			.hotspot-2 {
				top: 42%;
				left: 10%;
			}
		}

		.banner-slot-3 {
			.hotspot-1 {
				right: 31%;
			}
		}

		.banner-slot-4 {
			.hotspot-1 {
				top: 30%;
				left: 38%;
			}

			.hotspot-2 {
				top: 40%;
				right: 4%;
			}

			.hotspot-3 {
				left: 7%;
			}
		}

		.banner-slot-5 {
			.text-align {
				position: absolute;
				width: 47%;
				z-index: 1;
				left: 3%;
			}

			p {
				margin: 10px 0 10px 25px;
				line-height: 20px;
			}

			.hotspot-2 {
				bottom: 7%;
				right: 2%;
			}
		}
	}
}
