.pt_content {
    .full-width {
        padding: 0;
    }
}

.gift-landing {
    width: auto;
    float: left;
    position: relative;

    .main-slot,
    .gift-slot-1,
    .more-gift-cards,
    .main-slot-1 {
        width: 100%;
        display: inline-block;
        position: relative;
        margin: 0 0 10px 0;
        float: left;
    }

    .content {
        position: absolute;
        left: 50%;
        top: 50%;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        height: auto;
        transform: translate(-50%, -50%);

        .valign_height {
            width: 100%;
            float: left;

            .e-gift,
            .gift-card {
                h2 {
                    text-align: center;
                    margin: 0 0 30px 0;
                    letter-spacing: 10px;
                }

                button {
                    margin: 20px;
                    font-size: $base-font + 3px;
                    letter-spacing: 2px;
                    padding: 15px 20px;
                }
            }
        }
    }

    .white-background {
        padding: 40px 50px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        text-align: center;
        text-transform: uppercase;
        background: $light-skygrey;
        display: inline-block;

        .cards {
            width: 25%;
            float: left;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;

            @media screen and (min-width: 960px) and (max-width: 1024px) {
                width: 25%;
            }
            @media screen and (max-width: 767px) {
                width: 50%;
                margin-bottom: 20px;
            }

            &:nth-child(4) {
                margin: 0;
            }

            img {
                display: inline-block;
            }

            button {
                margin: 15px 0 0 0;
                padding: 14px 42px;
                width: auto;
                font-size: $base-font + 2px;
                letter-spacing: 2px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .gift-landing {
        .main-slot,
        .gift-slot-1,
        .more-gift-cards {
            .content {
                .valign_height {
                    margin: 0 auto;
                }
            }
        }
        .white-background {
            width: 100%;
            padding: 40px 5px;

            .shop-gift {
                margin: 0;
            }

            .cards {
                margin-top: 35px;
                width: 50%;

                &:nth-child(4) {
                    margin: 35px 0 0 0;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
}

.ui-dialog {
    &.gc-checkbalance {
        padding: 10px;
        font-size: $base-font;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;

        .ui-dialog-content {
            padding: 0;
        }

        .ui-widget-header {
            background: $mercury;
        }

        .gc-balance {
            background: $mercury;
            padding: 0 50px 50px;
            font-family: $HelveticaMedium, $sans-serif;

            &.make-label-absolute {
                .form-row {
                    max-width: 320px;
                    display: flex;
                    justify-content: flex-end;
                }
            }

            .heading {
                padding: 10px 0;
            }

            .form-row {
                .field-wrapper {
                    margin-bottom: 20px;
                    width:100%;

                    input[type="text"] {
                        width: 100%;
                        height: 50px;
                        padding: 0 20px;
                        border: 1px solid #aaa;
                    }

                    span {
                        &.error {
                            width: 100%;
                            float: left;
                        }
                    }
                }

                &.form-row-pincode {
                    .form-row {
                        width: 47%;
                        display: inline-block;
                        float: left;

                        @media screen and (max-width: 767px) {
                            width: 100%;
                        }

                        &.form-row-button {
                            margin: 0;
                            float: right;

                            button {
                                color: $white;
                                height: 50px;
                                width: 100%;
                            }
                        }
                    }

                    input[type="text"] {
                        width: 100%;
                    }
                }
            }

            .gc-show-message {
                padding: 10px 0 0 0;
                font-size: $base-font + 2px;
                font-family: $HelveticaBold;

                .heading {
                    font-family: $Helvetica;
                }
            }
        }
    }
}
