@import "nav";
@import "menu_utility";
@import "country_selector";
@import "_oss_brand_selector";

html {
    a,
    a:hover,
    a:focus,
    a:active,
    a:visited,
    button,
    button:hover,
    button:focus,
    button:active,
    button:visited {
        outline: 0;
        box-shadow: none;
    }
}

html,
body {
    &.iosScrollStopper {
        overflow: hidden;
        position: relative;
        height: 100%;
    }

    & > .bcFloat,
    & > .QSIFeedbackButton #QSIFeedbackButton-btn {
        left: auto !important;
        right: 17px !important;
    }

    & > #QSIFeedbackButton-target-container{
        transform: translateX(-17px);
    }

    &.mac-os {
        body {
            padding-right: 0;

            & > .bcFloat,
            & > .QSIFeedbackButton #QSIFeedbackButton-btn {
                right: 0 !important;
            }

            & > #QSIFeedbackButton-target-container{
                transform: translateX(0);
            }
        }
    }
}

body {
    overflow: overlay;
    overflow-x: hidden;
    padding-right: 15px;
    @supports not (overflow: overlay) {
        padding-right: 0;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding-right: 0;
    }
    @supports (-ms-ime-align: auto) {
        padding-right: 0;
    }
    @media (hover: none) and (pointer: coarse) {
        padding-right: 0;
    }
}

#header {
    width: 100%;
}
.ucp2-icon,
.ucp2-cart-icon,
.ucp2-cart-with-item-icon,
.ucp2-account-icon {
    display: block;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    margin: 0 auto;
    width: 100%;
    @media (min-width: 768px) {
        height: 18px;
    }
}

.ucp2-deals-icon {
    background-image: url(../../../../images/deals_black.svg);
}

.ucp2-search-icon {
    background-image: url(../../../../images/search_black.svg);
}

.ucp2-catalog-icon {
    background-image: url(../../../../images/catalog_black.svg);
}

.ucp2-card-icon {
    background-image: url(../../../../images/card_black.svg);
}

.ucp2-account-icon {
    background-image: url(../../../../images/account_black.svg);
}

.ucp2-cart-icon {
    background-image: url(../../../../images/cart_black.svg);
}

.ucp2-cart-with-item-icon {
    background-image: url(../../../../images/cart_black.svg);
    position: relative;
    width: 30px;

    .minicart-quantity {
        color: #fff;
        position: absolute;
        top: -5px;
        right: -2px;
        width: 18px;
        height: 18px;
        display: block;
        background-color: $promo-red;
        border-radius: 50%;
        padding: 0;
        margin: 0;
        line-height: 18px;
        font-size: 10px;
        text-align: center;
        font-weight: bold;
        box-sizing: border-box;

        @media screen and (min-width: 768px) and (max-width: 1023px) {
            top: -6px;
            right: -8px;
        }
    }
}
.top-menu-utility {
    background: #000;
}

.header-menu-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
        display: none;
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
    }
    li {
        padding: 14px 10px;
        text-transform: uppercase;
        font-family: "Futura LT W01 Medium";
        font-weight: bold;
        letter-spacing: 1px;
        white-space: nowrap;
    }
}

.menu-toggle {
    width: 47px;
    height: 40px;
    position: relative;
    border-radius: 0;
    text-transform: uppercase;
    color: #000;
    border: 0;
    padding: 0;
    background: transparent;
    @include prefix(transform, rotate(0deg), $prefixArray);
    cursor: pointer;

    @media only screen and (min-width: 768px) {
        width: 41.7px;
        height: 35.5px;
    }

    &:hover,
    &:focus,
    &:active {
        border: none;
        outline: none;
    }
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 35px;
        background: $black;
        @include prefix(border-radius, 0, webkit moz);
        opacity: 1;
        left: 6px;
        @include prefix(transform, rotate(0deg), $prefixArray);
        @include transitions($property: all, $time: 0.25s, $timing: ease-in-out);
        @media only screen and (min-width: 768px) {
            width: 28px;
        }
        &:nth-child(1) {
            top: 11px;
            @media only screen and (min-width: 768px) {
                top: 9px;
            }
        }
        &:nth-child(2) {
            top: 19px;
            @media only screen and (min-width: 768px) {
                top: 17px;
            }
        }
        &:nth-child(3) {
            top: 27px;
            @media only screen and (min-width: 768px) {
                top: 25px;
            }
        }
    }
}

.header-mobile-menu-open {
    .menu-toggle {
        span {
            width: 20px;
            &:nth-child(1) {
                top: 11px;
                @include prefix(transform, rotate(135deg), $prefixArray);
            }
            &:nth-child(2) {
                opacity: 0;
                left: -60px;
            }
            &:nth-child(3) {
                top: 11px;
                @include prefix(transform, rotate(-135deg), $prefixArray);
            }
        }
    }
    .header-tagline {
        display: none;
    }
}

.secondary-header {
    ul {
        text-align: right;
        margin: 0;
        padding: 0;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        @media only screen and (min-width: 1024px) {
            position: relative;
            z-index: 2;
        }
    }
    li {
        vertical-align: middle;
        padding: 0;
        text-transform: uppercase;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            padding: 0;
        }
        a {
            color: #fff;
            font-size: 10px;
            padding:0 5px;
            @media only screen and (min-width: 768px) and (max-width: 1023px) {
                font-size: 9px;
            }
        }
        &.header-search-util,
        &.secondary-catalog,
        &.secondary-card,
        &.secondary-account,
        &.shopping-bag-sm {
            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-content: center;
                text-align: center;
                justify-content: center;
                flex-direction: column;
                box-sizing: border-box;
                overflow: visible;
                white-space: nowrap;
                &.focus-outline:focus {
                    height: 93%;
                    margin-top: 2px;
                }
                @media only screen and (min-width: 768px) {
                    position: relative;
                }
            }
        }
    }
    .header-util-btn {
        position: relative;
        display: table;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
    }
}
.header-tagline {
    background-color: $black;
    color: $white;
    text-align: center;

    .content-asset {
        position: relative;
        max-width: 1260px;
        margin: 0 auto;
        padding: 15px 0 0;
        &:after {
            content: "";
            width: 100%;
            height: 1px;
            pointer-events: none;
            position: absolute;
            left: 0;
            top: 31px;
            border-top: 1px solid $white;
            box-sizing: border-box;
        }
    }
    p {
        font-size: 10px;
        background: $black;
        display: inline-block;
        z-index: 1;
        padding-left: 10px;
        padding-right: 10px;
    }

    hr {
        display: none !important;
    }
}
.header-menu-mobile-tablet {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}
.header-promo-bottom {
    z-index: 0 !important;
    .promo-banner {
        max-width: 1260px;
        margin: 17px auto 11px;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-family: "Futura LT W01 Medium";
        display: block;
        letter-spacing: 1px;
        span {
            vertical-align: middle;
            padding: 0 4px;
            display: inline-block;            
        }
        a[href]{
            color: $cobalt-blue;
            border-bottom-color: $cobalt-blue;
            &:hover{
                color: $cobalt-blue-hover;
                border-bottom-color: $cobalt-blue-hover;
            }
        }
    }
}
.header-mian-banner-top {
    height: 1px;
    position: relative;
    top: -1px;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    display: block;
    width: 100%;
}
@media screen and (max-width: 767px) {
    .header-tagline {
        display: none;
    }
    .header-sticky-mobile {
        .fixed-header {
            .fixed-header-wrapper {
                .secondary-header {
                    li {
                        width: 50%;
                    }
                }
            }
        }
    }
    .secondary-header {
        background: #fff;
        li {
            flex-basis: 50%;
            height: 60px;
            width: 20%;
            a {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                color: #000;
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                align-content: center;
                text-align: center;
                justify-content: center;
                flex-direction: column;
                box-sizing: border-box;
                font-weight: bold;
            }
        }
        .secondary-account {
            display: none;
        }
        .has-active-panel {
            a {
                background: #000;
                color: #fff;
            }
            .ucp2-search-icon {
                background-image: url(../../../../images/search_white.svg);
            }
            .ucp2-account-icon {
                background-image: url(../../../../images/account_white.svg);
            }
            .ucp2-card-icon {
                background-image: url(../../../../images/card_white.svg);
            }
            .ucp2-cart-icon,
            .ucp2-cart-with-item-icon {
                background-image: url(../../../../images/cart_white.svg);
            }
        }
    }
}
//new active code for secondary header
@media screen and (max-width: 1023px) and (min-width:768px){
    .secondary-header li.has-active-panel {
        a {
            background: $black;
            color: $white;
        }
        .ucp2-search-icon {
            background-image: url(../../../../images/search_white.svg);
        }
        .ucp2-account-icon {
            background-image: url(../../../../images/account_white.svg);
        }
        .ucp2-card-icon {
            background-image: url(../../../../images/card_white.svg);
        }
        .ucp2-cart-icon,
        .ucp2-cart-with-item-icon {
            background-image: url(../../../../images/cart_white.svg);
        }
    }

}
@media screen and (min-width: 768px) {
    .header-sticky-mobile {
        .fixed-header {
            .fixed-header-wrapper {
                width: calc(100vw - (100vw - 100%));
            }
        }
    }

    body:not(.header-mobile-menu-open):not(.header-sticky-mobile) {
        .top-menu-utility {
            .header-menu-mobile-tablet,
            ~ .top-banner {
                border-top: none;
                box-shadow: 5px -10px 10px -5px rgba(0, 0, 0, 0.2);
                position: relative;
            }
        }
    }

    .header-menu-scroll {
        .scroll {
            justify-content: space-between;
        }
    }

    .menu-utility {
        flex: unset;
    }
    .secondary-header {
        flex: unset;
        padding-right: 6px;
        ul {
            height: 55px;
            margin-top: 13px;
        }
        .header-search-util,
        .secondary-catalog,
        .secondary-account,
        .shopping-bag-sm,
        .secondary-card {
            width: auto;
            @media screen and (min-width: 1024px) and (max-width: 1399px){
                margin-right: 10px;
            }

            @media screen and (min-width: 1400px){
                margin-right: 15px;
            }
        }
        .ucp2-icon,
        .ucp2-cart-icon,
        .ucp2-cart-with-item-icon,
        .ucp2-account-icon {
            margin-bottom: 4px;
        }
        .deals-xs {
            display: none;
            @media only screen and (max-width: 767px) {
                text-align: right;
                display: list-item;
                .btn-highlight {
                    display: inline-block;
                    background-color: #b50000;
                    border-radius: 40px;
                    padding: 8px 20px;
                    font-weight: bold;
                    font-size: 12px;
                    letter-spacing: 1.5px;
                    span {
                        display: block;
                    }
                    .ucp2-deals-icon {
                        display: none;
                    }
                }
            }
        }
        .has-active-panel {
            a {
                background: #fff;
                color: #000;
            }
            .ucp2-search-icon {
                background-image: url(../../../../images/search_black.svg);
            }
            .ucp2-card-icon {
                background-image: url(../../../../images/card_black.svg);
            }
            .ucp2-account-icon {
                background-image: url(../../../../images/account_black.svg);
            }
            .ucp2-cart-icon,
            .ucp2-cart-with-item-icon {
                background-image: url(../../../../images/cart_black.svg);
            }
        }
    }
    .header-container {
        max-width: 1260px;
        margin: 0 auto;
    }
    #navigation,
    .top-menu-utility-wrapper {
        max-width: 1260px;
        margin: 0 auto;
        display: flex;
    }
    .top-menu-utility-wrapper {
        position: relative;
    }
}
@media screen and (min-width: 1024px) {
    .secondary-header {
        padding-right: 12px;

        .ucp2-search-icon {
            background-image: url(../../../../images/search_white.svg);
        }
        .ucp2-catalog-icon {
            background-image: url(../../../../images/catalog_white.svg);
        }
        .ucp2-card-icon {
            background-image: url(../../../../images/card_white.svg);
            background-size: 22px 18px; //Required for Edge
        }
        .ucp2-account-icon {
            background-image: url(../../../../images/account_white.svg);
        }
        .ucp2-cart-icon,
        .ucp2-cart-with-item-icon {
            background-image: url(../../../../images/cart_white.svg);
        }
    }
    .slider-menu {
        position: relative;
    }
    .sub-cat-wrapper {
    }
    .header-menu-mobile-tablet {
        display: none;
    }
    .navigation-header {
        display: none;
    }
    .menu-utility {
        flex: unset;
        flex-basis: unset;
    }
    .top-menu-utility-wrapper {
        flex-direction: row;
        align-items: flex-end;
        justify-content:space-between;
    }
    .secondary-header {
        flex: unset;
        z-index: 1;
        flex-basis: unset;
        margin-left: 20px;
    }
    .menu-category {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dcdcdc;
        .show-only-mobile {
            display: none;
        }
        .nav-viewAll {
            display: none;
        }
        .has-sub-menu,
        .no-flyout a {
            box-sizing: border-box;
            letter-spacing: 1.25px;
            padding: 5px 12px;
            height: 54px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            overflow: visible;
        }

        .has-sub-menu {
            padding: 5px 5px;
        }
        .level-2 {
            position: absolute;
            top: 54px;
            left: 0;
            width: 100%;
            opacity: 0;
            z-index: 10;
            visibility: hidden;
        }
        .hoverIntent {
            .level-2 {
                opacity: 1;
                visibility: visible;
                .sub-cat-wrapper {
                    border: 1px solid #dcdcdc;
                    background: #fff;
                    display: block;
                    margin: 0 auto;
                    max-width: 1260px;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 35px;
                    .sub-cat-nav {
                        display: inline-block;
                        vertical-align: top;
                        ul.nav-column {
                            box-sizing: border-box;
                            margin: 0 35px 0 0;
                            padding: 0;
                            width: 195px;
                            float: left;
                            li {
                                padding: 0 0 20px;
                                clear: left;
                                width: 100%;
                                text-align: left;
                            }
                            a {
                                text-transform: uppercase;
                                white-space: normal;
                            }
                            .boldCat a {
                                font-family: "Futura LT W01 Bold";
                            }
                        }
                    }
                    .banner {
                        display: inline-block;
                        vertical-align: top;
                        box-sizing: border-box;
                        padding: 0;
                    }
                    .level-3.show-only-mobile.show-categories,
                    .level-4.show-only-mobile.show-categories,
                    .has-sub-menu-parent-level3.show-categories {
                        display: block;
                    }
                    .has-sub-menu-parent-level3.show-categories ul {
                        padding-left: 0;
                    }
                    .boldCat.parent-level3 {
                        margin-bottom: 20px;
                    }
                }
                a {
                    color: $black;
                    display: inline-block;
                }
            }
            .has-sub-menu {
                position: relative;
            }
            .has-sub-menu::after {
                content: "";
                width: 100%;
                bottom: -5px;
                position: absolute;
                left: 0;
                opacity: 1;
                z-index: 11;
                border: 5px solid #dcdcdc;
                background: #dcdcdc;
                border-radius: 10px;
                box-sizing: border-box;
            }
        }

        .menu-item-toggle {
            display: none;
        }

        > li {
            > a {
                &.focus-outline {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
    .navigation-cart {
        display: none;
    }
    .menu-footer {
        display: none;
    }
    .header-sticky-desktop {
        .top-banner {
            height: 45px;
        }
        .menu-category {
            padding-right: 60px;
            border-bottom: none;
        }
        .header-mian-banner {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 10;
            background: #fff;
            box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
        }
        .navigation-cart {
            display: block;
            margin: 0;
            padding: 15px;
            position: absolute;
            top: 0;
            right: 5px;
            .secondary-account {
                display: none;
            }
            span {
                display: none;
                span {
                    display: block;
                }
            }
            .ucp2-cart-icon,
            .ucp2-cart-with-item-icon {
                display: block;
                width: 24px;
            }
        }
        .navigation-scroll-inner {
            position: relative;
        }

        .ucp2-cart-with-item-icon .minicart-quantity{
            top: -6px;
            right: -5px;
        }
    }
} // End 1024+

.right-panel-pane-cart {
    .mini-cart-total {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .mini-cart-content {
        display: flex;
        flex-direction: column;
        height: 100% !important;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            height: 87% !important;
        }
    }

    a.mini-cart-link {
        display: none;
    }

    .mini-cart-products {
        flex: 1;
        box-sizing: border-box;
        padding: 0 25px;
        margin-right: 8px;

        .mini-cart-product {
            .availability-msg {
                b {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    text-transform: capitalize;
                }
            }
            border-bottom: 1px solid #d2d2d2;
            position: relative;
            text-align: left;
            width: 100%;
            .mini-cart-item-row {
                padding: 11px 0 0 0;
                box-sizing: border-box;
                display: table;
            }
            &:first-child {
                padding: 0 0 14px 0;
            }
            &:last-child {
                border-bottom: none;
            }

            .left-content {
                display: table-cell;
                vertical-align: top;
                width: 81px;
                .mini-cart-image {
                    padding-top: 0;
                    text-align: left;
                    a {
                        display: inline-block;
                        img {
                            width: 80px;
                            height: 100px;
                        }
                    }
                }
            }
            .right-content {
                display: table-cell;
                vertical-align: top;
                box-sizing: border-box;
                padding-left: 10px;
                .mini-cart-product-info {
                    box-sizing: border-box;
                    font-size: 12px;
                    line-height: 16px;
                    .attribute {
                        display: inline-block;
                    }
                    .total-price {
                        margin-top: 15px;
                    }
                    .price-standard {
                        text-decoration: line-through;
                        margin-right: 5px;
                        font-size: 13px;
                    }
                    .price-sales {
                        color: #b50000;
                        font-size: 13px;
                        letter-spacing: 0;
                    }
                    .label {
                        font-family: "Futura LT W01 Bold";
                        text-transform: uppercase;
                        display: inline-block;
                        letter-spacing: 1px;
                        font-size: 12px;
                    }
                    .mini-cart-name {
                        font-size: 13px;
                        text-transform: capitalize;
                        line-height: 18px;
                        font-weight: 500;
                        margin-bottom: 3px;
                        letter-spacing: 0;
                        color: #000;
                        a {
                            color: #000;
                        }
                    }
                    .mini-cart-brand {
                        text-transform: uppercase;
                        color: $brand-name-color;
                        font-size: 8px;
                        letter-spacing: 0px;
                    }
                    .mini-cart-pricing .price,
                    .mini-cart-pricing .product-price {
                        display: inline-block;
                        margin-right: 4px;
                    }
                    .product-price .price-standard {
                        color: #000;
                        text-decoration: line-through;
                    }
                    .mini-cart-pricing .product-price span.price-standard-exist {
                        color: #bd0304;
                    }
                    .mini-cart-totals {
                        text-align: left;
                        .mini-cart-subtotals {
                            display: block;
                            text-align: left;
                            border-top: 1px solid #efefef;
                            padding: 17px 0 0;
                        }
                    }
                    .mini-cart-subtotals {
                        font-size: 14px;
                        font-family: "Futura LT W01 Medium";
                        font-weight: 500;
                    }
                    .value,
                    .is-in-stock {
                        font-size: 12px;
                        text-transform: capitalize;
                    }
                    .color-value {
                        text-transform: capitalize;
                        display: block;
                    }
                    .mini-cart-attributes {
                        display: inline;
                    }
                    .mini-cart-pricing.qty {
                        display: inline-block;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .mini-cart-upper-cta {
        text-align: center;
        padding: 18px 18px 4px;
        display: flex;
        justify-content: space-between;

        .mini-cart-link-cart {
            flex-basis: 48%;
        }
    }
    .mini-cart-link-cart {
        padding: 16px 0;
    }
    .mini-cart-products-wrapper {
        flex: 1;
        overflow: hidden;
    }
    .mini-cart-totals {
        border-top: 1px solid $alto;
        flex: 1;
        flex-grow: 0;
        padding: 10px 18px;
        text-align: center;

        .minicartslot {
            display: inline-block;
            padding-top: 20px;
        }

        .mini-cart-subtotals {
            padding: 0 0 10px;
            font-weight: 700;
            margin: 0 auto;
            text-align: center;

            span {
                font-size: 14px;
                color: $black;
                text-transform: uppercase;
                display: inline-block;
                line-height: normal;
                &.label {
                    margin: 0 0.5rem;
                }

                &.value {
                    margin-right: 0.5rem;
                    font-weight: 500;
                }
            }
        }
    }
}

.header-search {
    color: $black;
    width: 100%;
    background-color: $white;
    padding-top: 10px;
    padding-bottom: 10px;
    @media screen and (min-width: 768px) {
        border-bottom: 1px solid $nobel;
        position: relative;
        z-index: 1;
    }
    form {
        margin-bottom: 1px;
    }
    input[type="text"] {
        background-color: transparent;
        border: none;
        float: left;
        margin: 0;
        height: 42px;
        text-transform: capitalize;
        color: $black;
        font-size: 16px;
        font-family: $WorkSans;
        padding-top: 0;
        padding-bottom: 0;
        &:focus {
            box-shadow: none;
        }
    }
    .simpleSearch {
        display: table;
        width: 100%;
        fieldset {
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
        }
        input[type="text"] {
            float: left;
            width: 75%;
            @include tablet-up {
                padding-right: 30px;
            }
        }
        button[type="submit"] {
            background-color: $black;
            color: $white;
            border: none;
            padding: 10px 5px;
            border-radius: 0;
            float: left;
            width: 25%;
        }
        .close-icon {
            position: relative;
            padding: 0;
            background: $white;
            float: left;
            border: none;
            width: 25px;
            height: 35px;
            margin-left: -30px;
            top: 3px;
            cursor: pointer;
            &:after {
                content: "x";
                display: block;
                width: 15px;
                height: 15px;
                position: absolute;
                z-index: 1;
                color: $black;
                right: 3px;
                top: 7px;
                margin: auto;
                padding: 2px;
                text-align: center;
                font-weight: normal;
                font-size: 12px;
                pointer-events: none;
                font-family: "Futura LT W01 Medium";
            }
        }
    }
}
.hamburger-help {
    span {
        font-family: HurmeSemiBold !important;
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
    }
    .hamburger-help-icon {
        background: url(../../../../images/help.svg) no-repeat;
        background-position: center;
        height: 20px;
        margin-bottom: 2px;
    }
}

.right-panel-pane {
    .card-promo-button {
        display: block;
        text-align: center;
        padding: 14px 10px;
        text-transform: uppercase;
        border: 1px solid #000;
        border-radius: 0;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 1.2px;
        transition: background 200ms, border 200ms, color 200ms;

        &.card-promo-button-primary {
            background: #000;
            color: #fff;
            &:hover {
                background: #1b1b1b;
                border: 1px solid #1b1b1b;
            }
        }
        &.card-promo-button-secondary {
            background: #fff;
            color: #000;
            &:hover {
                background: #eaeaea;
            }
        }
    }
}
.right-panel-pane-card {
    .card-promo-image img {
        max-width: 240px;
    }
    .card-promo-button-wrapper {
        margin-bottom: 12px;
    }

    hr {
        border: none;
        border-top: 1px solid #bababa;
        margin: 12px 0;
    }
    h4 {
        font-size: 20px;
        margin: 20px 0;
    }
    .card-promo-list {
        margin: 0;
        padding: 0 15px;
        li {
            position: relative;
            padding: 0 0 0 55px;
            min-height: 42px;
            margin: 0 0 20px;
            .card-promo-list-image {
                position: absolute;
                top: 0;
                left: -8px; //Icon .PNGs not cropped tight, compensate for whitespace
                width: 56px;
            }
        }
        h5 {
            font-size: 15px;
            margin: 0 0 5px;
            letter-spacing: 0.3px;
        }
        p {
            color: #474747;
            margin: 0;
            font-size: 17px;
        }
    }
    .card-promo-link {
        a {
            display: inline-block;
            position: relative;
            color: #474747;
            text-transform: uppercase;
            overflow: visible;
            font-size: 16px;
            font-weight: 600;
            &::after {
                content: "";
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
                border-bottom: 2px solid #474747;
            }
            &:hover {
                color: #000;
                &::after {
                    border-bottom: 2px solid #000;
                }
            }
        }
    }
}
.user-info.info {
    display: block;
    padding: 18px 12px;

    @media (max-width: 767px) {
        margin: 0;
        padding: 0;
    }

    .user-panel {
        &.unregistered-user {
            .login-box {
                padding-bottom: 15px;

                a#password-reset {
                    font-size: 12px;
                    letter-spacing: 0.9px;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            .login-data {
                line-height: 20px;
                letter-spacing: -0.28px;
                font-size: 12px;
                padding: 0 0 15px 0;
                margin-bottom: 30px;
                border-bottom: 1px solid #d1d1d1;
            }

            .create-account-section {
                a {
                    display: block;
                    border: 2px solid #ececec;
                    width: 100%;
                    height: 50px;
                    position: relative;
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    margin-bottom: 5px;

                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        white-space: nowrap;
                    }
                }
            }

            .trackOrder {
                border-top: solid 1px #d1d1d1;
                padding-top: 26px;
                margin-top: 20px;
                a {
                    display: block;
                    text-align: center;
                    line-height: 45px;
                    color: #fff;
                    font-size: 14px;
                    letter-spacing: 2px;
                    border: 0;
                    background-color: #000;
                    text-transform: uppercase;
                    font-weight: 900;
                    font-family: "Hurme Geometric Sans_1 W01 Blk";
                }
            }
        }

        &.registered-user {
            @media (max-width: 767px) {
                display: block;
            }

            .my-account.heading {
                padding: 0 10px;
                letter-spacing: normal;
                font-size: 14px;

                @media (max-width: 767px) {
                    height: 45px;
                    display: flex;
                    font-size: 20px;
                    flex-direction: column;
                    justify-content: center;
                    border-top: 1px solid #e5e5e5;
                    background: #f4f4f4;
                    padding: 0 20px;
                }

                i {
                    display: none;
                    @media (max-width: 767px) {
                        display: inline;
                        margin-right: 20px;
                        font-size: 25px;
                    }
                }

                @media (max-width: 1023px) {
                    &.my-account-heading-desktop {
                        display: none;
                    }
                }
                @media (min-width: 1024px) {
                    &.my-account-heading-mobile {
                        display: none;
                    }
                }
            }

            .account-links {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                @media (max-width: 767px) {
                    margin-top: 0;
                }
                a {
                    background: transparent;
                    height: 45px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    padding: 0 10px;
                    border-bottom: 2px solid #d1d1d1;
                    font-weight: 600;
                    font-size: 15px;
                    margin: 0;
                    box-sizing: border-box;

                    @media (max-width: 767px) {
                        position: relative;
                        width: 100%;
                        &::after {
                            font-family: FontAwesome;
                            position: absolute;
                            right: 10px;
                            content: "\f105";
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 25px;
                        }
                    }

                    span {
                        display: block;
                        box-sizing: border-box;
                        position: relative;
                        padding-left: 30px;
                        line-height: 20px;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }
        }

        .form-row {
            margin: 0 0 20px 0;
            position: relative;
            &.username label:not(.input-focus),
            &.password label:not(.input-focus) {
                transform: translate(10px, -50%);
                top: 50%;
            }

            &.username label.input-focus,
            &.password label.input-focus {
                transform: translate(10px, 3px);
            }

            > label {
                position: absolute;
                z-index: 100;
                top: 2px;
            }

            > button {
                border-radius: 0;
                width: 100%;
                border: none;
                height: 50px;
                margin-top: 10px;
                padding: 0;
                background: black;
                outline-offset: -3px;
            }

            .field-wrapper {
                margin: 0;
                font-family: "Work Sans", sans-serif;
                font-weight: 500;
                font-size: 12px;
                position: relative;

                input.input-text {
                    border-radius: 5px;
                    height: 44px;
                }

                button.pwd-show {
                    padding: 18px;
                    background: transparent;
                    border: none;
                    color: #000;
                    font-family: "Work Sans", sans-serif;
                    font-weight: 600;
                    font-size: 12px;
                    position: absolute;
                    top: 0px;
                    right: -14px;
                    -webkit-transform: translate(-14px, 0);
                    transform: translate(-14px, 0);
                    z-index: 1;
                    cursor: pointer;
                    width: auto;
                    outline-offset: -3px;
                }
            }

            &.login-rememberme {
                display: inline-block;
                width: 50%;
                font-size: 12px;
                position: relative;

                .input-checkbox {
                    outline: none;
                }

                .field-wrapper {
                    input.input-checkbox {
                        outline: 1px solid black;
                    }
                }

                label {
                    top: 0;
                    transform: none;
                    left: auto;
                    line-height: 20px;
                    span {
                        font-family: "Futura LT W01 Medium", Arial, sans-serif;
                        font-size: 12px;
                        font-weight: 500;
                        letter-spacing: -0.33px;
                        color: #000000;
                    }
                }
            }
        }
    }
}

.header-offers {
    width: auto;
    display: inline-block;
    position: relative;
    margin-right: 25px;
    &:hover,
    &.DelayHover {
        a {
            &.offer-flyout {
                border: 1px solid $mercuryy;
                border-bottom-color: $white;
                background: $white;
            }
        }
        .offers-flyout-banner {
            display: block;
        }
    }
    a {
        &.offer-flyout {
            position: relative;
            z-index: 22;
            padding: 20px 32px 20px 50px;
            display: inline-block;
            border: 1px solid transparent;
            border-bottom-color: transparent;
        }
    }
    .offer-flyout-main {
        .offer-flyout {
            width: auto;
            float: left;
            padding-right: 4px;
            div.ro-offers img {
                width: 16px;
                height: 19px;
                @media screen and (max-width: 767px) {
                    display: block;
                }
            }
        }
        span {
            &.offers {
                font-size: $base-font - 1px;
                text-transform: uppercase;
                line-height: 1em;
                letter-spacing: 1px;
                display: inline-block;
                @media screen and (max-width: 767px) {
                    line-height: 19px;
                }
            }
        }
    }
    .offers-flyout-banner {
        display: none;
        text-align: left;
        position: absolute;
        left: 0;
        top: 62px;
        color: $black;
        border-top: none;
        width: 330px;
        z-index: 20;
        @include prefix(transition, all 0.3s ease, $prefixArray);
        @include prefix(border-radius, 0, $prefixArray);
        box-shadow: none;
        margin-top: 0;
        background: $white;
        padding: 20px;
        @include prefix(box-sizing, border-box, $prefixArray);
        border: 1px solid $mercuryy; //PXSFCC-1446
        .header-offers-promotion {
            .winter-promo {
                padding: 0 0 7px 0;
            }
            .first-order-promo {
                padding: 0 0 17px 0;
            }
        }
        .viewall-offers {
            a {
                font-size: $base-font + 3px;
                padding: 13px 18px 14px;
                border: 1px solid $dim-gray;
                letter-spacing: 1px;
                border-top-left-radius: 15px;
                border-bottom-right-radius: 15px;
                text-align: center;
                float: left;
                width: 100%;
            }
        }
    }
}

.pt_checkout {
    #header {
        .fixed-header {
            position: fixed;
            width: 100%;
            background: $white;
            z-index: 99;
            top: 0;
            left: 0;
            height: auto;
        }
        .top-banner {
            overflow: visible;
            height: auto;
            width: 100%;
            position: initial;
            background: transparent;
        }
        .header-mian-banner {
            width: 100%;
            box-sizing: border-box;
            position: relative;
            max-width: 1260px;
            margin: 0 auto;
            padding: 10px;
            border-bottom: 1px solid $checkout-header-border-color;
            top: 0 !important;
            box-shadow: none;
        }
        .primary-logo {
            a {
                width: 226px;
                display: inline-block;
                @media only screen and (min-width: 768px) and (max-width: 1023px){
                    width: 135px;
                }
                svg {
                    max-height: 35px;
                    @media only screen and (min-width: 768px) and (max-width: 1023px){
                        margin-left: 10px;
                    }
                }
            }
        }
        .shopping-bag-id {
            display: block;
            @media only screen and (min-width: 768px) and (max-width: 1023px){
                 width: 100%;
            }
        }
    }
    .checkout-progress-indicator-wrapper {
        display: block;
        margin: 0;
        padding: 0;
        position: relative;
        .progress-count {
            display: inline-block;
            width: 22px;
            height: 22px;
            background: $black;
            border: 1px solid $black;
            border-radius: 12px;
            color: $black;
            text-align: center;
            padding: 3px 0 0;
            box-sizing: border-box;
            margin-right: 6px;
        }
        .progress-text {
            width: 90px;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 0;
            @media screen and (max-width: 767px) {
                width: auto;
            }
        }
        .checkout-progress-indicator {
            display: table;
            width: 100%;
            margin: 0;
            padding: 0;
        }
        .step-1,
        .step-2,
        .step-3 {
            display: table-cell;
            vertical-align: middle;
            width: 33.3%;
            position: relative;
            height: 20px;
            font-size: 12px;
            &.submitted {
                &:hover {
                    .progress-count {
                        background: $black;
                        color: $white;
                    }

                    .progress-text {
                        color: $shipping-progress-active-bg-color;
                    }
                }
            }
            @media screen and (max-width: 767px) {
                width: auto;
                &.inactive {
                    width: 50px;
                    text-align: center;
                    .progress-text {
                        display: none;
                    }
                }
            }
            &.inactive {
                &:not(.submitted) a {
                    cursor: default;
                }
                .progress-text,
                .progress-count {
                    color: $wcag-grey;

                    @media screen and (max-width: 767px) {
                        color: $black;
                    }
                }
                .progress-count {
                    background: $white;
                    border: 1px solid $wcag-grey;
                    color: $wcag-grey;
                }
            }
            &.active {
                .progress-text {
                    color: $shipping-progress-active-color;
                }
                .progress-count {
                    color: $white;
                }
            }
        }
        .step-1,
        .step-2 {
            &.submitted {
                .progress-count {
                    background: $wcag-grey;
                    border: 1px solid $wcag-grey;
                    color: $white;
                }
            }
        }
        a {
            color: $night-rider;
        }
        i {
            padding-left: 0.83em;
            color: $very-light-gray;
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_checkout {
        #header {
            .header-mian-banner {
                .primary-logo,
                .chk-out-header-right-section {
                    display: block;
                    height: auto;
                }
                .primary-logo {
                    box-sizing: border-box;
                    padding-right: 120px;
                    margin: 0;
                }
                .help-click {
                    position: absolute;
                    top: 24px;
                    right: 10px;
                }
                .checkout-progress-indicator-wrapper {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
            .chk-out-header-right-section {
                .chk-out-header-top-content {
                    .content {
                        display: none;
                    }
                }
                &.active {
                    .chk-out-header-top-content {
                        .content {
                            padding: 5px 0 15px;
                            display: block;
                            text-align: right;
                            border-bottom: 1px solid $checkout-header-border-color;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .pt_checkout {
        #header {
            .header-mian-banner {
                display: table;
                padding-top: 7px;
                padding-bottom: 7px;
                .primary-logo,
                .checkout-progress-indicator-wrapper {
                    display: table-cell;
                    vertical-align: middle;
                    box-sizing: border-box;
                }
                .checkout-progress-indicator-wrapper {
                    padding-left: 20px;
                    padding-right: 240px;
                }
                .primary-logo {
                    width: 147px;
                }
                .chk-out-header-right-section {
                    position: absolute;
                    top: 0;
                    right: 10px;
                    width: 225px;
                    height: 100%;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .checkout-progress-indicator-wrapper {
            .checkout-progress-indicator {
                max-width: 700px;
            }
            .progress-count {
                position: absolute;
                top: 2px;
                left: 0;
            }

            .step-wrapper {
                position: relative;
                padding-left: 30px;
                max-width: 120px;
                margin: 0 auto;
                box-sizing: border-box;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .pt_checkout {
        #header {
            .header-mian-banner {
                .checkout-progress-indicator-wrapper {
                    padding-left: 40px;
                }
            }
        }
    }
}

.right-panel-pane-cart {
    .mini-cart-products {
        .mini-cart-product {
            margin-bottom: 14px;
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 14px;
            &:first-child {
                padding-top: 14px;
            }
            &.sw-special-product-set-overlay {
                .left-content .mini-cart-image {
                    padding-top: 0;
                }
                .mini-cart-item-row {
                    padding-bottom: 14px;
                }
                .right-content {
                    .mini-cart-product-info {
                        .mini-cart-brand {
                            font-size: 8px;
                            color: $brand-name-color;
                            letter-spacing: 0;
                            @include WorkSansMedium($WorkSans);
                        }

                        .mini-cart-name {
                            font-size: 13px;
                            line-height: 18px;
                            letter-spacing: 0;
                            @include WorkSansMedium($WorkSans);
                            margin-bottom: 10px;
                            a {
                                color: #000;
                            }
                        }

                        .special-productset-child-wrapper {
                            .mini-cart-attributes,
                            .availability-msg span {
                                font-size: 12px;
                                letter-spacing: 0;
                                font-family: $WorkSans;
                                color: #000;
                                text-transform: capitalize;
                                font-weight: normal;
                            }

                            .availability-msg {
                                margin-bottom: 10px;
                                b {
                                    font-size: 12px;
                                    font-weight: 500;
                                    text-transform: capitalize;
                                }
                                .value {
                                    span {
                                        font-weight: 500;
                                        text-transform: capitalize;
                                        line-height: 18px;
                                    }
                                    &.in-stock-msg,
                                    &.backorder-msg,
                                    b {
                                        font-weight: normal;
                                        span {
                                            font-weight: normal;
                                        }
                                    }
                                }
                            }

                            .mini-cart-pricing {
                                &.qty {
                                    margin-left: 0;
                                    span {
                                        font-size: 12px;
                                        color: #000;
                                        letter-spacing: 0;
                                        font-family: $WorkSans;
                                    }
                                }
                            }
                        }
                        .mini-cart-pricing {
                            span {
                                font-size: 13px;
                                font-family: $WorkSans;
                                &.price-diff {
                                    text-decoration: line-through;
                                }
                                &.sales {
                                    color: #b03434;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//SC-3513
@media screen and (min-width: 1024px) {
    .menu-category {
        .hoverIntent {
            .level-2 {
                &.new-mega-menu-L2 {
                    border-top: 1px solid #dcdcdc;
                }
                .new-menu {
                    overflow-y: hidden;
                    overflow-x: hidden;
                    min-height: 325px;
                    height: auto;
                    width: 97.5%;
                    margin-top: 15px;
                    border-top: 0;
                    @media screen and (max-width: 1042px) {
                        width: 98%;
                    }

                    &::-webkit-scrollbar {
                        width: 4px;
                    }
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 4px $white;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #9e9e9e;
                        border-radius: 2px;
                    }
                    .sub-cat-wrapper {
                        &.new-mega-menu {
                            padding: 0;
                            border: 0;
                            height: auto;
                            max-width: 1260px;
                            min-width: 1024px;
                            .sub-cat-nav {
                                display: flex;
                                width: 1024px;
                                margin: 9px auto 24px auto;
                                height: auto;
                                ul.nav-column {
                                    width: 100%;
                                    float: left;
                                    padding: 0;
                                    margin: 0;
                                    line-height: 0;
                                    a {
                                        text-transform: uppercase;
                                        letter-spacing: 0.45px;
                                        font-size: 12px;
                                        line-height: 20px;
                                        display: block;
                                        height: auto;
                                    }
                                    .boldCat {
                                        &.parent-level3 {
                                            margin-bottom: 0;
                                        }
                                    }
                                    li {
                                        padding: 0;
                                        margin: 0;
                                        line-height: 0;
                                        width: 140px;
                                    }
                                }
                                ul.ul-level2 {
                                    padding: 0 15px;
                                    height: auto;
                                    width: 170px;
                                    li.parent-level2 {
                                        + li.parent-level2 {
                                            > .boldCat {
                                                margin-top: 20px;
                                            }
                                            &.only-L2-cat {
                                                > .boldCat {
                                                    margin-top: 8px;
                                                }
                                                &.L2-cat-after-L3 {
                                                    > .boldCat {
                                                        margin-top: 20px;
                                                    }
                                                }
                                            }
                                            margin-top: 8px;
                                        }
                                    }
                                }
                                ul.ul-level3,
                                ul.ul-level4,
                                ul.ul-level5 {
                                    width: 100%;
                                    margin-top: 8px;
                                    float: none;
                                    > li {
                                        width: 100%;
                                        + li {
                                            margin-top: 8px;
                                        }
                                    }
                                }
                                .carousel-mm-recommendations {
                                    display: inline;
                                    width: 170px;
                                    float: left;
                                    min-height: 325px;
                                    height: auto;
                                    padding: 0 15px;
                                    font-family: inherit;
                                    box-sizing: border-box;
                                    &.recomm-container {
                                        $recomm-base-width: 170px;
                                        $product-tile-width: 150px;

                                        margin-top: 4px;

                                        @media (max-width: 1023px) {
                                            display: none;
                                        }

                                        &::before {
                                            content: attr(title);
                                            display: block;
                                            font-weight: 800;
                                            font-size: $base-font;
                                            line-height: 12px;
                                            margin-bottom: 5px;
                                        }

                                        @for $i from 1 through 5 {
                                            &.recomm-col-#{$i} {
                                                width: $i * $recomm-base-width;
                                            }
                                        }
                                        li.product-tile {
                                            display: inline-block;
                                            margin-right: 20px;
                                            width: $product-tile-width;
                                            vertical-align: top;
                                            border: 0px;

                                            &:last-child {
                                                margin: 0px;
                                            }

                                            .product-name-image-container .thumb-link {
                                                width: 100%;
                                            }
                                        }
                                    }
                                    box-sizing: border-box;
                                    .product-tile {
                                        .product-name-image-container {
                                            .thumb-link {
                                                width: 170px;
                                                box-sizing: border-box;
                                                img {
                                                    width: 100%;
                                                    height: auto;
                                                }
                                            }
                                            .product-name,
                                            .product-brand {
                                                text-transform: uppercase;
                                                letter-spacing: normal;
                                                font-size: 9px;
                                                margin: 5px 0;
                                                line-height: 1.2;
                                                text-align: center;
                                            }
                                            .product-name {
                                                font-size: $base-font - 2px;
                                            }
                                        }
                                        .product-promo {
                                            color: #008000;
                                            line-height: 1.2;
                                            text-align: center;
                                            margin: 0;
                                            font-size: 10px;
                                            letter-spacing: normal;
                                            .promotional-message {
                                                color: #008000;
                                            }
                                        }
                                        .product-pricing {
                                            margin: 0;
                                            text-align: center;
                                            font-size: 10px;
                                            letter-spacing: normal;
                                            line-height: 1.2;

                                            .price-product-sales-price,
                                            .price-product-standard-price {
                                                letter-spacing: normal;
                                                font-size: 10px;
                                                margin: 0;
                                                font-weight: 500;
                                                display: inline-block;
                                            }
                                        }
                                    }
                                }
                                .banner {
                                    display: inline;
                                    padding: 0;
                                    min-height: 325px;
                                    .module-mega-menu {
                                        display: inline;
                                        width: 170px;
                                        max-width: 170px;
                                        padding: 0 15px;
                                        min-height: 325px;
                                        font-family: inherit;
                                        box-sizing: border-box;
                                        @media screen and (max-width: 1042px) {
                                            width: 158px;
                                            max-width: 158px;
                                        }
                                        &.two-col-one-promo {
                                            width: 340px;
                                            max-width: 340px;
                                            @media screen and (max-width: 1042px) {
                                                width: 315px;
                                                max-width: 315px !important;
                                            }
                                            span {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header-sticky-desktop {
        &.new-sticky-mega-menu {
            #navigation,
            .top-menu-utility-wrapper {
                max-width: 100%;
            }
            .menu-category {
                max-width: 1260px;
                margin: 0 auto;
                .hoverIntent {
                    .level-2 {
                        .new-menu {
                            width: 97.5%;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .header-sticky-mobile {
        .top-menu-utility-wrapper {
            .secondary-header {
                ul {
                    margin-top: 0;
                }
            }
        }

        .ucp2-cart-with-item-icon .minicart-quantity{
            top: -6px;
            right: -2px;
        }
    }

    body:not(.header-mobile-menu-open):not(.header-sticky-mobile) {
        .top-menu-utility {
            .header-menu-mobile-tablet{
                height: auto;
            }

            ~ .top-banner {
                height: 22px;
            }
        }
    }

    body:not(.header-sticky-mobile) {
        .secondary-header {
            padding: 0;
            margin-bottom: -16px;
            ul {
                height: 30px;
                margin-top: 0;
                li {
                    &.header-search-util,
                    &.secondary-catalog,
                    &.secondary-account,
                    &.shopping-bag-sm,
                    &.secondary-card {
                        width: auto;
                        padding: 0 2px;
                        a {
                            overflow: visible;
                            flex-direction: row;
                            span[class^="ucp2-"] {
                                margin: 0;
                                align-self: center;
                                &.ucp2-search-icon {
                                    width: 18px;
                                }
                                &.ucp2-catalog-icon,
                                &.ucp2-account-icon {
                                    width: 19px;
                                }
                                &.ucp2-card-icon,
                                &.ucp2-cart-icon {
                                    width: 23px;
                                }
                                &.ucp2-cart-with-item-icon {
                                    width: 25px;
                                }
                                &.ucp2-cart-icon,
                                &.ucp2-cart-with-item-icon {
                                    transform: translateY(-1px);
                                }
                                + span {
                                    white-space: nowrap;
                                    align-self: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .recomm-container {
        display: none;
    }
}

// end SC-3513
// WCAG - skip to header functionality and added accessibility link on focus
.skip-section {
    max-width: 1260px;
    margin: 0 auto;
}
.skip:focus,
.link-accessibility:focus {
    position: static;
    padding: 0 10px;
    line-height: 25px;
}
.link-accessibility {
    margin-left: 100px;
}

/* Disable Monetate Banner to show, if exists */
#nanobar-container,
div[id^="monetate_selectorHTML"] {
    display: none;
}
.or-separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 10px;
  }
.or-separator::before,
.or-separator::after {
content: '';
flex: 1;
border-bottom: 1px solid #aaa;
}
.or-separator:not(:empty)::before {
margin-right: .25em;
}
.or-separator:not(:empty)::after {
margin-left: .25em;
}