/*	breadcrumbs css start */
.breadcrumb {
	position: absolute;
	width: auto;
	font-size: $base-font - 1px;
	display: block;
	padding: 5px;
	margin: 10px;
	color: $black;
	text-transform: uppercase;
	background: $zircon;
	letter-spacing: 0.109em;
	z-index: 2;
	text-align: left;

	ol {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;

			+ li::before {
				content: "/";
				margin: 0 0.5em;
				height: 0.8em;
				color: $black;
				@include mobile {
					display: none;
				}
			}

			a {
				font-size: inherit;
				&.breadcrumb-element,
				&.breadcrumb-element.current-element {
					cursor: pointer;
				}
			}
		}
	}

	&.nobanner-slot {
		position: relative;
		background: transparent;
		width: 100%;
		margin: 0;
		padding: 17px 10px;
		box-sizing: border-box;
		border-top: 1px solid $alto;
	}

	h1 {
		display: inline;
	}

	.breadcrumb-element {
		padding: 0;

		&:first-child {
			padding: 0;
		}
		&.current-element {
			color: $bread-crumb-font-color;
			font-size: inherit;
			&:after {
				content: "";
				margin: 0;
			}
		}
	}

	/* breadcrumb tablet media query start */
	@media screen and (max-width: 1023px) {
		position: relative;
		padding: 10px 0 10px 10px;
		border-bottom: 1px solid $alto;
		margin: 0;
		border-top: 1px solid $alto;
		text-align: left;
		z-index: 0;
		background: $white;
		.breadcrumb-element {
			display: inline;
			margin: 4px 0;
			&:last-child {
				font-weight: normal;
				vertical-align: top;

				&:after {
					content: "";
					margin: 0;
				}
			}
			&.hide-tablet {
				display: none;
			}
		}

		&.quick-order-breadcrumb {
			padding: 10px 0 10px 30px;
			.breadcrumb-element {
				overflow: auto;
			}
		}
	}
	/* breadcrumb tablet media query end */

	/* breadcrumb mobile media query start */
	@media screen and (max-width: 767px) {
		position: relative;
		margin: 0;
		padding: 5px 10px;
		border-top: 1px solid $alto;
		z-index: 0;
		background: $white;
		.breadcrumb-element {
			display: inline-block;
			&:last-child {
				font-weight: normal;

				&:after {
					content: "";
				}
			}
			&.hide-mobile {
				display: none;
			}

			&.hide-tablet {
				display: inline-block;
			}
			&.current-element {
				margin: 4px;
				vertical-align: top;
			}
		}

		.back-arrow {
			background: url("../../images/icon_leftarrownavsec.svg") no-repeat;
			width: 16px;
			height: 23px;
			display: inline-block;
			text-indent: -9999px;
			vertical-align: top;
			// below property is commented to fix mobile issue.
			&.iphonebackarrow {
				background-position: 0 -3px;
			}
		}

		&.quick-order-breadcrumb {
			padding: 5px 10px;
			border-bottom: 1px solid $alto;
			.breadcrumb-element {
				&:after {
					content: "";
					margin: 0;
				}
			}
		}
	}
	/* breadcrumb mobile media query end */
}
/*page  breadcrumb override style start*/
.pt_account,
.pt_order,
.adjust-inner-content,
.pt_customer-service,
.pt_article-page,
.pt_specials,
.pt_content,
.pt_wish-list,
.pt_categorylanding {
	.breadcrumb {
		position: relative;
		padding: 10px 0 10px 10px;
		margin: 0 auto;
		border-top: 1px solid $alto;
		background: $white;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		@media screen and (max-width: 1023px) {
			margin: 10px auto;
			background: $white;
		}
		@media screen and (max-width: 767px) {
			margin: 10px 0 0;
			padding: 12px 10px 5px 10px;
			background: $white;
		}
	}
}
.pt_product-details .breadcrumb {
	border-bottom: none;
	@media screen and (max-width: 767px) {
		border-bottom: 1px solid $alto;
		border-top: none;
		padding: 10px;
		margin: 0 -10px;
	}
}

.pt_content {
	.breadcrumb {
		padding: 10px 0 10px 20px;

		@media screen and (max-width: 1023px) {
			margin: 0 auto;

			.breadcrumb-element {
				margin: 0;
			}
		}

		@media screen and (max-width: 767px) {
			padding: 5px 10px;

			.breadcrumb-element {
				margin: 4px 0;
			}
		}
	}
}
/* page  breadcrumb override style end*/

/*	breadcrumbs css end */
@media only screen and (width: 800px) {
	.pt_product-details #main .adjust-inner-content,
	.pt_product-search-result.product-list-page .content-banner-breadcrumb .breadcrumb {
		margin: 40px 0 0 auto !important;
	}
}
