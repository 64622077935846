.addtocartoverlay-products {
	.mini-cart-product {
		display: table;
		width: 100%;
		margin: 10px 0 20px;
		.left-content {
			display: table-cell;
			vertical-align: top;
			text-align: center;
			width: 50%;
			box-sizing: border-box;
			.mini-cart-image a, .mini-cart-image a:focus {
				display:block;
				box-shadow: none;
			}
			img {
				max-width: 209px;
				width: 100%;
			}
		}
		.right-content {
			display: table-cell;
			vertical-align: top;
			width: 50%;
			padding: 0 40px 0 20px;
			box-sizing: border-box;
			font-size: 12px;
			.mr8 {
				margin-right: 8px;
				@media screen and (max-width: 767px) {
					margin-right: 5px;
				}
			}
			.finalSale-sps {
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 0.3px;
				color: #af3434;
				text-transform: uppercase;

				@media screen and (max-width: 767px) {
					font-size: 11px;
					letter-spacing: 0.24px;
				}
			}

			.attribute {
				margin-bottom: 15px;

				.final-sale-color {
					display: inline-flex;
					margin-right: 10px;
					@media screen and (max-width: 767px) {
						display: unset;
						width: unset;
					}
				}

				.finalSale {
					letter-spacing: 0.59px;
					color: #af3434;
					margin-left: 5px;
					text-transform: uppercase;

					@media screen and (max-width: 767px) {
						vertical-align: top;
						display: block;
						width: 100%;
						margin-left: unset;
					}
				}
			}
			.mini-cart-pricing,
			.product-availability-list,
			.total-price {
				margin-bottom: 15px;
			}
			.total-price {
				margin-top: 15px;
			}
			.label {
				text-transform: uppercase;
				display: inline-block;
				letter-spacing: 1px;
				font-size: 12px;
				font-weight: bold;
			}
			.mini-cart-name {
				font-size: 27px;
				margin-bottom: 16px;
				padding-bottom: 10px;
				border-bottom: 1px solid #efefef;
				text-transform: capitalize;
			}
			.mini-cart-brand {
				text-transform: uppercase;
				color: $brand-name-color;
				font-size: 10px;
			}
			.mini-cart-pricing .price,
			.mini-cart-pricing .product-price {
				display: inline-block;
				margin-right: 4px;
			}
			.product-price .price-standard {
				color: #000;
				text-decoration: line-through;
			}
			.mini-cart-pricing .product-price span.price-standard-exist {
				color: #bd0304;
			}
			.mini-cart-totals {
				text-align: left;
				padding: 0;
				.mini-cart-subtotals {
					display: block;
					text-align: left;
					border-top: 1px solid #efefef;
					padding: 17px 0 0;
				}
			}
			.mini-cart-subtotals {
				font-size: 12px;
				font-weight: 500;
			}
			.value,
			.is-in-stock {
				font-size: 12px;
				text-transform: uppercase;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.addtocartoverlay-products {
		.mini-cart-product {
			.left-content {
				width: 30%;
			}
			.right-content {
				width: 70%;
			}
		}
	}
}
