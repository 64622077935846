.plp-intl-not-avail{
    max-width: 742px;
    margin: 0 auto;
    
    .intl-msg-heading{
        font-family: $FrutigerBold;
        font-size: ($base-font*2) + 4px;
        text-align: left;
        line-height: 32px;
        letter-spacing: 0;
        color: $black;        
    }
    .intl-msg-body,.intl-msg-learn-more{
        font-family: $Frutiger;
        font-size: $base-font + 6px;
        text-align: left;
        line-height: 26px;
        letter-spacing: 0;
        color: $black;
        .faq-link{
            color: $cobalt-blue;
            text-decoration: none;
            &:hover,&:focus{
                color: $cobalt-blue;
                text-decoration: none;
            }
        }
    }
    .intl-msg-learn-more{
        margin-top: 20px;
    }
    @media screen and (max-width:767px){
        max-width: calc(100% - 24px);
        margin: 0 12px;
        .intl-msg-heading{
            font-size: $base-font*2;
        }        
    }
}