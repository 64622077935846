$mobileBreakPoint: 768px;
$tabletBreakPoint: 1024px;
$cartTabletBreakPoint: 1023px;
$customBreakPoint: 1160px;
$leftArrow_black: url("../../images/Left_ArrowBlack.svg") no-repeat center right / contain !default;
$leftArrow_gray: url("../../images/Left_ArrowGray.svg") no-repeat center right / contain !default;
$rightArrow_black: url("../../images/Right_ArrowBlack.svg") no-repeat center left / contain !default;
$rightArrow_gray: url("../../images/Right_ArrowGray.svg") no-repeat center left / contain !default;
@mixin mainStylesforCertona {
}
@mixin HomepageStyles() {
    background: transparent;
    width: 100%;
    float: none;
    margin: 40px auto 20px;
    display: block;
    max-width: 100%;
    max-height: inherit;
    ul.carousel-recommendations,
    ul.slick-track {
        padding: 0;
    }
    .carousel-recommendations,
    .search-result-items {
        .product-tile,
        .grid-tile {
            display: inline-block;
            margin: 0 auto;
            padding: 0 10px 10px;
            min-height: 275px;
            max-width: inherit;
            &:hover .quickview {
                display: block;
            }
            //Media Queries for ipad pro multiple versions feature/SC-2611
            @media screen and (min-width: $mobileBreakPoint) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
                &:hover .quickview {
                    display: none;
                }
            }
            .product-tile {
                display: block;
                margin: 0 auto;
                padding: 0;
                min-height: inherit;
                max-width: inherit;
            }
            .quickview {
            }
            .product-name-image-container {
                max-width: inherit;
                margin: 0 auto;
                .product-image {
                    max-width: 220px;
                    margin: 0 auto;
                    min-height:300px;
                    img{
                        max-height:300px;
                    }

                }
                .name-link {
                    display: inline-block;
                }
            }
        }
        .product-tile {
            &.product-set {
                .quickview {
                    display: none !important;
                }
            }
        }
        button {
            border: none;
            border-radius: 0;
            color: transparent;
            display: inline-block;
            padding: 0;
            margin: 0 auto;
            position: absolute;
            width: 23px;
            height: 23px;
            transform: translateY(-50%);
            top: 35%;
            &.slick-arrow {
                margin: 0 auto;
                padding: 12px;
                text-indent: 100%;
                overflow: hidden;
                white-space: nowrap;
                &.slick-next {
                    left: 100%;
                    background: $rightArrow_black;
                    margin-left: -13px;
                    &.slick-disabled {
                        background: $rightArrow_gray;
                    }
                }
                &.slick-prev {
                    right: 100%;
                    background: $leftArrow_black;
                    margin-right: -13px;
                    &.slick-disabled {
                        background: $leftArrow_gray;
                    }
                }
            }
            @media screen and (max-width: $tabletBreakPoint) {
                &.slick-arrow {
                    &.slick-next {
                        margin-left: -17px;
                    }
                    &.slick-prev {
                        margin-right: -17px;
                    }
                }
            }
        }
        ul {
            &.slick-dots {
                list-style-type: none;
                position: relative;
                margin: 0 auto;
                width: 100px;
                @media screen and (max-width: $mobileBreakPoint) {
                    display: table;
                    z-index: 3;
                    padding: 0;
                    max-width: 75%;
                    margin: 10px auto;
                    border-spacing: 4px 4px;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;

                    li {
                        display: table-cell;
                        margin: 0 auto;
                        padding: 0;
                        button,
                        button[type="button"] {
                            position: relative;
                            border: none;
                            border-radius: 100%;
                            width: 5px;
                            height: 5px;
                            padding: 2.5px;
                            background: $black;
                            margin: 0 auto;
                            vertical-align: middle;
                            color: transparent;
                            box-sizing: border-box;
                            -webkit-box-sizing: border-box;
                            -moz-box-sizing: border-box;
                        }
                        &.slick-active button {
                            background: $light-gray;
                            color: transparent;
                        }
                    }
                }
            }
        }
    }
    .recommendation-heading span {
        font-size: 22pt;
        letter-spacing: 1px;
        &.trending {
            margin: 0 auto;
            color: $recommendation-heading;
        }
    }
    .recommendation-heading {
        margin: 0 auto;
        padding: 20px 0;
        color: $recommendation-heading;
    }
    .product-name-image-container {
        max-width: inherit;
        margin: 0 auto;
        .product-image {
            max-width: 220px;
            margin: 0 auto;
        }
        .product-image img {
            width: 100%;
            height: auto;
            text-indent: -9999px;
            margin: 0 auto;
        }
    }
    .carousel-recommendations .product-tile .product-swatches {
        display: none;
    }
    .product-promo {
        display: none;
    }
    @media screen and (max-width: $tabletBreakPoint) {
        .carousel-recommendations {
            padding: 0 20px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            .product-tile {
                padding: 0 20px;
                .product-name {
                    margin: 20px auto 0;
                }
                .product-image {
                    margin: 0 auto;
                    img {
                        margin: 0 auto;
                    }
                }
                .quickview {
                    width: calc(100% - 30px);
                    top: auto;
                    bottom: 10px;
                    cursor: pointer;
                    max-width: inherit;
                }
                .product-pricing {
                    margin: 0 auto;                    
                    .product-sales-price,
                    .product-standard-price {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    @media screen and (max-width: $mobileBreakPoint) {
        .carousel-recommendations {
            padding: 0;

            .product-tile {
                display: inline-block;
                margin: 0 auto;
                padding: 0 10px 0 0;
                max-width: inherit;
                .product-name {
                    font-size: $base-font + 8px;
                    margin: 20px auto 0;
                }
                .product-image {
                    max-width: initial;
                    img {
                        margin: 0 auto;
                        width: 100%;
                        max-width: 220px;
                    }
                }
                .product-pricing {
                    font-size: $base-font + 3px;
                    margin: 0.5em auto 0;
                    .product-sales-price,
                    .product-standard-price {
                        margin: 0 auto;
                    }
                }
                #quickviewbutton {
                    display: none;
                }
            }
        }
        .tiles-container.recommendation-tiles {
            max-width: 100%;
            margin: 0 auto;
            display: block;
            width: auto;
            background: transparent;
        }
        .recommendations {
            padding: 10px 0;
            margin: 0 auto;
            width: auto;
            text-align: center;
        }
        &.hide-mobile {
            display: none;
        }

        @media screen and (max-height: $tabletBreakPoint) {
            .carousel-recommendations {
                padding: 0 25px;
                .product-tile {
                    padding: 0 10px 0;
                }
            }
        }
    }
    @media screen and (max-width: 766px) {
        .carousel-recommendations {
            padding: 0;
            .product-tile {
                padding: 0 10px 0 0;
            }
        }
    }
}

@mixin pdpStyles() {
    .recommendation {
        &.recommendations-v {
            .recommendations-heading {
                margin: 0 0 14px;
            }

            .slick-vertical {
                .slick-prev {
                    top: -10px;
                    left: 50%;
                    margin-left: -13px;
                }
                .slick-next {
                    top: 100%;
                    left: 50%;
                    margin-left: -13px;
                    transform: translate(0, -6px);
                }

                li {
                    float: none !important;

                    &.slick-list {
                        margin-bottom: 15px;
                    }
                }
            }
        }
        width: 14.5%;
        max-width: 180px;
        display: block;
        float: right;
        clear: right;
        .tiles-container.recommendation-tiles {
            background-color: transparent;
            margin: auto;
            top: 5px !important;
        }
        button {
            border: none;
            border-radius: 0;
            color: transparent;
            display: block;
            padding: 0;
            margin: 5px auto;
            width: 23px;
            height: 23px;
            overflow: hidden;
            &.slick-arrow {
                &.slick-prev {
                    background: url("../../images/rm/carat-up.svg") no-repeat center;
                    &.slick-disabled {
                        background: url("../../images/carat-up_Grey.svg") no-repeat center;
                    }
                }
                &.slick-next {
                    background: url("../../images/rm/carat-down.svg") no-repeat center;
                    &.slick-disabled {
                        background: url("../../images/carat-down_Grey.svg") no-repeat center;
                    }
                }
            }
            @media screen and (max-width: $customBreakPoint) {
                position: absolute;
                z-index: 2;
                clear: both;
                width: 23px;
                height: 23px;
                top: 35%;
                &.slick-arrow {
                    &.slick-next {
                        left: 100%;
                        background: $rightArrow_black;
                        margin: 0 auto;
                        &.slick-disabled {
                            background: $rightArrow_gray;
                        }
                    }
                    &.slick-prev {
                        right: 100%;
                        background: $leftArrow_black;
                        margin: 0 auto;
                        &.slick-disabled {
                            background: $leftArrow_gray;
                        }
                    }
                }
            }
        }
        ul {
            &.slick-dots {
                list-style-type: none;
                position: relative;
                margin: 0 auto;
                width: 100px;
                @media screen and (max-width: $mobileBreakPoint) {
                    display: table;
                    z-index: 3;
                    padding: 10px 0 5px;
                    li {
                        display: table-cell;
                        vertical-align: middle;
                        margin: 0 auto;
                        padding: 0;
                        float: none;
                        button {
                            position: relative;
                            border: none;
                            border-radius: 100%;
                            width: 0;
                            height: 0;
                            padding: 2.5px;
                            background: $black;
                            margin: 0 auto;
                        }
                        &.slick-active button {
                            background: $light-gray;
                        }
                    }
                }
            }
        }
        .grid-tile {
            float: none;
            width: 100%;
            border: 0;
            .product-promo .clearance.promotional-message {
                display: none;
            }
            .product-promo {
                display: none;
            }
        }
        .slick-slide img {
            &.p-image-rollover {
                display: none;
            }
        }
        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }
        .product-tile {
            padding: 10px 0;
            color: $black;
            width: 100%;
            border: 0;
            margin: 0 auto;
            box-sizing: border-box;
            .product-promo .clearance.promotional-message {
                display: none;
            }
            .product-promo {
                display: none;
            }
            &:not(.specialproductset):hover .quickview {
                display: block;
            }
            .product-standard-price,
            .product-sales-price {
                display: block;
                margin: 0 auto;
            }
            .product-image {
                max-width: 80px;
                margin: auto;
                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                }
            }
            .product-name {
                margin: 2px 0 5px;
                width: 100%;
                line-height: normal;
            }
            .product-pricing {
                margin: 0 auto;
                font-size: $base-font;
            }
            .product-price {
                margin: 1px 0 5px;
                .price-standard {
                    padding-right: 6px;
                }
            }
            .quickview {
                margin: 0 auto;
                padding: 8px 0;
                font-size: 12px;
                bottom: 0;
                cursor: pointer;
                width: 150%;
            }
        }
        &.for-desktop {
            .product-tile {
                padding-bottom: 9px;
            }
        }
        &.for-device {
            display: none;
        }
        .recommendations {
            clear: left;
            padding: 0 0 10px;
        }
        .recommendations-heading {
            text-align: center;
            padding: 14px 0 0;
            margin: 0 0;
            color: $recommendation-heading;
        }
        &.hide-desktop {
            display: none;
        }
        @media screen and (max-width: $customBreakPoint) {
            width: 100%;
            float: none;
            margin: 10px auto;
            display: block;
            max-width: 100%;
            max-height: inherit;
            clear: both;

            &.hide-tablet {
                display: none;
            }
            &.for-device {
                display: block;
            }
            &.hide-desktop {
                display: block;
            }
            .recommendations {
                padding: 10px 0;
                margin: 0 auto;
                width: auto;
            }
            .tiles-container.recommendation-tiles {
                max-width: 100%;
                margin: auto;
                display: block;
                width: 100%;
                background: transparent;
                padding: 0 25px;
                left: 0 !important;
            }
            .recommendations-heading {
                padding: 23px 0 22px;
            }
            .grid-tile {
                display: inline-block;
                padding: 0 25px;
            }
            .product-tile {
                display: table;
                width: auto;
                padding: 0 10px 10px 0;
                min-height: 275px;
                max-width: inherit;
                margin: 0 auto;
                .quickview {
                    margin: 0 auto;
                    padding: 0.72em;
                    top: auto;
                    bottom: 10px;
                    cursor: pointer;
                    font-size: 14px;
                    max-width: inherit;
                    width: calc(100% - 20px);
                }
                .product-name-image-container {
                    max-width: inherit;
                    margin: 0 auto;
                    .product-image {
                        max-width: 220px;
                        margin: 0 auto;
                    }
                }
                .product-name {
                    line-height: normal !important;
                    word-break: normal !important;
                    margin: 7px 0 0;
                }
            }
            .grid-tile {
                padding: 0 21px;
            }
            .product-name-image-container {
                width: auto;
            }
        }
        @media screen and (max-width: $mobileBreakPoint) {
            clear: both;
            .product-tile {
                display: table;
                margin: 0 auto;
                padding: 0 10px 10px 0;
                min-height: 275px;
                max-width: inherit;
                .product-name-image-container {
                    max-width: inherit;
                    margin: 0 auto;
                    .product-image {
                        max-width: 220px;
                        margin: 0 auto;
                        img {
                            margin: 0 auto;
                            width: 100%;
                        }
                    }
                }
            }
            .tiles-container.recommendation-tiles {
                max-width: 100%;
                margin: 0 auto;
                display: block;
                width: auto;
                background: transparent;
                padding: 0;
            }
            .recommendations {
                padding: 10px 0;
                margin: 0 auto;
                width: auto;
                text-align: center;
            }
            &.hide-mobile {
                display: none;
            }
        }
    }
    .special-recommendation {
        width: 100%;
        float: none;
        margin: 0 auto;
        display: block;
        max-width: 100%;
        max-height: inherit;
        .slick-track:before,
        .slick-track:after {
            content: "";
            display: none;
        }
        button {
            border: none;
            border-radius: 0;
            color: transparent;
            display: inline-block;
            padding: 0;
            margin: 0 auto;
            position: absolute;
            width: 23px;
            height: 23px;
            transform: translateY(-50%);
            top: 35%;
            &.slick-arrow {
                &.slick-next {
                    left: 100%;
                    background: $rightArrow_black;
                    &.slick-disabled {
                        background: $rightArrow_gray;
                    }
                }
                &.slick-prev {
                    right: 100%;
                    background: $leftArrow_black;
                    &.slick-disabled {
                        background: $leftArrow_gray;
                    }
                }
            }
        }
        ul {
            &.slick-dots {
                list-style-type: none;
                position: relative;
                margin: 0 auto;
                width: 100px;
                @media screen and (max-width: $mobileBreakPoint) {
                    display: table;
                    z-index: 3;
                    padding: 10px 0 5px;
                    li {
                        display: table-cell;
                        vertical-align: middle;
                        margin: 0 auto;
                        padding: 0;
                        float: none;
                        button {
                            position: relative;
                            border: none;
                            border-radius: 100%;
                            width: 0;
                            height: 0;
                            padding: 2.5px;
                            background: $black;
                            margin: 0 auto;
                        }
                        &.slick-active button {
                            background: $light-gray;
                        }
                    }
                }
            }
        }
        .product-tile {
            padding: 10px 0;
            margin: 0 auto;
            color: $black;
            border: 0;
            display: inline-block;
            width: auto;
            max-width: inherit;
            .product-name-image-container {
                max-width: inherit;
                margin: 0 auto;
                .product-image {
                    max-width: 220px;
                    margin: 0 auto;
                }
            }
            .product-standard-price,
            .product-sales-price {
                display: block;
                margin: 0 auto;
            }
            .product-image {
                margin: auto;
                img {
                    width: auto;
                    height: auto;
                }
            }
            .product-name {
                margin: 2px 0 0;
                width: 100%;
                line-height: normal;
            }
            .product-brand {
                padding: 0 0 6px 0;
            }
            .product-price {
                margin: 1px 0 5px;
                .price-standard {
                    padding-right: 6px;
                }
            }
        }
        &.for-desktop {
            display: none;
        }
        &.for-device {
            display: block;
        }
        .recommendations {
            padding: 10px;
            margin: 0 auto;
            width: auto;
            max-width: 1200px;
        }
        .tiles-container.recommendation-tiles {
            max-width: 100%;
            margin: auto;
            display: block;
            width: 100%;
            background: transparent;
            left: 0 !important;
            top: 0;
        }
        .recommendations-heading {
            padding: 23px 0 22px;
            margin: 0 auto;
            color: $recommendation-heading;
        }
        .grid-tile {
            width: 149px;
            float: left;
            padding: 0 25px;
        }
        .product-tile {
            .product-name {
                line-height: normal !important;
                word-break: normal !important;
                margin: 7px 0 0;
            }
        }
        .grid-tile {
            padding: 0 21px;
        }
        .product-name-image-container {
            width: auto;
        }
        @media screen and (max-width: $mobileBreakPoint) {
            .product-tile {
                display: inline-block;
                padding: 10px;
                min-height: 275px;
                max-width: inherit;
                .product-name-image-container {
                    max-width: inherit;
                    margin: 0 auto;
                    .product-image {
                        max-width: 220px;
                        margin: 0 auto;
                        img {
                            margin: 0 auto;
                            width: 100%;
                        }
                    }
                }
            }
            .tiles-container.recommendation-tiles {
                max-width: 100%;
                margin: 0 auto;
                display: block;
                width: auto;
                background: transparent;
            }
            .recommendations {
                padding: 10px 0;
                margin: 0 auto;
                width: auto;
                text-align: center;
            }
        }
    }
    .s-product-set {
        .recommendation {
            @media screen and (max-width: $customBreakPoint) {
                margin: 40px auto 20px;
            }
            @media screen and (max-width: $mobileBreakPoint) {
                margin: 0 auto;
            }
        }
    }
}

@mixin cartPage() {
    //recommendations
    max-width: 222px;
    float: right;
    margin-top: 20px;
    ul.cart-recommendations-tiles,
    ul.confirmation-recommendations-tiles,
    ul.slick-track {
        padding: 0;
    }
    h2.trending {
        color: $black;
        font-style: normal;
        font-weight: normal;
        text-align: center;
        text-transform: none;
        padding: 20px 0 0 0;
        margin: 0;
    }
    .product-tile {
        padding: 0 0;
        margin: 10px auto 0;
        color: $black;
        width: 100%;
        border: 0;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        &.product-set {
            .quickview {
                display: none !important;
            }
        }
        &:hover .quickview {
            display: block;
        }
        .quickview {
            margin: 0 auto;
            font-size: 12px;
            bottom: 0;
            width: 150%;
            cursor: pointer;
        }
        .product-pricing {
            display: block;
            margin: 0 auto;
        }
        .product-standard-price,
        .product-sales-price {
            display: table;
            border-spacing: 0;
            margin: 0 auto;
            &:after {
                left: 0;
            }
        }
        .product-image {
            max-width: 100px;
            margin: auto;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
            }
        }
        .product-name {
            margin: 2px 0 0;
            width: 100%;
            line-height: normal;
        }
        .product-brand {
            padding: 0 0 6px 0;
        }
        .product-price {
            margin: 1px 0 5px;
            .price-standard {
                padding-right: 6px;
            }
        } // hiding non required for cart recommendations
        .product-promo,
        .product-review,
        .product-swatches,
        .product-special-messages {
            display: none;
        }
    } //less than 1024pc
    .slick-slide img.p-image-rollover {
        display: none;
    }

    button {
        &.slick-arrow {
            position: relative;
            color: transparent;
            margin: 5px auto;
            border: none;
            padding: 14px;
            z-index: 9;
            width: 23px;
            height: 23px;
            display: block !important;
            &.slick-prev {
                background: url("../../images/rm/carat-up.svg") no-repeat center;
                &.slick-disabled {
                    background: url("../../images/carat-up_Grey.svg") no-repeat center;
                }
            }
            &.slick-next {
                background: url("../../images/rm/carat-down.svg") no-repeat center;
                &.slick-disabled {
                    background: url("../../images/carat-down_Grey.svg") no-repeat center;
                }
            }
            @media screen and (max-width: $cartTabletBreakPoint) {
                position: absolute;
                width: 23px;
                height: 64px;
                transform: translateY(-50%);
                top: 50%;
                padding: 14px;
                &.slick-next {
                    left: 100%;
                    background: $rightArrow_black;
                    margin: 0 auto;
                    margin-left: -6px;
                    background-position: center;
                    &.slick-disabled {
                        background: $rightArrow_gray;
                        background-position: center;
                    }
                }
                &.slick-prev {
                    right: 100%;
                    background: $leftArrow_black;
                    margin: 0 auto;
                    margin-right: -6px;
                    background-position: center;
                    &.slick-disabled {
                        background: $leftArrow_gray;
                        background-position: center;
                    }
                }
            }
            @media screen and (max-width: $mobileBreakPoint) {
            }
        }
    }
    @media screen and (max-width: $cartTabletBreakPoint) {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
        .cart-recommendations-tiles {
            max-width: 100%;
            margin: 0 auto;
        }
        .you-may-like {
            background: none;
            border: 0;
        }
        h2.trending {
            padding-bottom: 20px;
            margin-bottom: 20px;
            text-transform: capitalize;
            color: $recommendation-heading;
        }
        .product-tile {
            display: inline-block;
            width: auto;
            padding: 0 10px 10px;
            min-height: 275px;
            max-width: inherit;
            margin: 0 auto;
            vertical-align: top;
            .quickview {
                margin: 0 auto;
                padding: 0.72em;
                top: auto;
                bottom: 10px;
                cursor: pointer;
                font-size: 14px;
                max-width: 75%;
            }

            .product-name-image-container {
                max-width: inherit;
                margin: 0 auto;
                .product-image {
                    max-width: 220px;
                    margin: 0 auto;
                }
            }
            .product-name {
                font-size: $base-font + 8px;
                line-height: 20px;
                padding: 10px 0;

                &.is-product-brand {
                    padding-bottom: 0;
                }
            }
            .product-pricing {
                display: block;
                border-spacing: 10px 0;
                margin: 0 auto 20px;
            }
            .product-standard-price,
            .product-sales-price {
                display: block;
                margin: 0 auto;
            }
        }
    }
    @media screen and (max-width: $mobileBreakPoint) {
        .product-tile {
            padding: 0 10px 0 0;
            .product-image {
                max-width: inherit;
                margin: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        } //less than 1024pc
    }
}

.product-recommendations {
    .product-tile {
        .b_product_badge,
        .b_product_icon,
        .stickerToolTip {
            display: none;
        }
        .product-promo,
        .product-special-messages,
        .product-feature-messages {
            display: none;
        }
    }

    ul.cart-recommendations-tiles,
    ul.slick-track {
        padding: 0;
    }
}

//pdp styles

#main .pdp-main {
    @include pdpStyles();
}

.pt_cart .cart-right-content .cart-recommendations,
.pt_order-confirmation .confirmation-right-content .confirmation-recommendations {
    @include cartPage();
}

.pt_order-confirmation .confirmation-right-content .confirmation-recommendations {
    width: 100%;
    max-width: inherit;
    float: none;
}

.div-trending-section .you-may-like,
.cart-empty .product-slot-recommendation .you-may-like,
.trending-section .you-may-like,
.last-visited-section .last-visited,
.pt_offerscoupons .offers-below-content {
    @include HomepageStyles();
}
.last-visited-section .last-visited {
    .product-tile .product-image {
        .b_product_icon,
        .stickerToolTip {
            display: none;
        }
    }
}
.last-visited-section .last-visited .search-result-items button.slick-arrow {
    &.slick-prev,
    &.slick-next {
        margin: 0 auto;
    }
}
.cart-empty .product-slot-recommendation .you-may-like {
    .recommendation-heading h2 {
        text-transform: uppercase;
        color: $recommendation-heading;
    }
    .product-tile img {
        max-width: 200px;
    }
}

@media screen and (max-width: $cartTabletBreakPoint) {
    .pt_order-confirmation #main .confirmation .confirmation-right-content {
        width: auto;
        max-width: inherit;
        float: none;
        padding: 0 20px;
    }
}

#main .pdp-main .complete-the-look .product-tile .product-image img {
    width: 100%;
    max-width: 220px;
    text-indent: -9999px;
    margin: auto;
    object-fit: cover;

    &.p-image {
        height: 316px;
    }
}

#main .pdp-main .complete-the-look {
    max-width: 1260px;
    width: 100%;
    margin: auto;
    .slick-track {
        margin: 0 auto;
    }
}
#main .pdp-main .complete-the-look h4 {
    text-align: center;
    font-weight: bold;
    text-align: center;
    font-size: 22px;
    color: $recommendation-heading;
}

#main .pdp-main .complete-the-look .product-name.is-product-brand,
#main .pdp-main .complete-the-look .product-name.no-product-brand {
    font-size: 18px;
    line-height: normal;
    font-weight: normal;
    text-transform: capitalize;
    margin-top: 20px;
    font-family: "mreavessanaltr";
    margin-bottom: 0;
}

#main .complete-the-look .product-brand {
    padding: 0;
}
@media screen and (max-width: $customBreakPoint) {
    #main .pdp-main .complete-the-look h4 {
        padding: 23px 0 22px;
    }
}
@media screen and (max-width: $mobileBreakPoint) {
    #main .pdp-main .complete-the-look h4 {
    }
}

@media screen and (min-width: $mobileBreakPoint) and (max-width: $tabletBreakPoint) {
    #main .pdp-main .complete-the-look h4 {
        font-size: 32px;
    }
}

.slick-arrow {
    position: absolute;
    top: 35%;
    transform: translate(0, -35%);
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: unset;
    width: 14px;
    text-indent: -99999px;
    z-index: 9;
    &.slick-prev {
        left: 0;
        background: url("../../images/Left_ArrowBlack.svg") no-repeat center right/contain;
        &.slick-disabled {
            background: url("../../images/Left_ArrowGray.svg") no-repeat center right/contain;
        }
    }

    &.slick-next {
        right: 0px;
        background: url("../../images/Right_ArrowBlack.svg") no-repeat center left/contain;

        &.slick-disabled {
            background: url("../../images/Right_ArrowGray.svg") no-repeat center left/contain;
        }
    }
}

.pt_cart {
    .cart-recommendations {
        .slick-vertical {
            .slick-arrow {
                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    left: 0;
                }
            }
        }
    }
}

.pt_order-confirmation {
    .confirmation-recommendations {
        .slick-vertical {
            .slick-arrow {
                &.slick-prev {
                    left: 0;
                }
                &.slick-next {
                    left: 0;
                }
            }
        }
    }
}

.complete-the-look {
    .product-tile {
        .product-pricing {
            span {
                font-size: 12px;
                font-family: "Futura LT W01 Medium", Arial, sans-serif;
                font-weight: normal;
            }
            .product-sales-price {
                display: block;
            }
        }
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1159px) {
    #main .pdp-main .recommendation .recommendations.cross-sell {
        width: 100%;
    }
}

@media only screen and (min-width: 1350px){
    .pt_product-details #main .pdp-main .last-visited-section button.slick-arrow.slick-prev {
        transform: translate(-24px, -50%);
    }
}
#wrapper #main .pdp-main .pdp-horizontal .recommendations-h .recommendations-heading {
    text-align: center;
    font-size:24px;
}