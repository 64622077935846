// Start: Override Apple Pay Btn

@mixin applePayBtn {
    border-radius: 0.1875rem;
    padding: 11px 0 10px 0;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-style: black;
    -apple-pay-button-type: buy; // Fallback Apple Pay Button Type
    -apple-pay-button-type: continue;
}

.apple-pay-cart:not(.dw-apple-pay-button) {
    height: 40px;
    background: $light-grey;
}

.dw-apple-pay-button {
    &.apple-pay-cart,
    &.apple-pay-checkout {
        @include applePayBtn;
        &:hover, &:active {
            @include applePayBtn;
        }
    }
}

// End: Override Apple Pay Btn