.legacy-sitegen .pt_wish-list {
	.create-login {
		.col-1 {
			.login-box-content {
				.login-rememberme {
					label {
						position: static;
						line-height: 22px;
					}
				}
			}
		}
		@media screen and (max-width: 1024px) {
			.form-row {
				&.show-text {
					&.field-valid {
						select {
							text-indent: 0;
						}
					}
				}
			}
		}
	}

	.form-row {
		position: relative;
		margin: 0;

		&.birthMonth {
			select {
				background-color: $white;
			}
		}

		@media screen and (max-width: 1024px) {
			select {
				text-indent: -9999px;
				border: 1px solid $dark-gray;
			}

			&.show-text {
				select {
					text-indent: 0;
				}

				&.field-valid {
					select {
						text-indent: -9999px;
					}
				}
			}
		}
	}

	label {
		font-weight: normal;
		padding: 0;
		position: absolute;
		-webkit-transform: translate(5px, 20px);
		-ms-transform: translate(5px, 20px);
		transform: translate(5px, 20px);
		left: 12px;
		margin: 0;
		top: -3px;
		width: auto;
		-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
		transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
		-ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
		color: $dark-gray;

		span {
			text-transform: capitalize;

			&.required-indicator {
				display: none;
			}
			&.error {
				text-transform: none;
			}
		}

		&.input-focus {
			-webkit-transform: translate(5px, 11px);
			-ms-transform: translate(5px, 11px);
			transform: translate(5px, 11px);
		}
	}

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="date"],
	textarea {
		border-radius: 0;
		height: auto;
		padding: 20px 15px 14px 15px;
	}

	button {
		&.pwd-show {
			padding: 15px;
			background: transparent;
			border: none;
			color: $black;
			font-family: $HelveticaMedium, $sans-serif;
			font-size: $base-font;
			position: absolute;
			top: -20px;
			right: -14px;
			-webkit-transform: translate(-14px, 20px);
			-ms-transform: translate(-14px, 20px);
			transform: translate(-14px, 20px);
			z-index: 1;
			cursor: pointer;
			text-transform: uppercase;
			width: auto;
			outline-offset: -3px;
		}
	}

	.primary-content {
		width: 100%;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		padding: 0 10px;

		button {
			width: 100%;
			border: none;
			letter-spacing: 1px;
			color: $white;
			outline-offset: -3px;
		}

		.search-wishlist {
			.search-wishilist-box {
				max-width: 985px;
				margin: 30px auto;
				padding: 34px 45px 60px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				background: $light-skygrey;
			}

			h1 {
				border-bottom: 1px solid $alto;
				padding: 0 0 10px 0;
				margin: 0;
			}

			h2 {
				font-weight: inherit;
				text-transform: inherit;
				margin: 17px 0 0 0;
			}

			.or-link {
				display: inline-block;
				text-transform: uppercase;
				width: 100%;
				margin: 18px 0 0 2px;
			}

			.error-message {
				border: none;
				background: transparent;
				margin: 10px 0 0;
				padding: 0;
				width: 100%;
			}

			.form-row {
				clear: none;
				float: left;
				width: 36%;
				margin: 20px 20px 0 0;

				&.form-row-button {
					width: 100%;
					margin: 25px 0 0 0;

					button {
						width: auto;
						padding: 15px 72px;
					}
				}

				&.email_wishlist {
					margin: 17px 20px 0 0;
					clear: both;
				}

				.field-wrapper {
					margin: 0;
				}
			}

			label {
				font-size: $base-font;

				span {
					&.error {
						color: $checkout-error-color !important;
					}
				}

				&.input-focus {
					span {
						font-size: $base-font - 1px;
					}
				}
			}
			.email_wishlist {
				clear: both;
			}
		}

		.wishlist-main {
			max-width: 1024px;
			margin: 0 auto 20px;

			.heading {
				span {
					text-transform: capitalize;
				}
			}

			@media screen and (max-width: 1023px) {
				display: inline-block;
				margin: 0 20px;				
			}
			@media screen and (min-width: 768px) and (max-width: 1023px){
				width: calc(100% - 40px);
			}

			@media screen and (max-width: 767px) {
				margin: 0;
			}
		}

		.search-wishlist-bottom {
			.search-wishilist-box {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				background: $light-skygrey;
				padding: 25px 50px;

				h1 {
					padding: 0 0 10px 0;
					margin: 0;
					font-size: $base-font + 12px;
				}
				.form-row {
					width: 25%;
					max-width: 200px;
					float: left;
					margin-right: 15px;

					&.email_wishlist{
						@media screen and (min-width: 1024px){
							max-width:none;
							margin-right: 0;
						}						
						@media screen and (min-width: 768px) and (max-width: 1023px) {
							clear: both;
						}
					}

					&.form-row-button {
						width: auto;
						margin: 0;
						float: right;

						button {
							padding: 17px 58px;
						}
					}
				}

				.or-link {
					margin-top: 18px;
					&.or-element {
						width: auto;
					}
					@media screen and (min-width: 768px) and (max-width: 1023px) {
						clear: both;
						margin-top: 0;
					}
				}
			}
		}

		.results-found {
			max-width: 980px;
			margin: 0 auto;

			.heading {
				margin: 0 0 20px 40px;
			}

			.section-header-list {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				float: left;
				text-transform: uppercase;
				width: 100%;
				background: $light-skygrey;
				padding: 21px 30px 19px 45px;

				.section-header {
					
					width: 47%;
					margin: 0;
					letter-spacing: 1px;

					.inner-block {
						max-width: 85px;
					}

					&.col-2 {
						width: 47%;

						.inner-block {
							max-width: 85px;
						}
					}

					&.col-3 {
						width: 40%;
						display: none;

						.inner-block {
							max-width: 100px;
							text-align: center;
						}
					}

					&.col-4 {
						width: auto;
					}
				}
			}

			.item-list {
				margin: 0;
				width: 100%;
				display: inline-block;

				.section-item-list {
					width: 100%;
					float: left;
					box-sizing: border-box;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					border: 1px solid $alto;
					border-top: 0;
					padding: 21px 30px 19px 45px;

					.col-1,
					.col-2,
					.col-3,
					.col-4 {
						float: left;
					}

					.col-1 {
						width: 47%;
						margin: 0;

						.inner-block {
							max-width: 85px;
						}
					}

					.col-2 {
						width: 47%;
						margin: 0;

						.inner-block {
							max-width: 85px;
						}
					}

					.col-3 {
						width: 40%;
						margin: 0;
						display: none;

						.inner-block {
							max-width: 100px;
						}
					}

					.col-4 {
						width: auto;
						text-transform: uppercase;
						letter-spacing: 1px;
						margin: 0;
					}
				}
			}
		}
	}
	@media screen and (min-width: 1024px) {
		#main {
			padding: 0 20px;
		}
	}
}

//Wishlist login page
.legacy-sitegen .create-login {
	max-width: 985px;
	margin: 30px auto;

	@media screen and (max-width: 767px) {
		margin: 15px auto;
	}

	.form-row {
		position: relative;
	}

	label {
		font-weight: normal;
		padding: 0;
		position: absolute;
		-webkit-transform: translate(5px, 20px);
		transform: translate(5px, 20px);
		left: 12px;
		margin: 0;
		top: -3px;
		width: auto;
		z-index: 2;
		pointer-events: none;
		-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
		-ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
		transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
		color: $dark-gray;

		span {
			text-transform: capitalize;
			color: $dim-gray;

			&.required-indicator {
				display: none;
				color: $venetian-red;
			}
			&.error,
			&.server-error {
				text-transform: none;
				color: $venetian-red;
			}
		}

		&.input-focus {
			-webkit-transform: translate(2px, 2px);
			  transform: translate(20px, 6px);
		}
	}

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="date"] textarea {
		border-radius: 0;
		height: auto;
		padding: 20px 15px 14px 15px;
	}

	&.wishlist-login .login-create-account .form-row.addtoemaillist {
		label.input-focus{
			transform: none;
			padding: 0 10px;
			width: 87%;
			span {
				transform: none;
			}
		}
	}
	button {
		&.pwd-show {
			padding: 18px;
			background: transparent;
			border: none;
			color: $black;
			font-family: $HelveticaMedium, $sans-serif;
			font-size: $base-font;
			position: absolute;
			top: -20px;
			right: -14px;
			-webkit-transform: translate(-14px, 20px);
			transform: translate(-14px, 20px);
			z-index: 1;
			cursor: pointer;
			text-transform: uppercase;
			width: auto;
			&:hover,
			&:active {
				background-color: transparent;
			}
		}
	}

	button {
		width: 100%;
		border: none;
		letter-spacing: 1px;
		color: $white;
	}

	h1 {
		padding: 0 0 10px 0;
		margin: 0;
		border-bottom: 1px solid $alto;
		font-weight: normal;
	}

	h2 {
		font-weight: normal;
		text-transform: inherit;
	}

	.col-1 {
		width: 49%;
		display: inline-block;
		float: none;
		margin: 0;

		@media screen and (max-width: 767px) {
			width: 100%;
		}

		.form-row {
			margin: 0 0 20px 0;
			width: 100%;
			float: left;

			.field-wrapper {
				margin: 0;
			}

			&.login-rememberme {
				width: 100%;

				input[type="checkbox"] {
					background: url("../../images/checkbox.svg") no-repeat;
					width: 23px;
					height: 23px;
					border: none;
					outline: none;
					vertical-align: top;
					opacity: inherit;
					z-index: 9;
					margin-left: 0;
					margin-right: 5px;
				}

				input[type="checkbox"]:checked {
					background: url("../../images/checkmark.svg") no-repeat center;
					width: 23px;
					height: 22px;
					border: none;
					outline: none;
					vertical-align: top;
					opacity: inherit;
					z-index: 9;
					margin-left: 0;
				}

				label {
					color: $black;
					top: -3px;
					left: 21px;
				span{
					line-height:23px;
				}
				}
			}
		}
	}

	.col-2 {
		width: 50%;
		margin: 0 0 5% 0;
		float: right;

		.inner-block {
			float: right;
		}

		@media screen and (max-width: 767px) {
			width: auto;
			margin: 0 0 30px;
		}
		&.col-12 {
			@media screen and (max-width: 767px) {
				width: 100%;
			}			
		}
	}

	.create-inner-block {
		max-width: 460px;
		float: right;

		&.form-hide {
			display: none;
		}

		@media screen and (max-width: 767px) {
			max-width: 100%;
		}

		.login-create {
			-webkit-box-shadow: -2px 6px 30px 8px rgba(239, 239, 239, 1);
			-moz-box-shadow: -2px 6px 30px 8px rgba(239, 239, 239, 1);
			box-shadow: -2px 6px 30px 8px rgba(239, 239, 239, 1);
			background: $white;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			padding: 32px 70px 32px 65px;

			.para {
				font-size: $base-font + 1px;
				font-family: $Helvetica;
				margin: 16px 9px 20px 0;
				line-height: 20px;
			}

			.step {
				margin-bottom: 17px;

				h2 {
					text-transform: uppercase;
					margin: 0 0 10px 0;
					font-size: $base-font + 1px;
					font-weight: normal;
					letter-spacing: 1px;
				}

				p {
					font-size: $base-font + 1px;
					margin: 0 45px 0 0;
				}
			}

			.create-account {
				margin: 20px 0 0 0;
				padding: 16px 65px;
			}
		}
	}
	.form-row {
		.form-caption {
			text-align: left;
			float: left;
			font-family: $WorkSans;
			font-size: $base-font;
			color: $black;
			font-weight: 600;
			margin-left: 0px;
			@media screen and (max-width: 767px) {
				float: left;
			}
		}
	}

	.login-create-account {
		h2 {
			font-size: $base-font + 1px;
			font-family: $Helvetica;
			margin: 17px 0;
		}

		.para {
			font-size: $base-font + 1px;
			font-family: $Helvetica;
			margin: 16px 9px 20px 0;
			line-height: 20px;
		}

		.or-section {
			text-align: center;
			position: relative;

			.border {
				border-bottom: 1px solid $alto;
				position: absolute;
				width: 100%;
				z-index: 1;
				bottom: 7px;
			}

			.or-link {
				display: inline-block;
				position: relative;
				z-index: 1;
				text-transform: uppercase;
				background-color: $light-skygrey;
				font-size: $base-font;
				font-family: $HelveticaMedium, $sans-serif;
				margin: 18px 0 0 0;
				padding: 0 10px;
			}
		}

		.form-row {
			margin: 15px 0 0 0;
			width: 100%;
			float: left;	
			
			&.rewards-register-checkbox {
				width: 10%; 	
				+ .rewards-register-copy span{
			  		display: inline-block;
					width: 88%;
					margin-top: 3%;
				}
			}
			&.password,
			&.passwordconfirm {
				&.error-handle {
					.field-wrapper {
						&:before {
							font: normal normal normal 28px/1 FontAwesome;
							font-size: 25px;
							text-rendering: auto;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							content: "\f071";
							color: $venetian-red;
							position: absolute;
							top: 11px;
							left: 11px;
						}
					}
					label {
						padding-left: 55px;
						.error {
							color: $venetian-red;
						}
						span {
							&.required-indicator {
								color: $venetian-red;
							}
							&.server-error {
								& ~ .required-indicator {
									display: none;
								}
							}
						}
					}
					input[type="password"],
					input[type="text"] {
						border: 2px solid $venetian-red;
						padding-left: 55px;
					}
				}
			}
			&.password {
				.form-caption {
					display: none;
					padding: 10px;
					margin-top: -11px;
					background: $venetian-red;
					border-radius: 0 0 3px 3px;
					text-align: left;
					font-family: $WorkSans;
					font-size: $base-font;
					color: $white;
				}
			}

			.field-wrapper {
				margin: 0 0 10px 0;
				display: inline-block;
			}

			input[type="number"],
			input[type="password"],
			input[type="date"],
			textarea {
				float: left;
			}

			&.addtoemaillist {
				label {
					position: static;
					color: $black;
				}
			}
		}

		.legal {
			margin: 20px 0 0 10px;
			text-align: center;
			line-height: 20px;
		}
	}

	.inner-block {
		max-width: 460px;

		&.form-display {
			display: block !important;
		}

		@media screen and (max-width: 767px) {
			max-width: 100%;

			&.form-display,
			&.display-mobile {
				display: block !important;
			}
		}

		.login-box {
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			padding: 32px 70px 32px 65px;
			&.login-create-account {
				.form-row {
					label {
						span {
							&.required-indicator {
								display: inline;
							}
							&.server-error {
								color: $venetian-red;
								& ~ .required-indicator {
									display: none;
								}
							}
						}
					}
				}
			}
			h2,
			.description {
				margin: 17px 17px 15px 0;
				line-height: 20px;
			}

			#password-reset {
				width: auto;
				float: right;
				text-align: right;
				margin: 15px 0 32px 0;
				font-size: $base-font;
				color: $black;
				text-transform: uppercase;
				@media screen and (max-width: 410px)  {
					text-transform: capitalize;
				}
			}

			&.login-general {
				margin-top: 20px;
				padding: 32px 77px 32px 60px;

				.form-row {
					width: 100%;
					margin: 0 0 18px 0;

					&.form-row-button {
						margin: 24px 0 0 0;

						button {
							float: right;
						}
					}
				}
			}

			.login-data {
				text-align: center;
				padding-top: 17px;

				span {
					text-transform: inherit;
					display: inline-block;
					letter-spacing: normal;
					padding-bottom: 5px;

					&.login-brands {
						line-height: 20px;
						padding: 0;
					}
				}
			}

			.filled-wineberry {
				width: auto;
				float: right;
				margin: 4px 0 0 0;
				padding: 16px 61px;
			}

			.login-oauth {
				@media screen and (max-width: 767px) {
					margin-top: 20px;
				}
			}
		}
	}
	.login-oauth {
		button {
			background: $white;
			padding: 15.34px 16px;
			color: $light-black-three;
			font-weight: normal;
			border: 1px solid $light-black-three;
			letter-spacing: unset;
			&.Facebook_button {
				img {
					width: 18px;
					height: 18px;
				}
			}
		}
		
	}
	.custom-select {
		background: transparent;

		.selected-option {
			text-transform: capitalize;
			font-size: $base-font;
			color: $dim-gray;
			border: 1px solid $dark-gray;
			min-height: 100%;
		}
	}
}

//My Wishlist share
.legacy-sitegen .list-share {
	.list-title {
		margin: 0;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		border-bottom: 1px solid $alto;
		text-transform: inherit;
		font-weight: normal;
	}

	.top-section {
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;

		.heading {
			width: auto;
			float: left;
			margin: 0;
		}

		.share-option {
			padding: 0;
			width: auto;
			float: right;
			color: $black;
			margin: 7px 0 0 0;
		}
	}

	.bottom-section {
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		background: $light-skygrey;

		.share-options {
			display: inline-block;

			.share-icon-container {
				margin-top: 2px;

				.share-icon-label {
					display: none;
				}

				a {
					display: inline-block;
					line-height: 0;
					height: auto;
					vertical-align: middle;
					padding: 0;
					margin: 0 27px 0 0;

					&.share-twitter {
						margin: 0 25px 0 0;

						img {
							width: 27px;
						}
					}

					&.share-facebook {
						img {
							width: 17px;
						}
					}

					&.share-wishlist {
						img {
							width: 24px;
						}
					}

					img {
						width: 27px;
					}

					&:last-child {
						margin: 0;
					}
				}
			}
		}

		.share-link-content {
			max-width: 405px;
			float: right;

			.share-link-copy {
				width: 288px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				background: $white;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				float: left;

				a {
					cursor: not-allowed;
					pointer-events: none;
				}
			}

			.copy-link {
				text-transform: uppercase;
				letter-spacing: 1px;
				line-height: 33px;
				cursor: pointer;
				margin-left: 10px;
				vertical-align: text-top;
			}
		}
	}
}

//My Wishlist Items
.legacy-sitegen .item-list {
	.wishlist-row {
		width: 100%;
		display: inline-block;
		border-bottom: 1px solid $alto;

		&:last-child {
			border-bottom: 0;
		}

		.product-brand {
			font-family: $WorkSans;
			font-size: $base-font - 2px;
			text-transform: uppercase;
			color: $brand-name-color;

			&.jessicalondon {
				color: $brand-name-color;
			}
		}

		label {
			position: static;
			transform: none;

			span {
				&.label-text {
					color: $black;
					margin-left: 10px;
					text-transform: uppercase;
				}
			}
		}

		button {
			color: $black;
		}

		.wishlist-columns {
			width: 100%;
			display: inline-block;
			box-sizing: border-box;
			padding: 20px;

			@media screen and (max-width: 767px) {
				display: grid;

				.column {
					&.col-3 {
						order: 4;
						position:static;
					}
				}
			}

			.specialmessag-wishlist {
				margin-left: 145px;
				@media screen and (max-width: 767px) {
					margin-left: 0;
				}
				.product-image {
					width: 100px;
					display: inline-block;

					@media screen and (max-width: 767px) {
						display: none;
					}
				}
				.finalsale {
					font-size: $base-font;
					letter-spacing: 0.6px;
					display: block;
					font-weight: bold;
					color: $medium-carmine;
					text-transform: uppercase;
					font-family: $WorkSans;
					margin-bottom: 2px;
					@media screen and (max-width: 767px) {
						font-size: $base-font - 3px;
						letter-spacing: 0.45px;
					}
				}
				.specialmessaging {
					margin: 0;
					width: auto;
					display: block;
					padding-left: 0;
					box-sizing: border-box;

					@media screen and (max-width: 767px) {
						padding-left: 0;
					}

					p {
						margin: 0;
					}
				}
			}
		}

		.column {
			width: 33.33%;
			float: left;
			box-sizing: border-box;
			margin: 0;

			&.col-1 {
				max-width: 400px;
				width: 44%;

				.item-image {
					float: left;
					width: 100px;
					text-align: center;
				}

				.item-details {
					float: left;
					max-width: 67%;
					box-sizing: border-box;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					padding-left: 35px;

					.name {
						margin: 0 0 10px 0;
					}

					.product-brand {
						padding: 0 0 8px 0;
					}

					.price {
						display: none;
					}

					span {
						display: inline-block;
						text-transform: initial;
						margin: 0 0 10px 0;

						&.label {
							letter-spacing: 1px;
						}

						&.price-standard {
							text-decoration: line-through;
							margin: 0;
						}

						&.price-sales {
							color: $black;
							margin: 0;
						}

						&.notavailable {
							padding-left: 5px;
						}

						&.optionitemprice {
							margin: 0;

							.label,
							.value {
								margin: 0;
							}
						}
					}
					.edit-wishlist-detail-mobile {
						display: none;
					}
				}
			}

			&.col-2 {
				max-width: 320px;
				width: 26.8%;

				.price {
					text-transform: uppercase;
					text-align: center;
					float: left;
					width: 100%;

					span {
						display: inline-block;
						width: 100%;

						.price-standard {
							text-decoration: line-through;
							margin: 0;
						}

						.price-sales {
							color: $black;
							margin: 0;
						}
					}
				}
			}

			&.col-3 {
				max-width: 257px;
				width: auto;
				float: right;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;

				.item-dashboard {
					width: 100%;
					float: right;

					@media screen and (min-width: 768px) {
						position: relative;
					}
				}

				.option-date-added {
					width: 100%;
					display: block;
					text-align: right;
					text-transform: uppercase;
					color: $black;
					letter-spacing: 1px;
					margin: 6px 0 11px 25px;
					font-size: 11px;
				}

				.option-add-to-cart {
					display: inline-block;
					margin: 0;

					.inventory {
						width: 65px;
						float: left;
						white-space: nowrap;
						height: 50px;
						outline: none;
						.visually-hidden {
							display: none;
						}
					}

					button {
						&.add-to-cart {
							width: auto;
							display: inline-block;
							color: $white;
							box-sizing: border-box;
							-webkit-box-sizing: border-box;
							-moz-box-sizing: border-box;
							letter-spacing: 1px;
						}
					}

					.quantity-ats-message {
						width: 100%;
						vertical-align: top;

						&:not(.hide) {
							display: inline-block;
						}
					}
				}

				.edit-section {
					text-align: right;
					text-transform: uppercase;
					letter-spacing: 1px;
					float: right;
					color: $black;
					clear: right;
					.edit-wishlist-detail {
						float: left;
						border-right: 1px solid black;
						display: none;
					}

					.option-update {
						float: left;
					}
				}

				.option-toggle-public {
					float: right;
					letter-spacing: 1px;

					.input-checkbox {
						&:focus {
							outline: 2px solid $black;
							box-shadow: none;
						}
					}
				}

				.flex-content {
					@media screen and (max-width: 767px) {
						flex-flow: row wrap;
						display: flex;

						.option-date-added {
							order: 3;
						}

						.option-add-to-cart {
							order: 2;
						}

						.edit-section {
							order: 4;
							margin: 0;
						}

						.public-item {
							order: 1;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

//whishlist email popup
.ui-dialog {
	&.wishlist-email {
		.ui-dialog-titlebar {
			height: auto;

			.ui-dialog-title {
				display: block;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				margin: 0;
				font-weight: 400;
				text-transform: initial;
				padding: 34px 0 11px 72px;
			}

			.ui-dialog-titlebar-close {
				.ui-icon-closethick {
					background-image: url("../../images/close-icon.png");
					height: 16px;
					width: 16px;
					left: 46%;
					top: 57%;
				}
			}
		}

		.ui-dialog-content {
			border-top: 1px solid $alto;
			overflow: hidden;
			margin: 0 69px 35px 74px;
			padding: 15px 0 0 0;
		}
	}
}

.legacy-sitegen .sendtofriend {
	.form-row {
		position: relative;

		&.error-handle {
			.label-text {
				display: none !important;
			}
		}

		&.subject {
			width: 52%;
			margin: 15px 0 6px 0;
		}

		&.sendtome {
			width: auto;
			float: left;

			.field-wrapper {
				margin: 0;
			}

			label {
				color: $black;
				position: static;
				transform: none;
			}
			input[type="checkbox"] {
				outline: 1px solid $black;
				border: 1px solid $white;
				margin: 0 7px 0 0;
				width: 17px;
				height: 17px;
			}
		}

		&.recipientsemail {
			textarea {
				width: 100% !important;
				height: 50px;

				@media screen and (min-width: 768px) {
					overflow: hidden;
					padding: 20px 15px 0 15px !important;
				}

				@media screen and (max-width: 767px) {
					height: 87px;
				}
			}
		}

		&.message {
			label.input-focus {
				span {
					color: inherit;
				}
			}

			textarea {
				width: 100% !important;
				height: 87px;
				padding-right: 65px;
			}

			.character-count {
				position: absolute;
				right: 19px;
				bottom: 14px;
				color: $dark-gray;
				font-size: $base-font;
				z-index: 1;
			}
		}

		&.form-row-button {
			width: auto;
			float: right;

			button {
				border: 0;
				color: $white;
				letter-spacing: 1px;
			}
		}
	}

	label {
		font-weight: normal;
		padding: 0;
		position: absolute;
		-webkit-transform: translate(5px, 20px);
		-moz-transform: translate(5px, 20px);
		transform: translate(5px, 20px);
		left: 12px;
		margin: 0;
		top: -3px;
		width: auto;
		z-index: 2;
		-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
		-ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
		transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
		color: $dark-gray;

		span {
			&.required-indicator {
				display: none;
			}
			&.error {
				text-transform: none;
			}
		}

		&.input-focus {
			-webkit-transform: translate(5px, 8px);
			-moz-transform: translate(5px, 8px);
			transform: translate(5px, 8px);
		}
	}

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="date"],
	textarea {
		border-radius: 0;
		height: auto;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}
	.sub-heading {
		font-size: $base-font + 2px;
		font-family: $Helvetica;
		line-height: 20px;
		word-spacing: 4.5px;
	}
}

.sendtofriend-success,
.sendtofriend-error {
	display: none;
}

//My Wishlist
.list-share {
	.top-section {
		margin: 25px 0;
		width: 100%;
		display: inline-block;

		.heading {
		}
	}
}

//Wishlist Empty
.legacy-sitegen .wishlist-empty {
	h2 {
		margin: 12px 0 0 34px;
		text-transform: none;
		letter-spacing: 0.6px;
	}
	.wishlist-content {
		margin-bottom: 22%;
	}

	.heading {
		display: inline-block;
		font-size: $base-font + 12px;
		margin: 34px 0 0 32px;
	}
	.qa-section {
		.question-title {
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			text-transform: inherit;
			padding: 25px 40px;
		}

		.qa-content {
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			padding: 25px 40px;

			.question {
				letter-spacing: 1px;

				&::after {
					background-size: 11px;
				}

				&.active {
					&::after {
						background-size: 11px;
					}
				}
				.answer {
					padding: 18px 0 0 0;
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.legacy-sitegen .pt_wish-list {
		.primary-content {
			.search-wishlist {
				width: auto;
				display: inline-block;
				padding: 34px 20px;

				.search-wishilist-box {
					margin: 0 auto;
					padding: 34px 45px;
				}
			}

			//My Wishlist bottom section
			.search-wishlist-bottom {
				.search-wishilist-box {
					h1 {
						padding: 0 0 20px 0;
					}

					.form-row {
						width: 50%;
						padding: 0 20px 20px 0;

						&.email_wishlist {
							width: 50%;
							max-width: 300px;
							float: left;
							margin: 0;
						}

						&.form-row-button {
							float: left;
						}
					}
				}
			}

			.results-found {
				margin: 20px 10px;

				.item-list {
					.section-item-list {
						.col-3 {
							&.city {
								width: 38%;
							}
						}
					}
				}
			}
		}
	}

	//Wishlist login page
	.create-login {
		.login-oauth {
			button {
				padding: 16px;
			}
		}

		.create-inner-block {
			.login-create {
				padding: 34px 20px;
				margin: 0 20px;
			}
		}

		.inner-block {
			.login-box {
				padding: 34px 20px;
				margin: 0 20px;

				&.login-general {
					margin-top: 20px;
					padding: 34px 20px;
					margin-right: 0;
				}

				&.login-account {
					margin: 0 0 0 20px;
				}
			}
		}
	}

	//Wishlist Empty
	.legacy-sitegen .pt_wish-list {
		.primary-content {
			.wishlist-main {
				&.wishlist-empty-main {
					max-width: 100%;
					width: 100%;
					margin: 0 0 20px 0;
				}
			}
		}
	}

	//My Wishlist Items
	.legacy-sitegen .item-list {
		.wishlist-row {
			.column {
				&.col-1 {
					width: 56%;

					.item-details {
						.price {
							display: block;
						}
					}
				}

				&.col-2 {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.legacy-sitegen .pt_wish-list {
		.primary-content {
			.search-wishlist {
				margin: 0 10px;
				padding: 0;
				width: auto;
				display: inline-block;

				.form-inline {
					input[type="text"] {
						width: 100%;
					}
				}

				.form-row {
					width: 100%;

					&.form-row-button {
						width: 100%;

						button {
							width: 100%;
						}
					}
				}

				&.login-general {
					padding: 34px 20px;
					margin: 0;
				}
			}

			//My Wishlist bottom section
			.search-wishlist-bottom {
				.search-wishilist-box {
					padding: 25px 20px;
					margin: 0 0 20px 0;

					.wishlist-search .form-row {
						width: 100%;
						max-width: 100%;
						margin: 0 0 20px 0;
						padding: 0;

						&.email_wishlist {
							width: 100%;
							max-width: 100%;
							margin: 0 0 20px 0;
						}

						&.form-row-button {
							float: right;
							width: 100%;

							button {
								width: 100%;
								float: right;
							}
						}
					}
				}
			}

			.results-found {
				.item-list {
					.section-item-list {
						border: 1px solid $alto;
						padding: 20px;

						.combine-column {
							width: 100%;
							float: left;

							.col-1,
							.col-2 {
								width: auto;
								display: inline-block;
								padding-right: 5px;
							}
						}

						.inner-block {
							max-width: 100%;
							text-align: left;
						}

						.col-3 {
							width: 100%;
							margin-top: 10px;

							.location {
								width: auto;
								display: inline-block;
								text-transform: capitalize;
							}
						}

						.section-list-item-mobile {
							width: 90%;
							float: left;
							line-height: 30px;
						}

						.view {
							width: 10%;
							float: right;
							margin: 5px 0 0 0;

							a:not(.view-wishlist) {
								background: url("../../../images/back-arrow.png") no-repeat;
								width: 12px;
								height: 22px;
								display: inline-block;
								transform: rotate(180deg);
								-ms-transform: rotate(180deg);
								-webkit-transform: rotate(180deg);
								float: right;
							}
						}

						.mobile-label {
							width: auto;
							float: left;
							text-transform: uppercase;
							letter-spacing: 1px;
							padding-right: 5px;
						}
					}
				}
			}
		}
	}

	//Wishlist login page
	.create-login {
		.create-inner-block {
			.login-create {
				margin: 20px 0 0 0;

				.create-account {
					padding: 16px 55px;
				}
			}
		}

		.login-oauth {
			button {
				padding: 15px;
			}
		}

		.inner-block {
			.login-box {
				margin: 0 0 30px 0;
				padding: 20px 10px;

				&.login-account {
					margin: 0 0 30px 0;
				}

				.login-data {
					.login-email {
						width: 70%;
					}
				}

				.spc-login-btn {
					&.filled-wineberry {
						padding: 15px 55px;
					}
				}

				&.login-create-account {
					margin: 0;

					.create-account {
						margin: 18px 0 0 0;
						padding: 14px 55px;
					}

					.view-more {
						background: transparent;
						color: $black;
						margin: 0;
						padding: 25px 0 0 0;
						font-family: $HelveticaBold;
					}
				}

				&.login-general {
					margin-top: 20px;
					padding: 34px 20px;

					.or-link {
						margin: 15px 0 0 2px;
					}
				}
			}
		}
	}

	//whishlist email popup
	.ui-dialog {
		&.wishlist-email {
			.ui-dialog-titlebar {
				.ui-dialog-title {
					padding: 40px 0 15px 20px;
					font-size: $base-font + 8px;
				}
			}

			.ui-dialog-content {
				margin: 0 20px 25px;
			}
		}
	}

	.legacy-sitegen .sendtofriend {
		.sub-heading {
			font-size: $base-font + 1px;
		}

		.form-row {
			&.subject {
				width: 100%;
			}

			&.sendtome {
				margin: 25px 0 0 2px;
			}

			&.form-row-button {
				width: 100%;

				button {
					width: 100%;
				}
			}
		}

		label {
			font-size: $base-font - 1px;
		}
	}

	//My Wishlist
	.legacy-sitegen .list-share {
		.top-section {
			padding: 0;
			letter-spacing: 1px;
		}

		.bottom-section {
			padding: 0;
			background: transparent;

			.share-options {
				width: 100%;
				max-width: 100%;
				margin: 0 0 15px 0;

				.share-icon-container {
					margin-top: 0;
					width: 50%;
					float: left;

					a {
						margin: 0 15px 0 0;
					}
				}
			}

			.share-option {
				padding: 0;
				color: $black;
				width: 50%;
				float: right;
				margin: 5px 0 0 0;
				font-size: $base-font;
				text-align: right;
			}

			.share-link-content {
				width: 100%;
				max-width: 100%;
				padding: 10px;
				background: $light-skygrey;
				display: inline-block;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;

				.share-link-copy {
					width: 240px;
					@media screen and (max-width: 767px) {
						width: 100%;
					}
				}

				.copy-link {
					width: 15%;

					&.copied-link {
						width: 20%;
						line-height: 33px;
					}
				}
			}
		}
	}
	//Wishlist Empty
	.legacy-sitegen .wishlist-empty {
		.heading {
			margin: 0 0 10px 0;
		}

		h2 {
			margin: 0;
		}

		.qa-section {
			width: 100%;
			display: inline-block;

			.question-title {
				padding: 25px 20px;
			}

			.qa-content {
				padding: 20px;

				.question {
					font-size: $base-font - 1px;
				}

				.answer {
					font-size: $base-font - 2px;
				}
			}
		}
	}
}
@media screen and (max-width: 320px) {
	 .legacy-sitegen .list-share {
		.bottom-section {
			.share-options {
				.share-icon-container {
					a {
						margin: 0 10px 0 0;

						 &.share-twitter {
							margin: 0 15px 0 0;
						}
					}
				}
			}

			.share-link-content {
				.share-link-copy {
					width: 200px;
				}
			}
		}
	}
}

.create-login {
	.form-row {
		&.error-handle {
			.input-focus {
				.error {
					color: $venetian-red;
				}
				.label-text {
					display: none;
				}
			}
			label {
				span {
					text-transform: capitalize;
					color: $dim-gray;
					&.error {
						color: $venetian-red;
					}
					&.required-indicator {
						display: none;
						color: $venetian-red;
					}
				}
			}
		}
	}
}
.error-handle {
	.label-text {
		display: none;
	}
}
.pt_wish-list {
	.login-create-account {
		.form-row {
			&.password {
				&.error-handle {
					label {
						.label-text {
							display: none;
						}
					}
					.form-caption {
						margin-top: -16px;
					}
				}
			}
			&.password,
			&.passwordconfirm {
				&.error-handle {
					label {
						padding-left: 39px;
					}
				}
			}
		}
	}
}

#header {
	.secondary-header-overlay-container.active {
		&.fly-overlay-search {
			.overlay-inner-container .header-search {
				form.simpleSearch {
					@media screen and (min-width: 768px) {
						input#q {
							padding-top: 0;
						}
					}
					@media screen and (max-width: 767px) {
						input#q {
							padding-top: 15px;
						}
					}
				}
			}
		}
	}
}

.legacy-sitegen .wishlist-columns .product-list-item .name a:focus {
	display: block;
}

.legacy-sitegen .pt_wish-list .search-wishilist-box label {
	color: $dim-gray;
}

.legacy-sitegen .pt_wish-list .primary-content .search-wishlist-bottom .search-wishilist-box label span.error {
	color: $venetian-red;
}

.create-login .orderstatus-section label{
	position: absolute;
	top:-15px;
	left:15px;
	&.input-focus{
		transform:translate(10px, 20px);
	}
}
.login-create-account .addtoemaillist label span {
	display:inline-block;
	transform:translate(10px, -34px);
}
.first-name, .last-name, .view {
	font-size: .65em;
}

@media screen and (min-width:768px) {
	.legacy-sitegen .pt_wish-list .results-found{
		.first-name, .last-name, .section-list-item-mobile {
			flex: 0 0 50%;
		}
	}
}

.legacy-sitegen .create-login:not(.wishlist-login) .col-1 .form-row.login-rememberme label {
	left: 35px;
    padding-left: 0;
}
.legacy-sitegen .create-login:not(.wishlist-login) .col-2 .form-row.login-rememberme label {
	left: 25px;
	top: -5px;
    padding-left: 0;
}