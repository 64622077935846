.pt_product-search-result .primary-content h1.content-header {
	padding-left: 15px;
}

.pt_content-search-result,
.pt_product-search-noresult {
	.primary-content {
		> h1,
		> h2,
		> p {
			margin: 0;
			padding: 0.1em 1%;
		}
		.no-hits-help,
		.no-hits-search,
		.no-hits-footer {
			padding: 0.1em 1%;
		}
		.pagination {
			float: none;
			padding: 0.1em 1%;
		}
	}
}

.category-main-banner img {
	max-width: 100%;
}

.no-hits-search-term,
.no-hits-search-term-suggest {
	color: $sangria;
	font-style: italic;
}

.no-hits-footer {
	background: transparent;
	margin: 20px 0;
	padding: 20px 0;
}

.search-result-bookmarks {
	margin: 15px;
	.first {
		border-right: 1px solid $nobel;
		padding-right: 5px;
	}
}

.sort-by {
	form {
		label {
			color: $black;
		}
	}

	&.current_item {
		form {
			label {
				color: $white;
			}
		}
	}
}

.search-result-options {
	padding: 1em 0.5em;
	overflow: hidden;
	z-index: 2;

	.sort-by {
		padding-bottom: 0;
		margin-top: 2px;
	}
	.items-per-page {
		float: left;
		margin-right: 0.5em;
	}
	form {
		margin-bottom: 0;
	}
	label {
		display: none;
		line-height: 40px;
	}
	select {
		border: 1px solid $gainsboro;
		border-radius: 5px;
		color: $dim-gray;
		padding: 0.5em;
	}
	.pagination {
		width: 100%;
		float: left;
		text-align: center;

		.results-hits {
			color: $dim-gray;
			float: left;
			display: none;

			@media screen and (min-width: 1024px) {
				display: block;
			}
		}

		ul {
			float: none;
			display: inline-block;
			padding: 20px 0 0 0;
			margin: 0;
		}

		li {
			display: inline-block;
			margin: 0 10px 0 0;
			padding: 0;
			float: left;
			border: 1px solid $black;
			letter-spacing: 1px;
			text-transform: uppercase;
			line-height: 50px;
			box-sizing: border-box;
			color: $dim-gray;
			font-size: $base-font + 3px;
			a {
				padding: 16px 20px;
				color: inherit;
			}

			&.current-page {
				display: block;
				color: $white;
				width: 50px;
				background: $black;
			}

			&.dot-page {
				width: 58px;
				height: 48px;
				border: none;
				margin: 0;
			}

			&.dots {
				border: none;
				margin: 0;
				width: 30px;
			}
		}
	}
}

.product-search-page {
	@media screen and (min-width: 1024px) {
		.primary-content {
			width: 100%;
		}
	}

	#secondary {
		display: none;
	}

	.search-result-options {
		.result-count {
			display: none;
		}
	}

	.search-result-content {
		.search-result-items {
			.grid-tile {
				width: 20%;
				margin-right: 0;
				.product-name-image-container .product-image {
					.b_product_icon {
						width: 32px;
						position: absolute;
						top: 7px;
						right: 7px;
						overflow: hidden;
						z-index: 1;
						cursor: pointer;
						img {
							height: auto;
						}
					}
					.stickerToolTip {
						width: auto;
						height: auto;
						padding: 10px;
						display: none;
						position: absolute;
						top: 25px;
						right: 31px;
						border: 1px solid #d8d8d8;
						box-shadow: 0 0 4px 2px rgba(216, 216, 216, 0.7);
						background: #ffffff;
						font-size: 11px;
						z-index: 1;
					}
				}
				&:nth-child(5n + 1) {
					clear: both;
				}

				@media screen and (max-width: 1320px) {
					width: 25%;
					margin-right: 0;
					&:nth-child(5n + 1) {
						clear: none;
					}
					&:nth-child(4n + 1) {
						clear: both;
					}
				}

				@media screen and (max-width: 1023px) {
					width: 33.33%;
					margin-right: 0;
					&:nth-child(5n + 1) {
						clear: none;
					}
					&:nth-child(4n + 1) {
						clear: none;
					}
					&:nth-child(3n + 1) {
						clear: both;
					}
				}

				@media screen and (max-width: 767px) {
					width: 50%;
					margin-right: 20px;
					margin-right: 0;
					&:nth-child(5n + 1) {
						clear: none;
					}
					&:nth-child(4n + 1) {
						clear: none;
					}
					&:nth-child(3n + 1) {
						clear: none;
					}
					&:nth-child(odd) {
						clear: both;
					}
				}
			}
		}
	}
}

.search-result-items {
	margin: 0;
	padding: 0;

	@media screen and (max-width: 1023px) {
		.product-tile {
			.name-link,
			.product-name-image-container {
				display: inline-block;
			}
		}
	}

	li {
		list-style: none outside none;
	}
	.invisible {
		display: none;
	}
	.grid-tile {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		width: 25%;
		padding: 0 0 20px;
		float: left;
		display: inline-block;
		vertical-align: top;
		.product-tile {
			&:hover {
				background: $white;
				z-index: 1;
			}
			&.product-set {
				.quickview {
					display: none !important;
				}
			}
		}
	}
}

.search-result-content {
	clear: both;
	.search-result-items .grid-tile {
		.product-name-image-container .product-image {
			.b_product_icon {
				width: 32px;
				position: absolute;
				top: 7px;
				right: 7px;
				overflow: hidden;
				z-index: 1;
				cursor: pointer;
				img {
					height: auto;
				}
			}
			.stickerToolTip {
				width: auto;
				height: auto;
				padding: 10px;
				display: none;
				position: absolute;
				top: 25px;
				right: 31px;
				border: 1px solid #d8d8d8;
				box-shadow: 0 0 4px 2px rgba(216, 216, 216, 0.7);
				background: #ffffff;
				font-size: 11px;
				z-index: 1;
			}
		}
	}
	.product-price {
		.price-standard {
			color: $nobel;
			font-weight: 100;
			text-decoration: line-through;
		}
	}
}

.search-promo {
	padding: 20px;
	width: 100%;
	float: left;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

#results-content {
	background: transparent;
	padding-top: 30px;
}

.folder-content-list {
	align-items: stretch;
	clear: both;
	display: flex;
	flex-wrap: wrap;
	padding: 0.5em 1%;
	li {
		background-color: $white-smoke;
		border: 1px solid $very-light-gray;
		flex-basis: 100%;
		flex-grow: 1;
		list-style-type: none;
		margin: 0.25rem;
		padding: 1rem;
		@media screen and (min-width: 480px) {
			flex-basis: 40%;
		}
	}
	p {
		line-height: 1.1rem;
	}
	.content-title {
		display: block;
		font-size: 1.2em;
	}
	.readmore {
		font-style: italic;
		padding-left: 0.3rem;
	}
}

.no-results {
	padding: 20px;
}

.toggle-grid {
	display: block;
	float: left;
	margin-right: 0.5em;
	i {
		cursor: pointer;
		margin-right: 0.3em;
	}
	[data-option="column"] {
		color: $citrus;
	}
	&.wide {
		[data-option="wide"] {
			color: $citrus;
		}
		[data-option="column"] {
			color: $dim-gray;
		}
	}
}

.wide-tiles {
	.search-result-items .grid-tile {
		width: 91%;
	}
	.product-tile {
		.product-swatches {
			bottom: auto;
			left: auto;
			position: relative;
			right: auto;
			margin-top: 5px;
			li {
				float: left;
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	ul {
		&.search-result-items {
			.grid-tile {
				width: 33.33%;
				width: calc(100% / 3);
			}
		}
	}
	.search-result-items {
		li {
			&.grid-tile {
				&:nth-child(3n) {
					margin-right: 0;
				}
				&.two-space-tile {
					width: 66.67%;
					width: calc(2 * (100% / 3));
				}
			}
		}
	}
	.product-list-page {
		#secondary {
			width: 240px;
			padding-right: 40px;
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_product-search-result {
		#secondary {
			display: none;
		}
		.primary-content {
			width: 100%;
		}
	}
	.pt_product-search-result {
		#main {
			width: 100%;
		}
	}
}

@media screen and (max-width: 767px) {
	ul {
		&.search-result-items {
			.grid-tile {
				width: 50%;
				&.two-space-tile {
					width: 100%;
				}
			}
		}
	}
	.search-result-items {
		.grid-tile {
			&:hover {
				box-shadow: none;
				border: 1px solid transparent;
			}
			&:nth-child(2n) {
				margin-right: 0;
				float: right;
			}
		}
	}
	.pt_product-search-result {
		#main {
			width: 100%;
		}
	}
	.view-more {
		&.hide-desktop {
			margin: 0 0 21px;
		}
	}
}

.view-more {
	width: 100%;
	float: left;
	text-align: center;
	margin: 80px 0;

	.viewmore-grid {
		padding: 15px 103px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $dim-gray;
		display: inline-block;
	}
}
.product-list-page {
	.search-result-content {
		.search-result-items {
			li {
				&.new-row {
					clear: both;
				}

				@media screen and (max-width: 1300px) {
					&:nth-child(4n + 1) {
						clear: none;
					}
					&:nth-child(3n + 1) {
						clear: both;
						.product-tile {
							float: left;
						}
					}
					&:nth-child(3n + 2) {
						.product-tile {
							float: none;
						}
						&.two-space-tile {
							.offer-promo-tile {
								float: right;
							}
							.product-promo-tile {
								float: right;
							}
						}
					}
					&:nth-child(3n) {
						.product-tile {
							float: right;
						}
					}
					.two-space-tile {
						width: calc(100% - 10px);
					}
				}
				@media screen and (max-width: 767px) {
					&:nth-child(4n + 1) {
						clear: none;
						.product-tile {
							float: none;
						}
					}
					&:nth-child(3n) {
						.product-tile {
							float: none;
						}
					}
					&:nth-child(3n + 1) {
						clear: none;
						.product-tile {
							float: none;
						}
					}
					&:nth-child(odd) {
						clear: both;
						.product-tile {
							float: none;
						}
					}
				}

				@media screen and (max-width: 450px) {
					&:nth-child(odd) {
						.product-tile {
							float: left;
						}
					}
					&:nth-child(even) {
						.product-tile {
							float: right;
						}
					}
				}
			}
		}
	}
	#secondary {
		width: 20%;
		max-width: 245px;
		position: relative;
		padding-right: 3.17%;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		display: block;
	}
}

/*---------- SC-8013 -- PLP NEW Story --------*/
.search-result-items {
	@media screen and (max-width: 767px) {
		max-width: 515px;
		margin: 0 auto;
	}
	@media screen and(min-width: 768px) and (max-width: 1260px) {
		max-width: 750px;
		margin: 0 auto;
	}
	&.normal-image-plp,
	&.large-image-plp {
		display: flex;
		flex-wrap: wrap;

		.new-plp-tile {
			width: 50%;
			flex-basis: 50%;
			max-width: 100%;
			margin: 0 0 32px;
			border: 0;

			&.one-space-tile,
			&.two-space-tile,
			&.three-space-tile {
				.product-tile {
					&.module-plp {
						float: none;
						text-align: center;
					}
				}
			}
			&.one-space-tile {
				width: 50%;
				flex-basis: 50%;
				@media screen and (min-width: 768px) {
					width: 33%;
					flex-basis: 33%;
				}

				@media screen and (min-width: 1261px) {
					width: 25%;
					flex-basis: 25%;
				}
			}
			&.two-space-tile {
				width: 100%;
				flex-basis: 100%;
				@media screen and (min-width: 768px) {
					width: 66%;
					flex-basis: 66%;
				}
				@media screen and (min-width: 1261px) {
					width: 50%;
					flex-basis: 50%;
				}
			}
			&.three-space-tile {
				width: 100%;
				flex-basis: 100%;
				@media screen and (min-width: 1261px) {
					width: 75%;
					flex-basis: 75%;
				}
			}
			@media screen and (min-width: 768px) {
				width: 33%;
				flex-basis: 33%;
			}

			@media screen and (min-width: 1261px) {
				width: 25%;
				flex-basis: 25%;
			}

			.product-tile {
				flex: 1;
				padding: 0;
				display: flex;
				flex-direction: column;
				height: 100% !important;
				width: 243px;
				float: none;
				background-color: $grid-attr-bg;
				text-align: left;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
				&:hover {
					box-shadow: none;
				}
				.product-image {
					img {
						object-fit: contain;
						height: auto;
					}
				}
	    		.grid-bottom-section{
	    			align-content: flex-end;
				    justify-content: flex-end;
				    display: flex !important;
    				flex-direction: column;
    				margin-top:-5px;
    				&.product-tile-bottom-no-swatches{
    					padding-bottom:25px;
    				}
    				
    				.name-link{
    					padding:0 9px;
    					width:auto;
	    				&.focus-outline{
	    					outline-offset:-3px;
	    				}
	    			}
	    		}
	    		.grid-top-section{
	    			flex-grow: 1;
	    			.product-promo{ 
		    			margin:6px 0;
		    			padding: 0 9px;
		    			text-align:left;
		    			font-size:$base-font;
		    			line-height:14px;
		    			text-transform:uppercase;
		    			color:$promo-red; 
		    			font-weight:500;
		    			letter-spacing:normal;
	    			}
	    			
	    			.name-link{
	    				height: 100%;
	    				&.focus-outline{
	    					outline-offset:-3px;
	    				}
	    			}
	    		}
	    		.product-pricing{ 
	    			margin:0 0 6px 0;
	    			border-spacing:0;
						.product-sales-price{
							padding:0 9px;
							margin:0;
							span{ font-weight:500; }
							.price-from-text, .price-curreny-symbol, .price-currency-text, .price-cents-text{ 
								font-size: $base-font;
								line-height:23px;
								color:$black; 
								display:inline-block;
								letter-spacing:normal;
							}
							.price-curreny-symbol sup{
								display: inline-block;
							    position: relative;
 							    font-size:$base-font;
 							    top:0;
							}
							 .price-currency-text{
							 	vertical-align: text-top;
							    position: relative;
							    font-size:$base-font + 12px;
							 }
							.plp-save-message{
								color:$promo-red;
							    font-size: $base-font;
							    display: inline-block;
							    vertical-align: top;
							    position: relative;
							    letter-spacing:normal;
							    line-height:23px;
							    font-weight:500;
							}
							.plp-save-message {
								color: $black;
							}
						}
					}
				}
				.product-name {
					margin: 0 0 4px;
					font-size: $base-font + 4px;
					line-height: 18px;
					color: $black;
					letter-spacing: normal;
					font-weight: 500;
					overflow: visible;
				}
				.product-review {
					margin: 2px 0 0 0;
					padding-left: 9px;
					line-height: 0;
					min-height: 11px;
					.rating {
						color: $review-star-color;
						i {
							font-size: 11px;
						}
					}
				}
				.product-subbrand {
					color: $dim-gray;
					font-size: $base-font;
					letter-spacing: 0.5px;
					line-height: $base-font + 6px;
					margin-bottom: 6px;
				}
				.product-name-image-container {
					display: flex !important;
					flex-direction: column;
					flex-grow: 0;
					.name-link {
						padding: 0;
						outline-offset: 0;
					}
					#quickviewbutton {
						text-align: center;
					}
				}

				.product-image {
					border-bottom: 1px solid $white;
					text-align: center;
					img {
						vertical-align: bottom;
						width: 100%;
						max-width: 100%;
						&.p-image {
							width: 100%;
						}
					}

					.b_product_badge {
						top: 0;
						left: 0;
						margin-right: 7px;
						display: inline-block;
						width: auto;
						img {
							width: auto;
						}
						.text_product_badge {
							font-family: $HelveticaMedium, sans-serif;
							border: solid 0.8px rgba(0, 0, 0, 0.65);
							background-color: $white;
							border-radius: 4px;
							-moz-border-radius: 4px;
							-webkit-border-radius: 4px;
							padding: 3px 7px 4px;
							font-size: $base-font - 4px;
							text-transform: uppercase;
							color: rgba(0, 0, 0, 0.65);
							line-height: 10px;
							vertical-align: top;
							margin: 6px 0 0 6px;
							width: 70px;
							text-align: center;
							letter-spacing: 0.3px;
						}
					}
				}

				.product-swatches {
					width: 100%;
					margin: 0;

					.swatch-list {
						display: flex;
						max-width: none;
						box-sizing: border-box;
						padding-left: 1px;
						&:hover {
							li {
								opacity: 0.4;
								&:hover {
									opacity: 1;
								}
							}
						}
						li {
							flex: 1 0 0;
							display: block;
							padding: 0;
							margin: 0 0 0 -1px;
							border: 1px solid #fff;
							transition: flex-basis 200ms;

							.swatch {
								width: 100%;
								height: 24px;
								border-radius: 0;
								float: none;
								min-width: 0;
								margin: 0;
								padding: 0;
								border: none;
								background-position: center center;
								position: relative;
								overflow: visible;
								max-width: 300px;
								&.marketplace-no-swatch
								{
									background-repeat: no-repeat !important;
									background-size: 200% !important;
									background-position: center center !important;
								}
								&.selected,
								&:hover {
									&:after {
										content: "";
										width: calc(100% + 2px);
										height: calc(100% + 2px);
										display: block;
										position: absolute;
										top: -1px;
										left: -1px;
										box-sizing: border-box;
										border: 2px solid rgba(60, 60, 60, 1);
										z-index: 1;
									}
								}

								&.focus-outline {
									outline-offset: -3px;
								}
								img {
									// actual swatch img hidden as per SC-9915
									visibility: hidden;
									margin: 0;
									border: 0;
									border-radius: 0;
									height: 100%;
									width: 100%;
									object-fit: cover;
								}
							}
						}
					}
				}
			}
		}
	}


.search-result-items {
	&.large-image-plp {
		.new-plp-tile {
			padding: 0;
			@media screen and (min-width: 1260px) {
				width: 33%;
				flex-basis: 33%;
			}
			&.one-space-tile {
				width: 50%;
				flex-basis: 50%;
				@media screen and (min-width: 768px) {
					width: 33%;
					flex-basis: 33%;
				}
			}
			&.two-space-tile {
				width: 100%;
				flex-basis: 100%;
				@media screen and (min-width: 768px) {
					width: 66%;
					flex-basis: 66%;
				}
			}
			&.three-space-tile {
				width: 100%;
				flex-basis: 100%;
			}
			@media screen and (min-width: 1261px) {
				.product-tile {
					width: 304px;
				}
			}
		}
	}
}

.product-search-page {
	.search-result-content {
		.search-result-items {
			@media screen and (max-width: 1261px) {
				max-width: 1005px;
				margin: 0 auto;
			}
			&.normal-image-plp {
				.new-plp-tile {
					width: 50%;
					flex-basis: 50%;
					max-width: 100%;

					@media screen and (min-width: 768px) {
						width: 33%;
						flex-basis: 33%;
					}

					@media screen and (min-width: 1025px) {
						width: 25%;
						flex-basis: 25%;
					}

					@media screen and (min-width: 1261px) {
						width: 20%;
						flex-basis: 20%;
					}
				}
			}
		}
	}
}

.product-search-page {
	.search-result-content {
		.search-result-items {
			@media screen and (max-width: 1260px) {
				max-width: 1005px;
				margin: 0 auto;
			}
			&.large-image-plp {
				.new-plp-tile {
					width: 50%;
					flex-basis: 50%;
					max-width: 100%;

					@media screen and (min-width: 768px) {
						width: 33%;
						flex-basis: 33%;
					}

					@media screen and (min-width: 1261px) {
						width: 25%;
						flex-basis: 25%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.product-list-page
		.search-result-content
		.search-result-items.normal-image-plp
		li.new-plp-tile:nth-child(3n + 1)
		.product-tile,
	.product-list-page
		.search-result-content
		.search-result-items.normal-image-plp
		li.new-plp-tile:nth-child(3n)
		.product-tile {
		float: none;
	}
}
@media screen and (max-width: 767px) {
	.normal-image-plp,
	.large-image-plp {
		overflow-x: hidden;
	}
}
