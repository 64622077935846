@import "../common/variables";
@import "../common/mixin";
@import "font";

.gift-heading {
    text-align: center;
    letter-spacing: 2.5px;
    @media screen and (max-width: 767px) {
        font-size: $base-font + 6px;
    }
}

.gift-paragraph {
    font-family: $Helvetica;
    font-size: $base-font + 2px;
    letter-spacing: 0.6px;

    @media screen and (max-width: 767px) {
        font-size: $base-font;
    }
}

// SCSS @extend class for password show value button

%btn-pwd-show {
    button.pwd-show {
        padding: 15px;
        background: transparent;
        border: none;
        color: $black;
        font-family: $HelveticaMedium, $sans-serif;
        font-size: 12px;
        position: absolute;
        top: -16px;
        right: -14px;
        -webkit-transform: translate(-14px, 20px);
        -ms-transform: translate(-14px, 20px);
        transform: translate(-14px, 20px);
        z-index: 1;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 300;
        width: auto;
        @include focus-outline();
    }
}

.dialog-tooltip {
    cursor: pointer;
    .dialog-tool-tip-content {
        display: none;
    }
}

.db-half-width {
    width: 50%;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear {
    clear: both;
}

.db-padding-rt-10-pre {
    padding-right: 10%;
    box-sizing: border-box;
    @media screen and (max-width: 767px) {
        padding-right: 0;
    }
}
.plcc-main .plcc-primary .form-row .birth-year.error + .selected-option,
.plcc-main .plcc-primary .form-row .birth-date.error + .selected-option,
.plcc-main .plcc-primary .form-row .birth-month.error + .selected-option {
    text-indent: 0;
}

@media screen and (max-width: 767px) {
    .mb-full-width {
        width: 100%;
    }
    .slider-menu {
        #navigation-bar {
            .scroll-wrapper {
                .registered-user {
                    .account-links {
                        a.email-preferences {
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }
}

.plcc-main {
    margin-top: 22px;
    display: inline-block;
    width: 100%;
    .option-text {
        position: absolute;
        right: 12px;
        top: 23%;
        height: auto;
        color: $dim-gray;
    }
    .rate-table {
        width: 100%;
    }
    .error-terms {
        color: $sangria;
    }
    input[type="checkbox"].error {
        outline: 2px solid $sangria;
    }
    &.plcc-pre-approval-main {
        .plcc-main-heading {
            font-size: $base-font + 6px;
            color: $black;
            margin: 0 0 15px 0;
        }
        .plcc-profile-summary {
            margin: 0 0 15px 0;
            .plcc-profile-name,
            .plcc-profile-address,
            .plcc-city,
            .plcc-profile-email {
                font-family: $Helvetica;
                font-size: $base-font;
            }
            .plcc-profile-name,
            .plcc-profile-address,
            .plcc-city,
            .plcc-profile-email {
                color: $dim-gray;
            }
            .plcc-edit-profile {
                color: $black;
                line-height: 19px;
                text-decoration: underline;
            }
        }
        .phone-number-msg {
            clear: both;
        }
        .plcc-form-section {
            width: 92.1%;
            padding: 38px 70px 38px 28px;
            background: $white-smoke-light;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            margin-bottom: 20px;
            display: inline-block;

            .plcc-masked-number {
                width: 32%;
                background: $white;
                display: inline-block;
                padding: 18px 15px 14px 15px;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                font-family: $HelveticaBold;
                font-size: $base-font + 2px;
                color: $black;
            }
            .plcc-form-row-wrapper {
                display: inline-block;
                width: 100%;
            }
            .form-row {
                &.ssn {
                    width: 68%;
                    float: right;
                }
            }
        }
        .plcc-primary {
            label {
                color: $dim-gray;
            }
            .plcc-ssn-label,
            .extra-label {
                color: $black;
            }
            .phone-number-msg,
            .child-support-section,
            .married-section,
            .important-info {
                clear: both;
            }
            .extra-label,
            .plcc-personal-address-section {
                font-size: $base-font;
                text-transform: uppercase;
            }
            .plcc-personal-address-section {
                font-family: $HelveticaBold;
                font-size: $base-font;
                margin: 0 0 15px 0;
            }
        }
    }
    .plcc-secondary {
        width: 31.7%;
        padding-right: 6%;
        display: inline-block;
        float: left;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        .see-benefits {
            margin-bottom: 10px;
            text-decoration: underline;
            display: inline-block;
        }
        .plcc-left-section {
            margin: 0 0 24px -2px;
        }
    }
    .plcc-authorized-buyer {
        clear: both;
        width: 100%;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        margin: 0 0 10px 0;
        .plcc-form-section {
            padding: 30px 20px;
            border-top: 1px solid $silver;
            border-bottom: 1px solid $silver;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            .plcc-left-nav-heading {
                margin: 0;
            }
            .plcc-form-row {
                &.plcc-form-row-1 {
                    width: 52%;
                    padding-right: 3%;
                    box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                }
            }
        }
        @media screen and (max-width: 767px) {
            .plcc-form-section {
                padding-left: 0;
                padding-right: 0;
                .plcc-form-row {
                    &.plcc-form-row-1 {
                        width: 100%;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .plcc-authorized-buyer-section {
        display: none;
        .plcc-authorized-buyer-block {
            margin: 10px 0 0 0;
        }
    }
    .plcc-left-nav-heading {
        margin: 0 0 11px 0;
    }
    .plcc-form-section {
        .plcc-left-nav-heading {
            margin: 0 0 10px 0;
            position: relative;
            .heading-text {
                display: inline-block;
                width: 100%;
                &.heading-click {
                    cursor: pointer;
                }
                .cr-icon {
                    background: url("../../../images/authorized-toggle-icons.png") no-repeat;
                    background-position: 0 0;
                    width: 13px;
                    height: 13px;
                    float: right;
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
        &.active {
            .plcc-left-nav-heading {
                .heading-text {
                    .cr-icon {
                        background-position: 0 -40px;
                    }
                }
            }
        }
    }
    .plcc-left-nav-info {
    }
    .question-mark-icon {
        background: url("../../images/tips.svg") no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        display: inline-block;
        float: right;
        outline-offset: -3px;
    }
    .plcc-left-section {
        display: inline-block;
        width: 100%;
        margin: 0 0 15px 0;
    }
    .plcc-primary {
        width: 68.3%;
        display: inline-block;
        float: left;
        padding-left: 31px;
        box-sizing: border-box;
        margin-top: 7px;
        .form-row.form-indent label {
            -webkit-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            margin-bottom: 15px;
        }
        .plcc-form-section {
            width: 79.8%;
            float: left;
        }
        .note-section {
            clear: both;
            text-align: center;
            border-radius: 3px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
        }
        .iframe-content {
            display: inline-block;
            width: 100%;
            margin: 0 0 15px 0;
            iframe {
                width: 100%;
                border-bottom-color: $dustygray;
                border-right-color: $dustygray;
            }
            .electronic-iframe {
                height: 315px;
            }
            .financial-iframe {
                height: 265px;
            }
            .account-term-iframe {
                height: 256px;
            }
            .financial-disc-iframe {
                height: 296px;
            }
        }
        .bottom-buttons {
            .submit-terms {
                .input-checkbox {
                    &:focus {
                        outline: 2px solid $black;
                    }
                }
            }
        }
        @media screen and (max-width: 1024px) {
            .account-term-iframe-block,
            .financial-disc-iframe-block,
            .cc-terms-iframe-content,
            .electronic-iframe-block {
                overflow: hidden !important;
                overflow-y: scroll !important;
                -webkit-overflow-scrolling: touch !important;
            }
            .account-term-iframe-block {
                height: 256px;
            }
            .financial-disc-iframe-block {
                height: 296px;
            }
            .cc-terms-iframe-content {
                height: 265px;
            }
            .electronic-iframe-block {
                height: 315px;
            }
        }

        .extra-label,
        .plcc-ssn-label,
        .phone-number-label {
            margin: 0 0 15px 0;
        }
        .plcc-ssn-label {
            text-transform: uppercase;
        }
        .form-action {
            float: right;
            width: auto;
            .form-row-button {
                float: left;
                &.cancel-button {
                    width: 120px;
                    button {
                        background: none;
                        border: none;
                        color: $black;
                    }
                    a {
                        color: $black;
                        font-size: $base-font + 2px;
                        padding: 19px 30px;
                        display: inline-block;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        outline-offset: -3px;
                    }
                }
                &.save-button {
                    button {
                        width: 200px;
                    }
                }
            }
        }
        .form-row {
            position: relative;
            display: block;
            .selected-option {
                text-indent: -9999px;
                background: transparent;
                border: 1px solid $silver;
            }
            select {
                border: 1px solid $silver;
            }
            &.form-indent {
                margin-left: 0;
                label {
                    position: static;
                    &.input-focus {
                        -webkit-transform: translate(0, 0);
                        -ms-transform: translate(0, 0);
                        transform: translate(0, 0);
                    }
                }
            }
            &.zipcode {
                .field-wrapper {
                    input[type="text"] {
                        width: 46.2%;
                    }
                }
            }
            &.month,
            &.day,
            &.year {
                margin: 0 7% 0 0;
            }
            &.month {
                width: 28.5%;
            }
            &.day {
                width: 28.5%;
            }
            &.year {
                width: 28.3%;
                margin-right: 0;
            }
            &.address2,
            &.email {
                label {
                    width: 89%;
                    width: calc(100% - 34px);
                }
            }
            &.show-text {
                .selected-option {
                    text-indent: 0;
                }
                &.field-valid {
                    .selected-option {
                        text-indent: -9999px;
                    }
                }
            }
            &.custom-select {
                background: $white;
                .input-focus + .field-wrapper .selected-option {
                    text-indent: 0;
                }
            }
            &.error-handle {
                &.state,
                &.postal,
                &.country {
                    label {
                        -webkit-transform: translate(5px, 21px);
                        -ms-transform: translate(5px, 21px);
                        transform: translate(5px, 21px);
                    }
                }
                label {
                    span {
                        &.label-text {
                            display: none;
                        }
                    }
                }
            }
        }
        label {
            font-weight: normal;
            font-size: $base-font;
            padding: 0;
            position: absolute;
            -webkit-transform: translate(5px, 20px);
            -ms-transform: translate(5px, 20px);
            transform: translate(5px, 20px);
            left: 12px;
            margin: 0;
            top: -3px;
            width: auto;
            color: $dim-gray;
            z-index: 1;
            -webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
            -ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            text-transform: capitalize;
            span {
                &.required-indicator {
                    display: none;
                }
            }
            &.input-focus {
                -webkit-transform: translate(5px, 8px);
                -ms-transform: translate(5px, 8px);
                transform: translate(5px, 8px);
                text-align: left;
            }
            .second-label {
                position: absolute;
                right: 0;
            }
        }
        .field-wrapper {
            margin-bottom: 20px;
            display: inline-block;
            input[type="date"],
            input[type="text"],
            input[type="password"],
            select,
            textarea,
            input[type="tel"] {
                padding: 18px 15px 14px 15px;
                height: auto;
                border-radius: 0;
                -moz-border-radius: 0;
                -webkit-border-radius: 0;
            }
            input[type="tel"] {
                border: 1px solid #aaaaaa;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                text-indent: 0;
                width: 100%;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
            @extend %btn-pwd-show;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1399px) {
        padding: 0 10px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;

        &.plcc-pre-approval-main {
            .plcc-form-section {
                width: 97.1%;
                padding: 38px 3% 38px 2%;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .plcc-primary {
            .form-row {
                &.month {
                    width: 29%;
                }
                .custom-select::after {
                    display: none;
                }
                .selected-option {
                    display: inline-block;
                }
                select {
                    position: absolute;
                    left: 0;
                    right: 0;
                    z-index: 2;
                    filter: alpha(opacity=0);
                    -moz-opacity: 0;
                    -khtml-opacity: 0;
                    opacity: 0;
                }
            }
            .bottom-buttons {
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 2;
                background: #fff;
                width: 100%;
                padding: 25px 15px 20px;
                box-sizing: border-box;
                margin: 0;
                .form-row.form-indent {
                    margin-bottom: 15px;
                }
            }
        }
        .plcc-secondary {
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        .plcc-secondary,
        .plcc-primary {
            width: 100%;
        }
        .plcc-secondary {
            padding-right: 0;
            max-width: 650px;
            float: none;
            display: block;
            width: 100%;
            margin: 0 auto;
            .plcc-left-section {
                width: 45.5%;
                &.plcc-left-section-1 {
                    float: left;
                }
                &.plcc-left-section-2 {
                    float: right;
                }
            }
        }
        .plcc-bottom-section {
            width: 708px;
            float: none;
            display: block;
            margin: 0 auto;
        }
        .plcc-authorized-buyer {
            width: 650px;
            float: none;
            margin: 0 auto;
            margin-bottom: 10px;
        }
        .plcc-primary {
            margin: 15px 0 0 0;
            padding: 0;
            .plcc-form-section {
                width: 650px;
                float: none;
                margin: 0 auto;
            }
        }
        &.plcc-pre-approval-main {
            width: 708px;
            margin: 22px auto 0;
            display: block;
            .plcc-secondary {
                max-width: 100%;
                .see-benefits {
                    margin-bottom: 20px;
                }
                .plcc-left-sections {
                    display: inline-block;
                    width: 100%;
                }
            }
            .custom-select {
                font-size: 0;
            }
            .plcc-primary {
                padding: 0;

                .phone-number-msg,
                .child-support-section,
                .married-section,
                .important-info {
                    font-size: $base-font + 1px;
                }
                .plcc-form-section {
                    width: 100%;
                    padding: 38px 28px 7px 28px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        padding: 0 22.5px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        .question-mark-icon {
            width: 15px;
            height: 15px;
            background-size: contain;
            margin-right: 2%;
        }
        &.plcc-pre-approval-main {
            padding: 0 10px;
            .question-mark-icon {
                margin-right: 8px;
            }
            .custom-select {
                font-size: 0;
            }
            .plcc-profile-summary {
                .plcc-profile-name,
                .plcc-profile-address,
                .plcc-city,
                .plcc-profile-email {
                    line-height: 20px;
                }
                .plcc-edit-profile {
                    line-height: 24px;
                }
            }

            .plcc-primary {
                .phone-number-msg,
                .child-support-section,
                .married-section,
                .important-info {
                    padding: 0 17px;
                }
                .form-action {
                    .form-row-button {
                        &.save-button {
                            button {
                                font-size: $base-font;
                            }
                        }
                    }
                }
                .plcc-form-row {
                    .plcc-form-row-1 {
                        margin-bottom: 20px;
                    }
                }
                .form-row {
                    &.month,
                    &.day {
                        margin-right: 5%;
                    }
                    &.month {
                        width: 28%;
                    }
                    &.day {
                        width: 30.5%;
                    }
                    &.form-indent {
                        padding: 0;
                        label {
                            line-height: 16.5px;
                        }
                    }
                }
                .plcc-ssn-label,
                .extra-label {
                    color: $slightblack;
                }
            }

            .plcc-form-section {
                padding: 25px 10px;
                width: 100%;
                .plcc-masked-number {
                    font-size: $base-font;
                }
            }

            .plcc-secondary {
                padding: 0 22.5px;
            }
            .plcc-main-heading {
                padding: 0 22.5px;
                font-size: $base-font + 2px;
                margin: 0 0 10px 0;
            }
        }
        .plcc-primary {
            width: 100%;
            padding: 0;
            .plcc-form-section,
            .plcc-form-row {
                width: 100%;
            }
            .form-row {
                &.month,
                &.day {
                    margin-right: 3%;
                }
                &.month {
                    width: 27%;
                }
                &.day {
                    width: 29.5%;
                }
                &.year {
                    width: 29.3%;
                }
            }
            .bottom-buttons {
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 11;
                background: #fff;
                width: 100%;
                padding: 25px 15px 20px;
                text-align: center;
                box-sizing: border-box;
                margin: 0;
                .form-row.form-indent {
                    margin-bottom: 0;
                }
            }
            .form-action {
                margin: 0;
                .form-row-button {
                    &.save-button {
                        float: right;
                        button {
                            width: 168px;
                        }
                    }
                    button {
                        padding-top: 16.5px;
                        padding-bottom: 16.5px;
                    }
                }
            }
        }
        .plcc-authorized-buyer {
            padding: 20px 0;
            .plcc-form-section {
                .plcc-left-nav-heading {
                    margin: 0;
                }
            }
        }
        .plcc-secondary {
            width: 100%;
            padding: 0;
            .see-benefits {
            }
        }
    }
}

/* Gift card Section */

.pt_giftcard_lookup {
    #main {
        min-height: 700px;
    }
    .gift-cart-bg-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        #main {
            overflow: hidden;
            margin: 0 0 20px 0;
            min-height: 500px;
        }
    }
}

.gift-card-lookup-section,
.gift-card-lookup-result-section,
.gift-card-lookup-results {
    position: relative;
}

.giftcard-lookup-error {
    clear: both;
    display: inline-block;
    width: 100%;
    color: $checkout-error-color;
}

.gift-card-section {
    width: 643px;
    display: inline-block;
    margin: 40px 0 0 117px;
    &.with-result-setion {
        .gift-paragraph {
            margin-bottom: 20px;
        }
    }
    input[type="text"] {
        height: 50px;
        padding: 12px;
    }
    .note-section {
        width: 50%;
        display: inline-block;
        padding-left: 48px;
        padding-right: 30px;
        padding-bottom: 39px;
        background: $white;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
        float: left;
        z-index: 1;
        .note {
            width: 100%;
            display: inline-block;
            margin: 14px 0 20px 0;
            line-height: 20px;
        }
        .another-card {
            width: 100%;
            display: inline-block;
            text-decoration: underline;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    .make-label-absolute {
        .form-row {
            position: relative;
            &.pin {
                width: 48%;
                float: left;
            }
            .field-wrapper {
                margin-bottom: 20px;
            }
            &.error-handle {
                label {
                    span {
                        &.label-text {
                            display: none;
                        }
                    }
                }
            }
        }
        label {
            font-weight: normal;
            font-size: $base-font;
            padding: 0;
            position: absolute;
            -webkit-transform: translate(5px, 20px);
            -ms-transform: translate(5px, 20px);
            transform: translate(5px, 20px);
            left: 9px;
            margin: 0;
            top: -3px;
            width: auto;
            color: $dim-gray;
            z-index: 1;
            -webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
            -ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            span {
                color: $dim-gray;
                text-transform: capitalize;
                font-size: $base-font;
                &.required-indicator {
                    display: none;
                }
                &.error {
                    color: $checkout-error-color;
                    text-transform: none;
                }
            }
            &.input-focus {
                -webkit-transform: translate(5px, 5px);
                -ms-transform: translate(5px, 5px);
                transform: translate(5px, 5px);
            }
        }
    }
    .balance-result-section {
        position: relative;
        background: $white;
        display: inline-block;
        width: 50%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 21px 25px 20px;
        float: left;
        .balance-result-block {
            width: 250px;
            color: $white;
            padding: 30px;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -moz-border-radius: 25px;
            -webkit-border-radius: 25px;
            border-radius: 25px;
        }
    }
    .gift-card-message,
    .gift-card-lookup-form-section {
        position: relative;
        background: $white;
        padding: 48px 40px 0;
        display: inline-block;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .gift-card-lookup-form-section {
        padding-bottom: 42px;
        padding-top: 20px;
        margin: -1px 0 0 0;
        form {
            width: 400px;
            margin: 0 auto;
        }
    }
    .form-action {
        float: right;
        width: 46%;
        text-align: right;
        button {
            width: 100%;
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        display: block;
        margin: 0 auto;
        padding-top: 40px;
    }
    @media screen and (max-width: 767px) {
        width: 91%;
        margin: 0 0 0 20px;
        padding-top: 10px;
        &.with-result-setion {
            margin: 0 0 20px 20px;
        }
        .gift-card-message,
        .gift-card-lookup-form-section {
            padding: 20px 30px 0;
        }
        .note-section {
            padding-left: 15px;
            padding-right: 16px;
            padding-bottom: 15px;
            width: 100%;
            z-index: 0;
            .note {
                margin-bottom: 10px;
            }
            .another-card {
                text-align: center;
            }
        }
        .balance-result-section {
            width: 100%;
            .balance-result-block {
                margin: 0 auto;
            }
        }
        .gift-card-message {
            padding: 20px 15px 0;
        }
        .gift-card-lookup-form-section {
            padding-top: 0;
            padding-bottom: 25px;
        }
        input[type="text"] {
            height: 48px;
        }
        .gift-card-lookup-form-section form {
            width: 100%;
        }
        .gift-heading {
            width: 63%;
            margin: 0 auto;
        }
        .gift-paragraph {
            letter-spacing: 0;
        }
        &.with-result-setion {
            .gift-paragraph {
                margin-bottom: 10px;
            }
        }
        .form-action {
            width: 100%;
            button {
                width: 100%;
                height: 48px;
            }
        }
        .make-label-absolute {
            .form-row {
                &.pin {
                    width: 100%;
                }
            }
        }
    }
}

.display-inline-block {
    display: inline-block;
}

.full-width {
    width: 100%;
}

.hide-button-txt {
    display: none;
}

.border-box {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.address-main-heading {
    margin-bottom: 15px;
}

.addressinfo {
    @media screen and (max-width: 767px) {
        padding: 0 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.default-indicator {
    background: url("../../../images/Dot_Green.svg") no-repeat left center;
    background-size: 8px;
    margin: 9px 0 0 41px;
    padding: 0 0 0 16px;
    @media screen and (max-width: 767px) {
        clear: both;
        margin: 0;
    }
}

.paragraph {
    clear: both;
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
    .noaddress {
        @media screen and (max-width: 767px) {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 10px;
            .address-form-block {
                padding: 34px 15px 30px 15px;
                border: none;
                .make-label-absolute {
                    .form-row {
                        width: 100%;
                        padding: 0;
                        &.postal {
                            width: 52.1%;
                            padding-right: 6.5%;
                        }
                        &.state {
                            width: 47.5%;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            .address-form-block {
                padding: 34px 20px 54px 22px;
                .make-label-absolute {
                    .form-row {
                        width: 50%;
                        padding: 0 3% 0 0;
                        &.postal {
                            width: 24.5%;
                        }
                        &.state {
                            width: 25.4%;
                        }
                    }
                }
            }
        }
    }
    &.profile {
        .account-nav-asset {
            display: none;
        }
    }
}

.address-list {
    padding: 0;
    margin: 0;
    @media screen and (max-width: 767px) {
        margin-top: 20px;
    }
    li {
        width: 43.8%;
        display: inline-block;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        &.column-1 {
            float: left;
        }
        &.column-2 {
            float: right;
        }
        .list-sec-address-form {
            .address-list-sec {
                border: none;
                padding: 0;
            }
        }
        .address-list-sec {
            position: relative;
            border: 1px solid $sliver-light;
            padding: 29px 63px 29px 47px;
            margin-bottom: 20px;
            display: inline-block;
            width: 100%;
            
            &:hover {
                background-color: $light-skygrey;
            }
            .delete-icon {
                display: none;
            }
            @media screen and (max-width: 1023px) {
                padding: 33px 30px;
                .delete-icon {
                    display: inline-block;
                    background: url("../../../images/icon-delete-x.svg") no-repeat left top;
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    top: 4px;
                    position: relative;
                }
                
                span.address-delete {
                    font-size: 18px;
                    display: inline-block;
                    position: absolute;
                    right: 28px;
                    top: 30px;
                    line-height: normal;
                    color: $dim-gray;
                }
            }
            .mini-address-title,
            .mini-address-name,
            .mini-address-location,
            .address-links {
                padding: 0 0 0 18px;
                word-break: break-all;
            }
            .address-links {
                padding-top: 4px;
            }
            .mini-address-title {
                text-transform: capitalize;
                line-height: 27px;
                @media screen and (max-width: 767px) {
                    padding-right: 20px;
                }
            }
            .delete-content {
                max-width: 100%;
                max-height: 100%;
                padding: 27% 17%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                display: none;

                .transparent {
                    background: $light-skygrey;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
                    filter: alpha(opacity=90);
                    -moz-opacity: 0.9;
                    -khtml-opacity: 0.9;
                    opacity: 0.9;
                }
                .delete-msg {
                    margin: 0 0 35px 0;
                }
                .delete-controls {
                    .cancel {
                        margin: 0 40px 0 0;
                        display: inline-block;
                        background: none;
                        padding: 0;
                        border: none;
                        letter-spacing: unset;
                        border-radius: 0;

                        &.focus-outline {
                            outline-offset: -3px;
                        }
                    }
                }
            }
        }
        .default {
            .mini-address-title {
                background: url("../../../images/Dot_Green.svg") no-repeat left top 10px;
                background-size: 8px;
            }
            &.address-list-sec {
                background: $light-skygrey;
            }
        }
    }
}

.address-list-sec {
    .address-info {
        div {
            line-height: 27px;
        }
        a,
        button.address-delete,
        button.address-edit {
            line-height: 36px;
            margin: 0 15px 0 0;

            &.focus-outline {
                outline-offset: -3px;
            }
        }
        a,
        button.address-delete,
        button.address-edit,
        .address-delete,
        .cancel {
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        button.address-delete,
        button.address-edit {
            background: none;
            border: none;
            padding: 0;
            border-radius: 0;
        }
    }
    address {
        font-style: normal;
    }
    .delete-content {
        .delete-controls {
            .cancel,
            .address-delete {
                cursor: pointer;
                text-transform: uppercase;
            }
        }
    }
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
    .resetpasswordpage {
        display: none;
    }
    .address-create {
        text-transform: uppercase;
        letter-spacing: 0.7px;
        background: none;
        color: $black;
        padding: 0;
        border: none;
        border-radius: 0;

        &.focus-outline {
            outline-offset: -3px;
        }
    }
    .input-checkbox {
        width: 15px;
        height: 15px;
        border: 0;
        outline: 1px solid $black;
        background: $white;
        margin: 0 0;
        &:checked {
            background-size: 80%;
            background-color: $white;
        }
    }
    #main {
        .input-checkbox {
            background: {
                image: url("../../../images/input_checkmark.svg");
                position: -55px -74px;
                repeat: no-repeat;
                size: auto;
                color: transparent;
            }
            width: 18px;
            height: 18px;
            border: 0;
            outline: 0;
            vertical-align: top;
            opacity: inherit;
            z-index: 9;
            margin: 0;
            border-radius: 0;

            &:checked {
                background-position: -55px -38px;
            }
        }
    }
    .form-validation-error-msg {
        &.error {
            color: $RedRibbon;
            text-align: left;
            display: block;
            width: 100%;
            margin-bottom: 15px;
        }
    }
    .address-conatiner {
        margin: 0 auto;
        max-width: 952px;
        .make-label-absolute {
            .form-row {
                &.error-handle {
                    &.postal {
                        label {
                            &.input-focus {
                                -webkit-transform: translate(5px, 11px);
                                -ms-transform: translate(5px, 11px);
                                transform: translate(5px, 11px);
                                width: 80%;
                                box-sizing: border-box;
                            }
                            span {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .noaddress {
        display: inline-block;
        width: 100%;
        margin-top: 33px;
        .makeDefault {
            display: none;
        }
        .cancel-address {
            display: none;
        }

        button.focus-outline {
            outline-offset: -3px;
        }

        .make-label-absolute {
            .form-row {
                &.error-handle {
                    &.postal {
                        label {
                            &.input-focus {
                                width: 75%;
                            }
                        }
                    }
                }
            }
        }
    }
    .address-list {
        .address-form-block {
            padding: 30px 53px 33px 53px;
            @media screen and (max-width: 1023px) {
                padding: 26px 10px;
            }
            .make-label-absolute {
                .form-row {
                    width: 100%;
                    padding: 0;
                    &.postal {
                        width: 47.3%;
                        padding-right: 2.5%;
                        clear: both;
                    }
                    &.state {
                        width: 47.3%;
                        float: right;
                    }
                    &.country {
                        display: none;
                    }
                    &.form-row-button {
                        .apply-button {
                            width: auto;
                            float: right;

                            &.focus-outline {
                                outline-offset: -3px;
                            }

                            @media screen and (min-width: 1024px) {
                                width: 182px;
                            }
                        }
                        .cancel-address {
                            border: none;
                            border-radius: 0;
                            padding: 0;
                            margin: 17px 20px 17px 0;
                            background: none;
                            color:$black;
                            &.focus-outline {
                                outline-offset: -3px;
                            }
                        }
                    }
                }
            }
        }
    }
    .address-form-block {
        width: 100%;
        display: inline-block;
        clear: both;
        float: left;
        background: $light-skygrey;
        padding: 34px 52px 54px 52px;
        border: 1px solid $sliver-light;
        .form-horizontal {
            .field-wrapper {
                padding-left: 0;
                width: 100%;
            }
        }
        .make-label-absolute {
            .form-row {
                width: 44.5%;
                float: left;
                padding: 0 5% 0 0;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                margin: 0 0 17px 0;
                &.makeDefault {
                    .field-wrapper {
                        width: auto;
                        float: left;
                        margin-right: 0;
                    }
                    label {
                        position: static;
                        transform: none;
                        float: left;
                        padding-left: 10px;
                        span {
                            color: $black;
                            text-transform: none;
                            font-size: 11pt;
                            position:relative;
                            top:-3px;
                        }
                    }
                }
                &.form-row-button {
                    float: left;
                    clear: both;
                    .apply-button {
                        width: 100%;
                    }
                }
                &.firstname,
                &.phone,
                &.address1,
                &.makeDefault {
                    clear: both;
                }
                &.postal {
                    width: 21.1%;
                    padding-right: 2.5%;
                    clear: both;
                }
                &.state {
                    width: 23.5%;
                }
            }
            label {
                span {
                    &.error {
                        color: $checkout-error-color !important;
                        text-transform: none;
                    }
                }
                &.input-focus {
                    span {
                        font-size: $base-font;
                    }
                }
            }
        }
    } //Check order status page
    .check-status-order {
        .col-1 {
            width: 100%;
            .inner-block {
                max-width: 100%;
                .form-row {
                    width: 38%;
                    margin: 0 20px 20px 0;
                }
                .form-row-button {
                    text-align: left;
                    button {
                        padding: 16px 63px;
                    }
                }
                .error-form {
                    padding: 0;
                    margin: 0 0 20px 0;
                    color: $RedRibbon;
                    background-color: transparent;
                    border-radius: 0;
                    border: none;
                }
            }
        }
    }
}
.plcc-primary {
    .plcc-form-section{
        display:flex;
        justify-content: space-between;
        .plcc-form-row {
            width: 47.1%;
        }
        @media screen and (max-width: 480px){
            flex-direction: column;
            .plcc-form-row {
                width: 100%;
            }
        }
    }
}

.forgot-password {
    .error-handle {
        .label-text {
            display: none;
        }
        .error {
            color: $RedRibbon;
        }
    }
    &.ui-dialog {
        background-color: $light-grey;
        z-index: 10000 !important;
        .ui-widget-header {
            background-color: $light-grey;
        }
        .ui-icon-closethick {
            width: 23px;
            height: 23px;
            top: 60%;
            left: 45%;
        }
        .ui-dialog-titlebar-close {
            top: 38px;
            right: 54px;
        }
        .wrapper-forgot-password {
            padding: 0;
            margin: 0;
            max-width: 100%;
            background: transparent;

            p {
                font-size: $base-font;
                line-height: 20px;
                letter-spacing: -0.33px;
            }

            .pwdResetConfirmWrapper {
                border-bottom: 1px solid $alto;
                padding: 0 0 12px 0;

                img {
                    height: 60px;
                    width: auto;
                }

                h1 {
                    margin-top: 15px;
                    border-bottom: none;
                    padding-bottom: 5px;
                    @include WorkSansSemiBold($WorkSans);
                    letter-spacing: 0;
                }
                .passwordConfirmEmail {
                    font-size: $base-font;
                    @include WorkSansMedium($WorkSans);
                    word-break: break-all;
                    display: inline-block;
                }
                .passwordResetConfirmMsg {
                    font-size: $base-font;
                    @include WorkSansMedium($WorkSans);
                }
            }

            #PasswordResetForm {
                .form-row {
                    .field-wrapper {
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    &.resetemail {
                        &.error-handle {
                            .field-wrapper {
                                &:before {
                                    font: normal normal normal 28px/1 FontAwesome;
                                    font-size: 25px;
                                    text-rendering: auto;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    content: "\f071";
                                    color: $venetian-red;
                                    position: absolute;
                                    top: 11px;
                                    left: 11px;
                                    transition: all 0.3s ease;
                                }
                            }
                            label {
                                padding-left: 30px;
                                .error,
                                .server-error {
                                    color: $venetian-red;
                                }
                            }
                            input[type="text"] {
                                border: 1px solid $venetian-red;
                                padding-left: 47px;
                                &.error {
                                    padding-left: 27px;
                                }
                            }
                            .input-focus {
                                padding-left: 10px;
                                + .field-wrapper {
                                    &:before {
                                        font-size: 8px;
                                    }
                                    input[type="text"] {
                                        padding-left: 25px;
                                    }
                                }
                            }
                        }
                    }
                }
                button {
                    width: 100%;
                }
            }
            hr.bottom-divider {
                border: 0.5px solid $alto;
                margin: 0;
            }
            .password-reset-create-account-section {
                .create-message {
                    font-weight: 600;
                    margin-top: 18px;
                }
                .button {
                    box-sizing: border-box;
                    width: 100%;
                }
                .chat-support {
                    font-family: $WorkSans;
                    color: $black;
                    text-align: right;
                    font-weight: 600;
                    margin-top: 10px;
                    display: block;
                }
            }
        }
    }
    .wrapper-forgot-password {
        max-width: 990px;
        margin: 30px auto;
        background: $light-skygrey;
        padding: 40px 80px;
        box-sizing: border-box;
        @media screen and (max-width: 767px) {
            padding: 32px 20px;
        }
        h1 {
            font-size: $base-font + 12px;
            font-style: normal;
            margin: 4px 0 0 0;
            padding: 0 0 11px 0;
            border-bottom: 1px solid $alto;
        }
        .subheader {
            color: $sangria;
            font-weight: bold;
        }
        .pwdResetConfirmWrapper {
            border-bottom: 1px solid $alto;
            padding-bottom: 12px;

            img {
                height: 60px;
                width: auto;
            }

            h1 {
                margin-top: 15px;
                border-bottom: none;
                padding-bottom: 5px;
                @include WorkSansSemiBold($WorkSans);
                letter-spacing: 0;
            }
            .passwordConfirmEmail {
                font-size: $base-font;
                @include WorkSansMedium($WorkSans);
                word-break: break-all;
                display: inline-block;
            }
            .passwordResetConfirmMsg {
                font-size: $base-font;
                line-height: 22px;
                @include WorkSansMedium($WorkSans);
            }
        }
        .make-label-absolute {
            .resetemail {
                .field-wrapper {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .chat-support {
        font-family: $WorkSans;
        color: $black;
        text-align: right;
        font-weight: 600;
        display: block;
    }
    #dialog-container {
        padding: 0 71px 67px 73px;
        @media screen and (max-width: 767px) {
            padding: 0 20px 20px 20px;
        }
    }
    .ui-widget-header {
        height: 33px;
    }
    p {
        font-size: $base-font + 1px;
        font-family: $Helvetica;
        margin: 10px 0 17px 0;
    }
    .field-wrapper {
        width: 52.3%;
        margin: 0 0 35px 0;
        input[type="text"] {
            color: $black;
        }
    }
    button {
        width: 32.5%;
        margin: 0 0 30px 0;
        letter-spacing: 1px;
        color: $white;
    }
    .close-button {
        font-family: $HelveticaBold;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .thank-para {
        margin: 5px 0 5px 0;
        &:first-of-type {
            margin: 15px 0 0 0;
        }
        &:last-of-type {
            margin: 5px 0 17px 0;
        }
    }
    .confirm-button {
        margin: 0 0 84px 0;
        width: 47.5%;
    }
    .make-label-absolute {
        .form-row {
            position: relative;
            display: block;
        }
        .reset-error-caption {
            padding: 10px;
            margin: -23px 0 18px;
            background: $venetian-red;
            border-radius: 0 0 3px 3px;
            text-align: left;
            font-family: $WorkSans;
            font-size: $base-font;
            color: $white;
            width: auto;
            letter-spacing: -0.33px;
            line-height: 14px;

            .create-acclink {
                color: $white;
                text-decoration: underline;
            }
        }
        label {
            font-weight: normal;
            font-size: $base-font;
            padding: 0;
            position: absolute;
            -webkit-transform: translate(5px, 20px);
            -ms-transform: translate(5px, 20px);
            transform: translate(5px, 20px);
            left: 12px;
            margin: 0;
            top: -3px;
            width: auto;
            color: $dim-gray;
            z-index: 1;
            -webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
            -ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            span {
                color: $dim-gray;
                font-size: $base-font;
                &.required-indicator {
                    display: none;
                }
            }
            &.input-focus {
                -webkit-transform: translate(5px, 11px);
                -ms-transform: translate(5px, 11px);
                transform: translate(5px, 11px);
            }
        }
        .field-wrapper {
            input[type="text"],
            input[type="password"],
            select,
            textarea {
                padding: 23px 15px 8px 15px;
                height: auto;
            }
            @extend %btn-pwd-show;
        }
    }
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
    .primary-content {
        float: none;
        width: 100%;
        margin: 0;
        clear: both;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .setnewpassword {
        float: none;
        width: 78.1%;
        margin: 0 auto;
        margin-top: 30px;
        background-color: $light-skygrey;
        &.resetpassword-container {
            width: 100%;
            text-align: center;
            .resetpassword {
                width: 50%;
                padding-bottom: 40px;
                @media screen and (max-width: 1023px) {
                    width: 85.2%;
                }
            }
        }
        .setnewpassword-layout {
            width: 85.2%;
            margin: 0 auto;
        }
        .heading {
            width: 100%;
            display: inline-block;
        }
        h3 {
            font-size: $base-font + 12px;
            text-transform: uppercase;
        }
        p {
            font-family: $Helvetica;
            margin: 0 0 17px 0;
            &.form-error {
                color: $radical-red;
            }
        }
        .expiremessage {
            color: $radical-red;
        }
        .form-row {
            width: 41%;
            margin: 0 auto 25px;
            &.password,
            &.passwordconfirm {
                &.error-handle {
                    .field-wrapper {
                        &:before {
                            font: normal normal normal 28px/1 FontAwesome;
                            font-size: 25px;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            content: "\f071";
                            color: $venetian-red;
                            position: absolute;
                            top: 11px;
                            left: 11px;
                        }
                    }
                    label {
                        padding-left: 55px;
                        .error {
                            color: $venetian-red;
                        }
                    }
                    input[type="password"],
                    input[type="text"] {
                        border: 2px solid $venetian-red;
                        padding-left: 55px;
                    }
                }
            }
            &.password {
                .form-caption {
                    display: none;
                    padding: 10px;
                    margin: -1px 0 10px;
                    background: $venetian-red;
                    border-radius: 0 0 3px 3px;
                    text-align: left;
                    font-family: $WorkSans;
                    font-size: $base-font;
                    color: $white;
                    width: auto;
                }
            }
            &.passwordconfirm {
                .form-caption {
                    display: none;
                }
            }
        }
        .password-status {
            margin: 0px 0 10px;
        }
        .form-row-button {
            width: 23.9%;
            margin-bottom: 7%;
            button {
                color: $white;
                width: 100%;
                letter-spacing: 1px;
            }
        }
        button {
            color: $white;
        }
        .field-wrapper {
            margin: 0;
        }
        .form-caption {
            margin: 0;
            text-align: right;
            padding: 7px 0 7px 0;
            width: 100%;
            display: block;
        }
        .resetpassword {
            .login-data {
                padding: 18px 0 36px 0;
                .divider-line {
                    margin: 0 0 18px 0;
                    border: 0.5px solid $alto;
                }
                p {
                    font-family: $WorkSans;
                    font-size: $base-font;
                    font-weight: 500;
                    &.login-email {
                        margin-bottom: 10px;
                    }
                    b {
                        font-weight: 600;
                        word-spacing: -0.8px;
                    }
                }
            }
            .form-row {
                &.resetemail {
                    &.error-handle {
                        .field-wrapper {
                            &::before {
                                font: normal normal normal 28px/1 FontAwesome;
                                font-size: 25px;
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                content: "\f071";
                                color: $venetian-red;
                                position: absolute;
                                top: 11px;
                                left: 11px;
                                transition: all 0.3s ease;
                            }
                        }
                        .form-caption {
                            display: none;
                        }
                        label {
                            padding-left: 55px;
                            .error,
                            .server-error {
                                color: $venetian-red;
                            }
                        }
                        input[type="text"] {
                            border: 1px solid $venetian-red;
                            padding-left: 55px;
                            &.error {
                                padding-left: 20px;
                                color: $black;
                            }
                        }
                        .input-focus {
                            padding-left: 20px;
                            + .field-wrapper {
                                position: relative;
                                &::before {
                                    font-size: 8px;
                                    margin-top: 0.34rem;
                                    left: 6px;
                                    top: 0px;
                                    line-height: 8px;
                                }
                                input[type="text"] {
                                    padding-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
            hr.bottom-divider {
                border: 0.5px solid $alto;
                margin: 0;
            }
            .password-reset-create-account-section {
                .create-message {
                    font-weight: 600;
                    margin-top: 18px;
                }
                .button {
                    width: 100%;
                    margin-top: 0;
                    box-sizing: border-box;
                }
                .chat-support {
                    color: $black;
                    text-align: right;
                    font-weight: 600;
                    margin-top: 10px;
                }
            }
            #PasswordResetForm {
                button {
                    margin-bottom: 30px;
                    position: relative;
                    left: -4px;
                    top: 1px;
                    white-space: nowrap;
                }
            }

            .reset-error-caption {
                padding: 10px;
                margin: -15px 0 18px;
                background: $venetian-red;
                border-radius: 0 0 3px 3px;
                text-align: left;
                font-family: $WorkSans;
                font-size: $base-font;
                color: $white;
                width: 100%;
                box-sizing: border-box;
            }
            button {
                letter-spacing: 1px;
            }
            a {
                font-size: $base-font;
                font-family: $HelveticaBold;
                text-transform: uppercase;
                margin: 31px 0 54px 0;
                display: inline-block;

                &.create-acclink {
                    text-decoration: underline;
                    text-transform: lowercase;
                    margin: 0;
                    color: $white;
                    font-weight: normal;
                    display: inline;
                    line-height: 14px;
                    letter-spacing: -0.33px;
                }
            }
        }
        .make-label-absolute {
            .form-row {
                display: block;
            }
            .field-wrapper {
                width: 100%;
            }
            .back-to-login {
                margin-top: 0;
            }
        }
    }

    .login-data {
        padding: 31px 0 54px 0;
        span {
            line-height: 18px;
        }
    }
    .make-label-absolute {
        .form-row {
            position: relative;

            &.login-rememberme {
                label {
                    position: absolute;
                    vertical-align: middle;
                    line-height: 20px;
                    padding-left: 15px;
                    top:-3px;

                }
            }
        }
        label {
            font-weight: normal;
            font-size: $base-font;
            padding: 0;
            position: absolute;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            left: 16px;
            margin: 0;
            top: -3px;
            width: auto;
            color: $dim-gray;
            z-index: 1;
            -webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
            -ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
            span {
                color: $dim-gray;
                text-transform: capitalize;
                font-size: $base-font + 2px;
                display: inline;
                &.required-indicator {
                    display: none;
                }
            }
            &.input-focus {
                -webkit-transform: translate(5px, 11px);
                -ms-transform: translate(5px, 11px);
                transform: translate(5px, 11px);
            }
        }
        .orderstatus-section {
            .form-row {
                label {
                    transform: none;
                    &.input-focus {
                        transform: none;
                        letter-spacing: 0.5px;
                        .label-text {
                            font-size: 14px;
                            transition: none;
                        }
                    }
                }
            }
            .form-row.domestic, .form-row.international {
                .field-wrapper {
                    display: flex;
                }
            } 
        }
        .field-wrapper {
            input[type="text"],
            input[type="password"],
            select,
            textarea,
            input[type="tel"] {
                padding: 18px 15px 8px 20px;
                height: auto;
                border-radius: 0;
            }
            @extend %btn-pwd-show;
        }
    }
    &.paymentinstruments {
        #secondary {
            .account-nav-asset {
                margin: 0 0;
            }
        }
    } //Add card section.
    .paymentslist {
        max-width: 1024px;
        margin: auto;
        width: 100%;
        clear: both;
        span {
            &.is-default {
                background: url(../../images/Dot_Gray.svg) center no-repeat;
                width: 8px;
                height: 8px;
                display: inline-block;
                margin-right: 9px;
                &.yes {
                    background: url(../../images/Dot_Green.svg) center no-repeat;
                }
            }
        }
        .top-content {
            padding: 34px 30px 20px;
            clear: both;
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            h1 {
                display: inline-block;
                margin: 0 0;
            }
            .Payment-Methods-heading {
                color: $mortar-grey;
                font-size: $base-font;
            }
            .default-msg {
                margin-left: 18px;
                display: inline-block;
                line-height: 24px;
                vertical-align: top;
            }
        }
        .no-card-msg {
            margin: 0 0 18px 32px;
            font-size: $base-font + 2px;
            display: inline-block;
        }
        .form-horizontal {
            .field-wrapper {
                width: 100%;
                padding-left: 0;
                margin-bottom: 20px;
            }
        }
        &.make-label-absolute {
            .makeDefault {
                label.input-focus {
                    transform: none;
                    margin: 3px 0 0 14px;
                    span {
                        font-size: $base-font + 1px;
                        color: $black;
                        text-transform: none;
                        transform: none;
                    }
                }
            }
        }
        .payment-form {
            background: $light-skygrey;
            padding: 30px 0 35px;
            margin-bottom: 5px;
            .innner-content {
                max-width: 695px;
                margin: auto;
                .content-wrapper {
                    display: inline-block;
                    width: 100%;
                    margin: 2px 0 0 22px;
                    box-sizing: border-box;
                }
            }
            .form-indent {
                margin-left: 0;
                margin-bottom: 15px;
            }
            .form-row {
                label {
                    span {
                        text-transform: none;
                    }
                }
            }
            .right-cont {
                float: right;
                max-width: 305px;
                width: 40.6%;
                width: 45%;
                margin-top: -3px; //content asset
                .billing-payment-description {
                    width: 100%;
                    float: left;
                    .heading {
                        font-size: $base-font + 2px;
                        text-transform: none;
                    }
                    .payment-cards {
                        border-bottom: 1px solid $alto;
                        padding: 7px 0 17px;
                        img {
                            padding-right: 15px;
                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                    .brand-cards {
                        span {
                            font-size: $base-font + 2px;
                            margin: 20px 0 7px;
                            display: inline-block;
                            width: 100%;
                        }
                        ul {
                            margin: 0 0;
                            padding: 0 0;
                            li {
                                list-style-type: none;
                                float: left;
                                max-width: 97px;

                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .left-cont {
                max-width: 291px;
                float: left;
                width: 42%;
                .form-row {
                    &.month,
                    &.year {
                        width: 48%;
                        margin: 0;
                        select {
                            width: 100%;
                        }
                    }
                    &.year {
                        float: right;
                    }
                    &.makeDefault {
                        display: inline-block;
                        width: 100%;
                        margin: 6px 0 0 1px;
                    }
                }
            }
            .card-actions {
                width: 100%;
                padding: 0 0;
                display: inline-block;
                box-sizing: border-box;
                .checkbox {
                    float: left;
                    width: 290px;
                    margin-top: 5px;
                }
                .save-cancel {
                    float: right;
                    .save-button {
                        min-width: 180px;
                        outline-offset: -2px;
                    }
                    .cancel-button {
                        margin-right: 44px;
                        font-size: $base-font;
                        letter-spacing: 1px;
                        outline-offset: -3px;
                        padding: 3px;
                    }
                }
            }
        } // Saved card section
        .payment-list {
            background: $light-skygrey;
            clear: both;
            padding: 30px;
            .showall {
                text-align: center;
                width: 100%;
                display: inline-block;
                margin-top: 10px;
                letter-spacing: 1px;
                font-size: $base-font - 1px;
            }
            .cardinfo {
                width: 100%;
                clear: both;
                display: inline-block;
                .carddetails {
                    background: $white;
                    margin-bottom: 10px;
                    padding: 0 0;
                    box-sizing: border-box;
                    border: 1px solid $nobel;
                    font-size: $base-font + 1px;
                    letter-spacing: 0.2px;
                    display: inline-block;
                    width: 100%;
                    .plcc-card-message {
                        font-size: $base-font;
                        padding: 13px 0 0 29px;
                        display: inline-block;
                        @media screen and (max-width: 767px) {
                            padding: 13px 20px 0 20px;
                        }
                    }
                    .card-li {
                        padding: 11px 13px 8px;
                        display: inline-block;
                        width: 100%;
                        box-sizing: border-box;
                        .plcc-tooltip {
                            width: auto;
                            text-align: center;
                            max-width: 100%;
                            margin: 0 auto;
                            box-sizing: border-box;
                            @media screen and (max-width: 767px) {
                                width: 100%;
                                padding-left: 66px;
                                text-align: left;
                                box-sizing: border-box;
                                margin: 6px 0 0 0;
                            }
                            .question-mark-icon {
                                background: url(../../images/tips.svg) no-repeat;
                                padding: 0;
                                margin: 0;
                                display: inline-block;
                                text-indent: -9999px;
                                cursor: pointer;
                                width: 16px;
                                height: 16px;
                                background-size: cover;
                            }
                        }
                    }
                    div.li {
                        width: auto;
                        display: inline-block;
                        margin: 6px 0 0 0;
                        float: left;
                        &.make-default {
                            display: none;
                        }
                    }
                    .li.cardtype {
                        width: 30%;
                        margin: 0 0;
                        @media screen and (min-width: 767px) and (max-width: 1024px) {
                            display: table;
                            width: 27.2%;
                        }
                        span.img {
                            background: url("../../images/payment-sprite-image.png") no-repeat;
                            width: 35px;
                            height: 24px;
                            display: inline-block;
                            float: left;

                            &.amex {
                                background-position: -107px -3px;
                                width: 37px;
                            }
                            &.applepay {
                                background-position: -249px -3px;
                                width: 36px;
                            }
                            &.discover {
                                background-position: -157px -3px;
                            }
                            &.mastercard {
                                background-position: -59px -3px;
                            }
                            &.paypal {
                                background-position: -203px -3px;
                                width: 36px;
                            }
                            &.visa {
                                background-position: -9px -3px;
                            }

                            &.bh_plcc, &.BH_PLCC {
                                background-position: -246px -34px;
                                width: 37px;
                            }
                            &.ca_plcc, &.CA_PLCC {
                                background-position: -157px -34px;
                            }
                            &.fb_plcc, &.FB_PLCC {
                                background-position: -9px -34px;
                            }
                            &.jl_plcc, &.JL_PLCC {
                                background-position: -203px -34px;
                            }
                            &.ks_plcc, &.KS_PLCC {
                                background-position: -290px -34px;
                            }
                            &.rm_plcc, &.RM_PLCC {
                                background-position: -109px -34px;
                            }
                            &.ww_plcc, &.WW_PLCC {
                                background-position: -60px -34px;
                            }
                        }
                        span.is-default {
                            float: left;
                            margin-top: 10px;
                        }
                        .cardname {
                            line-height: 26px;
                            margin-left: 15px;
                            @media screen and (min-width: 767px) and (max-width: 1024px) {
                                display: table-cell;
                                vertical-align: middle;
                                width: 60.5%;
                                float: left;
                                margin-left: 10px;
                            }
                        }
                    }
                    .li.cardholder {
                        width: 21.8%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @media screen and (min-width: 767px) and (max-width: 1024px) {
                            width: 20.3%;
                        }
                    }
                    .li.cardnumber {
                        width: 18.6%;
                        @media screen and (min-width: 767px) and (max-width: 1024px) {
                            width: 20.6%;
                            letter-spacing: 0;
                        }
                        &.cardnumber-along-text {
                            margin: 0;
                            .available-credit {
                                display: block;
                            }
                            @media screen and (max-width: 767px) {
                                margin: 6px 0 6px 0;
                                .available-credit {
                                    display: inline;
                                    margin: 0 0 0 4px;
                                }
                            }
                        }
                    }
                    .li.expdate {
                        width: 16.9%;
                        @media screen and (min-width: 768px) {
                            margin-left: 16px;
                        }
                        @media screen and (min-width: 767px) and (max-width: 1024px) {
                            width: 17.2%;
                            margin-left: 10px;
                        }
                        &.rewardpoints-along-text {
                            margin: 0;
                            .rewards-text {
                                display: block;
                                @media screen and (max-width: 767px) {
                                    display: inline;
                                    margin: 0 0 0 4px;
                                }
                            }
                        }
                    }
                    .li.delete-card {
                        float: right;
                        width: auto;
                        .button-text {
                            font-size: $base-font - 1px;
                            font-weight: normal;
                            letter-spacing: 1.5px;
                            margin-right: 5px;
                            border-radius: 0;
                            -webkit-border-radius: 0;
                            -moz-border-radius: 0;
                            outline-offset: -3px;
                            padding: 3px;
                        }
                    }
                    &.selected {
                        border: 3px solid $black;
                    }
                    &.expired {
                        .expdate {
                            color: $RedRibbon;
                        }
                    }
                }
                .checkstatus {
                    padding: 16px 31px;
                    background: $light-skygrey;
                    box-sizing: border-box;
                    div {
                        display: inline-block;
                        width: 100%;
                        label {
                            position: static;
                            display: inline;
                        }
                        span {
                            margin-left: 11px;
                        }
                    }
                }
                &:last-of-type {
                    .carddetails {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .profile-info {
        width: 76.7%;
        margin: 0 auto;
        h1 {
            margin: 40px 0 13px 0;
        }
        h2 {
            text-transform: none;
            font-weight: normal;
        }
        .personal-info,
        .change-password {
            background-color: $light-skygrey;
            box-sizing: border-box;
            width: 47%;
            padding: 0 75px 50px 75px;

            @media screen and (max-width: 1024px) {
                padding: 0 25px 40px 25px;
            }

            @media screen and (max-width: 767px) {
                width: 100%;
            }

            .currentpassword {
                &.error-handle {
                    label {
                        &:not(.input-focus) {
                            span {
                                &.error {
                                    display: block;
                                    margin-top: -8px;
                                }
                            }
                        }
                    }
                }
            }

            .password,
            .newpassword,
            .newpasswordconfirm,
            .currentpassword {
                &.error-handle {
                    label {
                        &:not(.input-focus) {
                            width: 80%;
                            transform: translate(0, 15px);
                            text-align: left;
                        }
                    }
                }
            }
        }
        .personal-info {
            margin-right: 50px;
            @media screen and (max-width: 769px) {
                margin-right: 0;
                margin-bottom: 40px;
            }
            .form-caption {
                margin-bottom: 15px !important;
            }
        }
        .change-password {
            margin-left: 10px;
            @media screen and (max-width: 769px) {
                margin-left: 0;
            }
        }

        legend {
            background: none;
            text-transform: capitalize;
            font-weight: normal;
            border-bottom: 1px solid $alto;
            padding: 37px 0 17px 0;
            margin-bottom: 31px;
        }
        .inner-content {
            padding: 0 7.3% 3.6% 7.1%;
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
        }
        .make-label-absolute {
            .form-row {
                &.label-inline.form-indent {
                    label {
                        &.input-focus {
                            -webkit-transform: translate(0, 0);
                            -ms-transform: translate(0, 0);
                            transform: translate(0, 0);
                        }
                    }
                }
            }
        }
        .form-row {
            width: 100%;
            padding: 0;
            box-sizing: border-box;
            margin: 0;
            &.password,
            &.newpassword,
            &.newpasswordconfirm,
            &.passwordconfirm {
                .field-wrapper {
                    margin: 0;
                }
            }
            &.password,
            &.newpassword,
            &.newpasswordconfirm,
            &.passwordconfirm {
                &.error-handle {
                    .field-wrapper {
                        &:before {
                            font: normal normal normal 28px/1 FontAwesome;
                            font-size: 25px;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            content: "\f071";
                            color: $venetian-red;
                            position: absolute;
                            top: 11px;
                            left: 11px;
                        }
                    }
                    label {
                        padding-left: 55px;
                        .error {
                            color: $venetian-red;
                        }
                    }
                    input[type="password"],
                    input[type="text"] {
                        border: 2px solid $venetian-red;
                        padding-left: 55px;
                    }
                }
            }
            &.newpassword,
            &.password {
                .form-caption {
                    display: none;
                    padding: 10px;
                    margin: -1px 0 10px;
                    background: $venetian-red;
                    border-radius: 0 0 3px 3px;
                    text-align: left;
                    font-family: $WorkSans;
                    font-size: $base-font;
                    color: $white;
                }
            }
            &.password {
                .currentpassword {
                    margin-bottom: 20px;
                }
            }
        }
        .password-status {
            margin: 10px 0;
        }
        .field-wrapper {
            width: 100%;
            margin-bottom: 20px;
            padding-left: 0;
        }
        .form-caption {
            display: none;
        }
        .custom-select {
            width: 100%;
            float: none;
            background: transparent;
            .form-row {
                width: 100%;
            }
            .custom-select {
                width: 100%;
                .selected-option {
                    &.selected {
                        text-indent: 0;
                    }
                }
            }
        }
        button {
            letter-spacing: 1px;
            width: 100%;
        }
    }
}

.password_reset_checkout_modal {
    background-color: #f5f5f5 !important;
    border: 1px solid #aaaaaa !important;
    .ui-widget-header {
        background-color: #f5f5f5 !important;
    }
    .resetpassword {
        width: auto !important;
        .buttonOulined {
            margin: 0px 0 20px 0 !important;
        }
    }
    .setnewpassword {
        margin-top: 0 !important;
    }
    .studio-content {
        padding-top: 0 !important;
    }
}
//Email preferences page.
.legacy-sitegen .email-preferences {
    max-width: 960px;
    margin: 0 auto;
    clear: both;
    h1 {
        font-size: $base-font + 12px;
        &.email-header {
            margin: 0 0 21px;
            font-size: $base-font + 12px;
        }
        &.otherbrands-divider {
            font-weight: 500;
            clear: both;
            width: 100%;
            padding: 14px 11px 16px;
            border-top: 1px solid $alto;
            border-bottom: 1px solid $alto;
            background: $light-skygrey;
            box-sizing: border-box;
            margin: 10px 0;
            display: inline-block;
            font-size: $base-font + 12px;
        }
    }
    .edit-click {
        text-transform: uppercase;
        font-size: $base-font + 2px;
        .edit-icon {
            width: 12px;
            margin: 0 5px 0 20px;
        }
    }
    .top-content {
        padding: 34px 12px 6px;
    }
    &.thankyoumsg-main {
        .email-header {
            display: none;
        }
        .email-info {
            display: inline-block;
            clear: both;
            margin: 12px 0 0;
            width: 100%;
            span {
                margin: 0 0;
                float: left;
                &.email-currentaddress {
                    margin: 0 3px 0 0;
                }
            }
        }
    }
    .email-thankyou {
        h1 {
            &.offer {
                font-size: $base-font + 18px;
                margin: 0 0 5px;
                text-transform: uppercase;
            }
        }
        .code {
            border: 1px solid $alto;
            background: $light-skygrey;
            margin: 0 0;
            padding: 5px 10px;
            display: inline-block;
            margin-right: 10px;
            font-size: $base-font + 2px;
            text-transform: uppercase;
            .value {
                margin-left: 5px;
                letter-spacing: 1px;
            }
        }
    }
    .user-info {
        span {
            font-size: $base-font + 2px;
            margin: 12px 0;
            display: block;
            &.email-communication {
                margin: 10px 0;
                width: 58%;
            }
        }
    }
    .email-preference-row {
        clear: both;
        padding: 12px;
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        &.currentsite-brand {
            .col-2 {
                #guest-emailid-error {
                    float: left;
                }
                .email-description {
                    #guest-emailid-error {
                        float: right;
                        @media screen and (max-width: 767px) {
                            float: none;
                        }
                    }
                }
            }
        }
        //col-1
        .brand-catalog-image {
            width: auto;
            max-width: 18%;
            margin: 0;
            display: inline-block;
            vertical-align: top;
            img {
                max-width: 117px;
            }
            .content-asset p {
                margin: 0 0;
            }
        }
        .col-2 {
            display: inline-block;
            margin: 0;
            width: auto;
            max-width:100%;
           
            .left-text {
                width: 61.3%;
                max-width: 480px;
                margin: 0 0;
                float: left;
                margin-right: 88px;
                margin-right: 11.2%;
            }
            #guest-emailid-error {
                float: right;
                max-width: 27%;
                margin-top: 10px;
                font-family: $Helvetica;
                font-size: $base-font;
                color: $medium-carmine;
                line-height: 19px;
                letter-spacing: 0.5px;
                @media screen and (max-width: 767px) {
                    display: inline-block;
                    float: none;
                    max-width: 50%;
                }
            }
            .email-description {
                margin-top: 15px;

                @media screen and (max-width: 767px) {
                    display: inline-block;
                    float: none;

                    .left-text {
                        display: inline-block;
                        width: 60%;
                    }
                    .brand-catalog-image {
                        display: inline-block;
                        width: 30%;
                        margin-top: 16px;
                    }
                }

                @media screen and (min-width: 768px) and (max-width: 1024px) {
                    max-width: 70%;

                    .left-text {
                        width: 100%;
                    }
                }
            }

            .interest {
                font-size: $base-font + 1px;
                margin-top: 30px;
                clear: both;
                display: inline-block;
                width: 100%;
            }
            .preference-options {
                .options-checkbox {
                    font-size: $base-font + 1px;
                    float: left;
                    margin: 30px 41px 0 0;
                    .input-checkbox {
                        margin: 0 10px 0 0;
                    }

                    label {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
                .solid-wine-berry {
                    display: inherit;
                    float: left;
                    max-width: 185px;
                }
            }
            .subscribed-status {
                float: left;
                margin-top: 13px;
                max-width: 100%;
                width:100%;
                clear:both;

                @media screen and (max-width: 767px) {
                    max-width: 100%;
                }

                @media screen and (min-width: 768px) and (max-width: 1024px) {
                    max-width: 32%;
                    float: right;
                }
                .custom-slider-checkbox {
                    .switch {
                        float: none;
                        vertical-align: middle;
                    }
                }
                .event-msg {
                    padding: 10px 16px 14px 6px;
                    font-size: 12px;
                    letter-spacing: 0.83px;
                    color: #539704;
                    margin-top: 10px;
                    font-family: $Helvetica;

                    @media screen and (max-width: 767px) {
                        margin-top: 0;
                        padding-left: 3px;
                    }

                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        margin-left: 10px;
                    }
                }

                .brand-catalog-image {
                    display: none;
                }
            }
            .subscribe-action {
                margin-top: 26px;
                width: 100%;
                display: inline-block;
                .emailsubscribe-cancelupdate {
                    float: left;
                    margin-right: 20px;
                }
            }
        }
    }
    .sisterbrands {
        .email-preference-row {
            padding: 12px 12px 34px;
            margin-bottom: 10px;
            border-bottom: 1px solid $alto;
            &:last-child {
                border-bottom: 0;
            }
            .col-1 {
                display: inline-block;
                img {
                    max-width: 100px;
                }
            }
        }
    } //unathenticated users email preferences
    &.unauthenticated-user {
        margin: 34px auto;
        max-width: 1024px;
        .emailpreferences-header {
            padding: 0 50px 50px;
            border-bottom: 1px solid $alto;
            margin-bottom: 20px;
        }
        h1.mainheader {
            display: inline-block;
            margin: 0 0;
        }
        .subscribe-emailid {
            margin-top: 14px;
            display: inline-block;
            .form-row {
                width: 293px;
                float: left;
            }
            button {
                padding: 14px 51.4px;
            }
        }
    }
}

// Delete card confirmation dialog and email subs
.ui-dialog {
    &.delete-payment-card,
    &.email-unsubscription-section,
    &.international-modal {
        padding: 20px;
        box-sizing: border-box;
        .ui-dialog-titlebar-close {
            width: 16px;
            height: 18px;
        }
        .ui-icon-closethick {
            background: url("../../images/x-icon.svg") no-repeat;
            height: 16px;
            width: 16px;
        }
        .ui-widget-header {
            height: 14px;
        }
    }
    &.email-unsubscription-section {
        .ui-dialog-titlebar-close {
            padding: 0;
        }
    }
    .delete-creditcard-overlay {
        max-width: 500px;
        margin: auto;
        padding-bottom: 128px;
        h1 {
            border-bottom: 1px solid $alto;
            padding: 0 0 11px 0;
            margin-bottom: 17px;
            font-size: $base-font + 12px;
            text-transform: capitalize;
        }
        .waring-msg {
            margin-bottom: 20px;
            font-size: $base-font + 2px;
        }
        .cancle-delete-creditcard {
            margin-right: 17px;
            min-width: 200px;
        }
        .confirm-delete-creditcard {
            min-width: 200px;
        }
    }
    .ui-button {
        color: transparent;
    }
}

.email-frequency-modal {
    max-width: 487px;
    margin: 0 auto;
    font-size: $base-font + 1px;
    .modal-msg {
        font-size: $base-font + 2px;
        margin-bottom: 28px;
        display: block;
        line-height: 22px;
    }
    .custom-radio-wrapper {
        margin-bottom: 16px;
        .label {
            font-size: $base-font + 1px;
            color: $wcag-grey;
        }
        &.last {
            margin-bottom: 7px;
        }
    }
    .unsubscription-reasoncode {
        padding-left: 25px;
    }
    .other-reason-textbox {
        margin: 0 0 0 26px;
        textarea {
            max-width: 280px;
            border: 1px solid $black;
            margin: 0 0;
            padding: 0 10px;
            box-sizing: border-box;
            min-height: 66px;
        }
    }
    button {
        margin: 27px 0 18px 0;
    }
}

//International Modal in account page
.international-modal {
    form {
        max-width: 620px;
        margin: auto;
        .form-row-button {
            margin: 30px 0 36px;
            @media screen and (min-width: 768px) {
                float: right;
            }
            button {
                float: right;
                letter-spacing: 2px;
                &.nevershowagain-button {
                    margin-right: 20px;
                    float: left;
                }
                &.close-button {
                    min-width: 200px;
                }
            }
        }
    }
}

//Guest user
.emailsubscription-guest {
    max-width: 1020px;
    margin: 40px auto;
    @media screen and (max-width: 1024px) {
        margin: 0 auto;
        padding: 20px;
    }
    .subcription-content {
        display: inline-block;
        width: 100%;
        .content {
            margin: 20px auto;
        }
        .email-benefits {
            text-align: center;
            font-size: $base-font + 6px;
            margin: auto;
            line-height: 22px;
            background: #f5f5f5;
            padding: 30px;
            color: #666666;
            .email-heading {
                text-align: center;
                font-weight: normal;
                text-transform: uppercase;
                font-family: "Futura LT W01 Medium";
                font-size: 24px;
                letter-spacing: 3px;
                margin: 0 0 10px 0;
                color: #000000;
            }
        }
        .preference-options {
            margin: 30px 0 0 0;
            text-align: center;
            background: #f5f5f5;
            .options-checkbox {
                display: inline-block;
                margin: 16px 41px 15px 0;
                font-size: 13px;
                &.hide {
                    display: none;
                }
                .label {
                    font-size: $base-font + 1px;
                    margin-left: 10px;
                }
                @media screen and (min-width: 768px) {
                    &:first-child {
                        padding-left: 9%;
                    }
                }
                @media screen and (max-width: 767px) {
                    &:nth-child(2n + 1) {
                        clear: both;
                    }
                    .label {
                        display: block;
                        margin-left: 29px;
                    }
                }
            }
            .subscribe-action {
                margin: 24px auto 20px;
                display: inline-block;
                width: 100%;
            }
            .solid-wine-berry {
                width: 165px;
                padding-left: 0;
                padding-right: 0;
            }
            .email-ligal {
                color: $dim-gray;
                text-align: center;
            }
        }
    }
    .qa-section {
        margin-top: 20px;
    }
    .disclaimer {
        text-align: left;
    }
    @media screen and (max-width: 1024px) {
        margin: 0 auto;
        padding: 20px;
    }
    @media screen and (max-width: 767px) {
        .disclaimer {
            text-align: center;
        }
    }
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
    .email-unsubscribe-guest {
        &.make-label-absolute {
            .form-row {
                @media screen and (min-width: 768px) {
                    .emailsubscribeguest-unsubscribe {
                        height: auto;
                    }
                }
            }
        }
    }
}

.subscribe-emailid {
    text-align: center;
    margin: 0 auto;
    width: 41%;

    .field-wrapper {
        width: auto;
        float: left;

        .error {
            clear: both;
            float: left;
            padding-top: 0;
        }
    }
    .form-row-button {
        float: left;
        display: inline-block !important;

        button {
            line-height: 15px;
        }
    }
    .email-box {
        input {
            width: 250px;
            height: 54px;
            padding: 0 18px;
            border: 1px solid $dusty-gray;
            box-sizing: border-box;
            vertical-align: top;
            float: left;
            @media screen and (min-width: 768px) {
                border-right: none;
            }
        }
    }
}

.left-padding {
    @media screen and (min-width: 1024px) {
        padding-left: 5%;
    }
}

.unsubscribe-confirmation-email {
    .email-frequency-modal {
        max-width: 100%;
        margin: 20px auto 0;
        .custom-radio-wrapper {
            margin-bottom: 26px;
        }
    }
    .subscribe-emailid {
        width: 100%;
        text-align: left;
        margin-top: 18px;
        .email-box {
            width: 290px;
        }
        button {
            border: 0;
        }
    }
    .email-unauthenticatedunsubscribe-subhead {
        font-size: $base-font + 1px;
        span {
            margin: 7px 0;
            display: inline-block;
            width: 100%;
            &.sub-head1 {
                margin: 0 0 10px 0;
            }
        }
    }
}

// catalog request page.
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account{
    .catalog-guest {
        #secondary {
            &.nav {
                display: none;
            }
        }
    }
    .catalog-registered {
        .account-nav-asset {
            margin: 0;
            padding: 0;
        }
    }
    .catalog-request {
        max-width: 931px;
        width: 100%;
        margin: 0 auto;
        .address-form-block {
            .make-label-absolute {
                .form-row {
                    &.state {
                        .field-wrapper {
                            .custom-select {
                                .selected-option {
                                    padding: 19px 27px 0px 16px;
                                    line-height: 14px;
                                    &::before {
                                        background-position: 2px 1px;
                                        top: 42%;
                                        right: 0px;
                                        width: 28px;
                                    }
                                }
                                &.current_item {
                                    .selected-option {
                                        &::before {
                                            background-position: 2px 1px;
                                            top: 42%;
                                            right: 0px;
                                            width: 28px;
                                        }
                                    }
                                }
                                select {
                                    @media screen and (max-width: 1024px) {
                                        padding: 19px 34px 9px 16px;
                                    }
                                }
                            }
                        }
                    }
                    button {
                        outline-offset: -3px;
                    }
                }
            }
        }
        .catalog-request-header {
            font-size: 24px;
            margin: 34px 0 17px 0;
            width: 100%;
            float: left;
            .catalog-prefernces {
                width: 100%;
                float: left;
            }
        }
        .catalog-request-step1 {
            width: 100%;
            float: left;
            span {
                text-transform: uppercase;
            }
        }
        .catalog-request-step2 {
            width: 78%;
            float: left;
            padding-bottom: 18px;
            line-height: 20px;
            span {
                text-transform: uppercase;
            }
        }
        .catalog-request-callout {
            width: 100%;
            float: left;
            margin-bottom: 19px;
        }
        .address-form-block {
            padding: 37px 52px 45px 62px;
            margin: 17px 0 5% 0;
            width: 99.7%;
            .make-label-absolute {
                .form-row {
                    width: 40.2%;
                    margin: 0 0 12px 0;
                    padding: 0px 4.4% 0 0;
                    &.postal {
                        width: 19%;
                        padding: 0px 2.2% 0 0;
                    }
                    &.state {
                        width: 21.2%;
                    }
                    input {
                        padding: 22px 15px 9px;
                        font-size: 14px;
                        font-family: $Helvetica;
                    }
                    &.form-row-button {
                        .apply-button {
                            width: 73.6%;
                            padding: 16px 10px;
                            letter-spacing: 1px;
                            &:hover {
                                color: $white;
                                background-color: $wine-berry;
                            }
                        }
                    }
                    label {
                        &.input-focus {
                            -webkit-transform: translate(0, 3px);
                            -moz-transform: translate(0, 3px);
                            transform: translate(0, 3px);
                            span {
                                font-size: 11px;
                            }
                        }
                        span {
                            font-family: $Helvetica;
                            font-size: 12px;
                        }
                    }
                }
            }
            .address-section {
                div {
                    &.catalog-prefernces {
                        padding: 12px 0 27px 11px;
                        width: 100%;
                        float: left;
                        div {
                            &.form-row {
                                width: 100%;
                                margin: 0 0 10px 0;
                                label {
                                    margin-left: 5px;
                                    -webkit-transform: translate(5px, 9px);
                                    -ms-transform: translate(5px, 9px);
                                    transform: translate(5px, 9px);
                                    span {
                                        &.label-text {
                                            font-size: 13px;
                                            color: $black;
                                        }
                                    }
                                }
                                .field-wrapper {
                                    width: 2.4%;
                                    margin: 6px 0 0 0;
                                    .custom-radio-icon {
                                        margin-top: 7px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
        #main {
            padding: 0 20px;
        }
    }
}

@media screen and (max-width: 1023px) {
    .legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
        #secondary {
            &.resetpasswordpage {
                display: none;
            }
        }
        .setnewpassword {
            width: 93%;
            .form-row {
                width: 47.7%;
            }
            .form-row-button {
                width: 30%;
            }
            .field-wrapper {
                width: 53%;
            }
        } //Add card section.
        .paymentslist {
            padding: 20px;
            box-sizing: border-box;
            max-width: 100%;
            .top-content {
                padding: 32px 0px 10px;
            }
            .no-card-msg {
                margin: 0 0 18px 0;
            }
            .payment-form {
                .innner-content {
                    max-width: 100%;
                    .content-wrapper {
                        padding: 0 20px;
                        box-sizing: border-box;
                        margin: 0 0;
                    }
                }
                .left-cont {
                    margin-left: 0;
                }
                .right-cont {
                    width: auto;
                }
                .card-actions {
                    padding: 0 0;
                }
            } // Saved card section
            .payment-list {
                padding: 0;
                background: transparent;
                .cardinfo {
                    .carddetails {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        .profile-info {
            width: 98%;
            .inner-content {
                padding: 0 3.4% 5% 3.4%;
            }
            legend {
                width: 100%;
            }
            .form-row {
                &:nth-child(even) {
                    padding: 0;
                }
                &.lastname {
                    padding: 0;
                }
                &.passwordconfirm {
                    .pwd-show {
                        right: 0;
                    }
                }
            }
            .form-row-button {
                float: right;
                padding: 0;
            }
        } //check order status page
        .check-status-order {
            .col-1 {
                .inner-block {
                    .form-row {
                        width: 46.9%;
                    }
                }
            }
        }
    }
    .legacy-sitegen .email-preferences {
        padding: 20px;
        margin: 0 auto;
        .top-content {
            padding: 11px 0 6px;
        }
        .user-info {
            span {
                &.email-communication {
                    width: 75%;
                }
            }
        }
        .email-preference-row {
            padding: 12px 0;

            .col-2 {
                width: 80%;

                .left-text {
                    width: 73.4%;
                    margin-right: 0;
                }

                .subscribed-status {
                    float: right;

                    .custom-slider-checkbox {
                        .switch {
                            float: right;

                            @media screen and (max-width: 767px) {
                                float: none;
                                display: inline-block;
                            }
                        }

                        .slider-label {
                            display: inherit;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        float: none;

                        .custom-slider-checkbox {
                            display: inline-block;

                            &.hide {
                                display: none;
                            }

                            .slider-label {
                                text-align: left;
                                display: inline-block;
                                margin-left: 8px;
                            }
                        }
                    }
                }

                .interest {
                    margin-top: 0;
                }
            }
        }
        .sisterbrands {
            .email-preference-row {
                padding-left: 0;
            }
        }
        &.unauthenticated-user {
            .emailpreferences-header {
                padding: 0 0px 50px;
            }
            .subscribe-emailid {
                .form-row {
                    width: 250px;
                    &.error-handle {
                        label {
                            transform: none;
                            left: 0;
                            top: 54px;
                        }
                    }
                }
                button {
                    padding: 14px 44px;
                }
            }
        }
    }
    .legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
        .catalog-request {
            max-width: 730px;
            .address-form-block {
                padding: 37px 30px 45px 50px;
                .make-label-absolute {
                    .form-row {
                        width: 49.9%;
                        &.postal {
                            width: 23.5%;
                        }
                        &.state {
                            width: 26%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
        .setnewpassword {
            width: 100%;
            .form-row {
                width: 100%;
            }
            .form-row-button {
                width: 100%;
            }
            .field-wrapper {
                width: 100%;
            }
            .resetpassword {
                button {
                    width: 100%;
                }
                a {
                    width: 100%;
                    text-align: center;
                }
            }
        } //Add card section.
        .paymentslist {
            span {
                &.is-default {
                    background: none;
                    &.yes {
                        background: url(../../images/Dot_Green.svg) center no-repeat;
                    }
                }
            }
            .top-content {
                padding: 20px 0 0;
                h1 {
                    width: 100%;
                    margin-bottom: 10px;
                }
                .default-msg {
                    margin-left: 0;
                }
            }
            .close-on-mobile {
                position: absolute;
                right: 20px;
                top: 20px;
                background: url("../../images/close-on-mobile.png") no-repeat;
                width: 15px;
                height: 16px;
                font-size: 0 !important;
                margin: 0 0 !important;
                border-bottom-right-radius: 0;
                -webkit-border-bottom-right-radius: 0;
                -moz-border-bottom-right-radius: 0;
                border-top-left-radius: 0;
                -webkit-border-top-left-radius: 0;
                -moz-border-top-left-radius: 0;
            }
            .payment-form {
                position: relative;
                .no-card-msg {
                    margin: 0 0 18px 0;
                }
                .innner-content {
                    .content-wrapper {
                    }
                }
                .left-cont {
                    width: 100%;
                    max-width: 100%;
                }
                .right-cont {
                    width: 100%;
                    max-width: 100%;
                    float: left;
                }
                .card-actions {
                    padding: 0 0;

                    &.no-saved-cards {
                        .cancel-button {
                            display: none;
                        }
                    }

                    .checkbox {
                        width: 100%;
                    }
                    .save-cancel {
                        float: left;
                        width: 100%;
                        .save-button {
                            width: 100%;
                        }
                    }
                }
            } // Saved card section
            .payment-list {
                padding: 0 0;
                margin-top: 10px;
                .cardinfo {
                    .carddetails {
                        margin-bottom: 10px;
                        div.li {
                            &.make-default {
                                display: block;
                            }
                        }
                        .card-li {
                            position: relative;
                            padding: 20px;
                            &.default-card {
                                background: $light-skygrey;
                            }
                            .li.cardholder,
                            .li.cardnumber,
                            .li.expdate,
                            .li.delete-card,
                            .li.make-default {
                                width: 100%;
                                float: none;
                                padding-left: 66px;
                                box-sizing: border-box;
                            }
                            .li.cardtype {
                                width: 100%;

                                .plcc-tooltip {
                                    padding: 0;
                                    float: right;

                                    &.plcc-tooltip-main {
                                        width: auto;
                                    }
                                }
                            }
                            .li.make-default {
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
        .profile-info {
            width: 100%;
            .inner-content {
                padding: 0 5% 5% 5%;
            }
            legend {
                padding: 20px 0 20px 0;
                margin-bottom: 20px;
            }
            .form-row {
                width: 100%;
                &.password {
                    .form-caption {
                        padding: 7px 0 12px 0;
                    }
                }
                &.passwordconfirm {
                    .field-wrapper {
                        margin-bottom: 20px;
                    }
                }
                &.password {
                    .field-wrapper {
                        margin-bottom: 20px;
                    }
                }
            }
            .custom-select {
                width: 100%;
            }
        } //Check order status
        .check-status-order {
            .inner-block {
                .login-box {
                    padding: 0;
                    background-color: transparent;
                    margin: 0 10px 10px 10px;
                    h2 {
                        width: 100%;
                        border-bottom: 0;
                    }
                    .login-box-content {
                        fieldset {
                            padding: 20px 10px 0 10px;
                            box-sizing: border-box;
                            background: #f5f5f5;
                        }
                    }
                }
            }
            .col-1 {
                .inner-block {
                    .form-row {
                        width: 100%;
                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .legacy-sitegen .email-preferences {
        margin: 0 auto;
        padding: 0;
        h1 {
            &.email-header {
                margin: 0 0;
            }
        }
        .top-content {
            padding: 11px 10px 6px;
        }
        .email-preference-row {
            padding: 0 20px;
            box-sizing: border-box;
            .brand-catalog-image {
                float: right;
                margin: 0 0;
            }
            .col-2 {
                width: 100%;
                .subscribed-status {
                    .brand-catalog-image {
                        display: block;
                        margin-bottom: 20px;
                    }
                    .slider-label {
                        text-align: right;
                    }
                }
                .left-text {
                    width: 67%;
                    font-size: $base-font + 1px;
                }
                .preference-options {
                    width: 100%;
                    .options-checkbox {
                        margin: 20px 0px 0 0;
                        min-width: 47.5%;
                    }
                    .solid-wine-berry {
                        max-width: 100%;
                        margin-top: 20px;
                    }
                }
                .subscribe-action {
                    button {
                        width: 100%;
                        max-width: 100%;
                    }
                    .subscribe-action {
                        .emailsubscribe-cancelupdate {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .sisterbrands {
            padding: 0 10px;
            .email-preference-row {
                padding: {
                    left: 10px;
                    right: 10px;
                }
            }
        }
        .email-thankyou {
            .expirytime {
                width: 100%;
                display: inline-block;
                margin-top: 10px;
            }
        }
        &.unauthenticated-user {
            margin: 0 auto;
            .subscribe-emailid {
                width: 100%;
                .form-row {
                    width: 100%;
                }
                button {
                    width: 100%;
                    margin-top: 25px;
                }
            }
        }
    }
    .forgot-password {
        &.ui-dialog {
            .dialog-content {
                padding: 0 20px 20px 20px;
                display: inline-block;
            }
        }
        .field-wrapper {
            width: 100%;
        }
        button {
            width: 100%;
            padding: 14px 55px;
        }
        .close-button {
            float: left;
            width: 100%;
            text-align: center;
        }
    }
    .ui-dialog {
        &.delete-payment-card {
            padding: 10px;
        }
        .delete-creditcard-overlay {
            max-width: 100%;
            padding-bottom: 33px;
            .waring-msg {
                font-size: $base-font;
                .delete-card-number {
                    clear: both;
                    display: block;
                }
            }
            .cancle-delete-creditcard {
                width: 46.5%;
                min-width: 100px;
            }
            .confirm-delete-creditcard {
                width: 46.5%;
                float: right;
                min-width: 100px;
            }
        }
    }
    .email-frequency-modal {
        .unsubscription-reasoncode {
            padding-left: 15px;
        }
        .other-reason-textbox {
            textarea {
                max-width: 100%;
            }
        }
    }
    .international-modal {
        .ui-dialog-content {
            padding: 0 0;
        }
        form {
            margin-top: 25px;
            .form-row-button {
                margin: 20px 0 30px;
                button {
                    &.nevershowagain-button {
                        margin-right: 0;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .emailsubscription-guest {
        padding: 10px;
        .subcription-content {
            width: 100%;
            padding: 30px 0;
            box-sizing: border-box;
            .content {
                max-width: 100%;
                margin: 0;
                padding: 0 19px;
                box-sizing: border-box;
                background: $light-skygrey;
            }
            .preference-options {
                margin: 0 0;
                .options-checkbox {
                    margin: 15px 0 12.5px 0;
                    width: 50%;
                    text-align: left;
                    float: left;
                    padding-right: 10px;
                    box-sizing: border-box;
                    &.first {
                        padding-left: 0;
                    }
                }
                .subscribe-action {
                }
                .solid-wine-berry {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }
    .subscribe-emailid {
        width: 100%;
        .field-wrapper {
            width: 100%;
            margin: 0 0;
        }
        .form-row-button {
            width: 100%;
            margin-top: 20px;
        }
        .email-box {
            width: 100%;
        }
    }
    .unsubscribe-confirmation-email {
        .email-unauthenticatedunsubscribe-subhead {
            font-size: $base-font + 2px;
        }
        .subscribe-emailid {
            .email-box {
                width: 100%;
            }
        }
    }
    .legacy-sitegen .pt_account, .legacy-sitegen.pt_account{
        .catalog-request {
            max-width: 100%;
            width: 94%;
            .catalog-request-header {
                margin: 15px 0 10px 0;
            }
            .address-list-sec {
                font-size: 12px;
            }
            .catalog-request-callout {
                line-height: 21px;
                margin-bottom: 14px;
            }
            .catalog-request-step2 {
                padding-bottom: 18px;
                width: 100%;
            }
            .catalog-request-step1 {
                line-height: 18px;
            }
            .address-form-block {
                padding: 12px 11px 25px;
                div {
                    &.catalog-prefernces {
                        padding: 23px 0 13px 0;
                        div {
                            &.form-row {
                                margin: 0 0 22px 0;
                            }
                        }
                    }
                }
                .make-label-absolute {
                    .form-row {
                        width: 100%;
                        padding-right: 0;
                        &.form-row-button {
                            button {
                                &.catalog-preference {
                                    width: 100%;
                                }
                            }
                        }
                        &.postal {
                            width: 46.3%;
                            margin-right: 7%;
                            padding: 0;
                        }
                        &.state {
                            width: 46.6%;
                        }
                        &.form-row-button {
                            .apply-button {
                                width: 100%;
                            }
                        }
                    }
                    .field-wrapper {
                        input {
                            font-size: 12px;
                            padding: 18px 15px 15px 15px;
                        }
                        select {
                            padding-bottom: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
        .paymentslist {
            padding: 10px;
            .top-content {
                padding: 0;
                &.nocards {
                    margin: 0;
                }
                h1 {
                    font-size: $base-font + 6px;
                }
            }
            .no-card-msg {
                margin: 0 0 10px 0;
            }
            .payment-form {
                padding: 20px 0 10px;
                .innner-content {
                    .content-wrapper {
                        padding: 0 10px;
                    }
                }
            }
            .right-cont {
                .billing-payment-description {
                    .brand-cards {
                        ul {
                            li {
                                max-width: 92px;
                            }
                        }
                    }
                }
            } // Saved card section
            .payment-list {
                padding: 10px 0;
                position: relative;
            }
        }
    }
    .legacy-sitegen .email-preferences {
        .email-preference-row {
            .col-2 {
                .left-text {
                    width: 46%;
                    padding: 0 0 26px;
                }
                .preference-options {
                    width: 100%;
                }
                .subscribed-status {
                    .brand-catalog-image {
                        margin-bottom: 10px;
                    }
                }
            }
            .email-preference-brand {
                &.for-mobile {
                    img {
                        max-width: 150px;
                    }
                }
            }
        }
    }
    .plcc-main {
        .plcc-primary {
            .cc-terms-iframe-content {
                height: 265px;
                overflow: hidden;
                -webkit-overflow-scrolling: touch;
                overflow-y: scroll;
                &.no-scroll {
                    overflow: hidden;
                }
            }
        }
    }
}

.create-login {
    .password {
        input::-ms-clear,
        input::-ms-reveal {
            display: none;
        }
    }

    &.login-order-history {
        .col-1 {
            width: 100%;

            @media screen and (min-width: 768px) {
                display: flex;
                justify-content: space-between;
            }
        }

        .login-section,
        .check-order {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            background: $light-skygrey;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }

        .login-section {
            float: right;

            @media screen and (max-width: 1023px) {
                margin: 0 20px 0 0;
            }

            @media screen and (max-width: 767px) {
                margin: 30px 0 0 0;
            }

            .login-data {
                display: none;
            }

            .heading {
                margin: 17px 17px 15px 0;
                line-height: 20px;
            }
        }

        .check-order {
            margin: 0;

            @media screen and (max-width: 1023px) {
                margin: 0 20px;
            }

            @media screen and (max-width: 767px) {
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .legacy-sitegen .pt_account{ 
        .paymentslist {
            flex: 0 0 93.33333%;
            &.make-label-absolute .form-horizontal {
                .form-row.month label, .form-row.year label {
                    max-width: 90px;
                }
            }
        }
    }
    .legacy-sitegen .email-preferences {
        .email-preference-row {
            &.currentsite-brand {
                .col-2 {
                    form {
                        .email-description {
                            max-width: 100%;

                            .left-text {
                                width: 70%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.fewer-radio-class {
    padding: 15px 0px;
    margin-right: -5px;
    label {
        font-family: $Helvetica;
        font-size: $base-font + 1px;
        letter-spacing: 0.6px;
        color: $black;
    }
    #fewer-contact {
        margin: 0 5px;
    }
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
    .make-label-absolute {
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            button[name="dwfrm_ordertrack_findorder"] {
                padding: 12.5px 38px !important;
            }
        }
        form {
            .form-row {
                &.error-handle {
                    label {
                        span {
                            &.error,
                            &.required-indicator,
                            &.server-error {
                                color: $venetian-red;
                            }
                        }
                    }
                }
                label {
                    span {
                        &.server-error {
                            display: block;
                            color: $venetian-red;
                            text-align: left;
                            letter-spacing: -0.4px;
                            + .label-text,
                            + .required-indicator {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.password-status {
    span {
        font-family: $WorkSans;
        font-size: $base-font;
        color: $black;
        font-weight: 600;
        margin-right: 12px;
        i {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 6px;
        }
        &.default {
            i {
                color: $black;
                opacity: 0.3;
                &:before {
                    content: "\f111";
                }
            }
        }
        &.success {
            i {
                color: $pastel-green;
                opacity: 1;
                &:before {
                    content: "\f111";
                }
            }
        }
        &.fail {
            color: $venetian-red;
            i {
                color: $venetian-red;
                opacity: 1;
                position: relative;
                width: 10px;
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 8px solid $venetian-red;
                    position: absolute;
                    top: -8px;
                }
            }
        }
    }
}
.ui-dialog button.ui-dialog-titlebar-close {
    margin: 3px 3px 0 0;
}

@media screen and (max-width: 767px) {
    .legacy-sitegen .pt_account .catalog-request .address-list-sec .g-recaptcha
    {
        position: relative;
        margin: 0 0 20px;
        transform: scale(1.02);
        transform-origin: 0 0;
        display:block;
    }
}

@media screen and (min-width: 768px) {
    .legacy-sitegen .pt_account .catalog-request .address-list-sec .g-recaptcha
    {
        transform: scale(0.96);
        transform-origin: 0 0;
        position: relative;
        margin: 0 0 20px;
        display:block;
    }
}



//order history page
.legacy-sitegen .pt_account {
    .create-login {
        &.login-order-history {
            .justify-content-between {
                margin: 0;
                .check-order, .login-section {
                    margin-bottom: 30px;
                    .login-box {
                        &.login-account, &.login-order-track {
                            @media screen and (max-width: 1023px) {
                                padding: 20px 40px;
                            }
                            @media screen and (max-width: 767px) {
                                padding: 20px 10px;
                                margin:0 10px;
                            }
                            h1{
                                margin:0;
                            }
                        }
                    }
                }
            }        
        }
    }
}

//forgot password page
.legacy-sitegen.pt_account .setnewpassword.resetpassword-container {
    .heading{
        h3{
            margin: 24px 0; 
        } 
    }
    
    #PasswordResetForm {
        fieldset{
            display:flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin-bottom: 30px;
            position:relative;
            padding: 0 10px;

            .form-row.resetemail {
                flex:3;
                label {
                    font-size: $base-font - 2px;
                    -webkit-transform: translateY(17px);
                    -ms-transform: translateY(17px);
                    transform: translateY(17px);
                }
                .input-focus {
                    -webkit-transform: translate(0, 5px);
                    -ms-transform: translate(0, 5px);
                    transform: translate(0, 5px);
                    letter-spacing: 0.4px;
                }
                .field-wrapper {
                    input {                       
                        padding: 16px 0 15px 20px;
                        height: 47px;
                    }
                }
            }
            button {
                position: static;
                margin-bottom: 0;
                flex: 2;
                height: 47px;
            }
            .error-handle {   
                .label-text { 
                    display: none;
                }
            } 
           
            @media screen and (min-width: 767px) {
                flex-direction: row;
                padding: 0 40px;

                .reset-error-caption {
                    // width: 50%;
                    position: absolute;
                    text-align: center;
                    top: 65px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            } 
        }

    }   
    
}

//sign in page 
@media screen and (max-width: 767px) {
.legacy-sitegen .pt_account {
    .create-login {
        margin: 10px;
        .login-box.login-account {            
                padding: 10px;
            }
        }
        .col-2.col-12 {
            margin: 0;
        }
    }
}

//media unsubscribe page
@media screen and (min-width:767px){
    .legacy-sitegen {
        .unsubscribe-confirmation-email.emailsubscription-guest.email-preferences.pt_account {
            padding-top: 50px;
            .email-unsubscribe-guest {
                .field-wrapper{
                    width: auto;
                }
            }
        }        
    }
}
.perks-accout-section {
    .custom-rounded:not(.perks-account-atb) {
        border-radius: 8px;
    }
    h4 {
        font-size: 24px;
    }
    p.perks-accout-content {
        font-size: 16px;
        max-width: 548px;
    }
    a {
        color: $cobalt-blue !important;
    }
    .lm-btn {
        color: $navy-blue !important;
        letter-spacing: 0.32px;
    }
    .btn-primary {
        max-width: 450px;
        letter-spacing: 0.9px;
        &.renew-membership, &.reactivate-membership {
            height: 56px;
        }
    }
    .p-32 {
        padding: 32px;
    }
    > div:first-child {
        max-width: 518px;
    }
    .status-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 7px;
        flex-shrink: 0;
        margin-top: 7px;
    }
    .billing-address-text {
        color: $mortar-grey;
        letter-spacing: 0.24px;
    }
    .perks-help-text {
        color: #525252;
    }
    .membership-active-section, .membership-soft-decline-section {
        .status-dot {
            background-color: $CA-point-circle-green;
        }
        .status-active {
            color: #096506;
            letter-spacing: 0.21px;
        }
        hr {
            border-color: lightgray;
        }
        .renewal-date-text, .payment-method-text {
            letter-spacing: 0.32px;
        }

        .payment-method-text.expired {
            color: $promo-red;
        }
    }
    .membership-expired-section {
        .status-dot {
            background-color: $dark-grey;
        }
        .status-expired {
            color: #525252;
            letter-spacing: 0.21px;
        }
    }
    .membership-cancelled-section {
        .status-dot {
            background-color: $RedRibbon-thick;
        }
        .status-cancelled {
            color: $RedRibbon-thick;
            letter-spacing: 0.21px;
        }
        .payment-method-text {
            color: $mortar-grey;
            letter-spacing: 0.32px;
        }
    }
    .reactivate-membership::before {
        content: attr(data-desktop-text);
    }

    @media (max-width: 767px) {
        .reactivate-membership::before {
            content: attr(data-mobile-text);
        }
    }
    .status-text {
        color: #333;
        display: flex;
        letter-spacing: 0.21px;
    }
    .custom-margin {
        margin-bottom: 81px;
    }
    @media screen and (max-width: 767px) {
        margin-top: 8px;
        .custom-margin {
            margin-bottom: 51px;
        }
    }
}

.perks-help-text {
    color: #525252;
}

.notification-container {
    border: 2px solid #0DA508;

    .notification-icon {
        height: 20px;
        margin-right: 10px;
    }
    p {
        font-size: 14px !important;
        font-weight: bold !important;
        letter-spacing: 0px !important;
        line-height: 22px !important;
    }

    .alert-close-button {
        margin-left: auto;
    }

    .notification-close {
        height: 12px;
        width: 12px;
        margin-left: auto;
        cursor: pointer;
    }
}

.soft-decline-notification {
    border: 2px solid #B50000;

    @media screen and (max-width: 767px) {
        .soft-decline-error {
            width: 82%;
        }

        .notification-icon-fail, .notification-close {
            top: -21px !important;
            position: relative;
        }
    }

    @media screen and (min-width: 768px) {
        .notification-icon-fail, .notification-close {
            top: -10px;
            position: relative;
        }

        .notification-close {
            width: 20px;
        }
    }

    @media screen and (max-width: 767px) {
        .notification-close {
            width: 12px;
        }
    }
}

.plcc-ov-top-content {    
    width: 50%;

    .plcc-ov-left-section a {
        font-family: $Helvetica;
        color: $cobalt-blue;
    }
    .reard-tracker {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        width: 100%;

        .reard-tracker {
            margin-right: 0 !important;
        }
    }
}

#reactivateMembershipModal .modal-dialog {
    margin-top: 10%;
}

#cancelMembershipModal .modal-dialog {
    margin-top: 20%;
}

#reactivateMembershipModal, #cancelMembershipModal {
    .modal-content {
        box-shadow: 0px 0px 16px #000029;
        max-width: 360px;
        margin: auto;

        .reactivation-modal-header, .cancel-modal-header {
            background-color: #F5F5F5 !important;
        } 

        .close-btn {
            color: #0449B9;
            cursor: pointer;
            text-transform: none;
        }

        .btn-reactivate, .btn-keep-saving, .btn-cancel-membership {
            max-width: 312px;
            border-radius: 4px !important;
        }

        .btn-cancel-membership span {
            color: #000000 !important;
        }
    }
    .modal-header {
        
    }
    .modal-title {
        letter-spacing: 0.27px;
    }
    .modal-body {
        p:first-child {
            font-family: $sans-serif;
        }

        .letter-spacing-custom {
            letter-spacing: 0.24px;
        }

        .perks-help-text {
            color: #525252;
        }
        
    }
}

.modal-dialog {
    .slimScrollDiv {
        height: 100% !important;

        .modal-body {
            height: 100% !important;
        }
    }
}

.terms-conditions {
    .modal-title {
        font-family: $Lonotype !important;
        font-weight: 400 !important;
        font-size: 1.26rem !important;
    }

    .btn.close {
        bottom: 0 !important;
        color: #0449B9 !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        left: 0;
        margin: auto;
        opacity: 1;
        position: absolute;
        text-transform: capitalize !important;
        top: 0;
        border-style: none !important;
        border-width: 0 !important;
        background-color: transparent !important;
    }

    .modal-body {
        overflow-y: scroll !important;

        .text-left p {
            font-weight: 400 !important;
            font-size: 1rem !important;
        }
    }

    @media screen and (max-width: 767px) {
        .modal-content {
            height: 100% !important;
        }
    }
}



