@media screen and (max-width: 1023px) {
    .top-banner {
        width: 0;
        height: 100%;
        position: fixed;
        overflow: hidden;
        top: 0;
        background: rgba(255, 255, 255, 0.5);
        z-index: 29;
    }
    .secondary-header {
        .ucp2-icon,
        .ucp2-cart-icon,
        .ucp2-cart-with-item-icon {
            margin-bottom: 3px;
        }
    }
    .header-sticky-mobile {
        .header-tagline {
            display: none;
        }
        .header-menu-mobile-tablet {
            display: none;
        }
        .top-menu-utility-wrapper {
            display: flex;
        }
        .menu-utility {
            flex: 70%;
            width: 70%;
        }
        .secondary-header {
            flex: 30%;
            width: 30%;
            ul {
                width: 100px;
                float: right;
                margin-right: 10px;
                height: 100%;
            }
            li {
                height: 66px;
                a {
                    height: 66px !important;
                }
            }
        }
        .top-menu-utility {
            height: 66px;
        }
        .fixed-header {
            height: 175px;
        }
        .fixed-header-wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 11;
            background: #fff;
            box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
        }
        .brands-header-item-inactive,
        .brands-header-more-button {
            display: none;
        }
        .brands-header {
            padding-top: 0;
            .brands-header-active-mobile {
                border-left: none;
                border-right: none;
                .brand-menu-tab {
                    background: none;
                }
            }
            .brand-menu-tab,
            .brand-menu-image,
            .brand-menu-image a,
            .brand-menu-tab .content-asset {
                justify-content: start;
                padding-left: 3px;
                width: auto;
            }
            .brand-menu-image a {
                padding-left: 35px;
            }
            .brands-header-active .brand-menu-image img,
            .brands-header-active-mobile .brand-menu-image img {
                height: 30px;
                width: auto;
            }
        }

        .top-menu-utility {
            background: none;
        }
        .secondary-catalog,
        .secondary-card,
        .secondary-account,
        .deals-xs {
            display: none;
        }
        .secondary-header {
            .ucp2-search-icon {
                background-image: url(../../../../images/search_black.svg);
            }
            .ucp2-cart-icon,
            .ucp2-cart-with-item-icon {
                background-image: url(../../../../images/cart_black.svg);
            }
        }
        .header-search-util,
        .shopping-bag-sm {
            span {
                display: none;
                span {
                    display: block;
                }
            }
            .ucp2-cart-icon,
            .ucp2-cart-with-item-icon,
            .ucp2-search-icon {
                display: block;
                width: 30px;
            }
        }
    } // End Sticky CSS

    .slider-menu {
        background: #fff;
        position: relative;
        transform: translateX(-100%);
        visibility: hidden;
        transition: transform 200ms;
        width: 100%;
        height: 100%;
    }

    .level-2,
    .level-3,
    .level-4,
    .sub-menu-parent-level-4,
    .has-sub-menu-level3 {
        display: none;
        .banner {
            display: none;
        }
    }

    .l4-activate .level-3 {
        .has-sub-menu-level2 {
            display: none;
        }

        li.parent-level3 {
            display: none;
            a.level3 {
                display: none;
            }
            &.l4-active-child {
                display: block;
                a {
                    &.level3 {
                        display: block;
                        background: $wild-sand;
                        font-family: "Work Sans", sans-serif;
                        text-align: center;
                    }

                    &.has-sub-menu-level3:after {
                        right: unset;
                        left: 10px;
                        background: url(../../../images/icon_leftarrownavsec.svg) no-repeat 0 0;
                        text-align: center;
                        background-color: $wild-sand;
                    }
                }
            }
        }
    }

    .l4-active {
        ul,
        li {
            display: block;
        }
        ul {
            margin: 0;
            padding: 0;
        }
    }
    .has-sub-menu,
    .has-sub-menu-level2,
    .has-sub-menu-level3,
    .has-sub-menu-level4,
    .has-sub-menu-level4-heading,
    .has-sub-menu-level2-heading,
    .has-sub-menu-level3-heading {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: 14px;
            right: 10px;
            width: 14px;
            height: 40px;
            background: url(../../../images/icon_rightarrow.svg) no-repeat 0 0;
        }
    }

    .l2-activate {
        position: relative;
        top: 0;
        left: -90%;

        width: 100%;
        background: #fff;
        z-index: 10;
        .has-sub-menu {
            background: $wild-sand;
            font-family: "Work Sans", sans-serif;
            text-align: center;
            &:after {
                left: 10px;
                background-image: url(../../../images/icon_leftarrownavsec.svg);
            }
        }
        .level-2 {
            display: block;
        }
    }
    .l2-active {
        left: 0;
        transition: left 200ms;
    }

    .l3-activate {
        position: relative;
        top: 0;
        left: -90%;
        width: 100%;
        background: #fff;
        z-index: 10;
        .level-2 {
            li {
                display: none;
            }
            .l3-active-child {
                display: block;
            }
        }
        .level-3 {
            li {
                display: block;
            }
        }
        .has-sub-menu {
            display: none;
        }
        .has-sub-menu-level2-heading {
            background: $wild-sand;
            font-family: "Work Sans", sans-serif;
            text-align: center;
            &:after {
                left: 10px;
                background-image: url(../../../images/icon_leftarrownavsec.svg);
            }
        }
        .level-2,
        .level-3 {
            display: block;
        }
    }
    .l3-active {
        left: 0;
        transition: left 200ms;
    }

    .header-mobile-menu-l2-open {
        .l2-disabled {
            display: none;
        }
    }

    .nav-column {
        margin: 0;
        padding: 0;
    }
    // WCAG - Style applies accessing via keyboard only
    .hamburger-keyboard {
        .menu-category {
            background: $wild-sand;
            a {
                margin: 3px;
            }
        }
    }
    .menu-category {
        margin: 0;
        padding: 0;
        background: #fff;
        .menu-item-toggle::before {
            display: none;
        }
        a {
            display: block;
            padding: 17px 50px 17px 17px;
            border-bottom: 1px solid #ddd;
            font-size: 16px;
            letter-spacing: 2.5px;
            font-family: "mreavessanaltb", sans-serif;
            text-transform: uppercase;
        }
    }
    .menu-footer {
        padding: 20px 20px 20px 18px;
        background: $wild-sand;
        border-top: 50px solid $black;
        .footer-email-heading {
            font-size: 16px;
        }
        p {
            font-weight: 400;
            color: #666;
            font-size: 11px;
            line-height: 16px;
            margin-top: 5px;
        }
        .email-signup {
            .email-signup-footer-form {
                display: table;
                width: 100%;
                max-width: 300px;
                margin-bottom: 20px;
                .form-row {
                    display: table-cell;
                    vertical-align: top;
                    &.emailsignup {
                        width: 180px;
                    }
                    button {
                        width: 100%;
                        padding: 10px 5px 11px !important;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    .field-wrapper {
                        input[type="text"].email {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            font-size: 16px; //16px minimum to prevent zoom on IOS
                            padding-top: 16px;
                            padding-bottom: 0;
                            min-height: 46px;
                        }
                    }
                }
            }
        }
        .cs-flyout-phone {
            margin-top: 15px;
        }
        .primary-links {
            padding-bottom: 20px;
            padding-top: 20px;
            border-top: 1px solid #aaaaaa;
            margin-top: 20px;
            h2 {
                margin: 0 0 15px;
                font-size: 12px;
            }
            .email-us-icon {
                margin-bottom: 20px;
            }
        }
        .quick-links {
            padding: 15px 0;
            border-top: 1px solid #aaaaaa;
            .links {
                text-transform: uppercase;
                padding-top: 0;
                font-size: 16px;
            }
        }
        .primary-links,
        .footer-links,
        .cs-flyout-quick-links {
            color: $black;
            a {
                color: $black;
            }
        }
    }
    .navigation-cart {
        display: none;
    }
    .header-mobile-menu-open {
        overflow: hidden;
        &.right-panel-open {
            .navigation-scroll {
                display: none;
            }
            .right-panel:not(.p-absolute) {
                position: absolute;
                top: 56px;
                height: calc(100vh - 59px) !important;
                left: 0;
            }
        }
        .top-banner {
            width: 100%;
        }
        .navigation-header {
            display: table-row;
            height: 59px;
            .unselected,
            .oss-brand-title,
            .oss-brand-tag {
                display: none;
            }
        }
        .slider-menu {
            transform: translateX(0);
            visibility: visible;
        }
        #navigation {
            padding: 0;
            display: table;
            width: 100%;
            height: 100%;
            background: $white;
        }
        .header-mian-banner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        nav[role="navigation"]::after {
            display: none;
        }
        .navigation-content {
            display: table-row;
            position: relative;
        }
        .navigation-scroll {
            display: table-cell;
            vertical-align: top;
            position: relative;
        }
        .navigation-scroll-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            background: $wild-sand;
            scrollbar-color: gainsboro whitesmoke;
        }
        .navigation-header-wrapper {
            display: table;
            width: 100%;
            padding: 10px 0;
            border-bottom: 2px solid #ddd;
            .mobile-menu-logo,
            .mobile-menu-utilities {
                display: table-cell;
                vertical-align: top;
                a {
                    color: $black;
                    display: block;
                }
                ul {
                    display: block;
                    margin: 0;
                    padding: 0;
                    text-align: right;
                    li {
                        display: inline-block;
                        vertical-align: top;
                        padding: 0 5px;
                    }
                    .shopping-bag-sm {
                        display: none;
                    }
                }
            }
            .mobile-menu-logo {
                padding-left: 50px;
                vertical-align: middle;
                img {
                    width: 100px;
                    height:34px;
                }
            }
            .mobile-menu-utilities {
                width: 150px;
                padding-right: 15px;
            }
            .hamburger-toggle {
                position: absolute;
                top: 15px;
                left: 10px;
            }
        }
        .account-btn {
            span {
                font-family: HurmeSemiBold !important;
                display: block;
                text-transform: uppercase;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
            }
            .ucp2-account-icon {
                background-position: center;
                height: 20px;
                margin-bottom: 2px;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .slider-menu {
        max-width: 336px;
        box-shadow: 1px 10px 7px rgba(0, 0, 0, 0.4);
    }
    .header-mobile-menu-open.right-panel-open {
        .right-panel {
            max-width: 336px;
        }
    }
    #navigation {
        .secondary-account {
            display: none;
        }
    }
    .header-sticky-mobile {
        .fixed-header {
            height: 66px;
        }
    }
}
.cs-flyout-quick-links {
    display: block;
    .links {
        padding: 15px 0 0 0;
    }
    a {
        padding: 15px 0 0 0;
        display: block;
    }
}
@media screen and (max-width: 767px) {
    .right-panel-open {
        .right-panel {
            overflow: auto;
            &:not(.p-absolute) {
                -webkit-overflow-scrolling: touch;
            }
            .right-panel-inner {
                max-width: none;
                height: auto;
                transition: none;
                overflow: visible;
            }
        }
    }
    .header-search {
        .simpleSearch {
            fieldset {
                position: relative;
                .close-icon {
                    position: absolute;
                    top: 3px;
                    left: 11px;
                    margin-left: 2px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    &:after {
                        font-size: 18px;
                        right: auto;
                        left: 4px;
                        top: 2px;
                    }
                    &.hide-mobile {
                        display: none;
                    }
                }
            }
            input[type="text"] {
                padding-left: 40px;
            }
        }
    }
}

@include desktop {
    .menu-category .hoverIntent .level-2 {
        .module-studio {
            overflow: visible;
            a {
                overflow: visible;
                display: block;
            }
        }
        .banner .mm-story-160 {
            a {
                visibility: visible;
                display: block;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .nav-column .has-sub-menu-parent-level3,
    .nav-column .has-sub-menu-parent-level-4 {
        .parent-level3,
        .parent-level4 {
            .has-sub-menu-level2-heading,
            .has-sub-menu-level3-heading {
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 14px;
                    right: 10px;
                    width: 14px;
                    height: 40px;
                    background: url(../../../images/icon_rightarrow.svg) no-repeat 0 0;
                }
            }
        }

        &.l3-active {
            .has-sub-menu-level2-heading,
            .has-sub-menu-level3-heading {
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 14px;
                    right: 10px;
                    width: 14px;
                    height: 40px;
                    left: 10px;
                    background: url(../../../images/icon_leftarrownavsec.svg) no-repeat 0 0;
                }
            }
        }
    }

    .nav-column .has-sub-menu-parent-level3 {
        &.l3-active {
            li.has-sub-menu-parent-level-4 {
                a.has-sub-menu-level3-heading {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 14px;
                        right: 10px;
                        left: unset;
                        width: 14px;
                        height: 40px;
                        background: transparent;
                    }
                }
            }
        }
        &.l4-active {
            a.has-sub-menu-level2-heading {
                display: none;
            }

            li.nav-level3,
            li.has-sub-menu-parent-level-4 {
                display: none;

                &.l4-active-child {
                    display: block;

                    a.has-sub-menu-level3-heading {
                        position: relative;
                        background: $wild-sand;
                        font-family: "Work Sans", sans-serif;
                        text-align: center;

                        &:after {
                            content: "";
                            position: absolute;
                            top: 14px;
                            right: 10px;
                            width: 14px;
                            height: 40px;
                            left: 10px;
                            background: url(../../../images/icon_leftarrownavsec.svg) no-repeat 0 0;
                        }
                    }

                    > ul > li {
                        display: block;
                    }
                }
            }
        }
    }
}
