@import "../common/variables";
@import "../common/mixin";
@import "font";

.pt_article-page .legacy-sitegen {
	.form-horizontal {
		.field-wrapper {
			width: 100%;
			padding-left: 0;
		}
	}

	.mobile-nav {
		display: none;
	}

	.form-row {
		margin: 0 0 12px 0;
		position: relative;

		.selected-option {
			text-indent: -9999px;
			background: transparent;
		}
		&.show-text {
			.selected-option {
				text-indent: 0;
			}
		}
		&.custom-select {
			background: $white;
			.input-focus + .field-wrapper .selected-option {
				text-indent: 0;
			}
		}
		&.error-handle {
			&.state,
			&.postal,
			&.country {
				label {
					-webkit-transform: translate(5px, 14px);
					-ms-transform: translate(5px, 14px);
					transform: translate(5px, 14px);
				}
			}
			label {
				span {
					&.label-text {
						display: none;
					}
				}
			}
		}

		&.state,
		&.country {
			.field-wrapper {
				background: $white;
			}
		}
	}
	.custom-select {
		.selected-option {
			border-color: $dark-gray;
		}
	}
	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="date"],
	input[type="tel"],
	textarea,
	select {
		padding: 17px 15px 16px 15px;
		height: auto;
		border-radius: 0;
		width: 100%;
		box-sizing: border-box;
	}
	.make-label-absolute {
		label {
			font-weight: normal;
			font-size: $base-font;
			padding: 0;
			position: absolute;
			transform: translate(5px, 17px);
			left: 12px;
			margin: 0;
			top: 0;
			width: auto;
			color: $dim-gray;
			z-index: 1;
			-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
			transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
			-ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;

			span {
				font-family: $Helvetica;
				text-transform: capitalize;

				&.required-indicator {
					display: none;
				}

				&.error {
					color: $checkout-error-color;
					text-transform: none;
					z-index: 1;
				}
			}

			&.input-focus {
				transform: translate(3px, 3px);

				span {
					font-size: $base-font - 1px;
				}
			}
		}
	}
	#secondary {
		nav {
			border-bottom: none;
		}
		.secondary-navigation {
			max-width: 154px;
			margin: 0;
			text-align: left;

			ul {
				letter-spacing: 1px;
				margin: 0;
			}
		}
	}
}

//Contact page global elements which is on right side of the pages.
.contactpage-right-content {
	h1 {
		font-size: $base-font + 12px;
		padding-bottom: 14px;
		margin: 26px 0 12px;
		border-bottom: 1px solid $alto;
	}
	.text-content {
		font-size: $base-font + 1px;
		margin: 0 0;
		line-height: 25px;

		p {
			@extend .text-content;
		}

		a,
		strong,
		b {
			font-weight: normal;
		}
	}

	.contactpage-chat-module {
		h1 {
			border-bottom: 0;
			margin: 10px 0;
		}
		.chat-contact {
			border-right: 0;
			padding: 20px 42px 20px 38px;
		}

		.media-contacts {
			margin-left: 5.3%;
		}
	}
	.only-for-romansbrand {
		display: none;
	}

	.thankyou-section {
		.form-wrapper {
			padding: 46px 7.1% 40px;
			margin-top: 20px;
		}
		.contactusthankyouforcontacting {
			font-size: $base-font + 6px;
		}
		.text-content {
			margin-top: 13px;

			p {
				margin: 0 0 10px;
			}
		}
	}
}

//Contact us page
.contact-us-page {
	.form-wrapper {
		background: $light-skygrey;
		padding: 60px;
		padding: 7.1%;
		margin-top: 16px;
		margin-bottom: 30px;

		.custom-select {
			select {
				white-space: normal;
				padding-right: 25px;
			}
			.selected-option {
				text-transform: none;
				padding-right: 18px;
				&::before {
					right: 2px;
				}
			}
			.selection-list li {
				padding: 12.5px 9px;
			}
		}

		.left-cont {
			float: left;
			max-width: 309px;
			width: 43%;
		}
		.right-cont {
			float: right;
			max-width: 359px;
			width: 49.9%;

			@media screen and (min-width: 1025px) {
				padding-right: 4px;
			}

			.form-row-button {
				margin-top: -2px;
			}
		}

		.solid-wine-berry {
			float: right;
			letter-spacing: 1.6px;
		}
		textarea {
			height: 120px;
			padding: {
				top: 19px;
				left: 17px;
			}
		}
	}
}

.contact-us-priority {
	.form-wrapper {
		padding: 7.1% 7.8% 5.8% 7.1%;
	}
	.trackingnumber {
		margin: 0 0;
		clear: both;

		.field-wrapper {
			margin: 0 0;
		}
	}
	.form-row-button {
		margin: -3px 0 0 0;
		width: 180px;
		float: right;
	}
	.cp-email {
		max-width: 310px;
		width: 43.2%;
	}
}

.customerservice-name {
	text-align: center;
	font-size: $base-font + 48px;
	text-transform: uppercase;
	letter-spacing: 3px;
}

.tiles-section {
	width: 100%;
	display: inline-block;
	margin: 0 0 10px 0;
}
.customer-service-landing-contacts {
	margin: 0 0 3% 0;
}
.content-asset-info {
	width: 31%;
	float: left;
	background-color: $light-skygrey;
	text-align: center;
	margin: 0 19px 20px 0;

	&:nth-child(3n) {
		margin: 0 0 20px 0;
	}

	&:hover {
		background-color: $wine-berry;
		.inner-asset-info {
			display: none;
		}
		.hovered-content {
			display: table;
			height: 200px;
			width: 67%;
			vertical-align: middle;
			margin: 0 auto;

			a {
				display: table-cell;
				vertical-align: middle;
			}

			.hovered-text {
				display: table-cell;
				vertical-align: middle;
			}

			ul {
				font-size: $base-font + 2px;
				font-family: $HelveticaMedium, $sans-serif;
				margin: 0;
				padding: 0;
				text-align: left;
				color: $white;
			}
			li {
				margin: 0 0 17px 0;
			}
		}
	}
	.hovered-content {
		display: none;
	}

	.hovered-title {
		display: none;
	}

	.inner-asset-info {
		display: table;
	}
	.title {
		font-size: $base-font + 6px;
		text-transform: uppercase;
		display: inline-block;
		width: 100%;
		margin: 30px 0 0 0;
		float: left;
	}
}

.call-image,
.chat-image,
.mail-image {
	display: inline-block;
	margin: 0 5px 0 0;
}

.available-image {
	display: inline-block;
	margin: 0 5px;
}

.privacy-policy {
	margin: 0 0 20px 0;

	.heading {
		padding: 24px 0 14px 10px;
		border-bottom: 1px solid $alto;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}

	.answer {
		display: none;

		&.active {
			display: block;
			font-size: $base-font + 2px;

			div {
				margin: 0 0 15px 0;
			}
			.postal-address {
				.label {
					display: inline-block;
					width: auto;
					vertical-align: top;
				}

				.value {
					width: auto;
					display: inline-block;
					line-height: 20px;
				}
			}

			.customer-service {
				.contact {
					border-right: 1px solid $black;
					padding-right: 10px;
				}

				.hearing {
					padding-left: 10px;
				}
			}
		}
	}
}
.customer-service-faq {
	margin: 0;
	text-align: right;
}
.customer-service-landing-faq {
	.qa-desc {
		margin: 30px 0 0 0;
	}
}
@media screen and (min-width: 1024px) {
	.pt_customer-service  {
		#main.legacy-sitegen {
			padding: 0 20px;
			box-sizing: content-box;
		}
	}
}

@media screen and (max-width: 1024px) {
	.contactpage-right-content {
		.contactpage-chat-module {
			.chat-contact {
				padding: 20px 13px 20px 20px;
			}
			.media-contacts {
				margin-left: 0;
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_customer_service .legacy-sitegen {
		.page-content {
			padding: 0 20px;
		}

		.qa-section {
			.question-title {
				float: left;
				width: auto;
				display: inline-block;
			}
		}
	}

	//Contact us page
	.contactpage-right-content {
		h1,
		.text-content {
			padding-left: 20px;

			p {
				padding-left: 0;
			}
		}

		.thankyou-section {
			.form-wrapper {
				padding: 46px 20px 36px;

				.text-content {
					padding-left: 0;
				}
			}
		}
	}
	.contact-us-page {
		.form-wrapper {
			padding: 60px 20px 44px;

			.left-cont {
				max-width: 310px;
				width: 45.1%;
			}
			.right-cont {
				max-width: 360px;
				width: 52.2%;
			}
		}
	}
	.inner-service-landing-contacts {
		margin-bottom: 25px;
	}

	.pt_article-page .legacy-sitegen {
		ul {
			&.cscurrentpage {
				display: none;
			}
		}
		.secondary-article-heading {
			display: none;
			&.mobile-nav {
				display: block;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_customer-service .legacy-sitegen {
		.page-content {
			padding: 0 10px;
		}
	}
	.media-contacts {
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		padding: 20px 0 20px 0;
		border-right: none;
	}
	.chat-contact {
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		border-right: none;
	}
	.mail-contact {
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		padding: 20px 0 0 0;
	}
	.tiles-section {
		margin: 0 0 20px 0;
	}
	//Contact us page
	.contactpage-right-content {
		h1,
		.text-content {
			padding-left: 0;
		}

		.thankyou-section {
			.form-wrapper {
				padding: 20px 10px;
				box-sizing: border-box;
			}
		}
	}
	.contact-us-page {
		.form-wrapper {
			padding: 18px 10px 53px;

			.left-cont {
				max-width: 100%;
				width: 100%;
			}
			.right-cont {
				max-width: 100%;
				width: 100%;
			}
		}
	}
	.contactpage-right-content {
		.contactpage-chat-module {
			.chat-contact {
				padding: 20px 13px 20px 0px;
			}
		}
		.cp-email {
			width: 100%;
			max-width: 100%;
		}
	}

	//Article-page
	.secondary-article-heading {
		font-size: $base-font + 4px;
	}

	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 20px;
		}
		.primary-content {
			display: inline-block;
		}
		#secondary {
			width: 100%;
			margin: 15px 0;

			nav {
				margin: 0;
			}
			.secondary-navigation {
				ul {
					font-size: $base-font + 4px;
				}
			}
		}
		.customerservice-name {
			display: none;
		}

		.qa-section {
			.qa-content {
				padding: 25px 15px;
			}
		}
		.col-1 {
			width: 30%;
			float: left;
			margin: 0 9% 0 0;
		}

		.col-2 {
			width: 21%;
			float: left;
			margin: 0;
		}

		.qa-section {
			.qa-content {
				.answer {
					font-size: $base-font;
				}
				.question {
					font-size: $base-font + 1px;
				}
			}
		}
	}

	.customerservice-name {
		font-size: $base-font + 12px;
		margin: 30px 0 16px 0;
	}

	.tiles-section {
		.content-asset-info {
			width: 100%;
			margin: 0 0 5px 0;

			&:nth-child(3n) {
				margin: 0 0 5px 0;
			}

			.inner-asset-info {
				padding: 0;
				display: inline-block;
				height: auto;

				a {
					width: 85%;
					display: table;
					vertical-align: middle;
					margin: 0 auto;
					margin-top: 15px;
					margin-bottom: 15px;
					padding: 0;

					img {
						max-height: 100%;
					}
				}

				.icon {
					float: none;
					display: table-cell;
					vertical-align: middle;
					width: 35.5px;
					height: 25px;
				}

				.title {
					width: auto;
					float: none;
					display: table-cell;
					vertical-align: middle;
					text-align: left;
					padding: 0 0 0 32px;
				}
			}
		}
	}
	.inner-service-landing-contacts {
		h2 {
			padding: 0;
		}
	}
	.media-contacts {
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		padding: 20px 0 20px 0;
		border-right: none;
	}
}
@media screen and (max-width: 480px) {
	.customer-service-faq {
		text-align: left;
	}
}
