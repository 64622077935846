.pt_account {
	.header_fixed {
		.reset-message-notify {
			position: absolute;
			top: 100%;
			width: 100%;
			left: 0px;
		}
	}
	@media screen and (max-width: 767px) {
		#header.has-reset-header:not(.header_fixed) {
			#mini-cart {
				margin-top: 50px;
			}
		}
	}
	.reset-message-notify {
		padding: 10px 0;
		text-align: center;
		letter-spacing: 1px;
		color: $black;
		background: $light-skygrey;
		box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
		@media screen and (max-width: 767px) {
			position: static;
			bottom: 0;
			clear: both;
			width: 100%;
		}

		@media screen and (min-width: 768px) and (max-width: 1023px) {
			position: absolute;
			width: 100%;
			clear: both;
			z-index: 2;
		}
		span {
			font-size: $base-font + 2px;
		}
		.checkSign {
			position: relative;
			margin-right: 26px;
			&::after {
				content: "\f058";
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				color: $circlegreen;
				font-size: 22px;
				padding-right: 0.5em;
				position: absolute;
				top: -3px;
				left: 0;
			}
		}
		.closeMsg {
			position: relative;
			margin-left: 12px;
			cursor: pointer;
		}
	}
}

.email-trouble-message {
	display: inline-block;
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
	padding: 10px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.plcc-ov-right-section {
	.slick-arrow {
		&.slick-prev {
			background: url(../../images/Left-Arrow-Icon_Black.svg) no-repeat;
		}

		&.slick-next {
			background: url(../../images/Right-Arrow-Icon_Black.svg) no-repeat;
		}
	}
}

.reward-trackers-main {
	.plcc-card-name-container .plcc-card-icon {
		background: url("../../images/cards-sprite2.png") no-repeat;
		background-position: -59px -34px;
		width: 35px;
		height: 24px;

		&.ca_plcc {
			background-position: -157px -35px;
		}
		&.rm_plcc {
			background-position: -290px -3px;
		}
		&.ww_plcc {
			background-position: -203px -3px;
		}
		&.jl_plcc {
			background-position: -157px -3px;
		}
		&.bh_plcc {
			background-position: -10px -34px;
		}
		&.ks_plcc {
			background-position: -109px -34px;
		}
	}
}

//My Account Overview
#secondary {
	width: 100%;
	float: none;
	display:block;
	padding: 0;
	box-shadow:none;

	&.nav.guest-user {
		@media screen and (max-width: 767px) {
			margin-top: 20px;
			background: transparent;

			nav {
				display: none;
			}
		}
	}

	nav {
		padding: 0;
		border-bottom: 1px solid $alto;
		max-width:100%;
		margin:0 auto;
	}

	.secondary-navigation {
		max-width: 830px;
		margin: 20px auto;
		text-align: center;
		text-transform: uppercase;

		.main {
			display: inline-block;
			width: 100%;
		}

		.navigation-links-row {
			margin: 0;
			padding: 0;

			li {
				width: auto;
				display: inline-block;

				&.navigation-links-row-inner {
					margin: 0;
				}

				a {
					color: $black;
					@include tablet-up {
						display: inline-block;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.legacy-sitegen #secondary {
		&.nav {
			display: block;
			width: auto;
			margin: 0 20px;
		}
	}
	.pt_checkout {
		#secondary {
			&.summary {
				margin: 0;
				float: none;
				clear: both;
			}
		}
	}
	#secondary {
		.secondary-navigation {
			.secondary-navigation-links {
				.navigation-links-row {
					li {
						margin: 13px 20px 0;

						&.navigation-links-row-inner {
							margin: 0;
							li {
								margin: 24px 10px 0;
								word-spacing: normal;
							}
						}
					}
				}
			}
		}
	}
	body[data-page="account"] .legacy-sitegen #secondary{
		width:100%; 
		margin:0 auto;
	}
}

@media screen and (max-width: 767px) {
	#secondary {
		&.nav {
			width: 100%;
			margin: 0;
		}

		.slider-menu-overlay {
			position: fixed;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			margin-top: 0;
			background: #ffffff;
			opacity: 0.8;
			top: 0;
		}

		&.overlay {
			.slider-menu-overlay {
				display: block;
			}

			nav {
				z-index: 2;
				position: relative;
			}
		}

		nav {
			padding: 0;
			border: 0;
			margin: 0 18px;
		}

		.secondary-navigation {
			text-align: left;
			text-transform: uppercase;

			.main {
				display: none;
			}

			.secondary-navigation-links {
				padding: 0 20px 20px 20px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				display: none;

				.navigation-links-row {
					li {
						margin: 25px 0 0 0;
						&.navigation-links-row-inner {
							li {
								margin: 25px 0 0 0;
							}
						}
					}
				}

				&.active {
					display: block;
					position: absolute;
				}
			}

			.heading {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				margin: 0;
				font-style: initial;
				position: relative;
				padding: 20px;

				&::after {
					content: "";
					background: url("../../images/overview.svg") no-repeat;
					width: 22px;
					height: 17px;
					padding: 0;
					right: 20px;
					margin-top: 2px;
					position: absolute;
					transform: rotate(0deg);
					-ms-transform: rotate(0deg);
					-webkit-transform: rotate(0deg);
				}

				&.value-updated {
					padding-bottom: 0;

					&:after {
						transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-webkit-transform: rotate(180deg);
					}
				}
			}

			.navigation-links-row {
				display: inline-block;

				li {
					width: 100%;

					&.navigation-links-row-inner {
						margin: 0;
					}
				}
			}
		}
	}
	#wrapper {
		@each $pagename in $pagenames {
			&.#{nth($pagename, 1)} {
				#secondary {
					.secondary-navigation {
						.secondary-navigation-links {
							li {
								&.#{nth($pagename, 2)} {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}
.subscription-error-msg {
	font-family: $Helvetica;
	font-size: $base-font + 4px;
	color: $medium-carmine;
}

.login-form {
	.error-form {
		background-color: transparent;
		border: 0;
		border-radius: none;
		padding: 0 0 5px 0px;
		color: $RedRibbon;
	}
}

#accountNavigation.hideElqRewardsLink .elq-rewards,
#accountNavigation.hideDiaRewardsLink .dia-rewards {
	display: none !important;
}
