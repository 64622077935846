.intl-exclusion-modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 99999;

	.focus-outline {
		outline-offset: -2px;
	}

	.dialog-overlay {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.8);
	}

	.intl-modal-inner {
		position: absolute;
		top: 40px;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
		opacity: 1;
		display: block;	
		background: $white;
    	width: 355px;
		padding: 0 15px 25px 15px;
		@media screen and (max-width : 767px) {
			top:60px;
		}
		@media screen and (max-width : 375) {
			width: 300px;
		}

	}

	.exclusion-modal-heading{
		position: relative;
		margin: 0 -15px;
		font-size: $base-font + 6px;
		font-family: $HelveticaBold;
		padding: 17px 14px;
		text-align: center;
		background-color: $gainsboro-white;
		color: $black;
		.modal-close{			
			width: 16px;
			height: 16px;
			top: 50%;
			right: 15px;
			transform: translateY(-50%);
			position: absolute;
			background: transparent;
			border: none;
			padding: 0;
			line-height: 0;
			letter-spacing: 0;
			color: $black;
			font-family: $Helvetica;
			font-size: $base-font + 8px;
		}
	}
	.exclusion-modal-body {
		p{
			font-size: $base-font + 4px;
			font-family: $Helvetica;
			margin: 16px 0;
			text-align: center;
		}
		button{
			width: 100%;
			margin-bottom: 20px;
			height: auto;
			background: $cobalt-blue;
			border: none;
			font-size: $base-font + 6px;
			font-family: $HelveticaBold;
			padding: 11px 30px;
			letter-spacing: 0;
			border-radius: 0%;
			margin-bottom: 0;
		}
	}

	.hide {
		display: none !important;
	}
}
