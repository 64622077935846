@each $type in $swatch-types {
	.swatch-#{nth($type, 1)} a {
		background-color: nth($type, 2) !important;
	}
}

.swatch-white a {
	background-color: $white !important;
	border-color: $light-gray !important;
}

.swatch-silver a {
	background-color: $silver !important;
}

.swatch-multi a {
	background-image: url("../../../images/MultiColor_Swatch.svg") !important;
	background-size: 30px 23px;
}

.swatch-bronze a {
	background-image: url("../../../images/Bronze_Swatch.svg") !important;
	background-size: 30px 23px;
}

.swatch-miscellaneous a {
	background: url("../../../images/interface/icon-color-swatch-misc.png") no-repeat scroll left top transparent;
}

.unselectable.swatch-miscellaneous a {
	background: url("../../../images/interface/icon-color-swatch-misc-unselectable.png") no-repeat scroll left top
		transparent !important;
}
