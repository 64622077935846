.brands-header {
	//Recommend splitting logo for tab into a new content item.
	padding-top: 6px;

	@media only screen and (max-width: 767px) {
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			width: 0 !important;
		}
		overflow: hidden;
	}

	.oss-brand-tag,
	.oss-brand-title {
		display: none;
	}
	ul {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		margin: 0;
		padding: 0;
	}
	li {
		flex: 1;
		align-self: flex-end;
		flex-grow: 1;
		flex-basis: 8%;
		position: relative;
		&:not(.brands-header-item-first):not(.brands-header-active):not(.brands-header-active-mobile):after,
		&[style]:after {
			content: "";
			display: block;
			height: 70%;
			width: 0;
			border-right: 1px solid #bdbdbd;
			position: absolute;
			left: 0;
			top: 15%;
		}
		@media only screen and (min-width: 1024px) {
			&.brands-header-item-inactive {
				margin-top: 10px;
			}
		}
		@media only screen and (min-width: 768px) and (max-width: 1023px) {
			&.brands-header-item-1.brands-header-item:not(.brands-header-item-first):not(.brands-header-active):not(.brands-header-active-mobile):after {
				display: none;
			}
		}
		@media only screen and (min-width: 1024px){
			&.brands-header-item-inactive{
				max-width: 81px;
				min-width:50px;
			}
			&.brands-header-active + li.brands-header-item-inactive.brands-header-item:after {
				display: none;
			}
		}
	}

	.brands-header-more-button {
		flex-basis: 55px;
	}
	.selected,
	.selected-mobile {
		display: none;
	}
	.brand-menu-tab,
	.brand-menu-image,
	.brand-menu-image a,
	.brand-menu-tab .content-asset {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.brand-menu-image a {
		padding: 6px 8px;
	}
	.brands-header-item:not(.brands-header-active-mobile):not(.brands-header-active) {
		.brand-menu-image a {
			&.focus-outline {
				outline-offset: -3px;
				z-index: 1;
			}
		}
	}
	.brand-menu-tab {
		position: relative;
		background: #e6e6e6;
		height: 55px;
	}
	.oss-brand-title {
		display: none;
		font-family: "Helvetica Neue LT W01_55 Roman", Arial, sans-serif, Arial, sans-serif;
		line-height: 15px;
		position: absolute;
		background: #303030;
		color: #fff;
		width: 150px;
		color: #fff;
		top: 61px;
		z-index: 10;
		padding: 10px;
		text-align: center;
		border-radius: 6px;
		font-size: 11px;
		pointer-events: none;
		left: 50%;
		transform: translateX(-50%);
	}
	.brands-header-active {
		.oss-brand-title {
			top: 68px;
		}
	}
	.brand-menu-image a,
	.oss-brand-title {
		&:after {
			bottom: 100%;
			left: 50%;
			border: solid transparent;
			content: "";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(243, 243, 243, 0);
			border-bottom-color: #303030;
			border-width: 6px;
			margin-left: -6px;
		}
	}
	.brand-menu-image a {
		&:after {
			display: none;
		}
	}

	.brand-menu-image img {
		height: 32px;
		opacity: 0.8;
		width: 100%;
		@media only screen and (max-width: 767px) {
			width: auto;
		}
	}

	.brands-header-active,
	.brands-header-active-mobile {
		flex-grow: 2;
		flex-basis: 13%;
		@media only screen and (min-width: 1024px) {
			 max-width: 125px;
			 min-width:98px;
		}
		&.brands-header-item-first {
			border-left: none;
		}
		.brand-menu-tab {
			height: 65px;
			background: #fff;
			z-index: 1;
			@media only screen and (max-width: 767px) {
				box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);
			}
		}
		.brand-menu-image {
			img {
				height: 55px;
				opacity: 1;

				@media screen and (min-width: 768px) and (max-width: 1023px){
					height: 30px;
				}
			}
			> a {
				padding: 6px;
			}
		}
		&:hover .oss-brand-title {
			display: none;
		}
	}

	.brands-header-active,
	.brands-header-active-mobile {
		.unselected {
			display: none;
		}
		.selected {
			display: inline-block;
		}
		.brand-menu-image {
			border: none;
		}
	}
	.brands-header-active-mobile {
		.brand-menu-image {
			a {
				margin-left: 34px;
			}
		}
		.hamburger-toggle {
			position: absolute;
			top: 12px;
			left: 6px;
		}
	}
	.expand-menu {
		border-radius: 0;
		text-transform: uppercase;
		font-size: 11px;
		font-family: "Futura LT W01 Medium", Arial, sans-serif;
		color: #000;
		border: 0;
		padding: 0;
		background: transparent;
		.icon-more,
		.icon-less {
			background-repeat: no-repeat;
			background-position: center;
			height: 15px;
			margin-bottom: 2px;
		}
		.icon-more {
			background-image: url(../../../../images/more.svg);
		}
		.icon-less {
			background-image: url(../../../../images/less.svg);
		}
		.less {
			display: none;
		}
		&.expand {
			.less {
				display: block;
			}
			.more {
				display: none;
			}
		}
	}
}

.brand-menu {
	display: none;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	position: absolute;
	top: 66px;
	left: 0;
	background: rgba(255, 255, 255, 0.85);
	width: 100%;
	z-index: 11;
	height: calc(100vh - 66px);
	ul {
		width: 100%;
		background: #f4f4f4;
		box-shadow: 3px 6px 13px rgba(0, 0, 0, 0.3);
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
	}
	li {
		width: 50%;
		border-top: 1px solid #c7c7c7;
		border-left: 1px solid #c7c7c7;
		margin-top: -1px;
		margin-left: -1px;
		position: relative;
		&.brands-menu-item-7 {
			width: 100%;
		}
	}
	.selected,
	.oss-brand-title {
		display: none;
	}
	a {
		display: block;
		padding: 15px;
	}
	.content-asset {
		text-align: center;
		padding-top: 40px; //No wrapper around logo image, need method of positioning.
	}
	.oss-brand-tag {
		display: block;
		margin: 10px 0;
	}
	img {
		height: 40px;
		margin-top: -40px;
	}
	.brands-menu-ks {
		img {
			height: 30px;
			margin-top: -30px;
		}
	}
}

.brands-header-more-button {
	display: none;
}

.brands-header {
	.brands-carousel {
		display: none;
	}
}

@media only screen and (max-width: 767px) {
	body:not(.header-sticky-mobile) {
		.brands-header {
			.brands-header-active-mobile {
				.brand-menu-image {
					a {
						margin-left: 55px;
						padding: 25px;
						display: block;
						width: calc(100% - 55px);
						.content-asset {
							margin-left: -27.5px;
							display: inline-block;
							text-align: center;
							.selected-mobile {
								transform: translateY(-25%);
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.brands-header-more-button {
		.brand-menu-tab,
		.brand-menu-image {
			border-top-right-radius: 6px;
		}
	}
	.brand-menu {
		li {
			width: 33.3%;
			&.brands-menu-item-last.brands-menu-item-7 {
				width: 100%;
			}
		}
	}

	.brands-header .brands-header-active-mobile .brand-menu-image{
		justify-content: start;
		a{
			width: 60%;
			display: flex;
			padding: 0;
			width: auto;
    		margin-left: 50px;
		}
	}
}
@media screen and (max-width: 1023px) {
	.brands-header {
		.brands-header-item-1 {
			.brand-menu-tab,
			.brand-menu-image {
				border-top-left-radius: 0;
			}
		}
	}
	.brand-banner-expand-active {
		width: 100%;
		height: 100%;
		overflow: hidden;
		.brand-menu {
			display: block;
		}
		.brands-header-item-inactive {
			visibility: hidden;
			pointer-events: none;
		}
		.menu-utility {
			background: #fff;
		}
		.brands-header {
			padding-top: 0;
			.brands-header-active,
			.brands-header-active-mobile {
				border-right-color: #fff;
				border-left-color: #fff;
			}
			.brands-header-more-button {
				.brand-menu-tab {
					background: transparent;
					border-left: 1px solid $separator-color;
				}
			}
		}
	}
	.brands-header-active {
		display: none;
	}
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
	.brand-banner-expand-active {
		.brands-header-more-button {
			.brand-menu-tab {
				height: 68px;
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.brands-header-active,
	.brands-header-active-mobile {
		z-index: 2;
	}
	#navigation {
		.navigation-content {
			width: 100%;
		}
	}
}
#navigation {
	.selected-mobile {
		display: none;
	}
}
@media screen and (max-width: 1023px) {
	.brand-banner-expand-active {
		.brands-header-more-button {
			.brand-menu-tab {
				height: 60px;
			}
		}
	}
	.hamburger-toggle {
		button:hover {
			background: none;
		}
	}
	body:not(.header-sticky-mobile) {
		.top-menu-utility-wrapper {
			background: #fff;
			.menu-utility {
				margin-bottom: 5px;
				.brands-header {
					position: relative;
					background: #fff;
					padding-top: 0;
					> ul {
						margin-top: 53px;
					}
					ul {
						display: block;
						width: 100%;
						overflow: hidden;
						margin-bottom: 83px;
						height: 1px;
						li.brands-header-item {
							display: none;
							&.brands-header-active-mobile {
								display: block;
								.hamburger-toggle {
									top: 18px;
								}
							}
						}
						li.brands-header-active-mobile {
							position: absolute;
							transform: translateY(100%);
							width: 100%;
							top: -26px;
							left: 0;
							border-bottom: 1px solid #fff;

							@media screen and (min-width: 768px){
								top:-2px;
								box-shadow: 5px -10px 10px -5px rgba(0, 0, 0, 0.2);
							}
							.brand-menu-tab {
								height: 82px;

								@media screen and (min-width: 768px){
									height: 60px;
								}
							}
							.selected {
								display: none;
							}
							.selected-mobile {
								display: inline-block;
							}
						}
					}
					.brands-carousel {
						+ ul {
							margin-bottom: 78px;

							@media screen and (min-width: 768px) and (max-width: 1023px){
								margin-bottom: 60px;
							}
						}
					}
				}
			}
		}
	}
	html {
		body {
			.top-menu-utility-wrapper {
				.menu-utility {
					.brands-header {
						.brands-carousel {
							position: relative;
							display: block;
							width: 125vw;
							margin-left: -12.5vw;
							ul {
								margin-bottom: 0;
								height: auto;
								width: 9999em;
								position: relative;
								display: block;
								.brands-header-item {
									display: block;
									width: 26vw;
									float: left;
									border-top: 3px solid #000;
								}
							}
							&:after {
								content: "";
								position: absolute;
								height: 100%;
								width: 0;
								border-right: 1px solid #e6e6e6;
								left: 0;
								top: 0;
							}
							+ ul {
								margin-top: 0;
							}
						}
					}
				}
			}
			&.header-sticky-mobile {
				.top-menu-utility-wrapper {
					.menu-utility {
						.brands-header {
							.brands-carousel {
								position: fixed;
								top: -9999em;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.brands-header {
		.brands-header-item:hover {
			.brand-menu-image img {
				opacity: 1;
			}
			.oss-brand-title {
				display: block;
			}
		}
		.brands-header-active-mobile {
			border-left: 3px solid #000;
		}
	}
	.brands-header-active-mobile {
		display: none;
	}
	.brands-header-more-button {
		display: none;
	}
}
@media screen and (min-width: 1024px) and (max-width: 1335px) {
	//At smaller breakpoints arrow on hover state is relative to the tab width instead of the tooltip width.
	.brands-header {
		.brands-header-item-first:hover,
		.brands-header-item-active-first:hover {
			.oss-brand-title {
				left: 50%;
				margin-left: 20px;
				&:after {
					display: none;
				}
			}
			a {
				position: relative;
				overflow: visible;
				&:after {
					bottom: -3px;
					display: block;
					margin-left: -3px;
				}
			}
		}
	}
	
	.brands-header-item-inactive.brands-header-item-1.brands-header-item-first .oss-brand-title{left:100%;}
	.brands-header-item-inactive.brands-header-item-1.brands-header-item-first a::after{bottom:-6px;}
}


//SC-10451 tab secondary header menu
body{

	&.header-sticky-mobile .top-menu-utility-wrapper .menu-utility .brands-header ul li.brands-header-active-mobile .brand-menu-tab{
		.secondary-header{
			display: none;
		}
	}

	&:not(.header-sticky-mobile){
		.secondary-header{
			@media screen and (min-width: 768px) and (max-width: 1023px){
			display: none;
			}
		}
		.top-menu-utility-wrapper .menu-utility{
			@media screen and (min-width: 768px) and (max-width: 1023px){
				width: 100%;
				border-bottom: 1px solid $header-megamenu-seperator;
				margin-bottom: 0;
			}
			.brands-header{
				@media screen and (min-width: 768px) and (max-width: 1023px){
					overflow: hidden;
				}
	
				ul li{
					&.brands-header-active .brand-menu-tab{
						.secondary-header{
							display: none;
						}
					}

					&.brands-header-active-mobile .brand-menu-tab{
						.secondary-header{
							display: none;
						}
					
						@media screen and (min-width: 768px) and (max-width: 1023px){
							display: flex;
							justify-content: space-between;
							width: 100vw;
							
							.brand-menu-image{
								width: 50%;
				
								.hamburger-toggle{
									top: 50%;
									transform: translateY(-50%);
								}
		
								a{
									width: auto;
									margin-left: 50px;

									.content-asset {
										width: auto;

										img{
											width: auto;
										}
									}
								}
							}
					
							.secondary-header{
								height: 100%;
								width: auto;
								margin-bottom: 0;
								display: block;
					
								ul{
									display: flex;
									justify-content: space-between;
									flex-direction: row;
									height: 100%;
									margin: 0;
					
									li{
										height: 100%;
										margin-right: 25px;
										&.has-active-panel{
											a{
												color:$white;
											 }
										}
					
										&::after{
											display: none;
										}

										&.secondary-account.has-active-panel{
											.account-btn{
												background: $black;

												.ucp2-account-icon{
													background-image: url(../../../../images/account_white.svg);
												}

												.secondary-header-icon-label{
													color: $white;
												}
											}
										}
					
										a{
											display: flex;
											flex-direction: column;
											color: $black;
					
											span[class^="ucp2-"]{
												height: 18px;
												width: 18px;
											}
					
											span.ucp2-card-icon{
												width: 24px;
											}
					
											span.secondary-header-icon-label{
												font-size: 10px;
												font-weight: bold;
												letter-spacing: 0.01px;
												margin-top: 3px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


// IA and SH logo size tweaks mobile and tablet
@media screen and (min-width: 768px) and (max-width: 1023px){
	.brands-header .brands-header-active-mobile{
		&.brands-header-ia, &.brands-header-sh{
			.brand-menu-image img{
				height: 25px;
				margin: 5px 0 0 5px;
			}
		}
	}
}

@media screen and (max-width: 767px){
	.brands-header .brands-header-active-mobile{
		&.brands-header-ia, &.brands-header-sh{
			.brand-menu-image img{
				margin: 5px 0 0 5px;
				height: 50px;
			}
		}
	}
}