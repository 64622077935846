.field-wrapper {
    .custom-select {
        background: #ffffff;
    }
}

.server-error {
    color: #e6003c;

    + .label-text {
        display: none;
    }
}
.dob-error {
    color: $error-crl;
    margin: 0 0 6px 0;
    display: inline-block;
    width: 100%;
}
.pt_product-details .sizechart-dialog + .ui-widget-overlay {
    z-index: 999999 !important;
}
.breadcrumb .breadcrumb-element.current-element {
    cursor: default;
}
.custom-select {
    width: 100%;
    float: left;
    color: $night-rider;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    // Start: Override Design Systems .custom-select
    padding: 0;
    background: none;
    border: none;
    // End: Override Design Systems .custom-select

    select {
        position: absolute;
        left: 0;
        top: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        opacity: 0;
        z-index: 0;
        width: 100%;
        border: 1px solid $black;
        height: 50px;
        outline: none;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &:disabled {
            + .selected-option,
            + .error + .selected-option {
                opacity: 0.4;
            }
        }
        &:focus + .selected-option {
            border-color: $black;
        }
        &:focus {
            outline: 2px solid var(--color-btn-focus);
        }
        &.error ~ .selected-option {
            border-color: $black;
            color: $sangria;
        }
    }
    &.disabled {
        pointer-events: none;
    }
    .selected-option {
        display: block;
        background-color: $white;
        line-height: 48px;
        outline: none;
        overflow: hidden;
        position: relative;
        padding: 0px 16px 0px;
        width: 100%;
        color: $black;
        min-height: 50px;
        border: 1px solid $black;
        cursor: pointer;
        z-index: 1;
        font-size: 15px;
        -webkit-transition: all 0.15s linear;
        -moz-transition: all 0.15s linear;
        -ms-transition: all 0.15s linear;
        transition: all 0.15s linear;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        text-transform: uppercase;
        &:before {
            content: "";
            position: absolute;
            background: url("../../images/carat-down.svg") no-repeat;
            top: 40%;
            right: 10px;
            height: 20px;
            width: 20px;
            background-size: 15px 11px;
            background-color: $white;
        }
    }
    .selection-list {
        background-color: $white;
        list-style: none;
        display: none;
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 99;
        border: solid 1px $black;
        border-top: 0px;
        -webkit-transition: all 0.15s linear;
        -moz-transition: all 0.15s linear;
        transition: all 0.15s linear;
        overflow: auto;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        &::-webkit-scrollbar-track {
            background: $dim-gray;
        }
        li {
            line-height: 18px;
            outline: none;
            width: 100%;
            padding: 9px 0 9px 16px;
            color: $black;
            cursor: pointer;
            box-sizing: border-box;
            &:last-child {
                border-bottom: 0px;
            }
            &:hover,
            &.hover {
                color: $white;
                background: $black;
            }
        }
    }
    &.current_item {
        .selection-list {
            display: block;
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
        .selected-option {
            &:before {
                background: url(../../images/carat-up.svg) no-repeat;
                background-size: 15px 11px;
                background-color: $white;
            }
        }
    }
    &.input-focus .selected-option {
        outline: 2px solid var(--color-btn-focus);
    }
}

.pt_account {
    .form-row {
        label {
            + .field-wrapper {
                .custom-select {
                    .selected-option {
                        padding: 8px 15px 0px 20px;
                        height: 49px;
                        text-transform: none;
                        font-size: inherit;
                    }
                }
            }
        }
    }
}

.pt_checkout {
    .form-row {
        label {
            + .field-wrapper {
                .custom-select {
                    .selected-option {
                        padding: 8px 15px 0px 16px;
                        height: 49px;
                    }
                }
            }
        }
    }
}

.pt_checkout,
.pt_account,
.pt_customer-service,
.pt_article-page {
    .custom-select {
        .selected-option {
            border-color: $dusty-gray;
            height: 49px;
        }
        .selection-list {
            padding: 0;
            border: 1px solid $dusty-gray;
            li {
                padding: 12.5px 15px;
                &:hover,
                &:active,
                &.selected,
                &.hover {
                    background: $light-skygrey;
                    color: $black;
                }
            }
        }
        &.current_item {
            .selected-option {
                border-bottom: 0;
                &.selected {
                    border-bottom: 0;
                    background: $white;
                    color: $black;
                    text-indent: 0;
                }
                &:before {
                    background: url(../../images/carat-up.svg) no-repeat;
                    background-size: 15px 11px;
                    background-color: $white;
                }
            }
            .selection-list {
                border-top: 0;
                padding-top: 0;
            }
            &.address-dropdown {
                .selected-option {
                    padding-right: 34px;
                }
            }
        }
        select {
            border: 1px solid $dark-gray;
            max-height: 50px;
        }
    }
    .country {
        .custom-select {
            .selection-list {
                li.selected {
                    display: block;
                }
            }
        }
    } // moving lable to top after selected
    .make-label-absolute {
        .form-row {
            .selected-option {
                text-indent: -9999px;
                background: transparent;
                border-color: $dark-gray;
                &.selected {
                }
            }
            &.show-text {
                &.field-valid {
                    .selected-option {
                        text-indent: -9999px;
                    }
                }
            }
            &.show-text {
                .selected-option {
                    text-indent: 0;
                }
            }
            &.custom-select {
                .input-focus + .field-wrapper .selected-option {
                    text-indent: 0;
                    font-size: 13px;
                    text-transform: capitalize;
                }
            }
            &.error-handle {
                &.state,
                &.postal,
                &.country,
                &.dropdown {
                    label {
                        -webkit-transform: translate(5px, 19px);
                        -ms-transform: translate(5px, 19px);
                        transform: translate(5px, 19px);
                        &.input-focus {
                            -webkit-transform: translate(5px, 10px);
                            -ms-transform: translate(5px, 10px);
                            transform: translate(5px, 10px);
                        }
                    }
                    .selected-option.selected {
                        text-indent: -9999px;
                    }
                }
                &.postal {
                    label {
                        width: 86%;
                    }
                }
                label {
                    span {
                        &.label-text {
                            display: none;
                        }
                        &.error {
                            color: $RedRibbon;
                        }
                        &.required-indicator {
                            color: $RedRibbon;
                        }
                    }
                }
            }
            .field-wrapper {
                .custom-select {
                    background: $white;
                }
            }
            &.postal,
            &.state {
                label {
                    span {
                        &.error {
                            float: left;
                            text-align: left;
                            width: 77%;
                        }
                    }
                }
            }
            .month label {
                height: auto;
                clip: auto;
            }
        }
    }
}

.mm-prod-details {
    .custom-select {
        .selected-option {
            line-height: 38px;
            min-height: 38px;
            letter-spacing: 1px;
            font-size: $base-font - 1px;
        }
    }
}

.make-label-absolute {
    .form-row {
        &.optional {
            .field-wrapper {
                .custom-select {
                    .selected-option {
                        text-indent: -9999px;
                    }

                    @media screen and (max-width: 1024px) {
                        select {
                            text-indent: -9999px;
                        }
                    }
                }
            }
        }
        &:not(.error-handle) {
            > label {
                span {
                    &.error {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .custom-select {
        select {
            position: static;
            filter: alpha(opacity=1);
            -moz-opacity: 1;
            -khtml-opacity: 1;
            opacity: 1;
            padding: 0px 12px 0px;
            border-radius: 0;
        }
        div.selected-option,
        div.selection-list {
            display: none;
        }
        &::after {
            content: "";
            height: 6px;
            width: 11px;
        }
        select {
            background: url("../../images/carat-down.svg") no-repeat right 17px center;
            background-size: 15px 11px;

            &:focus {
                background: url(../../images/carat-up.svg) no-repeat right 17px center;
                background-size: 15px 11px;
            }
        }
    }
    .pt_checkout,
    .pt_account {
        .custom-select {
            select {
                border: 1px solid $dark-gray;
            }
        }
    }
    .selectBox {
        &.error-handle {
            label {
                span.error {
                    display: none;
                }
            }
        }
    } // Handling text overlap in address drop down
    .pt_checkout,
    .pt_account,
    .pt_customer-service,
    .pt_article-page {
        .form-row {
            select {
                text-indent: -9999px;
            }
            &.show-text {
                select {
                    text-indent: 0;
                }
            }
            &.error-handle {
                color: $RedRibbon;
            }
        }
    }
    .login-box-content {
        .custom-select {
            &:after {
                opacity: 0;
            }
            .form-row {
                .custom-select {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .profile {
        .custom-select {
            .input-select {
                cursor: pointer;
            }
            &:after {
                top: 39%;
            }
            .custom-select {
                &:after {
                    display: none;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .custom-select {
        &.address-dropdown {
            background: transparent;

            select {
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                -moz-opacity: 0;
                -khtml-opacity: 0;
                opacity: 0;
                position: absolute;
                z-index: 2;
            }
            div.selected-option {
                display: block;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .custom-select {
        &.address-dropdown {
            .address-nickname-mobile {
                background: url("../../images/carat-down.svg") no-repeat right 17px center;
                background-size: 15px 11px;
                padding: 11px 15px;
                max-height: initial;
                height: auto;
                box-sizing: border-box;
                line-height: 26px;
                font-size: 14px;
                text-transform: none;
                border: 1px solid $dusty-gray;
                .address-name-mobile{
                    display: inline-block;
                    padding-right:5px;
                }
            }
            select {
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                -moz-opacity: 0;
                -khtml-opacity: 0;
                opacity: 0;
                position: absolute;
                z-index: 2;
                max-height: 51px !important;
            }
        }
    }
}

// Custom radio
.custom-radio-wrapper {
    position: relative;
    input[type="radio"] {
        opacity: 0;
        width: 17px;
        height: 17px;
        &:checked {
            + .custom-radio-icon {
                background: url(../../../images/circle-selected.svg);
                background-position: center;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
    .custom-radio-icon {
        background: url(../../../images/circle-outline.svg) no-repeat;
        width: 17px;
        height: 17px;
        background-position: center;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    .label {
        margin-left: 7px;
        vertical-align: top;
    }
}

//Custom slider Checkbox
.custom-slider-checkbox {
    .switch {
        position: relative;
        display: inline-block;
        width: 62px;
        height: 26px;
        float: left;
        input {
            display: none;
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: -2px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $light-skygrey;
        -webkit-transition: 0.4s;
        -ms-transition: 0.4s;
        transition: 0.4s;
        border: 1px solid $alto;
        &.round {
            border-radius: 35px;
            &:before {
                border-radius: 50%;
            }
        }
        &:before {
            position: absolute;
            content: "";
            height: 21px;
            width: 21px;
            left: 3px;
            bottom: 3px;
            -webkit-transition: 0.4s;
            -ms-transition: 0.4s;
            transition: 0.4s;
            background: $dusty-gray;
        }
    }
    input:checked + .slider {
        background-color: $dark-pink;
        &:before {
            -webkit-transform: translateX(33px);
            -ms-transform: translateX(33px);
            -o-transform: translateX(33px);
            transform: translateX(33px);
            background: $light-skygrey;
        }
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }
    .slider-label {
        font-size: $base-font + 2px;
        text-transform: capitalize;
        line-height: 23px;
        margin-left: 15px;
    }
}

.pt_account {
    .catalog-request {
        .custom-radio-icon {
            background: url("../../images/circle-outline.svg") no-repeat;
            width: 17px;
            height: 17px;
            background-position: center;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }
        input[type="radio"] {
            opacity: 0;
            position: relative;
            z-index: 9;
            width: 18px;
            height: 17px;
            &:checked {
                + .custom-radio-icon {
                    background: url("../../images/circle-selected.svg") no-repeat;
                    background-position: center;
                    display: block;
                    position: absolute;
                }
            }
        }
    }
}

.quick-view,
.cart-overlay {
    .slimScrollRail,
    .slimScrollBar {
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        -webkit-border-radius: 0 !important;
        opacity: 1 !important;
    }
}

.pt_checkout {
    .custom-select {
        &.address-dropdown {
            .selected-option {
                padding: 12px 15px !important;
                max-height: initial;
                height: auto !important;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                line-height: 26px;
                font-family: $Helvetica;
                font-size: $base-font + 2px;
                text-transform: none;
            }
            .selected-option,
            .selection-list {
                .nickname-bold {
                    font-family: $HelveticaBold;
                    text-transform: capitalize;
                }
            }
            select {
                &:focus {
                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        ~ .selected-option::before {
                            background: url("../../images/carat-up.svg") no-repeat;
                            background-size: 15px 11px;
                        }
                    }
                    @media screen and (max-width: 767px) {
                        ~ .address-nickname-mobile {
                            background: url("../../images/carat-up.svg") no-repeat right 17px center;
                            background-size: 15px 11px;
                        }
                    }
                }
            }
        }
    }
}

//Disabling scroll for ATC overlay.
body {
    &.disable-scroll,
    &.active-menu-body {
        overflow: hidden;
        @media screen and (max-width: 1024px) {
            height: 100%;
            width: 100%;
        }
    }
}

.custom-select {
    .custom-select-hide & {
        display: none !important;
    }
    &.focus-outline {
        .selection-list {
            li {
                &.selected {
                    border: 3px solid #0000ff !important;
                }
            }
        }
    }
}
