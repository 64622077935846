body {
	&.js-quickorder-zoom-container {
		@media screen and (min-width: 1024px) {
			.zoomWindowContainer {
				.zoomWindow {
					@media screen and (min-width: 1024px) {
						margin-left: 40px;
					}
				}
			}

			.zoomLens {
				background: rgba(239, 239, 239, 0.4) !important;
				width: 120px !important;
				height: 138.6px !important;
				opacity: 1 !important;
			}

			.custom-select {
				.selected-option {
					z-index: 0;
				}
			}
		}
	}
}

.js {
	.ui-dialog-content {
		padding: 0;
		overflow: hidden;
	}
}

.js {
	.zoomPad {
		position: relative;
		float: left;
		z-index: 99;
		cursor: crosshair;
		width: 100%;
	}
	.zoomPreload {
		-moz-opacity: 0.8;
		opacity: 0.8;
		filter: alpha(opacity = 80);
		color: $night-rider;
		font-size: 12px;
		font-family: $sans-serif;
		text-decoration: none;
		border: 1px solid $very-light-gray;
		background-color: $white;
		padding: 8px;
		text-align: center;
		background-image: url(../../../images/zoomloader.gif);
		background-repeat: no-repeat;
		background-position: 43px 30px;
		z-index: 110;
		width: 90px;
		height: 43px;
		position: absolute;
		top: 0px;
		left: 0px;
		*width: 100px;
		*height: 49px;
	}
	.zoomPup {
		overflow: hidden;
		background-color: $white;
		-moz-opacity: 0.6;
		opacity: 0.6;
		filter: alpha(opacity = 60);
		position: absolute;
		border: 1px solid $very-light-gray;
		z-index: 101;
		cursor: crosshair;
	}
	.zoomOverlay {
		position: absolute;
		left: 0px;
		top: 0px;
		background: $white;
		width: 100%;
		height: 100%;
		display: none;
		z-index: 101;
	}
	.zoomWindow {
		position: absolute;
		left: 110%;
		top: 40px;
		background: $white;
		height: auto;
		z-index: 110;
	}
	.zoomWrapper {
		position: relative;
		border: 1px solid $nobel;
		z-index: 110;
	}
	.zoomWrapperTitle {
		display: block;
		background: $nobel;
		color: $white;
		height: 18px;
		line-height: 18px;
		width: 100%;
		overflow: hidden;
		text-align: center;
		font-size: 10px;
		position: absolute;
		top: 0px;
		left: 0px;
		z-index: 120;
		-moz-opacity: 0.6;
		opacity: 0.6;
		filter: alpha(opacity = 60);
	}
	.zoomWrapperImage {
		display: block;
		position: relative;
		overflow: hidden;
		z-index: 110;
		img {
			border: 0px;
			display: block;
			position: absolute;
			z-index: 101;
		}
	}
	.zoomIframe {
		z-index: -1;
		filter: alpha(opacity = 0);
		-moz-opacity: 0.8;
		opacity: 0.8;
		position: absolute;
		display: block;
	}
}

.quickview-nav {
	.quickview-next {
		float: right;
	}
}
#QuickViewDialog {
	.b_product_badge {
		position: absolute;
		z-index: 9;
		top: 0;
		left: 0;
	}
	.pdp-main {
		.main-product-set-details,
		.product-set {
			#thumbnails {
				.thumb {
					padding-right: 0;
					a img[src="null"] {
						display: none;
					}
				}
			}
		}
		.product-brand {
			padding: 10px 0;
			font-size: $base-font;
			font-weight: 400;
		}
	}
}
.ui-dialog.quick-view {
	.ui-widget-header {
		height: 30px;
		.ui-dialog-titlebar-close {
			padding: 15px 25px;
		}
	}
	&.product-set-quickview {
		.ui-widget-header {
			height: 33px;
		}
	}
}
.quickView-product-set-Details {
	width: 59%;
	display: inline-block;
	vertical-align: top;
}
.ui-dialog.quick-view.product-set-quickview .ui-widget-header {
	text-align: right;
	height: auto;
	.ui-dialog-titlebar-close {
		position: relative;
	}
}
