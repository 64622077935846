html.no-scroll {
	height: 100%;
	overflow: hidden;
}

body {
	@screen and (min-width:768px) and (max-width: 1023px) {
		.refinements {
			&.ws-sticky {
				position: sticky;
			}
		}
	}
	&.no-scroll {
		height: 100%;
		overflow: hidden;
		-webkit-overflow-scrolling: auto;
		position: relative;
		#wrapper {
			height: 100%;
			overflow: hidden;
			-webkit-overflow-scrolling: auto;
			#header {
				.fixed-header,
				.header-promo-bottom {
					visibility: hidden;
				}
			}
			#main {
				position: absolute;
				top: 50px;
				z-index: 10;
				.category-search {
					display: none;
				}
				.product-tile {
					visibility: hidden;
				}
			}
		}
		a.back-to-top {
			display: none !important;
		}
	}

	&.sort-overlay {
		.refinements {
			z-index: 22;
		}

		.dropdown-overlay {
			display: block;
		}
	}

	.refinement-breadcrumb .refine-by-clearall .breadcrumb-refinement-value {
		cursor: pointer;
	}

	.refinements {
		@include sticky-to-mixin($top: 54px);
		background: $white;
		z-index: 3;
	}
}
.refinements {
	.refinement .expended .expended-main .slimScrollDiv {
		margin: 0 !important;
		overflow: inherit !important;
	}
	.refinement {
		.expended {
			li {
				padding-left: 1px;
				a {
					outline-offset: -1px;
				}
			}
		}
	}
}

.sort-by {
	&.custom-select {
		select {
			font-size: 15px;
			@media screen and (min-width:1024px) {
				z-index: -1;
			}
		}
	}
}

#main {
	.refinements {
		@media screen and (min-width: 1024px) {
			.mobile-refinement {
				display: flex;
				flex-direction: row;
			}
			.refinement {
				flex: 1;
				outline: 0 !important;
			}
		}

		.refinement {
			button {
				&.refinement-heading-category {
					outline-offset: -3px;
				}
			}
			.refinementsCombinations-main {
				&.expended {
					.expended-main {
						max-height: 400px;
					}
					ul {
						.available,
						.notAvailable {
							a {
								width: 75px;
							}
						}

						@media screen and (min-width: 1024px) {
							width: 260px;
							box-sizing: content-box;
						}
					}
				}
			}
		}
	}
}

.pt_categorylanding {
	#secondary {
		float: left;
		width: 18%;
		max-width: 240px;
		position: relative;
		padding-right: 3.17%;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		display: block;
		padding-top: 20px;
		@media screen and (max-width: 1023px) {
			display: none;
		}
	}

	#primary {
		float: right;
		padding: 20px 0 20px 0;
		position: relative;
		width: 81%;
		max-width: 1020px;
		@media screen and (max-width: 1023px) {
			padding: 0 20px;
			width: 100%;
		}
		@media screen and (max-width: 1023px) {
			padding: 0 20px;
			width: 100%;
		}
		@media screen and (max-width: 1023px) {
			padding: 0;
			width: 100%;
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_product-search-result {
		.dropdown-overlay {
			display: none;
			position: fixed;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			margin-top: 0;
			background: white;
			opacity: 0;
			top: 0;
		}
	}

	.pt_product-search-result.product-search-page {
		.refinements {
			height: 40px;
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_product-search-result {
		.category-search .simplesearch {
			.result-count {
				&.js-fixed-count {
					margin-top: 51px !important;
				}
			}
		}
		.refinements .filters,
		.sort-by {
			height: 40px;
			background: $white;
			top: 51px;
			outline-offset: -3px;			
		}
		.refinements {
			margin-bottom: 20px;
			background: transparent;
			z-index: 2;
			top: 65px;
			&.js-fixed-bar {
			}
			> .hide-desktop {
				top: 51px;
				&[tabindex]:not(.focus-outline){
					outline: none;
				}
			}
			.filters {
				padding: 0;
				.filter {
					padding: 10px 14px;
					width: 99%;
					outline-offset: -3px;
					box-sizing: border-box;
					i {
						margin-right: -4px;
					}
				}
			}
		}
		.result-count-mobile {
			&.js-fixed-count {
				margin-top: 51px !important;
			}
		}
		&.product-search-page {
			.result-count-mobile {
				display: none;
			}
			.refinements .filters,
			.sort-by {
				height: 40px;
			}
		}
		#header {
			z-index: 10;
			.fixed-header {
				z-index: 10;
				position: static;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.refinements {
		.refinements-count {
			.refinement {
				&.active {
					.refinement-heading-category {
						.toggle-heading {
							pointer-events: none;
							cursor: not-allowed;
						}
					}
				}
			}

			&:not(.has-selection) .refine-by-clearall {
				display: none;
			}
		}
		.hide-desktop {
			display: inline-block;
			width: 50%;
			outline-offset: -3px;
			.sort-by {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.refinements {
		.hide-desktop {
			display: inline-block;
			width: 50%;
			.sort-by {
				width: 100%;
			}
		}

		.refinements-count {
			overflow: auto;

			.mobile-refinement {
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch;
				display: inline-block;
			}

			.refine-by-clearall {
				display: none;

				a {
					width: auto;
				}

				.view-results {
					display: none;
				}
			}

			&.has-selection {
				.refine-by-clearall {
					display: block;
					width: 97%;

					.view-results {
						display: block;
						outline-offset: -3px;
					}
				}
			}
		}
	}
	.pt_product-search-result {
		.refinements {
			display: block;
		}
		&.product-list-page {
			.refinements {
				&.search-scroll-css {
					z-index: 999;
				}
			}
			.result-count-mobile {
				margin: 10px;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.refinements {
		.refinements-count {
			&.has-selection {
				.refine-by-clearall {
					width: 95%;
				}
			}
		}
	}
}

@media screen and (max-width: 375px) {
	.refinements {
		.sort-by {
			&.current_item {
				.selection-list {
					padding: 0 7px;

					li {
						padding: 16px 0 16px 37px;
						border-bottom: 1px solid $very-light-gray;
					}
				}
			}
			.selected-option:before {
				margin-top: -2px;
			}
		}

		.filters {
			.refinements-count {
				padding-top: 3px;
			}
			.refinement {
				padding: 18px 0;
				.refinement-heading-category {
					padding: 0 0 0 11px;
					.count {
						padding: 7px 0 0 0;
						display: none;

						&.selected {
							display: block;
						}
					}
					i {
						&.fa-angle-up {
							top: 55%;
						}
					}
					&.expanded {
						.count-selected-val.selected {
							display: none;
						}
					}
				}
				.brand {
					text-transform: uppercase;
				}
			}
			.refine-by-clearall {
				.device-clear-all {
					color: $black;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	body.single-column-refinement {
		.refinements {
			.sort-by {
				&.current_item {
					.selection-list {
						padding: 0 7px;

						li {
							padding: 16px 0 16px 37px;
							border-bottom: 1px solid $very-light-gray;
						}
					}
				}
				.selected-option:before {
					margin-top: -2px;
				}
			}
		}
	}
	body.single-column-refinement {
		#main {
			.refinements {
				&.ws-sticky:not(.js-fixed-bar) {
					width: 70%;
				}
				.filters {
					&:not(.refinement-mobile) {
						padding: 0px;
						.filter {
							display: flex;
							align-items: center;
							height: 100%;
							justify-content: center;
						}
					}
					.filter-open {
						display: none;
					}
					&.refinement-mobile {
						width: 65%;
						@media screen and (max-width: 360px) {
							width: 75%;
						}
						background: $black;
						padding: 0px;
						.filter {
							display: inline-block;
							width: auto;
							padding-left: 20px;
							height: 40px;
							box-sizing: border-box;
							line-height: 40px;
						}
						.filter-close {
							display: none;
						}
						.filter-open {
							display: inline-block;
						}
						.align-right {
							float: right;
							height: 100%;
							a {
								margin-left: 5px;
								position: relative;
								height: 100%;
								padding: 5px;
								box-sizing: border-box;
								margin-right: 15px;
								img {
									width: 17px;
									margin: 0;
									padding-top: 5px;
								}
							}
						}
					}
					.filter-text {
						position: relative;
						margin-left: 5px;
						&.filter-count {
							margin-left: 2px;
						}
					}
					.refinements-count {
						padding: 20px 20px 80px 20px;
						.result-count-mobile {
							display: block;
							text-align: left;
							background: transparent;
							border-bottom: 1px solid;
							box-sizing: border-box;
							padding-left: 0px;
							padding-top: 0px;
							margin: 0px;
							width: 100%;
							font-size: $base-font - 2px;
							&.hide {
								display: none;
							}
						}
						.refinement {
							padding: 18px 0;
							border-bottom: 1px solid $black;
							&.firstrefinement {
								border-top: 1px solid $black;
							}
							.refinement-heading-category {
								background: transparent;
								color: inherit;
								position: relative;
								font-size: $base-font + 1;
								padding-left: 0px;
								.toggle-heading {
									display: flex;
									align-items: center;
									flex-wrap: wrap;
								}
								.last-selected-filter {
									display: none;
								}
								.count {
									color: $black;
									&.selected {
										flex: auto;
										max-width: 100%;
										line-height: 1.5;
										width: 100%;
									}
								}
								i {
									&.fa-angle-up {
										top: 50%;
										transform: none;
										-ms-transform: none;
										-webkit-transform: none;
									}
								}
								&.expanded {
									.count-values,
									.count-selected-val.selected {
										display: none;
									}
								}
							}
							&.active {
								padding: 0px;
								.refinement-heading-category {
									margin: 0;
									position: relative;
									border-bottom: 0px;
									i {
										&.fa-angle-up {
											transform: rotateZ(180deg);
											-ms-transform: rotateZ(180deg);
											-webkit-transform: rotateZ(180deg);
										}
										&.fa-angle-down {
											display: none;
										}
									}
									.last-selected-filter {
										display: inline;
										margin-left: 10px;
										font-weight: 400;
										text-transform: none;
										max-width: 70%;
									}
									.toggle-heading {
										border-bottom: 2px solid $black;
										padding-bottom: 5px;
										display: inline;
									}
								}
								.clear-refinement {
									a {
										color: $dark-gray;
										outline-offset: -3px;
									}
								}
								.expended {
									.scroll-bar-height {
										margin: 15px 0px 10px;
										padding: 5px 0 0 3px;
										ul:not(.silhoutteheadermenu, .refinementColor) {
											width: 50%;
										}
										.refinementColor {
											width: 100%;
											.color-refinement-count {
												ul {
													width: 100%;
													display: flex;
													flex-direction: row;
													flex-wrap: wrap;
													li {
														max-width: 15%;
														flex: 0 0 15%;
														@media screen and (max-width: 360px) {
															max-width: 20%;
															flex: 0 0 20%;
														}
														a {
															width: 30px;
															height: 30px;
															border-radius: 50%;
															box-sizing: content-box;
														}
														&.selected {
															a {
																&::after {
																	height: 38px;
																	width: 38px;
																	border-radius: 50%;
																	left: -5px;
																	top: -5px;
																}
															}
														}
													}
												}
											}
										}
									}
								}
								&.refinementColor {
									.toggle.refinement-heading-category.expanded {
										display: flex;
										align-items: center;
										flex-direction: row;
										align-items: baseline;
										.last-selected-filter {
											margin-top: -5px;
										}
									}
								}
							}
							.brand {
								text-transform: uppercase;
							}
							.hide {
								display: none !important;
							}
							.count-values,
							.count {
								flex: auto;
								margin-left: 10px;
								padding-top: 0px;
								max-width: 70%;
								@media screen and (max-width: 360px) {
									max-width: 64%;
								}
							}
						}
						.spinner {
							display: none;
						}
						&.refinement-overlay {
							&::before {
								position: absolute;
								display: block;
								content: "";
								width: 100%;
								height: 100%;
								background-color: rgba(216, 215, 223, 0.9);
								z-index: 999;
								top: 0;
								left: 0;
							}
							.spinner {
								animation: rotate 2s linear infinite;
								z-index: 1000;
								position: absolute;
								display: block;
								top: 50%;
								left: 50%;
								margin: -25px 0 0 -25px;
								width: 50px;
								height: 50px;

								& .path {
									stroke: $black;
									stroke-linecap: round;
									animation: dash 1.5s ease-in-out infinite;
								}
							}

							@keyframes rotate {
								100% {
									transform: rotate(360deg);
								}
							}

							@keyframes dash {
								0% {
									stroke-dasharray: 1, 150;
									stroke-dashoffset: 0;
								}
								50% {
									stroke-dasharray: 90, 150;
									stroke-dashoffset: -35;
								}
								100% {
									stroke-dasharray: 90, 150;
									stroke-dashoffset: -124;
								}
							}
						}
						.apply-button {
							position: absolute;
							bottom: 14px;
							z-index: 12;
							width: calc(100% - 40px);
							left: 20px;
							.button {
								width: 100%;
								box-sizing: border-box;
							}
						}
					}
					.refine-by-clearall {
						.device-clear-all {
							color: $black;
						}
					}
				}
				.sort-by {
					fieldset {
						label {
							.sort-open {
								display: none;
							}
						}
					}
					.selected-option:before {
						display: none;
					}
					&.current_item {
						background: $black;
						form {
							box-sizing: border-box;
						}
						fieldset {
							label {
								.sort-closed {
									display: none;
								}
								.sort-open {
									display: inline;
								}
								.close-btn {
									float: right;
								}
							}
						}
					}
					&.custom-select {
						.selected-option {
							position: absolute;
							width: 50%;
							right: 0;
							text-indent: -9999px;
							top: 0px;
						}
						&:not(.current_item) {
							form label {
								display: flex;
								align-items: center;
								flex-direction: row;
								justify-content: center;
								img {
									margin-right: 10px;
								}
							}
						}
						&.current_item {
							.selected-option {
								position: absolute;
								width: 20%;
								right: auto;
								text-indent: -9999px;
								left: 30%;
								top: 0;
							}
						}
					}
				}
			}
		}
	}
	body.disable-scroll.single-column-refinement {
		#main {
			&::before {
				content: "";
				position: fixed;
				z-index: 2;
				height: 100%;
				width: 100%;
				top: 0px;
				left: 0px;
				background-color: rgba(216, 215, 223, 0.9);
			}
		}
		.pt_product-search-result.product-search-page .refinements.js-fixed-bar {
			background: transparent;
		}
	}

	body.single-column-refinement .pt_product-search-result.product-list-page {
		#main {
			.mobile-only-filter {
				& > .result-count-mobile {
					position: absolute;
					background: none;
					padding: 0px;
					text-align: left;
					margin: 0px;
					width: auto;
					max-width: 25%;
					right: 10px;
					bottom: 0px;
					@media screen and (max-width: 360px) {
						max-width: 20%;
					}
				}
				position: relative;
				max-height: 40px;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.pt_product-search-result {
		.refinements {
			display: table;
			top: 65px;
		}
	}
}
@media screen and(max-width:1023px) {
	.pt_product-search-result {
		.refinements {
			.filters {
				.refinement {
					&.active {
						.clear-refinement {
							a {
								color: $dark-gray;
							}
						}
					}
					.toggle-heading {
						padding-left: 5px;
					}
				}
				&.refinement-mobile {
					.refinements-count {
						.refinement.silhouette {
							.expended.right {
								.silhoutteheadermenu {
									li {
										width: 49%;
										a {
											line-height: 15px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.refinements .refinement .refinement-heading-category.focus-outline {
	outline-offset: -3px;
}
@media screen and (max-width: 767px) {
	.refinements {
		.filters {
			.refinement {
				&.plpRefinement {
					padding: 0;
					.refinement-heading-category {
						padding: 18px 0;
					}
				}
			}
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 1023px){
	.pt_product-search-result .refinements .sort-by{
		&:focus{
			outline:none;
		}
		&.custom-select .selected-option 
		{
			width: calc(100% - 9px) !important;
			margin-left:-95px;
			padding-left:95px;
			max-width:100%!important; 
	}
  }
}