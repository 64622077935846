#wrapper.pt_checkout_globale.pt_checkout{
    
    @media screen and (min-width: 1025px){
        max-width: 100%;
    }
    .primary-content{
        max-width: 100%;
    }

    #header {
        .fixed-header{
            border-bottom: 1px solid $alto;
            min-height: 50px;
        }
        .primary-logo a {
            top: 0px;
            width: auto;
            svg{
                max-height: 30px;
            }
        }
        .header-mian-banner{            
            border-bottom: 0;

            @media screen and (min-width: 768px) {                
                padding-bottom: 0;
                padding-top: 10px;                             
            }
            @media screen and (min-width: 768px) and (max-width: 1023px) {                
                padding-top: 15px;    
            }  
            .chk-out-header-right-section{

                @media screen and (min-width: 1024px) {                
                    max-width: 193px;      
                }

                .help-click{
                    color: $navy-blue;
                    font-family: $Helvetica;
                    font-size: $base-font;
                    background: none;
                    @media screen and (max-width: 767px){
                        top: 24px;
                        padding-right: 6px;
                    }
                    
                    svg,img{
                        display: inline;
                        width: 12px;
                        margin-left: 6px;                  
                    }                    
                }

                &.active .help-click {
                    svg,img{
                        transform: rotate(180deg);
                        vertical-align: top;
                        margin-top: 4px;
                    }
                }

                .content {
                    border-bottom: 0;
                    
                    .content-asset {
                        letter-spacing: normal;
                        color: $mortar-grey;
                        border-bottom: 0;  
    
                        p,a,b,span{
                            color: $mortar-grey;                        
                            font-size: $base-font - 2px;
                            letter-spacing: normal;
                        }
                        
                        p,a{
                            font-family: $Helvetica;
                        }
                        b{
                            font-family: $HelveticaBold;
                        }
                    }
                }     
            }
        }
    }
    .intl-footer{
        .footer-container.footer-down{
            margin: 0;
            background: $light-skygrey;
            .footer-down-inner{
                margin: 27px auto;
                .footer-help {
                    padding-bottom: 26px;
                    border-bottom: 1px solid $dark-gray;
                    margin-bottom: 16px;

                    .text-center{
                        text-align: center;
                    }

                    a{
                        display: inline;
                    }
                    p,a{
                        font-size: $base-font + 2px;
                        color: $black;
                        font-family: $Helvetica;
                    }
                    img{
                        vertical-align: bottom;
                    }
                }
                .footer-legal-copyrights{
                    .static-links{
                        margin-bottom: 16px;

                        li{
                            margin-right: 24px;
                            @media screen and (max-width: 767px){
                                display: inline-block;
                            }
                            a{
                                font-size: $base-font;
                                color: $black;
                                font-family: $Helvetica;
                                font-weight: normal;
                                text-decoration: none;
                                letter-spacing: normal;
                                text-transform: none;
                            }
                        } 
                    }
                    .copyrights-wrapper{
                        margin-bottom: 24px;

                        p,span{
                            font-size: $base-font;
                            color: $dim-gray;
                            font-family: $Helvetica;
                        }
                        p{
                            letter-spacing: 0.12px;
                        }
                        .copy-arr{
                            margin-bottom: 0px;
                            line-height: 1.67;
                        }
                    }
                }
            }
        }
    }
}