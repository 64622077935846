.login-box-content {
	&.returning-customers {
		.form-row {
			&.username,
			&.password {
				float: none;
				&.error-handle {
					.field-wrapper {
						&:before {
							font: normal normal normal 28px/1 FontAwesome;
							font-size: $base-font + 13px;
							text-rendering: auto;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							content: "\f071";
							color: $venetian-red;
							position: absolute;
							top: 11px;
							left: 11px;
							transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
						}
					}
					label {
						padding-left: 51px;
						left: 0px;
						.error,
						.server-error {
							color: $venetian-red;
						}
						.label-text {
							display: none;
						}
					}
					input[type="text"],
					input[type="password"] {
						border: 1px solid $venetian-red;
						padding-left: 47px;
						&.error {
							padding-left: 27px;
							color: $black;
						}
					}
					.input-focus {
						padding-left: 27px;
						transform: translate(0, 4px);
						left: 0px;
						+ .field-wrapper {
							&:before {
								font-size: 8px;
								top: 7px;
								left: 10px;
							}
							input[type="text"],
							input[type="password"] {
								padding-left: 25px;
								color: $black;
							}
						}
					}
				}
				label:not(.input-focus) {
					transform: translate(0, 17px);
				}
			}
		}

		.reset-error-caption {
			padding: 2px 10px;
			margin: -8px 0 7px;
			background: $venetian-red;
			border-radius: 0 0 3px 3px;
			text-align: left;
			font-family: $WorkSans;
			font-size: $base-font;
			color: $white;
			width: auto;
			letter-spacing: -0.33px;
			line-height: 14px;
			transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
			&.hide {
				display: none;
			}
			&.gb-msg{
				border-radius: 0;
				margin-top: 25px;
			}
			.create-acclink {
				color: $white;
				text-decoration: underline;
				display: inline;
			}
		}
	}
}

.username-req,
.password-req {
	display: none;
}

.error-handle {
	+ .username-req,
	+ .password-req {
		display: block;
	}
}
