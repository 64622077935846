.country-selector {
	border-bottom: 5px solid darken($dim-gray, 10%);
	color: $white;
	width: 100%;
	@media screen and (min-width: 768px) {
		border-bottom: none;
		padding: 0;
		position: absolute;
		top: -1rem;
		right: 0.2rem;
		z-index: 30;
		width: auto;
	}
	.selector {
		display: none;
		margin-top: 0.5rem;
		padding-left: 2rem;
		&.active {
			display: block;
		}
		@media screen and (min-width: 768px) {
			background-color: $white;
			border-radius: 3px;
			box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3);
			margin-top: 1rem;
			padding: 1rem;
		}
	}
	.current-country {
		cursor: pointer;
		padding: 0.8em 1rem;
		&.selector-active {
			border-bottom: 1px solid darken($night-rider, 10%);
			.selector-icon {
				transform: rotate(270deg);
			}
		}
		@media screen and (min-width: 768px) {
			padding: 0;
			&.selector-active {
				border-bottom: none;
				padding-bottom: 0;
				// prevent the text from shifting left to accommmodate the selector width
				position: absolute;
				right: 0;
			}
		}
		.selector-icon {
			margin-right: 0.3rem;
			@media screen and (min-width: 768px) {
				float: right;
				margin-right: 0;
				margin-left: 0.3rem;
				// display as angle-down icon for desktop
				transform: rotate(90deg);
			}
		}
		.flag-icon {
			border: none;
		}
	}
	.continent-name {
		margin-top: 0;
		margin-bottom: 0.5rem;
		@media screen and (min-width: 768px) {
			color: $sangria;
		}
	}
	.country {
		margin-bottom: 0.5rem;
	}
	.locale {
		border-right: 1px solid $gainsboro;
		display: inline-block;
		padding: 0 0.3rem;
		vertical-align: middle;
		&:first-of-type {
			padding-left: 0;
		}
		&:last-of-type {
			border-right: none;
			padding-right: 0;
		}
	}
	.flag-icon {
		border: 1px solid $gainsboro;
		margin-right: 0.3rem;
	}
}

.globale-country-selector{	

	.title{
		font-size: $base-font + 4px;
		@include WorkSansMedium($WorkSans);		
	}
	
	.globale-selector{
		background-color: transparent;
		border: 0;
		font-size: $base-font + 2px;
		color: $black;
		border-radius: 0;
		padding: 0px;
		border-radius: 0;
		@include WorkSansMedium($WorkSans);
		.flag-icon{
            margin-right: 10px;
        }
	}
}
.menu-footer .footer-nav .globale-country-selector{
	padding-bottom: 20px;
    border-bottom: 1px solid $silver;
}

@media screen and (max-width:1023px){
	.desktop-country-selector{
		display: none;
	}
}

@media screen and (min-width:1024px){
	
%header-country-selector-properties{
	position: absolute;
    right: 0;
    top: 0px;
	height: 100%;
	width: 46px;
	background: $black;
	padding-top: 8px;
	.globale-selector {
		width: 100%;
		.flag-icon,.country-code{
			display: block;
			text-align: center;
			color: $white;
			font-size: 10px;
			letter-spacing: 0.11px;
		}
		.flag-icon{
			margin: 8px auto 5px;
		}
	}
}

.header-promo-banner #sfcc-nanobar-container .desktop-country-selector{
	@extend %header-country-selector-properties;
	.current-country{
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		line-height: 1;
		height: 26px;
		.flag-icon{
			margin-top: 0px;
		}
	}
	min-height: 40px;	
}
.header-country-selector-container{
	height: 37px;
    margin-bottom: -16px;
    z-index: -1;
    background-color: $black;
	.country-sel-inn-row{
		position: fixed;
		z-index: 9;
		width: 100%;
		left: 0;
		top: 0;
		height: 37px;		
		.desktop-country-selector{
			@extend %header-country-selector-properties;
			height: 37px;
			padding-top: 0px;
			.globale-selector .flag-icon{
				margin: 8px auto 5px;
				min-width: 16px;
				min-height: 11px;
			}			
		}
	}	
}
.header-sticky-desktop .country-sel-inn-row{
    background-color: $black;
}
html:not(.mac-os) body.browser-chrome {
	#sfcc-nanobar-container.sticky ,
	.header-country-selector-container {
		.desktop-country-selector{
			right: 15px !important;
		}
	}
}
.header-country-selector-container.only-sticky{
    display: none;
}
.header-sticky-desktop .header-country-selector-container.only-sticky{
    display: block;
}
}
@media screen and (min-width:1024px) and (max-width:1071px){
.header-promo-banner #sfcc-nanobar-container.globalE-enabled .nanobar-inner-row{
    max-width: calc(100% - 46px);
	margin-left: 0;
}
html:not(.mac-os) body.browser-chrome .header-promo-banner #sfcc-nanobar-container.globalE-enabled.sticky .nanobar-inner-row{
	max-width: calc(100% - 61px);
}
}
.footer-globale-country-selector .flag-icon{
	margin-right: 5px;
}
