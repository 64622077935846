.right-panel {
	width: 0;
	height: calc(100vh - 66px);
	position: fixed;
	top: 66px;
	right: 0;
	pointer-events: none;
	visibility: hidden;
	&.p-absolute {
		position: absolute;
	}
	.right-panel-inner {
		position: absolute;
		top: 0;
		right: -361px;
		width: 100%;
		max-width: 361px;
		height: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		transition: right 200ms;
	}
	.right-panel-pane {
		display: none;
	}
	.right-pane-body {
		padding: 20px 12px;
	}
}

.right-panel-open {
	overflow: hidden;
	background-color: $black;
	&:not(.header-mobile-menu-open) {
		-webkit-overflow-scrolling: touch;
	}
	.right-panel {
		width: 100%;
		pointer-events: all;
		background: rgba(255, 255, 255, 0.6);
		z-index: 9999;
		visibility: visible;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		cursor: pointer;
		.right-panel-inner {
			right: 0;
			background: #fff;
			box-shadow: -35px 0px 20px -15px rgba(0, 0, 0, 0.26);
			cursor: initial;
		}
	}
	.right-panel-pane.active {
		display: block;
	}
}

/*------- IE Edge Specific CSS -------*/
@supports (-ms-ime-align: auto) {
	.right-panel-open,
	.right-panel-open div[id^="monetate_selectorBanner_"] {
		padding-right: 16px;
	}
}

/*------- IE 10+ Specific CSS -------*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.right-panel-open,
	.right-panel-open div[id^="monetate_selectorBanner_"] {
		padding-right: 16px;
	}
}
