.checkout-body:not(.plcc-redesign), body[data-page="credit-apply"] .plcc-apply-error-model {
//PLCC Step1
.ui-dialog {
	&.checkout-plcc-modal,
	&.checkout-plcc-modal-step2,
	&.plcc-acquisition-rebuttal-model,
	&.checkout-plcc-modal-step3 {
		@media screen and (max-width: 1024px) {
			top: 0 !important;
		}
		
		.ui-dialog-titlebar-close {
			cursor: not-allowed;
			pointer-events: none;
			display: none;
			span {
				&.ui-icon-closethick {
					background-image: url(../../images/plcc-close-button-icon.png);
					height: 18px;
					width: 18px;
					left: 44%;
					top: 58%;
					opacity: 0;
				}
			}
		}

		.ui-dialog-title {
			display: block;
			text-align: right;
			width: 93%;
			text-transform: initial;
			font-weight: normal;
		}
	}

	&.checkout-plcc-modal-step2,
	&.plcc-acquisition-rebuttal-model {
		height: 100% !important;
		overflow-y: scroll;
	}

	&.checkout-plcc-modal-step3 {
		height: auto !important;
	}
}
.checkout-stepone-scroll, #dialog-container,
.checkout-plcc-modal-step2,
.plcc-acquisition-rebuttal-model,
.checkout-plcc-modal-step3 {
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;  
	scrollbar-width: none;  
}

.checkout-plcc-modal {
	
	.plcc-section-1 {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;

		.plcc-banner {
			width: 100%;
			display: inline-block;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
		}

		.plcc-banner-main {
			margin: 0 auto;
			text-align: center;
		}

		.bonus-reward {
			width: 100%;
			display: inline-block;
			text-align: center;

			h1 {
				text-transform: uppercase;
			}

			h2 {
				margin: 0;
				text-transform: inherit;
				font-weight: normal;
			}

			ul {
				padding: 0;
				margin: 0;
				width: 100%;
				display: inline-block;

				li {
					list-style: none;
					float: left;

					.section {
						margin: 0 auto;
					}

					span {
						width: 100%;
						display: inline-block;
					}
				}
			}
		}
	}

	.pre-screen-notice {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;

		.prescreen-message {
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			text-align: center;
		}
	}

	.legal-copy {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;

		span {
			width: 100%;
			display: inline-block;
		}
	}

	.form-row-button {
		border-top: 1px solid $alto;

		button {
			letter-spacing: 1px;
			color: $white;
			border: 0;

			&.plcc-nothanks-button {
				background: $white;
			}

			&.plcc-get-it-button {
				float: right;
			}
		}
	}
}

//PLCC Step2 Dialog
.checkout-plcc-modal-step2 {
	&.ui-dialog {
		.ui-icon-closethick {
			background: url(../../images/close-button-icon.png) no-repeat;
			width: 27px;
			height: 16px;
		}
	}
}

.plcc-model2 {
	&.plcc-main {
		margin-top: 0;
		.plcc-primary {
			.ssn {
				label {
					&.input-focus {
						font-size: $base-font;
					}
				}
			}
		}
	}
	.bottom-buttons {
		padding: 0;
		clear: both;
		box-sizing: border-box;
		margin: 0;
		.form-row {
			&.form-indent {
				padding: 0 41px 35px 41px;
				box-sizing: border-box;
				border-bottom: 1px solid $alto-grey;
			}
		}

		@media screen and (max-width: 1024px) {
			background: #fff;
			width: 100%;
			box-sizing: border-box;
			margin: 0 auto;
		}
		@media screen and (max-width: 767px) {
			text-align: center;
			padding: 0 15px 20px;
			.form-row {
				&.form-indent {
					padding: 0 0 35px 0;
				}
			}
		}
	}
	.plcc-primary {
		width: 100%;
		box-sizing: border-box;

		&.handle-mobile-overflow {
			overflow: auto;
		}
		.iframe-content {
			margin: 0 0 20px 0;
		}

		.form-row {
			&.show-text {
				.selected-option {
					&.selected {
						text-indent: 0;
					}
				}
			}
		}

		.plcc-form-row {
			width: 47.1%;
            display: inline-block;
            &.plcc-form-row-1 {
                float: left;
            }
            &.plcc-form-row-2 {
                float: right;
            }
			&.plcc-form-row-1 {
				width: 51%;
				margin: 0 43px 0 0;
			}
		}
	}

	.plcc-upper {
		padding: 25px 41px 0 41px;
		margin: 0;
	}

	.plcc-lower {
		padding: 20px 41px 25px 41px;
		display: inline-block;
		width: 100%;
		box-sizing: border-box;

		.plcc-modal-error {
			color: $sangria;
			margin: 0 0 10px 0;
		}

		button {
			padding: 14px 41px;
		}
	}

	.plcc-model2-form {
		width: 100%;
		display: inline-block;
		background-color: $white-smoke-light;
		padding: 37px 20px 14px 28px;
		box-sizing: border-box;
		margin: 0 0 20px 0;
	}

	.pre-screen-notice {
		.underlined {
			text-decoration: underline;
		}
	}

	.plcc-accept-button {
		float: right;
	}

	.plcc-profile-summary {
		width: 37%;
		display: inline-block;
		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}
}

//PLCC Apply Error Model
.plcc-apply-error-model {
	&.ui-dialog {
		.ui-icon-closethick {
			display: none;
		}

		.ui-dialog-titlebar {
			button {
				cursor: not-allowed;
				pointer-events: none;
			}
		}

		button {
			cursor: pointer;
		}
	}
}

.plcc-apply-model {
	width: 64%;
	margin: 0 auto;
	margin-bottom: 16.4%;
	text-align: center;
	
	.review-model {
		.header div{
			display: inline;
		}
		margin: 4.9% 0 0 0;
		.review-message{
			font-size: 14px;
			color: #666;
			margin: 0 0 12% 0;
			.message{
				display: inline;
			}
		}
	}

	.header {
		margin: 0 0 6.4% 0;
	}

	.sub-header {
		margin: 0 0 11% 0;
	}

	button {
		padding: 15px 58px;
	}
}

.approved-model {
	width: 93%;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 8%;

	.header {
		margin: 0 0 5% 0;
	}

	.sub-header {
		width: 74%;
		margin: 0 auto;
		margin-bottom: 4%;
	}

	.continue-message {
		display: inline-block;
		width: 100%;
		margin: 0 0 4% 0;
	}

	.guest-sign-in {
		float: left;
	}
	//approved modal from apply page
	.approved-message, .approved-giftcard {
		width: 95%;
		margin: 0 auto;
		margin-bottom: 6%;
		color: $dim-gray;
		.approved-message-part3{
			margin-top: 5%;
		}
	}
	.approved-legal{
		display:none;
	}
}
//approved modal buttons from apply page
	.bottom-buttons{
		.approved-buttons {
			display:flex;
			justify-content: space-around;
			margin-bottom: 6%;
			button{
				width: 44%;
			}
		}
	}
	@media screen and (max-width: 767px){
		.bottom-buttons{
			.approved-buttons {
				flex-direction: column;
				align-items: center;
				button{
					margin-bottom: 5%;
					width: 82%;
					font-size: 12px;
				}
			}
		}
	}

//PLCC Acquisition Rebuttal Model
.plcc-acquisition-rebuttal-model {
	&.ui-dialog {
		.ui-icon-closethick {
			background: url(../../images/close-button-icon.png) no-repeat;
			width: 22px;
			height: 22px;
		}
		.ui-widget-header {
			height: 60px;
		}
	}
	.scrollingpart {
		overflow-y: scroll;
		border-bottom: 1px solid $alto;
	}
}
.plcc-acquisition-rebuttal-model {
	.legal-copy-message {
		&.rebuttal-in-hide {
			display: none;
		}
	}
	.rebuttal-banner {
		margin: 0 auto;
		text-align: center;
		position: relative;
	}

	.rebuttal-banner-text {
		position: absolute;
		top: 52%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		left: 50%;
		width: 83%;
		display: inline-block;
	}

	.rebuttal-section {
		padding: 0 40px 0 40px;
	}

	.form-row-button {
		margin: 20px 40px;
	}
}

.billing-cards-section .checkout-rebuttal {
	border: 2px solid $dusty-gray;
	padding: 15px 15px 10px 15px;
	font-size: $base-font + 1px;
	font-family: $Helvetica;
	margin: 0 0 23px 0;
	background-color: $white;
	letter-spacing: 0.5px;

	@media screen and (max-width: 767px) {
		padding: 15px 8px 10px 8px;
		letter-spacing: normal;
	}

	.pre-approved {
		font-family: $HelveticaBold;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.extra-percent {
		font-family: $HelveticaBold;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	.acquisition-rebuttal {
		font-family: $HelveticaBold;
		font-size: $base-font;
		cursor: pointer;

		@media screen and (max-width: 767px) {
			display: inline-block;
		}
	}
}

@media screen and (max-width: 768px) {
	.checkout-plcc-modal,
	.checkout-plcc-modal-step2,
	.plcc-acquisition-rebuttal-model {
		touch-action: auto;
		#dialog-container {
			overflow-y: scroll;
		}
	}
}

@media screen and (max-width: 1023px) {
	.plcc-apply-model {
		margin-bottom: 16.5%;
	}
}

@media screen and (max-width: 767px) {
	.plcc-model2 {
		&.plcc-main {
			margin: 0;
			padding: 0;

			.plcc-primary {
				.form-row {
					&.form-indent {
						label {
							float: inherit;
						}
					}
					&.month {
						width: 33.33%;
					}
				}
				.plcc-form-row {
					&.plcc-form-row-1 {
						width: 100%;
						margin: 0;
					}
				}

				.important-info {
					padding: 0 0 15px 0;
				}

				.phone-number-label {
					display: inline-block;
				}
			}
		}

		.plcc-upper {
			padding: 0 10px;
		}

		.plcc-model2-form {
			padding: 25px 10px 10px 10px;
		}

		.pre-screen-notice {
			padding: 18px 6px;
		}

		.plcc-lower {
			padding: 10px;

			.plcc-nothanks-button {
				width: 41%;
			}

			.plcc-accept-button {
				width: 49.9%;
			}
		}
	}
	.plcc-apply-model {
		width: 70%;

		button {
			padding: 15px 17px;
		}
	}
}

@media screen and (max-width: 480px) {
	.plcc-model2 {
		.plcc-lower {
			.plcc-nothanks-button {
				padding: 14px 18px;
				width: auto;
			}

			.plcc-accept-button {
				width: auto;
				padding: 14px 22px;
			}
		}
	}
}
}

