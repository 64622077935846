/* forms overrides TBR start */

@mixin label_mixin($translateX: 0, $translateY: 20px, $position: absolute) {
    font-weight: normal;
    font-size: $base-font;
    position: $position;
    padding: 0 0 0 17px;
    -webkit-transform: translate($translateX, $translateY);
    -ms-transform: translate($translateX, $translateY);
    transform: translate($translateX, $translateY);
    margin: 0;
    top: 0;
    left: 0;
    width: auto;
    color: $dim-gray;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
    transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
    -ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
}

@mixin input-focus_mixin($translateX: 0, $translateY: 7px) {
    -webkit-transform: translate($translateX, $translateY);
    -ms-transform: translate($translateX, $translateY);
    transform: translate($translateX, $translateY);
}

@mixin field-wrapper_mixin {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: 0.5rem;
    width: 100%;
}

@mixin inputs_mixin {
    height: auto;
    padding: 19px 0 9px 17px;
    line-height: normal;
}

@mixin selected-option_mixin {
    @include inputs_mixin();
    padding: 19px 0 9px 16px;
}

@mixin button_mixin {
    padding-top: 16.5px;
    padding-bottom: 16.5px;
    height: auto;
}

@mixin formjsCorrector {
    > span.error ~ span.label-text,
    span.invalid ~ span.label-text {
        display: none;
    }

    > span.error[style^="display"] ~ span.label-text {
        display: block;
    }
}

.field-wrapper {
    @include field-wrapper_mixin();

    input {
        &.phone,
        &.alternativePhone {
            &::-ms-clear {
                display: none;
            }
        }
    }
}

.emailsignup .field-wrapper {
    @include field-wrapper_mixin();
    margin-bottom: 0;
}

.field-wrapper label,
.gc-balance.make-label-absolute .form-row label,
.create-login .login-create-account .form-row.addtoemaillist label {
    @include label_mixin($position: relative);
    &.input-focus {
        @include input-focus_mixin();
    }
}

.create-login .col-1 .form-row {
    &.login-rememberme.label-inline {
        margin: 15px auto 0;
        span {
            &.label-text {
                color: $black;
                font-size: $base-font;
            }
        }
    }
}

.field-wrapper,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper,
.pt_account .address-list .address-form-block .make-label-absolute .form-row .field-wrapper,
.pt_account .make-label-absolute .form-row .field-wrapper,
.footer-email-signup .email-signup.make-label-absolute .form-row .field-wrapper {
    input[type="date"] textarea,
    input[type="number"],
    input[type="password"],
    input[type="text"],
    input[type="tel"] {
        @include inputs_mixin();
    }
}

.subscribe-action.clearboth .subscribe-emailid {
    display: table;
    .field-wrapper {
        display: table-cell;
        float: none;
        vertical-align: middle;
        .form-row.email-box {
            width: auto;
            height: auto;
            padding: 0;
            float: none;
            .field-wrapper {
                input[type="text"] {
                    @include inputs_mixin();
                    padding: 18px 0 18px 17px;

                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    .form-row.form-row-button {
        float: none;
        display: table-cell;
        vertical-align: middle;
        button {
            float: none;
        }
    }
    @media screen and (max-width: 767px) {
        .field-wrapper {
            display: table;
            margin: 0 auto 20px;
        }
        .form-row.form-row-button {
            float: none;
            display: table;
            margin: 0 auto 10px;
            button {
                float: none;
            }
        }
    }
}

.field-wrapper .custom-select .selected-option,
.pt_account
    .catalog-request
    .address-form-block
    .make-label-absolute
    .form-row
    .field-wrapper
    .custom-select
    .selected-option,
.pt_account
    .address-list
    .address-form-block
    .make-label-absolute
    .form-row
    .field-wrapper
    .custom-select
    .selected-option,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select .selected-option,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select .selected-option,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select select,
.form-row .field-wrapper .custom-select select,
.field-wrapper .custom-select .selected-option,
.pt_article-page .make-label-absolute .field-wrapper .custom-select .selected-option,
.pt_article-page .make-label-absolute .field-wrapper .custom-select select {
    @include selected-option_mixin();
}

.pt_account .make-label-absolute label,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row label,
.pt_account .address-list .address-form-block .make-label-absolute .form-row label,
.pt_account .make-label-absolute .form-row label,
.pt_account .address-conatiner .make-label-absolute .form-row.error-handle.postal.required label,
.pt_checkout .make-label-absolute .form-row label,
.footer-email-signup .email-signup.make-label-absolute .form-row label,
.pt_account .make-label-absolute .form-row.error-handle label,
.pt_account .email-signup.make-label-absolute .form-row label,
.pt_article-page .make-label-absolute .form-row label {
    @include label_mixin($translateY: 17px);
    &.input-focus {
        @include input-focus_mixin(0, 3px);
    }
}

.pt_account .make-label-absolute .payment-fields label {
    padding: 0 0 0 10px;
}

.catalog-quick-order .make-label-absolute .form-row label {
    @include label_mixin($translateY: 17px);
    &.input-focus {
        @include input-focus_mixin(0, 7px);
    }
}

.form-row label {
    &.input-focus {
        @include input-focus_mixin(0, 3px);
    }
}

.footer-container .footer-email-signup .email-signup.make-label-absolute {
    display: table;
    float: none;
    margin: 20px auto;
    form {
        display: table;
        margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
        form {
            width: 100%;
        }
    }
}

.footer-container .footer-email-signup .email-signup .form-row {
    float: none !important;
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
    padding: 0;
    &.form-row-button:after {
        content: "";
        display: none;
    }
}

.form-row-button button:not(.btn-ds),
.create-login .create-inner-block .login-create .create-account,
.create-login .login-oauth button {
    @include button_mixin();
}

.create-login .login-oauth button {
    @include button_mixin();
    background: #395799;
    padding: 16px 55px;
}

.footer-container .footer-email-signup .email-signup.make-label-absolute .form-row label {
    @include label_mixin($translateY: 17px);
    padding: 4px 0 0 16px;
    &.input-focus {
        @include input-focus_mixin($translateY: 3px);
    }
}

.footer-container .footer-email-signup .email-signup .form-row input[type="text"],
.footer-container .footer-email-signup .email-signup.make-label-absolute .form-row input[id*="emailsignup_email"] {
    @include inputs_mixin();
    padding: 15px 0 16px 15px;
    @media screen and (max-width: 1024px) {
        padding: 15px 0 16px 15px;
    }
}

.footer-email-signup .email-signup.make-label-absolute .form-row.form-row-button button {
    padding-top: 14.5px;
    padding-bottom: 14.5px;
    height: auto;
}

/* forms overrides TBR end */

// label animation on input focus change
.make-label-absolute {
    .form-row {
        label {
            span {
                color: $dim-gray;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                &.error {
                    color: $sangria;
                }
            }
            &.input-focus {
                span {
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                }
            }
        }
    }
}

.field-wrapper .custom-select,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .address-list .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.form-row .field-wrapper .custom-select,
.field-wrapper .custom-select {
    &.current_item {
        .selected-option,
        select {
            border-bottom: 1px solid;
        }
    }
}

.custom-select .selection-list {
    border-top: none;
}

#QuickViewDialog,
.prod-detail-page {
    .custom-select .selection-list,
    .custom-select.current_item .selection-list {
        border-color: #000;
    }
}

.form-row.selectBox.required.show-text label,
.pt_account .make-label-absolute .form-row.show-text label {
    @include input-focus_mixin(0, 3px);
    @include formjsCorrector();
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
textarea {
    border: 1px solid $dark-gray;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 0.7em 0.5em;
    text-indent: 0;
    width: 100%;

    &:focus {
        outline: 2px solid var(--color-btn-focus);
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.form-row {
    &.newpasswordconfirm,
    &.newpassword,
    &.currentpassword,
    &.password,
    &.passwordconfirm {
        input {
            &::-ms-reveal {
                display: none;
            }
        }
    }
}

.input-text {
    &.reward-code,
    &.coupon-code {
        text-transform: uppercase;
    }
}

input[type="text"]::placeholder {
    color: $dim-gray;
}

.plcc-left-section-2 ul li{
    list-style-type: disc;
}