.legacy-sitegen.ui-dialog {
	&.bonus-product-quickview {
		&.ui-draggable {
			.ui-dialog-content {
				padding: 0 20px 40px 40px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				overflow: hidden;

				@media screen and (max-width: 767px) {
					padding: 0 10px 40px;
				}
			}
			.product-variations .color li a img,
			.product-options .color li a img {
				width: 88%;
			}
			.promotion {
				padding: 0 0 30px 0;
				font-family: $Helvetica;

				@media screen and (max-width: 767px) {
					font-size: $base-font - 2px;
				}

				b {
					font-weight: normal;
					font-size: $base-font;
					font-family: $HelveticaBold;
					letter-spacing: 1px;

					@media screen and (max-width: 767px) {
						font-size: $base-font - 2px;
					}
				}

				.viewless {
					font-family: $HelveticaBold;
					font-size: $base-font - 1px;
					text-transform: uppercase;
					cursor: pointer;
					letter-spacing: 1px;
				}

				a {
					font-size: $base-font - 1px;
				}
			}

			.promodetails {
				cursor: pointer;
				display: inline;
				&:hover {
					+ .promo-content {
						display: block;
						padding-top: 10px;
					}
				}
			}

			.open {
				display: none;
				cursor: pointer;
			}

			.bonus-product-item {
				width: 97%;

				.product-name {
					display: none;
				}
			}

			.bonus-product-list {
				input[type="checkbox"] {
					width: 15px;
					height: 15px;
					outline: 1px solid $dim-gray;
					border: none;
				}

				.product-col-2 {
					.product-name {
						display: block;
						margin: 0 0 13px 0;
					}

					.product-desc {
						margin: 0 0 18px 0;
						width: 100%;
						display: inline-block;
						word-break: break-word;
						line-height: 1.5;
					}

					.morelink {
						font-size: $base-font - 1px;
						font-family: $HelveticaBold;
					}

					.hiddentext {
						display: none;
					}
				}
			}

			.product-name {
				font-size: $base-font + 12px;
			}

			.product-variations {
				.attribute {
					width: 49%;
					float: none;
					display: inline-block;
					vertical-align: top;
					overflow: visible;
					border-bottom: none;
					margin: 0 0 10px 0;
					border-top: none;
					padding-top: 4px;
					&.Size {
						&.Family {
							.attribute_label {
								.selected-value {
									width: 42%;
								}
							}
						}
					}
					.attribute_label {
						width: 90%;
						margin: 10px 0 9px;

						.selected-value {
							text-overflow: ellipsis;
							overflow: hidden;
							height: 14px;
							white-space: nowrap;
							width: 48%; 
						}
					}

					.errormsg {
						color: $radical-red;
						&.selecting {
							color: $radical-red;
						}
					}

					&.color {
						.attribute_label {
							margin: 10px 0 9px;
						}
					}

					.value {
						width: 90%;
						position: relative;
						height: 38px;
						border: 1px solid $dim-gray;

						.selecting {
							padding: 6px 12px;
							font-family: $HelveticaMedium, $sans-serif;
							letter-spacing: 2px;
							background: url(../../images/arrow-down.png) no-repeat;
							background-position: 93% 50%;
							text-transform: capitalize;
							cursor: pointer;

							&.active {
								background: url("../../images/up-arrow.png") no-repeat;
								background-position: 93% 50%;
								background-color: $black;
								color: $white;
							}

							.swatch-image {
								width: 43px;
								height: 21px;
								line-height: 22px;
								min-width: 43px;
								overflow: hidden;
								float: left;
							}

							span {
								width: 59%;
								overflow: hidden;
								text-overflow: ellipsis;
								vertical-align: middle;
								display: inline-block;
								white-space: nowrap;
							}
						}

						.swatches {
							border: 1px solid $black;
							border-top: none;
							width: 99%;
							display: none;
							background-color: $white;
							max-height: 170px;
							overflow: hidden;
							overflow-y: auto;

							&.active {
								display: block;
								position: absolute;
								z-index: 11;
								width: 100%;
								left: -1px;
							}

							li {
								width: 100%;

								&:hover {
									background-color: $black;
									color: $white;
								}

								&:last-child {
									border-bottom: none;
								}

								a {
									border: none;
									margin: 5px 0 5px 0;
									width: 100%;

									.swatch-image {
										width: 43px;
										float: left;
										padding: 0 5px 0 0;
									}
									span {
										line-height: 26px;
										text-align: left;
										float: left;
										text-overflow: ellipsis;
										width: 100%;
										overflow: hidden;
										box-sizing: border-box;
										padding: 0 8px;
									}
								}
							}

							&.size {
								li {
									a {
										padding: 5px 0 5px 13px;
										margin: 0;
										width: 100%;
										text-align: left;
									}
								}
							}
						}
					}
					.size-chart-link {
						width: 90%;
						float: left;
						margin: 10px 0 0 0;
						text-transform: uppercase;
						font-size: $base-font - 1px;
						font-family: $HelveticaBold;
						text-align: right;

						.size_icon {
							width: 23px;
							height: 15px;
							display: inline-block;
						}

						.size_label {
							vertical-align: top;
						}
					}
				}
			}

			.bonus-product-list-footer {
				padding: 20px 0 0 40px;
				box-sizing: border-box;

				.add-to-cart-bonus {
					margin: 0;
				}

				.quantity-error {
					width: 100%;
					display: inline-block;
					text-align: right;
					margin: 10px 0 0 0;
				}
			}

			.ui-widget-header {
				height: auto;

				.ui-dialog-titlebar-close {
					z-index: 1;
					background: transparent;
    				border: 0;
				}
			}
		}
		.price-standard {
			border-right: none !important;
			text-decoration: line-through red;
			color: black;
		}
		.price {
			.value {
				color: red;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.bonusheading {
		width: 70%;
	}
	.legacy-sitegen.ui-dialog {
		&.bonus-product-quickview {
			&.ui-draggable {
				.bonus-product-list {
					input[type="checkbox"] {
						margin: 0 9px 0 2px;
					}
					.bonus-product-item {
						.product-col-1 {
							width: 40%;
						}
					}

					.product-name {
						font-size: $base-font + 20px;
						line-height: normal;
						display: block;
					}

					.product-col-2 {
						width: 60%;

						.product-name {
							display: none;
						}

						.product-variations-toggle {
							display: none;
						}
					}
				}
				.product-variations {
					.attribute {
						padding: 0 0 10px 0;
						width: 100%;

						.attribute_label {
							display: none;
						}

						.value {
							width: 100%;
							border: none;
							.selecting {
								display: block;
								border: 1px solid $black;

								&.attr-size,
								&.attr-braBandSize,
								&.attr-braCupSize {
									width: 100%;
									box-sizing: border-box;
									height: auto;
									max-height: 40px;
								}
							}

							.swatches {
								width: 99%;
								border: 1px solid $black;
								border-top: none;
								display: none;

								&.active {
									display: block;
									left: 0;
								}

								&.size,
								&.bracupsize,
								&.brabandsize,
								&.color {
									width: 99%;

									&.active {
										width: 98%;
									}

									li {
										border: none;
										width: 100%;

										a {
											padding: 5px 0 5px 13px;
											margin: 0;
											width: 100%;
											text-align: left;
											border: none;
										}
									}
								}
							}
						}

						.size-chart-link {
							float: left;
							width: auto;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.legacy-sitegen.ui-dialog {
		&.bonus-product-quickview {
			&.ui-draggable {
				.bonus-product-list {
					.product-name {
						font-size: $base-font + 4px;
					}

					.product-col-2 {
						padding: 0 0 0 10px;
						width: 60%;
					}
				}
				.product-variations {
					.attribute {
						.value {
							.selecting {
								span {
									width: 47%;
								}
							}
							.swatches {
								width: 98%;
								&.active {
									width: 98%;
								}

								&.size {
									&.active {
										width: 98%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.bonusheading {
	font-size: $base-font + 2px;
	font-family: $HelveticaBold;
	padding: 20px 0 10px 0;

	@media screen and (max-width: 767px) {
		width: 77%;
		font-size: $base-font - 2px;
	}
}

.promotion {
	font-size: $base-font;
	font-family: $Helvetica;

	a {
		font-family: $HelveticaBold;
		text-transform: uppercase;
	}
}

.bonus-product-items {
	max-height: 520px;
	overflow-y: auto;
	width: 100%;

	&::-webkit-scrollbar-track {
		background: $mercury;
	}

	&::-webkit-scrollbar {
		width: 15px;
		padding-top: 40px;
		background: $mercury;
	}

	&::-webkit-scrollbar-thumb {
		background: $gray;
	}
}

.bonus-product-list {
	.bonus-product-item {
		border-bottom: 1px solid $gainsboro;
		display: inline-block;
		width: 100%;
		padding: 30px 0 20px;

		&:last-of-type {
			border-bottom: none;
		}
		&.lastitem {
			border-bottom: none;
		}

		input[type="checkbox"] {
			margin: 0 20px 0 2px;
		}

		.product-col-1 {
			width: 25%;
			max-width: 210px;
		}

		.product-col-2 {
			width: 75%;
			max-width: 430px;
			padding: 0 0 0 35px;

			.product-add-to-cart {
				display: none;
			}
		}

		.price {
			font-size: $base-font;
			font-family: $HelveticaMedium, $sans-serif;

			span {
				&.label {
					font-family: $HelveticaBold;
					padding-right: 5px;
					text-transform: uppercase;
					letter-spacing: 1px;
				}
			}
		}
	}

	.product-add-to-cart {
		padding-bottom: 0;
		.inventory {
			width: auto;
		}
	}

	.product-name {
		float: none;
		margin: 0 0 15px 0;
	}

	.quantity-error {
		color: red;
		text-transform: inherit;
	}

	.bonus-product-list-footer {
		padding-top: 20px;
		padding-bottom: 0;
		border-top: 1px solid $gainsboro;

		.add-to-cart-bonus {
			float: right;
			padding: 16px 55px;
			border: none;
			color: $white;
			background: $wine-berry;
			letter-spacing: 1px;
			margin-right: 40px;
		}
	}
}

.pt_cart {
	#cart-table {
		&.item-list {
			.cart-promo {
				font-style: normal;
				font-size: $base-font - 1px;
				font-family: $HelveticaMedium, $sans-serif;
				line-height: 22px;
				letter-spacing: 2px;

				.bonus-item-details {
					text-align: left;
					float: left;
					padding: 0 0 0 25px;
					text-transform: none;

					.tooltip {
						color: $white;

						.tooltip-content {
							display: none;
						}
					}
				}

				.bonus-item-actions {
					color: $white;
					padding: 0 25px 0 0;

					.select-bonus {
						color: $white;
						text-transform: none;
						font-weight: bold;
					}
				}
			}
		}
	}
}

.legacy-sitegen.ui-dialog.bonus-product-quickview.ui-draggable .product-variations .attribute .value .swatches li.selected a.swatchanchor span {
	color: $white;
}