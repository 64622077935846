$leftArrow_black: url("../../images/Left_ArrowBlack.svg") no-repeat center right / contain;
$leftArrow_gray: url("../../images/Left_ArrowGray.svg") no-repeat center right / contain;
$rightArrow_black: url("../../images/Right_ArrowBlack.svg") no-repeat center left / contain;
$rightArrow_gray: url("../../images/Right_ArrowGray.svg") no-repeat center left / contain;

@mixin show-expanded-offer {
	.plcc-cart-banner-img {
		flex-basis: 25%;
	}

	.plcc-cart-banner-content {
		margin-bottom: 10px;
	}

	.plcc-cart-banner-info {
		display: block;
		margin: auto 10px;

		.plcc-cart-banner-extended-text {
			display: initial;
		}

		.plcc-cart-banner-learn-more:hover {
			text-decoration: underline;
		}
	}

	.plcc-cart-banner-username {
		display: block;
		font-size: $base-font + 8;
		margin-bottom: 10px;
	}

	.expand-button {
		display: none;
	}
	.minimize-button {
		display: block;
	}
}

audio,
canvas,
progress,
video {
	width: 100%;
	height: 100%;
}
.dummy-close-icon {
	display: none;
	@media screen and (min-width: 767px) and (max-width: 1023px) {
		background-image: url(../../../images/dialog-close-icon.png);
		height: 23px;
		width: 23px;
		right: 38px;
		top: 36px;
		display: inline-block;
		z-index: 99999;
		position: absolute;
		cursor: pointer;
	}
}
.cart-row {
	&.gift-Card {
		.col-1 {
			.giftmessage {
				word-break: break-all;
			}
		}
	}
}
.dummy-slick-arrow {
	background: url(../../../images/enlarge-slider-lt-rt-icon.png) no-repeat;
	width: 15px;
	height: 25px;
	padding: 0;
	border: none;
	text-indent: -9999px;
	position: absolute;
	-webkit-transition: initial;
	transition: initial;
	-ms-transition: initial;
	z-index: 9999;
	cursor: pointer;
	top: 0;
	bottom: 0;
	margin: auto;
	&.dummy-slick-prev {
		left: 7px;
		background-position: 0 -68px;
		@media screen and (min-width: 1024px) {
			top: 338px;
			margin: 0;
			display: none;
		}
	}
	&.dummy-slick-next {
		background-position: 0 0;
		right: 7px;
		@media screen and (min-width: 1024px) {
			top: 338px;
			margin: 0;
			display: none;
		}
	}
}

.position-fixed {
	position: fixed !important;
}
.cart-recommendations {
	margin-top: 1rem;
	.product-listing {
		background-color: $white-smoke;
		padding: 1rem 0;
	}
	.name-link {
		&:focus {
			outline: none;
			box-shadow: none;
		}
	}
}

.international-cart-message {
	background: $light-black-two;
	padding: 5px 10px;
	margin-bottom: 9px;
	color: $white;
	text-align: center;
	line-height: 20px;

	span {
		width: 100%;
		display: inherit;
	}
}

.pt_cart {
	.last-visited-section {
		.search-result-items {
			.grid-tile {
				@media screen and (min-width: 1024px) and (max-width: 1350px) {
					padding: 0 10px 10px;
				}

				.product-tile {
					@media screen and (min-width: 1024px) {
						width: 204px;
					}
				}
			}
		}
	}
}

.last-visited-section {
	display: inline-block;
	width: 100%;
	margin-top: 20px;
	.last-visited-heading {
		height: 24px;
		font-family: $WorkSans;
		font-size: 20px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $recommendation-heading;
		padding-left: 10px;
		margin-bottom: 20px;
		text-transform: uppercase;

		h2 {
			@include inherit_parent_props();
			color: $recommendation-heading;
		}
	}
	.slick-slide img.p-image-rollover {
		display: none;
	}
	button {
		border: none;
		border-radius: 0;
		color: transparent;
		display: inline-block;
		padding: 0;
		margin: 0 auto;
		position: absolute;
		width: 23px;
		height: 23px;
		transform: translateY(-50%);
		top: 35%;
		&.slick-arrow {
			margin: 0 auto;
			padding: 12px;
			&.slick-next {
				left: 100%;
				background: $rightArrow_black;
				&.slick-disabled {
					background: $rightArrow_gray;
				}
			}
			&.slick-prev {
				right: 100%;
				background: $leftArrow_black;
				&.slick-disabled {
					background: $leftArrow_gray;
				}
			}
		}
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			&.slick-arrow {
				&.slick-next {
				}
				&.slick-prev {
				}
			}
		}
	}
	.search-result-items {
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			max-width: 93%;
			margin: 0 auto;
		}
		.grid-tile {
			width: 200px;
			margin: 0;
			padding: 0 10px 10px;
			border: none;

			&:hover .quickview {
				display: block;
			}
			.product-name-image-container {
				max-width: inherit;
				margin: 0 auto;
				.product-image {
					max-width: 220px;
					margin: 0 auto;
				}
			}

			@media screen and (max-width: 768px) {
				width: auto;
				padding: 0 10px 10px 0;
				min-height: 275px;
				max-width: inherit;
				margin: 0 auto;
			}
			&:hover {
				box-shadow: none;
				border: none;
			}
			.product-tile {
				width: 100%;
				border: none;
				padding: 0;
				.quickview {
				}
				.product-image {
					img {
						width: auto;
						height: auto;
						max-width: 100%;
					}
				}
				&:hover {
					box-shadow: none;
					border: none;
				}
			}
		}
	}
}
.pt_cart {
	.cart-left-content {
		float: left;
		margin-top: 20px;
		width: 100%;
	}
	.cart-top-banner {
		.cart-action-checkout {
			.button-fancy-large {
				@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
					width: 28.5%;
				}
			}
		}
	}

	.combined-cart-footer,
	.last-visited-section,
	.question-and-answers {
		max-width: 895px;
		float: left;
		margin-top: 20px;
		width: 74.1%;

		@media screen and (max-width: 1023px) {
			width: 100%;
			max-width: 100%;
		}
	}

	.last-visited-section {
		max-width: 672px;
	}

	.cart-right-content {
		@media screen and (max-width: 1023px) {
			margin-top: 0;
		}

		h2.trending {
			color: $black;
			font-style: normal;
			font-weight: normal;
			text-align: center;
			text-transform: none;
			padding: 20px 0;
		}

		.product-tile {
			padding: 0 0;
			margin: 10px auto 0;
			color: $black;
			border: 0;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			.product-pricing {
				display: block;
				margin: 0 auto;
			}
			.product-standard-price,
			.product-sales-price {
				display: inline-block;
			}
			.product-image {
				max-width: 100px;
				margin: auto;

				img {
					width: auto;
					height: auto;
				}
			}
			.product-name {
				margin: 2px 0 0;
				width: 100%;
				line-height: normal;
			}

			.product-brand {
				padding: 0 0 6px 0;
			}

			.product-price {
				margin: 1px 0 5px;

				.price-standard {
					padding-right: 6px;
				}
			}

			// hiding non required for cart recommendations
			.product-promo,
			.product-review,
			.product-swatches {
				display: none;
			}
		}

		//less than 1024pc
		.slick-slide img.p-image-rollover {
			display: none;
		}

		@media screen and (max-width: 1023px) {
			.you-may-like {
				background: none;
				border: 0;
			}
			h2.trending {
				padding-bottom: 20px;
				margin-bottom: 20px;
				text-transform: capitalize;
			}
			.product-tile {
				.product-name {
					font-size: $base-font + 8px;
					line-height: 20px;
					padding: 10px 0;
				}
			}
		}
	}

	.cart-actions {
		.cart-content {
			@media screen and (max-width: 1023px) {
				padding: 0 20px;
			}
		}
	}
}

.cart-top-banner {
	text-align: center;
	padding: 20px 0 10px;
	background: $white;
	border-top: 1px solid $alto;
	border-bottom: 1px solid $alto;

	h1 {
		font-size: $base-font + 30px;
		letter-spacing: 3px;

		text-transform: uppercase;
	}

	.pid {
		text-transform: uppercase;
		color: $pid-font-color;
		margin: 0;

		a {
			color: $pid-font-color;
		}
	}
	.paypal-button {
		margin-top: 20px;

		.or-link {
			font-size: $base-font + 2px;
			font-family: $WorkSans;
			font-weight: 500;
			text-transform: uppercase;
			line-height: 60px;
			vertical-align: top;
			display: inline-block;
			margin-top: -8px;

			@media screen and (max-width: 767px) {
				padding-left: 19px;
			}
		}
		.checkout-paypal-btn {
			float: none;
			margin-left: 36px;
			display: inline-block;
			max-width: 228px;
			margin-top: 0;
			margin-bottom: 0;
			line-height: 35px;
			@media screen and (max-width: 767px) {
				margin-left: 17px;
			}
			@media screen and (min-width: 768px) and (max-width: 1024px) {
				margin-left: 18px;
			}
		}
	}
	.shopping-bag-id {
		text-align: right;
		display: inline-block;
		width: 100%;
		padding: 15px 0 0;
		box-sizing: border-box;
		margin-top: -20px;

		@media screen and (max-width: 767px) {
			text-align: center;
			padding: 0px;
			margin-top: 10px;
		}
	}
}

.pt_cart {
	.error-form {
		margin: 0 0;
		background: #eba346;
		border: 0;
		border-radius: 0;
		color: $white;
		padding: 10px 5px;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.5px;

		i {
			display: none;
		}
	}
}

.cart-columns {
	width: 100%;
	display: inline-block;
	padding: 20px;
	box-sizing: border-box;

	&.thead {
		padding: 0 0;
	}

	.column {
		width: 33.33%;
		float: left;
		box-sizing: border-box;
		margin: 0 0;

		&.col-1 {
			position: relative;
			display: flex;
			max-width: 420px;
			width: 47%;
		}

		&.col-2 {
			max-width: 160px;
			width: 18.3%;

			&.section-header {
				padding-left: 20px;
			}
			.availability-web {
				padding: 10px 0;
			}
		}

		&.col-3 {
			max-width: 100px;
			width: 18.8%;

			&.section-header {
				padding-left: 34px;
			}
		}

		&.col-4 {
			float: right;
			width: auto;
			max-width: 110px;
		}

		&.section-header {
			padding: 9px 0;
		}
	}
}

.cart-row {
	width: 100%;
	display: inline-block;
	border-top: 1px solid $alto;

	.col-1 {
		display: inline-block;
		.item-image {
			width: 100px;
			min-width: 100px;
			text-align: center;
			float: none;
			display: inline-block;
			a {
				display: block;
			}
			.cart-brand-logo {
				float: none;
				display: inline-block;
				margin: 5px 0;
				height: 30px;
				width: 100%;
			}
		}
		.item-details {
			max-width: 66%;
			padding-left: 35px;
			box-sizing: border-box;
			display: inline-block;
			float: none;
			.value {
				text-transform: uppercase;
			}
			.sku {
				.value,
				span,
				div {
					color: $dim-gray;
				}
			}
			.product-brand {
				@include WorkSansRegular($WorkSans);
				text-transform: uppercase;
				color: $brand-name-color;
				font-size: $base-font - 2px;
				letter-spacing: 0.2px;
				margin-bottom: 6px;
			}

			.name {
				text-transform: capitalize;
				a:focus {
					display: block;
				}
			}
		}
	}

	.col-2 {
		.item-quantity {
		}

		.not-available,
		.availability-msg {
			color: $mangotango;
		}

		.custom-cart-update {
			border: 1px solid $dim-gray;
			width: 58px;
			height: 38px;
			position: relative;
			margin: 59px 0 0 10px;
			text-align: center;

			&.subscription-fee{
                line-height: 3em;
                border: none;
                @media only screen and (min-width: 768px) and (max-width: 1023px){
                    display: block !important;
                }
            }

			.qty-arrows {
				position: absolute;
				width: 16px;
				height: 10px;
				right: 6px;
				cursor: pointer;

				&.disabled {
					pointer-events: none;
					cursor: auto;
				}

				&.qty-increase {
					top: 7px;
					background: url("../../images/Down-Arrow-Black-cart.svg") center no-repeat;
					-ms-transform: rotate(180deg);
					-webkit-transform: rotate(180deg);
					transform: rotate(180deg);

					&.disabled {
						background: url("../../images/Down-Arrow-Grey.svg") center no-repeat;
						-ms-transform: rotate(180deg);
						-webkit-transform: rotate(180deg);
						transform: rotate(180deg);
					}
				}

				&.qty-decrease {
					top: 21px;
					background: url("../../images/Down-Arrow-Black-cart.svg") center no-repeat;
					-ms-transform: none;
					-webkit-transform: none;
					transform: none;
					right: 5px;

					&.disabled {
						background: url("../../images/Down-Arrow-Grey.svg") center no-repeat;
						-ms-transform: none;
						-webkit-transform: none;
						transform: none;
						right: 5px;
					}
				}
			}

			input {
				border: 0;
				font-size: $base-font + 2px;
				width: 20px;
				height: 20px;
				padding: 0 0;
				margin: 14% auto 0 -5px;

				&:disabled {
					background: transparent;
					color: $black;
					opacity: 1;
				}
			}
		}
	}

	.col-3 {
		max-width: 110px;
		.item-total {
			text-align: center;
			margin-top: 50px;
		}
	}

	.col-4 {
		.item-quantity-details {
			text-align: right;

			.btm-space,
			.remove-btn {
				letter-spacing: 0.5px;
			}
		}
	}
}
.outofstock {
	background-color: $wild-sand;
	.btm-space {
		display: none;
	}
	.item-quantity {
		&:before {
			content: "OUT OF STOCK";
			display: table;
			margin: 70px 0 0;
			color: $guardsman-Red;
			font-weight: bold;

			@media screen and (max-width: 767px) {
				max-width: inherit;
				width: auto;
				margin: 0;
				letter-spacing: 2px;
			}
		}
		.custom-cart-update {
			display: none;
		}
	}
	.item-total {
		display: none;
	}
	.product-availability-list {
		display: none;
	}
	@media screen and (max-width: 767px) {
		.column.col-1 {
			.item-details {
				.product-availability-list {
					display: none;
				}
			}
		}
	}
}
.pt_checkout .cart-row.outofstock,
.pt_checkout .outofstock {
	background-color: $white;
	.btm-space {
		display: inherit;
	}
	.item-quantity {
		&:before {
			content: " ";
			display: none;
		}
		.custom-cart-update {
			display: inherit;
		}
	}
	.item-total {
		display: inherit;
	}
	.product-availability-list {
		display: inherit;
	}
	.col-4 .item-quantity-details {
		margin-top: inherit;
	}

	@media screen and (max-width: 767px) {
		.item-quantity:before {
			display: none;
		}
	}
}

.cart-footer {
	margin: 0;
	width: 100%;
	float: left;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	background: $white;
	.cart-footer-left {
		width: 60%;
		float: left;
		max-width: 303px;
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
		}
	}
	.payment-description {
		.heading {
			margin-bottom: 15px;
		}

		.payment-cards {
			border-bottom: 1px solid $alto;
			padding: 0 0 15px 0;

			li {
				padding-right: 15px;
				display: inline-block;

				&:last-child {
					padding-right: 0;
				}
			}
		}

		.brand-cards {
			width: 100%;
			display: inline-block;
			max-width: 280px;
			margin: 15px 0 10px;

			img {
				vertical-align: middle;
			}
			.banner {
				width: auto;
				vertical-align: middle;
				padding: 0 5px;

				&.right-section {
					line-height: normal;
					padding: 0;
					float: none;
				}
				&.left-section {
					line-height: normal;
					padding: 0;
				}
			}
			span {
				display: inline;
			}
		}
		.see-details {
			display: inline-block;
			letter-spacing: 0.6px;
			text-transform: uppercase;
			margin-top: 10px;
			vertical-align: bottom;
		}
	}
	.shoppers-club {
		padding: 20px 0;
		.cart-shoppers-club-title {
			margin-bottom: 15px;
		}
		.cart-shoppers-club-callout {
			width: 100%;
			display: inline-block;
			max-width: 280px;
			margin: 0 0 10px;
		}
		.tooltip {
			display: inline-block;
			letter-spacing: 0.6px;
			text-transform: uppercase;
			margin-top: 10px;
			vertical-align: bottom;
		}
	}
	.cart-order-totals {
		float: right;
		width: 40%;
		max-width: 290px;

		.order-detail {
			width: 100%;
			display: inline-block;

			.label {
				width: 60%;
				float: left;
				text-transform: capitalize;
			}

			.value {
				width: 40%;
				float: right;
				text-align: right;
			}

			&.order-total {
				border-top: 1px solid $alto;

				&.order-total-remaining {
					border-top: 0;
				}
			}

			&.remaining-total {
				border-top: 1px solid $alto;
			}

			&.discount {
				span,
				.label,
				.value {
					color: $guardsman-Red;
				}
			}

			&.order-shipping {
				.label {
					.shippingoverlay-link {
						width: 16px;
						padding: 0;
						margin: 0;
						display: inline-block;
						text-indent: -9999px;
						cursor: pointer;
						border: 0;
						overflow: hidden;
					}
				}
			}
		}
	}

	.cart-actions {
		.cart-action-checkout {
			float: right;
			width: 40%;
			max-width: 290px;

			.button-fancy-large {
				width: 100%;
				border: none;
				color: $white;
			}

			.paypal-button {
				display: inline-block;
				width: 100%;

				.or-link {
					width: auto;
					float: left;
					text-transform: uppercase;
				}
			}
		}

		.cart-action-continue-shopping {
			width: 60%;
			float: left;
			max-width: 303px;
		}
	}
	.tax-disclaimer {
		font-size: 11px;
		color: $dim-gray;
		line-height: 150%;
		clear: both;
		width: 40%;
		max-width: 290px;
		float: right;
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
		}
	}

	.int-disclaimer {
		font-size: 11px;
		color: $reddish-monza;
		line-height: 150%;
		clear: both;
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
		}
	}
}

.intl-shipping-btn {
	float: right;
	max-width: 300px;
	padding: 0 27px 6px;
	text-decoration: underline;
	cursor: pointer;
	font-size: 12px;
	letter-spacing: normal;
	font-family: "Work Sans", sans-serif;
	background: none;
	color: $black;
	border: 0;
	text-transform: capitalize;
	font-weight: 400;

	&:hover {
		background: transparent;
	}
}
.intl-shipping-btn::after {
	content: "";
	display: table;
	clear: both;
}

.intl-shipping-icon {
	background: url(../../images/International_Shipping-Icon.svg) no-repeat;
	height: 27px;
	width: 27px;
	display: inline-block;
	vertical-align: middle;
}
.cart-empty {
	.cat-banner {
		text-align: center;

		h1 {
			text-transform: uppercase;
		}

		.cartempty-message {
			text-transform: inherit;
		}
	}

	.cart-action-continue-shopping {
		float: none;
		display: inline-block;

		button {
			border: none;
			color: $white;
		}
	}

	.product-listing {
		border-top: 1px solid $alto;
		min-height: 600px;
        @media screen and (max-width: 767px) {
            min-height: 510px;
        }
		.search-result-items {
			.grid-tile {
				width: 20%;
				margin: 0;
			}
		}

		h2 {
			margin: 0 0 40px 0;
			text-align: center;
			text-transform: capitalize;
			font-weight: 400;
		}
	}
}

//PLCC
.cart-plcc-section {
	button {
		overflow: hidden;
	}
}

.bonus-product-calloutMsg {
	padding: 10px 0 8px 25px;
	letter-spacing: 1px;
	border-bottom: 1px solid $alto;
	position: relative;

	&:before {
		content: "";
		border-style: solid;
		border-width: 8px 0 8px 10px;
		top: -12px;
		position: absolute;
		transform: rotate(270deg);
		-ms-transform: rotate(270deg);
		-webkit-transform: rotate(270deg);
		left: 65px;

		@media screen and (max-width: 767px) {
			left: 20px;
		}
	}
}

.promo-adjustment {
	text-transform: uppercase;
	letter-spacing: 1px;
	margin: 10px 0 0 0;
}

.specialmessaging {
	color: $mangotango;
	letter-spacing: 1px;
	margin: 7px 0 0;
	p {
		margin: 0 0 7px;
		width: 100%;
		&.finalsalemessage {
			color: #af3434;
			text-transform: uppercase;
			letter-spacing: 0.6px;
			font-size: 12px;
			line-height: normal;
			font-weight: bold;
		}
		@media screen and (max-width: 767px) {
			margin: 0;
			&.finalsalemessage {
				margin-bottom: 7px;
			}
		}

		&.exclusionmessage,
		&.nationalmessage,
		&.clearancemessage {
			color: #c55300;
		}
	}

	.text-uppercase {
		text-transform: uppercase;
	}

	.text-initial {
		text-transform: none;
	}
}

.cart-row {
	.specialmessag {
		&:nth-child(2n) {
			&.specialmessagpromo {
				@media screen and (max-width: 767px) {
					display: inline-block;
				}
			}
		}

		&.monogrammed {
			@media screen and (max-width: 767px) {
				display: none;
			}

			@media screen and (min-width: 768px) {
				display: block;

				.optioninfo {
					br {
						display: none;
					}

					span {
						letter-spacing: 1px;

						&.monogram,
						&.hemming {
							cursor: pointer;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

.cart-row .col-1 .item-details .optioninfo {
	span {
		&.monogram,
		&.hemming {
			cursor: pointer;
			display: inline-block;
			margin: 10px 0;
			cursor: pointer;
			padding: 10px;
			border: 1px solid $black;
			display: inline-block;
			border-radius: 3px;
			@media screen and (max-width: 767px) {
				margin: 10px 0 0;
			}
			&:before {
				content: "";
				background: url("../../images/plus-symbol.png") no-repeat;
				width: 11px;
				height: 11px;
				display: inline-block;
				padding-right: 10px;
			}
		}
	}
}

.qty-error-msg {
	padding: 10px 0;
	text-align: center;
	letter-spacing: 1px;
	color: $white;
	background: $mangotango;
	text-transform: uppercase;
}

.quick-order-badge-confirmation {
	.heading {
		padding-top: 5px;
		border-bottom: none !important;
		font-size: inherit !important;
	}
}

.quick-order-badge {
	width: auto;
	text-align: right;
	@media only screen and (min-width: 768px) {
		float: none;
		right: 0px;
		position:absolute;
	}
	
	.quick-order-badge-link {
		text-transform: uppercase;
		width: auto;
		display: inline-block;
		position: relative;
		font-size: 9px;
		text-decoration: underline;
		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			right: 0;
		}
		&::after {
			width: 22px;
			height: 17px;
			content: "";
			background-size: 14px;
			position: absolute;
			right: -5px;
			top: -30%;
		}
		.heading {
			margin-right: 20px;
			letter-spacing: 0.05em;
			width: auto;
			display: block;
		}

		@media screen and (min-width: 768px) {
			&:after {
				content: "";
				width: 14px;
				height: 19px;
				padding: 0;
				margin: 0;
				display: inline-block;
				position: absolute;
				right: 0;
				top: -30%;
			}
		}
	}
}

.ui-dialog {
	&.shipping-overlay {
		.ui-widget-header {
			height: auto;

			.ui-dialog-title {
				display: block;
				padding: 25px 0 15px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				border-bottom: 1px solid $alto;
				margin-left: 20px;
				width: 82%;
			}
		}

		#dialog-container {
			padding: 15px 45px 0 20px;

			.order-detail {
				padding: 0 0 15px 0;
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;

				.label {
					width: 80%;
					float: left;
				}

				.value {
					width: 20%;
					float: right;
					text-align: right;
				}

				&.shippingtotal {
					border-top: 1px solid $alto;
					padding: 20px 0 15px 0;
				}

				&.discount {
					color: $guardsman-Red;
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_cart {
		.cart-left-content {
			width: 74.1%;
		}
	}

	.cart-columns {
		padding: 20px 3px 20px 20px;

		.column {
			&.col-1 {
			}

			&.col-2 {
				padding-left: 12px;

				&.section-header {
					padding-left: 17px;
					text-align: center;
				}
			}

			&.col-3 {
				width: 16.8%;

				&.section-header {
					padding-left: 32px;
				}
			}
		}
	}

	.cart-row {
		.col-2 {
			.custom-cart-update {
				margin: 59px auto 0;
			}

			.availability-msg {
				text-align: center;
			}
		}
	}
}

.qa-section {
	width: 100%;
	display: inline-block;
	border-top: 1px solid $alto;

	.question-title {
		padding: 25px;
		text-transform: capitalize;
	}
	.qa-content {
		background-color: $light-skygrey;
		padding: 25px;
		margin: 0 0 2px 0;

		.question {
			text-transform: uppercase;
			display: inline-block;
			width: 100%;
			&::after {
				content: "";
				background: url(../../images/carat-down.svg) no-repeat;
				background-position: 100% 3px;
				width: 16px;
				height: 17px;
				float: right;
				display: inline-block;
				cursor: pointer;
			}

			&.active {
				&::after {
					content: "";
					background: url(../../images/carat-up.svg) no-repeat;
					background-position: 100% 3px;
					cursor: pointer;
					width: 16px;
					height: 17px;
				}
			}
		}
		.answer {
			padding: 17px 0 0 0;
			display: none;

			&.active {
				display: block;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.pt_cart {
		#main {
			max-width: none;
			#primary {
				max-width: 1260px;
				margin: 0 auto;
				padding: 0 20px;
				box-sizing: content-box;
			}
		}
	}
}
@media screen and (min-width: 1024px) and (max-width: 1260px) {
	.paypal-button {
		.checkout-paypal-btn {
			width: 84%;
		}
	}
	.specialmessag {
		margin-left: 0;
		.promo-adjustment,
		.specialmessaging {
			margin-left: 0;
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_cart {
		.cart-content {
		}
		.cart-left-content {
			width: 100%;
		}
		.cart-right-content {
			width: 100%;
			max-width: 100%;
		}
	}

	.specialmessaging {
		margin: 0;
	}

	.promo-adjustment {
		margin: 10px 0 0 0;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.cart-footer {
		.cart-actions {
			.cart-action-checkout {
				.paypal-button {
					margin: 37px 0 0;
					float: left;
					width: auto;

					+ a {
						margin: 38px 0 0 20px;
						display: inline-block;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.specialmessaging {
		margin: 0;
		display: inline-block;
	}
	.basket-details {
		.cart-columns {
			.columns-combine {
				width: 100%;
				padding-left: 0;
			}
		}
		.cart-row,
		.cart-row.gift-Card {
			.column {
				&.col-1 {
					.item-details {
						width: 58%;
					}
				}
			}
		}
	}
	.cart-columns {
		padding: 20px 0;

		.column {
			&.col-1,
			&.col-2,
			&.col-3 {
				width: 100%;
				max-width: 100%;
				padding: 0;
			}

			&.col-4 {
				width: 40%;
				max-width: 100%;
			}
		}

		.columns-combine {
			width: 60%;
			float: right;
			padding-left: 15px;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
		}
	}

	.cart-row,
	.cart-row.gift-Card {
		.column {
			&.col-1 {
				.item-image {
					width: 40%;
					height: auto;
				}

				.item-details {
					width: 60%;
					max-width: 100%;
					padding-left: 15px;
				}
			}
			&.col-2 {
				margin: 10px 0 0 0;
				.custom-cart-update {
					width: 90px;
					height: 40px;

					@media screen and (max-width: 380px) {
						width: 80px;
					}
				}
				.item-quantity {
					float: left;
				}
				.availability-web {
					display: inline-block;
					float: left;
					width: 40%;
					text-align: left;
					margin-left: 10px;
					padding: 4px 0;

					.availability-msg {
						text-align: left;
					}
				}
			}
		}
		.col-1 {
			.item-details {
				.name {
					margin: 0 0 9px;
					line-height: 16px;
				}
			}
		}
		.col-2 {
			.custom-cart-update {
				input {
					margin: 12% auto 0;
					text-align: center;
				}
			}
		}
	}

	.item-details {
		.quick-order-badge {
			.quick-order-badge-link {
				font-size: $base-font - 1px;
			}
		}
	}

	.cart-footer {
		.payment-description {
			width: 100%;
			max-width: 100%;
			.heading {
				font-size: $base-font + 2px;
				font-family: "URWImperialW01-Regular";
			}
		}

		.cart-order-totals {
			width: 100%;
			max-width: 100%;
		}

		.cart-actions {
			.cart-action-checkout {
				width: 100%;
				max-width: 100%;
			}

			.cart-action-continue-shopping {
				width: 100%;
				max-width: 100%;
			}
		}
	}

	.quick-order-badge {
		width: 100% !important;
		text-align: left;
		margin: 1px 0 15px;
		float: left;
	}
	.pt_cart {
		.cart-empty {
			.cart-content {
				margin: 0;
			}
		}
		.cart-actions {
			.cart-content {
				padding: 0 10px;
			}
		}
		.cart-columns {
			padding: 25px 0 13px;
		}
		.cart-left-content {
			margin-top: 0;
		}

		.specialmessaging {
			width: 100%;
			margin: 17px 0 0;
			display: inline-block;
			@media screen and (max-width: 767px) {
				margin: 0;
			}
			p {
				margin: 0 0 1px 0;
				&.finalsalemessage {
					margin: 0 0 7px 0;
				}
			}
		}
	}
	.promo-adjustment {
		margin: 17px 0 0 0;
		display: inline-block;
		width: 100%;
	}
	img {
		&.cart-brand-logo {
			max-width: 70%;
		}
	}
}
.paypal-button {
	.checkout-paypal-btn {
		float: right;
		margin: 0.5em 0;

		&.disabled {
			pointer-events: none;
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}
/*PXSFCC-1407 fixes*/
.pobox-items {
	.apply-slim-scrollbar {
		.product-line-item {
			.cart-unit-price {
				.price-promotion {
					.price-standard {
						text-decoration: line-through;
					}
				}
			}
		}
	}
}
.top-menu-utility .menu-utility .mini-cart-content .mini-cart-products .mini-cart-product span.price-standard-exist {
	color: #bd0304;
}

.cart-overlay .mini-cart-content {
	.cart-recommendations .you-may-like {
		.trending {
			font-size: $base-font + 12px;
		}
		.product-promo {
			display: none;
		}

		.product-tile {
			.product-image {
				.thumb-link {
					width: auto;
					height: 120px;
					img {
						width: 100%;
						&.p-image-rollover {
							display: none;
						}
						&.p-image {
							display: block;
						}
						&:hover {
							&.p-image-rollover {
								display: block;
							}
						}
					}
				}
			}
			.product-name {
				font-size: $base-font + 6px;
				overflow: initial;
				word-wrap: break-word;
			}
		}

		.product-pricing {
			font-size: $base-font;
			span {
				margin: 0 auto;
			}
		}
	}
}

.ui-dialog.cart-overlay .mini-cart-content {
	.ui-dialog-buttonpane .ui-dialog-buttonset {
		margin: 10px auto 0;
	}
}

.ui-dialog {
	&.cart-mini-overlay {
		&.cart-overlay {
			max-height: 90vh !important;
			overflow-y: scroll;
			&.minicartpopup {
				@media screen and (max-width: 767px) {
					overflow-y: scroll;
					position: fixed;
				}
			}
		}
	}
	.addtocartoverlay-content {
		.mini-cart-image a {
			display: inline-block;
		}
	}
}

.ui-dialog {
	&.bonus-product-quickview {
		.product-variations {
			.attribute {
				&.Cup,
				&.Band {
					.attribute_label {
						.selected-value {
							width: 46%;
						}
					}
				}
			}
		}
	}
}

.cart-coupon-code {
	.error-messages {
		word-break: break-word;
		@media screen and (max-width: 767px) {
			margin: 0;
		}

		.error {
			@media screen and (max-width: 767px) {
				text-align: left;
				width: 100%;
				padding-top: 10px;
				float: none;
			}
		}
	}
	.cart-left-section {
		.cart-message {
			@media screen and (min-width: 1024px) {
				width: 60%;
			}
		}
	}
	.cart-right-section {
		position: relative;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		.rowcoupons + .error-messages {
			position: absolute;
			right: 0;
		}
	}
	.rowcoupons {
		width: 70%;
		float: left;
		padding: 10px 0 0 0;
		border-bottom: 1px solid $alto;

		@media screen and (min-width: 768px) and (max-width: 1024px) {
			width: 100%;
		}

		&.rowcoupons-last {
			border-bottom: 0;
		}

		.item-details {
			.auto-gift-outofstock-details {
				.auto-gift-outofstock-msg {
					margin: 18px 0 18px;
					background-color: $black;
					padding: 18px 0 16px;
					border-radius: 5px;

					@media screen and (max-width: 767px) {
						padding: 10px 0 10px;
					}

					@media screen and (min-width: 768px) and (max-width: 1024px) {
						padding: 16px 0 15px;
						margin: 15px 0 15px;
					}

					p {
						color: $white;
						font-size: $base-font + 2px;
						font-family: $WorkSans;
						letter-spacing: 0.5px;
						margin: 0;
						text-align: center;

						@media screen and (min-width: 768px) and (max-width: 1024px) {
							font-size: $base-font + 4px;
						}
						@media screen and (max-width: 767px) {
							font-size: $base-font;
							line-height: 1;
						}

						b {
							@media screen and (max-width: 767px) {
								display: block;
							}
						}
					}
				}
				.label {
					.see-details {
						font-size: $base-font + 2px;
						font-family: $WorkSans;
						color: $black;
						font-weight: bold;
						text-transform: uppercase;
						letter-spacing: 1px;
						margin-left: 35px;
						cursor: pointer;

						@media screen and (max-width: 767px) {
							font-size: $base-font;
							letter-spacing: 0.86px;
							margin-left: 0;
						}
					}
				}
			}
		}

		@media screen and (max-width: 767px) {
			padding: 20px 0 0 0;
			width: 100%;
		}

		.cartcoupon {
			text-transform: uppercase;

			.outofstock-giftitem {
				font-size: $base-font + 2px;
				letter-spacing: 1px;
				margin-left: 35px;

				@media screen and (max-width: 767px) {
					font-size: $base-font;
					letter-spacing: 0.86px;
					margin-left: 0;
				}
			}

			span {
				padding: 0 2px;
				letter-spacing: 0.6px;
			}

			.promoApplied {
				display: inline-block;
				&:focus{
					outline: none;
				}
			}

			button {
				padding: 0 0 0 6px;
				border: none;
				margin: 0;
				color: $dim-gray;
				letter-spacing: 0.6px;
				background: transparent;
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				&.removebutton {
					background: url("../../../images/x-Icon-Black.svg") no-repeat;
					background-size: cover;
					padding: 0;
					margin: 0;
					display: inline-block;
					text-indent: -9999px;
					cursor: pointer;
					width: 16px;
					height: 16px;
					vertical-align: middle;
					margin-left: 3px;
				}
			}
		}
		.discount-item-details {
			padding: 10px 0;
			max-width: 75%;
			.discount {
				padding: 8px 2px;
				letter-spacing: 0.5px;
				width: 100%;

				.see-details {
					text-transform: uppercase;
					display: inline-block;
					cursor: pointer;
					font-weight: bold;
					color: $black;
				}
			}
		}
	}

	input {
		color: $black;
		text-transform: uppercase;
	}
}
.international-shipping-modal {
	padding: 0 20px 25px;
	form {
		width: 60%;
		margin: auto;
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
		}
	}
	label {
		display: inline-block;
		width: 180px;
		font-size: 18px;
		color: $black;
		letter-spacing: 0.6px;
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			text-align: center;
			padding-bottom: 10px;
		}
	}
	select {
		display: inline-block;
		width: calc(100% - 190px);
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			text-align: center;
		}
	}
	a {
		text-decoration: underline;
		color: $dodger-blue;
	}
	.bkg-error {
		background-color: $coral-candy;
	}
	.button-group {
		margin: 26px auto 20px;
		button {
			width: 42%;
			margin: 12px;
			height: 40px;
			font-size: 18px;
			padding: 0;
			border: none;
		}
		.cancel {
			background-color: #979797;
		}
		.continue {
			background-color: #f5a623;
		}
	}
	.international_error_msg {
		margin-left: 200px;
		margin-top: 20px;
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			text-align: center;
			margin-left: 0;
		}
	}
}
.shipping-icon {
	.UPS-i-parcel-logo {
		background-image: url("../../images/ups-i-parcel-logo.jpg");
		float: left;
		height: 80px;
		width: 150px;
		background-size: 133px;
		background-repeat: no-repeat;
	}
	.secure-icon {
		background-image: url("../../images/Lock_Icon_Black.svg");
		float: right;
		width: 45px;
		height: 80px;
		background-size: 20px 59px;
		background-repeat: no-repeat;
		opacity: 0.5;
	}
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}
.specialmessag p.expeditedship {
	color: $mangotango;
	letter-spacing: 1px;
}

// SC-6157 - FF focus outline issue
.cart-recommendations .you-may-like {
	button.slick-arrow {
		overflow: hidden;
	}
}

// SC-5009 cart-redesign
.pt_cart_template_b {
	@media only screen and (max-width: 1024px) and (min-width: 768px) {
		.quick-order-badge .quick-order-badge-link {
			right: 0;
		}
	}
	.you-may-like {
		position: relative;
		padding: 20px;
		padding-bottom: 0;
		.recommendation-heading {
			.trending {
				margin: 0;
				font-size: 20px;
				text-transform: uppercase;
				font-family: $WorkSans;
				color: $recommendation-heading;
				font-weight: 600;
			}
		}

		#addtocart1_rr {
			&.cart-recommendations-tiles {
				.product-tile:hover {
					.quickview {
						display: block;
					}
				}
			}
			&.add-to-cart-rec {
				.slick-arrow {
					position: absolute;
					top: 35%;
					transform: translate(0, -50%);
					background: none;
					padding: 0;
					margin: 0;
					border: 0;
					border-radius: unset;
					width: 14px;
					text-indent: -99999px;
					&.slick-prev {
						left: -15px;
						background: url("https://cdn-us-ec.yottaa.net/5e136259d9314057054e4d4d/adobe.roamans.com/v~4b.1/on/demandware.static/Sites-oss-Site/-/default/v1584430096093/images/Left_ArrowBlack.svg?yocs=1_4_")
							no-repeat center right/contain;
						&.slick-disabled {
							background: url("https://cdn-us-ec.yottaa.net/5e136259d9314057054e4d4d/adobe.roamans.com/v~4b.1/on/demandware.static/Sites-oss-Site/-/default/v1584430096093/images/Left_ArrowGray.svg?yocs=1_4_")
								no-repeat center right/contain;
						}
					}

					&.slick-next {
						left: 100%;
						background: url("https://cdn-us-ec.yottaa.net/5e136259d9314057054e4d4d/adobe.roamans.com/v~4b.1/on/demandware.static/Sites-oss-Site/-/default/v1584430096093/images/Right_ArrowBlack.svg?yocs=1_4_")
							no-repeat center left/contain;
						&.slick-disabled {
							background: url("https://cdn-us-ec.yottaa.net/5e136259d9314057054e4d4d/adobe.roamans.com/v~4b.1/on/demandware.static/Sites-oss-Site/-/default/v1584430096093/images/Right_ArrowGray.svg?yocs=1_4_")
								no-repeat center left/contain;
						}
					}
				}
			}
			.product-tile {
				.name-link {
					max-width: 220px;
					.product-image {
						img {
							width: 200px !important;
							height: 290px !important;
							margin: auto;
						}
						&.product-brylane {
							img.p-image {
								object-fit: cover;
							}
						}
					}
				}
			}
		}
	}

	.cart-left-content {
		.below-cart-table-outside {
			display: none;
		}

		border-top: 1px solid #dcdcdc;
		margin: 0;
		padding-top: 20px;
		.combined-cart-footer {
			margin-top: 0;
			display: flex;
			flex-direction: row;
			width: 100%;
			max-width: 100%;

			.combined-cart-footer-left {
				width: 70%;
				box-sizing: border-box;
				padding: 20px 40px 20px 20px;
				border: none;

				.qty-error-msg {
					text-align: left;
					padding-left: 6px;
					margin: 15px 0;
				}

				.error-form {
					background-color: #eba447;
					border: none;
					color: white;
					border-radius: unset;
					font-size: 13px;
					text-transform: uppercase;
				}

				.plcc-cart-banner-container {
					background: #f6f5f7;
					display: flex;
					margin: 20px 0 20px 0;
					padding: 5px;
					position: relative;
					justify-content: space-between;

					.plcc-cart-banner-img {
						flex-basis: 65px;
					}

					.plcc-cart-banner-info {
						align-items: center;
						display: flex;
						flex-basis: 50%;
						flex-grow: 1;
						padding: 0 10px;
						justify-content: space-between;

						.plcc-cart-banner-username {
							font-weight: 600;
							display: inline;
							font-size: 15px;

							.plcc-first-name:before {
								content: ", ";
							}
						}

						.plcc-cart-banner-learn-more {
							color: $mainly-blue;
							flex-basis: 90px;
							font-weight: bold;
							text-align: center;
						}

						.plcc-cart-banner-extended-text {
							display: none;
						}
					}

					.accordian-action-btn {
						background: transparent;
						border: none;
						color: #000;
						flex-basis: 7%;
						font-size: $base-font + 13;
						padding: 0;
						position: relative;

						&.expand-button:before {
							content: "\2304";
							left: 50%;
							position: absolute;
							top: 45%;
							transform: translate(-50%, -55%);
						}
						&.minimize-button {
							display: none;
							position: absolute;
							right: 0;

							&:before {
								content: "\d7";
								height: 20px;
								padding: 15px;
							}
						}
					}

					&.expanded {
						@include show-expanded-offer;
					}

					@media screen and (max-width: 480px) {
						@include show-expanded-offer;

						.accordian-action-btn.minimize-button,
						.accordian-action-btn.expand-button {
							display: none;
						}
					}
				}

				@media screen and (max-width: 767px) {
					.plcc-cart-banner-container {
						margin: 20px 10px;
						min-height: 100px;
					}
				}

				form {
					display: inline-block;
					width: 100%;

					#cart-table {
						.cart-promo.bonus-product-calloutMsg {
							.bonus-item-details {
								text-align: left;
								float: left;
								padding: 0 0 0 25px;
								text-transform: none;
								color: $white;
								border-bottom: none;
								font-size: 11px;
								font-style: normal;
								font-size: 11px;
								font-family: $HelveticaMedium;
								line-height: 22px;
								letter-spacing: 2px;
								a.tooltip {
									color: $white;
									font-family: $HelveticaMedium;
									font-size: 11px;
								}
							}
							.bonus-item-actions {
								color: $white;
								padding: 0 25px 0 0;
								line-height: 22px;
								letter-spacing: 2px;
								a.select-bonus {
									text-transform: none;
									font-weight: bold;
									font-style: normal;
									color: $white;
								}
							}
						}
						.thead {
							&.thead-small {
								display: none;
							}
							.section-header {
								font-size: 16px;
								font-weight: 600;
								color: #131415;
							}
						}
						.tbody {
							border-bottom: 1px solid #dddddd;

							.cart-row .cart-columns .column {
								position: relative;
								&.col-1 {
									.image-and-cta .column.col-4 {
										display: none;
									}

									.item-details-and-qty {
										.item-details {
											max-width: 100%;
										}
										.columns-combine {
											display: none;
										}
									}
								}

								&.col-2 {
									.not-available {
										margin-left: unset;
									}
								}
							}
						}
					}
				}

				.cart-top-banner {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 0;
					border: none;

					h1 {
						font-size: 20px;
						color: black;
						display: inline;
						font-weight: 600;
						letter-spacing: 1px;
						margin: auto 0;
						line-height: 50px;
					}

					.shopping-bag-id {
						display: inline-block;
						width: auto;
						margin: 0;
						padding: 0;
						line-height: 50px;
						.pid a {
							font-size: 13px;
							font-weight: 700;
							color: $pid-font-color;
							text-transform: uppercase;
						}
					}
					.upper-subtotal {
						display: none;
						text-transform: uppercase;
					}
				}
			}

			.cart-footer {
				width: 30%;
				border: none;
				padding: 0;
				margin-right: 20px;
				font-family: "Helvetica Neue LT W01_65 Md" !important;

				.cart-right-upper {
					box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
					padding: 30px;
					overflow: auto;
					.intl-shipping-btn {
						background: none;
						border: none;
						color: black;
						font-weight: 900;
						margin: 0 auto 24px;
						float: none;
						text-decoration: underline;
						text-underline-position: under;
						cursor: pointer;
						font-size: 12px;
						letter-spacing: normal;
						font-family: $WorkSans;
						text-align: center;
						width: 100%;
						max-width: unset;
						margin: 20px auto 15px;
						box-sizing: border-box;
					}
					.cart-order-totals {
						width: 100%;
						float: unset;
						max-width: unset;
						box-sizing: border-box;

						.order-totals-table {
							padding-top: 15px;
							border-bottom: 1px solid #dddddd;
						}

						.order-detail-heading {
							border-bottom: 1px solid #dddddd;
							font-size: 20px;
							font-weight: bold;
							padding-bottom: 9px;
						}

						.order-detail {
							padding: 0 0 5px 0;

							.label {
								font-size: 14px;
								font-weight: 500;
								font-family: inherit;
								width: 65%;
							}

							.value {
								font-size: 13px;
								font-family: inherit;
								font-weight: bold;
								width: 35%;
								white-space: nowrap;
							}

							&.order-total {
								margin-top: 15px;
								padding-top: 10px;
								padding-bottom: 8px;
								border-bottom: 0;
								.label {
									font-size: 14px;
									font-weight: 600;
									text-transform: uppercase;
								}

								.value {
									font-size: 16px;
								}
							}

							&.order-shipping {
								.label .shippingoverlay-link {
									background: none;
									text-indent: 0px;
									img {
										height: 16px;
										width: 16px;
									}
								}
							}
						}
					}

					.cart-actions {
						margin-top: 5px;

						.cart-action-checkout.clearboth {
							float: none;
							clear: both;
							width: 100%;
							margin: 0;
							max-width: unset;

							.secure-checkout-msg {
								font-size: 10px;
								font-weight: 600;
								display: flex;
								justify-content: center;
								margin: 5px 0;
								letter-spacing: 0.8px;
							}

							button {
								border-radius: unset;
								font-weight: 600;
								font-size: 16px;

								@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
									max-width: 400px;
									left: 50%;
									transform: translateX(-50%);
									position: relative;
								}
							}

							.paypal-button {
								display: flex;
								justify-content: center;
								margin-top: 15px;
							}

							.or-link {
								display: none;
							}
						}
					}

					.cart-coupon-code {
						float: none;
						width: 100%;
						box-sizing: border-box;
						-webkit-box-sizing: border-box;
						-moz-box-sizing: border-box;
						padding: 0;
						background: none;

						@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
							max-width: 400px;
							margin: auto;

							.cart-message {
								margin-left: 0;
								&.hide {
									display: none;
								}

								.promo-code-anchor {
									position: relative;
									display: inline-block;
									color: $black;
									left: 28%;
									transform: translateX(-50%);
								}
							}
						}
						.cart-right-section {
							.rowcoupons {
								box-sizing: border-box;
								-webkit-box-sizing: border-box;
								-moz-box-sizing: border-box;
								float: none;
								width: 100%;
								margin-top: 10px;
								padding: 0 10px;
								border: 2px solid $promo-green;
								.item-details {
									position: relative;
									padding: 5px 0;
									.discount-details {
										margin-bottom: 25px;
										font-family: $WorkSans;
										font-size: 11px;
										letter-spacing: 0.18px;
									}
									.discount-item-details {
										&.no-calloutmsg {
											height: 20px;
											margin: 10px 0;
											padding: 0;
										}
										.discount {
											padding: 0;
											font-family: $WorkSans;
											font-size: 11px;
											letter-spacing: 0.18px;
											.label {
												.see-details {
													position: absolute;
													right: 0;
													top: 35px;
													font-family: $WorkSans;
													font-size: 12px;
													font-weight: 600;
													letter-spacing: 0.2px;
													text-transform: uppercase;
													&.opened {
														top: 28px;
													}
												}
											}
										}
									}
									.cartcoupon {
										font-size: 16px;
										max-width: 75%;
										.promo-success-icon {
											display: inline-block;
											height: 16px;
											width: 16px;
											text-align: center;
											border-radius: 12px;
											background-color: $promo-green;
											color: $white;
											font-size: 14px;
											line-height: 16px;
											padding: 0;
											letter-spacing: normal;
										}
										.applied-code-text {
											text-transform: capitalize;
											margin-left: 5px;
											font-family: $WorkSans;
											font-size: 12px;
											font-weight: 500;
											letter-spacing: 0.2px;
											padding: 0;
										}
										.value {
											font-family: $WorkSans;
											font-size: 12px;
											font-weight: bold;
											text-transform: uppercase;
											letter-spacing: 0.2px;
											padding: 0;
										}
										.textbutton {
											position: absolute;
											top: 10px;
											right: 0;
											font-family: $WorkSans;
											font-size: 12px;
											font-weight: 600;
											letter-spacing: 0.2px;
											text-transform: uppercase;
											color: $black;
											&.remove-details-swap {
												top: 10px;
												right: 3px;
											}
										}
									}
								}
								&.rewardCertificate{
									padding: 0 8px;
									.cartcoupon {
										max-width: inherit;
										position: relative;
										.applied-code-text {
											font-size: 12px;
											font-weight: 500;
											margin-left: 0;
										}
										.value {
											font-size: 12px;
											font-weight: 800;
										}
										.applied-code-amount{
											margin: 6px 0;
											padding-top: 4px;
											font-size: 12px;
											letter-spacing: 0;
											text-transform: capitalize;
											font-weight: 500;
											font-family: $WorkSans;
											color: $black;
										}
										.promoApplied:focus{
											outline: 0;
										}
										.promo-success-icon{
											width: 10px;
											font-size: 13px;
											border-radius: 25px;
										}
										.textbutton.removebuttontext{
											top: inherit;
											bottom: 5px;
										}									
									}
								}
							}
							.error-messages {
								width: 100%;
								float: none;
								max-width: inherit;
								&.promo-error-exist {
									width: 100%;
									border: 2px solid $promo-red;
									padding: 10px;
									margin-top: 10px;
									box-sizing: border-box;
									-webkit-box-sizing: border-box;
									-moz-box-sizing: border-box;
								}
								.error-promo {
									display: inline;
									font-family: $WorkSans;
									font-size: 12px;
									font-weight: normal;
									box-sizing: border-box;
									-webkit-box-sizing: border-box;
									-moz-box-sizing: border-box;
									letter-spacing: 0.2px;
									color: $promo-red;
									.promo-failure-icon {
										display: inline-block;
										font-family: $WorkSans;
										font-size: 12px;
										line-height: 8px;
										color: $promo-red;
										width: 0;
										height: 0;
										border-left: 8px solid transparent;
										border-right: 8px solid transparent;
										border-bottom: 12px solid $promo-red;
									}
								}
							}
						}
						.error-messages.promo-error-exist.error-reward{
							.error.error-promo{
								width: 100%;
								display: flex;
								letter-spacing: 0;
								padding-top:0;
							}
							.promo-failure-icon{
								margin: 2px 5px 0 0;
								border-left-width: 6px;
								border-right-width: 6px;
								border-bottom-width: 10px;
							}
							span{
								color: $black;
								.rewardCode{
									text-transform: uppercase;
								}
							}
						}
						.cart-left-section {
							.cart-message {
								width: 100%;
								height: 17px;
								font-family: $HelveticaBold;
								font-size: 14px;
								font-stretch: normal;
								font-style: normal;
								line-height: normal;
								letter-spacing: normal;
								color: $black;
								text-transform: uppercase;
								margin-left: 20px;
								padding: 0;
								@media only screen and (min-width: 768px) and (max-width: 1080px) {
									margin-left:0;
									font-size:12px;
								}
								#promo-icon-plus {
									font-family: $Helvetica;
									font-weight: 700;
									padding-right: 2px;
								}
							}
							.input-fields {
								width: 100%;
								float: none;
								max-width: inherit;
								input {
									border-radius: 0;
									&.coupon-code {
										width: 67%;
										color: $black;
										border: 1px solid $black;
										font-family: $Helvetica;
										font-size: 14px;
										text-transform: uppercase;
										&::placeholder{
											text-transform: none;											
											font-size: 13px;											
										}
									}
									&::placeholder {
										color: $black;
										opacity: 0.85;										
									}
								}
								button {
									&#add-coupon {
										background-color: $black;
										color: $white;
										border-radius: 0;
										width: 33%;
										padding: 0;
										font-family: $HelveticaBold;
										font-size: 14px;
									}
								}
							}
						}
					}
				}

				.cart-right-lower {
					padding: 15px 20px;

					.cart-sign-in {
						padding-bottom: 25px;
						margin: 25px 0 20px;
						border-bottom: 2px solid $silver;

						.cart-sign-in-header {
							font-size: 16px;
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.67;
							letter-spacing: normal;
							text-align: center;
							color: $black;
						}

						.cart-sign-in-content {
							font-size: 12px;
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.67;
							letter-spacing: normal;
							text-align: center;
							color: $black;
						}

						.cart-sign-in-cta {
							width: 100%;
							margin-top: 15px;
							font-size: 16px;
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: 51px;
							letter-spacing: 0.64px;
							text-align: center;
							border: 2px solid $black;
							border-radius: 0;
							display: block;
							height: 55px;
							text-transform: uppercase;
						}
					}
					.tax-disclaimer {
						width: 100%;
						border-bottom: 1px solid $alto;
						padding-bottom: 10px;
						margin-bottom: 10px;
						max-width: unset;
						color: $black;
					}

					.cart-footer-left {
						width: 100%;
						float: none;
						clear: both;
						max-width: 303px;

						@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
							display: inline-block;
							position: relative;
							transform: translateX(-50%);
							left: 50%;
						}

						.payment-description {
							.heading {
								font-size: 18px;
								color: $black;
							}

							.brand-cards {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}

	.last-visited-section {
		width: 100%;
		margin-top: 0;
		.slick-slider {
			.slick-list.draggable {
				.slick-track {
					margin: 0 auto;
				}
			}
		}
		.last-visited-heading {
			padding-left: 20px;
			color: $black;
			font-size: 20px;

			h2 {
				@include inherit_parent_props();
				color: $recommendation-heading;
			}
		}
		.search-result-items.tiles-container {
			.grid-tile .product-name-image-container {
				.product-image {
					img {
						width: 200px !important;
						height: 290px !important;
						margin: auto;
					}
					&.product-brylane {
						img.p-image {
							object-fit: cover;
						}
					}
				}
			}
		}

		.last-visited {
			margin-top: 20px;
		}
	}

	.question-and-answers {
		width: 70%;
		@media screen and (max-width: 1023px) {
			width: 100%;
			padding: 0 20px;
			box-sizing: border-box;
		}
	}

	// tablet landscape
	@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
		.cart-content {
			padding: 0 20px;

			.cart-left-content .combined-cart-footer {
				.combined-cart-footer-left {
					width: 63%;
					padding-left: 0;

					#cart-table {
						.column.col-3 {
							display: none;
						}

						.column.col-1 {
							width: 60%;

							.image-and-cta .column.col-4 {
								display: none;
							}

							.item-details-and-qty .columns-combine {
								display: none;
							}

							.item-details {
								.specialmessag {
									margin-left: 0;
								}
							}
						}
					}
				}

				.cart-footer {
					width: 37%;
					margin-right: 0;
					.cart-right-upper .cart-coupon-code {
						.cart-left-section .cart-message {
							.promo-code-anchor {
								color: $black;
							}
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
		.cart-content {
			padding: 0px 20px;

			.cart-left-content .combined-cart-footer {
				.combined-cart-footer-left {
					width: 60%;
					padding: 10px 40px 20px 0;

					#cart-table {
						.tbody .cart-row {
							.cart-columns {
								.column.col-1 {
									.image-and-cta {
										display: flex;
										flex-direction: column;
										width: 40%;

										.item-image {
											width: unset;
										}

										.column.col-4 {
											display: block;
											width: unset;

											.item-quantity-details {
												text-align: left;
												margin-top: 20px;

												.btm-space {
													margin-bottom: 10px;
												}

												.button-text {
													text-align: left;
												}
											}
										}
									}

									.item-details-and-qty {
										display: flex;
										flex-direction: column;
										width: 60%;

										.item-details {
											width: unset;
											min-height: 187px;
										}

										.columns-combine {
											display: block;
											width: 100%;

											.column.col-3 {
												position: absolute;
												bottom: 0;
											}

											.availability-web {
												float: none;
												display: block;
												clear: both;
												width: unset;
											}
										}
									}
								}
								.column.col-4,
								.columns-combine {
									display: none;
								}
							}
						}

						.thead.thead-small {
							display: block;

							.section-header {
								font-size: 20px;
							}
						}

						.thead.thead-large {
							display: none;
						}
					}
				}

				.cart-footer {
					width: 40%;
					margin-right: 0;
				}
			}
		}

		.specialmessaging {
			display: inline-block;

			p {
				margin: 15px 0 0;
				line-height: 15px;
			}
		}
		.basket-details {
			.cart-columns {
				.columns-combine {
					width: 100%;
					padding-left: 0;
				}
			}
			.cart-row,
			.cart-row.gift-Card {
				.column {
					&.col-1 {
						.item-details {
							width: 58%;
						}
					}
				}
			}
		}
		.cart-columns {
			padding: 20px 0;

			.column {
				&.col-1,
				&.col-2,
				&.col-3 {
					width: 100%;
					max-width: 100%;
					padding: 0;
				}

				&.col-2 {
					.custom-cart-update {
						margin-top: 20px;
						display: flex;
						flex-direction: row;
						flex-flow: row-reverse;

						.qty-arrows {
							position: relative;
						}

						.qty-increase {
							left: unset;
							transform: translateY(-50%) rotate(180deg);
							top: 50%;
							right: 5px;
						}

						.qty-decrease {
							left: 6px;
							right: unset;
							transform: translateY(-50%);
							top: 50%;
						}
					}
				}

				&.col-3 {
					.item-total {
						display: flex;
						margin-top: 20px;
						.sub-total.hide-desktop {
							display: inline;
							margin-right: 10px;
						}
					}
				}

				&.col-4 {
					width: 40%;
					max-width: 100%;
				}
			}

			.columns-combine {
				width: 60%;
				float: right;
				padding-left: 15px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}
		}

		.cart-row,
		.cart-row.gift-Card {
			.column {
				&.col-1 {
					.item-image {
						width: 40%;
						height: auto;
					}

					.item-details {
						width: 60%;
						max-width: 100%;
						padding-left: 15px;
					}
				}
				&.col-2 {
					margin: 10px 0 0 0;
					.custom-cart-update {
						width: 90px;
						height: 40px;

						@media screen and (max-width: 380px) {
							width: 80px;
						}
					}
					.item-quantity {
						float: left;
					}
					.availability-web {
						display: inline-block;
						float: left;
						width: 40%;
						text-align: left;
						margin-left: 10px;
						padding: 4px 0;

						.availability-msg {
							text-align: left;
							margin-top: 20px;
							margin-bottom: 20px;
						}
					}
				}
			}
			.col-1 {
				.item-details {
					.name {
						margin: 0 0 9px;
						line-height: 24px;
					}

					.attribute {
						margin-bottom: 8px;
					}
				}
			}
			.col-2 {
				.custom-cart-update {
					input {
						margin: 12% auto 0;
						text-align: center;
					}
				}
			}
		}

		.item-details {
			.quick-order-badge {
				.quick-order-badge-link {
					font-size: $base-font - 1px;
				}
			}
		}

		.cart-footer {
			.payment-description {
				width: 100%;
				max-width: 100%;
				.heading {
					font-size: $base-font + 2px;
					font-family: "URWImperialW01-Regular";
				}
			}

			.cart-order-totals {
				width: 100%;
				max-width: 100%;
			}

			.cart-actions {
				.cart-action-checkout {
					width: 100%;
					max-width: 100%;
				}

				.cart-action-continue-shopping {
					width: 100%;
					max-width: 100%;
				}
			}
		}

		.quick-order-badge {
			width: 100% !important;
			text-align: left;
			margin: 1px 0 9px;
			float: left;
		}
		.pt_cart {
			.cart-empty {
				.cart-content {
					margin: 0;
				}
			}
			.cart-actions {
				.cart-content {
					padding: 0 10px;
				}
			}
			.cart-columns {
				padding: 25px 0 13px;
			}
			.cart-left-content {
				margin-top: 0;
			}

			.specialmessaging {
				width: 100%;
				margin: 17px 0 0;
				display: inline-block;
				@media screen and (max-width: 767px) {
					margin: 0;
				}
				p {
					margin: 0 0 1px 0;
				}
			}
		}
		.promo-adjustment {
			margin: 17px 0 0 0;
			display: inline-block;
			width: 100%;
		}
		img {
			&.cart-brand-logo {
				max-width: 70%;
			}
		}
	}

	#cart-table .column.col-1 .item-details .specialmessag {
		margin-left: 0;
		padding-top:10px;
		&.gift-card{
			position: absolute;
			left: 0;
			bottom: -15px;
		}
	}


	@media only screen and (min-width: 320px) and (max-width: 767px) {
		.cart-content {
			.cart-left-content {
				.below-cart-table-outside .product-listing {
					&.last-visited {
						.last-visited-heading {
							text-transform: capitalize;
						}
					}
				}
				.combined-cart-footer {
					display: block;
					.combined-cart-footer-left {
						width: auto;
						padding: 0;
						box-sizing: border-box;

						.cart-top-banner {
							display: block;
							margin: 0 10px;
							text-align: left;

							h1 {
								text-align: left;
								@media only screen and (min-width: 320px) and (max-width: 360px) {
									letter-spacing: 0;
								}
							}

							.shopping-bag-id {
								float: right;
								margin: 0;
							}

							.upper-subtotal {
								display: block;
								flex: 0 1 100%;
								border-top: 1px solid #dcdcdc;
								padding-top: 15px;
								margin-top: 5px;

								@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
									position: relative;
								}

								.label {
									float: left;
									font-size: 17px;
									font-weight: 500;
								}

								.value {
									float: right;
									font-size: 20px;
									font-weight: bold;

									@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
										position: absolute;
										right: 0;
									}
								}
							}

							form {
								flex: 0 1 100%;
								@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
									fieldset {
										text-align: center;
									}
								}

								button {
									border-radius: 0;
									width: 100%;
									max-width: none;
									background-color: black;
									font-weight: 600;
									font-size: 16px;
									margin-top: 15px;

									@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
										max-width: 400px;
									}
								}
								.paypal-button {
									display: none;
								}
							}
						}

						#cart-table {
							.thead {
								&.thead-large {
									display: none;
								}

								&.thead-small {
									display: block;

									.section-header.col-1 {
										font-size: 25px;
										padding: 5px 0 10px 10px;
									}
								}
							}

							.tbody {
								border-bottom: none;

								.cart-row {
									margin: 0 10px;
									padding: 0 5px;
									width: auto;

									.cart-columns {
										.column {
											&.col-1 {
												.image-and-cta {
													display: inline-block;
													vertical-align: top;
													width: 40%;

													.item-image {
														width: 100%;
														@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
															width: auto;

															a img {
																width: 153px;
																height: 220px;
															}
														}
													}

													.column.col-4 {
														display: block;
														width: 100%;
														float: none;

														.item-quantity-details {
															text-align: left;
															margin-top: 20px;

															.btm-space {
																margin-bottom: 10px;
															}

															.button-text {
																text-align: left;
															}
														}
													}
												}

												.item-details-and-qty {
													display: inline-block;
													width: 58%;

													.item-details {
														width: 100%;
														min-height: 187px;
													}

													.columns-combine {
														display: block;
														width: 100%;

														.availability-web {
															float: none;
															display: block;
															clear: both;
															width: unset;
														}
													}
												}
											}

											&.col-2 {
												margin: 20px 0 0 0;
											}

											&.col-4 {
												display: none;
											}

											&.col-3 {
												.item-total .price-total-strikeoff {
													margin-left: 0;
												}
											}
										}

										.columns-combine {
											display: none;
										}
									}
								}
							}
						}

						.below-cart-table-inside {
							display: none;
						}
					}

					.cart-footer {
						width: auto;
						margin: 0;
						float: none;

						.cart-right-upper {
							padding: 0 10px 20px;
							overflow: hidden;
							.cart-coupon-code {
								padding: 0 10px;
								.cart-right-section .rowcoupons {
									.item-details .cartcoupon {
										.promo-success-icon {
											box-sizing: content-box;
										}
									}
								}
								.cart-left-section {
									.cart-message {
										margin-left: 10px;
										&.hide {
											display: none;
										}
										@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
											margin-top: 15px;
										}
									}
									.input-fields {
										margin-top: 0;
										@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
											margin-top: 10px;
										}
									}
								}
							}

							.cart-order-totals {
								float: none;
							}

							.cart-actions {
								margin: 5px 10px;
							}
						}

						.cart-right-lower {
							.tax-disclaimer {
								font-size: 12px;
								border-bottom: none;
							}

							.cart-footer-left {
								.payment-card-section {
									.heading {
										font-weight: 600;
									}
								}
							}
						}
					}
				}

				.below-cart-table-outside {
					display: block;
				}
			}
		}
	}
}
.cart-recommendations .you-may-like {
	button.slick-arrow {
		overflow: hidden;
	}
}

// SC-4444 specific changes
#wrapper.pt_cart_template_b,
#wrapper.pt_cart {
	.cart-row.sps-cart-row {
		.column {
			&.col-1 {
				.item-image {
					a img {
						width: 100px;
						height: 144px;
					}
				}

				.item-details {
					.product-list-item {
						.product-brand {
							color: $brand-name-color;
							font-weight: 600;
							font-size: 10px;
							margin-bottom: 0;
							line-height: normal;
							letter-spacing: 0.36px;
						}

						.name {
							margin-top: 5px;
							font-size: 14px;
							line-height: 1;
							letter-spacing: 0.64px;
							font-stretch: normal;
							font-weight: 600;
							margin-bottom: 12px;

							a {
								color: black;
							}
						}

						.sku {
							font-size: 10px;
							font-weight: 600;
							letter-spacing: 0.36px;
						}

						.variant-availability-group {
							margin-bottom: 6px;

							@media screen and (max-width: 767px) {
								margin-bottom: 8px;
							}
						}

						.swsps-top-bottom-label {
							font-size: 12px;
							font-weight: normal;
							letter-spacing: 0.26px;
						}

						.swsps-top-bottom-val {
							font-size: 12px;
							text-transform: capitalize;
							font-weight: bold;
							letter-spacing: 0.26px;
						}

						.product-availability-list {
							font-size: 12px;
							margin-bottom: 0;
							span.label {
								font-weight: normal;
								letter-spacing: 0.26px;
								text-transform: capitalize;
								color: black;
							}

							span:not(.label) {
								font-weight: bold;
								letter-spacing: 0.26px;
							}

							span.is-in-stock {
								color: black;
								letter-spacing: 0.26px;
							}
						}
					}

					.cart-unit-price {
						font-size: 12px;
						margin: 0 0 7px;

						span.label {
							font-weight: normal;
							letter-spacing: 0.26px;
						}

						span.price-promotion {
							font-weight: bold;
							letter-spacing: 0.26px;

							.price-sales {
								font-weight: bold;
								letter-spacing: 0.26px;
							}
						}
					}

					.specialmessaging {
						p,
						span {
							font-weight: 600;
							font-size: 12px;
							letter-spacing: 0.26px;
						}

						p.nationalmessage,
						p.clearancemessage,
						p.exclusionmessage {
							color: #c55300;
						}

						.finalsalemessage {
							font-weight: bold;
							font-size: 12px;
							letter-spacing: 0.6px;
						}
					}
				}
			}

			&.col-2 {
				.custom-cart-update {
					border: 2px solid #cbcbcb;

					.qty-decrease {
						right: 5px;
					}
				}

				.availability-web {
					margin-left: 15px;
					font-size: 12px;
					color: #c55300;
					font-weight: 600;

					.not-available {
						font-weight: 600;
					}
				}
			}

			&.col-3 {
				span {
					&.price-sales {
						font-size: 14px;
						font-weight: bold;
						color: #af3434;
						letter-spacing: 0.3px;
					}

					&.price-total {
						font-size: 14px;
						font-weight: bold;
						color: #000;
						letter-spacing: 0.3px;
					}

					&.price-unadjusted {
						font-size: 14px;
						font-weight: bold;
						color: #af3434;
						letter-spacing: 0.3px;
						text-decoration: line-through;
						display: block;
					}
				}

				.price-option {
					display: none;
				}
			}

			&.col-4 {
				.item-quantity-details .item-user-actions {
					.button-text span,
					a {
						letter-spacing: 0.26px;
						font-size: 12px;
					}
				}
			}
		}

		@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
			.column {
				&.col-2 {
					.availability-web {
						width: 105px;
						margin-left: 10px;
					}
					.custom-cart-update {
						margin: 63px 0 0 10px;
					}
				}

				&.col-3 {
					.item-total {
						.sub-total.hide-desktop {
							display: inline;
							font-weight: bold;
							font-size: 14px;
						}

						.price-sales,
						.price-unadjusted {
							margin: auto 0;
							color: #af3434;
						}

						.price-sales{
							margin-left: 5px;
						}

						.price-total {
							margin: auto 0;
							color: #000;
						}
					}
				}

				&.col-4 {
					.item-quantity-details {
						margin-top: 25px;
					}
				}
			}
		}

		@media only screen and (max-width: 767px) {
			.column {
				&.col-1 {
					.item-details {
						.sku {
							font-size: 10px;
						}

						.specialmessag .specialmessaging span {
							font-size: 12px;
						}
					}
				}

				&.col-2 {
					.availability-web {
						display: block;
						clear: both;
						margin-left: 0;
						margin-top: 5px;
						width: unset;
					}
				}

				&.col-3 {
					.item-total {
						margin-top: 15px;
						.sub-total.hide-desktop {
							display: inline;
							font-weight: bold;
							font-size: 12px;
						}

						.price-sales,
						.price-unadjusted {
							margin: auto 5px auto 0;
							color: #af3434;
							font-size: 13px;
							display: inline;
						}

						.price-total {
							margin: auto 0;
							color: #000;
							font-size: 13px;
						}
					}
				}

				&.col-4 {
					.item-quantity-details {
						margin-top: 25px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
	#wrapper.pt_cart .cart-row.sps-cart-row .column {
		&.col-3 .item-total .sub-total.hide-desktop {
			display: none;
		}
		&.col-4 .item-quantity-details {
			margin-top: 55px;
		}
	}
}

//cta and quantity alignment for sps in old cart
#wrapper.pt_cart .cart-row {
	.cart-columns {
		.column {
			&.col-1 {
				.image-and-cta .column.col-4 {
					display: none;
				}

				.item-details-and-qty {
					.item-details {
						max-width: 100%;
					}
					.columns-combine {
						display: none;
					}
				}
			}

			@media only screen and (min-width: 320px) and (max-width: 767px) {
				&.col-1 {
					.image-and-cta {
						display: inline-block;
						vertical-align: top;
						width: 40%;

						.item-image {
							width: 100%;
							@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
								width: auto;

								a img {
									width: 153px;
									height: 220px;
								}
							}
							@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
								width: auto;

								a img {
									width: 120px;
									height: 172px;
								}
							}
						}

						.column.col-4 {
							display: block;
							width: 100%;
							float: none;

							.item-quantity-details {
								text-align: left;
								margin-top: 20px;

								.btm-space {
									margin-bottom: 10px;
								}

								.button-text {
									text-align: left;
								}
							}
						}
					}

					.item-details-and-qty {
						display: inline-block;
						width: 58%;

						.item-details {
							width: 100%;
							min-height: 187px;
						}

						.columns-combine {
							display: block;
							width: 100%;

							.availability-web {
								float: none;
								display: block;
								clear: both;
								width: unset;
							}
						}
					}
				}
				&.col-4 {
					display: none;
				}
			}
		}
		.columns-combine {
			@media only screen and (min-width: 320px) and (max-width: 767px) {
				display: none;
			}
		}
	}
}

#wrapper {
	&.pt_cart,
	&.pt_cart_template_b {
		.cart-row .column.col-2 .availability-web {
			.availability-msg {
				text-align: left;
			}
			@media only screen and (min-width: 1024px) {
				width: 115px;

				.not-available {
					margin-left: 0;
				}
			}
		}
	}
}

.cart-row.sps-cart-row {
	.item-details {
		.product-special-messages {
			div {
				color: $special-product-messages-color;
				font-size: $base-font - 1px;
				letter-spacing: 0.5px;
				@include GothamMedium($Gotham);
				a,
				span {
					color: $special-product-messages-color;
					font-size: $base-font - 1px;
					letter-spacing: 0.5px;
					@include GothamMedium($Gotham);
				}
			}
		}
	}
}

/* SC-7459 CODE CHANGES STARTS HERE*/
.cart-primary-content
	.cart-actions
	.cart-content
	.last-visited-section
	.last-visited
	.search-result-items
	.slick-list.draggable {
	margin-left: 17px;
}
/* SC-7459 CODE CHANGES ENDS HERE*/

/* SC-7883 CODE STARTS HERE*/
.cart-content .combined-cart-footer .cart-coupon-code .error {
	width: 100%;
}
/* SC-7883 CODE ENDS HERE*/

/* SC-8342 CODE STARTS HERE*/
.cart-coupon-code .error,
.promo-error-exist .error {
	color: $promo-red;
}
/* SC-8342 CODE ENDS HERE*/
#wrapper.pt_cart_template_b{
	.reward-certificate.discount.order-detail + .remaining-total.order-detail{
		padding-top: 7px;
		.value{float:right}
	}
}
/* SC-9896 CODE STARTS HERE*/
.pt_cart_template_b .cart-primary-content .cart-empty .product-slot-recommendation .you-may-like {box-sizing:border-box;}

.cart-row {
	.column.col-4 {
        .item-quantity-details {
			margin-top: 65px;
		}
	}
}

//sc-10626 code start here
.cart-recommendations .at-recommendation #emptycart1_rr {padding: 0 20px;}

.perks-removed-from-cart .icon-close.icon-inline-svg{
	fill: $black;
    width: 0.76rem;
    height: 0.76rem;
}

.perks-discount a {
	margin-top: 2px;
	line-height: 1.05;
	color: $cobalt-blue;
	font-size: $base-font;
	font-family:$Helvetica;
}
.lineItemDetail.cart-columns {
    border-bottom: 1px solid $silver;
}

 

.btn.btn-primary:not([disabled]) {
    color: $white !important; 
}

 

button#return-myself {
    color: $black;
    text-decoration: underline;
&[disabled] {
        color: $mortar-grey;
    }
}