.gallery-top-container {
    display: block;
    font-size: 14px;
    img {
        width: 100%;
        height: auto;
    }
}

//dialog
#gallery-term-condition {
    display: none;
    padding: 0 15px 15px;
}

.gallery-dialog {
    text-align: right;
}
.gallery-dialog-open {
    text-align: right;
    font-size: 12px;
    display: none;
}
.gallery-dialog-content {
    p {
        font-size: 16px;
    }
}
