.plcc-landing-content {
	h1 {
		font-size: $base-font + 12px;
	}
	.top-banner {
		width: 100%;
	}

	.plcc-tabs {
		width: 100%;
		clear: both;
		text-align: center;
		padding: 20px 0;
		font-size: $base-font + 6px;

		.tab {
			margin: 0 7.2%;
			text-transform: uppercase;
			letter-spacing: 2px;
			padding-bottom: 3px;
		}

		&.four-links {
			.tab {
				margin: 0 6.2%;
				@media screen and (max-width: 1023px) {
					margin: 0 3.2%;
					letter-spacing: 3px;
				}
				@media screen and (max-width: 767px) {
					margin: 0 1.2%;
					letter-spacing: 1px;
				}
			}
		}
	}

	.benefits {
		display: inline-block;
		width: 100%;

		.tiles-wrapper {
			padding: 10px;
			display: inline-block;
			width: 100%;
			box-sizing: border-box;

			.tile {
				max-width: 400px;
				width: 31.33%;
				text-align: center;
				margin: 0 20px 0 0;
				box-sizing: border-box;
				display: inline-block;
				min-height: 300px;
				vertical-align: top;
				padding: 37px;

				&.row-last {
					margin-right: 0;
				}

				img {
					margin-top: 40px;
				}
				h1 {
					margin: 13px 0 14px 0;
					line-height: 33px;
				}
				span {
					font-size: $base-font + 2px;
					line-height: 22px;
					color: $dim-gray;
				}

				&.free-ship {
					img {
						margin: 45px 0 5px;
					}
				}
			}

			.row {
				display: inline-block;
				width: 100%;

				.row-2 {
					&.tile {
						img {
							margin-top: 35px;
						}

						h1 {
							margin: 10px 0 17px 0;
						}

						&.perks {
							img {
								margin-top: 31px;
							}
						}
					}
				}
			}
		}

		.brands-list {
			text-align: center;
			margin: 55px 0 35px;

			ul {
				display: table;
				width: 100%;
				padding: 0;
				margin: 0;

				li {
					display: table-cell;

					&:last-child {
						margin-right: 0px;
					}
					a {
						float: left;
						img {
							max-width: 100%;
							width: 100%;
						}
					}
				}
			}
		}
	}

	.rewards {
		position: relative;
		display: inline-block;
		width: 100%;
		margin: 170px 0 52px;

		.circle {
			max-width: 558px;
			max-height: 558px;
			width: 43vw;
			height: 43vw;
			float: left;
			border-radius: 50%;
			background: $light-skygrey;
			margin-left: 24px;
			margin-top: -6%;

			.inner-circle {
				width: 92%;
				height: 92%;
			}
			.text-content {
				max-width: 290px;
				margin: 32% auto 0;
				text-align: center;

				h1 {
					font-size: $base-font + 12px;
					margin-bottom: 34px;
					line-height: 32px;

					&.bold {
						letter-spacing: 0.5px;
					}
				}
			}
		}

		.table-wrapper {
			max-width: 695px;
			width: 55.16%;
			float: right;
			margin-left: -44px;
			background: $white;
			margin-right: 20px;

			.table-content {
				padding: 30px;
				padding: 4.3% 4.2%;
				-webkit-box-shadow: 2px 5px 21px 0px rgba(239, 239, 239, 1);
				-moz-box-shadow: 2px 5px 21px 0px rgba(239, 239, 239, 1);
				box-shadow: 2px 5px 21px 0px rgba(239, 239, 239, 1);
				box-sizing: border-box;
			}
		}

		table {
			border-collapse: collapse;
			text-align: center;
			max-width: 637px;
			width: 100%;
			table-layout: fixed;
		}

		table td,
		table th {
			border: 1px solid $light-skygrey;
			text-align: center;
			padding: 16px 10px;
			padding: 2.6% 2.1%;
		}
		table th {
			font-weight: normal;
			font-size: $base-font + 8px;
		}
		table td {
			font-size: $base-font + 12px;
		}
		table tr:first-child th {
			border-top: 0;
		}
		table tr:last-child td {
			border-bottom: 0;
		}
		table tr td:first-child,
		table tr th:first-child {
			border-left: 0;
		}
		table tr td:last-child,
		table tr th:last-child {
			border-right: 0;
		}

		.reward-look {
			font-size: $base-font + 2px;
			color: $dim-gray;
			margin: 30px 0 20px;
			width: 375px;
			line-height: 20px;

			u {
				color: $black;
			}
		}
	}

	.perks-email-wrapper {
		max-width: 786px;
		width: 100%;
		margin: 45px auto 53px;

		.perks-signup {
			max-width: 690px;
			margin: auto;

			.plcc-landing-perks {
				float: left;
				text-align: center;

				h1 {
					font-size: $base-font + 20px;
					text-transform: uppercase;
					letter-spacing: 6px;
					margin-top: 8px;
				}
			}
			.plccbenefits-n-email {
				max-width: 380px;
				float: right;
				width: 100%;

				.plcc-platinumcard-benefits {
					.plcc-benefits-head {
						width: 100%;
						display: inline-block;
						font-size: $base-font + 6px;
						margin: 4px 0 10px;
					}
					.plcc-benefit-msg {
						color: $dim-gray;
						font-size: $base-font + 2px;
						line-height: 20px;
						margin-bottom: 4px;
						display: inline-block;
						width: 100%;
					}
				}

				.footer-container {
					width: 100%;
					border: 0;

					.footer-email-signup {
						width: 100%;
					}
				}
			}
		}
		.perks-offer {
			width: 100%;
			display: inline-block;
			margin-top: 15px;

			.per-off-content {
				background: $light-skygrey;
				width: 100%;
				display: inline-block;
				padding: 13px;
				box-sizing: border-box;
				text-align: center;
				margin-top: 15px;

				span {
					font-size: $base-font + 4px;
				}
				h1 {
					font-size: $base-font + 28px;
					margin: 8px 0 3px;
					text-transform: uppercase;
					letter-spacing: 10px;
				}
			}
		}
	}

	.plcc-bottom {
		.bottom-content {
			border-top: 1px solid $alto;
			margin: 0 0 30px;
			padding: 50px;
			display: inline-block;
			width: 100%;
			box-sizing: border-box;
		}

		.bottom-left {
			max-width: 728px;
			float: left;
			width: 63%;

			.qa-section {
				border-top: 0;

				.qa-content {
					padding: 20.5px;
				}
			}
		}
		.bottom-right {
			max-width: 280px;
			float: right;
			width: 24.2%;
			text-align: center;
		}

		.plcc-text-content {
			clear: both;
			color: $dim-gray;
			line-height: 24px;
			width: 75%;
			margin: 23px 0;
			display: inline-block;
		}
		.disclaimer {
			padding: 0 15px;
		}
	}
	.pre-approved-cta {
		width: 100%;
		text-align: center;

		h1 {
			line-height: 32px;
		}
		.approved {
			font-size: $base-font + 8px;
		}
		.sign-in {
			font-size: $base-font + 2px;
			text-transform: uppercase;
			letter-spacing: 2px;
			margin-top: 10px;
		}
	}
	.footer-container {
		.footer-email-signup {
			.email-signup.make-label-absolute {
				.form-row {
					input,
					button {
						outline-offset: -3px;
					}
					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
						input[id*="emailsignup_email"] {
							margin: 3px 0 3px 4px;
						}
						button {
							margin: 3px 3px 3px 0;
						}
						label {
							&.input-focus {
								top: 4px;
								left: 4px;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.plcc-landing-content {
		.rewards {
			margin: 80px 0 27px;

			.circle {
				margin-top: 0%;
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	.plcc-landing-content {
		.plcc-tabs {
			padding: 20px 0 18px;
		}
		.benefits {
			.tiles-wrapper {
				padding: 0 10px;
				box-sizing: border-box;

				.tile {
					width: 30.75%;
					padding: 4px;
				}

				.row {
					.row-2 {
						margin-top: 30px;
					}
				}
			}
		}
		.brands-list {
			margin: 32px 0 75px;

			h1 {
				margin-bottom: 27px;
			}
		}

		.rewards {
			padding: 0 20px;
			box-sizing: border-box;

			.circle {
				width: 73vw;
				height: 73vw;
				width: 570px;
				height: 570px;
				margin: 0px auto 0;
				float: none;
			}

			.table-wrapper {
				width: 100%;
				margin: -13.5% auto 0;
				margin: -93px auto 0;
				float: none;
			}

			.reward-look {
				margin: 20px 0 10px;
			}
		}
		.perks-email-wrapper {
			padding: 0 20px;
			box-sizing: border-box;

			.perks-offer {
				padding-bottom: 50px;
				border-bottom: 1px solid $alto;
			}
		}
		.plcc-bottom {
			padding: 0 20px;
			box-sizing: border-box;
			border: 0;

			.bottom-left {
				max-width: 100%;
				width: 100%;
				float: none;
			}

			.bottom-right {
				width: 100%;
				float: none;
				text-align: center;
				max-width: 100%;
				margin: 60px 0;
				clear: both;
				display: inline-block;
			}
			.plcc-text-content {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_specials {
		#main {
			padding: 0 10px;
		}
	}

	.plcc-landing-content {
		.plcc-tabs {
			display: inline-block;
			font-size: $base-font - 2px;
			padding: 10px 0;
			.tab {
				width: auto;
				display: inherit;

				&.active {
					border-width: 2px;
				}
			}
		}

		.pre-approved-cta {
			width: 73%;
			margin: 0 auto;

			.button {
				box-sizing: border-box;
			}
		}

		.benefits {
			.tiles-wrapper {
				.tile {
					width: 50%;
					margin: 0 0;
					padding: 6px;
					min-height: 247px;

					h1 {
						line-height: 16px;
						margin: 13px 0 11px 0;
					}
					span {
						font-size: $base-font - 1px;
						line-height: normal;
					}
				}
				.tile:nth-child(even) {
					float: right;
				}
				.row {
					.row-2 {
						margin-top: 0;
					}
				}
			}
			.pre-approved-cta {
				button {
					max-width: 62%;
					margin: 20px 0 16px;
				}
			}
			.brands-list {
				display: none;
			}
		}

		.rewards {
			margin: 55px 0 27px;
			padding: 0 0;

			.circle {
				width: 84vw;
				height: 84vw;
				width: 247.5px;
				height: 247.5px;

				.text-content {
					max-width: 180px;
					margin: 20% auto 0;

					h1 {
						font-size: $base-font;
						margin-bottom: 3%;
						line-height: 16px;

						&.bold {
							font-size: $base-font + 1px;
						}
					}
				}
			}
			.table-wrapper {
				margin: -32.5% auto 0;
				margin: -62px auto 0;
				max-width: 301px;

				.table-content {
					padding: 5px;
				}
			}
			table th {
				font-size: $base-font - 2px;
				padding: 5px 10px;
			}
			table td {
				font-size: $base-font;
				padding: 5px 10px;
			}

			.reward-look {
				width: 100%;
				font-size: $base-font - 1px;
				line-height: 16px;
			}
		}

		.perks-email-wrapper {
			margin: 5px auto 18px;
			padding: 0 0;

			.perks-signup {
				.plcc-landing-perks {
					float: none;

					h1 {
						font-size: $base-font + 14px;
						margin-top: 2px;
					}
				}

				.plccbenefits-n-email {
					text-align: center;
					float: none;
					margin: auto;
					width: 72.6%;

					.plcc-platinumcard-benefits {
						.plcc-benefits-head {
							font-size: $base-font;
							margin: 4px 0 7px;
						}
						.plcc-benefit-msg {
							font-size: $base-font - 2px;
							line-height: 16px;
						}
					}
					.footer-container {
						.footer-email-signup {
							.email-signup {
								margin: 9px 0;

								.form-row {
									width: 60%;
								}
								.form-row-button {
									width: 40%;

									button {
										float: left;
										width: 100%;
									}
								}
							}
						}
					}
				}
			}

			.perks-offer {
				padding-bottom: 25px;
				margin-top: 0;

				.per-off-content {
					padding: 4px 10px 10px;
					margin-top: 0;

					h1 {
						font-size: $base-font + 13px;
						letter-spacing: 6px;
						margin: 4px 0 3px;
					}
					span {
						font-size: $base-font - 2px;
					}
				}
			}
		}
		.plcc-bottom {
			padding: 0 0;

			.bottom-content {
				padding: 20px 0 0;
			}

			.bottom-right {
				margin: 50px 0 18px;
			}
			.plcc-text-content {
				font-size: $base-font - 3px;
				line-height: 13px;
			}
			h1 {
				font-size: $base-font + 8px;
			}
		}
		.pre-approved-cta {
			.approved {
				font-size: $base-font;
			}
			.sign-in {
				font-size: $base-font - 2px;
				margin-top: 8px;
			}
			button {
				max-width: 62%;
			}
		}
	}
}
