.error-page {
	text-align: center;
	margin: auto;
	div {
		margin-bottom: 50px;
		color: $gainsboro;
		font-style: italic;
		@media screen and (max-width: 767px) {
			font-size: $base-font + 100px;
		}
	}
	p {
		margin-bottom: 40px;
		font-size: $base-font + 6px;
	}
}
.pt_error {
	.trending-section {
		.recommendation-heading {
			text-align: center;

			span {
				&.trending {
					font-size: $base-font + 18px;
					letter-spacing: 1px;

					@media screen and (max-width: 767px) {
						font-size: $base-font + 12px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.error-page-message {
		.error-page {
			div {
				font-size: $base-font + 20px;
			}
		}
	}
}
