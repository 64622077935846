#secondary {
	.refinement.category-refinement {
		margin: 0;
		width: 100%;
		display: inline-block;

		@media screen and (max-width: 1023px) {
			display: none;
		}
	}
}

#category-level-1 {
	padding: 0;
	margin: 0;

	li {
		display: block;

		.refinement-link {
			color: $black;
			text-transform: uppercase;
			&.active {
			}
		}

		ul {
			.refinement-link.active {
				&.CurrActive {
					color: $cat-red;
				}
			}
		}
	}

	ul {
		padding: 0;
		margin: 0;
	}

	> li {
		border-top: 1px solid $alto;
		padding: 0;

		> .refinement-link {
		}

		&:last-child {
			border-bottom: 1px solid $alto;
		}
	}

	#category-level-2 {
		.expandable {
			.cr-section {
				.refinement-link {
					max-width: 85%;
				}
			}
		}
	}
}

#category-level-2 {
	padding: 0;
	&:first-child {
		padding-top: 0;
	}
	> li {
		padding: 15px 0;
		&:first-child {
			padding-top: 0;
		}
		&.expandable {
			font-weight: bold;
			&:not(.not-bold) {
				border-bottom: 1px solid $alto;
			}
		}
	}
}

.not-bold {
	#category-level-3 {
		padding: 0 !important;
		li {
			&:first-child {
				padding-bottom: 0 !important;
			}
		}

		a {
			&.refinement-link {
				font-style: normal;
				margin: 0;
				padding: 0;
				display: inline-block;
			}
		}
	}
}

.nav {
	ul {
		border: 0 none;
		list-style: none outside none;
		margin: 0.58em 0;
		padding: 0;

		li {
			list-style: none outside none;
		}
	}

	.toggle {
		border: 0 none;
		display: block;
		font-size: 1em;
		font-weight: 700;
		margin: 0.83em 0 0.58em;
		text-transform: uppercase;

		span {
			background: none repeat scroll 0 0 transparent;
		}
	}

	.expanded {
		background-position: left -96px;
	}
}

.category-refinement {
	#category-level-1 {
		li {
			border-top: medium none;
			width: 100%;
			float: left;
			&.expandable {
				&.non-active {
					ul {
						display: none !important;
					}
				}

				&.active {
					a.active {
						&.CurrActive {
							color: $mainly-blue;
						}
					}

					ul {
						display: block;
					}
				}
				ul {
					li {
						.cr-section-pl {
							display: none;
						}
					}
				}
			}
			.has-sub-menu {
				&.active {
					.cr-section-pl {
						display: block;
					}
				}
			}
			a {
				letter-spacing: 1px;
				font-weight: normal;
				width: auto;
				float: left;
				word-break: break-word;
				&:hover {
					font-weight: bold;
				}
			}
			.cr-section {
				width: 100%;
				float: left;
				a {
					&.active {
						color: $black;
					}
					font-weight: bold;
				}
			}
			.cr-icon {
				background: url("../../../../images/plus-icon.svg") no-repeat;
				float: right;
				height: 15px;
				width: 15px;
				margin: 1px 6px 0 0;
				border: 0;
				padding: 0;

				&.active {
					background: url("../../../../images/minus-icon.svg") no-repeat center;
					margin: 0 6px 0 0;
				}
			}
			ul {
				padding-left: 0;
				width: 100%;
				float: left;
				li {
					border-top: medium none;
				}
			}
		}

		#category-level-2 > li.expandable {
			.cr-section a {
				font-weight: normal;
				&:hover {
					font-weight: bold;
				}
			}
			&.not-bold {
				#category-level-3 {
					margin-left: 30px;
				}
				> .cr-section > a {
					font-weight: normal;
				}
			}
			> .cr-section > a {
				font-weight: bold;
			}

			#category-level-3 li {
				&:first-child {
					padding: 14px 0 0;
				}
				padding: 20px 0 0;
				a {
					&:hover {
						font-weight: bold;
					}
				}
				#category-level-4 a.refinement-link {
					margin-left: 30px;
				}
			}
		}
	}
}

.no-expandable {
	ul {
		display: none;
	}

	&.expandable {
		ul {
			display: block;

			li {
				&.expandable {
					ul {
						display: block;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	#secondary {
		.category-refinement {
			display: none;
		}
	}
}

.category-refinement {
	#category-level-3 li.expandable {
		&.non-active {
			ul {
				display: none !important;
			}
		}
	}
}
