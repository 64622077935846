/* SMS-OPT-IN MODAL : SC-10903 */
$Helvetica : 'Helvetica Neue LT W01_55 Roman', 'Helvetica';
$HelveticaBold: 'Helvetica Neue LT W01_71488914', 'Helvetica';
$HelveticaMedium: 'Helvetica Neue LT W01_65 Md', 'Helvetica';
$Lora: 'Lora', serif;
$WorkSans: 'Work Sans', sans-serif;
$modalBackdropColor: rgba(0, 0, 0, 0.6);
$mortar-grey: #525252;
$promo-red: #b50000;

.modal-sms-opt-in-sizing{
    width: 100%;
    height: 100%;
    z-index: 1045;
    top: 0;
    left: 0;
}
.modal.sms-opt-in{
    @extend .modal-sms-opt-in-sizing;
    position: fixed;     
    &:before{
        @extend .modal-sms-opt-in-sizing;
        content: "";
        display: block;
        position: absolute;
        background: $modalBackdropColor;
    }
    &:not(.show){
        left: -999999em;
    }
    .modal-body{
        padding: 15px;
    }
    .modal-dialog{
        max-width: 390px;
        margin: auto;
        z-index: 1050;
        transform: translateY(-100vh);
        background-color: $white;
        padding: 0px;
        transition: transform 0.25s;        
        h5{
            margin: 9px 0 8px;
            font-family: $WorkSans;
            font-size: 24px;
        }
        p{
            font-family: $WorkSans;
            font-size: 16px;
            letter-spacing: 0;
            margin: 0 0 12px;
        }
        small{
            font-size: 10px;
            color: $black;
            font-family: $Helvetica;
        }
        .close{
            padding: 0;
            border: none;
            background-color: transparent;
            color: $black;
            font-size: 24px;
            position: absolute;
            right: 10px;
            top: 5px;
            font-family: $WorkSans;
            font-weight: 600;
            z-index: 1;
        }
        input#sms-opt-phone-no{
            height: 54px;
            border-radius: 0;
            margin: 0;
            font-family: $Helvetica;
            font-size: 16px;
            color: $black;
        }
        .modal-header{
            box-shadow: none;
            padding: none;
        }
        .modal-footer{
            box-shadow: none;
            display: block;
            margin-top: 8px;
            button{
                width: 100%;
                ~ .content-asset{
                    margin: 16px 0 9px;
                }
            }
        }
        label{
            position: relative;
            span{
                position: absolute;
                top: 16px;
                left: 8px;
                font-family: $Helvetica;
                font-size: 16px;
                color: $mortar-grey;
                transition: top 0.2s;
                pointer-events: none;
                &.active{
                    top: 3px;
                    font-size: 12px;
                }
                + input::placeholder{
                    opacity: 0;
                } 
                + input{
                    + .error{
                        display: none !important;
                    }
                }               
            }
            &.error{
                color: $black;
                span{
                    color: $promo-red;
                }
            }
        }
        .responseContainer{
            font-family: $Helvetica;
            font-size: 12px;
            color: $promo-red;
            text-align: center;
            outline: none;
        }
    }
    &.show{
        .modal-dialog{
            transform: translateY(10%);
        }
    }
    #success-body, #error-body{
        outline: none;
        box-shadow: none;
    }
}
/* End : SMS-OPT-IN MODAL : SC-10903 */