.tooltip-dlg-ht-510 {
	height: 510px !important;
}

.tooltip-dlg-wt-428 {
	width: 428px !important;
}

.tooltip-dlg-ht-600 {
	height: auto !important;
	max-height: 600px !important;
}

.tooltip-dlg-wt-mb-320 {
	width: 320 !important;
}

.ui-tooltip-content {
	.tooltip-custom {
		width: 297px;
	}
}

.ui-tooltip.ui-widget-content {
	background: $white;
	box-sizing: content-box;

	@media screen and (max-width: 480px) {
		margin: 0 10px;
	}
}

.ui-dialog {
	&.tips,
	&.product-hemming-tips {
		padding-top: 30px;
		padding-bottom: 15px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;

		.ui-dialog-titlebar {
			position: absolute;
			right: 0;
			width: 25px;
			height: 25px;
			top: 8px;
		}

		.ui-dialog-titlebar-close {
			padding: 7px 15px 10px 15px;
			right: 17px;
		}

		.ui-dialog-content {
			padding: 0 14px 0 25px;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;

			&.make-height-auto {
				height: auto !important;
			}

			.slimScrollDiv {
				margin: 0 !important;
			}

			.tips-contents {
				padding: 0 14px 0 0px;
				width: 100%;
				display: inline-block;

				p {
					display: inline-block;
					width: 100%;
				}
			}
		}
	}
}
.tooltip .tooltip-content {
	display: none;
}
