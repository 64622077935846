.checkout-body {
	@import "node_modules/bootstrap/scss/functions";
	@import "node_modules/bootstrap/scss/variables";
	@import "node_modules/bootstrap/scss/mixins";
	@import "node_modules/bootstrap/scss/modal";
	@import "./thirdparty/applePay";
	@import "./payment-sprite-styles";
	.font-weight-bold {
		font-weight: bold !important;
	}
	.font-weight-normal {
		font-weight: normal !important;
	}
	.not-available,
	.text-sale {
		color: $text-sale !important;
	}
	.text-muted {
		color: $mortar-grey !important;
	}
	.text-uppercase {
		text-transform: uppercase !important;
	}
	.mt-4 {
		margin-top: 1rem !important;
	}
	.mt-5 {
		margin-top: 24px !important;
	}
	.btn-block {
		display: block;
		width: 100%;
	}
	font-family: $Helvetica;
	&.no-scroll, &.no-scroll-checkout {
		height: 100%;
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}

	.plcc-acquisition-rebuttal-model {
		overflow: hidden;
	}

	.cart-row .col-1 .item-details {
		.label {
			font-weight: bold;
		}
		.label, .value{
			text-transform: capitalize !important;
		}
		.optioninfo,
		.price-promotion {
			margin-bottom: 8px;
		}
		.attribute {
			margin-bottom: 8px;

			.price-promotion {
				.price-standard {
					color: $mortar-grey;
				}
				.price-sales:not(.has-price-difference) {
					color: $text-sale;
					font-weight: bold;
				}
				.has-price-difference {
					color: $black;
					font-weight: normal;
					margin-right: 5px;
				}
			}

		}
		.optioninfo .hemmable {
			margin-top: 0;
			.optionheading {
				font-family: $Helvetica !important;
				text-transform: capitalize;
				font-weight: bold;
			}
			.optionvalue .label {
				font-weight: normal;
			}
		}
		.personalized-message {
			text-transform: capitalize !important;
			font-family: $Helvetica !important;
			font-weight: bold;
			margin-bottom: 8px !important;
		}
		.line-item-promo {
			font-size: 11px;
			margin: 0 0 8px 0;
		}
	}
	@media only screen and (max-width: 767px){
		.cart-row .column.col-1 .item-details, .cart-row .column.col-3 .item-total, .cart-row .column.col-3 .item-total .sub-total, .specialmessaging, .specialmessaging *{
			font-size: 11px;
			text-transform: uppercase;
		}
	}
}

html.no-scroll {
	height: 100%;
	overflow: hidden;
	#wrapper {
		height: 100%;
		overflow: hidden;
	}
}

.disabled-hover-event {
	display: none;
}
button[disabled],
button.disabled,
.button[disabled],
.button.disabled,
.buttonstyle[disabled],
.buttonstyle.disabled,
input[type="button"][disabled],
input[type="button"].disabled {
	position: relative;
	+ .disabled-hover-event {
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}
.full-left {
	float: left;
}
.full-right {
	float: right;
}
.width-full {
	width: 100%;
}
.display-inline-block {
	display: inline-block;
}
.content-hide {
	display: none !important;
}
@media screen and (max-width: 767px) {
	.mb-float-none {
		float: none;
	}
	.mb-margin-right-zero {
		margin-right: 0;
	}
	.mb-make-text-center {
		text-align: center;
	}
}
.checkout-pobox-smexception {
	.ui-dialog-titlebar {
		display: none;
	}
}
.address-validation-dialog {
	.suggested-address-text,
	.current-shipping-address {
		text-transform: capitalize;
	}
	#address-validation-dialog {
		padding: 28px 33px;
		overflow: hidden;
		@media screen and (max-width: 767px) {
			padding: 28px 10px;
			overflow: hidden;
			.field-wrapper {
				float: left;
			}
			.suggested-address-text,
			.current-shipping-address {
				width: 87%;
			}
		}
	}
	.ui-dialog-titlebar {
		display: none;
	}
	.address-information {
		padding: 0 20px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
	}
	.field-wrapper {
		position: relative;
		width: auto;
		display: inline-block;
		margin: 0 10px 0 0;
	}
	.addr-dialog-heading {
		background: $light-skygrey;
		border: 1px solid $very-light-gray;
		padding: 18px 18px 17px 18px;
		display: inline-block;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		margin: 0 0 24px 0;
		line-height: 20px;
	}
	.or-text {
		margin: 0 0 24px -2px;
		font-family: $HelveticaBold;
		font-size: $base-font + 1px;
	}
	.suggested-addresses {
		margin: 0 0 20px 0;
	}
	.suggested-address-text {
		font-family: $Helvetica;
		font-size: $base-font + 1px;
		.bold {
			font-family: $HelveticaBold;
		}
	}
	.original-address {
		margin-bottom: 58px;
		@media screen and (max-width: 767px) {
			margin-bottom: 20px;
		}
	}
	.addr-dialog-sub-heading {
		margin: 0 0 13px 0;
		letter-spacing: 0.6px;
		font-family: $HelveticaBold;
		font-size: $base-font + 1px;
	}
	.current-address {
		margin-bottom: 64px;
		padding: 0 18px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		.fields-value {
			line-height: 25px;
		}
		@media screen and (max-width: 767px) {
			margin-bottom: 30px;
		}
	}
	.correct-address-button {
		@media screen and (max-width: 767px) {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
	button {
		&.corrected-avs-button {
			margin: 18px 19px;
			padding: 0;
			letter-spacing: 0.5px !important;
			@media screen and (max-width: 767px) {
				margin: 18px 19px 18px 0;
			}
		}
		&.update-avs-button {
			letter-spacing: 0.5px;
		}
	}
	.address-controls {
		width: 60%;
		button {
			border-radius: 0;
			-moz-border-radius: 0;
			-webkit-border-radius: 0;
			&#cancel-avs-button {
				background: none;
				border: none;
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				margin: 18px 19px;
				padding: 0;
				letter-spacing: 0.2px;
			}
			&#selected-address-continue {
				width: 187px;
			}
		}
		@media screen and (max-width: 767px) {
			width: 100%;
			margin-bottom: 20px;
			.selected-address-button,
			.cancel-avs-suggestion {
				clear: both;
				width: 100%;
			}
			button {
				&#selected-address-continue {
					width: 100%;
				}
				&#cancel-avs-button {
					width: 87%;
				}
			}
		}
	}
	input[type="radio"] {
		opacity: 0.1;
		position: relative;
		z-index: 9;

		&:checked {
			+ .custom-radio-icon {
				background-position: -32px -5px;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 98;
			}
		}
	}
	.custom-radio-icon {
		background: url("../../images/radio-button-sprite.png") no-repeat;
		width: 17px;
		height: 17px;
		background-position: -5px -5px;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}
	.addresses-section {
		font-family: $Helvetica;
		font-size: $base-font + 2px;
		.bold {
			font-family: $HelveticaBold;
		}
		.current-address {
			font-family: $Helvetica;
			font-size: $base-font + 1px;
		}
		.current-addr-heading {
			font-family: $HelveticaBold;
			font-size: $base-font + 1px;
			margin-bottom: 20px;
		}
	}
}
.ui-dialog {
	@media screen and (max-width: 767px) {
		&.checkout-pobox-smexception {
			width: 300 !important;
			.ui-dialog-content {
				@media screen and (max-width: 767px) {
					padding: 0;
				}
			}
		}
	}
}

.pobox-exclusions {
	padding: 30px 28px 30px 34px;

	&.sm-main-section {
		padding: 28px 31px 30px 30px;
	}
	@media screen and (max-width: 767px) {
		&.sm-main-section {
			padding: 13px 10px 0;
		}
		padding: 13px 10px;

		.slimScrollRail,
		.slimScrollBar {
			width: 7px !important;
		}
		.remove-button {
			margin: 0 0 15px 0;
			width: 100%;
		}
	}
	button {
		&.edit-shipping {
			width: 319px;
			letter-spacing: 3.7px;
			@media screen and (max-width: 767px) {
				width: 100%;
				letter-spacing: 0;
			}
		}
		&.cancel-ship-method-except {
			background: none;
			border: none;
			margin: 17px 26px;
			padding: 0;
			float: right;
			font-size: $base-font;
			@media screen and (max-width: 767px) {
				width: 100%;
				margin: 17px auto;
			}
		}
		&.ship-method-submit {
			float: right;
			width: 189px;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}
	}
	.remove-button {
		button {
			width: 300px;
			letter-spacing: 3.7px;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
		}
	}
	.pobox-heading {
		background: $light-skygrey;
		border: 1px solid $very-light-gray;
		padding: 17px 18px 22px 18px;
		display: inline-block;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		margin: 0 0 34px 0;
		font-family: $Helvetica;
		font-size: $base-font + 2px;
		&.shippingexception-heading {
			padding: 12px 18px 12px 23px;
			line-height: 20px;
			margin: 0 0 26px 0;
		}
		@media screen and (max-width: 767px) {
			padding: 8px 6px;
			margin: 0 0 13px 0;
			font-size: $base-font - 2px;
			&.shippingexception-heading {
				padding: 8px 6px;
				margin: 0 0 13px 0;
			}
		}
	}
	.pobox-intro {
		font-family: $HelveticaBold;
		font-size: $base-font + 1px;
		clear: both;
		width: 100%;
		margin-bottom: 13px;
		&.shippingexception-intro {
			margin-bottom: 11px;
			letter-spacing: 0.6px;
			.selected-shipping-method {
				font-family: $HelveticaMedium, $sans-serif;
				color: $RedRibbon;
			}
		}
		@media screen and (max-width: 767px) {
			margin-bottom: 15px;
			font-size: $base-font;
			&.shippingexception-intro {
				margin-bottom: 15px;
			}
		}
	}
	.slimScrollBar {
		opacity: 1 !important;
		border-radius: 0 !important;
		-moz-border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
	}
	.slimScrollRail {
		border-radius: 0 !important;
		-moz-border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
	}
	.pobox-items {
		display: inline-block;
		width: 100%;
		margin-bottom: 19px;

		&.sem-item {
			margin-bottom: 22px;
		}
		.apply-slim-scrollbar {
			border: 1px solid $very-light-gray;
			padding: 20px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			height: 193px;
			overflow: hidden;
			@media screen and (max-width: 767px) {
				padding: 15px;
				height: 253px;
			}
		}

		.product-line-item {
			width: 50%;
			float: left;
			margin-bottom: 15px;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
			@media screen and (min-width: 768px) {
				&:nth-child(odd) {
					clear: both;
				}
			}
		}
		.item-image {
			width: 107px;
			float: left;
			@media screen and (max-width: 767px) {
				width: 85px;
			}
		}
		.po-prod-info {
			float: right;
			width: 58%;

			.attribute {
				margin-bottom: 4px;

				&.product-availability-list {
					display: none;
				}
				.label,
				.value,
				.price-promotion {
					font-size: $base-font - 1px;
				}
				.label {
					font-family: $HelveticaBold;
					text-transform: uppercase;
					margin-right: 3px;
					letter-spacing: 1px;
				}
				.value,
				.price-promotion {
					font-family: $HelveticaMedium, $sans-serif;
				}
			}
			.name {
				font-size: $base-font + 4px;
				font-family: $Helvetica;
				text-transform: capitalize;
				margin: 1px 0 10px 0;
				@media screen and (max-width: 767px) {
					font-size: $base-font + 2px;
				}
			}
			@media screen and (max-width: 767px) {
				width: 53%;
			}
		}
		button {
			font-family: $HelveticaBold;
			font-size: $base-font + 2px;
			border-radius: 0;
			-moz-border-radius: 0;
			-webkit-border-radius: 0;
			&.cancel-ship-method-except {
				font-size: $base-font + 2px;
				@media screen and (max-width: 767px) {
					width: 83%;
				}
			}
		}

		@media screen and (max-width: 767px) {
			margin-bottom: 14px;
			&.sem-item {
				margin-bottom: 14px;
			}
		}
	}

	.swsps-top-bottom-val {
		font-size: 12px;
		text-transform: capitalize;
		font-weight: bold;
	}
	.variant-availability-group {
		margin-bottom: 10px;
	}
}

.intlshipping-exclusions {
	padding: 30px 50px;

	&.sm-main-section {
		padding: 28px 31px 30px 30px;
	}
	@media screen and (max-width: 767px) {
		&.sm-main-section {
			padding: 13px 10px 0;
		}
		padding: 13px 10px;

		.slimScrollRail,
		.slimScrollBar {
			width: 7px !important;
		}
		.remove-button {
			margin: 0 0 15px 0;
			width: 100%;
		}
	}
	button {
		width: 300px;
		letter-spacing: 3.5px;
		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}
	.intlshipping-heading {
		background: $old-brick;
		border: 1px solid $old-brick;
		padding: 12px 30px;
		display: inline-block;
		box-sizing: border-box;
		width: 100%;
		margin: 0 0 34px 0;
		color: white;
		font-family: $WorkSans;
		font-size: $base-font + 2px;
		letter-spacing: 0.5px;
		@media screen and (max-width: 767px) {
			padding: 8px 6px;
			margin: 0 0 13px 0;
			font-size: $base-font - 2px;
		}
	}
	.intlshipping-intro {
		font-family: $HelveticaBold;
		font-size: $base-font + 1px;
		clear: both;
		width: 100%;
		margin-bottom: 13px;
		&.shippingexception-intro {
			margin-bottom: 11px;
			letter-spacing: 0.6px;
			.selected-shipping-method {
				font-family: $HelveticaMedium, $sans-serif;
				color: $RedRibbon;
			}
		}
		@media screen and (max-width: 767px) {
			margin-bottom: 15px;
			font-size: $base-font;
			&.shippingexception-intro {
				margin-bottom: 15px;
			}
		}
	}
	.slimScrollBar {
		opacity: 1 !important;
		border-radius: 0 !important;
		-moz-border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
	}
	.slimScrollRail {
		border-radius: 0 !important;
		-moz-border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
	}
	.intlshipping-items {
		display: inline-block;
		width: 100%;
		margin-bottom: 19px;

		&.sem-item {
			margin-bottom: 22px;
		}
		.apply-slim-scrollbar {
			border: 1px solid $very-light-gray;
			padding: 20px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			height: 365px;
			overflow-y: scroll;
			@media screen and (max-width: 767px) {
				padding: 15px;
				height: 253px;
			}
		}

		.product-line-item {
			width: 50%;
			float: left;
			margin-bottom: 15px;
			pointer-events: none;
			@media screen and (max-width: 767px) {
				width: 100%;
			}
			@media screen and (min-width: 768px) {
				&:nth-child(odd) {
					clear: both;
				}
			}
		}
		.item-image {
			width: 107px;
			float: left;
			@media screen and (max-width: 767px) {
				width: 85px;
			}
		}
		.int-exclusion-sps {
			.intlshipping-prod-info {
				.line-item-quantity {
					margin-bottom: 10px;
				}
				.sps-top-variant,
				.sps-bottom-variant {
					margin-bottom: 5px;
					.attribute {
						display: inline-block;
					}
					.color-value:after {
						content: ", ";
					}
					[data-attribute="size"],
					[data-attribute="color"] {
						font-weight: bold;
						text-transform: capitalize;
					}
					[data-attribute="size"]:before {
						content: "Size ";
					}
					.line-item-quantity,
					.cart-unit-price {
						display: block;
					}
				}
			}
		}
		.swsps-top-bottom-val {
			font-size: 12px;
			text-transform: capitalize;
			font-weight: bold;
		}
		.variant-availability-group {
			margin-bottom: 5px;
		}
		.intlshipping-prod-info {
			float: right;
			width: 58%;

			.attribute {
				margin-bottom: 4px;

				&.product-availability-list {
					display: none;
				}
				.label,
				.value,
				.price-promotion {
					font-size: $base-font - 1px;
				}
				.label {
					font-family: $HelveticaBold;
					text-transform: capitalize;
					margin-right: 3px;
					letter-spacing: 1px;
				}
				.value,
				.price-promotion {
					font-family: $HelveticaMedium, $sans-serif;
				}

				.price-promotion {
					font-size: 12px;
					font-family: $WorkSans;
					.price-standard {
						text-decoration: line-through;
					}

					.price-sales {
						color: #af3434;
						margin-left: 10px;
					}
				}
			}

			.line-item-quantity {
				.label {
					font-weight: 500;
					text-transform: uppercase;
					font-size: 12px;
					font-family: $WorkSans;
				}

				.value {
					font-weight: bold;
					font-size: 12px;
					font-family: $WorkSans;
				}
			}

			.cart-unit-price {
				.label {
					font-family: "Work Sans", sans-serif;
					font-weight: 500;
					font-size: 12px;
				}
			}

			.name {
				font-size: $base-font + 4px;
				font-family: $Helvetica;
				text-transform: capitalize;
				margin: 1px 0 10px 0;
				@media screen and (max-width: 767px) {
					font-size: $base-font + 2px;
				}
			}
			@media screen and (max-width: 767px) {
				width: 53%;
			}
		}
		button {
			font-family: $HelveticaBold;
			font-size: $base-font + 2px;
			border-radius: 0;
			-moz-border-radius: 0;
			-webkit-border-radius: 0;
			&.cancel-ship-method-except {
				font-size: $base-font + 2px;
				@media screen and (max-width: 767px) {
					width: 83%;
				}
			}
		}

		@media screen and (max-width: 767px) {
			margin-bottom: 14px;
			&.sem-item {
				margin-bottom: 14px;
			}
		}
	}
}

//page layouts
.pt_checkout {
	.primary-content {
		max-width: 840px;
		float: left;
		width: 66.6%;
		padding-top: 0;
	}
	&#wrapper {
		#main {
			padding-top: 65px;
			@media screen and (max-width: 767px) {
				padding-top: 140px;
			}
		}
		.primary-content, #secondary {
			span.paypal-text {
				font-size: 17px;
				font-weight: normal;
				text-transform: none;
				color: $black;
			}
		}
	}

	#secondary {
		max-width: 330px;
		float: right;
		width: 30%;
		padding: 0;
		clear: none;
		display: block;
	}
	&.returnconfirm-page #secondary {
		max-width: 100%;
		width: 100%;
	}
	legend {
		background: $light-skygrey;
		border-bottom: 1px solid $alto;
		padding: 35px 0 15px 0;
		margin-bottom: 30px;
		text-transform: capitalize;
		width: 100%;
		font-weight: normal;
		font-family: $Helvetica;
		font-size: $base-font + 6px;
	}
	.checkout-tabs {
		.checkout-tab-head {
		}
		.checkout-tab {
			.make-label-absolute {
				.form-row:not(.label-inline) {
					label {
						span {
							&.required-indicator {
								display: inline;
								color: $razzmatazz;
							}
						}
					}
				}
			}
		}
	}
	input[type="radio"] {
		opacity: 0.1;
		position: relative;
		z-index: 9;

		&:checked {
			+ .custom-radio-icon {
				background-position: -32px -5px;
				display: block;
				position: absolute;
				top: -2px;
				left: -2px;
				z-index: 98;
			}
		}
	}
	.custom-radio-icon {
		background: url("../../images/radio-button-sprite.png") no-repeat;
		width: 17px;
		height: 17px;
		background-position: -5px -5px;
		display: block;
		position: absolute;
		top: -2px;
		left: -2px;
	}
	.block-section {
		display: inline-block;
		width: 100%;
		padding: 0 5.2% 0 7.3%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		.mobile-isgift {
			display: inline;
		}
	}
	.saved-addr-dropdown-section {
		.select-address {
			label {
				text-align: left;
			}

			.selected-details {
				padding: 0 0 0 15px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				font-size: $base-font + 1px;
			}
		}
	}
	.checkout-tab-head {
		margin: 0;
		h3,
		h2,
		h1 {
			font-size: $base-font + 8px;
			text-transform: capitalize;
			font-weight: normal;
			font-family: $Helvetica;
		}
		h3 {
			padding-bottom: 0px;
		}
	}
	.av-plcc-error-message {
		margin: 16px 0 -20px 0;
	}
	.shipping-section {
		.delivery-option-heading {
			width: 100%;
			display: inline-block;
			legend {
				margin-bottom: 18px;
			}
			a {
				background: url(../../images/tips.svg) no-repeat;
				width: 16px;
				height: 16px;
				background-size: cover;
				background-position: 64%;
				float: right;
			}
		}
		.form-row {
			width: 49%;
			display: inline-block;
			vertical-align: top;
			min-height: 45px;
			margin: 0 0 10px 0;

			&.even {
				label {
					padding: 0;
				}
			}

			&.odd {
				label {
					padding: 0;
				}
			}

			.field-wrapper {
				width: 4%;
				margin: 1px 10px 0 2px;
			}
		}
		.field-desc {
			span.form-caption {
				display: block;
			}
		}
		.field-desc {
			width: 87%;
			float: left;
			font-size: $base-font + 1px;
			.standard-shipping {
				text-decoration: line-through;
			}
			.discount-shipping {
				color: $dark-red;
			}
			.base-shipping {
				color: $black;
			}
			.promo {
				.label {
					color: $RedRibbon;
					font-family: $HelveticaBold;
					font-size: $base-font + 1px;
				}
			}

			label {
				span {
					color: $black;
				}
			}
		}
		span {
			font-family: $HelveticaBold;
			color: $black;
		}
		label {
			font-family: $HelveticaBold;
			font-size: $base-font + 1px;
			padding: 0 0 6px 0;
			display: inline-block;
			color: $black;

			&.input-focus {
				transform: none;
			}
		}
		.form-caption {
			font-size: $base-font + 1px;
			margin: 0;
			font-family: $Helvetica;
			color: $black;
			text-transform: none;
			.estimateddate {
				font-size: $base-font + 1px;
				font-family: $Helvetica;

				span {
					font-size: $base-font + 1px;
					font-family: $Helvetica;
				}
			}
		}
		.form-caption-orange {
			font-size: $base-font;
			color: $mangotango;
			margin: 0 0 10px 0;
		}
		ul {
			margin: 0 0 6px 0;
			&.shipping-promos {
				margin: 0;
				display: inline-block;
				padding: 0;
				font-size: $base-font + 1px;
				font-family: $Helvetica;
			}
		}
	}
	.gift-section {
		.free-gifting {
			display: table;
			width: 100%;
		}
		.gift-right {
			width: 40%;
			box-sizing: border-box;
			display: table-cell;
			vertical-align: bottom;
			padding: 0 0 10px 0;
			.form-row-button {
				width: 100%;
				margin: 40px 0 0 0;
				text-align: right;
				button {
					font-family: $HelveticaBold;
					padding-top: 14.5px;
					padding-bottom: 14.5px;
				}
				@media screen and (min-width: 768px) {
					float: right;
					width: auto;
				}
			}
			.spc-shipping-btn {
				span {
					color: $white;
				}
			}
		}
		.form-row {
			&:nth-child(2n) {
				float: right;
			}
			.field-wrapper {
				width: auto;
			}
			&.hidden {
				display: none;
			}
			&.form-indent {
				label {
					span {
						font-family: $Helvetica;
						font-size: $base-font + 1px;
						color: $black;
					}
				}
			}
		}
	}
	.cart-row {
		.col-1 {
			width: 62%;
			max-width: 100%;
			.item-details {
				.name {
					font-size: $base-font + 4px;
					font-family: $Helvetica;
					text-transform: capitalize;
					margin-bottom: 8px;
					font-weight: bold;
				}
				.sku {
					color: $dim-gray;
					font-family: $HelveticaMedium, $sans-serif;
					font-size: $base-font - 1px;
					margin: 0 0 17px 0;
				}
				.attribute {
					.label {
						font-family: $HelveticaBold;
					}
					.value {
						font-family: $HelveticaMedium, $sans-serif;
					}
				}
			}
		}
		.col-2 {
			.custom-cart-qty {
				margin: 52px 0 0 0;
				font-size: $base-font - 1px;
				.label {
					font-family: $Helvetica;
					letter-spacing: 1px;
					font-weight: bold;
				}
				.value {
					font-family: $HelveticaMedium, $sans-serif;
				}
			}
		}
		.col-3 {
			.item-total {
				text-align: right;
				font-family: $Helvetica;
				font-weight: bold;
				font-size: 14px;
				.discount {
					color: $guardsman-Red;
				}
				.sub-total {
					font-family: $HelveticaBold;
				}
				.price-adjusted-total {
					font-weight: bold;
					font-size: 16px;
					@media screen and (max-width: 767px) {
						font-size: 11px;
					}
				}
			}
		}
		&.gift-Card {
			.col-1 {
				.item-details {
					.giftcard,
					.to {
						margin-bottom: 8px;

						.value {
							font-size: 11px;
						}
					}
				}
			}
		}
	}
	.specialmessag {
		.promo-adjustment, .specialmessaging p {
			color: $mortar-grey;
			font-size: 11px;
		}
		@media screen and (min-width: 1024px) and (max-width: 1260px) {
			margin-left: 0;
		}
	}
	.specialmessaging {
		p {
			margin-bottom: 0;
			&.finalsalemessage {
				margin-bottom: 7px;
			}
		}
		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}

	.specialmessaging-Mobile {
		display: none;
		@media screen and (max-width: 767px) {
			display: block;
			.finalsalemessage {
				margin-bottom: 5px;
			}
		}
	}

	.promo-adjustment {
		font-family: $Helvetica;
		font-size: $base-font;
		margin: 10px 0 0 0;
	}
	span.tax-error-message {
		&:not(.hide) {
			display: inline-block;
		}
		width: auto;
		padding: 0 0;
		border: 0;
		margin: 8px 0;
		background: none;
		font-family: $Helvetica;
		color: $checkout-error-color-thick;
		clear: both;
		float: right;
	}
	.make-label-absolute {
		.form-row {
			.selected-option {
				text-indent: -9999px;
				background: transparent;
			}
			.field-wrapper {
				.custom-select {
					.selected-option {
						padding: 21px 0 10px 13px;
						font-size: $base-font + 2px;
						font-family: $HelveticaMedium, $sans-serif;
						text-transform: none;
					}
				}
			}
			&.show-text {
				.selected-option {
					text-indent: 0;
				}
			}
			&.custom-select {
				background: $white;
				.input-focus + .field-wrapper .selected-option {
					text-indent: 0;
				}
			}
			&.error-handle {
				&.postal,
				&.state {
					label {
						-webkit-transform: translate(5px, 14px);
						-ms-transform: translate(5px, 14px);
						transform: translate(5px, 14px);
						span {
							&.error {
								float: left;
								text-align: left;
								width: auto;
							}
						}
					}
				}
				label {
					span {
						&.label-text {
							display: none;
						}
					}
				}
			}
			&.state,
			&.country {
				.field-wrapper {
					background: $white;
				}
			}
			&:not(.label-inline) {
				label {
					font-weight: normal;
					font-size: $base-font + 2px;
					padding: 0;
					position: absolute;
					-webkit-transform: translate(5px, 18px);
					-moz-transform: translate(5px, 18px);
					transform: translate(5px, 18px);
					left: 12px;
					margin: 0;
					top: -3px;
					width: auto;
					color: $dim-gray;
					z-index: 1;
					-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
					transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
					-ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
					text-align: left;
					&.error {
						color: $checkout-error-color !important;
						text-transform: none;
					}
					span {
						font-family: $Helvetica;
						font-size: $base-font + 2px;
						color: $dim-gray;
						text-transform: capitalize;

						&.required-indicator {
							display: none;
						}
						&.error {
							color: $promo-red !important;
							text-transform: none;
						}
						&.server-error {
							color: $venetian-red;
						}
					}
					&.input-focus {
						-webkit-transform: translate(2px, 6px);
						-ms-transform: translate(2px, 6px);
						transform: translate(2px, 6px);
						text-align: left;

						span {
							font-size: $base-font - 1px;
						}
					}
				}
			}
		}
	}

	.spc-shipping {
		.saved-address-block {
			border-bottom: none;
			.shipping-address-save {
				padding: 0;
				background: none;
				border: none;
				font-size: $base-font - 1px;
				font-family: $HelveticaBold;
				letter-spacing: 1px;
				border-radius: 0;
			}
			.saved-addr-dropdown-section {
				label {
					text-align: left;
					width: auto;
					margin-top: 0;
					display: inline-block;
				}
			}
		}
		.billing {
			border-bottom: 1px solid $alto;
		}
		.summary {
			border-bottom: 1px solid $alto;
		}
	}
	.saved-address-block {
		.shipping-address-save {
			padding: 0;
			background: none;
			border: none;
			font-size: $base-font - 1px;
			font-family: $HelveticaBold;
			letter-spacing: 1px;
			border-radius: 0;
		}
	}
	.address-section {
		.nickname-field {
			width: 100%;
			float: left;
			visibility: hidden;
		}
		.form-row {
			width: 49.5%;
			max-height: 70px;
			float: left;
			padding: 0 5% 0 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			&.error-handle {
				.selected-option,
				.input-text,
				.input-select {
					outline: 3px solid $promo-red;
				}
			}
			&.country {
				display: none;
			}
			&.continue-to-payment {
				width: 100%;
				max-height: 100%;
			}
			&.postal {
				width: 23.5%;
				padding-right: 2.5%;
				clear: both;
			}
			&.phone,
			&.address2 {
				.form-field-tooltip,
				.form-caption {
					display: none;
				}
			}
			&.useAsBillingAddress,
			&.makeDefaultAddress {
				clear: both;
			}
			&.useAsBillingAddress {
				padding: 1px 0 0 1px;
				input[type="checkbox"] {
					margin: 0;
				}
			}
			&.label-inline {
				.field-wrapper {
					width: auto;
					margin-right: 11px;
				}
				label {
					position: static;
					padding-left: 0;
					transform: none;
				}
			}
			&.state {
				width: 26%;
			}
			.field-wrapper {
				width: 100%;
				padding: 0;
				font-size: $base-font + 2px;
			}
		}
		&.make-label-absolute {
			.form-row {
				&.label-inline {
					label {
						span {
							font-family: $Helvetica;
							font-size: $base-font + 1px;
							color: $black;
						}
					}
				}
			}
		}
	}
	.checkout-tab-content {
		width: 100%;
		background: $light-skygrey;
	}

	input[type="checkbox"] {
		width: 15px;
		height: 15px;
		border: 0px solid $white;
		outline: 1px solid $black;
		background: $white;
		margin: 0 0 0 1px;

		&:checked {
			background: url(../../images/check.svg) no-repeat center;
		}
	}

	.form-row {
		margin: 0 0 17px 0;
		position: relative;

		.field-wrapper {
			margin: 0;
			font-family: $HelveticaMedium, $sans-serif;
			font-size: $base-font;
			position: relative;

			input[type="text"],
			input[type="number"],
			input[type="password"],
			input[type="tel"],
			input[type="date"] textarea,
			select {
				padding: 16px 15px 16px 15px;
				font-size: $base-font + 2px;
				height: auto;
				min-height: 50px;
				border-radius: 0;
			}

			button {
				&.pwd-show {
					padding: 15px;
					background: transparent;
					border: none;
					color: $black;
					font-family: $HelveticaMedium, $sans-serif;
					font-size: 11px;
					position: absolute;
					top: -20px;
					right: -14px;
					-webkit-transform: translate(-14px, 20px);
					-ms-transform: translate(-14px, 20px);
					transform: translate(-14px, 20px);
					z-index: 1;
					cursor: pointer;
					text-transform: uppercase;
					width: auto;
				}
			}

			.selected-details {
				@media screen and (max-width: 767px) {
					display: inline-block;
					font-size: $base-font;
					padding: 11px 0 0 2px;
					box-sizing: border-box;
				}
			}
		}
		.error-messages {
			position: absolute;
			right: 0;
		}
	}
	.billing {
		.address-section {
			.form-row {
				&.addToAddressBook {
					width: 100%;
				}
				&.makeDefault {
					width: 100%;
				}
			}
		}
	}

	.differed-billing {
		.form-row {
			label {
				margin: 3px 0 0 0;
				position: static;

				span {
					font-size: $base-font + 1px !important;
					color: $black !important;
					font-family: $Helvetica !important;
				}

				&.input-focus {
					span {
						font-size: $base-font + 1px !important;
						color: $black;
						font-family: $Helvetica !important;
					}
				}
			}
		}
		.more {
			line-height: 18px;
			margin-bottom: 20px;
			font-family: $Helvetica;
			color: $black;
		}
		.morecontent span {
			display: none;
		}
		.morelink {
			display: inline-block;
			color: $dim-gray;
			text-decoration: underline;
			line-height: 12px;
			cursor: pointer;
		}
		@media screen and (max-width: 767px) {
			.differedBilling {
				margin-bottom: 30px;
			}
			.differed-billing-content {
				position: relative;
			}
			.morelink {
				text-decoration: none;
				padding-right: 14px;
				position: absolute;
				top: -17px;
				right: 0;
				background: url("../../images/carat-down-gray.svg") no-repeat right center;
				background-size: 13px;

				&.less {
					background: url("../../images/carat-up-gray.svg") no-repeat right center;
					background-size: 13px;
				}
			}
		}
	}
}
.pt_checkout {
	.form-row {
		label {
			+ .field-wrapper {
				input[type="text"],
				input[type="number"],
				input[type="password"],
				input[type="tel"],
				input[type="date"] textarea,
				select {
					padding: 21px 15px 10px 13px;
				}
				input[type="number"]::-webkit-inner-spin-button,
				input[type="number"]::-webkit-outer-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}
			}
		}
	}

	.international-cart-message {
		padding: 5px 20px;
		text-align: left;
		margin: 0;
	}
}

//login content styling
.spc-login {
    h3,
	h2,
	h1 {
		text-transform: none;
		font-weight: normal;
		margin: 12px 0;

		&.heading {
			padding-bottom: 13px;
			margin-bottom: 20px;
			border-bottom: 1px solid $alto;
		}
	}

	.login-data {
		.checkout-login-email {
			font-size: $base-font + 1px;
			font-family: $HelveticaBold;
		}
		.checkout-login-brands {
			display: inline-block;
			width: 100%;
			font-size: 13px;
			margin-top: 6px;
		}
	}
	button:not(.intl-shipping-btn) {
		float: right;
		font-weight: normal;
		font-family: $HelveticaBold;
		font-size: $base-font + 2px;
		letter-spacing: 1.5px;
		&.spc-guest-btn {
			outline-offset: -3px;
			border-radius: 5px;
			text-transform: none;
		}
	}

	.login-rememberme {
		margin: 5% 0 0;
		float: left;

		label {
			position: static;
			transform: none !important;
			margin-left: 9px;

			span {
				color: $black;
			}
		}
	}
	.checkout-tab-head {
		h3,
		h2,
		h1 {
			text-transform: none;
		}
	}
}

// login boxes
.checkoutlogin {
	padding-top: 18px;
	display: inline-block;
	width: 100%;
	padding-bottom: 22px;
	background: $white;

	.checkoutlogin-col-wrapper {
		display: table;
		width: 100%;
		position: relative;
		border-spacing: 4px 0;
		.username-login {
			display: none;
		}
		.required-field {
			&.error-label {
				font-family: $Helvetica;
				font-size: $base-font + 1px;
				color: $promo-red;
				font-weight: 500;
				padding-bottom: 10px;
				display: inline-block;
				@media screen and (max-width: 767px) {
					display: block;
					padding: 10px 0px 0px;
				}
				&.hide {
					display: none;
				}
			}
		}
	}

	.col-1 {
		max-width: 416px;
		width: 49.5%;
		background: $light-skygrey;
		margin: 0 5px 0 0;
		padding: 24px 63px 52px 62px;
		box-sizing: border-box;

		.login-data {
			display: none;
		}

		button {
			&.oAuthIcon {
				background: $chambray;
				border-color: $chambray;
				color: $white;
				width: 100%;
				padding: 11.67px 0 9px;
				margin-top: 15px;
				font-size: 14px;
			}
			&.oAuthIcon.social-icons {
				display: flex;
    			align-items: center;
				justify-content: center;
				background: $white;
				border-color: $light-black-three;
				border-radius: 5px;
				text-transform: none;
				color: $light-black-three;
				border-width: 1px;
				span {
					padding-left: 10px;
				}
			}
			&.spc-login-btn {
				border-radius: 5px;
				text-transform: none;
				@media screen and (min-width: 1025px) {
					width: 56.4%;
					max-width: 164px;
					padding: 14px 0;
				}
			}
			&.Facebook_button {
				img {
					width: 18px;
					height: 18px;
				}
			}
			&.Apple_button {
				img {
					padding-top: 0px;
					vertical-align: top;
					width: 15px;
					height: 18px;
				}
			}
		}
		.or-separator {
			display: flex;
			align-items: center;
			text-align: center;
			margin-top: 15px;
		  }
		.or-separator::before,
		.or-separator::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid #aaa;
		}
		.or-separator:not(:empty)::before {
		margin-right: .25em;
		}
		.or-separator:not(:empty)::after {
		margin-left: .25em;
		}

		#password-reset {
			color: $black;
			float: right;
			margin: 13px 3px;
			@include WorkSansSemiBold($WorkSans);
			clear: right;
			text-transform: uppercase;
		}

		.form-row-button {
			margin-top: 6px;
		}
		h2 {
			&.heading {
				font-family: $Helvetica;
				font-size: 20px;
				border-bottom: 1px solid $alto;
				font-weight: bold;
			}
		}
		.login-box {
			.login-box-content {
				.error-form {
					& + fieldset {
						.password {
							label {
								.label-text {
									color: $RedRibbon;
								}
							}
						}
					}
				}
			}
		}
		.form-row {
			&.error-handle {
				+ .password {
					label {
						.label-text {
							color: $RedRibbon;
						}
					}
				}
			}
		}
	}

	.col-seperator {
		display: inline-block;
		position: absolute;
		left: 47%;
		top: 44%;
		width: 50px;
		height: 50px;
		background: $white;
		border-radius: 50%;
		text-align: center;
		line-height: 50px;
		font-size: 20px;
		text-transform: lowercase;
		font-family: $Helvetica;
	}
	.col-2 {
		max-width: 416px;
		width: 49.5%;
		background: $light-skygrey;
		margin: 0 0;
		padding: 24px 62px 50px;
		box-sizing: border-box;

		.newsletter-signup {
			label {
				transform: none;
				margin: 2px 0 0 11px;

				span {
					color: $black;
					text-transform: inherit;
				}
			}
		}
		.formbuttonrow {
			margin: 37px 0 56px;
			margin: 12.8% 0 4%;
		}
		h2 {
			&.heading {
				font-family: $Helvetica;
				font-size: 20px;
			}
		}
	}
	.col-1,
	.col-2 {
		display: table-cell;
		float: none;
		max-width: inherit;
	}
}

// Customer sign in section design
.customer-signin-section {
	margin-top: 0 !important;
	background: $light-skygrey;
	padding: 14px 34px 28px;

	.head-wrapper {
		padding-bottom: 15px;
		margin: 6px 0 15px;
		border-bottom: 1px solid $alto;

		h2, .h2 {
			text-transform: none;
			font-weight: normal;
			font-size: $base-font + 8px;
			margin: 0 0;
			font-family: $Helvetica;

			&.align-left {
				display: inline-block;
				letter-spacing: normal;
			}
		}
	}
	.checkout-guest,
	.welcome-check {
		font-size: $base-font + 1px;
		font-family: $Helvetica;
	}
	.edit-link {
		float: right;
		text-transform: uppercase;
		font-family: $HelveticaBold;
		font-size: $base-font + 2px;
		letter-spacing: 1px;
		line-height: 30px;
		cursor: pointer;
		.edit-icon {
			width: 14px;
			margin: 0 5px 0 10px;
			height: 16px;
		}
	}
}

// saved-address-block
.saved-address-block {
	&:not(.block-section) {
		border-top: 1px solid $alto;
	}
	border-bottom: 4px solid $white;
	.head-wrapper {
		padding-bottom: 16px;
		margin: 32px 0 4px 0;
		border-bottom: 1px solid $alto;
		display: inline-block;
		width: 100%;
		legend {
			text-transform: none;
			font-weight: normal;
			margin: 0 0;
			padding: 0 0;
			border: 0;
			float: left;
			width: auto;

			&.align-left {
				display: inline-block;
			}
			.required-field {
				font-family: $Helvetica;
				font-size: $base-font + 1px;
				color: $promo-red;
				font-weight: 500;
				@media screen and (max-width: 767px) {
					display: block;
					padding: 10px 0px 0px;
				}
				&.hide {
					display: none;
				}
			}
		}
	}
	legend {
		&.head-wrapper {
			padding-bottom: 16px;
			margin: 32px 0 4px 0;
			border-bottom: 1px solid $alto;
			display: inline-block;
			width: 100%;
			text-transform: none;
			font-weight: normal;

			.required-field {
				font-family: $Helvetica;
				font-size: $base-font + 1px;
				color: $promo-red;
				font-weight: 500;
				@media screen and (max-width: 767px) {
					display: block;
					padding: 10px 0px 0px;
				}
				&.hide {
					display: none;
				}
			}
		}
		&.align-left {
			display: inline-block;
		}
	}
	.saved-address-count {
		font-family: $Helvetica;
		font-size: $base-font + 1px;
		margin: 7px 0 15px;
		display: inline-block;
	}

	.select-address {
		.field-wrapper {
			padding: 0 0;
			width: 100%;

			select {
				width: 100%;
				font-family: $HelveticaBold;
			}
		}
	}
	.addresssection {
		font-family: $Helvetica;
		margin: 2px 0 27px;
		font-size: $base-font + 1px;
		.bill-div {
			line-height: 21px;
		}
	}
	.address-nickname {
		font-family: $HelveticaBold;
	}
	.saved-addr-dropdown-section {
		label {
			display: inline-block;
		}
	}
}

//save, cancel, edit buttons
.address-actions {
	float: right;
	font-family: $HelveticaBold;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: $base-font - 1px;
	margin-top: 8px;
	font-weight: bold;

	span {
		cursor: pointer;
		font-family: $HelveticaBold;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: $base-font - 1px;
	}
	.seperator {
		margin: 0 14px;
		cursor: auto;
		padding: 0;
	}

	a {
		cursor: pointer;
		color: $black;
	}
}

//Billing page
.payment-option-section {
	.tab-section {
		padding: 0 6.4% 0 7.3%;
	}
}

		.tab-section {
			border-top: 4px solid $white;
			display: inline-block;
			width: 100%;
			box-sizing: border-box;

			@media screen and (max-width: 767px) {
				padding: 0 6.4% 0 3.3%;
			}

			.left-content {
				.error-messages {
					.error {
						color: $promo-red;
					}
				}
				.reward_content_desc p, .gift_certificate_desc p, .reward_content_desc p{
                    font-family: $Helvetica;
					margin-top: 0px;
				}
			}

			.toggle-content {
				margin-top: 11px;
				display: none;
			}

			.row-header {
				width: 100%;
				display: inline-block;
				padding: 21px 0 18px 0;
				box-sizing: border-box;
				cursor: pointer;
				vertical-align: middle;

				legend {
					border: 0;
					padding: 0 0;
					width: auto;
					float: left;
					margin: 0 0;
				}
				.arrow-img {
					float: right;
					background: url("../../images/carat-down.svg") center no-repeat !important;
					width: 16px;
					height: 22px;
					cursor: pointer;
					max-width: 16px;
					padding: 0 !important;
					min-width: 16px;
					max-height: 22px;
					border: 0;
				}

				.arrow-img {
					&.expanded {
						transform: rotate(-180deg);
						-webkit-transform: rotate(-180deg);
						-ms-transform: rotate(-180deg);
					}
				}
				button {
					&.arrow-img {
						&:hover {
							background: url("../../images/carat-down.svg") center no-repeat;
						}
					}
				}
				.option-tooltip {
					background: url("../../images/tips.svg") center no-repeat;
					width: 16px;
					height: 16px;
					background-size: cover;
					padding: 0;
					margin: 4px 21px 0 0;
					display: inline-block;
				}
			}

			&.showcontent {
				.toggle-content {
					display: block;
				}
			}

			.left-content {
				float: left;
				max-width: 340px;
				width: 47%;

				@media screen and (max-width: 767px) {
					width: 100%;
				}

				.seperator {
					margin: 0 10px;
					width: auto;
					float: left;
				}

				.applied-code-section {
					margin-bottom: 33px;
					width: 100%;
					display: inline-block;

					&.giftcert {
						.applied-code {
							margin-bottom: 9px;
						}
						.applied-value {
							margin-bottom: 12px;
						}
					}
					span {
						font-family: $Helvetica;
						display: inline-block;
						float: left;

						&.cursor {
							cursor: pointer;
						}
					}
					.applied-code {
						display: inline-block;
						width: 100%;
						margin-bottom: 6px;

						span {
							font-family: $HelveticaBold;
						}
					}
					.applied-value {
						margin-bottom: 9px;
					}

					.remove-giftcert,
					.gift-card-number {
						width: auto;
						float: left;
					}
				}
				.billing-coupon-code {
					.rowcoupons {
						&.rowcoupons-last {
							border-bottom: 0;
						}
						.item-details {
							position: relative;
							border-bottom: 1px solid $alto;
							padding: 10px 0 0 0;
						}
						.cartcoupon {
							span {
								letter-spacing: 0.6px;
								font-family: $HelveticaBold;
								float: left;
								font-size: 14px;
								&:focus{
									outline: none;
								}
							}

							button {
								padding: 0;
								border: none;
								margin: 0;
								font-family: $HelveticaBold;
								font-size: $base-font;
								letter-spacing: 0.6px;
								background: transparent;
								color: $black;
								text-transform: capitalize;
								border-radius: 0;
								-webkit-border-radius: 0;
								-moz-border-radius: 0;

								&.removebutton {
									background: url("../../images/x-Icon-Black.svg") no-repeat;
									width: 14px;
									height: 14px;
									background-size: cover;
									vertical-align: middle;
									padding: 0;
									margin: 0;
									display: inline-block;
									text-indent: -9999px;
									cursor: pointer;
								}
							}
						}

						.discount {
							padding: 8px 2px;
							letter-spacing: 0.5px;
							width: 100%;

							.see-details {
								display: inline;
								cursor: pointer;
								position: absolute;
								top: 0;
								right: 0;
								text-transform: uppercase;
								font-size: $base-font - 1px;
								transform: none;
							}
						}
						.error-messages {
							display: none;
							.error {
								color: $promo-red;
							}
							@media screen and (max-width: 767px) {
								display: none;
								position: static;
								.error {
									color: $promo-red;
								}
							}
						}
					}
				}
			}

			.right-content {
				float: right;
				width: auto;
				max-width: 290px;
				.item-details {
					display: none;
				}
				.field-wrapper {
					padding: 0 0;
					width: 100%;
				}

				.form-row {
					width: 189px;
					float: left;
					margin: 0 0 10px 0;

					input[type="text"],
					input[type="number"],
					&:not(.label-inline) span {
						font-size: $base-font + 2px;
					}
					label span.label-text {
						font-size: $base-font;
						text-transform: none;
						letter-spacing: 0;
					}
					&.cardnumber {
						width: 290px;
						float: right;
						margin-bottom: 20px;

						@media screen and (max-width: 767px) {
							width: 100%;
						}
					}

					&.pin {
						clear: both;
						float: left;
						width: 129px;
						margin-bottom: 27px;
					}
				}

				button {
					float: left;
					max-width: 100px;
					border-radius: 0;
					padding: 15px 22px;
					max-height: 51px;
					min-width: 100px;
					min-height: 50px;

					@media screen and (max-width: 767px) {
						padding: 14px 24px;
					}
				}
				.error-msg-content {
					display: inline-block;
					float: left;
					clear: both;
					margin-bottom: 8px;
					.error-messages {
						word-break: break-word;
						color: $promo-red;
					}
					.error {
						color: $promo-red;
					}
				}
			}
			.coupon-apply {
				float: left;
			}
			.coupon-error {
				.error {
					color: $promo-red;
				}
				@media screen and (max-width: 480px) {
					float: left;
					width: 100%;
				}
			}
			.giftcert-apply {
				float: right;
				width: 140px;
				@media screen and (max-width: 767px) {
					width: 46.5%;
				}
				button {
					width: 100%;
					max-width: 100%;

					&.disabled {
						pointer-events: none;
					}
				}
			}

			&.couponsection {
				.left-content {
					.billing-coupon-code {
						.rowcoupons {
							.item-details {
								border-bottom: 0;
							}
							.discount {
								letter-spacing: 0;
								padding: 8px 0;
								.plcc-incomplete {
									margin-bottom: 10px;
								}
								.see-details {
									font-size: $base-font;
									font-family: $HelveticaBold;
									text-transform: none;
									color: $black;
									float: none;
									background: transparent;
									padding: 0;
								}
							}
						}
					}
				}
				.right-content {
					.couponCode {
						&.error-handle {
							label {
								.label-text {
									display: inline-block;
								}
							}
						}
					}
				}
			}
		}
	.form-row {
		&.addToAddressBook {
			width: 100%;
		}
	}
	.form-row-button {
		&.billing-page-continue {
			padding: 21px 6.4% 21px 7.3%;
			border-top: 4px solid $white;
			display: inline-block;
			width: 100%;
			box-sizing: border-box;
			margin: 0 0;

			button {
				float: right;
				border-radius: 0;
				font-family: $HelveticaBold;
			}
		}
	}

// Billing-payment-card section
.billing-cards-section {
	margin: 23px 0;

	.error-tender-rebuttal {
		width: 45%;
		float: right;
		font-size: $base-font;
		margin: 10px 0 0 0;
		text-align: left;
		color: $mangotango;

		b,
		strong {
			font-weight: normal;
			font-family: $HelveticaBold;
		}
	}

	.error-line {
		border: 0;
		background: none;
		padding: 0 0;
		margin-bottom: 10px;
		display: inherit;
		color: $RedRibbon;
		font-family: $Helvetica;
	}

	.form-indent {
		margin-left: 0;
		&.payment-method-options {
			.form-row {
				width: 100%;
			}
			&.paypal-radio .form-row:first-child {
				border-color: $alto;
				border-style: solid;
				border-width: 1px 0 1px 0;
				margin: 20px 0 20px 4.5%;
				padding: 20px 0;
				width: 96%;
				.paypal-wrapper {
					margin-left: -4.5%;
				}
			}
		}
	}
	.form-row {
		&.exp {
			margin: 0 0;
		}

		&.month,
		&.year,
		.billingfields .cvn {
			width: 46%;

			select {
				width: 100%;
			}
		}

		&.year {
			float: right;
		}

		&.cvn {
			width: 133px;
			float: left;
			&.error-handle {
				.input-text {
					outline: 3px solid $promo-red;
				}
			}
			.form-caption {
				width: 100%;
				margin: 0;
			}

			.field-wrapper {
				padding-left: 0;
				width: 100%;
			}

			.form-field-tooltip {
				padding: 0 0;

				a {
					position: absolute;
					right: 14px;
					top: 29%;
					font-size: 0;
					background: url("../../images/tips.svg") center no-repeat;
					width: 16px;
					height: 16px;
					background-size: cover;
				}
			}

			&:not(.label-inline) {
				label {
					.input-focus span {
						font-size: $base-font;
					}
					&.error {
						font-size: $base-font;
						-moz-transform: translate(3px, 12px);
						-webkit-transform: translate(3px, 12px);
						transform: translate(3px, 12px);
					}
				}
			}
			.error-tender-rebuttal {
				width: 41%;
				float: right;
				font-family: $Helvetica;
				font-size: $base-font;
				padding: 10px 0 0 0;
				color: $tulip-tree;

				@media screen and (max-width: 767px) {
					width: 100%;
					padding: 0 0 10px 0;
				}

				b {
					font-weight: normal;
					font-family: $HelveticaBold;
				}
			}
		}
	}
	legend {
		&.paywith {
			padding: 0 0;
			border-bottom: 0;
			margin-bottom: 38px;
		}
	}

	.cvv-label {
		position: relative;
		float: left;

		.question-mark-icon {
			position: absolute;
			left: -22px;
			top: 17px;
			font-size: 0;
			background: url("../../images/tips.svg") center no-repeat;
			width: 16px;
			height: 16px;
			background-size: cover;

			@media screen and (width: 1024px) and (orientation: landscape) {
				left: -32px;
			}
		}
		.content-asset {
			img {
				width: 100%;
				height: 48px;
				max-width: 145px;
				@media screen and (width: 1024px) and (orientation: landscape) {
					margin-left: -11px;
				}
			}
		}
	}
	.selected-info,
	.selected {
		.cvv-msg {
			@media screen and (max-width: 767px) {
				display: inline-block;
			}
		}
		.cvv-label {
			.content-asset {
				img {
					@media screen and (max-width: 1024px) and (min-width: 768px) and (orientation: landscape) {
						margin-left: 0;
					}
				}
			}
		}
	}
	.cvv-msg {
		font-size: $base-font + 1px;
		font-family: $HelveticaBold;
		line-height: 50px;
		margin-left: 15px;
		text-transform: capitalize;
		letter-spacing: 0.7px;
	}

	.payment-method-options {
		.form-row {
			text-transform: uppercase;
			font-family: $HelveticaBold;
			font-size: $base-font + 2px;
			line-height: 14px;
			letter-spacing: 1.5px;
			margin: 0 0;

			label {
				margin-left: 14px;
				color: $black;
				font-family: $HelveticaBold;
				line-height: 14px;
			}
			.required-field {
				font-family: $Helvetica;
				display: inline;
				color: $promo-red;
				font-size: $base-font + 1;
				font-weight: 500;
				text-transform: none;
				@media screen and (max-width: 767px) {
					display: block;
					padding: 10px 0px 0px;
					margin-top: 20px;
				}
				&.hide {
					display: none;
				}
			}
		}
		&.apple-pay-radio {
			.form-row {
				margin-top: 20px !important;
				padding-top: 20px;
				&:before {
					content: "";
					border-bottom: 1px solid $alto;
					position: absolute;
					right: 0;
					top: 0;
					width: 96%;
				}
				.field-wrapper {
					margin-top: 14px;
					padding-left: 4.5%;

					input#is-apple-pay {
						position: absolute;
						left: 0;
					}
				}
				.apple-pay-icon {
					cursor: pointer;
					margin-left: 0;
				}
			}
		}
	}

	.card-count {
		width: 100%;

		span {
			font-family: $Helvetica;
			font-size: $base-font + 1px;

			&.newcreditcard {
				float: right;
				font-family: $HelveticaBold;
				text-transform: uppercase;
				letter-spacing: 1px;
				cursor: pointer;
			}
		}
	}
	.payment-method {
		padding: 20px 0 0 4.5%;
		&.credit-auth {
			@media screen and (min-width: 767px) {
				padding-left: 0;
				margin: 0 0 0 4.5%;
			}
			@media screen and (max-width: 767px) {
				margin-bottom: 25px;
			}
		}
		.billingfields {
			.cvn {
				width: 46%;

				input {
					width: 100%;
				}
			}
			.enter-card-detials-wrapper {
				display: inline-block;
				width: 100%;

				.left-cont {
					float: left;
					max-width: 310px;
					width: 51%;

					.field-wrapper {
						width: 100%;
						padding-left: 0;
					}
					.form-row {
						&.error-handle {
							.selected-option,
							.input-text,
							.input-select {
								outline: 3px solid $promo-red;
							}
						}
						label {
							span {
								text-transform: none;
							}
						}
					}
				}

				.right-cont {
					float: right;
					max-width: 303px;
					width: 47%;

					.billing-payment-description {
						width: 100%;
						float: left;

						.heading {
							font-size: $base-font + 2px;
							font-family: $Helvetica;
						}

						.payment-cards {
							border-bottom: 1px solid $alto;
							padding: 17px 0;

							img {
								padding-right: 15px;

								&:last-child {
									padding-right: 0;
								}
							}
						}

						.brand-cards {
							span {
								font-size: $base-font + 2px;
								font-family: $Helvetica;
								margin: 20px 0 7px;
								display: inline-block;
								width: 100%;
							}
							ul {
								margin: 0;
								padding: 0;
								@media screen and (max-width: 767px) {
									min-height: 114px;
								}

								li {
									list-style-type: none;
									float: left;
									max-width: 97px;

									img {
										width: 100%;
									}
								}
							}
						}
					}
				}
				.card-actions {
					width: 100%;

					.checkboxes {
						float: left;
						width: 70%;

						span {
							font-size: $base-font + 1px;
							color: $black;
							font-family: $Helvetica;
						}

						label {
							margin: 3px 0 0 0;
							position: static;
							transform: none;
						}

						label.input-focus {
							transform: none;
						}
					}
					.address-actions {
						margin: 0 0;
					}
				}
			}
		}
	}
	.card-list {
		margin-top: 20px;

		.showall {
			text-align: center;
			width: 100%;
			display: inline-block;
			margin-top: 10px;
			letter-spacing: 1px;
			font-size: $base-font - 1px;
			font-family: $HelveticaBold;
		}
	}
	.cardinfo {
		width: 100%;
		clear: both;
		display: inline-block;

		.plcc-tooltip {
			width: auto;
			float: right;
			margin: 6px 0 0 0;

			@media screen and (max-width: 767px) {
				margin: 0;
				width: 4.5%;
			}

			.question-mark-icon {
				background: url(../../images/tips.svg) no-repeat;
				width: 16px;
				height: 16px;
				background-size: cover;
				padding: 0;
				margin: 0;
				display: inline-block;
				text-indent: -9999px;
				cursor: pointer;
			}
		}

		.carddetails {
			background: $white;
			margin-bottom: 10px;
			padding: 11px 13px 10px;
			box-sizing: border-box;
			border: 1px solid $nobel;
			font-size: $base-font + 1px;
			font-family: $Helvetica;
			letter-spacing: 0.2px;
			display: inline-block;
			width: 100%;

			&.plcc-message-card {
				border: none;
				margin-bottom: 0;

				.plcc-message {
					font-size: $base-font;
					font-family: $Helvetica;
					b {
						font-family: $HelveticaBold;
					}
				}
			}

			div.li {
				width: auto;
				display: inline-block;
				margin: 6px 0 0 0;
			}

			.li.cardtype {
				width: 34.3%;
				font-family: $HelveticaBold;
				margin: 0 0;
				vertical-align: top;

				span.img {
					background: url("../../images/payment-sprite-image.png") no-repeat;
					width: 35px;
					height: 24px;
					display: inline-block;
					float: left;

					&.amex {
						background-position: -107px -3px;
						width: 37px;
					}
					&.applepay {
						background-position: -249px -3px;
						width: 36px;
					}
					&.discover {
						background-position: -157px -3px;
					}
					&.mastercard {
						background-position: -59px -3px;
					}
					&.paypal {
						background-position: -203px -3px;
						width: 36px;
					}
					&.visa {
						background-position: -9px -3px;
					}

					&.bh_plcc, &.BH_PLCC {
						background-position: -246px -34px;
						width: 37px;
					}
					&.ca_plcc, &.CA_PLCC {
						background-position: -157px -34px;
					}
					&.fb_plcc, &.FB_PLCC {
						background-position: -9px -34px;
					}
					&.jl_plcc, &.JL_PLCC {
						background-position: -203px -34px;
					}
					&.ks_plcc, &.KS_PLCC {
						background-position: -290px -34px;
					}
					&.rm_plcc, &.RM_PLCC {
						background-position: -109px -34px;
					}
					&.ww_plcc, &.WW_PLCC {
						background-position: -60px -34px;
					}
				}

				.cardname {
					line-height: 26px;
					margin-left: 10px;
				}
			}

			.li.cardholder {
				width: 25.4%;
				vertical-align: top;
				overflow: hidden;
				text-overflow: ellipsis;

				@media screen and (max-width: 1024px) {
					width: 21.4%;
				}
			}

			.rewardPoints {
				.rewardpoints-value {
					font-family: $HelveticaBold;
					font-size: $base-font + 1px;
				}

				&.rewardpoints-along-text {
					margin: 0;

					.rewards-text {
						display: block;

						@media screen and (max-width: 767px) {
							display: inline;
							margin: 0 0 0 4px;
						}
					}
				}
			}

			.li.cardnumber {
				width: 22%;

				@media screen and (max-width: 1024px) {
					width: 21%;
				}

				@media screen and (max-width: 767px) {
					width: 100%;
				}

				.cardnumber-value {
					font-family: $HelveticaBold;
					font-size: $base-font + 1px;
				}

				&.cardnumber-along-text {
					margin: 0;

					.available-credit {
						display: block;
					}

					@media screen and (max-width: 767px) {
						margin: 6px 0 6px 0;
						width: auto;

						.available-credit {
							display: inline;
							margin: 0 0 0 4px;
						}
					}
				}

				&.insufficient {
					color: $redmonza;
					font-family: $HelveticaBold;

					.available-credit {
						font-family: $Helvetica;
					}
				}
			}
			.li.expdate {
				float: right;
			}

			&.selected {
				border: 3px solid $black;
			}

			&.expired {
				.expdate {
					color: $RedRibbon;
				}
			}

			&.expires {
				.expdate {
					color: $RedRibbon;
				}
			}
		}
	}
	.insufficient-funds {
		margin: 0 0 20px 0;
		display: inline-block;
		font-size: $base-font;
		font-family: $Helvetica;
		color: $mangotango;
	}
}

// Billing page selection of radio buttons.

.paypal-radio {
	.form-row {
		width: auto;

		.field-wrapper {
			margin-top: 11px;
		}
	}
	.paypal-radio-image {
		background: url("../../images/paypal-logo135x38.png") center no-repeat;
		width: 140px;
		height: 38px;
		margin-left: 13px;
		display: inline-block;
	}
	&.paypal-disable {
		cursor: not-allowed;
		pointer-events: none;
		opacity: 0.2;
	}
	.paypal-radio-button img {
		margin-left: 10px;
	}
}

.paypal-radio-message,
.giftcard-radio-message {
	padding: 7px 7.3%;
	border-top: 4px solid #ffffff;
	width: 100%;
	box-sizing: border-box;
	color: $tuliptree;
	display: none;
	&.paypal-combo {
		display: inline-block;
	}
	@media screen and (max-width: 767px) {
		padding: 7px 3.3%;
	}
}

.giftcard-radio-message {
	display: block;
}
.selectpayment {
	&.paypal-selected.hide-paypal-combo {
		.billing-coupon-code {
			.rewardcertificate,
			.giftcardsection {
				display: none;
			}
		}
	}
}
.selectpayment {
	&.applePay-selected,
	&.paypal-selected.hide-paypal-combo {
		.paypal-radio-message {
			display: block;
		}
		.payment-method-expanded {
			padding-top: 10px;
			.paypal_selected_account_email {
				padding-bottom: 15px;
			}
			.form-row.useAnotherAccount {
				.input-checkbox {
					margin-right: 11px;
				}
				label span {
					color: $black;
				}
			}
		}
	}
}

//Items in bag section
.items-in-bag {
	&.saved-address-block {
		border-bottom: 0;
		border-top: none;

		.head-wrapper {
			border: 0;
			margin: 51px 0 11px;
			padding: 0 0;

			legend {
				background: none;

				h3 {
					font-size: inherit;
					font-weight: inherit;
					display: inline;
					text-transform: inherit;
					letter-spacing: inherit;
					font-family: inherit;
				}
			}
		}
	}
}

.redemption {
	.success {
		color: $islamic-green;
	}
	span.error {
		margin-left: 0;
	}
}

.payment-method-options {
	.form-row {
		clear: none;
		float: left;
		width: 31%;
	}
}

.payment-method {
	clear: both;
	display: none;

	&.credit-auth {
		display: block;
	}
}

.payment-method-expanded {
	display: block;
}

.month,
.year,
.day {
	clear: none;
	float: left;
	margin: 0 0 2% 2%;
	label {
	}
	select {
		width: auto;
	}
}

.month .form-caption {
	margin-left: 0;
}

.cvn {
	clear: left;
	.field-wrapper {
		width: 50px;
	}
}

.ssn {
	clear: left;
}

.bml-terms-and-conditions {
	border: 1px solid $dark-gray;
	font-size: 0.8em;
	height: 400px;
	overflow: auto;
	padding: 1em;
}

.bml-table-col-1 {
	width: 25%;
}

.pt_checkout h3 {
	padding-bottom: 1em;
}

.checkout-mini-cart {
	background-color: $white-smoke;
	max-height: 330px;
	overflow: auto;
}

.checkout-order-summary {
	max-width: 100%;
	width: 100%;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;

	@media screen and (min-width: 1024px) and (max-width: 1259px) {
		right: 20px;
	}

	@media screen and (max-width: 1023px) {
		.order-summary-footer {
			width: 100%;
			display: inline-block;
			margin: 10px 0 20px 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			.required-fields-warn {
				width: auto;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.order-summary-footer {
			padding: 10px 0 22px 0;
			margin: 0;
		}
	}

	.apple-pay-container {
		min-width: 300px;
	}

	.order-summary-footer-ctas {
		margin-top: 5px;
	}

	.review-disclaimer {
		margin: 20px 0 0 0;
		font-size: $base-font;
		font-family: $Helvetica;

		@media screen and (max-width: 1023px) {
			width: 50%;
			float: left;
			line-height: 20px;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}

	.form-row {
		padding: 0;
		margin: 16px 0 0 0;

		&.paypalCheckoutFromCart {
			margin: 0px !important;
		}

		.button-fancy-large {
			width: 100%;
			margin: 0;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			border: 0;
			color: $white;
			letter-spacing: 2px;
			padding: 18px 20px;

			@media screen and (max-width: 1023px) {
				width: 270px;
				float: right;
			}

			@media screen and (max-width: 767px) {
				width: 100%;
			}
			&.rightbutton {
				padding: 18px 35px;
				letter-spacing: 1px;
			}
		}

		@media screen and (max-width: 1023px) {
			width: 50%;
			padding: 0;
			float: left;
			display: inline-block;
			clear: none;
		}

		@media screen and (max-width: 767px) {
			width: 100%;
			margin: 25px 0 20px 0;
		}
	}

}
.secure-message {
	&:before {
		content: "";
		background: url(../../images/lock-icon.png) no-repeat 0 0;
		width: 16px;
		height: 18px;
		padding: 0;
		margin: 0 10px 0 0;
		display: inline-block;
		vertical-align: bottom;
	}

	@media screen and (max-width: 1023px) {
		width: 100%;
		float: left;
		text-align: left;
	}
}

.tax-disclaimer {
	color: $dim-gray;
	font-size: 11px;
	padding: 10px 0 0 12px;
	clear: both;
	line-height: 150%;
}

.int-disclaimer {
	color: $reddish-monza;
	font-size: 11px;
	padding: 10px 0 0 0;
	clear: both;
	line-height: 150%;
}

.checkout-order-totals {
	background: $white;
	padding: 20px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-shadow: -2px 6px 20px 8px rgba(239, 239, 239, 1);
	-moz-box-shadow: -2px 6px 20px 8px rgba(239, 239, 239, 1);
	box-shadow: -2px 6px 20px 8px rgba(239, 239, 239, 1);

	.summary-heading {
		margin: 0 0 16px;
	}

	.order-detail {
		width: 100%;
		display: inline-block;

		&:not(.remaining-total) {
			padding: 0 0 11px 0 !important;
		}

		&.order-total {
			padding: 16px 0 11px 0 !important;
		}

		&.order-coupons {
			.label {
				text-transform: none;
			}
		}

		.label {
			width: 61%;
			float: left;

			.shippingoverlay-link {
				width: 16px;
				padding: 0;
				margin: 0;
				display: inline-block;
				text-indent: -9999px;
				cursor: pointer;
			}
		}

		.value {
			width: 39%;
			float: right;
			text-align: right;
		}
		&.discount {
			color: $guardsman-Red;
			span,
			.label,
			.value {
				color: $guardsman-Red;
			}
		}
		&.order-total {
			border-top: 1px solid $alto;
			margin-top: 5px !important;

			.value {
				font-family: $HelveticaBold;
			}

			&.order-total-remaining {
				border-top: 0;

				.value {
					font-family: $Helvetica;
				}
			}
		}

		&.remaining-total {
			border-top: 2px solid $alto;

			.value {
				font-family: $HelveticaBold;
			}
		}
	}
}
.tooltip-hidden {
	display: none;
}
.add-to-emaillist-warningmsg {
	background-color: $black;
	color: $white;
	margin-top: 10px;
	text-align: center;
	position: relative;
	line-height: 40px;
	font-size: $base-font - 2px;
	font-weight: normal;
	font-family: $Helvetica;
	@media screen and (max-width: 767px) {
		font-size: $base-font - 2px;
	}
	.vertical-bar {
		&:before {
			content: "|";
			font-size: $base-font + 8px;
			margin-left: 5px;
		}
	}
	.arrow-up {
		position: absolute;
		z-index: 20;
		top: -20px;
		width: 0px;
		height: 0px;
		z-index: 0;
		border-left: 15px solid transparent;
		border-right: 15px solid transparent;
		border-bottom: 30px solid $black;
		left: 60px;
	}
	.checkmark {
		display: inline-block;
		position: relative;
		width: 25px;
		height: 23px;
		top: 6px;
		background: url("../../images/check-white.svg") no-repeat center;
		margin-left: 5px;
	}
}

.email-container-block {
	float: left;
	width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.login-create-account {
		.add-to-emaillist-warningmsg {
			margin-left: -20px;
			margin-right: -20px;
			padding: 10px;
			line-height: 20px;
			.arrow-up {
				left: 15px;
			}
		}
	}
	.checkoutlogin {
		.add-to-emaillist-warningmsg {
			margin: 10px -34px 15px;
			span {
				font-size: $base-font - 2px;
				letter-spacing: 0;
			}
			.arrow-up {
				left: 28px;
			}
		}
	}
}
@media screen and (min-width: 1024px) {
	.login-create-account {
		.add-to-emaillist-warningmsg {
			margin-left: -65px;
			margin-right: -70px;
		}
	}
	.checkoutlogin {
		.add-to-emaillist-warningmsg {
			margin: 10px -55px 15px;
			.arrow-up {
				left: 48px;
			}
		}
	}
}
@media screen and (width: 1024px) {
	.checkoutlogin {
		.add-to-emaillist-warningmsg {
			margin: 10px -40px 15px;
			line-height: 24px;
			padding-left: 2px;
			padding-right: 2px;
			span {
				font-size: $base-font - 3px;
				letter-spacing: 0;
			}
			.vertical-bar {
				&:before {
					margin-left: 0px;
				}
				.checkmark {
					margin-left: 2px;
				}
			}
			.arrow-up {
				left: 34px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.checkoutlogin {
		.email-container-block {
			margin-bottom: 20px;
		}
	}
}

.spc-summary,
.spc-express-checkout {
	.place-order {
		.left-section {
			.form-row {
				.add-to-email-list {
					text-align: left;
					.subscribeToEmail,
					div.content-asset {
						display: inline-block;
						float: none;
						margin-top: 10px;
						vertical-align: top;
					}
					.vertical-bar {
						&:before {
							content: "|";
							font-size: $base-font + 8px;
							margin-left: 10px;
						}
					}
					.add-to-emaillist-warningmsg {
						background-color: $black;
						color: $white;
						margin-top: 34px;
						text-align: center;
						position: relative;
						min-width: 500px;
						right: 0px;
						line-height: 40px;
						@media screen and (min-width: 768px) {
							position: absolute;
						}
						@media screen and(min-width:768px) and (max-width: 1024px) {
							min-width: 350px;
						}
						@media screen and(width:1024px) and (orientation: landscape) {
							margin-top: 8px;
						}
						.arrow-up {
							position: absolute;
							z-index: 20;
							top: -16px;
							width: 0px;
							height: 0px;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-bottom: 16px solid $black;
							left: 173px;

							@media screen and (min-width: 768px) and (max-width: 1024px) {
								left: 25px;
							}
							@media screen and(width:1024px) and (orientation: landscape) {
								left: 38px;
							}
						}
						.checkmark {
							display: inline-block;
							position: relative;
							width: 25px;
							height: 23px;
							top: 6px;
							background: url("../../images/check-white.svg") no-repeat center;
							margin-left: 10px;
						}
						span {
							font-size: $base-font + 3px;

							@media screen and (max-width: 1024px) {
								font-size: $base-font - 2px;
							}
						}
					}
				}
			}
		}
	}
}
.spc-summary,
.spc-express-checkout {
	.add-to-email-list {
		div.content-asset {
			margin-right: 3px;
			margin-left: 1%;
		}
	}
}
.spc-summary {
	.add-to-email-list {
		.subscribeToEmail {
			@media screen and (max-width: 767px) {
				width: 6%;
				float: left;
			}
		}
	}
}

#secondary {
	&.summary {
		position: sticky;
		top: 50px;
		@media screen and (max-width: 1023px) {
			position: static;
			top: auto;
		}
	}

	.checkout-ecoship-container {
		border: black 2px solid;
		padding: 8px;
		padding-left: 11px;
		padding-bottom: 0;
		margin-bottom: 16px;

		.ecoship-title {
			margin-bottom: 10px;

			.ecoship-tagline {
				font-size: 14px;
			}
		}

		.ecoship-icon {
			width: 20px;
			height: 18px;
			max-width: none;
			margin-right: 6px;
			vertical-align: text-bottom;
		}

		.ecoship-field-heading {
			display: inline;
			font-size: 16px;
			font-family: $sans-serif !important;
		}

		/* Hide the default checkbox appearance */
		.custom-control .ecoship-checkbox-input {
			position: absolute;
			opacity: 0;
			z-index: -1;
		}

		/* Style the label to display the custom checkbox */
		.custom-control .ecoship-checkbox-label {
			position: relative;
			cursor: pointer;
			padding-left: 26px;
		}

		/* Create a custom checkbox using the label's ::before pseudo-element */
		.custom-control .ecoship-checkbox-label::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 15px;
			height: 15px;
			background-color: white;
    		border: 1px solid $dark-grey;
			border-radius: 4px;
		}

		.custom-control .ecoship-checkbox-input:checked + .ecoship-checkbox-label::before {
			background-color: $cobalt-blue;
			border: 1px solid $cobalt-blue;
		}

		.custom-control .ecoship-checkbox-input:checked + .ecoship-checkbox-label::before {
			background: url("../../images/icon-checkmark.svg") no-repeat center;
			background-color: $cobalt-blue;
		}


		.ecoship-checkbox-label {
			font-size: 14px;
		}

		p {
			margin-top: 10px;
			font-size: 12px;
			color: $mortar-grey;
			font-family: $sans-serif !important;
			margin-left: 27px;
		}
	}

	.checkout-order-summary {
		.order-summary-footer {
			.form-row {
				.add-to-email-list {
					text-align: left;
					.subscribeToEmail,
					div.content-asset {
						display: inline-block;
					}
					div.content-asset {
						margin-left: 1%;
						&.wrapper-add-to-email {
							display: block;
						}
					}
					.subscribeToEmail {
						vertical-align: middle;
						width: 14px;
						margin: 0 0 2px 0;
					}
					@media screen and(width:1024px) {
						text-align: left;
					}

					.vertical-bar {
						&:before {
							content: "|";
							font-size: $base-font + 4px;
						}
					}
					.add-to-emaillist-warningmsg {
						background-color: $black;
						color: $white;
						padding: 2px 7px 15px;
						position: relative;
						text-align: center;
						letter-spacing: 0;
						line-height: 7px;
						font-size: $base-font - 1px;
						margin-top: 8px;

						@media screen and(min-width:768px) and (max-width: 1023px) {
							position: absolute;
							min-width: 330px;
							right: 3px;
							margin-top: 14px;
						}
						@media screen and(width:1024px) and (orientation: landscape) {
							padding: 2px 2px 10px;
							width: 100%;
						}
						.arrow-up {
							position: absolute;
							z-index: 20;
							top: -10px;
							width: 0px;
							height: 0px;
							border-left: 8px solid transparent;
							border-right: 8px solid transparent;
							border-bottom: 16px solid $black;
							left: 0px;

							@media screen and(min-width:768px) and (max-width: 1024px) {
								left: 22px;
							}
							@media screen and(width:1024px) and (orientation: landscape) {
								left: 0px;
							}
						}
						.checkmark {
							background: url("../../images/check-white.svg") no-repeat center;
							width: 20px;
							height: 20px;
							display: inline-block;
							position: relative;
							top: 5px;
							@media screen and(width:1024px) and (orientation: landscape) {
								margin-left: 7px;
							}
						}
						span {
							font-size: $base-font - 3px;
							@media screen and(max-width:767px) {
								font-size: $base-font - 2px;
							}
						}
					}
				}
			}

			@media screen and(min-width:768px) and (max-width: 1024px) {
				.form-row {
					text-align: right;
					.add-to-email-list {
						div.content-asset {
							margin-left: 2px;
						}
						.wrapper-add-to-email {
							margin-left: 0;
						}
					}
				}
			}
			@media screen and(min-width:768px) and (max-width: 1024px) and (orientation: landscape) {
				.review-disclaimer {
					width: 100%;
				}
				.form-row {
					.add-to-email-list {
						div.content-asset {
							letter-spacing: 0;
						}
					}
				}
			}
		}
		&.checkout-step-0 {
			.summary-continue-button-container {
				display: none;
			}
		}
	}

	#checkoutcouponsection {
		.promotion-field-container {
			margin: 0 12px;
			.promotion-field-hr {
				display: none;
			}
			.promotion-field-heading {
				font-size: 16px;
				margin-bottom: 2px;
			}
		}

		.tips p {
			margin: 0;
		}

		.checkout-coupon-code-form {
			margin: 8px auto 8px auto;
			width: 100%;
		}

		.form-label-group {
			label {
				font-size: 16px;
			}

			.form-control {
				padding: 0.75rem 0.75rem;
				border-radius: 0;
				&:not(:placeholder-shown) {
					padding-top: 1.1rem;
					padding-bottom: 0.1rem;
				}
				&:focus ~ label {
					font-size: .8rem;
				}
			}
		}

		.row {
			display: flex;
			flex-wrap: wrap;
			margin-right: -12px;
			margin-left: -12px;
		}

		.col-7 {
			flex: 0 0 58.33333%;
			max-width: 58.33333%;
		}

		.col-5 {
			flex: 0 0 41.66667%;
			max-width: 41.66667%;
			padding: 0 0 0 8px;
			box-sizing: border-box;

			.btn-outline-primary {
				border-radius: 3px;
			}
		}

		.alert {
			border: 2px solid black;
			color: #000;
			position: relative;
			padding: 0.75rem 30px 0.75rem 50px;

			&.alert-danger {
				background-color: white;
				border-color: #b50000;
			}

			.coupon-error-message {
				word-break: break-all;
			}
		}

		.error-messages {
			margin: 1.5rem auto;
		}

		.appliedCouponRewards {
			margin: 1.5rem auto;

			.cartcoupon {
				display: flex;
				justify-content: space-between;
				margin-bottom: 5px;

				.promoApplied {
					svg {
						fill: #0DA508;
						position: absolute;
						width: 20px;
						height: 20px;
					}

					.promoAppliedValue {
						margin-left: 30px;
						font-size: 16px;
					}
				}
			}

			.discount-item-details .discount {
				font-size: 14px;
				margin-bottom: 5px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.add-to-emaillist-warningmsg {
		letter-spacing: 0.5px;
		.arrow-up {
			top: -8px;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 8px solid #000000;
			left: 4px;
		}
		span {
			letter-spacing: 0;
		}
		.vertical-bar {
			&:before {
				margin-left: 0px;
				font-size: $base-font + 3px;
			}
			.checkmark {
				margin-left: 0;
				width: 18px;
				height: 18px;
				top: 5px;
			}
		}
	}
	.spc-summary,
	.spc-express-checkout {
		.place-order {
			.order-summary-footer {
				.left-section {
					.add-to-email-list {
						.subscribeToEmail {
							width: 5%;
							float: left;
							vertical-align: top;
						}

						div.content-asset {
							width: 92%;
							text-align: left;
							margin-left: 1%;
							word-break: break-word;
							margin-right: 0;
							font-size: $base-font - 1px;
							letter-spacing: 0.5px;

							&.wrapper-add-to-email {
								width: 100%;
								margin-left: 0;
							}
						}
						.add-to-emaillist-warningmsg {
							min-width: auto;
							line-height: 7px;
							font-size: $base-font - 2px;
							.arrow-up {
								top: -8px;
								border-left: 5px solid transparent;
								border-right: 5px solid transparent;
								border-bottom: 8px solid #000000;
								left: 4px;
							}
							span {
								letter-spacing: 0;
							}
							.vertical-bar {
								&:before {
									margin-left: 0px;
								}
								.checkmark {
									margin-left: 0;
									width: 20px;
									height: 20px;
									top: 5px;
								}
							}
						}
					}
					fieldset {
						.form-row {
							width: 100%;
							float: none;
						}
					}
				}
			}
		}
	}
	#secondary {
		.checkout-ecoship-container {
			margin: auto;
			width: fit-content;
			margin-bottom: 40px;
	
			.ecoship-tagline {
				font-size: 14px;
			}
	
			.ecoship-checkbox-label {
				font-size: 14px;
			}
		}
		
		.checkout-order-summary {
			.order-summary-footer {
				.form-row {
					width: 100%;
					.add-to-email-list {
						.subscribeToEmail {
							vertical-align: middle;
						}
						div.content-asset {
							word-break: break-word;
							width: 92%;
							margin-left: 1%;
							margin-top: 0;
							font-size: $base-font - 1px;
							height: 20px;
							letter-spacing: 0.5px;
							vertical-align: middle;
							line-height: 20px;

							&.wrapper-add-to-email {
								width: 100%;
								margin-left: 0;
							}
							&.wrapper-add-to-email:last-child, &.content-asset{
								@media only screen and (max-width: 767px){
									margin-top: 0;
									height: auto;
								}
							}
						}
						.add-to-emaillist-warningmsg {
							margin-top: 5px;
							font-size: $base-font - 2px;
							.arrow-up {
								top: -8px;
								border-left: 5px solid transparent;
								border-right: 5px solid transparent;
								border-bottom: 8px solid #000000;
								left: 4px;
							}
							span {
								letter-spacing: 0;
							}
						}
					}
				}
			}
		}
	}
	.spc-summary,
	.spc-express-checkout {
		.place-order {
			.left-section {
				.form-row {
					.add-to-email-list {
						.add-to-emaillist-warningmsg {
							padding: 0 0px 8px;
							margin-top: 15px;

							@media screen and (max-width: 767px) {
								margin-top: 7px;
							}
						}
					}
				}
			}
		}
	}
}

.spc-summary,
.spc-billing,
.spc-express-checkout {
	.billing {
		.checkout-tab-head {
			margin: -3px 0 0 0;

			.section-heading {
				margin: 26px 0 20px 0;
			}

			.details {
				padding: 37px 5.2% 70px 7.3%;
			}
		}
	}

	.review {
		.checkout-tab-head {
			margin: -11px 0 0 3px;

			.section-heading {
				margin: 23px 0 13px 0;
			}
		}
	}

	.checkout-tab-head {
		.section-heading {
			margin: 24px 0 14px 0;
			text-transform: capitalize;
			font-weight: normal;
			font-size: $base-font + 6px;
			font-family: $Helvetica;

			.edit-link {
				text-transform: uppercase;
				float: right;
				padding-right: 34px;
				line-height: 30px;
				cursor: pointer;
			}
		}

		.details {
			border-top: 1px solid $alto;
			padding: 35px 5.2% 50px 7.4%;
			background: $light-skygrey;

			&.block-section {
				&.e-giftcard-shipping-details {
					padding: 40px 65px;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					@media screen and (max-width: 1399px) {
						padding: 40px 34px 40px 32px;
					}
					@media screen and (max-width: 1023px) {
						padding: 40px 32px 46px 32px;
					}
					@media screen and (max-width: 767px) {
						padding: 17px 3.3%;
					}
				}
			}
			.inner-block {
				h3 {
					font-size: $base-font + 8px;
				}
			}

			@media screen and (max-width: 1024px) {
				padding: 37px 5.2% 37px 4.3%;
			}

			.mini-shipping,
			.delivery-options,
			.giftinfo {
				width: 33.33%;
				float: left;

				@media screen and (max-width: 767px) {
					width: 100%;
				}
			}

			.delivery-options {
				.inner-block {
					padding-left: 10px;

					@media screen and (max-width: 767px) {
						padding: 0;
						margin: 20px 0 26px;
					}
				}
			}

			.giftinfo {
				.inner-block {
					padding-left: 32px;

					@media screen and (max-width: 767px) {
						padding: 0;
					}
				}
			}

			.inner-block {
				max-width: 205px;

				@media screen and (max-width: 767px) {
					max-width: 100%;
				}

				h3 {
					padding: 0 0 13px 0;
					margin: 0;
					text-transform: capitalize;
					border-bottom: 1px solid $alto;
					font-weight: normal;
				}

				.address {
					padding: 17px 0 0 0;
					line-height: 18px;

					.default {
						padding: 0 0 20px 0;
						text-transform: capitalize;
						word-break: break-word;
					}
				}

				.minishipments-method {
					padding: 20px 0 0 0;
					line-height: 15px;

					.option-heading {
						text-transform: capitalize;
					}

					.promo {
						color: $mangotango;
					}
				}

				.info {
					display: inline-block;
					width: 100%;

					.label {
						float: left;
					}

					.value {
						float: right;
					}

					&.giftreceipt {
						padding: 10px 0;
						border-bottom: 1px solid $alto;
					}

					&.giftmessage {
						padding: 15px 0 10px 0;

						.value {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

.spc-billing {
	.checkout-tab-head .details .inner-block .address {
		padding-top: 18px;
		.default {
			padding-bottom: 7px;
		}
	}
	.checkout-tab.billing {
		.saved-address-block {
			.head-wrapper {
				margin-bottom: 0;
			}
		}
		.checkout-billing {
			.billing-cards-section {
				.payment-method {
					.billingfields {
						.enter-card-detials-wrapper {
							.left-cont {
								.form-row {
									.custom-select {
										select {
											background-position: right 5px center;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.spc-express-checkout {
	.checkout-tab-head {
		.details {
			&.block-section {
				padding: 0;

				.saved-address-block {
					border-top: 0;
				}
			}
		}
	}

	.order-summary-footer {
		.left-section {
			fieldset {
				width: 50%;
				float: right;

				.form-row {
					text-align: right;
					margin: 0;

					button {
						letter-spacing: 2px;
					}
				}
			}

			.review-disclaimer {
				width: 50%;
				float: left;
				line-height: 20px;
			}
		}
	}
}

.pt_checkout {
	.checkout-tab-content {
		&.place-order {
			background: $white;
		}
	}
	#expiry-month-error {
		width: 46%;
		float: left;
		position: relative;
		clear: none;
		margin-top: 0px;
		.text-left {
		  padding-left: 17px;
		}
	 }
	 #expiry-year-error {
		width: 46%;
		position: relative;
		float:right;
		clear:none;
		margin-top: 0px;
		.text-left {
		  padding-left: 17px;
		}
	 }
	 .address-section {
		.invalid-feedback {
			padding: 10px 0 10px 0;
			text-align: left;
			clear: both;
			font-family: $Helvetica;
			font-size: $base-font;
			color: $promo-red;
			width: auto;
			margin-left: auto;
			@media screen and (max-width: 767px) {
				width: 100%;
				padding: 10px 0px;
				text-align: center;
			}
		}
	 }
	.required-fields-warn,
	.required-shipping-warn {
		margin: 10px 0 10px 0;
		text-align: center;
		clear: both;
		font-family: $Helvetica;
		font-size: $base-font + 1px;
		color: $promo-red;
		margin-left: auto;
		@media screen and (max-width: 767px) {
			width: 100%;
			padding: 0;
		}
	}
	#primary {
		.required-fields-warn,
		.required-shipping-warn {
			text-align: right;
			@media screen and (max-width: 767px) {
				text-align: center;
			}
		}
	}
	.required-shipping-warn {
		text-align: right;
	}
}

.billing-right-section {
	width: 50%;
	float: left;
	padding: 0 0 0 54px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	@media screen and (max-width: 767px) {
		width: 100%;
		padding: 0;
	}

	.reward-certificate,
	.promo-code,
	.gift-card {
		max-width: 295px;

		@media screen and (max-width: 767px) {
			max-width: 100%;
			margin: 34px 0 0 0;
		}

		h3 {
			padding: 0 0 16px 0;
			margin: 34px 0 0 0;
			text-transform: capitalize;
			border-bottom: 1px solid $alto;
			font-weight: normal;
		}

		.detail {
			font-family: $HelveticaBold;
			padding: 3% 0 12% 0;
			line-height: 23px;

			@media screen and (max-width: 767px) {
				padding: 10px 0 0 0;
			}
		}
	}

	h3:first-of-type {
		margin: 0;
	}

	.promo-code,
	.gift-card {
		margin: 0;
		.payment-promo-code {
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		.promo-callout-message {
			font-family: $Helvetica;
		}
	}
}

.billing-left-section {
	width: 50%;
	float: left;

	@media screen and (max-width: 767px) {
		width: 100%;
	}

	.mini-payment-instrument {
		margin-top: 20%;

		&.paypal-payment {
			.detail {
				max-width: 100%;
			}
		}
		.detail {
			max-width: 205px;

			@media screen and (max-width: 767px) {
				max-width: 70%;
			}
			@media screen and (max-width: 360px) {
				max-width: 83%;
			}

			.tooptip-msg {
				letter-spacing: 0.5px;
				text-transform: none;
				float: left;
			}
			.differed-tooltip {
				background: url("../../images/tips.svg") center no-repeat;
				width: 20px;
				height: 20px;
				background-size: cover;
				padding: 0;
				margin: 4px 0 0 0;
				display: inline-block;
				float: left;
				margin-left: 10px;
			}
		}

		.deferred-payment {
			max-width: initial;

			.cc-number {
				max-width: 185px;

				@media screen and (max-width: 767px) {
					max-width: 70%;
				}

				@media screen and (max-width: 360px) {
					max-width: 83%;
				}
			}
		}
	}

	.mini-billing-address,
	.mini-payment-instrument {
		max-width: 295px;

		@media screen and (max-width: 767px) {
			max-width: 100%;
			margin: 0;
		}

		h3 {
			padding: 0 0 16px 0;
			margin: 0;
			text-transform: capitalize;
			border-bottom: 1px solid $alto;
			font-weight: normal;
		}

		.detail {
			padding: 10px 0 0 0;
			line-height: 25px;
			text-transform: capitalize;

			span.img {
				background: url("../../images/cards-sprite2.png") no-repeat;
				width: 35px;
				height: 24px;
				display: inline-block;
				float: right;

				&.visa {
					background-position: -59px -3px;
				}
				&.amex {
					background-position: -8px -3px;
					width: 37px;
				}
				&.mastercard {
					background-position: -108px -3px;
					width: 36px;
				}
				&.discover {
					background-position: -247px -3px;
				}
				&.JL_PLCC,
				&.jl_plcc {
					background-position: -157px -3px;
				}
				&.CA_PLCC,
				&.ca_plcc {
					background-position: -157px -35px;
				}
				&.WW_PLCC,
				&.ww_plcc {
					background-position: -203px -3px;
				}
				&.RM_PLCC,
				&.rm_plcc {
					background-position: -290px -3px;
				}
				&.fb_plcc {
					background-position: -59px -34px;
				}
				&.CA_PLCC,
				&.ca_plcc {
					background-position: -157px -35px;
				}
				&.bh_plcc {
					background-position: -10px -34px;
				}
				&.ks_plcc {
					background-position: -109px -34px;
				}
			}

			.cc-owner {
				font-family: $HelveticaBold;
			}
		}

		.address {
			margin: 0 0 23px 0;

			.email-address {
				text-transform: none;
			}
		}
	}
}

.place-order {
	.order-summary-footer {
		padding: 22px 0 22px 22px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		border-top: 1px solid $alto;

		@media screen and (max-width: 767px) {
			padding: 22px 0;
			.left-section {
				fieldset {
					width: 100%;

					.form-row {
						width: 100%;
						button {
							width: 100%;
						}
					}
				}
			}
		}

		.left-section,
		.right-section {
			width: 100%;
			display: inline-block;

			.review-disclaimer {
				width: 50%;
				float: left;
				line-height: 20px;

				@media screen and (max-width: 767px) {
					width: 100%;
					margin: 25px 0 20px 0;
					max-width: none;
				}
			}

			.submit-order {
				width: 50%;
				padding: 0;
				float: right;
				display: inline-block;
				clear: none;

				@media screen and (max-width: 767px) {
					width: 100%;
				}

				.form-row {
					margin: 0;
				}
			}

			.secure-message {
				width: 50%;
				float: right;
				margin: 6px 0 0;

				@media screen and (max-width: 767px) {
					width: 100%;
				}

				&:before {
					content: "";
					background: url(../../images/lock-icon.png) no-repeat 0 0;
					width: 16px;
					height: 18px;
					padding: 0;
					margin: 0 10px 0 0;
					display: inline-block;
				}
			}
		}
	}
}

.place-order-totals {
	clear: both;
	.order-totals-table {
		float: right;
		width: 50%;
	}
	.content-asset {
	}
}

.order-component-block {
	margin: 1.67em 0;
	.section-header a {
		float: right;
		font-weight: normal;
	}
	.details {
		background: none repeat scroll 0 0 $gainsboro;
		color: $dark-gray;
		padding: 0.5em 1em;
	}
}

.order-summary-footer {
	position: relative;
	h2 {
		font-size: 2em;
		font-style: italic;
		font-weight: normal;
	}
	.button-fancy-large {
		width: 100%;
	}
}

.summary-help {
	float: right;
	font-size: 0.8em;
	margin: 1em 0;
}

.submit-order {
	clear: both;
	display: block;
	padding: 1.67em 0 0;
	text-align: right;
	.back-to-cart {
		font-size: 1.1em;
		&:hover {
			text-decoration: underline;
		}
	}
}

.order-discount {
	color: $sangria;
}

.gift-cert-used {
	padding: 0.83em 0;
}

.summary .edit {
	position: absolute;
	text-transform: uppercase;
	right: 10px;
	font-size: 0.9em;
	line-height: 1.5em;
}

.pt_cart #main {
	padding: 0;

	@media screen and (max-width: 1023px) {
		margin: 0;
	}
}
.item-shipping-address {
	min-width: 120px;
}

.billing-error {
	color: red;
	margin: 0 0 0.83em 24%;
}

.cvn-tooltip {
	font-weight: bold;
	margin: 5px;
	text-align: center;

	img {
		height: 150px;
		width: 110px;
	}
}
.pt_checkout .shipping-section span.shippingmethod-exception {
	color: $mangotango;
	width: 100%;
	display: inline-block;
}
#shipping-method-list {
	position: relative;
	.shipping-promos {
		display: block;
		span {
			display: block;
		}
	}

	.form-caption {
		clear: both;
	}
}

.billing-coupon-code {
	.form-row {
		label {
			span {
				font-family: $Helvetica;
			}
		}
	}
	button {
		font-family: $HelveticaMedium, $sans-serif;
		font-size: $base-font + 2px;
		letter-spacing: 2px;
	}
}

.giftinfo {
	&.giftinfo-jl {
		display: none;
	}
}

//cvv tooltip design
.cvv-tooltip {
	&.ui-dialog {
		padding: 7px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		border-color: $silver;

		.ui-icon-closethick {
			background: url("../../images/x-icon-gray16.svg") no-repeat;
			height: 16px;
			width: 16px;
		}
		.ui-widget-header {
			height: 14px;
		}
		.ui-dialog-titlebar-close {
			height: 16px;
			width: 16px;
			display: block !important;
		}

		&.tooltip-dialog {
			.ui-dialog-title {
				margin: 0 0;
				padding: 0 0;
			}
			.ui-dialog-content {
				padding: 0 13px;
			}
		}
		.cvv-header1 {
			font-family: $Helvetica;
			margin-bottom: 0;
		}
	}
}

.differed-tooltip {
	&.ui-dialog {
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;

		.ui-icon-closethick {
			background: url("../../images/X_mobile2.svg") no-repeat;
			height: 28px;
			width: 28px;
			top: initial;
			bottom: -13px;
			background-size: 100% 100%;
		}
		.ui-widget-header {
			height: 32px;
		}
		.ui-dialog-titlebar-close {
			height: 40px;
		}

		&.tooltip-dialog {
			.ui-dialog-title {
				margin: 0 0;
				padding: 0 0;
			}
			.ui-dialog-content {
				padding: 0 70px 40px;
				font-size: $base-font + 1px !important;
				color: $black;
				font-family: $Helvetica !important;
				line-height: 20px;

				@media screen and (max-width: 1023px) {
					padding: 0 40px 30px;
				}

				@media screen and (max-width: 767px) {
					padding: 0 20px 20px;
					line-height: 18px;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1259px) {
	.pt_checkout {
		&#wrapper {
			#main {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.pt_checkout {
		&#wrapper {
			#main {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}
		}
		.address-dropdown {
			&.custom-select {
				select {
					max-height: initial;
					word-break: break-word;
					overflow-wrap: break-word;
					white-space: pre-wrap;
					white-space: -moz-pre-wrap;
					white-space: -pre-wrap;
					white-space: -o-pre-wrap;
					word-wrap: break-word;
				}
			}
		}
	}

	.checkoutlogin {
		.col-1,
		.col-2 {
			padding: 30px 20px;
			max-width: 100%;
		}

		.col-seperator {
			width: 35px;
			height: 35px;
			line-height: 35px;
			font-size: $base-font + 4px;
			left: 47.5%;
		}

		.col-2 {
			padding-bottom: 39px;
		}
	}
}

.pt_checkout {
	#secondary {
		.checkout-order-summary {
			.order-summary-footer {
				.form-row {
					.subscribeToEmail {
						.field-wrapper,
						.content-asset {
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_checkout {
		&#wrapper {
			#main {
				padding-left: 10px;
				padding-right: 10px;
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
	.billing-cards-section {
		.payment-method {
			.billingfields {
				.cvn {
					width: 48%;
				}
				.enter-card-detials-wrapper {
					.left-cont {
						.cvn {
							.field-wrapper {
								width: 95.5%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.billing-cards-section {
		.payment-method {
			.billingfields {
				.cvn {
					width: 46%;
				}
				.enter-card-detials-wrapper {
					.left-cont {
						.cvn {
							.field-wrapper {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_checkout {
		.primary-content {
			width: 100%;
			padding: 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		#secondary {
			width: 100%;
			max-width: 100%;
		}
		.address-section {
			.form-row {
				&.billing-page-continue {
					width: 100%;
				}
			}
		}
		.gift-section {
			.form-row {
				&.label-inline {
					width: 50%;
					.field-wrapper {
						margin: 0 10px 0 0;
					}
				}
			}
		}
	}
	.checkoutlogin {
		.col-1 {
			#password-reset {
				float: left;
				width: 30%;
				white-space: inherit;
				clear: left;
			}
			.spc-login-btn {
				margin: 35px 0 15px;
			}
		}
		.col-2 {
			padding-bottom: 28px;
		}
	}

	//Items in bag section
	.items-in-bag {
		&.saved-address-block {
			.head-wrapper {
				margin: 30px 0 10px;

				legend h3 {
					font-size: inherit;
					font-weight: inherit;
					display: inline;
					text-transform: inherit;
					letter-spacing: inherit;
					font-family: inherit;
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.checkoutlogin {
		.col-seperator {
			display: none;
		}

		.col-1 {
			h2 {
				&.heading {
					margin-bottom: 14px;
					padding-bottom: 13px;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	//page layouts
	.pt_checkout {
		#header {
			.fixed-header {
				display: block;
				height: auto;
			}
		}

		&#wrapper {
			#main {
				margin-top: 0;
			}
		}
		.form-row {
			div {
				&.field-wrapper {
					input[type="text"] {
						font-size: 14px;
					}
				}
			}
		}
		.checkout-tab-head {
			margin: 20px 0 0 0;
			h3,
			h2,
			h1 {
				font-size: 18px;
			}
		}
		.form-row {
			.field-wrapper {
				button {
					&.pwd-show {
						font-size: 10px;
						width: auto;
					}
				}
			}
		}
		.checkout-tabs {
			.form-row {
				label {
					span {
						font-family: $Helvetica;
						color: $nobel;
					}
				}
				&.login-rememberme {
					label {
						span {
							color: $black;
						}
					}
				}
			}
		}
	}
	.spc-login {
		.login-data {
			.checkout-login-brands {
				font-size: 12px;
			}
		}
	}

	.checkoutlogin {
		padding: 20px 0 24px;
		.col-1 {
			width: 100%;
			margin: 0px 0px 5px 0;
			padding: 20px 10px 30px;
			display: table;
			h2 {
				&.heading {
					font-size: 18px;
					margin: 4px 0 0;
					padding-bottom: 16px;
				}
			}
			.login-box-content {
				margin-top: 20px;
			}
			.spc-login-btn {
				padding: 14px 50px 15px;
			}
			button {
				&.oAuthIcon {
					padding: 12px 0;
					margin-top: 15px;
				}
			}
		}

		.col-seperator {
			top: -5.5%;
			display: block;
			font-size: 18px;
		}
		.col-2 {
			width: 100%;
			padding: 30px 10px 0;
			position: relative;
			display: table;
			h2 {
				&.heading {
					font-size: 18px;
				}
			}
			.formbuttonrow {
				margin: 38px 0 30px;
			}
			.login-box {
				p {
					font-size: 13px;
				}
				button {
					width: 100%;
					padding: 15px 30px;
				}
			}
			.newsletter-signup {
				label {
					font-size: 13px;
				}
			}
		}
	}
	.customer-signin-section {
		margin-top: 20px;
		padding: 20px;
	}

	.checkout-billing,
	.spc-express-checkout {
		.payment-option-section {
			&.billing-coupon-code {
				.tab-section {
					padding: 0 4% 0 4%;

					.left-content {
						max-width: 480px;
						width: 100%;
					}

					.right-content {
						display: inline-block;
						width: 100%;
						max-width: 100%;
						.form-row {
							&.couponCode,
							&.rewardCode {
								width: 60%;
							}
						}
						&.payment-benefits {
							@media screen and (max-width: 767px) {
								width: auto;
							}
						}
						.coupon-apply {
							width: 40%;
							display: inline-block;
							button {
								width: 100%;
								max-width: 100%;
							}
						}
					}
					&.rewardcertificate {
						.apply {
							width: 40%;
							max-width: 100%;
						}
					}
				}
			}
		}

		.form-row-button {
			&.billing-page-continue {
				padding: 21px 4% 21px 4%;

				button:not(.btn-ds) {
					width: 100%;
					padding: 15.5px 20px;
					font-size: 14px;
				}
			}
		}
	}

	.pt_checkout {
		legend {
			font-size: 18px;
		}
		.shipping-section {
			legend {
				padding: 15px 0 16px 0;
				margin-bottom: 21px;
			}
			.form-row {
				margin: 0 0 15px 0;
			}
		}
		.form-row {
			margin: 0 0 20px 0;
		}
		.checkout-tabs {
			.checkout-tab-head {
				margin: 0;
				h3,
				h2,
				h1 {
					margin: 20px 0;
					padding-bottom: 0;
					font-size: 18px;
					&.align-left {
						margin: 0;
					}
				}

				&.open {
					h2,
					h1 {
						margin: 24px 0 18px 0;
						padding-bottom: 0;
					}
				}
			}
			.checkout-tab {
				&.login {
					.checkout-tab-head {
						h3,
						h2,
						h1 {
							margin: 0;
							padding-bottom: 21px;
						}
					}
				}
			}
			&.spc-shipping {
				.login {
					.checkout-tab-head {
						h3,
						h2,
						h1 {
							margin: 0;
							padding-bottom: 0;
						}
					}
				}
			}
		}
		.block-section {
			padding: 0 3.3%;
		}
		.shipping-section {
			.form-row {
				width: 100%;
				.field-wrapper {
					width: 5%;
				}
				.field-desc {
					width: 94%;
				}
				&.even {
					width: 100%;
				}
			}
		}
		.address-section {
			.form-row {
				width: 100%;
				padding: 0;
				.field-wrapper {
					font-family: $Helvetica;
				}
				&.postal {
					width: 50%;
					padding-right: 6%;
				}
				&.state {
					width: 50%;
				}
				.field-wrapper {
					input {
						padding-top: 0;
						font-size: 12px;
					}
					select {
						padding-top: 17px;
					}
				}
			}
			.fields-containter {
				.form-row {
					padding: 0;
					&.postal {
						padding-right: 4%;
					}
				}
			}
		}
		.gift-section {
			.free-gifting {
				display: inline-block;
			}
			.gift-right {
				display: inline-block;
				width: 100%;
				.form-row-button {
					margin: 0;
					button {
						width: 100%;
						font-size: 14px;
						padding: 15px 30px 14px;
					}
				}
				.spc-shipping-btn {
					span {
						font-size: 14px;
					}
				}
			}
			.form-row {
				&.gift-message-text {
					.field-wrapper {
						width: 100%;
					}
				}
				&.label-inline {
					width: 100%;
					.field-wrapper {
						margin: 0 10px 0 0;
					}
				}
			}
		}
		.cart-row {
			.col-1 {
				width: 100%;
				.item-details {
					.name {
						margin: 0px 0 15px 0;
						a {
							font-size: 14px;
						}
					}
					.attribute {
						.label {
							font-size: 11px;
						}
					}
				}
			}
			.columns-combine {
				.column {
					&.col-2 {
						width: 100%;
						float: left;
						margin: 10px 0 13px;
						.custom-cart-qty {
							margin: 0;
						}
					}
				}
				.col-3 {
					width: 100%;
					font-size: 12px;
					.item-total {
						margin: 10px 0 20px 0;
					}
				}
			}
			&.gift-Card {
				.col-1 {
					.item-details {
						width: 60%;
					}
				}
			}
			.specialmessag:nth-child(n) {
				display: block;
			}
			&.gift-Card {
				.specialmessag:nth-child(n) {
					display: block;
				}
			}
			&.specialproductsetchild:last-child {
				.cart-columns {
					padding: 0;
				}
			}
		}
		.items-in-bag {
			&.saved-address-block {
				.head-wrapper {
					legend {
						font-size: $base-font + 6px;

						h3 {
							font-size: inherit;
							font-weight: inherit;
							display: inline;
							text-transform: inherit;
							letter-spacing: inherit;
							font-family: inherit;
						}
					}
				}
			}
		}
		.saved-address-block {
			.head-wrapper {
			}
		}
		.address-actions {
			font-size: 11px;
			margin: 3px 0 0 0;
		}
	}
	.chk-out-header-right-section {
		.help-click {
			padding: 0 26px 0 0;
			font-size: 12px;
			&.help-btn {
				border: none;
				text-transform: none;
				letter-spacing: 0;
				display: inline;
				font-weight: normal;
				background-color: transparent;
				background: url(../../images/arrow-down.png) no-repeat;
				background-size: 12px;
				background-position: 100% 43%;
				color:$dim-gray;
			}
		}
		&.active {
			.help-click {
				&.help-btn {
					background: url(../../images/arrow-up.png) no-repeat;
					background-size: 12px;
					background-position: 100% 43%;
				}
				padding: 0 26px 0 0;
			}
		}
	}

	.billing-cards-section {
		.cvv-msg {
			letter-spacing: 0;
		}
		.error-tender-rebuttal {
			width: 100%;
			margin: 0 0 15px;
		}
		.card-count {
			span {
				&.newcreditcard {
					font-size: 10px;
					float: none;
				}
			}
		}
		.payment-method {
			padding: 0;

			.billingfields {
				.enter-card-detials-wrapper {
					.left-cont {
						max-width: 100%;
						width: 100%;
					}
					.right-cont {
						max-width: 100%;
						width: 100%;
						margin-bottom: 20px;
					}
				}
				select {
					padding-top: 18px;
				}
			}
		}
		.cardinfo {
			.carddetails {
				.cardname {
					font-family: $HelveticaBold;
				}
				.moile-wrapper {
					display: inline-block;
					width: 70%;
				}
				.li.cardholder,
				.li.expdate {
					width: 100%;
				}
				.li.cardtype {
					min-width: 60px;
					width: 10%;
				}
			}
		}
	}
	.checkout-order-totals {
		padding: 19px 10px;
		.summary-heading {
			font-size: 24px;
		}
		div {
			&.order-detail {
				padding: 0 0 10px 0;
				&.order-total {
					margin-top: 5px;
				}
			}
		}
	}
	.spc-login {
		margin: 0;
		.login-rememberme {
			margin: 3% 0 0;
			width: 100%;
		}
		.login-box {
			h2,
			h1 {
				&.heading {
					padding-bottom: 16px;
				}
			}
		}
	}
	.cart-columns {
		padding: 33px 0 20px;
	}
	.qa-section {
		.question-title {
			font-size: 20px;
			padding: 25px 0;
		}
		.qa-content {
			padding: 21px;
		}
	}
	.customer-signin-section {
		padding: 15px 15px 20px;
		.head-wrapper {
			margin: 11px 0 10px;
			padding-bottom: 13px;
			h2 {
				font-size: 18px;
			}
		}
		.welcome-check {
			font-size: 14px;
		}
		.legend {
			font-size: 18px;
		}
		.saved-address-block {
			.saved-address-count {
				font-size: 18px;
			}
		}
	}
	.saved-address-block {
		.head-wrapper {
			padding-bottom: 15px;
			margin: 21px 0 12px;
		}
		.saved-address-count {
			margin: 0px 0 8px;
		}
		.select-address {
			.field-wrapper {
				select {
					padding: 17px 15px 14px 15px;
					font-size: 12px;
				}
			}
		}
	}
	.spc-billing {
		.checkout-tab-head {
			.details {
				.inner-block {
					h3 {
						font-size: 18px;
						padding: 0 0 15px 0;
					}
					.address {
						padding: 15px 0 0 0;
						.default {
							padding: 0 0 3px 0;
						}
					}
					div {
						&.minishipments-method {
							padding: 7px 0 0 0;
							.promo {
								font-size: 13px;
							}
						}
					}
				}
				.delivery-options {
					.inner-block {
						h3 {
							padding: 0 0 11px 0;
						}
					}
				}
			}
		}
	}
	.billing {
		.saved-address-block {
			.head-wrapper {
				margin: 17px 0 0;
				padding-bottom: 16px;
			}
			.addresssection {
				margin: 0px 0 21px;
			}
		}
		.billing-cards-section {
			.payment-method {
				.billingfields {
					.enter-card-detials-wrapper {
						padding-bottom: 12px;
						margin-bottom: 14px;
					}
				}
			}
			legend.paywith {
				margin-bottom: 20px;
			}
			.payment-method-options {
				.form-row {
					margin: 0 0 8px 0;
					label {
						margin-left: 0;
					}
				}
			}
		}
	}
	.checkout-billing,
	.spc-express-checkout {
		.billing-coupon-code {
			.tab-section {
				.right-content {
					.form-row {
						width: 177px;
						label {
							top: -6px;
						}
						.field-wrapper {
							input[type="text"] {
								padding: 14px 10px 15px;
							}
						}

						&.pin {
							width: 46.5%;
						}
					}
				}
				.giftcert-apply {
					width: 46.5%;
				}
			}
			button {
				padding: 15px 30px;
			}
		}
		.billing-cards-section {
			.cvninfo {
				.cvv-msg {
					font-size: 12px;
				}
			}
		}
	}
	.spc-summary {
		.checkout-tab-head {
			.section-heading {
				a {
					&.edit-link {
						font-size: 10px;
					}
				}
			}
			.details {
				.inner-block {
					h3 {
						font-size: 18px;
					}
				}
			}
		}
		.billing {
			.checkout-tab-head {
				div {
					&.details {
						padding-bottom: 14px;
					}
				}
			}
		}
	}
	.details {
		.billing-left-section {
			.mini-payment-instrument,
			.mini-billing-address {
				h3 {
					font-size: 18px;
				}
			}
		}
	}
	.submit-order {
		button {
			font-size: 14px;
			padding: 17px 30px;
		}
	}
	.spc-express-checkout {
		.payment-option-section {
			.tab-section {
				.right-content {
					.form-row {
						width: 170px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_checkout {
		.saved-address-block {
			.address-actions {
				float: right;
				width: auto;

				.edit-section {
					.shipping-add-edit {
						float: left;
					}
					.shipping-add-new {
						float: right;
					}
				}
			}
		}
		.shipping-section {
			.form-row {
				label {
					span {
						color: $black;
						font-family: $HelveticaBold;
					}
				}
				.field-desc {
					span.form-caption {
						font-family: $Helvetica;
					}
				}
			}
		}
		.address-section {
			.form-row {
				&.label-inline {
					label {
						span {
							text-transform: none;
						}
					}
				}
			}
		}
		.billing {
			.address-section {
				.form-row {
					&.makeDefault,
					&.addToAddressBook {
						input {
							padding-top: 15px;
						}
						label {
							&.input-focus {
								span {
									color: $black;
								}
							}
						}
					}
				}
			}
			.billing-right-section {
				.promo-code,
				.gift-card {
					.section-header {
						margin: 34px 0 0 0;
						font-size: $base-font + 6px;
					}
				}
			}
		}
	}
	.checkout-progress-indicator {
		.step-1,
		.step-2 {
			&.submitted {
				.progress-count {
					border-color: $dark-gray;
				}
			}
		}
	}
	.guest-shipping {
		.customer-signin-section {
			.head-wrapper {
				.edit-link {
					padding: 12px 0;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.pt_checkout {
		.checkout-tabs .checkout-tab {
			&.login {
				.checkout-tab-head {
					h1 {
						margin: 0;
						padding: 0 0 15px;
					}
				}
			}
		}
		.shipping-section {
			.form-row {
				.field-wrapper {
					width: 9%;
				}
				.field-desc {
					width: 90%;
				}
			}
		}
		.items-in-bag.saved-address-block {
			.address-actions {
				margin-top: 8px;
			}
		}
	}
	.checkoutlogin {
		.col-seperator {
			left: 44.5%;
		}
	}

	.spc-summary,
	.spc-billing,
	.spc-express-checkout {
		.checkout-tab-head {
			.section-heading {
				font-size: $base-font + 4px;
				margin: 20px 0 10px 0;
			}
			.details {
				padding: 20px 5.2% 20px 4.3%;
			}
		}
	}

	.spc-login {
		h2,
		h1 {
			&.heading {
				padding-bottom: 10px;
				margin-bottom: 15px;
			}
		}
	}

	// saved-address-block
	.address-actions {
		margin-top: 5px;

		.seperator {
			margin: 0 6px;
		}
	}

	.checkout-billing {
		.payment-option-section {
			.tab-section {
				.row-header {
					padding: 15px 0 15px 0;
				}
				.right-content {
					button {
						padding: 15px 20px;
					}
				}
			}
		}

		.form-row-button {
			&.billing-page-continue {
				padding: 11px 4% 11px 4%;
			}
		}
	}

	//Items in bag section
	.items-in-bag {
		&.saved-address-block {
			.head-wrapper {
				margin: 10px 0 5px;

				legend h3 {
					font-size: inherit;
					font-weight: inherit;
					display: inline;
					text-transform: inherit;
					letter-spacing: inherit;
					font-family: inherit;
				}
			}
		}
	}

	.billing-cards-section {
		.payment-method {
			.billingfields {
				.enter-card-detials-wrapper {
					.card-actions {
						.checkboxes {
							width: 100%;
						}
						.savecreditcard {
							width: 100%;
							margin: 10px 0;
						}
					}
				}
			}
		}

		.card-count {
			.no-of-cards {
				width: 100%;
				margin-bottom: 15px;
				display: inline-block;
			}
			.newcreditcard {
				width: 100%;
				margin-bottom: 15px;
				span {
					display: inline-block;
				}
			}
		}
	}
}
.pt_checkout {
	.address-section {
		.form-row {
			.makeDefaultAddress {
				width: 38%;
			}
		}
	}
	.checkout-shipping {
		> fieldset {
			padding-bottom: 26px;
		}
	}
}

@media screen and (min-width: 1260px) {
	.pt_checkout {
		.shipping-section {
			.form-row-button {
				button {
					width: 330px;
				}
			}
		}
	}
	.checkout-billing,
	.spc-express-checkout {
		.form-row-button {
			&.billing-page-continue {
				button:not(.btn-ds) {
					width: 330px;
				}
			}
		}
	}
}
.pt_checkout {
	.reset-message-notify {
		padding: 10px 0;
		text-align: center;
		letter-spacing: 1px;
		color: $black;
		background: $light-skygrey;
		box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
		span {
			font-size: $base-font + 2px;
			&.reset-text {
				font-family: $WorkSans;
			}
		}
		.checkSign {
			position: relative;
			margin-right: 26px;
			&::after {
				content: "\f058";
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				color: $circlegreen;
				font-size: 22px;
				padding-right: 0.5em;
				position: absolute;
				top: -3px;
				left: 0;
			}
		}
		.closeMsg {
			position: relative;
			margin-left: 12px;
			cursor: pointer;
		}
	}
}

.checkout-billing,
.spc-express-checkout {
	.payment-option-section {
		.couponsection {
			.couponcontent.toggle-content {
				.span-title {
					display: block;
					font-size: $base-font + 2px;
					margin-bottom: 15px;
				}
				.defer-show {
					display: none;
				}
				&.deferactive {
					display: none;
				}
				&.deferactive[style*="flex"] {
					flex-wrap: wrap;
					.left-content {
						flex: 0 0 50%;
						order: 3;
						max-width: 50%;
						@media screen and (max-width: 767px) {
							flex: 0 0 100%;
							max-width: 100%;
							order: 1;
						}
					}
					.right-content {
						flex: 0 0 50%;
						order: 1;
						max-width: 50%;
						@media screen and (max-width: 767px) {
							flex: 0 0 100%;
							max-width: 100%;
							order: 2;
						}
					}
					.defer-show {
						flex: 0 0 50%;
						order: 2;
						display: inline-block;
						@media screen and (max-width: 767px) {
							flex: 0 0 100%;
							max-width: 100%;
							order: 4;
							padding-top: 15px;
						}
						.right-container {
							position: relative;
							vertical-align: top;
							.deferCode {
								margin-bottom: 10px;
								label {
									transform: translate(0px, 18px);
									.label-text {
										color: $black;
									}
									&.input-focus {
										transform: translate(0px, 3px);
										left: 4px;
									}
								}
								.field-wrapper {
									width: 100%;
									.input-text {
										background: transparent;
										border-color: $black;
										border-width: 0 0 1px 0;
										width: 100%;
										box-shadow: none;
										padding-left: 4px;
										text-transform: uppercase;
									}
								}
							}
							.defer-apply {
								display: inline;
								float: none;
								position: absolute;
								top: 2px;
								right: 0px;
								button {
									&.apply-defer {
										background: transparent;
										border: 0px;
										color: black;
										padding-right: 0px;
										box-shadow: none;
									}
								}
							}
						}
					}
					.differed-billing-content {
						flex: 0 0 50%;
						order: 3;
						@media screen and (max-width: 767px) {
							flex: 0 0 100%;
							max-width: 100%;
						}
						.content-asset {
							padding: 8px 0;
						}
						.defercoupon {
							padding: 10px 0 0 0;
							.seperator {
								margin: 0 10px;
							}
							span {
								letter-spacing: 0.6px;
								font-family: $HelveticaBold;
								float: left;
								font-size: 14px;
							}
							button {
								padding: 0;
								border: none;
								margin: 0;
								font-family: $HelveticaBold;
								font-size: $base-font;
								letter-spacing: 0.6px;
								background: transparent;
								color: $black;
								text-transform: capitalize;
								border-radius: 0;
								-webkit-border-radius: 0;
								-moz-border-radius: 0;

								&.removebutton {
									background: url("../../images/x-Icon-Black.svg") no-repeat;
									width: 14px;
									height: 14px;
									background-size: cover;
									vertical-align: middle;
									padding: 0;
									margin: 0;
									display: inline-block;
									text-indent: -9999px;
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
}

.checkout-billing .payment-option-section .couponsection.showcontent .couponcontent.toggle-content.deferactive,
.spc-express-checkout .payment-option-section .couponsection.showcontent .couponcontent.toggle-content.deferactive {
	display: block;
}

.pt_checkout .form-row.differedBilling {
	display: none;
}
.differed-billing-content {
	.see-details {
		font-size: $base-font;
		font-family: $HelveticaBold;
		text-transform: none;
		color: $black;
		float: none;
		padding: 8px 0;
		display: block;
		cursor: pointer;
	}
	.morelink {
		display: inline-block;
		color: $dim-gray;
		text-decoration: underline;
		line-height: 12px;
		cursor: pointer;
	}
}
@media screen and (max-width: 767px) {
	.defer-code-error .error {
		margin-bottom: 15px;
	}
	.chk-out-header-right-section {
		span.shopping-bag-id.clear-content {
			display: block;
		}
	}
}

.intlshipping-prod-info {
	.product-brand {
		text-transform: uppercase !important;
		letter-spacing: 0.36px;
		margin-bottom: 8px;
		font-size: 12px;
	}
	.sps-top-variant,
	.sps-bottom-variant {
		.attributes {
			font-weight: 600 !important;
			font-size: $base-font;
			text-transform: capitalize !important;
		}
	}
}
.pobox-exclusions {
	&.sps-pobox-exclusions {
		.pobox-heading.shippingexception-heading,
		.pobox-intro.shippingexception-intro {
			font-size: 13px;
			line-height: 19px;
			letter-spacing: 0.5px;
			font-family: $HelveticaMedium, $sans-serif;
			font-weight: 500;
		}

		.pobox-intro.shippingexception-intro {
			font-weight: bold;
			.selected-shipping-method {
				color: #af3434;
			}
		}

		.pobox-items {
			.product-line-item {
				&.sps-pobox-exclusions {
					.product-brand {
						font-size: 9px;
						letter-spacing: 0.5px;
						color: $brand-name-color;
						text-transform: uppercase;
					}

					.name {
						font-size: 14px;
						line-height: 14px;
						letter-spacing: 0.5px;
						text-transform: capitalize;
					}

					.variant-availability-group {
						margin-bottom: 8px;
						font-family: $HelveticaMedium, $sans-serif;
						span {
							text-transform: uppercase;
							font-weight: bold;
							font-size: 11px;
							letter-spacing: 0.5px;
							font-family: $HelveticaMedium, $sans-serif;
						}
					}

					.line-item-quantity span,
					.cart-unit-price span {
						font-weight: normal;
						font-size: 11px;
						letter-spacing: 0.55px;
						font-family: $HelveticaMedium, $sans-serif;
					}
				}
			}
		}

		.ship-method-submit,
		.cancel-ship-method-except {
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 1.5px;
			font-family: $HelveticaMedium, $sans-serif;
			font-weight: bold;
		}
	}
}

.checkout-body {
	.cart-row {
		&.sps-checkout {
			.cart-columns {
				.specialmessaging-Mobile .specialmessaging {
					@media screen and (max-width: 767px) {
						margin-top: 10px;
					}
				}

				.col-1 {
					.item-image {
						a {
							display: block;
							width: 100px;
							@media screen and (max-width: 767px) {
								display: unset;
							}
							img {
								width: 100%;
							}
						}
					}
					.item-details {
						line-height: 16px;
						font-weight: normal;
						letter-spacing: 0.55px;
						font-family: $Helvetica;
						max-width: 96%;
						text-transform: capitalize;
						color: $black;
						.attribute {
							font-size: 11px;
							font-weight: bold;
							color: $black;
							.label,
							.value,
							span {
								margin-bottom: 0;
								font-size: 11px;
								font-weight: initial;
								font-family: $Helvetica;
								letter-spacing: 0.55px;
								text-transform: capitalize;
								color: $black;
							}
							.label {
								font-weight: bold;
							}
						}
						.product-list-item {
							.product-brand {
								color: $brand-name-color;
								font-size: 9px;
								letter-spacing: 0.5px;
							}
							.name {
								letter-spacing: 0.5px;
								font-size: 14px;
								line-height: 1;
								color: $black;
								a {
									color: $black;
								}
							}
							.sku {
								font-size: 10px;
								font-weight: normal;
								color: $black;
								span,
								div {
									color: $black;
								}
							}
							.variant-availability-group {
								margin-bottom: 7px;
								font-size: 11px;
								color: $black;
								.swsps-top-bottom-label {
									font-weight: bold;
									margin-bottom: 8px;
									margin-top: 24px;
								}
								.product-availability-list {
									font-size: 11px;
									font-weight: bold;
								}
							}
						}
						.cart-unit-price {
							.price-promotion {
								.price-standard,
								.price-sales,
								span {
									font-size: 11px;
									font-weight: bold;
									letter-spacing: 0.55px;
								}
								.price-sales {
									color: $medium-carmine;
								}
							}
						}
					}
				}

				.specialmessag {
					margin-left: 0;
					@media screen and (max-width: 1260px) and (min-width: 1024px) {
						margin-left: 0;
					}
					.specialmessaging {
						line-height: normal;
						margin-left: 0;
						letter-spacing: 0.55px;
						color: $black;
						p {
							font-size: 11px;
							font-weight: bold;
							letter-spacing: 0.55px;
							color: $black;
							&.finalsalemessage {
								color: #af3434;
								text-transform: uppercase;
								letter-spacing: 0.6px;
								font-size: 12px;
								line-height: normal;
								font-weight: bold;
							}
							@media screen and (max-width: 767px) {
								margin-bottom: 10px;
								line-height: 14px;
							}
						}
					}
				}
				.item-quantity {
					.custom-cart-qty {
						@media screen and (max-width: 767px) {
							margin-top: 0;
						}
						.label,
						.value {
							font-size: 11px;
							letter-spacing: 0.55px;
							color: $black;
						}
					}
				}

				.col-2 {
					@media screen and (max-width: 767px) {
						margin: 0;
					}
				}
				.columns-combine {
					.col-3 {
						@media screen and (max-width: 767px) {
							width: 100%;
							max-width: 100%;
						}
						.item-total {
							color: $black;
							@media screen and (max-width: 767px) {
								margin: 10px 0 0 0;
							}
						}
					}
				}
				.col-3 {
					.item-total {
						.sub-total {
							letter-spacing: 0.55px;
							font-weight: bold;
							font-family: $Helvetica;
							color: $black;
							font-size: 11px;
						}
						.price-total {
							color: $black;
							letter-spacing: 0.34px;
							font-weight: bold;
							font-size: 16px;
							@media screen and (max-width: 767px) {
								font-size: 11px;
								margin-left: 5px;
								letter-spacing: 0.55px;
							}
						}

						.price-unadjusted,
						.price-sales {
							color: $medium-carmine;
							letter-spacing: 0.34px;
							font-weight: bold;
							@media screen and (max-width: 767px) {
								font-size: 11px;
								margin-left: 2px;
								letter-spacing: 0.55px;
							}
						}

						.price-unadjusted {
							display: block;
							@media screen and (max-width: 767px) {
								display: unset;
							}
						}
					}
				}
			}
		}
	}

	/* SC-11053, SC-11016, SC-11025 */
	.spc-summary, .spc-express-checkout {
		.place-order{
			.order-summary-footer{
				.left-section{
					.add-to-email-list{
						.wrapper-add-to-email{
							display: block;
							margin-top: 0;
							@media only screen and (min-width: 768px){
								.add-to-emaillist-warningmsg{
									margin-top: 15px;
									.arrow-up{
										left: auto;
										right: 250px;
									}
								}
							}
						}
						@media only screen and (min-width: 1025px){
							.wrapper-add-to-email{
								.add-to-emaillist-warningmsg{
									margin-top: 22px;
									.arrow-up{
										left: auto;
										right: 325px;
									}
								}
							}
						}
						.subscribeToEmail{
							text-align: left;
							width: auto;
							float: none;
							~ .content-asset:not(.wrapper-add-to-email){
								max-width: calc(100% - 28px);
								text-align: left;
								width: auto;
								@media only screen and (max-width: 1024px){
									 margin-left: 5px;
								}
							}
						}
						@media only screen and (max-width: 767px){
						    text-align: left;
						}
						~ .checkout-sms-signup{
							@media only screen and (min-width: 768px) and (max-width: 1024px){
								padding-top: 0;
								margin-top: 10px;
								> .content-asset{
									max-width: calc(100% - 22px);
									text-align: left;
								}
							}
							@media only screen and (max-width: 767px){
								> .content-asset{
									font-size: 11px;
								}
							}
						}
						.add-to-emaillist-warningmsg{
							z-index: 1;
						}
					}
					.checkout-sms-signup{
						@media only screen and (min-width: 768px){
							box-sizing: border-box;
							text-align: left;
						}
						> .smsSignUp{
							margin-right: 4px;
						}
						text-align: right;
						@media only screen and (max-width: 767px){
							padding-top: 0;
							margin-bottom: 0;
							margin-top: 10px;
							padding-right: 0;
							text-align: left;
						}
					}
				}
			}
		}
	}

	.pt_checkout#wrapper{
		.checkout-tabs.spc-login{
			.checkout-tab.login{
				.checkoutlogin{
					.col-2{
						.form-row.newsletter-signup.addToEmailList{
							label{
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.checkout-sms-signup{
		text-align: left;
		margin-top: 10px;
		.smsSignUp,.smsSignUp ~ .content-asset{
			&.smsSignUp{
				margin: 0 2px 0 0;
			}
			display: inline-block;
			vertical-align: middle;
			width: auto !important;
		}
		~ .checkout-guest-btn ~ .content-asset {
			color: #525252;
		}
	}
	/* End: SC-11053, SC-11016, SC-11025 */

	/* SC-10946 - SC-11044, SC-11045, SC-11136 */
	.checkout-tab.shipping{
		.smssignup-container{
			clear: both;
			@media only screen and (max-width: 767px) {
				width: 100%;
    			float: left;
    			padding-bottom: 40px;
			}
			.form-row{
				margin: 0;
				padding: 0;
				width: auto;
				~ .content-body{
					float: left;
					width: 41%;
					@media only screen and (max-width: 767px){
						width: calc(100% - 30px);
					}
				}
			}
			.smsSignUp {
				.second-label {
					margin-left: 28px;
    				display: inline-block;
				}
			}
		}
	}
	/* End: SC-10946 - SC-11044, SC-11045, SC-11136 */

	/* SC-11047, SC-11048, SC-11198 */
	.checkoutlogin-col-wrapper{
		.checkout-sms-signup.phone-field{
			@media only screen and (min-width: 768px){
				margin-top: -12px;
			}
			@media only screen and (max-width: 767px){
				margin-top: 0;
			}
			> .sms-singup-checkbox{
				display: block !important;
				float: left;
				margin-top: 2px !important;
				~ .phoneNo{
					clear: both;
				}
			}
			> .sms-sign-up-phone-field{
				display: none;
			}
			~ .checkout-guest-btn ~ .content-asset {
				display: none;
			}
		}
		&.smsvibes{
			@media only screen and (min-width: 768px){
				.form-row-button .spc-login-btn, .checkout-sms-signup.phone-field ~ .formbuttonrow.checkout-guest-btn{
					position: relative !important;
					width: 100% !important;
					top: auto;
					bottom: auto !important;
					margin-top: 10px !important;
					margin-bottom: 10px !important;
					transition: none !important;
				}
			}
		}
	}
	/* End: SC-11047, SC-11048, SC-11198 */
}

.cart-row.sps-checkout {
	.item-details {
		.product-special-messages {
			div {
				color: $special-product-messages-color;
				font-size: $base-font - 1px;
				letter-spacing: 0.5px;
				@include GothamMedium($Gotham);
				a,
				span {
					color: $special-product-messages-color;
					font-size: $base-font - 1px;
					letter-spacing: 0.5px;
					@include GothamMedium($Gotham);
				}
			}
		}
	}
}
.checkoutlogin {
	.guest-form {
		.reset-error-caption {
			padding: 2px 10px;
			margin: -8px 0 7px;
			background: $venetian-red;
			border-radius: 0 0 3px 3px;
			text-align: left;
			font-family: $WorkSans;
			font-size: $base-font;
			color: $white;
			width: auto;
			letter-spacing: -0.33px;
			line-height: 14px;
			transition: transform 0.3s ease, font-size 0.3s, color 0.3s;

		}
		.existing-user-email {
			color: $promo-red;
			margin: 7px 0 9px 13px;
			a {
				color: $cobalt-blue;
			}
			.password-reset {
				padding: 0;
				font-size: 12px;
				color: $navy-blue;
				background-color: inherit;
				letter-spacing: 0.7px;
				float: none;
			}
		}
		input {
			&.email {
				&.error {
					border: 1px solid $venetian-red;
				}
			}
		}
	}
}
/*SC-7601 NEW CSS STARTS HERE*/
@media screen and (max-width: 1024px) and (min-width: 768px) {
	.chk-out-header-right-section {
		.chk-out-header-top-content {
			.content {
				display: flex;
				flex-wrap: wrap;
				padding-right: 10px;
			}
		}
	}
}

.chk-out-header-right-section * {
	color: $wcag-grey;
}

.pt_checkout#wrapper .spc-login.checkout-tabs .checkout-tab.login .checkoutlogin .guest-form .reset-error-caption.emailValidCheckoutGuest{
	width: auto;
}


.new-checkout.checkout-body {
	.pt_checkout {
		&#wrapper #main{
			.checkout-tabs {
				.checkout-tab.login {
					margin-top: 0;
					.checkoutlogin {
						padding: 0;
						h2.heading {
							border-bottom: none;
							margin: 0 0 8px;
							font-weight: 900;
							padding-bottom: 0;
						}
						.login-data, .sub-heading {
							line-height: 13px;
							font-size: 12px;
							margin: 0;
							@media screen and (min-width: 768px){
								height: 70px;
							}
							.checkout-login-brands {
								font-size: 1em;
							}
						}
						.login-data{
							@media screen and (max-width: 1024px) and (min-width: 768px){
								min-height: 70px;
								height: auto;
							}
						}
						form.login-form, form.guest-form {
							position: relative;

							fieldset {
								.form-row {
									margin:0 0 8px;
								}
								.form-row-button {
									margin:12px 0 0 !important;
									@media screen and (min-width: 768px) {
										bottom: 0 !important;
										width: 100% !important;
									}
									button {
										margin:0 !important;
										width: 100% !important;
										padding-left: 10px;
										padding-right: 10px;
									}
								}
							}
						}

						.col-1 {
							margin: 0;
							padding: 16px 10px 16px;
							@media screen and (max-width: 767px){
								border-bottom: 1px solid lightgrey;

							}
							@media screen and (min-width: 768px){
								padding: 32px 48px;
							}

							.login-box-content {
								margin-top: 0;
							}

							.login-data {
								display: block;
								.content-asset {
									margin-bottom: 8px;
								}
							}
							.pass-reset-cont {
								margin: 0;
								font-size:12px;
								font-weight: 300;
								text-transform: capitalize;
								vertical-align: top;
								margin-top: -1px;
								a, .btn {
									color: $navy-blue;
									margin: 0;
									font-size:12px;
									font-weight: 300;
									text-transform: capitalize;
									display: inline;
									float: none;
									width: 100%;
									text-align: right;
									min-height: auto;
								}
								.btn {
									padding: 0;
									background: none;
									border: none;
								}
							}
						}
						.col-2 {
							padding: 16px 10px 8px;
							@media screen and (min-width: 768px){
								padding: 0 48px 32px 48px;
							}
							@media screen and (max-width: 767px){
								.col-seperator, .sub-heading {
									display: none;
								}
							}
							.form-row {

								&.newsletter-signup {
									label {
										span {
											font-size: $base-font;
											color: $black;
										}
									}
								}
							}
						}
					}
				}
				.checkout-tab {
					&.shipping, &.billing, &.summary {
						padding-left: 0;
						.checkout-tab-head .heading {
							font-size: 18px;
							margin: 0;
							padding: 23px 10px;
							font-weight: 900;
						}
					}
				}
			}
			.checkout-tab-head {
				.items-in-bag {
					padding-left:10px;
					.head-wrapper h3 {
						font-weight: 100;
						font-size: 16px;
						padding-bottom: 0px;
					}
				}
				.address-actions a{
					color: #0750C5;
					text-transform: capitalize;
					font-weight: 100;
				}
			}
			.basket-details .cart-row{
				&:first-child{
					border: none;
				}
			}
		}
	}
}

.checkout-order-summary .design-systems .perks-removed-from-cart{
	border: 2px solid $CA-rewards-modal-footer-devider;
	padding: 0.75rem 1.875rem 0.75rem 3.125rem;
    margin-bottom: 1rem;
}


.pt_checkout .sign-up-section.shipping-section,
.pt_checkout .sign-up-section.billing-section {
	legend {
		margin-bottom: 0;
		border-bottom: 0;
		border-top: 2px solid $alto;
		padding-top: 16px;
		padding-bottom: 16px;
		.optional-text {
			color: $mortar-grey;
			font-size: 0.75rem;
		}
	}
	.create-acc-desc-text {
		font-size: 0.875rem;
		margin-right: 1rem;
		margin-top: 0;
	}
	.sign-up-form-component {
		float: left;
		display: table-cell;
		width: 100%;
		@media screen and (min-width: 768px) {
			width: 49%;
		}
	}


	.form-row {
		margin-bottom: 10px;
		width: 100%;
		&.optionalPassword,
		&.mandatoryPassword {
			&.error-handle {
				.field-wrapper {
					&:before {
						font: normal normal normal 28px/1 FontAwesome;
						font-size: 25px;
						text-rendering: auto;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						content: "\f071";
						color: $venetian-red;
						position: absolute;
						top: 11px;
						left: 11px;
					}
				}
				label {
					padding-left: 43px;
					.error {
						color: $venetian-red;
					}
				}
				input[type="password"],
				input[type="text"] {
					border: 2px solid $venetian-red;
					padding-left: 55px;
				}
			}
		}
		&.optionalPassword,
		&.mandatoryPassword {
			.field-wrapper {
				width: 100%;
				padding-left: 0;
				margin: 0;
			}
			.form-caption {
				display: none;
				padding: 10px;
				margin: -1px 0 10px;
				background: $venetian-red;
				border-radius: 0 0 3px 3px;
				text-align: left;
				font-family: $WorkSans;
				font-size: $base-font;
				color: $white;
				width: auto;
			}
		}
	}
	.password-status {
		margin: 0px 0 10px;
	}
	.gift-right {
		&.ck-field-required-error-handle {
			position: relative;
		}
		.form-row-button {
			margin-top: 0;
		}
	}
	@media screen and (min-width: 768px) {
		.required-fields-warn {
			position: absolute;
			top: -40px;
		}
	}
	.form-row.continue-to-payment {
		@media screen and (min-width: 768px) {
			width: 49%;
			padding: 0;
    		border: 0;
		}
	}
}
.pt_checkout .sign-up-section.billing-section {
	border-top: 4px solid $white;
	display: block;
	padding-bottom: 40px;
	legend {
		float: left;
		border-top: 0;
	}
}

.existing-user-email-section {
	&.tooltip-shown {
		position: relative;
    	top: 10px;
	}
}

.pt_checkout .checkout-order-summary .design-systems .alert .alert-close-button {
	background: transparent;
	padding-top: 0;
	border: 0;
}
@media screen and (min-width: 768px) {
	.existing-user-email-section.tooltip-shown .form-row.emailAddress.error-handle {
		margin-top: -16px !important;
	}
}
.promotion-details-dialog {
	.ui-dialog-title {
		width: 100%;
		text-align: center;
		padding-left: 0;
		font-weight: normal;
		margin-top: 18px;
    	font-size: 18px;
	}
	#dialog-container.dialog-content {
		padding-left: 0;
		padding-right: 1px;

		.oc-actions-row-container {
			display: none;
		}

	}
	.modal-content {
		border: none;

		h3 {
			margin: 0;
			margin-left: 24px;
		}

		.oc-promo-details {
			padding: 0px 24px 24px 24px;
		}

		.promotionDetailsCode {
			padding: 8px 24px;
			margin-bottom: 16px;
			font-size: 1rem;
		}

		.oc-promo-details {
			background: none;
		}
	}
}
.login-oauth #appleid-signin{
	margin-top: 10px;
}

.checkout-body {
	.spc-summary, .spc-express-checkout {
		.open {
			.section-heading {
				font-weight: 700;
			}
		}
		.checkout-tab.review {
			.place-order {
				.order-summary-footer {
					padding-left: 0;
					@media only screen and (max-width: 767px) {
						padding: 0;
						border: 0;
					}
				}
			}
		}
	}
}
.pt_checkout {
	.checkout-tab-content {
		.order-summary-footer {
			.left-section {
				.bottom-sec-left, .bottom-sec-right, .left-content, .right-content, .submit-order, .review-disclaimer, .right-plcc-express {
					box-sizing: border-box;
				}
				.left-content, .right-content {
					.first-last, .shipping-addcontent, a {
						font-size: $base-font + 2px;
					}
					.shipping-texthead, .payment-heading {
						font-family: $HelveticaBold;
    		 			font-size: $base-font + 6px;
					}
					a {
						cursor: pointer;
					}
				}
				.left-content {
					border-right: 1px solid $silver;
					@media only screen and (max-width: 767px) {
						border: 0;
						border-top: 1px solid $silver;
						border-bottom: 1px solid $silver;
					}
				}
				.right-content {
					@media only screen and (max-width: 767px) {
						border-bottom: 1px solid $silver;
					}
					.plcc-cc {
						font-size: $base-font + 2px;
						.gift-plcc {
							font-size: $base-font + 3px;
						}
						.billing-plcc {
							.address {
								div {
									display: inline-block;
								}
							}
						}
					}
				}
				.bottom-section-review, .right-plcc-express {
						.add-to-email-list, .checkout-sms-signup {
							width: 100%;
							.content-asset {
								font-size: $base-font + 2px;
							}
							label {
								color: $black;
							}
						}
				}
			}
		}
	}
}

.address1-suggestions-container {
	z-index: 100;
    background-color: $white;
	color: $black;
	border-left: 1px solid $black;
	border-right: 1px solid $black;
	position: absolute !important;
	overflow: auto;
	max-height: 300px;
}

.address1-suggestions-item {
	padding: 1rem 0.75rem 1rem 0.75rem;
	border-bottom: 1px solid $black;
	cursor: pointer;
}

.address1-suggestions-item-entry {
	text-decoration: underline;
	margin:0px;
}

.validate-shipping-warn {
	margin: 10px 0 10px 0;
	text-align: right;
	clear: both;
	font-family: $Helvetica;
	font-size: $base-font + 1px;
	color: $promo-red;
	width: 330px;
	margin-left: auto;
	@media screen and (max-width: 767px) {
		width: 100%;
		padding: 0;
	}
}

.disclaimers-container {
	.disclaimer-item {
		display: none;
	}

	&.toggleDisclaimers {
		.disclaimer-item {
			display: block !important;
		}
	}
}

.block-section {
	@media screen and(min-width:768px) and (max-width:1024px) {
		padding: 0 0 0 6%;
	}
	@media screen and (max-width:1024px) and (orientation:landscape) {
		padding: 0 0 0 3%;
	}
}
.pt_checkout{
  .reward-certificate{
    .section-header {
      padding-bottom: 0;
      margin: 10px 0 0 0;
      font-size: 15px;
      font-weight: bold;
      font-family: inherit;
    }
  }
}
