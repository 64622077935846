.pt_storefront {
	#main {
		//use this class to wrap content to 1260px
		.home-inner-wrapper {
			max-width: 1260px;
			margin: auto;
			width: 100%;
		}

		h1 {
			font-size: 60px;
		}
		h2:not(.recommendation-heading):not(.dont-use-legacy-sitegen) {
			font-size: 50px;
		}
		h3 {
			font-size: 40px;
		}
		h4 {
			font-size: 30px;
			margin: 0 0;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 1px;
		}

		h1,
		h2,
		h3 {
			text-transform: uppercase;
			text-align: center;
		}

		.text-parent {
			position: relative;
			.wrap-center {
				width: 100%;
				text-align: center;
				display: inline-block;
				position: absolute;
				top: 45%;
				left: 0;
			}
			.text-content {
				color: $black;
				span {
					display: inherit;
					width: 100%;
				}
				.promo-red {
					background: $radical-red;
					display: inline-block;
					padding: 5px 25px;
					font-size: $base-font + 4px;
					color: $white;
					text-transform: uppercase;
					font-family: $HelveticaBold;
					letter-spacing: 1px;
				}
			}
		}
		.banner {
			width: 100%;
			margin-bottom: 20px;

			&.text-parent {
				.wrap-center {
					width: 63%;
					top: 33.3%;
					.text-content {
						span {
							font-size: 30px;
							text-transform: uppercase;
							line-height: 30px;
						}
						button {
							margin-top: 33px;
						}
					}
				}
			}
		}

		.homepage-carousel {
			visibility: hidden;
			&.slick-initialized {
				visibility: visible;
			}
		}

		.tall-small-wrapper {
			margin: 13px 0px 20px;
			display: inline-block;
			width: 100%;
			.tall {
				width: 48.45%;
				max-width: 610px;
				float: left;

				&.text-parent {
					.wrap-center {
						top: 42.7%;
						.text-content {
							h1 {
								color: $white;
								margin-top: 15px;
							}
							span {
								font-size: $base-font + 8px;
								color: $white;
							}
							button {
								margin-top: 35px; //PXSFCC-1500
							}
							.promo-red {
								padding: 9px 25px;
								font-size: $base-font + 6px;
							}
						}
					}
				}
			}
			.small {
				max-width: 610px;
				width: 48.45%;
				float: right;

				.small-top {
					margin-bottom: 20px;
					display: inline-block;

					&.text-parent {
						.wrap-center {
							top: 51%;
							h3 {
								color: $white;
								margin-top: 4px;
								font-weight: normal;
							}
							button {
								margin-top: 17px;
							}
						}
					}
				}
				.small-bottom {
					margin-top: 18px;

					&.text-parent {
						.wrap-center {
							top: 58.4%;
							button {
								margin-top: 18px;
							}
						}
					}
				}
			}
		}
		.promo-wide {
			color: $white;
			text-align: center;
			font-size: $base-font + 4px;
			display: inline-block;
			width: 100%;
			box-sizing: border-box;
			.off {
				width: auto;
				float: left;
			}
			.cc-code {
				float: right;
				margin-top: 2.6%;
				.code {
					border: 2px solid $white;
					padding: 11px 56px;
					text-transform: uppercase;
					font-size: $base-font + 8px;
					letter-spacing: 1px;
				}
				.condition {
					text-align: right;
					font-size: $base-font + 2px;
					display: inline-block;
					width: 100%;
					margin-top: 30px;
				}
			}
		}
		.heritage-collection {
			position: relative;
			display: inline-block;
			width: 100%;
			margin: 30px 0 88px;

			h3 {
				color: $white;
				padding: 40px 0;
			}
			.gray-div {
				background-color: $silver;
				position: absolute;
				height: 50%;
				top: 0;
				width: 100%;
			}
			.heritagetile-wrapper {
				position: relative;
				z-index: 20;

				.heritage-tile {
					width: 33.33%;
					max-width: 400px;
					float: left;
					margin-right: 30px;
					&.last {
						margin-right: 0px;
						h4 {
							padding-right: 20px; //PXSFCC-1500
						}
					}

					h4 {
						margin-top: 18px;
						font-weight: 100;
						letter-spacing: 0.05em;
					}
					&:first-of-type {
						//PXSFCC-1500
						h4 {
							padding-left: 20px;
						}
					}
				}
			}
		}
		//ipad landscape and below
		@media screen and (max-width: 1024px) {
			.heritage-collection {
				margin: 20px 0 50px;
				.heritagetile-wrapper {
					.heritage-tile {
						width: 31.33%;
						margin-right: 22px;
					}
				}
			}
			.promo-wide {
				font-size: 14px;
				padding: 33px 30px 33px 40px;
				.cc-code {
					margin-top: 2%;
					.condition {
						margin-top: 20px;
					}
					.code {
						padding: 11.5px 30px;
						font-size: $base-font + 8px;
					}
				}
			}
			.tall-small-wrapper {
				margin: 8px 0px 12px;
				.small {
					.small-bottom {
						margin-top: 12px;
					}
				}
			}
		}
	}
	#main{
		.homepage-seo {
			max-width: 1260px;
			margin: 0 auto;
			position: relative;
			h1{
				font-size: inherit;
				font-family: inherit;
				+ p, &{
					display: inline;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.pt_storefront {
		#main {
			.home-content-container {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}
		}
	}
}

.div-trending-section {
	padding: 0;
	width: 100%;
	display: inline-block;

	.you-may-like {
		max-width: 1260px;
		margin: 23px auto 0;
	}

	.recommendation-heading {
		text-align: center;
		margin-bottom: 45px;
		color: $recommendation-heading;
		span {
			width: 100%;
			display: inline-block;
			text-transform: uppercase;
			color: $recommendation-heading;
			&.trending {
				font-size: $base-font + 42px;
				color: $recommendation-heading;
			}
		}
	}

	.carousel-recommendations {
		width: 100%;
		display: inline-block;
		text-align: center;

		.product-tile {
			width: calc(20% - 4px);
			width: -moz-calc(20% - 4px);
			width: -webkit-calc(20% - 4px);
			width: -o-calc(20% - 4px);
			float: none;
			display: inline-block;
			vertical-align: top;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;

			.product-swatches {
				display: block;
			}

			.p-image-rollover {
				display: none;
			}
		}

		button {
			&.slick-arrow {
				width: 17px;
				height: 23px;
				text-indent: -9999px;
				position: absolute;
				bottom: 0;
				margin: 0 auto;
				z-index: 9;
				-webkit-transition: initial;
				transition: initial;
				-ms-transition: initial;
				padding: 0;
				border: 0;
				top: 50%;
			}

			&.slick-prev {
				left: -20px;
				background: url(../../../../images/trending-arrows-left.svg) no-repeat;

				&.slick-disabled {
					background: url(../../../../images/recently-viewed-arrows-left-unactivated.svg) no-repeat;
					cursor: not-allowed;
				}
			}

			&.slick-next {
				right: -20px;
				background: url(../../../../images/trending-arrows-right.svg) no-repeat;

				&.slick-disabled {
					background: url(../../../../images/recently-viewed-arrows-right-unactivated.svg) no-repeat;
					cursor: not-allowed;
				}
			}
		}
	}
}
