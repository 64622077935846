.ui-widget-overlay {
	background: $white;
	opacity: 0.8;
}

.ui-dialog {
	background-color: $white;
	padding: 0;
	-webkit-box-shadow: -2px 6px 30px 8px rgba(239, 239, 239, 1);
	-moz-box-shadow: -2px 6px 30px 8px rgba(239, 239, 239, 1);
	box-shadow: -2px 6px 30px 8px rgba(239, 239, 239, 1);

	.ui-widget-header {
		background-color: $white;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 50px;
		text-transform: uppercase;
		padding: 0;
	}

	.ui-dialog-title {
		color: $night-rider;
		font-weight: bold;
		display: none;
	}

	.ui-icon-closethick {
		background-image: url("../../images/x-icon.svg");
		height: 15px;
		width: 15px;
		left: 50%;
		top: 50%;
		margin-left: 0;
		margin-top: 0;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
	}

	.ui-dialog-titlebar-close {
		background-color: transparent;
		border: 0 none;
		border-radius: 0 0 0 0;
		height: 50px;
		margin: 0;
		padding: 0;
		right: 0;
		top: 0;
		width: 50px;
		z-index: 1;
		text-indent: -9999px;
		overflow: hidden;
		&:hover {
			opacity: 1;
		}

		span {
			margin: 0;
			padding: 0;
		}
	}

	h1,
	h2 {
		font-size: 1.8em;
	}
}

 .no-close .ui-dialog-titlebar-close {
	display: none;
}

.ui-accordion .ui-accordion-content {
	padding: 0;
}

.ui-tabs {
	.ui-tabs-nav {
		height: 2.45em;
		padding: 0;

		li {
			border-color: $white;
			border-style: solid;
			border-width: 0 0 3px 0;

			&.ui-state-active {
				border-bottom-color: $citrus;

				a {
					color: $black;
					font-weight: 700;
				}
			}

			a {
				color: $nobel;
			}
		}
	}

	.ui-tabs-panel {
		background-color: $white;
		border: 1px solid $white;
		line-height: 1.5em;
		margin: 0;
		padding: 20px 10px;

		h3 {
			@media screen and (min-width: 768px) {
				display: none;
			}
		}
	}
}

#vertical-carousel {
	height: 19rem;
	position: relative;
	overflow: hidden;

	ul {
		height: 10000em;
		list-style: none;
		margin: 0;
		padding: 0;
		position: relative;

		li {
			float: left;
			height: 19rem;
			width: 100%;

			.capture-product-id {
				display: none;
			}
		}
	}

	.jcarousel-nav {
		color: $nobel;
		cursor: pointer;
		font-size: 0.9em;
		font-weight: 700;
		position: absolute;
		right: 0;
		text-transform: uppercase;

		&:hover {
			color: $citrus;
		}

		&.inactive {
			display: none;
		}
	}

	.jcarousel-prev {
		bottom: 30px;
	}

	.jcarousel-next {
		bottom: 0;
	}
}

