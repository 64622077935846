$tablet-width: 768px;
$desktop-width: 1025px;
$ipad-pro-width: 1366px;

// mixins
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.clearfix {
  @extend %clearfix;
}
.clearboth {
  clear: both;
}

$Lora: "Lora", serif;
@mixin LoraItalic($Lora) {
  font-family: $Lora;
  font-style: italic;
}
@mixin LoraBold($Lora) {
  font-family: $Lora;
  font-weight: bold;
}
@mixin LoraBoldItalic($Lora) {
  font-family: $Lora;
  font-weight: bold;
  font-style: italic;
}

$WorkSans: "Work Sans", sans-serif;
@mixin WorkSansThin($WorkSans) {
  font-family: $WorkSans;
  font-weight: 100;
}
@mixin WorkSansExtraLight($WorkSans) {
  font-family: $WorkSans;
  font-weight: 200;
}
@mixin WorkSansLight($WorkSans) {
  font-family: $WorkSans;
  font-weight: 300;
}
@mixin WorkSansRegular($WorkSans) {
  font-family: $WorkSans;
  font-weight: 400;
}
@mixin WorkSansMedium($WorkSans) {
  font-family: $WorkSans;
  font-weight: 500;
}
@mixin WorkSansSemiBold($WorkSans) {
  font-family: $WorkSans;
  font-weight: 600;
}
@mixin WorkSansBold($WorkSans) {
  font-family: $WorkSans;
  font-weight: 700;
}
@mixin WorkSansExtraBold($WorkSans) {
  font-family: $WorkSans;
  font-weight: 800;
}
@mixin WorkSansBlack($WorkSans) {
  font-family: $WorkSans;
  font-weight: 900;
}

$OpenSans: "Open Sans", sans-serif;
@mixin OpenSansRegular($OpenSans) {
  font-family: $OpenSans;
  font-weight: 400;
}
@mixin OpenSansSemiBold($OpenSans) {
  font-family: $OpenSans;
  font-weight: 600;
}
@mixin OpenSansBold($OpenSans) {
  font-family: $OpenSans;
  font-weight: 700;
}

@mixin Univers($font) {
  font-family: $font;
  font-weight: 400;
  letter-spacing: 0.5px;
}

@mixin UniversBold($font) {
  font-family: $font;
  font-weight: 700;
  letter-spacing: 0.5px;
}

nav[role="navigation"] {
  @extend .clearfix;
}
.level-1 > li {
  @extend %clearfix;
}
.level-2 > ul {
  @extend .clearfix;
}

.product-tile {
  @extend %clearfix;
}
.search-suggestion-wrapper {
  @extend %clearfix;
}
.product-suggestion {
  @extend %clearfix;
}

/* All Device Breakpoints */

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-width: #{$tablet-width - 1px}) and (orientation : landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation : portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (orientation : landscape) {
    @content;
  }
}

@mixin ipadPro-portrait {
  @media (min-width: #{$desktop-width - 1px}) and (max-width: #{$ipad-pro-width}) and (orientation : portrait) {
    @content;
  }
}

@mixin ipadPro-landscape {
  @media (min-width: #{$desktop-width - 1px}) and (max-width: #{$ipad-pro-width}) and (orientation : landscape) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin text-properties($letterSpacing : '', $textColor : '', $fontSize : '') {
  @if ($letterSpacing != '') {
    letter-spacing: $letterSpacing;
  }
  @if ($fontSize != '') {
    font-size: $fontSize;
  }  
  @if ($textColor != '') {
    color: $textColor;
  }
}

@mixin inherit_parent_props {
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  font-family: inherit;
  margin: 0;
  border: 0;
  padding: 0;
  color: inherit;
}

// WCAG common clases for focus outline across all the browsers
@mixin focus-outline {
  &.focus-outline {
    outline-offset: -3px;
  }
}

// iOS safari browser only
@mixin iOS-safari {
  @supports (-webkit-hyphens: none) {
    @content;
  }
}
