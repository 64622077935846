.sizechart-dialog {
	&.ui-dialog {
		top: 0 !important;
		.ui-widget-header {
			height: auto;
		}
	}
}

@media screen and (max-width: 767px) {
	.size-guide-content {
		&.active-menu {
			.size-guide-navigation__dropdown_selected {
				display: block;
				margin: 0;
				padding: 1.11rem 20px 1.11rem;
				background: $wild-sand;
				text-align: center;
				position: relative;

				.selected-category {
					font-size: $base-font + 4px;
				}

				.fa {
					position: absolute;
					width: calc(29px / 2);
					height: calc(42px / 2);
					left: 20px;
					top: 26px;
					transform: rotate(90deg);
					-ms-transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
				}
			}

			.size-guide-navigation {
				display: none;
			}
		}
	}
}

.sizinginformation {
	border: 0 none;
	padding: 10px;
	th {
		background: none repeat scroll 0 0 $white-smoke;
	}
	td,
	th {
		border-bottom: 1px solid $white-smoke;
		padding: 10px;
	}
	td {
		border-left: 1px solid $white-smoke;
	}
	.ui-tabs-nav {
		height: 33px;
	}
	.sizing-charts {
		border: 0 none;
	}
	.tips-list {
		padding: 0 !important;
		li {
			list-style: none outside none !important;
		}
	}
}

.size-guide-container {
	.title {
		font-size: 24px;
		margin-top: 20px;
		margin-bottom: 1em;
		text-align: center;
		text-transform: capitalize;

		&.Intn {
			border-top: 1px solid $alto;
			border-bottom: 1px solid $alto;
			margin: 0.5em;
			padding: 15px 0;

			@media screen and (max-width: 767px) {
				margin: 0;
			}
		}
	}

	.size-guide-navigation__dropdown_selected {
		margin: 0 10px;
		padding: 15px;
		box-sizing: border-box;
		border-top: 1px solid #eaeaea;
		border-bottom: 1px solid #eaeaea;
		text-transform: uppercase;
		cursor: pointer;
		font-size: 12px;

		.fa {
			font-size: 2em;
			float: right;
			margin-top: -7px;
			color: $black;
		}
		.fa-angle-up {
			display: none;
		}
	}

	.size-chart-data,
	.size-guide-navigation__dropdown_selected,
	.brand-dropdown {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}

	.size-guide-navigation {
		display: block;
		margin: 0 10px;
		font-size: 1.3em;

		&.active {
			display: block;
		}

		a {
			display: block;
			padding: 0.8em 1.2em;
			letter-spacing: 1px;

			&:hover,
			&.selected {
				color: $black;
			}
		}
		.fa {
			font-size: 1.5em;
			float: right;
		}
	}

	.brand-dropdown {
		position: relative;
		text-align: center;
		background-color: $wild-sand;
		@extend .clearfix;

		.brand-dropdown_categoryName {
			display: inline-block;
			margin-right: 1.1em;
		}
		.fa {
			font-size: 2em;
			position: absolute;
			top: 4px;
			right: 5px;
		}

		select,
		.brand-dropdown_JSMenu {
			text-transform: uppercase;
			padding: 0.6rem 1.8rem 0.6rem 0.8rem;
			display: inline-block;
			background-color: $white;
			cursor: pointer;
		}
		.brand-dropdown_JSMenu {
			opacity: 0;
			border: 1px solid #a9a9a9;
			position: absolute;
			top: 10px;
			text-align: left;

			.dropdown-options {
				height: 0;
				visibility: hidden;
			}
			.item {
				padding: 25px 0 5px 0;
			}
			.fa-angle-up {
				display: none;
			}
			&.is-active {
				.dropdown-options {
					height: auto;
					visibility: visible;
				}
				.fa-angle-down {
					display: none;
				}
				.fa-angle-up {
					display: block;
				}
			}
		}
		.brand-dropdown_selectMenu {
			position: relative;

			.fa {
				top: -4px;
				@media screen and (max-width: 767px) {
					&.fa-angle-up {
						display: none;
					}
				}
			}
		}
		select {
			width: auto;
			margin: 0.8em 0;
			height: auto;
			-webkit-appearance: none;
			opacity: 1;
			border-color: #a9a9a9;

			&:focus {
				outline: none;
			}
			&::-mis-expand {
				display: none;
			}
		}
		.selected-option {
			display: inline-block;
		}
	}
	@media screen and (min-width: 768px) {
		.title {
			margin-bottom: 0.5em;
		}
		.size-guide-navigation__dropdown_selected {
			display: none;
		}
		.size-guide-navigation {
			display: block;
		}
		.size-guide-navigation-inner {
			display: flex !important;
			font-size: 1em;
			justify-content: space-between;
			margin: 0;
			padding: 2em 0 3px 0;
			text-transform: uppercase;
			border-top: 1px solid #eaeaea;
			border-bottom: 1px solid #eaeaea;

			a {
				display: inline-block;
				padding: 0 0.5em 1em;
				border-bottom: 0.4em solid #fff;
				font-size:0.7rem;
				color:$black;

				&:hover,
				&.selected {
					color: inherit;
					border-color: inherit;
				}
			}
		}
		.brand-dropdown {
			.brand-dropdown_JSMenu {
				display: inline-block;
				opacity: 1;
			}
			.brand-dropdown_selectMenu{
				&.focused{
					.fa-angle-down{
						display: block;
					}
				}
				&:not(.focused) {
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
		.size-guide-navigation {
			a {
				text-transform: uppercase;
				font-size: $base-font;
				padding-top: 1.11rem;
				padding-bottom: 1.11rem;
				border-top: 1px solid $alto;
			}

			&.active {
				position: relative;
				margin: 0;

				.size-guide-navigation-inner {
					position: absolute;
					width: 100%;
					padding: 0 10px;
					background: $white;
					box-sizing: border-box;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
				}

				a {
					text-transform: uppercase;
					font-size: $base-font;
				}
			}
		}
	}
}
body .size-guide-container .size-guide-navigation a{
	font-size:0.7rem;
}
