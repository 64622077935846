.only-for-bh-section {
	.bh-shipping-return {
		color: $black;
		text-decoration: underline;
		margin-top: 20px;
		display: inline-block;
	}
	.bh-choose-item-below {
		width: 280px;
		height: 50px;
		background: $black;
		border-color: $black;
		border-radius: 0;
		color: $white;
		text-align: center;
		margin-top: 20px;
		display: inline-block;
		padding: 0;
		outline-offset: -3px;
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			width: auto;
			height: auto;
			padding: 0;
			background: none;
			border: none;
			text-align: left;
			margin: 20px 0 0 0;
			text-transform: none;
			color: $black;
		}
		@media screen and (max-width: 767px) {
			width: 100%;
		}
	}
}
.product-video-container {
	position: absolute;
	z-index: 1;
	background-color: #fff;
	display: none;
	width: 100%;
	height: 80%;
	.yt-outer-wrapper,
	.yt-inner-wrapper {
		height: 100%;
	}
	.yt-inner-wrapper {
		position: relative;
		&.shown::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			cursor: pointer;
			background-color: black;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 64px 64px;
			background-image: url(data:image/svg+xml;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMjgiIGhlaWdodD0iMTI4IiB2aWV3Qm94PSIwIDAgNTEwIDUxMCI+PHBhdGggZD0iTTI1NSAxMDJWMEwxMjcuNSAxMjcuNSAyNTUgMjU1VjE1M2M4NC4xNSAwIDE1MyA2OC44NSAxNTMgMTUzcy02OC44NSAxNTMtMTUzIDE1My0xNTMtNjguODUtMTUzLTE1M0g1MWMwIDExMi4yIDkxLjggMjA0IDIwNCAyMDRzMjA0LTkxLjggMjA0LTIwNC05MS44LTIwNC0yMDQtMjA0eiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==);
		}
		iframe {
			position: absolute;
			top: 0px;
			left: 0px;
			height: 100%;
			width: 100%;
		}
		.ytp-pause-overlay {
			display: none;
		}
		.ytp-show-cards-title {
			display: none;
		}
	}
}

#QuickViewDialog {
	.pdp-main .prod-detail-page {
		.focus-outline:not(.custom-select) {
			outline-offset: -2px;
			&.thumbnail-link {
				outline-offset: -4px;
			}
		}
		.custom-select {
			min-height: 50px;
		}
		#pdp-image-container .product-image-container {
			.product-primary-image {
				.b_product_icon {
					width: 32px;
					position: absolute;
					top: 7px;
					right: 7px;
					overflow: hidden;
					z-index: 9999;
					cursor: pointer;
				}
				.stickerToolTip {
					width: auto;
					height: auto;
					padding: 10px;
					display: none;
					position: absolute;
					top: 25px;
					right: 31px;
					border: 1px solid #d8d8d8;
					box-shadow: 0 0 4px 2px rgba(216, 216, 216, 0.7);
					background: #ffffff;
					font-size: 11px;
					z-index: 9999;
				}
			}
		}
	}
	.product-video-container {
		max-width: 304px;
	}
}

.enlarge-video-sec {
	text-align: left;
}
.enlarge-dialog {
	.variant-dropdown {
		display: none;
	}
	.enlarge-thumb-image {
		.thumb {
			display: inline-block;
			float: none;
		}
	}
	button {
		&.slick-arrow {
			&.slick-next {
				right: 33px;
			}

			&.slick-prev {
				left: 33px;
			}
		}
	}
}

.pt_product-details #main {
	@media only screen and (min-width: 1024px){		
		z-index: 1;		
	}
	.pdp-main {
		.complete-the-look {
			.product-tile a.name-link {
				max-width: 220px;
			}
		}
		.product-tile{
			.name-link{
				.thumb-link{
					padding: 3px;
				}
			}
		}
		.product-image-container .product-primary-image,
		.image .product-primary-image,
		.complete-the-look .product-name-image-container .product-image {
			z-index: 1;
			.b_product_icon {
				width: 33px;
				position: absolute;
				top: 7px;
				right: 7px;
				overflow: hidden;
				z-index: 9;
				cursor: pointer;
				img {
					height: auto;
				}
			}
			.stickerToolTip {
				width: auto;
				height: auto;
				padding: 10px;
				display: none;
				position: absolute;
				top: 25px;
				right: 31px;
				border: 1px solid #d8d8d8;
				box-shadow: 0 0 4px 2px rgba(216, 216, 216, 0.7);
				background: #ffffff;
				font-size: 11px;
				z-index: 10;
			}
		}
		&.standardproductset {
			.product-primary-image {
				@media screen and (max-width: 767px) {
					display: none;
				}
			}

			.product-price {
				.price-standard,
				.price-sales {
					@media screen and (max-width: 767px) {
						display: inline-block;
					}
				}
			}

			.product-col-2 {
				.product-price {
					.price-standard,
					.price-sales {
						display: inline-block;
					}
				}
			}

			.product-set {
				#thumbnails {
					width: 100%;
					height: auto;

					@media screen and (max-width: 767px) {
						padding-bottom: 0;
					}

					ul {
						&:not(.slick-dots) {
							width: 63.5%;

							@media screen and (max-width: 1023px) {
								width: 80%;
							}

							@media screen and (max-width: 767px) {
								width: 100%;
							}

							&.slick-arrow-bar {
								&::before {
									top: 82px;
									right: -30px;

									@media screen and (max-width: 1023px) {
										top: 60px;
										right: -28px;
									}
								}
							}
						}

						button {
							&.slick-arrow {
								right: -23px;

								&.slick-prev {
									top: 100px;

									@media screen and (max-width: 1023px) {
										top: 76px;
										display: block !important;
									}
								}

								&.slick-next {
									top: 50px;

									@media screen and (max-width: 1023px) {
										top: 30px;
										display: block !important;
									}
								}
							}
						}
					}

					.slick-dots {
						text-align: right;
					}

					.thumb {
						width: 33%;
						padding-right: 0;
						height: 140px;

						@media screen and (max-width: 1023px) {
							height: 80px;
							max-width: 80px;
						}

						@media screen and (max-width: 767px) {
							max-width: 100%;
							width: 100%;
							height: auto;
						}

						a {
							@media screen and (min-width: 768px) {
								height: 100%;
							}

							img {
								width: 100%;
								max-height: 100%;
							}
						}
					}
				}
			}
		}
		.product-name {
			&.is-product-brand {
				margin-bottom: 10px;
			}
		}

		&.specialproductset.sw-specialproductset {
			.product-price {
				.price-standard {
					@media screen and (max-width: 767px) {
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.83px;
						font-size: 15px;
						color: #8d8aa5;
					}
				}

				.price-sales {
					@media screen and (max-width: 767px) {
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.83px;
						font-size: 15px;
					}
				}				
			}

			.clearance-discount-msg {
				margin-top: 12px;
				font-weight: 600;
				font-size: 16px;
				line-height: 1.25;
				letter-spacing: 0.73px;
				color: $salered;

				@media screen and (max-width: 767px) {
					line-height: 1.67;
					font-size: 12px;
					letter-spacing: 0.55px;
					span {
						line-height: 1.67;
						font-size: 12px;
					}
				}
			}

			.product-variations {
				#current_color {
					display: none;
				}

				.attribute_label {
					.attribute_label_left {
						display: inline-block;
						max-width: calc(100% - 125px);

						.label {
							margin-right: 10px;
							white-space: unset;
							display: inline;
						}

						.final-sale-color-msg {
							margin-left: 0;
							white-space: nowrap;
							letter-spacing: 0.5px;
							font-weight: bold;

							@media screen and (max-width: 767px) {
								font-size: 13px;
								letter-spacing: 0.43px;
							}
						}
					}
				}
			}
		}
	}
	.product-set-long-description {
		overflow: hidden;
		margin-top: 10px;
		position: relative;
		max-height: 4em;
		border-bottom: 1px solid $light-gray;
		padding-bottom: 10px;
		p {
			margin: 0 0 3px;
		}
		.read-more-content {
			display: none;
			position: absolute;
			bottom: -2px;
			padding: 8px 0;
			right: 0;
			width: 100%;
			text-align: left;
			background-color: $white;
			.read-more {
				text-decoration: underline;
				cursor: pointer;
				outline-offset: -3px;
				padding: 0;
			}
		}
		&.max-height-limit {
			padding-bottom: 25px;
			box-sizing: content-box;

			.read-more-content {
				display: inline-block;
			}
		}
		&.make-height-auto {
			max-height: none;
			padding-bottom: 0;
			.read-more-content {
				position: static;
				text-align: left;
				width: 100%;
				display: inline-block;
			}
		}
	}
	.product-primary-image {
		.b_product_icon {
			width: 33px;
			position: absolute;
			top: 7px;
			right: 7px;
			overflow: hidden;
			z-index: 1;
			cursor: pointer;
		}
		iframe {
			width: 100%;
			height: 100%;
		}
	}

	.last-visited-section {
		.last-visited-heading h2 {
			@include inherit_parent_props();
			color: $recommendation-heading;
		}
	}
}

.tab-content {
	&.bh-tab-shipping-return {
		.tab-heading {
			text-align: left;
			pointer-events: none;
		}
		.moreinfo {
			color: $warm-grey;
		}
		.shippinginfo {
			color: $dim-gray;
			display: block;
		}
		.clickhere {
			display: none;
		}
	}

	.tt-o-pagination {
		display: flex;
	}
}

.tab-headers {
	display: flex;

	.tab {
		width: 33.33%;
	}
}

#wrapper {
	&.pt_product-details {
		.quickview {
			display: none !important;
		}
		@media screen and (max-width: 767px) {
			.pdp-main {
				.product-col-1 {
					&.product-set,
					&.product-bundle {
						.product-primary-image {
							.pinch-zoom-container {
								display: block;
							}
						}
					}
					.product-primary-image {
						.pinch-zoom-container {
							display: none;
						}
					}
				}
				#tt-product-info .tabs {
					.tab-label {
						letter-spacing: 0.4px;
					}
				}
			}
		}
	}
}
@media screen and (min-width: 768px) {
	.pdp-main {
		.product-thumbnails,
		.enlarge-thumb-image {
			button {
				&.slick-arrow {
					overflow: hidden;
					&:focus {
						outline: none;
					}
				}
			}
		}
	}
}

.pdp-main {
	.product-name {
		text-transform: capitalize;
	}

	.product-col-2 {
		&.giftcard-detail,
		&.e-giftcard-detail {
			.make-label-absolute {
				.form-row {
					&.error-handle {
						label {
							span.label-text {
								display: none;
							}
						}
					}
				}
			}			
		}
	}

	.product-col-2 {
		&.e-giftcard-detail {			
			.gift-card{
				.variant-dropdown{
					.custom-select{							
						@media only screen and (max-width: 1023px){
							width: auto;
							> label + select.variation-select{
								margin-left: 0;
							}
						}
						@media only screen and (min-width: 768px) and (max-width: 1023px){
							margin-left: 5px;
						}
					}
				}				
			}
		}
	}

	.pdp-max-width {
		@media screen and (min-width: 1161px) {
			width: 85.5%;
			display: inline-block;
			max-width: 1000px;
		}

		&.edit-page {
			.top-wrap {
				display: none;

				@media screen and (max-width: 767px) {
					display: inline-block;

					.ratings {
						display: none;
					}
					.product-brand {
						display: none;
					}
				}
			}

			.info {
				.product-col-2 {
					.product-name {
						@media screen and (max-width: 767px) {
							display: none;
						}
					}
				}
				@media screen and (min-width: 768px) and (max-width: 1023px) {
					width: 45%;
				}
			}
		}
	}
	.promotion {
		.promotion-callout {
			.promo-tooltip {
				display: inline-block;
				vertical-align: middle;

				.tooltip-content {
					display: none;
				}
			}
		}
	}

	&.enlarge-container {
		.enlarge-hero-container {
			.slick-arrow {
				overflow: hidden;
				&.slick-disabled {
					opacity: 0.3;
					cursor: not-allowed;
					pointer-events: none;
				}
			}
			.thumb {
				display: none;
				&:first-child {
					display: block;
				}
			}
		}
	}
	.product-add-to-cart {
		&.specialProductset,
		&.add-sub-product {
			.add-all-to-cart,
			.sub-product-item.add-to-cart {
				width: 318px;
				white-space: normal;
				box-sizing: border-box;
				outline-offset: -3px;
			}
		}
		.shippingdetails {
			.heading {
				padding: 17px 5px 15px 0;
				cursor: pointer;
				display: block;
			}
			.shippingdrawer {
				padding-bottom: 10px;
				.productinfo {
					margin-top: 15px;
					span {
						color: $dim-gray;
					}
					.clickhere {
						text-transform: lowercase;
						text-decoration: underline;
						color: $dim-gray;
						background: none;
						width: initial;
						font-size: inherit;
						border: none;
						padding: 15px 0;
						&.opened {
							padding-left: 5px;
							float: left;
						}
					}
					#shippinginfoBtn {
						@media screen and (min-width: 320px) and (max-width: 1024px) {
							width: auto;
						}
					}
					.shippinginfo {
						padding-top: 10px;
						p {
							margin: 0;
							line-height: 20px;
							width: 100%;
							&:last-child {
								width: auto;
								float: left;
							}
						}
					}
				}
			}
		}
		.add-to-cart {
			display: inline-block;
			width: 280px;
		}
		.wishlist {
			text-align: left;
			@media screen and (max-width: 767px) {
				text-align: center;
			}
		}
	}

	.product-brand {
		font-family: $WorkSans;
		text-transform: uppercase;
		padding: 0 0 10px 0;
		color: $brand-name-color;
		display: inline-block;
	}
	.jessicalondon {
		color: $brand-name-color;
	}

	.product-col-2 {
		&.e-giftcard-detail,
		&.giftcard-detail {
			.e-gift-email {
				justify-content: space-between;
				input[type="text"] {
					color: $black;
				}
			}
		}
		&.product-set {
			&.bh-product-col-2-product-detail {
				width: 100%;
				padding: 0;
				float: none;
				margin-top: 41px;
				clear: both;
				display: inline-block;
			}
			@media screen and (max-width: 767px) {
				&.product-col-2 {
					span {
						&.product-variations-toggle {
							text-transform: uppercase;
							position: relative;
							margin-top: 20px;
							display: inline-block;
							width: 100%;
							padding: 15px 10px;
							box-sizing: border-box;
							border: 2px solid $black;
							text-align: center;
						}
					}
				}
			}
		}
		.attribute {
			.attribute_label {
				.selected-value {
					text-transform: uppercase;
				}
				.final-sale-color-msg {
					color: $salered;
					letter-spacing: 0.64px;
					line-height: normal;
					margin-left: 10px;
					@media screen and (max-width: 767px) {
						letter-spacing: 0.55px;
					}
				}
			}
		}
	}
	.savingmessage {
		clear: both;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.73px;
		color: #af3434;
		text-transform: uppercase;
		@media screen and (max-width: 767px) {
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.59px;
		}
		.value {
			color: $salered;
			line-height: 1.25;
			letter-spacing: 0.73px;
			font-size: $base-font + 4px;
			font-weight: 600;
			text-transform: uppercase;
			@media screen and (max-width: 767px) {
				line-height: 1.23;
				letter-spacing: 0.59px;
				font-size: $base-font + 1px;
			}
		}
	}
	.promotion {
		.callout-message {
			color: $salered;
		}
	}
	.product-teasers {
		.TTreviewcount {
			cursor: pointer;
		}
	}

	.product-col-1 {
		&.product-set {
			&.bh-product-col-1-product-set {
				width: 100%;
				> .product-primary-image {
					width: 38.1%;
					box-sizing: border-box;
					display: inline-block;
				}
				.bh-product-col-1-productdetail {
					width: 60.9%;
					padding: 0;
					display: inline-block;
					vertical-align: top;
					box-sizing: border-box;
					@media screen and (min-width: 1024px) {
						padding: 0 20px 0 3.2%;
					}
					.share-icon-container {
						display: block;
					}
				}
			}
		}
	}

	&.specialproductset {
		.pdp-max-width {
			&.sps-edit-page {
				.info {
					.product-col-2 {
						#product-set-list {
							margin: 0;
							border: none;

							.quickView-product-set-Details {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.ca-size-chart {
		background-color: #f1f0f6;
		padding: 8px;
		line-height: 1.46;
		font-size: 14px;
		margin: 10px 0px;
		border-radius: 2%;
		font-family: $Helvetica !important;
	}
	.ca-size-chart-link {
		text-decoration: revert;
		color: #000fc1;
	}
	.catherines-size-message {
		font-weight: bold;
		@media screen and (min-width: 768px) {
			display: inline;
			margin-right: 10px;
		}
	}
	.catherines-size-chart-link {
		font-size: 12px;
		@media screen and (min-width: 768px) {
			position: relative;
			margin-left: 30px;
		}
	}
	.size-chart-scale {
		border-radius: 50%;
		background: #3a2a7c;
		width: 20px;
		height: 20px;
		display: inline-block;
		position: relative;
		top: 4px;
		margin-right: 4px;
		display: none;
		@media screen and (min-width: 768px) {
			display: inline-block;
		}
	}
	.size-chart-scale-icon {
		height: 18px;
		width: 21px;
		padding: 3px 4px;
		position: relative;
		display: none;
		@media screen and (min-width: 768px) {
			display: inline-block;
		}
	}
}

.prod-detail-page {
	.image {
		width: 100%;
		float: left;
		max-width: 400px;
		margin: 0 80px 0 0px;
		min-height: 100px;
		position: relative;
		@media screen and (max-width: 1023px) {
			clear: left;
			width: 100%;
			max-width: 352px;
			margin-right: 20px;
		}
		@media screen and (max-width: 767px) {
			max-width: 100%;
			width: 100%;
			float: none;
			margin: 0 auto;
			.product-primary-image.not-a-device {
				display: none;
			}
		}
		.product-col-1 {
			width: 100%;
			box-sizing: border-box;
			&.e-giftcard-detail .product-thumbnails {
				display: none;
				@media screen and (max-width: 767px) {
					display: block;
				}
			}
			@media screen and (max-width: 1023px) {
				width: 100%;
			}
			@media screen and (max-width: 767px) {
			}
		}
	}
	.top-wrap,
	.info {
		width: auto;
		max-width: 440px;
		min-width: 440px;
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;
		margin: 0 0 0 10px;
		padding: 0;
		@media screen and (max-width: 1023px) {
			width: auto;
			display: inline-block;
			max-width: 36%;
			min-width: inherit;
			margin: 0 0 0 10px;
		}
		@media screen and (max-width: 767px) {
			max-width: 100%;
			width: 100%;
			float: none;
			min-width: auto;
			padding: 0;
			margin: 0 auto;
			display: block;
		}
		.product-col-2 {
			width: 100%;
			padding: 0;
			box-sizing: border-box;
			@media screen and (max-width: 1023px) {
				width: 100%;
			}
		}
	}

	.top-wrap {
		@media screen and (max-width: 1023px) {
			display: inline-block;
		}
	}
	&.s-product-set {
		.share-icon-container {
			display: none;
		}
		@media screen and (max-width: 767px) {
			.image {
				.product-primary-image.not-a-device {
					display: block;
				}
			}
		}
	}
	.turntofit {
		.turntofitcounts {
			cursor: pointer;
		}
	}
}
.recommendations,
.product-listing {
	.b_product_badge {
		display: none;
	}
}
.product-set {
	.share-icon-container {
		display: none;
	}
	.product-col-2-productdetail {
		.share-icon-container {
			display: block;
		}
	}
}
.pt_product-details #main .pdp-main .product-col-wrapper {
	@media screen and (max-width: 1023px) {
		border-spacing: 10px 0;
	}
}
@media screen and (max-width: 1023px) {
	.pdp-main {
		.product-col-2 {
			&.e-giftcard-detail,
			&.giftcard-detail {
				.product-variations {
					&.gift-card {
						.attribute {
							&:first-child {
								border-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
#QuickViewDialog {
	padding: 0 20px;
	box-sizing: border-box;
	.pdp-main {
		.product-col-2 .attribute {
			.attribute_label {
				.final-sale-color-msg {
					line-height: normal;
					letter-spacing: 0.59px;
					font-size: 13px;
				}
			}
		}
		.savingmessage .value {
			display: none;
		}
	}
	.pdp-main .product-add-to-cart {
		border: none;
		padding: 0;
		button#add-to-cart {
			width: 254px;
			&.atb-disabled {
				padding-left: 0;
				padding-right: 0;
				color: $white;
			}
		}
	}
	.prod-detail-page {
		margin-top: 0;
		display: table;
		border-spacing: 5px 0;
		max-width: 700px;
		.image {
			width: 42%;
			float: none;
			display: table-cell;
			margin: 0 auto;
			vertical-align: top;
			padding: 0 20px 0 0;
			.product-col-1 {
				padding: 0;
				float: none;
				width: auto;
				.enlarge-video-sec.hide-mobile {
					padding: 10px 0 0;
				}
				.product-primary-image {
					height: auto;
					.product-image.main-image {
						max-width: 304px;
						width: 100%;
						margin: 0 auto;
						img.primary-image {
							max-width: 304px;
						}
					}
				}
				.product-thumbnails {
					max-width: 275px;
					padding: 0;
					min-width: 95%;
					width: auto;
				}
			}
		}
		.info {
			width: 52%;
			padding: 0;
			margin: 0 auto;
			display: table-cell;
			vertical-align: top;
			max-width: 373px;
			.product-col-2 {
				padding: 0;
				float: none;
				width: auto;
				max-width: 395px;
				min-width: 350px;
			}
			.slimScrollDiv,
			.product-variation-content {
				max-width: 100%;
				max-height: 100%;

				&.max-height {
					height: 465px;
				}
			}
			.slimScrollDiv {
				border-bottom: 1px solid $alto;
				padding: 0 20px 0 0;
			}
			.pdpForm fieldset {
				padding: 10px 0 0;
			}
			.product-variation-content {
				overflow: hidden;
			}
		}
	}
	.view-details {
		font-size: $base-font + 2px;
		border-top: 1px solid $alto;
		letter-spacing: 1px;
		color: $black;
		text-align: center;
		text-transform: uppercase;
		width: 100%;
		padding: 20px 0;
		margin: 10px auto 0;
	}
	.clearboth {
		display: none;
		&.padding-eight {
			display: block;
		}
	}
	.pdp-main.specialproductset {
		border-spacing: 5px 0;
		.image {
			.product-col-1 {
				.product-thumbnails {
					margin: 35px 0 0;
				}
			}
		}
		.product-bundle .product-col-2.product-detail .product-set-details .product-variations .attribute {
			border-top: none;
			border-bottom: 1px solid $alto;
		}

		.product-set .product-set-list .product-set-details .qv-productset-scrollbar {
			display: block;
		}
		.product-set .product-set-list .product-set-image {
			padding: 40px 0 0;
			border-bottom: none;
		}
		.product-bundle-main .product-col-2 .product-set-list.product-set-list-sps .product-set-item {
			border-top: 1px solid $alto;
		}
	}

	.prod-detail-page .image .product-col-1 .product-primary-image {
		display: block;
		width: 304px;
	}

	#pdpMain,
	.product-col-wrapper.adjust-inner-content.prod-detail-page {
		&:after,
		&:before {
			content: "";
			display: none;
			clear: none;
			border: none;
		}
	}
	.share-icon-container {
		display: none;
	}
	.pdp-main {
		.product-variations {
			.attribute {
				&.variant-dropdown {
					&.option-top {
						.selection-list {
							border-top: 1px solid $black;
							border-bottom: none;
							top: auto;
						}
					}
				}
			}
		}
		.promotion {
			.promotion-callout {
				.promo-tooltip {
					display: inline-block;
					vertical-align: bottom;
					padding: 1px 5px;
				}
			}
		}
	}
}
//end
.product-details-container {
	letter-spacing: 0.025em;
}

.zoomContainer.primaryZoom {
	z-index: 1;
}
.js-quickorder-zoom-container .zoomContainer.primaryZoom {
	z-index: 2;
}


@media screen and (min-width: 1024px) {
	.enlarge-dialog {
		&.ui-dialog {
			max-height: 700px !important;
			.ui-dialog-content {
				max-height: 700px !important;
				.enlarge-container {
					max-height: 700px !important;
					.enlarge-hero-image {
						max-height: 700px;
						.enlarge-hero-container {
							max-height: 700px;
						}
						.thumb {
							max-height: 700px;
							text-align: center;
							img {
								max-height: 700px !important;
								min-height: 700px !important;
							}
						}
					}
				}
			}
		}
	}
	.js {
		.zoomContainer {
			.js-zoom-container {
				max-height: 700px;
				.zoomWindow {
					max-height: 700px;
				}
			}
		}
		&.pdp-page .overflow-hidden{
			.zoomContainer {
				&.js-zoom-container {
					z-index: 9999;
				}
			}
		}
	}
}
@media screen and (max-width: 1024px) {
	.adjust-inner-content {
		padding: 0;
		box-sizing: border-box;
	}
}
@media screen and (max-width: 1023px) {
	.adjust-inner-content {
		padding: 0;
	}
	.pt_product-details {
		#main {
			.e-giftcard-detail {
				.product-add-to-cart {
					.egift-prod {
						margin-bottom: 17px;
					}
					.shippingdetails {
						margin-top: 0;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	#QuickViewDialog {
		.pdp-main .product-add-to-cart {
			button#add-to-cart {
				width: 254px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.zoomWindowContainer,
	.zoomContainer {
		display: none;
	}
	.pt_product-details {
		#main {
			.adjust-inner-content:not(.s-product-set) {
				padding: 0 10px;
			}
			.product-thumbnails {
				.thumb:not(.selected) {
					display: none;
				}
				&.slick-initialized {
					.thumb:not(.selected) {
						display: block;
					}
				}
				.slick-dots {
					margin: 15px 0 15px 0;
					width: auto;
					position:static;
					li {
						margin-left: 10px;
						height:auto;
						width:auto;
						button {
							text-indent: -99999px;
							border: 1px solid $very-light-gray;
							background: $very-light-gray;
							width: 10px;
							border-radius: 50%;
							padding: 0;
							height: 10px;
						}
						&.slick-active {
							button {
								border: 1px solid $black;
								background: $black;
							}
						}
					}
				}
			}
		}
		.pdp-main {
			.product-teasers {
				.TTratingBox {
					margin: 0;
				}
				.rating-text {
					margin: 0;
				}
			}
		}
	}
}

.pt_product-details,
.ui-dialog.quick-view {
	.make-label-absolute {
		.form-row {
			position: relative;

			&.confirmemail,
			&.email {
				&.error-handle {
					label {
						span {
							&.label-text {
								display: none;
							}
						}
					}
				}
			}

			&.error-handle {
				label {
					span {
						&.label-text {
							display: block;
						}
					}
				}
			}
		}

		label {
			font-weight: normal;
			font-size: $base-font;
			padding: 0;
			position: absolute;
			-webkit-transform: translate(5px, 20px);
			transform: translate(5px, 20px);
			-ms-transform: translate(5px, 20px);
			left: 9px;
			margin: 0;
			top: -3px;
			width: auto;
			color: $dim-gray;
			z-index: 1;
			-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
			transition: transform 0.3s ease, font-size 0.3s, color 0.3s;

			span {
				color: $dim-gray;
				text-transform: none;
				font-size: $base-font;

				&.required-indicator {
					display: none;
				}
			}
			&.has-value,
			&.input-focus {
				-webkit-transform: translate(5px, 5px);
				transform: translate(5px, 5px);
				-ms-transform: translate(5px, 5px);
			}
		}
	}
}

.quick-view {
	.slimScrollRail,
	.slimScrollBar {
		-moz-border-radius: 0 !important;
		border-radius: 0 !important;
		-webkit-border-radius: 0 !important;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)" !important; // IE8
		filter: alpha(opacity=1) !important; // IE 5-7
		opacity: 1 !important;
		background-color: #666 !important;
	}
	.slimScrollRail {
		background-color: #bababa !important;
	}
}
.ui-dialog.quick-view.product-set-quickview {
	max-width: 900px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.prod-detail-page {
		.top-wrap,
		.info {
			max-width: 45%;
		}
	}
}

@media screen and (min-width: 768px) {
	.pt_product-details {
		#main {
			.pdp-main {
				.prod-detail-page {
					.top-wrap {
						.product-name {
							margin-top: 0;
						}
					}
				}
				.product-name {
					margin-top: 0;
				}
			}
		}
	}
}

/*WCAG - pdp thumnails focus */
@include tablet-up {
	.product-thumbnails .slick-slide .thumb {
		width: 96% !important;
		margin: 2px;
	}

	//SC-7129
	#product-content[data-truefit-loaded="true"] .tfc-fitrec-product {
		min-height: 35px;
	}
}
.size-chart-link a {
	display: block;
}

#wrapper #main .pdp-main .pdp-horizontal .recommendations-h .recommendations-heading {
	color: $recommendation-heading;
	opacity: 1;
}

.product-thumbnails .slick-slide .thumb .thumbnail-link {
	display: block;
}

.TurnToReviewsTeaser {
	float: left;
	margin-right: 20px;
	.TTratingLinks {
		margin-top: 4px;
		color: $writeReview;

		a {
			color: $writeReview;
			&:hover {
				color: $writeReview;
			}
		}
	}
}
.TurnToItemTeaser {
	margin-top: 4px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  .search-result-items.normal-image-plp .new-plp-tile .product-tile .product-image img.p-image, .search-result-items.large-image-plp .new-plp-tile .product-tile .product-image img.p-image{width:auto;}
  .search-result-items.normal-image-plp .new-plp-tile .product-tile .product-image img, .search-result-items.large-image-plp .new-plp-tile .product-tile .product-image img{width:auto;}
  .product-tile .product-name-image-container .name-link{width:auto;}
}

.tt-o-button--icon.tt-o-button--disabled, button.tt-o-button--icon.tt-o-button--disabled{
    background-color: transparent !important;
}

.product-variations .swatchanchor img.hide-swatch {
	visibility: hidden;
}
//New class added for You may also like section across all brands
#wrapper #main .primary-content .pdp-main .pdp-horizontal .you-may-like{box-sizing:border-box;}

//sc-11149 class added for marketplcae no swatch image
.product-variations .color li.marketplace-no-swatch a.color.color-swatch {
	background-repeat: no-repeat !important;
    background-size: 200% !important;
    background-position: center center !important;
}

//SC-11333
#main .pdp-main .pdp-horizontal .recommendations-h .recommendations .product-tile .product-image {
    min-height:300px;
	img{
		max-height:300px;
	}
}

.pt_product-details #main .specialproductset .product-add-to-cart {
	padding-top:20px;
	.button-with-error-msg {
		button {
			width: 70%;
		}
	}
}

.pdp-main .product-add-to-cart .collapsible-lg button{
	background: none;
	color:$black;
	text-transform: uppercase!important;
	&+div{
		font-size:0.8rem;
		font-weight:bold;
	}
}
.product-variation-content .swatches label.hide, #size-variants-container .swatches label.hide{
	display:none;
}

.shippingdetails .collapsible-lg:not(.active) .content{
	display: none;
}
.shippingdetails .collapsible-lg.active .title::after {
    float: right;
    content: "\F077";
    font-family: "FontAwesome";
}

.shippingdetails .collapsible-lg:not(.active) .title::after {
    float: right;
    content: "\F078";
    font-family: "FontAwesome";
}

.ui-dialog.ui-corner-all.ui-widget.ui-widget-content.ui-front.quick-view{
	max-width:1024px!important;
	left:50%!important;
	transform: translate(-50%);
	top:0%!important;
	overflow-y:visible;
	
}

[data-page=productdetails] .ui-tooltip {
	background: $white;
	border: 2px solid $black;
	box-shadow: none;
}

#QuickViewDialog #main .pdp-main .pdp-horizontal .pdp-max-width{
	min-width: 100%;
	.info{
		width: 52%;
    	max-width: 373px;
		min-width:52%;
	}	
}

a#bread-payments-product-placement {
	overflow: inherit; //needed to negate legacy-sitegen rules of SPS pages
}


#QuickViewDialog .product-variations .attribute ul.swatches {
	padding-left: 4px;
}

.pt_product-details #main .specialproductset.sw-specialproductset.pdp-main .product-set-list .availability-web {
	.not-available-msg,
	.backorder-msg {
		b {
			color: $fire-engine-red;
		}
	}
}

#QuickViewDialog .prod-detail-page .perks-link {
	font-family: var(--skin-body-font);
    color: var(--link-1);
    font-weight: 400;
}

.tfc-fitrec-product {
	min-height: 37px;
}