.catalog-thankyou-main {
	max-width: 964px;
	margin: 0 auto;

	h3 {
		margin: 0;
		text-transform: uppercase;
		font-family: inherit;
		font-size: $base-font;
	}
	.catalog-thankyou {
		font-size: $base-font + 2px;
		display: inline-block;
		width: 100%;
		margin: 34px 0 20px 16px;
	}

	.catalog-thankyou-heading {
		font-size: $base-font + 2px;
		font-family: $Helvetica;
		margin: 0 0 19px 15px;
		display: inline-block;
		width: 100%;
	}
	.catalog-thankyou-header {
		font-size: $base-font + 13px;
		line-height: 1.3;
		letter-spacing: normal;
		text-align: left;
	}
	.catalog-address {
		margin: 0 0 10px 15px;
		font-size: $base-font + 2px;
		font-family: $Helvetica;
		display: inline-block;
		width: 100%;
	}

	.catalog-current {
		margin: 0 0 27px 16px;
		font-size: $base-font + 2px;
		font-family: $Helvetica;
		display: inline-block;
		width: 100%;
		text-transform: capitalize;
	}

	.catalog-left {
		width: 15%;
		float: left;
	}

	.catalog-right {
		float: left;
		width: 85%;
	}

	.current-brand-image {
		max-width: 117px;
		width: 100%;
	}

	.brand-logo {
		margin: 0 0 11px 0;
		width: 142px;
		max-width: 100%;
		img {
			height: 27px;
		}
	}

	.current-brand-section {
		width: 100%;
		display: inline-block;
		padding: 0 0 24px 15px;
		box-sizing: border-box;
	}

	.current-brand-benefits {
		font-size: $base-font;
		font-family: $Helvetica;
		width: 57%;
	}

	.sister-brands {
		width: 100%;
		display: inline-block;
	}

	.sister-brand-message {
		font-size: $base-font + 12px;
		padding: 15px 0 15px 13px;
		width: 100%;
		display: inline-block;
		border-top: 1px solid $alto;
		border-bottom: 1px solid $alto;
		margin: 0;
		background-color: $light-skygrey;
		box-sizing: border-box;
	}

	.sister-brand-rows {
		padding: 22px 28px 37px 17px;
		border-bottom: 1px solid $alto;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;

		&.row-last {
			border-bottom: none;
		}
	}

	.sister-brand-section {
		.form-row-button {
			float: right;
			margin: 2.3% 0 4% 0;
		}
	}

	.sister-brand-image {
		width: 15.3%;
		float: left;
	}

	.sister-image-size {
		max-width: 100px;
		width: 100%;
	}

	.sister-brand-right {
		width: 84%;
		float: left;

		.form-row {
			width: 17.6%;
			float: right;
			text-align: left;
			font-size: $base-font + 1px;
			font-family: $Helvetica;
			margin-top: 4px;
			line-height: 25px;
		}

		input[type="checkbox"] {
			border: none;
			background: url(../../../images/checkbox.svg) no-repeat center;
			width: 25px;
			height: 24px;
			outline: none;
			margin: 0px 4px 0 1px;
			&:checked {
				background: url(../../../images/checkmark.svg) no-repeat center;
				width: 25px;
				height: 24px;
			}
		}

		.brand-logo {
			width: 142px;
		}
	}

	.current-brand-description {
		width: 63.7%;
		float: left;
		margin: 0 18.5% 0 0;
		line-height: 19px;
	}

	.sister-request-submit {
		padding: 14px 83px;
		font-family: $ImperialRegular;
		letter-spacing: 1px;
		color: $white;
		background-color: $wine-berry;
		border-color: $wine-berry;
	}
}

//Catalog Preference confirmation
.catalog-confirmation {
	max-width: 1022px;
	margin: 0 auto;

	.catalog-request-header {
		font-size: $base-font + 12px;
		display: inline-block;
		width: 100%;
		font-family: $ImperialRegular;
		padding: 0 0 0 3.2%;
		margin: 31px 0 14px 0;
		box-sizing: border-box;
		letter-spacing: 1px;
	}

	.catalog-request-confirmation {
		font-family: $Helvetica;
		font-size: $base-font + 1px;
		width: 100%;
		display: inline-block;
		margin: 0 0 28% 0;
		padding: 0 0 0 3.4%;
		box-sizing: border-box;
		line-height: 27px;
	}

	.catalog-print-preference {
		.catalog-request-header {
			padding: 0;
			margin: 30px 0 15px;
		}

		.catalog-request-confirmation {
			padding: 0;
		}
	}

	.qa-section {
		.question-title {
			padding: 25px 25px 25px 42px;
		}
		.qa-content {
			padding: 24px 38px 20px 44px;
			&.active {
				padding: 24px 38px 35px 44px;
			}
			.question {
				font-family: $HelveticaBold;
				font-size: $base-font;
				letter-spacing: 1px;
			}

			.answer {
				font-family: $Helvetica;
				font-size: $base-font + 2px;
				&.active {
					padding: 7px 0 0 0;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.catalog-confirmation {
		margin: 0 20px;
	}
}

@media screen and (max-width: 1023px) {
	.catalog-thankyou-main {
		margin: 0 20px;

		.current-brand-section {
			padding: 0 0 30px 0;
		}

		.catalog-left {
			width: 20%;
		}

		.catalog-right {
			width: 79%;
		}

		.current-brand-benefits {
			width: 71%;
		}

		.sister-brand-rows {
			padding: 22px 18px 33px 0;
		}

		.sister-brand-image {
			width: 20%;
		}

		.sister-brand-right {
			width: 79%;

			.form-row {
				width: 16%;
			}
		}

		.request-sister-brand-text {
			display: inline-block;
			width: 60px;
			line-height: 15px;
			margin-top: 5px;
		}

		.current-brand-description {
			width: 77%;
			margin: 0 5% 0 0;
		}

		.sister-request-submit {
			padding: 14px 76px;
		}
	}

	//Catalog Preference confirmation
	.catalog-confirmation {
		margin: 0 20px;

		.catalog-request-header {
			padding: 0;
		}

		.catalog-request-confirmation {
			padding: 0;
		}

		.qa-section {
			.qa-content {
				.question {
					font-size: $base-font;
				}

				.answer {
					font-size: $base-font + 1px;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.catalog-thankyou-main {
		margin: 0;
		.morecontent span {
			display: none;
		}

		.morelink {
			display: inline;
			font-size: $base-font;
			font-family: $Helvetica;
			color: $disco;
		}
		.current-brand-description {
			p {
				display: inline;
			}
		}

		.catalog-thankyou {
			padding: 0 20px;
			box-sizing: border-box;
			margin: 0;
		}

		.catalog-thankyou-heading {
			display: none;
		}
		.catalog-thankyou-msg {
			display: none;
		}
		.catalog-address {
			padding: 0 20px;
			box-sizing: border-box;
			margin: 0 0 10px 0;
		}

		.catalog-current {
			padding: 0 20px;
			box-sizing: border-box;
			margin: 0 0 27px 0;
		}

		.current-brand-section {
			padding: 0 20px 30px 20px;
			box-sizing: border-box;
		}

		.catalog-left {
			width: 22%;
			float: right;
		}

		.catalog-right {
			width: 78%;
		}

		.current-brand-benefits {
			width: 83%;
			font-size: $base-font + 1px;
		}

		.current-brand-name {
			.brand-logo {
				margin: 0 0 5px 0;
			}
		}

		.sister-brand-rows {
			padding: 22px 20px 33px 20px;
			box-sizing: border-box;
			.brand-logo {
				margin: 0 0 20px 0;
			}
		}

		.sister-brand-image {
			width: 22%;
			float: right;
		}

		.sister-brand-right {
			width: 78%;
			float: right;
		}

		.sister-brand-section {
			.form-row {
				margin: 20px 0 0 0;
				font-size: $base-font + 1px;
				input[type="checkbox"] {
					border: none;
					background: url(../../images/checkbox.svg) no-repeat center;
					width: 25px;
					height: 24px;
					outline: none;
					margin: 0px 4px 0 1px;
					&:checked {
						background: url(../../images/checkmark.svg) no-repeat center;
						width: 25px;
						height: 24px;
					}
				}
			}

			.form-row-button {
				float: none;
				padding: 0 20px;
				width: 100%;
				box-sizing: border-box;
			}
		}

		.sister-request-submit {
			width: 100%;
		}
	}

	//Catalog Preference confirmation
	.catalog-confirmation {
		margin: 0 10px;
	}
}

@media screen and (max-width: 480px) {
	.catalog-thankyou-main {
		.sister-brand-image {
			width: 33%;
			float: right;
		}

		.sister-brand-right {
			width: 67%;
		}

		.catalog-left {
			float: right;
			width: 33%;
			margin: 13px 0 0 0;
		}

		.catalog-right {
			width: 67%;
		}
	}
}
