$sans-serif: "Inter Tight", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$serif: TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif;

$Helvetica: $sans-serif;
$HelveticaMedium: $sans-serif;
$HelveticaBold: $sans-serif;
$ImperialRegular: $serif;
$ImperialMedium: $serif;
$BauerBodoniRegular: $serif;
$WorkSans: $sans-serif;
$sans-serif-alt: $sans-serif;
$HurmeLight: $sans-serif;
$HurmeSemiBold: $sans-serif;
$WorkSansBold: $sans-serif;
$OpenSans: $sans-serif;
$Frutiger: $sans-serif;
$FrutigerBold: $sans-serif;
$Gotham: $sans-serif;
$Lonotype: "linotype-didot", $serif;

@mixin GothamMedium($Gotham) {
	font-family: $Gotham;
	font-weight: 500;
}
