.item-list {
	margin-bottom: 1em;
	tr {
		border-bottom: 1px solid $gainsboro;
		&.last {
			border: 0 none;
		}
		&.headings {
			display: none;
		}
	}
	td {
		padding: 1em;
		vertical-align: top;
	}
}
.pt_cart {
	.cart-actions-top {
		.cart-recommendations {
			max-width: 195px;
			.you-may-like {
				ul.recommendation-tiles {
					margin: auto;
					padding: 0;
					.product-image {
						max-width: 80px;
					}
				}
			}
		}
	}
}

.cart-mini-overlay {
	.mini-cart-content {
		.cart-recommendations {
			.you-may-like {
				.einstien-reommendations {
					ul.recommendation-tiles {
						padding: 0;
					}
				}
			}
		}
	}
}
.item-availability ul,
.item-quantity-details ul {
	list-style: none outside none;
	margin: 0;
	padding: 0;
}
.item-dashboard {
	color: $dark-gray;
	width: 16rem;
	label {
		font-weight: normal;
		width: auto;
	}
	.field-wrapper {
		width: auto;
	}
	.option-quantity-desired {
		input {
			width: 3rem;
		}
	}
	.value {
		margin-top: 0.5rem;
	}
	.form-row {
		margin: 0;
	}
	input,
	.value,
	select .option-toggle-public label {
		color: $night-rider;
	}
	.input-checkbox {
		margin: 0 5px;
	}
	.option-add-to-cart {
		margin-top: 0.5rem;
		width: 18rem; //for IE
		width: max-content;
		width: -moz-max-content;
		width: -webkit-max-content;
		width: -o-max-content;
		label {
			color: $night-rider;
			float: left;
			font-weight: 700;
			margin: 0.5rem 0.5rem 0 0;
			text-transform: uppercase;
			width: auto;
		}
		input {
			width: 3rem;
			margin-right: 1em;
		}
		button {
			padding: 0.5em 1.5em;
		}
	}
	.option-quantity-purchased span.exceeded {
		color: $sangria;
	}
}

// design for SEO text in PLP
.category-seo-text {
	width: 100%;
	float: left;
	padding: 20px;
	box-sizing: border-box;
	border-top: 1px solid $alto;

	// SC-9149
	.read-more, .read-less{
		display: none;
		color: $black;
		font-size: $base-font + 2;
		vertical-align: middle;		
		text-decoration: underline;					
		&:hover{
			text-decoration: none;
		}
	}

	@media only screen and (max-width: 1023px){

		overflow: hidden;

		> .content-inline{
			display: inline;
			vertical-align: middle;	
		}

		&:not(.expand){			
			> .content-inline{
				> :not(:first-child):not(style), :not(:first-child):not(style) > :not(:first-child):not(style){
					position: absolute;
					left: -999999px;
				}
	
				> :first-child:not(style), > div > :first-child, > style + div:not(:first-child):not(style){
					display: inline;
					vertical-align: middle;		
					position: static;			
				}	
				~ .read-more{
					display: inline-block;
					position: static;					
				}
			}	
		}

		&.expand{
			> .content-inline{
				~ .read-less{
					display: inline-block;
					position: static;
				}
				> :last-child, > div > :last-child{
					display: inline;
					vertical-align: middle;	
				}
			}
		}

	}
	// End: SC-9149

	p {
		margin: 10px 0;
		font-size: 14px;
		text-align: justify;
	}
	h1 {
		font-size: 24px;
		margin: 10px 0;
		display: inline-block;
		width: 100%;
	}
	.left-content {
		width: 48%;
		float: left;
	}
	.right-content {
		width: 48%;
		float: right;
	}
}

.pt_product-search-result {
	&.product-search-page {
		.header-promo-bottom {
			.promo-banner {
				margin: 17px auto 12px;
			}
		}
		.category-search {
			.simplesearch {
				padding: 16px 11px 5px 22px;
				button {
					margin: 5px 22px 0 0;
					overflow: hidden;
					&.close-icon {
						display: none;
					}
				}
			}
		}
		.refinements {
			.refinement {
				.refinement-heading-category {
					padding-right: 21px;
				}
			}
		}
	}
}
.content-banner-breadcrumb {
	position: relative;
	max-width: 1260px;
	margin: 0 auto 40px;
	@media screen and (max-width: 1023px) {
		margin: 0 auto;
	}
}
@media screen and (max-width: 767px) {
	#studio-sliding-nav {
		padding-bottom: 0 !important;
	}
	.slot-grid-header {
		&.no-category-banner {
			height: auto;
		}
		padding-bottom: 0 !important;
	}
}

.content-banner-breadcrumb .slick-slide a.block {
	margin: 3px 0 0 3px;
}
