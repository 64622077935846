.pt_product-search-noresult {
	.primary-content {
		width: 100%;
		max-width: 1260px;
		margin: 0 auto;
		float: none;
		border: none;

		.section-header {
			clear: both;
			border-bottom: none;
			margin: 0;
			font-weight: normal;
			text-transform: lowercase;

			&:first-letter {
				text-transform: uppercase;
			}

			p {
				margin: 0;
			}

			.no-hits-search {
				padding: 0;

				input[type="text"] {
					border: none;
					background: transparent;
					text-transform: capitalize;
					width: 90%;
					padding: 0;
					outline: none;
					@include prefix(box-sizing, border-box, $prefixArray);
				}

				button {
					float: right;
					border: none;
					text-indent: -9999999px;
					padding: 0;
					background: url("../../../../images/big-search-icon.png") no-repeat;
					background-size: 100%;
					overflow: hidden;
					@include prefix(border-radius, 0, $prefixArray);
				}
			}
		}
	}
}

.popular-searches {
	text-align: center;
	width: 100%;

	.no-results-heading {
		text-transform: capitalize;
	}

	.search-brands {
		width: 100%;

		a {
			text-transform: uppercase;
		}
	}

	.did-you-mean {
		&:first-letter {
			text-transform: uppercase;
		}

		.no-hits-search-term-suggest {
			text-transform: uppercase;
			font-style: normal;
		}
	}

	.shop-all {
		.button {
			letter-spacing: 1.65px;
			color: $dim-gray;
			border-color: $dim-gray;
		}
	}
}

//Trending Now
.trending-section {
	padding: 0;
	width: 100%;
	display: inline-block;

	.you-may-like {
		max-width: 1260px;
		margin: 0 auto;
		border-top: 1px solid $alto;
	}

	.recommendation-heading {
		text-align: center;

		span {
			width: 100%;
			display: inline-block;
			text-transform: capitalize;
			&.trending {
				color: $recommendation-heading;
			}
		}
	}

	.carousel-recommendations {
		width: 100%;
		display: inline-block;
		text-align: center;

		.product-tile {
			width: calc(20% - 4px);
			width: -moz-calc(20% - 4px);
			width: -webkit-calc(20% - 4px);
			width: -o-calc(20% - 4px);
			float: none;
			display: inline-block;
			vertical-align: top;
			@include prefix(box-sizing, border-box, $prefixArray);

			.product-swatches {
				display: block;
			}

			.p-image-rollover {
				display: none;
			}
		}

		button {
			&.slick-arrow {
				width: 17px;
				height: 23px;
				text-indent: -9999px;
				position: absolute;
				bottom: 0;
				margin: 0 auto;
				z-index: 9;
				-webkit-transition: initial;
				transition: initial;
				-ms-transition: initial;
				padding: 0;
				border: 0;
				top: 50%;
			}

			&.slick-prev {
				left: -20px;
				background: url(../../../../images/trending-arrows-left.svg) no-repeat;

				&.slick-disabled {
					background: url(../../../../images/recently-viewed-arrows-left-unactivated.svg) no-repeat;
					cursor: not-allowed;
				}
			}

			&.slick-next {
				right: -20px;
				background: url(../../../../images/trending-arrows-right.svg) no-repeat;

				&.slick-disabled {
					background: url(../../../../images/recently-viewed-arrows-right-unactivated.svg) no-repeat;
					cursor: not-allowed;
				}
			}
		}
	}
}
