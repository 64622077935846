.ui-dialog {
	&.cart-overlay {
		max-height: 95vh;
		overflow-y: scroll;

		.cta-button-wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-left: 5%;
			margin-right: 5%;
			@media screen and (max-width: 767px) {
				margin-left: 2%;
				margin-right: 2%;
			}

			.button {
				flex-basis: 46.8%;
				margin-bottom: 14px;
				padding-left: 0;
				padding-right: 0;
			}

			.primary {
				flex-basis: 100%;
			}

			@media screen and (max-width: 767px) {
				.button {
					flex-basis: 100%;
				}
			}
		}

		.slick-arrow {
			border: 0;
			font-size: 0;
			position: absolute;
			top: 34%;
			z-index: 9;
			padding: 0;
			width: 30px;
			height: 30px;

			&.slick-next {
				background: url("../../images/Right_ArrowBlack.svg") no-repeat center left / contain;
				right: 0;
			}

			&.slick-prev {
				background: url("../../images/Left_ArrowBlack.svg") no-repeat center left / contain;
				margin-left: 14px;
			}

			&.slick-disabled {
				opacity: 0.25;
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
				filter: alpha(opacity=25);
				-moz-opacity: 0.25;
				-khtml-opacity: 0.25;
			}
		}

		.ui-dialog-titlebar {
			&.ui-widget-header {
				.ui-dialog-title {
					@media screen and (max-width: 767px) {
						font-size: $base-font + 4px;
					}

					@media screen and (max-width: 480px) {
						font-size: $base-font;
						margin-top: 16px;
						display: inline-block;
						margin-left: 1%;
					}
				}
			}
		}

		.mini-cart-content {
			.mini-cart-product {
				.right-content {
					.product-availability-list {
						margin-bottom: 15px;
						span {
							&.label {
								margin: 0;
							}
						}
					}

					.mini-cart-pricing {
						.product-price {
							.price-standard {
								span {
									display: initial;
								}
							}
						}
					}

					div {
						span {
							&.label {
								&.baseprice {
									margin-bottom: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.cart-overlay .you-may-like {
	.product-tile {
		min-height: 210px;
		.b_product_badge,
		.b_product_icon,
		.stickerToolTip {
			display: none;
		}
		.product-pricing{
			font-size: $base-font;
		}
	}
}

@media screen and (max-width: 767px) {
	.ui-dialog {
		&.cart-overlay {
			.mini-cart-content {
				.mini-cart-product {
					.right-content {
						.product-availability-list {
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
	.cart-overlay-html.legacy-sitegen button[value="View Bag"]{
		margin:10px 0;
	}
}
