@media print {
	body {
		-webkit-print-color-adjust: exact;
	}
	header, .header, .secondary-header-section, footer, .breadcrumb, .carousel-nanobar, .container-fluid.brand-cont, .print-page, #secondary {
		display: none !important;
	}
	.tracking-status-container {
		flex: none !important;
	}
	.order-detail-with-order-summary {
       
        .wrap {
            width: 26%;
            
            .order-payment-summary {
                padding: 16px;
                .order-detail-summary {
                    width: 100%;
                }
            }
        }
    }
	.confirmation-left-section.ot .line-items {
		padding-right: 10px;
	}
}
@media print and (min-width: 500px) {
	.order-detail-with-order-summary {
		flex-direction: row !important;
	}
	.wrap {
		&.plcc-content {
			order: 1 !important;
		}
		&.od {
			order: 2 !important;
		}
		&.os {
			order: 3 !important;
		}
	}
}
.only-for-mobile {
	display: none;
}
.order-detail-show {
	display: none !important;
}
.pt_order {
	.orderdetails {
		.order-shipments {
			.order-shipment-table {
				.order-shipment-details {
					.heading {
						margin: 15px 0 10px 5px;
						@media screen and (max-width: 767px) {
							text-transform: uppercase;
							width: auto;
							display: inline-block;
							margin: 15px 0 0;

							&:after {
								content: ":";
							}
						}
					}
					.value {
						@media screen and (max-width: 767px) {
							display: inline-block;
							width: 72%;
						}
					}
				}
				&.shipping-exception {
					.order-shipment-details {
						.method {
							width: 19%;
							max-width: 160px;
							display: inline-block;
							vertical-align: top;

							@media screen and (max-width: 767px) {
								width: 100%;
								max-width: 100%;

								.shipment-method {
									max-width: 100%;
								}
							}
						}

						.heading {
							margin: 15px 0;

							@media screen and (max-width: 767px) {
								text-transform: uppercase;
								width: auto;
								display: inline-block;
								margin: 15px 0 0;

								&:after {
									content: ":";
								}
							}
						}

						.value {
							@media screen and (max-width: 767px) {
								width: auto;
								display: inline-block;
							}
						}

						.order-shipment-section {
							width: 80%;

							@media screen and (max-width: 767px) {
								width: 100%;
							}
						}
					}
				}
				.order-tracking.item-details {
					.product-brand {
						font-size: $base-font;
						margin: 0;
					}
					.name {
						font-size: $base-font + 4px;
						text-transform: capitalize;
					}
					.attribute {
						font-size: $base-font;
					}
					.label.order-quantity {
						font-weight: normal;
					}
					.product-status {
						display: none;
					}
				}
			}
		}
	}
	.order-history {
		h1 {
			letter-spacing: 3px;
			text-transform: uppercase;
		}
		.international-orders {
			.check-order {
				.form-row {
					display: inline-block;
					width: 27%;
					padding: 0 47px 0 0;

					&.orderNumber,
					&.orderEmail {
						label {
							span {
								font-weight: 600;
								color: $black;
								font-size: $base-font;
								&.error {
									color: $medium-carmine;
								}
							}
						}
					}

					label {
						padding: 0 0 16px 0;
						text-transform: uppercase;
					}
					.field-wrapper {
						border-bottom: 4px solid $black;
					}
				}
				@media screen and (max-width: 768px) {
					.form-row {
						width: 100%;
						padding: 0;
					}
					button {
						float: right;
					}
				}
			}
			.error-form {
				border: none;
				background-color: transparent;
			}
			button {
				background: transparent;
				border: none;
				color: $sky-blue;
				text-decoration: underline;
				letter-spacing: 3px;
				font-weight: 900;
				font-size: $base-font + 2px;
			}
		}
	}

	.order-payment-summary {
		.order-detail-summary {
			.order-detail {
				.label {
					.shippingoverlay-link {
						padding: 0;
						margin: 0;
						display: inline-block;
						text-indent: -9999px;
						cursor: pointer;
						background: url(../../images/tips.svg) no-repeat;
						width: 16px;
						height: 16px;
						background-size: cover;
						vertical-align: bottom;
					}
				}
			}
		}
	}

	.primary-content {
		width: 100%;
		float: left;
	}
	.order-details-section {
		.order-shipment-table {
			.line-items {
				.line-item {
					.specialmessaging {
						clear: both;
						margin: 10px 0 0 0;

						@media screen and (max-width: 1023px) {
							margin: 0 0 0 0;
						}

						@media screen and (max-width: 767px) {
							margin: 10px 0 0 0;
						}
					}
					.col-3 .quick-order-badge {
						display: none;
					}
				}
			}
		}
	}

	.return-inner-items {
		.specialmessaging {
			margin-left: 0;
		}
	}

	.order-details-section {
		.qa-section {
			border-top: 0;
			max-width: 1020px;
			margin: 0 auto;
			display: block;

			.question-title {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
			}

			.qa-content {
				box-sizing: border-box;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;

				.question {
					&::after {
						background-size: 16px;
					}

					&.active {
						&::after {
							background-size: 16px;
						}
					}
				}
			}
		}
	}

	.orderdetails {
		.actions {
			width: auto;
			float: right;

			a {
				margin: 0;
				border: 1px solid $black;
			}
		}

		.order-information {
			width: auto;
			float: left;

			.order-print {
				.print-page {
					display: none;
				}
			}

			.order-status {
				.order-status {
					display: none;
				}

				.mail-box {
					background-size: 100%;
					width: 17px;
					height: 15px;
					display: inline-block;
					background-position: 0 1px;
					vertical-align: bottom;
				}
			}
		}

		.order-detail-section {
			span.img {
				background: url("../../../images/cards-sprite2.png") no-repeat;
				width: 35px;
				height: 24px;
				display: inline-block;
				margin-left: 20px;

				&.visa {
					background-position: -59px -3px;
				}
				&.amex {
					background-position: -8px -3px;
					width: 37px;
				}
				&.mastercard {
					background-position: -108px -3px;
					width: 36px;
				}
				&.discover {
					background-position: -247px -3px;
				}
				&.JL_PLCC,
				&.jl_plcc {
					background-position: -157px -3px;
				}
				&.CA_PLCC,
				&.ca_plcc {
					background-position: -157px -35px;
				}
				&.WW_PLCC,
				&.ww_plcc {
					background-position: -203px -3px;
				}
				&.RM_PLCC,
				&.rm_plcc {
					background-position: -290px -3px;
				}

				&.CA_PLCC,
				&.ca_plcc {
					background-position: -157px -35px;
				}

				&.fb_plcc {
					background-position: -59px -34px;
				}
				&.bh_plcc {
					background-position: -10px -34px;
				}
				&.ks_plcc {
					background-position: -109px -34px;
				}
			}

			&.order-detail-response {
				.order-billing {
					&.section {
						@media screen and (max-width: 1023px) {
							width: 20%;
						}

						@media screen and (max-width: 767px) {
							width: 100%;
						}
					}
				}
			}
			.domain {
				word-wrap: normal;
			}
		}

		.order-shipments {
			.order-shipment-details {
				.trackingnumber {
					background: url("../../images/Shipping_Icon_Black.svg") no-repeat;
					display: inline-block;
					padding-left: 35px;
					box-sizing: border-box;
					line-height: 20px;
					vertical-align: middle;
					margin-top: 8px;
				}
				
				&.trackingnumber-new {
					.order-shipping-status {
						.shipping-status {
							@media screen and (max-width: 767px) {
								display: inline-block;
								margin-right: 10px;

								.value {
									width: auto;
								}
							}
						}

						.shipmentnumber {
							width: auto;
							float: right;
							vertical-align: bottom;
						}
					}
				}
			}

			.shipmentnumber {
				width: 100%;
				display: inline-block;

				h2 {
					padding: 0;
					border: 0;
					width: auto;
					float: left;
				}

				.trackingnumber {
					width: auto;
					float: right;

					.label,
					.value {
						width: auto;
						display: inline-block;
					}
				}
			}

			.order-shipment-section {
				width: 100%;
				display: inline-block;
			}
		}
		.order-payment-summary {
			.actions {
				float: left;
			}
		}

		.mini-address-name {
			display: inline-block;
			width: 100%;
		}
	}

	//order History page
	.order-history {
		margin: 36px auto 20px;
		.top-content {
			margin: 0 auto 30px;
			max-width: 984px;
			padding: 0;
			h1 {
				font-size: $base-font + 12px;
				margin: 0 0 10px;
			}
		}
		.ordertrack-form-content-top {
			margin: 0 auto 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid $white-whisper;
		}
		.ordertrack-form-content-bottom {
			margin: 30px auto;
			padding-top: 30px;
			border-top: 1px solid $white-whisper;
			> * {
				max-width: 984px;
				margin: 0 auto 25px;
			}
		}
		.order-history-table {
			margin: 0 auto 30px;
			max-width: 984px;
			.order-history-header {
				padding: 20px 20px 18px;
				background: $white-smoke;
				text-transform: uppercase;
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				letter-spacing: 1px;
			}
			.col-1,
			.col-2 {
				width: auto;
				float: none;
				margin: 0 0;
			}

			.col {
				width: 15%;
				float: left;
				text-align: center;

				&.col-4 {
					padding-left: 34px;
					box-sizing: border-box;
				}
			}
			.col-first {
				text-align: left;
			}
			.col-last {
				text-align: right;
				float: right;
			}

			.col-2 {
				width: 23.1%;
			}
			.col-3 {
				margin-left: 1.3%;
			}

			.order-list-wrapper {
				margin: 0;
				display: inline-block;
				width: 100%;
				max-width:100%;
				box-sizing: border-box;
				margin-top: -3px;
				border: 1px solid $alto;
				border-top: 0;

				.order-list {
					padding: 28px 20px 39px;
					display: inline-block;
					width: 100%;
					box-sizing: border-box;
					border-top: 1px solid $alto;

					&.with-returns {
						padding: 28px 20px 24px;
					}
					.order-history-overlay {
						background: url(../../../images/tips.svg) no-repeat;
						width: 16px;
						height: 16px;
						background-size: cover;
						vertical-align: bottom;
						padding: 0;
						margin: 0;
						display: inline-block;
						text-indent: -9999px;
						cursor: pointer;
					}
				}
				.col-last {
					padding-top: 1px;
				}

				.button-text {
					letter-spacing: 1px;
					font-size: $base-font - 1px;
					@media screen and (max-width: 1023px) and (min-width: 767px) {
						padding-left: 8px;
						padding-right: 8px;
						width: 100%;
					}

					&.return {
						vertical-align: top;
					}

					&.view-detials,
					&.return {
						background: none;
						border-radius: 0;
						text-transform: uppercase;
					}
				}

				.col:not(.col-last),
				.view-detials {
					padding-top: 15px;
					letter-spacing: 1px;
					@media screen and (min-width: 767px) and (max-width: 1025px) {
						padding-left: 0;
						letter-spacing: 0.4px;
					}
				}
			}
		}
	}
}

.pt_order-confirmation {
	@media screen and (max-width: 1023px) {
		&.menu-active {
			.slider-menu {
				display: block;
			}
		}
	}
	.confirmation-recommendations {
		.you-may-like {
			clear: both;
			padding: 0;
		}
	}
	.order-payment-summary {
		.order-detail-summary {
			.order-detail {
				.label {
					.shippingoverlay-link {
						padding: 0;
						margin: 0;
						display: inline-block;
						text-indent: -9999px;
						cursor: pointer;
						background: url(../../images/tips.svg) no-repeat;
						width: 16px;
						height: 16px;
						background-size: cover;
						vertical-align: bottom;
					}
				}
				&.discount {
					color: $guardsman-Red;
				}
			}
		}
	}
	.hide {
		display: none;
	}

	.mail-box {
		background: url("../../images/mail-black.svg") no-repeat;
		background-size: 100%;
		width: 17px;
		height: 15px;
		display: inline-block;
		background-position: 0px 2px;
	}

	.receipt-message {
		padding: 0 0 0 11px;
	}

	.order-status {
		margin: 0 0 30px 0;

		.order-email {
			word-wrap: break-word;

			@media screen and (max-width: 767px) {
				line-height: 26px;
			}
		}
	}

	.heading {
		margin: 0 0 10px 0;
	}

	&:not(.menu-active) {
		.my-account {
			&.heading {
				display: none;
			}
		}
	}

	.order-shipment-section {
		.method {
			&.electronic {
				padding-bottom: 15px;
			}
		}
	}

	.order-detail-section {
		.payment-block:not(:last-child) {
			border-bottom: 1px solid $mortar-grey;
			margin-bottom: 10px;
			padding-bottom: 10px;
		}
		.value {
			margin: 0 0 5px 0;
			display: inline-block;
			width: 100%;
			@media screen and (min-width: 768px) {
				&.cc-number {
					margin-bottom: 5px;
				}
			}
		}
		span.img {
			background: url("../../images/cards-sprite2.png") no-repeat;
			width: 35px;
			height: 24px;

			&.cc-number-symbol {
				display: inline-block;
			}

			&.visa {
				background-position: -59px -3px;
			}
			&.amex {
				background-position: -8px -3px;
				width: 37px;
			}
			&.mastercard {
				background-position: -108px -3px;
				width: 36px;
			}
			&.discover {
				background-position: -247px -3px;
			}
			&.JL_PLCC,
			&.jl_plcc {
				background-position: -157px -3px;
			}
			&.CA_PLCC,
			&.ca_plcc {
				background-position: -157px -35px;
			}
			&.WW_PLCC,
			&.ww_plcc {
				background-position: -203px -3px;
			}
			&.RM_PLCC,
			&.rm_plcc {
				background-position: -290px -3px;
			}
			&.fb_plcc {
				background-position: -59px -34px;
			}
			&.bh_plcc {
				background-position: -10px -34px;
			}
			&.ks_plcc {
				background-position: -109px -34px;
			}
			&.CA_PLCC,
			&.ca_plcc {
				background-position: -157px -35px;
			}
		}
		.section {
			.inner-block {
				.reward-certificate {
					margin-top: 25px;
				}
			}
		}
	}

	address {
		font-style: normal;
		line-height: 26px;
	}

	.option-info {
		color: $mangotango;
	}

	.profile-summary {
		margin: 0 7% 7% 0;
		.profile-name {
			margin: 0 0 18px 0;
		}
		.profile-email {
			float: left;
			margin: 0 0 30px 0;
		}
		.edit-profile {
			float: right;
			text-transform: uppercase;
			font-size: $base-font - 1px;
			letter-spacing: 1px;
			cursor: pointer;
		}
	}

	.orderdetails {
		.login-create-account {
			.login-box-right-content {
				.inner-block {
					max-width: 290px;
					width: 100%;
					display: inline-block;

					@media screen and (max-width: 767px) {
						max-width: 100%;
					}

					@media screen and (max-width: 1025px) {
						padding-left: 0;
					}

					&.make-label-absolute {
						.form-row {
							&.password,
							&.passwordconfirm {
								&.error-handle {
									.field-wrapper {
										&:before {
											font: normal normal normal 28px/1 FontAwesome;
											font-size: 25px;
											text-rendering: auto;
											-webkit-font-smoothing: antialiased;
											-moz-osx-font-smoothing: grayscale;
											content: "\f071";
											color: $venetian-red;
											position: absolute;
											top: 11px;
											left: 11px;
										}
									}
									label {
										padding-left: 39px;
										.error {
											color: $venetian-red;
										}
									}
									input[type="password"],
									input[type="text"] {
										border: 2px solid $venetian-red;
										padding-left: 55px;
									}
								}
								.field-wrapper {
									margin-bottom: 0px;
								}
							}
							&.password {
								.form-caption {
									display: none;
									padding: 10px;
									margin: -1px 0 10px;
									background: $venetian-red;
									border-radius: 0 0 3px 3px;
									text-align: left;
									font-family: $WorkSans;
									font-size: $base-font;
									color: $white;
									width: auto;
								}
							}
							&.passwordconfirm {
								.form-caption {
									display: none;
								}
							}
							&.error-handle {
								label {
									span {
										&.label-text {
											display: none;
										}
									}
								}
							}
						}
						span.error {
							color: $radical-red;
						}

						.field-wrapper {
							.pwd-show {
								font-size: $base-font;
								padding: 18px;
								background: transparent;
								border: none;
								color: $black;
								position: absolute;
								top: -21px;
								right: -14px;
								-webkit-transform: translate(-14px, 20px);
								transform: translate(-14px, 20px);
								z-index: 1;
								cursor: pointer;
								text-transform: uppercase;
								width: auto;
							}
						}
						.password-status {
							margin: 10px 0;
						}
					}
				}
			}
		}
	}
	.login-box-right-content {
		button {
			width: 100%;
		}
	}
	.order-shipment-table {
		.line-items {
			.column.col-1 {
				.item-details {
					.name {
						font-size: $base-font + 4;
						margin: 1px 0 8px 0;
						@media screen and (min-width: 767px) {
							font-size: $base-font + 2;
						}
					}
					.price-promotion {
						.price-sales {
							color: $text-sale;
						}
					}

					.optioninfo {
						.hemmable {
							margin-top: 15px;
							margin-bottom: 10px;
						}
					}
					.attribute {
						margin-bottom: 4px;
					}
				}
			}

			.label {
				display: inline-block;
				text-transform: capitalize;
				letter-spacing: 0.8px;
				font-weight: 700;
				font-size: $base-font - 1;
			}

			.value {
				display: inline-block;
				text-transform: capitalize;
			}

			.size-attribute {
				.value {
					text-transform: uppercase;
				}
			}
			.personalized-message {
				margin: 0 0 7px 0;
			}
			.giftcard {
				margin: 0 0 7px 0;
			}
			.qty-value {
				font-size: $base-font - 1;
			}
			.line-item-promo {
				font-size: $base-font - 1;
			}
			.col-2 {
				margin: 45px 0 0 0;
			}
			.col-3 {
				.line-item-price {
					&.value {
						margin-top: 45px;

						@media screen and (max-width: 767px) {
							display: none;
						}
					}
					.sale-price {
						font-size: 16px;
						@media screen and (max-width: 767px) {
							font-size: 11px;
						}
					}
				}

				&.quick-order-item {
					.line-item-price {
						&.value {
							margin-top: 30px;
						}
					}
				}
			}
			.line-item {
				&.quick-order-item {
					.col-3 {
						.line-item-price {
							&.value {
								@media screen and (min-width: 1024px) {
									margin-top: 30px;
								}
							}
						}
					}
				}
			}
		}
	}
	.discount-price {
		color: $guardsman-Red;
	}
	.additional-offers {
		h2 {
			text-align: center;
			margin: 4% 0 3% 0;
			text-transform: none;
		}
		.section-images {
			margin: 0 1.5% 1% 0;
			width: 49%;
			float: left;
			&:nth-child(even) {
				margin: 0 0 1% 0;
			}
			img {
				max-width: 100%;
				width: 100%;
			}
		}
	}
	.order-shipment-section {
		.method {
			width: auto;
			float: left;
		}

		.order-shipment-address {
			width: auto;
			float: left;
			margin: 0 0 0 20px;
		}
	}

	.cart-brand-logo {
		width: 100%;
	}
}

.confirmation-left-section {
	.qa-section {
		border-top:0;
		margin: 0 0 20px 0;
		.qa-content {
			.question {
				letter-spacing: 1px;
				&::after {
					background: url(../../images/arrow-down.png) no-repeat;
					background-position: 99.6% 5px;
				}
				&.active {
					&::after {
						background: url(../../images/arrow-up.png) no-repeat;
						background-position: 99.6% 5px;
					}
				}
			}
		}
	}
	&.ot {
		.tracking-bar {
			height: 0.38rem;
			z-index: 5;
			width: 100%;
			@media screen and (max-width: 767px) {
				max-width: 350px;
			}
			.progress-bar {
				z-index: 5;
			}
		}
		.tracking-status-container p {
			max-width: 294px;
    		width: 294px;
		}
		.shipment-number {
			right: -12px;
    		top: -14px;
			&::before {
				background: url("../../images/Shipping_Icon_Black.svg") no-repeat;
				content: "";
				display: inline-block;
				width: 25px;
				height: 20px;
				vertical-align: middle;
				margin-right: 5px;
			}
		}
		.orderdetails {
			margin-top: -1px;
		}
		.order-shipment-table {
			.attribute .label:not(.order-quantity) {
				display: none;
			}
			&:only-child, &:last-child {
				.line-items .line-item {
					border-bottom: 0px !important;
				}
			}
			.line-items.EGC {
				.line-item {
					margin-left: 0 !important;
				}
			}
			.item-details {
				.sku, .attribute.shipped, .order-detail-hide {
					display: none !important;
				}
				.order-detail-show {
					display: block !important;
					margin-bottom: 2px;
				}
			}
		}
		.progress-bar-border {
			position: relative;
			top: 6px;
			height: auto;
			z-index: 4;
			width: 100%;
			@media screen and (max-width: 767px) {
				max-width: 350px;
			}
		}
		.shipping-statuses-checks, .shipping-statuses {
			position: relative;
			top: -25px;
			@media screen and (max-width: 767px) {
				max-width: 350px;
			}
			.status-check.unchecked {
				content: "";
				background: url("../../images/circle-filled-gray.svg") no-repeat;
				width: 17px;
				height: 17px;
				background-size: 100%;
				position: relative;
				left: 0px;
				top: 5px;
				filter: invert(67%) sepia(0%) saturate(142%) hue-rotate(50deg) brightness(210%) contrast(1);
				@media screen and (max-width: 767px) {
					width: 19px;
					height: 19px;
					top: 4px;
				}
			}
			.status-check.checked {
				content: "";
				background: url("../../images/check-green.svg") no-repeat;
				width: 16px;
				height: 16px;
				background-size: 100%;
				position: relative;
				top: 6px;
				z-index: 7;
				@media screen and (max-width: 767px) {
					width: 19px;
					height: 19px;
					top: 4px;
				}
			}
		}
		.shipping-statuses {
			top: -20px;
		}
		.confirmation-message {
			margin-top: -1px;
			@media screen and (max-width: 767px) {
				border-top: 0 !important;
			}
		}
		.order-detail-with-order-summary {
			box-shadow: 0 0 0 100vmax var(--color-primary-light);
    		clip-path: inset(0 -100vmax);
			background-color: var(--color-primary-light);
			.label, .value {
				font-family: $sans-serif;
			}
			.order-payment-summary .order-detail-summary .order-detail {
				.label, .value {
					font-family: $sans-serif;
				}
			}
			.order-detail-section, .order-payment-summary {
				box-shadow: none;
				margin: 0px;
			}
		}
	}
}

.pt_order,
.confirmation {
	.product-list-item {
		.product-brand,
		.brand {
			font-size: $base-font - 1px;
			text-transform: capitalize;
			margin-bottom: 9px;

			&.jessicalondon {
				color: $brand-name-color;
			}
		}
	}
}

h2.summary-heading {
	font-size: 24px !important;
}

.text-muted {
	color: $mortar-grey !important;
}
.elq-rewards {
	.label, .value {
		font-weight: 400;
	}
}
.order-payment-summary .order-detail-summary .order-detail {
	padding-bottom: 0 !important;

	&.order-total {
		margin-top: 6px;
	}
}
.order-totals-table.prorated {
	.order-detail {
		.label {
			width: 62%;
		}
		.value {
			width: 38%;
		}
	}
	.remaining-total {
		padding: 12px 0 10px 0;
		margin-top: 9px;
		border-top: 2px solid $alto;
		.label, .value {
			font-size: 20px;
		}
	}
}

@media screen and (min-width: 767px) and (max-width: 1025px) {
	.pt_order {
		.confirmation-left-section.ot {
			.tracking-status-container {
				width: 33%;
			}
			.order-details-section {
				margin: 0 10px;
			}
			.col-1.mob {
				width: 32% !important;
			}
			.order-detail-with-order-summary {
				& > div {
					width: 30%;
				}
				.order-detail-section, .order-payment-summary {
					height: 286px;
					padding: 16px;
				}
				.order-payment-summary {
					padding: 16px;
					font-size: 14px;
					.order-detail-summary {
						width: 100%;
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1023px) {
	.confirmation-left-section.ot {
		.tracking-status-container, .tracking-status-container p {
			max-width: 294px;
    		width: 294px;
		}
		.order-shipments {
			.order-shipment-table {
				.line-item {
					width: calc(100% - 1.5rem);
				}
			}
		}
		.order-detail-with-order-summary {
			& > div {
				width: 26%;
			}
			.summary-heading {
				letter-spacing: normal;
			}
			.order-detail-section, .order-payment-summary {
				height: 286px;
			}
			.order-payment-summary {
				padding: 16px;
				.order-detail-summary {
					width: 100%;
					.label {
						font-size: $base-font + 2px;
					}
					.order-total {
						border-top: 1px solid $dark-grey;
						.label, .value {
							font-size: $base-font + 6px;
							font-weight: bold;
						}
					}
					.order-saving {
						font-weight: bold;
					}
				}
			}
		}
	}
	.order-shipment-table {
		.line-item-price {
			display: none;
		}
	}
}
@media screen and (max-width: 1024px) {
	.pt_order-confirmation {
		.confirmation-left-section {
			box-sizing: border-box;
			padding: 0;
			margin-right: 0;
			max-width: 100%;
		}

		.order-shipment-table {
			.line-items {
				.column {
					&.col-1 {
						max-width: 100%;
					}
					&.col-3 {
						text-align: right;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_order-confirmation {
		.order-shipment-table {
			.line-items {
				.column {
					&.col-1 {
						width: 70%;
						.item-details {
							.line-item-quantity {
								display: none;
							}
						}
					}
					&.col-2 {
						max-width: 100%;
						text-align: left;
					}
					&.col-3 {
						.quick-order-badge {
							display: none;
						}
					}
				}
			}
		}
		.profile-summary {
			margin: 0;
		}
		.order-shipment-section {
			.order-shipment-address {
				margin: 0 0 0 7%;
			}
		}
	}
	.pt_order {
		.order-history {
			max-width: none;
			margin: 25px auto 20px;

			.top-content {
				.subheading {
					font-size: $base-font + 1px;
				}
			}

			.order-history-table {
				.order-history-header {
					padding: 20px 10px 18px;
				}
				.order-list-wrapper {
					.order-list {
						&.with-returns {
							padding: 28px 10px 23px;
						}
					}
				}
				.col {
					&.col-4 {
						padding-left: 14px;
					}
				}
			}
		}
		.orderdetails {
			.order-detail-section {
				&.order-detail-response {
					span {
						&.img {
							float: none;
							vertical-align: middle;
							margin: -4px 0 0 4px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.only-for-mobile {
		display: block;
	}

	.pt_order {
		.order-history {
			.top-content {
				.subheading {
					font-size: $base-font + 2px;
				}
			}

			.order-history-table {
				padding: 0 10px;

				.order-history-header {
					display: none;
				}

				//columns settings

				.col {
					width: 100%;
					float: left;
					text-align: left;

					&.col-4 {
						padding-left: 0;
					}
				}
				.col-2 {
					width: 48.9%;
					float: left;
				}
				.col-3 {
					width: 48.9%;
					float: right;
				}

				.col-last {
					text-align: left;
					float: none;
					display: block;
				}

				.col-4 {
					display: none;
				}

				.only-for-mobile {
					float: left;
				}
				.col:not(.col-last),
				.view-detials {
					padding-top: 10px;
				}

				.order-list-wrapper {
					.order-list {
						padding: 15px 13.5px;

						&.with-returns {
							padding: 15px 13.5px;
							.view-detials {
								padding: 0 0 0 15px;
							}
						}
						.order-number {
							font-size: $base-font + 3px;
							padding: 0;
							position: relative;

							.arr-img {
								background: url("../../images/carat-down.svg") no-repeat;
								width: 5%;
								height: 12px;
								position: absolute;
								right: 0;
								top: 5px;
								display: inline-block;
								padding: 0 0;
								background-size: 100%;
								max-width: 22px;
							}
						}

						.col-brand {
							padding-bottom: 20px;
							padding-top: 0px;
						}
						.only-for-mobile {
							text-transform: uppercase;
							padding-right: 5px;
						}

						.order-date {
							border-right: none;
							padding: 0 0;
						}
						.order-price {
							border-left: 1px solid $black;
							padding: 0 0 0 8%;
							margin-left: 0;
							box-sizing: border-box;
						}

						&.opened {
							.arr-img {
								transform: rotate(180deg);
								-ms-transform: rotate(180deg);
								-webkit-transform: rotate(180deg);
							}
						}
					}
					.button-text {
						font-size: $base-font;
						border-radius: 0;
					}
					.view-detials {
						padding: 0 0 0 0;
					}
					.oh-buttons {
						clear: both;
						padding-top: 15px;
						display: block;
					}
				}
			}
		}
		.orderdetails {
			.order-shipments {
				.order-shipment-table {
					&.gift-card {
						.order-shipment-details {
							.order-shipment-section {
								.order-shipment-address,
								.order-shipping-status {
									width: 100%;
								}
							}
						}
					}
					&.e-gift-card,
					&.gift-card {
						.line-items {
							.line-item {
								.col-3 {
									margin-top: 15px;
								}
							}
						}
					}
				}
			}
		}
	}

	.pt_order-confirmation {
		.order-shipment-table {
			.line-items {
				.column {
					&.col-1 {
						.item-details {
							.line-item-quantity {
								display: block;
							}
						}
					}
				}
			}
		}
		.confirmation-message {
			border-top: 1px solid $alto;
			padding: 18px 0 18px 0;
		}

		.order-confirmation-details {
			.order-print {
				padding: 20px 0 0 0;
			}
		}

		.receipt-message {
			padding: 0 0 0 5px;
		}

		.confirmation-left-section {
			.create-heading {
				padding: 0 0 20px 0;
			}
		}
		.orderdetails {
			.login-create-account {
				.login-box-content {
					padding: 30px 20px 30px 20px;
				}

				.login-box-left-content {
					width: 100%;
				}

				.login-box-right-content {
					width: 100%;
					.inner-block {
						&.make-label-absolute {
							.form-row {
								&.password {
									.form-caption {
										left: 34%;
									}
								}
							}
						}
					}
				}
			}
			.order-shipments {
				.order-shipment-address {
					margin: 0;
				}

				.heading {
					letter-spacing: 1px;
				}
				.order-shipment-table {
					.line-item-quantity {
						width: 100%;
						margin: 0 0 13px 0;
					}
					.line-item-price {
						width: 100%;
						text-transform: uppercase;
						letter-spacing: 1px;
						font-size: $base-font + 2;
					}
				}
				.line-items {
					.line-item {
						padding: 20px 0;

						&.quick-order-item {
							.heading {
								line-height: 15px;
							}
							.quick-order-badge-link {
								&:after {
									top: 0;
								}
							}
						}
					}
					.column {
						&.col-1 {
							width: 100%;
							.item-image {
								width: 40%;
							}
							.item-details {
								width: 60%;
								max-width: 100%;
								padding-left: 20px;
							}
						}
						&.col-2 {
							display: none;
						}
					}
				}
			}
		}

		.profile-summary {
			margin: 0;
			.profile-email {
				margin: 0 0 25px 0;
			}
		}
		.order-detail-section {
			padding: 35px 30px;
			&.expanded-order {
				.order-status {
					.inner-block {
						.order-date {
							width: 100%;
						}
					}
				}
			}
			.cc-number {
				span {
					&.img {
						vertical-align: middle;
						margin-left: 8px;
					}
				}
			}
			.section {
				width: 100%;
				.inner-block {
					width: 100%;
					max-width: 100%;
					.order-date {
						display: inline-block;
						width: 57%;
						margin: 0 5% 0 0;
					}
					.ecoship-shipping {
						display: none;
					}
					.total {
						width: 38%;
						float: right;
						margin: 0;
						&.total-expanded {
							width: 100%;
							margin: 0 0 10px 0;
							.heading {
								margin: 20px 0 10px 0;
							}
						}
					}
				}
			}
			.expanded-section {
				display: none;
			}

			.view-more-section {
				width: 100%;
				text-align: center;
				text-transform: uppercase;
				display: inline-block;
				letter-spacing: 1px;

				.opened {
					margin: 30px 0 0 0;
					width: 100%;
					display: inline-block;
				}

				.view-more-link {
					width: auto;
				}
			}
		}
		.order-payment-summary {
			padding: 40px 20px;
			.order-detail-summary {
				width: 100%;
				max-width: 100%;
			}
			.actions {
				display: none;
			}
		}
		.additional-offers {
			h2 {
				text-align: left;
			}
		}
		.option-info {
			display: none;
		}

		.order-detail-section {
			.label {
				&.heading {
					margin: 0 0 10px 0;
				}
			}

			.value {
				margin: 0;
				line-height: 26px;
			}

			.order-payment-instruments {
				margin: 0 0 20px 0;

				.cc-exp {
					line-height: 26px;
				}
			}

			.discount-section {
				margin: 0 0 20px 0;
			}

			span {
				&.img {
					vertical-align: middle;
				}
			}

			.mini-address-name {
				margin: 0 0 5px 0;
			}
		}
	}
}
.ui-dialog.order-status-overlay {
	.ui-dialog-title {
		display: block;
		font-size: 12px;
		padding: 25px 0 15px;
		box-sizing: border-box;
		border-bottom: 1px solid #ddd;
		margin-left: 20px;
		width: 82%;
	}
	.ui-dialog-content {
		padding: 15px 45px 20px 20px;
	}
}
@media screen and (max-width: 480px) {
	.pt_order-confirmation {
		.confirmation-left-section {
			margin-top: 0;
			padding-top: 0;
			.confirmation-message {
				border-top: none;
			}
		}
		.login-box-content {
			.login-box-right-content {
				.profile-summary {
					.profile-name {
						margin-top: 5px;
						margin-bottom: 5px;
					}
					.profile-email {
						margin-top: 5px;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}
.catalog-item-number {
	width: 100%;
	color: $dim-gray;
	margin: 10px 0;
	font-size: 12px;

	@media screen and (min-width: 768px) {
		text-align: center;
	}
}

.product-content-quickorder {
	float: left;
	width: 100%;
	height: auto !important;

	.product-variations-toggle {
		width: 100%;
		font-size: $base-font + 3px;
		font-family: $HelveticaBold;
		text-transform: uppercase;
		position: relative;
		margin-top: 12px;
		line-height: normal;
		margin-bottom: 12px;

		@media screen and (max-width: 767px) {
			display: inline-block;
			padding: 12px 10px;
			margin: 0 -10px;
			background-color: $gallery;
			width: calc(100% + 20px);
			color: $dim-gray;
			border: 1px solid $gallery;
		}

		&.open {
			&:after {
				-webkit-transform: translateY(-50%) rotate(180deg);
				-ms-transform: translateY(-50%) rotate(180deg);
				-o-transform: translateY(-50%) rotate(180deg);
				-moz-transform: translateY(-50%) rotate(180deg);
				transform: translateY(-50%) rotate(180deg);
				-webkit-transition: transform 0.3s;
				-ms-transition: transform 0.3s;
				-moz-transition: transform 0.3s;
				-o-transition: transform 0.3s;
				transition: transform 0.3s;
			}
		}

		&:after {
			content: "";
			background: url(../../images/select_arrow.png) no-repeat;
			height: 6px;
			width: 11px;
			position: absolute;
			top: 50%;
			right: 17px;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			transform: translateY(-50%);
			-webkit-transition: transform 0.3s;
			-ms-transition: transform 0.3s;
			-moz-transition: transform 0.3s;
			-o-transition: transform 0.3s;
			transition: transform 0.3s;
		}
	}
	.veil {
		z-index: 99;
	}
}

.order-payment-summary {
	.tax-disclaimer {
		clear: both;
		width: 50%;
		float: right;
		max-width: 290px;
		color: $black;
		padding: 0px;
		line-height: 150%;
		@media screen and (max-width: 767px) {
			width: 100%;
			max-width: 100%;
		}
	}
}
@media screen and (max-width: 767px) {
	.catalog-quick-order {
		.pdp-main {
			.product-col-2 {
				&.product-quickorder-detail {
					.product-content-quickorder {
						.variation-add-to-cart-content {
							display: none;
						}
						.product-variations-toggle.open {
							+ .variation-add-to-cart-content {
								display: block;
								background-color: $gallery;
								margin-left: -10px;
								margin-right: -10px;
								padding-left: 10px;
								padding-right: 10px;
								.swatches {
									li:not(.selected) {
										a {
											background-color: $white;
										}
									}
								}
								.custom-select {
									select {
										background-color: $white;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.pt_order-confirmation {
		.order-shipment-table {
			.line-items {
				.column {
					&.col-1 {
						.line-item-price {
							.subtotal-discount {
								color: $light-red;
							}
						}
					}
				}
			}
		}
	}
	.pt_order,
	.pt_order-confirmation {
		.orderdetails {
			.order-shipments {
				.line-item {
					.callout-message {
						clear: both;
					}
				}
			}
		}
	}
}

.finalsale-order-detail {
	font-weight: bold;
	font-size: 12px;
	letter-spacing: 0.6px;
	color: #af3434;
	display: none;
	text-transform: uppercase;
	@media screen and (max-width: 767px) {
		display: block;
	}

	@media screen and (max-width: 1023px) {
		font-size: 8px;
		letter-spacing: 0.4px;
	}
}
.pt_order-confirmation {
	.specialmessaging {
		word-break: break-word;
		margin: 0;
		color: $black;
		@media screen and (max-width: 767px) {
			margin-top: 14px;
		}
		p {
			margin-bottom: 0;
			font-size: 12px;
    		color: $mortar-grey;
			&.finalsalemessage {
				margin-bottom: 7px;
				@media screen and (max-width: 767px) {
					margin-bottom: 14px;
				}
			}
		}
	}

	.finalsale-order-detail {
		@media screen and (max-width: 767px) {
			display: none;
		}
	}
}

.order-history {
	.specialmessaging {
		p {
			&.finalsalemessage {
				@media screen and (max-width: 767px) {
					display: none;
				}

				@media screen and (max-width: 1023px) {
					font-size: 8px;
					letter-spacing: 0.4px;
				}
			}
		}
	}
}

// SC-6272 changes
#wrapper.pt_order-confirmation .confirmation-left-section .line-items .line-item.sps-line-item {
	.column {
		&.col-1 {
			.item-image {
				width: 101px;
				@media screen and (max-width: 767px) {
					width: 123px;
				}

				a img {
					width: 101px;
					height: 149px;

					@media screen and (min-width: 768px) and (max-width: 1024px) {
						width: 98px;
						height: 137px;
					}

					@media screen and (max-width: 767px) {
						width: 123px;
						height: 179px;
					}
				}
			}

			.item-details {
				.product-list-item {
					.product-brand {
						font-size: 10px;
						font-weight: 600;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: 0.36px;
						color: $brand-name-color;
					}

					.name {
						font-size: 14px;
						font-weight: 500;
						line-height: 1;
						letter-spacing: 1px;
						color: #000000;
						margin-bottom: 15px;
					}

					.sku {
						font-size: 10px;
						letter-spacing: 0.36px;
						color: #000000;
						margin-bottom: 10px;

						.value {
							font-weight: 500;
						}
					}

					.variant-availability-group {
						font-size: 12px;
						letter-spacing: 0.26px;
						color: #000000;
						font-weight: bold;
						text-transform: uppercase;
						margin-bottom: 10px;
					}

					.cart-unit-price {
						font-weight: bold;
						font-size: 12px;
						letter-spacing: 0.26px;
						color: #000000;

						.price-promotion {
							.price-sales {
								color: #af3434;
							}
						}
					}

					.line-item-price {
						display: none;
					}
				}
			}

			.callout-message {
				@media screen and (max-width: 767px) {
					padding-top: 0;
				}
			}
		}

		&.col-3 {
			.line-item-price {
				color: #af3434;
				font-weight: bold;
				letter-spacing: 0.3px;
				font-size: 14px;
				line-height: normal;

				@media screen and (min-width: 768px) and (max-width: 1024px) {
					line-height: 1.2;
					letter-spacing: 0.32px;
					font-size: 15px;
					font-weight: normal;
				}
			}
		}
	}

	.specialmessaging {
		font-weight: 600;
		p.exclusionmessage,
		p.nationalmessage,
		p.clearancemessage {
			color: #c55300;
		}
	}
}

.sps-line-item {
	.product-special-messages {
		margin-bottom: 10px;
		div {
			color: $special-product-messages-color;
			font-size: $base-font - 1px;
			letter-spacing: 0.5px;
			@include GothamMedium($Gotham);
			a,
			span {
				color: $special-product-messages-color;
				font-size: $base-font - 1px;
				letter-spacing: 0.5px;
				@include GothamMedium($Gotham);
			}
		}
	}
}

@-moz-document url-prefix() { 
	.pt_order-confirmation .confirmation-right-content .confirmation-recommendations  .slick-arrow {text-indent: -40px;}
}

.check-status-order.international-orders {
	p{
		font-family: $Helvetica;
	}
	a{
		margin-right: 16px;
		margin-bottom: 10px;		
	}
}

/* SC-11133 and SC-11134 */
.order-confirmation-details{
	.order-information{
		~ .sms-vibes-error{
			border: 2px solid $black;
			padding: 15px;
			overflow: hidden;
			margin-bottom: 15px;
			span{
				width: 25px;
				height: 25px;
				background: url(../../../images/icon-alert.svg) 0 0 no-repeat;
				display: inline-block;
				text-indent: -99999px;
				overflow: hidden;
				vertical-align: top;				
				+ p {
					display: inline-block;
					width: calc(100% - 28px);
					margin-top: 0;
					vertical-align: top;
					padding-left: 5px;
					font-family: $WorkSans;
					font-size: 18px;
					letter-spacing: 0;
					box-sizing: border-box;
					@media only screen and (max-width: 767px){
						font-size: 16px;
					}
				}
			}
		}
	}
}


/* End: SC-11133 and SC-11134 */

@media screen and (max-width: 992px) {
	.pt_order-confirmation.confirmation .confirmation-right-content {
		width: 100%;
		max-width: 100%;
	}	
}

@media screen and (max-width: 767px) {
	.pt_order-confirmation .order-confirmation-details .order-detail-section .order-payment-instruments .cc-number.value span.img{
		display:none;
	}
	.pt_order .confirmation-left-section.ot {
		.col-1 {
			&.mob {
				width: 79% !important;
				float: right !important;
			}
			.item-image {
				width: 21% !important;
			}
		}
	}
}


.confirmation-left-section .tt-c-comment-form__image a.tt-o-product__image-link.focus-outline {
	display: block;
}

#mnt-clarus-overlay-wrapper {
	#SignIn {
		.signin-container [data-rel=toggleForm] {
			float: right;
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_order-confirmation {
		.mail-box {
			background-position: 0px 3px;
		}
		.receipt-message {
			vertical-align: middle;
		}
	}
}