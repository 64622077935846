.personal-message {
	width: 100%;
	float: left;
	margin: 20px 0;

	.form-row {
		width: auto;
		float: left;
		margin: 0 8px 0 0;
		&.personalizedMessage::after {
			display: none !important;
		}

		input[type="checkbox"] {
			margin: 0 0.3rem 0 2px;
		}
	}

	.addto-message {
		font-size: $base-font + 1px;
		font-family: $HelveticaMedium, $sans-serif;
		color: $black;
		text-align: left;
		line-height: 18px;
	}
}

.fields-address {
	width: 100%;
	float: left;
	display: none;

	&.active {
		display: block;
	}

	.field-wrapper {
		margin: 0;
		position: relative;
	}

	.character-count {
		position: absolute;
		right: 35px;
		top: 19px;
		font-family: $Helvetica;
		color: $dim-gray;
		font-size: $base-font;
		text-align: left;
		z-index: 1;
	}
	.input-focus+.field-wrapper .character-count{
		top: 3px;
	}

	.form-row {
		width: 100%;
		float: left;
		margin: 20px 0 0 0;

		&:nth-child(3) {
			width: auto;
			margin: 20px 0 30px 0;
		}

		input[type="text"] {
			width: 288px;
			height: 48px;
			border: 1px solid $dim-gray;
			padding: 0 15px;
			font-size: $base-font;
			font-family: $Helvetica;
			color: $black;
		}

		.input-textarea {
			width: 436px;
			height: 197px;
			border: 1px solid $dim-gray;
			padding: 20px 15px;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			font-size: $base-font;
			font-family: $Helvetica;
			color: $black;

			@media screen and (max-width: 1023px) {
				width: 320px;
			}
		}
	}
}

.gift-certificate-purchase {
	background-color: $white-smoke;
	margin-bottom: 1em;
	padding: 0.83em;
	.content-asset {
		@media (min-width: 768px) {
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			float: left;
			padding-right: 1rem;
			width: 50%;
		}
		img {
			margin: 1.66em 0;
		}
	}
	form {
		background-color: $white;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		padding: 0 1.66em 1.66em;
		width: 100%;
		@media (min-width: 768px) {
			float: left;
			width: 50%;
		}
		.cert-amount {
			clear: none;
			float: left;
			margin: 0;
			width: 40%;
		}
		button {
			margin-top: -4px;
			width: 12em; //for IE
			width: max-content;
		}
		.form-row-button {
			clear: none;
			float: left;
			margin: 2.1em 0 0 1.66em;
			width: 50%;
		}
	}
	.cert-amount {
		.form-caption {
			margin: 1.66em 0 0;
			position: absolute;
			width: 40%;
		}
		&.error {
			padding-bottom: 2.92em;
		}
	}
	span.error {
		margin-left: 2%;
	}
}

.check-balance {
	border-bottom: 1px solid $gainsboro;
	margin: 0 0 1.66em;
	padding: 0 0 1.66em;
}

.gift-cert-balance {
	float: left;
	width: 40%;
	label {
		display: none;
	}
	input {
		margin-top: 0;
		width: 93%;
	}
	.form-caption {
		margin-left: 0;
	}
}

.gift-cert-balance-submit {
	clear: none;
	float: left;
}

.gift-cert-balance .label {
	display: none;
}
