.checkout-body.plcc-redesign {
//PLCC Step1
.ui-dialog {
	&.checkout-plcc-modal,
	&.checkout-plcc-modal-step2,
	&.plcc-acquisition-rebuttal-model,
	&.checkout-plcc-modal-terms,
	&.checkout-plcc-modal-step3 {
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}
	
		.ui-dialog-titlebar {
			background: $light-mercury-gray;
			height: 57px;
		}
		.ui-dialog-title {
			display: block;
			text-align: center;
			font-size: 1.125rem;
			margin: 18px 0 0 0;
			width: 100%;
			font-family: "Helvetica Neue LT W01_71488914";
			text-transform: initial;
			letter-spacing: 0.18px;
		}
		.ui-dialog-content{
			-ms-overflow-style: none;
			scrollbar-width:none;
			overflow: auto;
			&::-webkit-scrollbar {
				display: none;
			}
		}

		.ui-dialog-titlebar-close {
			display: none;

			&:hover {
				display: none;
			}
		}
		.plcc-terms-open,
		.plcc-terms-link {
			color: $navy-blue;
		}
		.plcc-terms-link{
			display:inline-block;
		}
		.plcc-terms-open{
			display:inline;
		}

		.plcc-offer-main {
			margin: 0 16px;
			text-align: center;
			div, p, span {
				font-family: "Helvetica Neue LT W01_55 Roman";
			}

			.plcc-offer-banner {
				padding: 10px;

				.plcc-offer-banner-right .plcc-subtitle {
					margin: 0;
				}
			}

			.plcc-greeting, .plcc-subtitle {
				font-family: "Helvetica Neue LT W01_71488914";
				font-size: 1.125rem;
				margin-bottom: 12px;
				span {
					font-family: "Helvetica Neue LT W01_71488914";
				}
			}

			.plcc-get20 {
				font-family: "Helvetica Neue LT W01_71488914";
				font-size: 2.75rem;
				sup {
					font-size: 1.5rem;
				}
			}

			.plcc-see-details {
				margin: 0;
				.plcc-terms-open {
					color: $white;
					text-decoration: underline;
				}
			}

			.plcc-greeting {
				color: $black;
				font-size: 1.25rem;

				.plcc-preapproved-name {
					text-transform: capitalize;
				}
			}

			.plcc-legal {
				font-size: .625rem;
				color: $legal-gray;
				.plcc-terms-open {
					font-family: "Helvetica Neue LT W01_71488914";
					text-decoration: underline;
				}
			}

			.plcc-benefits {
				font-size: 1rem;
			}

			.plcc-legal-sm {
				font-size: 6px;
				padding: 0 20%;
			}

			.plcc-benefit-list {
				text-align: left;
				font-size: .8125rem;
				
				li {
					list-style-type: disc;
				}
			}
		}

		.bottom-buttons {
			background: white;
			bottom: 0;
			padding: 16px 15px;
			position: sticky;
			z-index: 1;
	
			.form-row {
				&.form-indent {
					padding-left: 3px;
					margin-left: 0;
					margin-bottom: 16px;
				}
			}
			.form-row-button {
				display: flex;
				justify-content: space-between;
			}
	
			@media screen and (max-width: 1024px) {
				background: $white;
				width: 100%;
				box-sizing: border-box;
				margin: 0 auto;
			}
		}
	}

	&.checkout-plcc-modal-step3 .bottom-buttons button {
		width: 100%;
	}

	&.checkout-plcc-modal-terms .ui-dialog-titlebar {
		background-image: url(../../images/plcc-card.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		height: 57px;
	}
	&.checkout-plcc-modal-step2 fieldset, 
	&.checkout-plcc-modal .ui-dialog-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.dialog-content.ui-dialog-content {
		overflow: auto;
		padding: 0;
	}

	.plcc-terms-and-conditions {
		margin: 15px;

		.prescreen-message {
			border: 3px solid $black;
			padding: 10px;
		}
	}

	.plcc-model-dialog .plcc-offer-banner {
		padding: 0 10px 10px;
	}

	.plcc-offer-banner {
		display: flex;
		margin: 0 -16px;

		.plcc-offer-banner-left {
			background: url(../../images/plcc-card.png) no-repeat center;
			background-size: contain;
			width: 130px;
			height: 90px;
		}
	}
	.pre-screen-notice {
		margin-bottom: 15px;
	}
	.plcc-terms-banner-container {
		background: $plcc-banner-gray;
		margin: 15px -15px;

		.plcc-terms-banner {
			color: $white;
			padding: 16px;
			text-align: center;
			
			.plcc-subtitle {
				font-family: "Helvetica Neue LT W01_71488914";
				font-size: 1.125rem;
			}
			.plcc-message {
				font-size: .75rem;
				font-family: "Helvetica Neue LT W01_55 Roman";
			}
	
			.plcc-details-container {
				display: flex;
				font-family: "Helvetica Neue LT W01_71488914";
				justify-content: space-between;

				.plcc-detail {
					&:not(.plcc-detail-symbol) {
						flex-basis: 25%;
					}
					&.plcc-detail-bonus {
						flex-basis: 33%;
						.plcc-detail-savings {
							align-items: center;
							display: flex;
							.plcc-detail-savings-copy {
								line-height: 1.125;
								margin-left: 5px;
								text-align: left;
							}
						}
					}
					&.plcc-detail-total {
						flex-basis: 15%;
					}
					.plcc-detail-copy {
						font-size: 10px;
					}
					.plcc-detail-savings {
						font-size: .75rem;
						margin-bottom: 5px;

						.plcc-detail-savings-value {
							font-size: 1.75rem;

							sup {
								font-size: 60%;
								top: -7px;
							}
						}
					}
				}
				.plcc-detail-symbol {
					font-size: 28px;
				}
			}
		}
	}

	.plcc-terms-back {
		background: none;
		border: none;
		color: $black;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		font-size: $base-font + 4px;
		font-family: "Helvetica Neue LT W01_55 Roman";
		text-transform: none;

		&:before {
			content: "";
			background: url(../../images/back-arrow.png) no-repeat center;
			background-size: contain;
			height: 20px;
			left: 13px;
			position: absolute;
			width: 11px;
		}
	}
}

.checkout-plcc-modal {
	.form-row-button {
		border-top: 1px solid $black;
		position: sticky;
		bottom: 0;

		.bottom-buttons {
			display: flex;
			justify-content: space-between;

			button,
			button.plcc-get-it-button,
			button.plcc-nothanks-button {
				letter-spacing: 1.4px;
				color: $white;
				border: 0;
				padding: 16px 20px;
	
				&.plcc-nothanks-button {
					background: $white;
					color: $navy-blue;
	
				}
	
			}
		}
	}
}

//PLCC Step2 Dialog
.checkout-plcc-modal-step2 {
	&.ui-dialog {
		.ui-icon-closethick {
			background: url(../../images/close-button-icon.png) no-repeat;
			width: 27px;
			height: 16px;
		}
	}

	.dialog-content {
		overflow: auto;
	}
}

.plcc-model2 {
	&.plcc-main {
		margin-top: 0;
		.form-row-dob {
			position: relative;
			.birthday {
				label {
					font-size: 11px;
					position: absolute;
					left: 15px;
					top: 3px;
					transform: none;
				}
			}
			.invalid-date {
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}

		.bottom-buttons {
			border-top: 1px solid $black;
		}

		.plcc-masked-number {
			font-family: "Helvetica Neue LT W01_71488914";
			font-size: $base-font + 2px;
			padding: 18px 0 14px 12px;
		}
	}

	.plcc-primary {
		width: 100%;
		box-sizing: border-box;
		float: none;

		&.handle-mobile-overflow {
			overflow: auto;
		}
		.iframe-content {
			margin: 0 0 20px 0;
		}

		.form-row {
			&.show-text {
				.selected-option {
					&.selected {
						text-indent: 0;
					}
				}
			}
			.input-text {
				font-weight: bold;
				// Setting larger font-size in mobile for iOS Safari input/form zooming issue
				@media screen and (max-width: 767px) {
					font-size: $base-font + 4px;
				}
			}
		}

		label {
			font-size: 11px;
		}
	}

	.label-text {
		color: $black;
	}

	.termsLink .required-indicator {
		display: none;
	}

	.plcc-ssn-container {
		border: 1px solid $silver;
		display: flex;
		max-height: 50px;
		margin-bottom: 16px;
		position: relative;

		.form-row.ssn {
			position: static;
			width: 74%;

			label {
				position: absolute;
				font-size: 11px;
				left: 15px;
				top: 3px;
				transform: none;
			}

			.input-text {
				background: transparent;
				padding-left: 2px;
			}
		}

		.invalid-ssn-error {
			color: $sangria;
			bottom: -15px;
			position: absolute;
		}
		.field-wrapper {
			margin-bottom: 0;
		}
		.preapprovedssn {
			border: none;
			font-family: "Helvetica Neue LT W01_71488914";
			font-size: $base-font + 2px;
			padding: 18px 0 14px 12px;
		}
	}

	.plcc-upper {
		padding: 25px 15px 0 15px;
		margin: 0;
	}

	.plcc-lower {

		.plcc-modal-error {
			color: $sangria;
			margin: 0 0 10px 0;
		}

		button {
			padding: 14px 10px;
			flex-basis: 49%;

			&.plcc-nothanks-button {
				background: $white;
				border: none;
				color: $navy-blue;
			}
		}
	}


	.pre-screen-notice {
		.underlined {
			text-decoration: underline;
		}
	}

	.plcc-accept-button {
		float: right;
	}

	.plcc-profile-summary {
		margin-bottom: 16px;
		.plcc-header-row {
			margin-bottom: 14px;
		}

		.plcc-profile-details {
			font-size: $base-font + 1px;
			line-height: 18px;
		}
	}

	.plcc-form-header {
		font-family: "Helvetica Neue LT W01_71488914";
		font-size: $base-font + 4px;
		margin-bottom: 16px;
	}

	.show-profile .plcc-form-header {
		margin-top: 0;
	}
}

//PLCC Apply Error Model
.plcc-apply-error-model {
	&.ui-dialog {
		.ui-icon-closethick {
			display: none;
		}

		.ui-dialog-titlebar {
			button {
				cursor: not-allowed;
				pointer-events: none;
			}
		}

		button {
			cursor: pointer;
		}
	}
}

.ui-dialog-content .plcc-apply-model {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	.review-model{
		display:flex;
		flex-direction: column;
		justify-content: space-between;
		height:100%;
	}
	.error-model:before {
		content: "";
	}

	&.plcc-apply-error {
		.error-model {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			.bottom-buttons{
				padding: 16px 10px;
			}
		}
	}
}

.approved-model,
.review-model {
	margin: 0 20px;
	text-align: center;
	margin-bottom: 15px;

	.header {
		margin: 24px 0 5% 0;
		font-family: "Helvetica Neue LT W01_71488914";
		line-height: 20px;
		font-size: $base-font + 8px;
		color: $black;

		.header-name {
			text-transform: capitalize;
		}
	}

	.sub-header {
		margin: 0 auto;
		margin-bottom: 4%;
		font-size: $base-font + 4px;
	}

	.continue-message {
		display: inline-block;
		width: 100%;
		margin: 0 0 4% 0;
	}

	.guest-sign-in {
		float: left;
	}

	.plcc-card {
		background: url(../../images/plcc-card.png) no-repeat center;
		background-size: contain;
		height: 175px;
		width: 100%;
	}

	.approved-giftcard,
	.approved-legal,
	.approved-message,
	.review-message {
		margin-bottom: 16px;
	}

	.review-message,
	.approved-message {
		font-size: $base-font + 4px;
	}

	.approved-message-part2 {
		font-family: "Helvetica Neue LT W01_71488914";
	}

	.approved-legal {
		color: $legal-gray;
		font-size: 10px;
		font-family: 'Helvetica Neue Regular';
		line-height: 13px;
		.plcc-terms-open {
			font-size: 10px;
			font-family: "Helvetica Neue LT W01_71488914";
			text-decoration: underline;
		}
	}

	.approved-giftcard {
		font-family: "Helvetica Neue LT W01_71488914";
		font-size: $base-font + 1px;
	}

}

//PLCC Acquisition Rebuttal Model
.plcc-acquisition-rebuttal-model {
	&.ui-dialog {
		.ui-icon-closethick {
			background: url(../../images/close-button-icon.png) no-repeat;
			width: 22px;
			height: 22px;
		}
		.ui-widget-header {
			height: 60px;
		}
	}
	.scrollingpart {
		overflow-y: auto;
		border-bottom: 1px solid $alto;	
	}
}
.plcc-acquisition-rebuttal-model {
	.legal-copy-message {
		&.rebuttal-in-hide {
			display: none;
		}
	}
	.rebuttal-banner {
		margin: 0 auto;
		text-align: center;
		position: relative;
	}

	.rebuttal-banner-text {
		position: absolute;
		top: 52%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		left: 50%;
		width: 83%;
		display: inline-block;
	}

	.rebuttal-section {
		padding: 0 40px 0 40px;
	}

	.form-row-button {
		margin: 20px 40px;
	}
}

.plcc-application-banner {
	background: $plcc-banner-gray;
	border-radius: 2px;

	.plcc-banner-details {
		color: $white;
		padding: 0 5px 4px;
		text-align: right;

		& > .plcc-subtitle {
			font-family: "Helvetica Neue LT W01_71488914";
			font-size: 1.125rem;
		}

		.plcc-banner-details-link {
			text-align: right;

			.plcc-terms-open {
				color: $white;
				font-size: 10px;
				text-decoration: underline;
			}
		}
	}
	.plcc-credit-card-image {
		background: url(../../images/plcc-card.png) no-repeat center;
		background-size: contain;
		width: 90px;
		height: 70px;
		position: absolute;
		left: 3px;
		top: 9px;
	}
}

.plcc-offer-banner {
    align-items: center;
	background: linear-gradient(270deg, $plcc-banner-gray 0%, #C4C2D2 100%);
	border-radius: 2px;
	color: $white;
	display: flex;
	min-height: 35px;
	margin-bottom: 25px;
}

.plcc-header-row {
	display: flex;
	justify-content: space-between;
	font-size: 16px;

	.plcc-edit-profile,
	.plcc-details-open {
		color: $navy-blue;
		font-family: "Helvetica Neue LT W01_55 Roman";
		font-size: 13px;
	}

	.plcc-form-header {
		margin-bottom: 0;
	}
}

.plcc-modal-accordion-content {
	.plcc-details-content {
		font-family: "Helvetica Neue LT W01_55 Roman";
		line-height: 18px;

		&:first-child {
			margin-top: 0;
		}
	}

	.plcc-accordion-control {
		display: none;
	}
}
.billing-cards-section .checkout-rebuttal-new {
    border: 3px solid #000;
    padding: 10px 15px 15px 15px;
    margin: 0 0 23px 0;
    background-color: $white;

	.exclamation-icon-new {
		border-radius: 20px;
		background: black;
		margin-right: 20px;
		height: 20px;
		position: relative;
		width: 20px;
		display: inline-block;
		color: $white;
		margin-bottom: -5px;

		span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}
}


.billing-cards-section .checkout-rebuttal {
    border: 2px solid $dusty-gray;
    padding: 10px 15px 15px 15px;
    margin: 0 0 23px 0;
    background-color: $white;

    .plcc-rebuttal-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .plcc-rebuttal-top-header {
        font-family: "Helvetica Neue LT W01_71488914";
        font-size: $base-font + 8px;

        @media screen and (max-width: 767px) {
            font-size: $base-font + 1px;
        }

        &:before {
            content: "";
            background: url(../../images/plcc-card.png) no-repeat center;
            background-size: contain;
            height: 35px;
            width: 45px;
            display: inline-block;
            vertical-align: middle;
            margin-left: -10px;
        }
    }

    .plcc-rebuttal-link {
        color: #004ECD;
    }

    .plcc-benefit-list li {
        list-style-type: disc;
    }

    .plcc-legal {
        font-size: 10px;
        color: #808080;
    }

    .plcc-bottom-links {
        display: flex;
        justify-content: space-between;
    }

    .plcc-accordion-control {
        display: none;
    }

    .plcc-first-name {
        text-transform: capitalize;
    }
}

@media screen and (max-width: 767px) {
	.plcc-model2 {
		&.plcc-main {
			margin: 0;
			padding: 0;

			.plcc-primary {
				.form-row {
					&.form-indent {
						label {
							float: inherit;
						}
					}
					&.month {
						width: 33.33%;
					}
				}
				.plcc-form-row {
					&.plcc-form-row-1 {
						width: 100%;
						margin: 0;
					}
				}

				.important-info {
					padding: 0 0 15px 0;
				}

				.phone-number-label {
					display: inline-block;
				}
			}
		}

		.pre-screen-notice {
			padding: 18px 6px;
		}

		.plcc-lower {
			.plcc-nothanks-button {
				width: 41%;
			}

			.plcc-accept-button {
				width: 49.9%;
			}
		}
	}
	.ui-dialog {
		&.forgot-password {
			.breadcrumb, .back-to-login {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.plcc-model2 {
		.plcc-lower {
			.plcc-nothanks-button {
				padding: 14px 18px;
				width: auto;
			}

			.plcc-accept-button {
				width: auto;
				padding: 14px 10px;
			}
		}
	}
}
}