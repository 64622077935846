@media screen and (min-width: 1024px) {
    .hide-desktop {
        display: none;
    }
    .header_fixed {
        .header-promo-bottom {
            display: none;
        }

        .fixed-header {
            position: fixed;
            top: 0;
            background: $white;
            z-index: 26;
            width: 100%;
            left: 0;
            transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
        }

        #navigation {
            .menu-category {
                margin: 0;
            }
        }

        .top-menu-utility {
            height: 0;

            .header-brand-selector {
                display: none;
            }

            .left-section {
                display: none;
            }
        }
        .top-banner {
            .header-mian-banner {
                padding: 0;

                .primary-logo,
                .header-secondary {
                    display: none;
                }

                #navigation {
                    .menu-category {
                        border: none;
                        width: 92.38%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .header_fixed .fixed-header {
        border-bottom: 1px solid $very-light-gray;
    }
}

@media screen and (min-width: 1024px) {
    .header_fixed {
        .top-menu-utility {
            .right-section {
                #mini-cart {
                    .mini-cart-content {
                        top: 100%;
                    }
                }
            }
            .menu-utility {
                .menu-utility-user {
                    .right-section {
                        #mini-cart {
                            .mini-cart-link {
                                background-color: transparent !important;
                                padding-left: 16px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    #wrapper,
    #primary,
    .menu-active,
    .menu-footer {
        .hide-desktop,
        div.hide-desktop,
        div > .hide-desktop,
        div > div.hide-desktop {
            display: none;
        }
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .header_fixed {
        .top-menu-utility {
            .right-section {
                #mini-cart {
                    .mini-cart-content {
                        top: 100%;
                    }
                }
            }
        }
    }

    #wrapper,
    .menu-active,
    .menu-footer {
        .hide-tablet,
        div.hide-tablet,
        div > .hide-tablet,
        div > div.hide-tablet {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    #wrapper,
    .menu-active,
    .menu-footer {
        .hide-mobile,
        div.hide-mobile,
        div > .hide-mobile,
        div > div.hide-mobile {
            display: none;
        }
    }
    .sub-total {
        &.hide-desktop {
            display: none;
        }
    }
    .hide-mobile {
        display: none;
    }
    .refinement-breadcrumb,
    .sort-breadcrumb {
        .refine-by-clearall {
            float: left;
            margin-top: 10px;
            width: 100%;
            .breadcrumb-refinement-value {
                float: right;
                margin-right: 0;
                background: transparent;
            }
        }
    }
    #header.header_fixed {
        .fixed-header {
            display: sticky;
        }
    }
}

@media screen and (min-width: 1025px) and (max-width: 1320px) {
    .top-menu-utility {
        .menu-utility {
            .menu-utility-user {
                right: 0;
            }
        }
    }
    .top-banner {
        .header-secondary {
            .header-search {
                .simplesearch {
                    right: 0;
                }

                #search-suggestions {
                    .search-suggestion-wrapper {
                        right: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .pt_product-details {
        #main {
            .product-col-1 {
                &.product-bundle {
                    padding-right: 0px;
                    &.product-bundle-main {
                        width: 58%;
                    }
                }
            }
            .product-bundle {
                &.product-bundle-main {
                    .product-col-2 {
                        width: 100%;
                        &.product-set {
                            .product-add-to-cart {
                                width: 100%;
                                .inventory {
                                    width: 20%;
                                }
                                button {
                                    float: left;
                                    width: 80%;
                                }
                            }
                        }
                        &.product-detail {
                            .product-actions {
                                text-align: left;
                                .share-icon-container {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    /* large tablets and below */
    .product-tile #quickviewbutton {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .pt_product-details {
        #main {
            .product-bundle {
                &.product-bundle-main {
                    .product-col-2 {
                        &.product-set {
                            .product-add-to-cart {
                                width: 100%;
                                .inventory {
                                    width: 20%;
                                }
                                button {
                                    float: left;
                                    width: 80%;
                                }
                            }
                        }
                        &.product-detail {
                            .product-actions {
                                text-align: left;
                                .share-icon-container {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .header_fixed {
        top: 0;
    }

    img {
        max-width: 100%;
    }

    .tooltip {
        font-size: 0.9em;
    }

    .form-field-tooltip {
        float: none;
        margin: 0 0 10px 5px;
        width: auto;
    }
    #mini-cart {
        .mini-cart-name {
            a {
                color: $black;
            }
        }
    }
    .ui-dialog {
        max-width: 98%;
        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }
    .col-1,
    .col-2 {
        float: none;
        width: 100%;
    }
    .scrollable {
        height: auto;
        li {
            float: left;
            a {
                border: 1px solid $blue-berry;
                border-radius: 6px;
                -webkit-border-radius: 6px;
                -moz-border-radius: 6px;
                display: block;
                padding: 0.2em 0.8em;
            }
        }
    }
    #header {
        height: auto;
        padding: 0;

        .primary-logo {
            padding: 0;

            img {
                max-width: 100%;
            }
        }
    }

    .mini-cart-products {
        max-height: 100%;
        padding: 10px;
        overflow: auto;

        .mini-cart-product {
            border-bottom: 1px solid $silver;
            padding: 0 0 20px 0;

            .mini-cart-name {
                font-size: $base-font + 2px;
                width: 50%;
                font-style: normal;
                padding: 0 0 20px 0;

                a {
                    color: $black;
                }
            }

            .mini-cart-attributes,
            .mini-cart-pricing {
                font-size: $base-font - 1px;
                width: 50%;
                font-style: normal;

                span {
                    color: $black;
                    padding: 0 0 10px 0;
                    display: inline-block;
                }
            }

            &.collapsed {
                .mini-cart-image {
                    display: block;
                }
            }
        }
    }
    .mini-cart-totals {
        .button {
            height: auto;
            line-height: normal;
            overflow: inherit;
            padding: 15px 50px;
            margin-bottom: 25px;
            background: $wine-berry;
            color: $white;
            border: none;
        }

        .mini-cart-slot {
            background: none;
            border: none;
            font-size: 0.9em;
            font-style: italic;
            margin: 1em 1em;
        }

        .mini-cart-subtotals {
            padding: 15px 0 25px 0;
            font-size: $base-font - 1px;

            span {
                color: $black;

                &.label {
                    margin: 0 0.5rem;
                }

                &.value {
                    margin-right: 0.5rem;
                }
            }
        }
    }
    .mini-cart-content {
        max-width: 280px;
        background: white;
        z-index: 1;
        margin-right: 10px;
        top: 4.3em;
        border: none;
        box-shadow: 0 18px 30px 0px rgba(229, 229, 229, 0.5);
        -webkit-box-shadow: 0 18px 30px 0px rgba(229, 229, 229, 0.5);
        -moz-box-shadow: 0 18px 30px 0px rgba(229, 229, 229, 0.5);
        position: absolute;
        right: 0;
        display: none;
    }
    #quickviewbutton {
        display: none !important;
    }
    .search-result-options {
        margin: 0.5em 0;
        .sort-by {
            margin: 0;
            select {
                width: 120px;
            }
        }
        .items-per-page {
            display: none;
        }
        .pagination {
            margin: 0;
            .results-hits {
                display: none;
            }
        }
    }
    .product-tile {
        height: auto !important;
    }
    .wide-tiles .product-tile {
        .product-image {
            width: 41%;
        }
        .product-name,
        .product-pricing,
        .product-promo,
        .pr_stars,
        .product-swatches {
            float: right;
        }
        .product-name h2 {
            margin-top: 0px;
        }
    }
    .product-listing-1x4 ul.search-result-items .grid-tile {
        margin: 2%;
        width: 92%;
    }
    .product-compare,
    .compareitems {
        display: none !important;
    }
    .pt_product-details {
        .breadcrumb .last {
            display: none;
        }
    }
    #product-nav-container {
        bottom: 0;
        width: 100%;
        div {
            width: 60px;
            &.product-next {
                float: right;
            }
        }
        .divided span {
            border: 0;
        }
    }
    .sizinginformation {
        padding: 0;
        th {
            font-weight: normal;
            padding: 0 0 0 2px;
        }
        td {
            background: $white;
            padding: 5px;
            text-align: center;
        }
        .sizechart {
            padding: 0 !important;
        }
    }
    .ui-dialog .ui-dialog-content {
        padding: 0.25em;
    }
    .pt_cart #secondary {
        display: none;
    }
    .order-summary-footer h2 {
        width: 50%;
    }
    .place-order-totals .order-totals-table {
        width: 100%;
    }
    .payment-method-options {
        padding: 0;
        .form-row {
            width: 100%;
            label {
                float: left;
                margin: 0;
                padding: 0 0 0 1em;
                width: 150px;
            }
            .input-radio {
                margin: 0;
            }
        }
    }
    .pt_order-confirmation {
        .item-list {
            th {
                display: none;
            }
            td {
                float: left;
                padding: 1em 0;
                width: 98%;
            }
            .order-totals-table td {
                float: left;
                width: 48%;
            }
        }
        .order-shipment-table {
            th {
                display: none;
            }
            td {
                font-size: 0.9em;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                float: left;
            }
            .line-item-details {
                width: 70%;
            }
            .line-item-quantity {
                width: 10%;
            }
            .line-item-price {
                width: 20%;
            }
            .order-shipment-details {
                border: none;
                width: 100%;
            }
            .scrollable {
                height: auto;
                li {
                    float: left;
                    a {
                        border: 1px solid $blue-berry;
                        border-radius: 6px;
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                        display: block;
                        padding: 0.2em 0.8em;
                    }
                }
            }
            #header {
                height: auto;
                padding: 1% 1% 30px;
                .primary-logo {
                    float: left;
                    position: relative;
                    top: auto;
                    width: 65%;
                    img {
                        max-width: 100%;
                    }
                }
            }
            .mini-cart-products {
                max-height: 100%;
            }
            .mini-cart-content {
                max-width: 220px;
            }
            #quickviewbutton {
                display: none !important;
            }
            ul.search-result-items .grid-tile {
                margin: 2%;
                width: 92%;
            }
            .product-tile {
                height: auto !important;
            }
            .wide-tiles .product-tile {
                .product-image {
                    width: 41%;
                }
                .product-name,
                .product-pricing,
                .product-promo,
                .pr_stars,
                .product-swatches {
                    float: right;
                }
                .product-name h2 {
                    margin-top: 0px;
                }
            }
            .product-tile .product-swatches .swatch img {
                height: 2em;
                width: 2em;
            }
            .product-listing-1x4 ul.search-result-items .grid-tile {
                margin: 2%;
                width: 92%;
            }
            .product-compare,
            .compareitems {
                display: none !important;
            }
            .pt_product-details {
                .breadcrumb .last {
                    display: none;
                }
            }
            #product-nav-container {
                bottom: 0;
                width: 100%;
            }
        }
        .create-new-registry {
            overflow: hidden;
            p {
                display: none;
            }
        }
        .order-summary-footer h2 {
            width: 50%;
        }
        .place-order-totals .order-totals-table {
            width: 100%;
        }
        .payment-method-options {
            padding: 0;
            .form-row {
                width: 100%;
                label {
                    float: left;
                    margin: 0;
                    padding: 0 0 0 1em;
                    width: 150px;
                }
                .input-radio {
                    margin: 0;
                }
            }
        }
        .pt_order-confirmation {
            .item-list {
                th {
                    display: none;
                }
                td {
                    float: left;
                    padding: 1em 0;
                    width: 98%;
                }
                .order-totals-table td {
                    float: left;
                    width: 48%;
                }
            }
            .order-shipment-table {
                th {
                    display: none;
                }
                td {
                    font-size: 0.9em;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    float: left;
                }
                .line-item-details {
                    width: 70%;
                }
                .line-item-quantity {
                    width: 10%;
                }
                .line-item-price {
                    width: 20%;
                }
                .order-shipment-details {
                    border: none;
                    width: 100%;
                }
            }
        }
        .order-history-header button {
            font-size: 1em;
            padding: 0.25em;
        }
        .page-content-tab-navigaton button {
            width: 100%;
        }
        .pt_gift-registry {
            .add-gift-cert {
                p {
                    width: 100%;
                }
            }
            .create-new-registry {
                overflow: hidden;
                p {
                    display: none;
                }
            }
            .item-list {
                width: 98%;
                th {
                    display: none;
                }
            }
        }
        .order-shipment-table .section-header {
            font-size: 0.9em !important;
            padding: 0.3em;
        }
        table.item-list .section-header,
        .order-shipment-table .section-header {
            background-image: none;
        }
        table.item-list {
            .item-image,
            .item-details,
            .item-availability,
            .item-dashboard {
                display: inline-block;
            }
            .item-image {
                width: 30%;
            }
            .item-details {
                width: 50%;
            }
            .item-availability,
            .item-dashboard {
                width: 97%;
            }
            .delete-registry {
                text-align: center;
            }
        }
        .list-table-header {
            padding: 1em !important;
            .button {
                display: block;
                margin: 20px 0;
                width: 196px;
            }
            p {
                display: none;
            }
            button {
                display: block;
                width: 260px;
            }
        }
        .home-bottom-left,
        .home-bottom-center {
            width: 100% !important;
        }
        .home-bottom-right {
            clear: both;
            float: none;
            width: 100% !important;
            .product-tile {
                min-height: 0;
            }
        }
        .product-tile .product-image {
            height: auto;
        }
        #email-alert-signup {
            margin: 0;
            width: 100%;
            label {
                text-align: center;
                width: 100%;
            }
            input [type="text"] {
                float: none;
                margin: 0;
                width: 50%;
            }
            input[type="image"] {
                float: none;
                vertical-align: bottom;
            }
        }
        .facebook-registration {
            left: -3px;
            position: relative;
            width: 100.7% !important;
            iframe {
                position: relative;
                width: 100.7% !important;
            }
        }
    }
}
@media screen and (min-width: 480px) and (max-width: 767px) {
    .product-tile .pr_stars {
        left: 40px;
    }
    .wide-tiles .product-tile {
        .product-name h2 {
            margin-top: 10px;
        }
        .product-image {
            width: 38%;
        }
    }
    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
    .pt_cart {
        .primary-content {
            width: 100%;
        }
    }
    #secondary {
        width: 171px;
    }
    .primary-focus {
        .primary-content {
            width: 540px;
        }
        #secondary {
            width: 190px;
        }
    }
    .html-slot-container img,
    .bottom-banner-cell img {
        max-width: 100%;
    }
    .ui-dialog {
        max-width: 98%;
        .ui-dialog-content {
            height: auto !important;
            overflow: visible;
        }
    }
    .scrollable {
        height: auto;
    }
    .mini-cart-total-label {
        display: none;
    }
    .mini-cart-products {
        max-height: 100%;
    }
    ul.search-result-items {
        .new-row {
            clear: none;
        }
    }
    table .section-header {
        font-size: 1em !important;
    }
    #compare-table td .product-tile {
        width: 79px;
    }
    .pt_gift-registry .add-gift-cert p {
        width: auto;
    }

    .home-bottom-right .product-tile {
        min-height: 0;
    }
    #email-alert-signup {
        margin: 0;
        width: 100%;
        label {
            float: none;
            display: block;
            text-align: center;
            width: 100%;
        }
        input[type="text"] {
            width: 73%;
        }
    }
    .facebook-registration {
        left: 0px;
        position: relative;
        width: 100% !important;
        .fb_iframe_widget {
            position: relative;
            width: 100% !important;
            span {
                position: relative;
                width: 100% !important;
                iframe {
                    position: relative;
                    width: 100% !important;
                }
            }
        }
    }
    .mini-cart-item-word {
        span {
            display: none;
        }
    }
}

@media screen and (max-width: 480px) {
    #preferred-store-panel input[type="text"] {
        width: 49.5966%;
    }
}

@media screen and (max-width: 1024px) {
    .form-row {
        &.select-handle {
            label {
                pointer-events: none;
            }
        }
    }
}
