.product-monogramming,
.product-hemmable {
	.checkbox {
		z-index: 0;
	}
	.input-fields-set {
		width: 100%;
		float: none;
		position: relative;
		clear: both;

		.character-left {
			&.text-uppercase {
				input {
					text-transform: uppercase;
				}
			}

			&.text-initial {
				input {
					text-transform: none;
				}
			}
		}
	}
}

.custom-select-single-option {
	margin: 20px 0 0 0;
	float: none;
}

.single-selection {
	display: block;
	line-height: 48px;
	overflow: hidden;
	padding: 0 16px;
	width: 100%;
	min-height: 50px;
	box-sizing: border-box;
}

.single-selection {
	span {
		&.attribute {
			color: $dusty-gray;
		}

		&.value {
			color: $black;
		}
	}
}

.product-monogramming {
	.checkbox {
		position: relative;

		/* Remove default checkbox */
		[type="checkbox"]:not(:checked),
		[type="checkbox"]:checked {
			position: absolute;
			z-index: 9999;
			opacity: 0;
		}
		[type="checkbox"]:not(:checked) + label,
		[type="checkbox"]:checked + label {
			position: relative;
			padding-left: 21px;
			cursor: pointer;
			display: inline-block;
			height: 23px;
			line-height: 23px;
			transform: none;
			left: 0;
			top: 0;
		}
		/* checkbox aspect */
		[type="checkbox"] + label:before,
		[type="checkbox"] + label:after {
			content: "";
			position: absolute;
			left: 0;
			z-index: 1;
		}
		/* Unchecked styles */
		[type="checkbox"]:not(:checked) + label:before {
			top: 0px;
			width: 18px;
			height: 18px;
			border: 2px solid $black;
		}
		[type="checkbox"]:not(:checked) + label:after {
			top: 0px;
			width: 18px;
			height: 18px;
			border: 2px solid $black;
			z-index: 0;
		}
		/* Checked styles */
		[type="checkbox"]:checked + label:before {
			width: 21px;
			height: 21px;
			background: url("../../../images/check.svg") no-repeat center;
		}
		[type="checkbox"]:checked + label:after {
			top: 0px;
			width: 18px;
			height: 18px;
			border: 2px solid $black;
			z-index: 0;
		}

		label {
			span {
				display: inline-block;
			}
		}
	}
}

.product-hemmable {
	display: inline-block;
	.checkbox {
		position: relative;

		input:focus {
			outline: 2px solid $black;
		}

		[type="checkbox"]:not(:checked) + label,
		[type="checkbox"]:checked + label {
			position: relative;
			cursor: pointer;
			display: inline-block;
			height: 23px;
			line-height: 23px;
			transform: none;
			left: 0;
			top: 0;
		}

		label {
			span {
				display: inline-block;
			}
		}
	}
	.hemming-button {
		display: block;
		height: 100%;
		width: 100%;
		padding: 10px;
		margin: 0px;
		background: transparent;
		border: 0;
		color: inherit;
		font-size: inherit;
		font-weight: inherit;
		letter-spacing: inherit;
	}
}
