.email-popup {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 99999;

	.focus-outline {
		outline-offset: -2px;
	}
	img {
		max-width: 100%;
		height: auto;
		margin: 0 auto;
	}

	.email-popup__overlay {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.8);
	}

	.email-popup__inner,
	#success-email-international {
		height: auto;
		margin: 0px auto;
		position: relative;
		width: 500px;
		opacity: 1;
		display: block;
		transition: opacity 0.5s ease 0s;
		@media screen and (max-width: 767px) {
			width: 90%;
			max-width: 500px;
		}
	}

	.hide {
		display: none !important;
	}

	.vertical-align {
		position: relative;
		height: 100%;
	}

	.vertical-align .vertical-align-outer {
		display: table;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	.vertical-align.center .vertical-align-inner {
		display: table-cell;
		vertical-align: middle;
	}
}
