.search-suggestion-wrapper {
	@extend %clearfix;
	background-color: $white;
	border: none;
	position: absolute;
	width: 100%;
	z-index: 1;
	.search-result-label {
		padding: 1em 2em;
		color: $black;
		text-transform: uppercase;
	}

	.slimScrollDiv {
		margin: 0 !important;
		.product-suggestions {
			.product-suggestion {
				padding: 5px 0 5px;
			}
		}
		.slimScrollBar {
			opacity: 0.5 !important;
		}
	}

	.search-scrollbar-height {
		width: 100% !important;
		display: inline-block;
		padding: 0 20px;
		background-color: rgba(216, 216, 216, 0.2);
		box-sizing: border-box;
	}

	@media screen and (min-width: 768px) and (max-width: 1023px) {
		padding: 0;

		&.full {
			.product-suggestions {
				width: 100%;
				float: right;
			}
		}
	}

	@media screen and (min-width: 1024px) {
		&.full {
			.product-suggestions {
				width: 100%;
				float: right;
				padding-left: 15px;

				.product-suggestion {
					.product-link {
						display: -webkit-inline-box;
						display: -ms-inline-flexbox;
						display: inline-flex;
					}
				}
			}
		}
	}

	.hitgroup {
		color: $black;
		margin: 0.7rem 0 0.7rem 1.5rem;
		border-bottom: 1px solid $very-light-gray;

		&:last-child {
			border-bottom: 0;
		}

		.header {
			margin-top: 20px;
			width: calc(100% - 60px);
			border-bottom: 1px solid #cccccc;
			padding-bottom: 10px;
			text-transform: uppercase;
		}

		.hit {
			display: block;
			margin-left: 1rem;
			margin-bottom: 0.5rem;
			color: $disco;
			text-transform: capitalize;

			&:hover {
				text-decoration: none;
			}

			a {
				color: $black;
			}
		}

		.parent-category {
			color: $black;
		}
	}
}

.phrase-suggestions {
	border-top: 1px solid $very-light-gray;

	@media screen and (min-width: 768px) {
		border-top: none;
	}

	@media screen and (min-width: 1024px) {
		border-top: none;
		padding-top: 1px;
		padding-bottom: 1px;
	}
}

.product-suggestion {
	@extend %clearfix;
	border-bottom: 1px solid $very-light-gray;
	padding: 0.5em;
	width: 100%;
	box-sizing: border-box;

	&:last-child {
		border-bottom: 0;
	}

	.product-image {
		float: left;
		text-align: center;
		width: 25%;
		img {
			max-width: 100%;
			float: left;
		}
	}

	.product-name {
		text-transform: capitalize;
	}

	.product-details {
		width: 60%;
		float: left;
		padding-left: 15px;
		margin-left: 8px;

		.product-name {
			font-size: $base-font + 4px;
			color: $black;
		}

		.product-price {
			color: $black;
			padding-top: 20px;
			text-transform: capitalize;
			font-size: $base-font + 4px;

			span {
				&.subunit {
					position: relative;
					left: -3px;
				}
			}
		}
	}
}

.search-phrase {
	color: $black;
	border-bottom: 1px solid $dark-gray;
	padding: 3px 0 17px 3px;
	letter-spacing: 1px;
	display: none;

	a {
		display: inline-block;
		vertical-align: middle;

		span {
			text-transform: capitalize;
			color: $black;
		}
	}

	.completed {
		font-style: italic;

		.original {
			text-decoration: none;
			font-weight: bold;
		}
	}

	.corrected {
		font-weight: bold;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.header_fixed {
		.search-suggestion-wrapper {
			.product-suggestion {
				.product-details {
					.product-name {
						overflow-wrap: break-word;
						width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.search-suggestion-wrapper {
		.suggested-phrase {
			padding-bottom: 5px;
		}
		.phrase-suggestions {
			padding-bottom: 20px;
		}
	}
}

.search-suggestion-wrapper {
	.search-label-font,
	.phrase-suggestions .search-label-font {
		font-family: $WorkSansBold;
		font-size: $base-font - 2px;
		font-weight: 700;
	}
}
