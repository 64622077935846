@media print {
	// Header
	header {
		.menu-utility {
			.user-panel {
				display: none;
			}
		}
	}

	// Order Confirmation
	.pt_order-confirmation #mini-cart,
	.pt_order-confirmation .menu-utility-user,
	.pt_order-confirmation .menu-utility,
	.pt_order-confirmation .header-banner,
	.pt_order-confirmation #navigation,
	.pt_order-confirmation .pdf-print,
	.pt_order-confirmation .header-main-search {
		display: none;
	}
}
