/**** colors ****/

/***** names taken from http://www.color-blindness.com/color-name-hue/ *******/
$grid-attr-bg: #f5f6f6;
$black: #000000;
$slightblack: #010101;
$slightblack-two: #020202;
$mortar: #545454;
$nero: #1c1c1c;
$white: #ffffff;
$light-white: #ff0;
$mixed-gray: #c0c0c0;
$dim-gray: #666666;
$lighter-green: #75de9f;
$wine-berry: #651e40;
$slightgrey: #b3b3b3;
$plcc-error: #e31313;
$disco: #9f1557;
$fountain-blue: #5ebbb8;
$radical-red: #ff3a51;
$red-orange: #ff3434;
$silver: #aaaaaa;
$alto: #dddddd;
$wild-sand: #f4f4f4;
$yellow: #f8fc02;
$dusty-gray: #999999;
$light-black: #131313;
$chambray: #3b5998;
$slight-blue: #321e53;
$cat-red: #9f1557;
$zircon: #fdfeff;
$gray: #8f8f8f;
$light-gray: #eaeaea;
$light-skygrey: #f5f5f5;
$light-mercury-gray: #e6e6e6;
$blue-berry: #d9d9d9;
$cerulean: #00629b;
$charcoal: #424242;
$citrus: #84bd00;
$dark-gray: #aaaaaa;
$gainsboro: #e1e1e1;
$islamic-green: #019328;
$lawn-green: #6dd400;
$pastel-green: #50e263;
$night-rider: #333333;
$nobel: #999999;
$rain-forest: #64772d;
$sangria: #990000;
$stark-white: #cebdb3;
$teal: #008578;
$torea-bay: #0f2f88;
$tutu: #faeded;
$very-light-gray: #cccccc;
$white-smoke: #efefef;
$concrete: #f3f3f3;
$alabaster: #f8f8f8;
$mercury: #e7e7e7;
$mangotango: #c55300;
$dark-red: #e70000;
$lightpink: #f83c5b;
$lighter-grey: #f6f6f6;
$light-black-two: #2d2d2d;
$gainsboro: #dcdcdc;
$hollywood-cerise: #d5007e;
$spicy-pink: #d5007e;
$puerto-rico: #5ebbbb;
$white-lilac: #d7d6db;
$white-smoke-light: #f7f7f7;
$white-whisper: #e4e4e4;
$RedRibbon: #e6003c;
$RedRibbon-thick: #B50000;
$venetian-red: #d0021b;
$medium-carmine: #af3434;
$light-pink: #ffd4ed;
$light-blue: #d8d8e3;
$sliver-light: #c6c6c6;
$wild-sand-dark: $light-skygrey;
$chambray: #395799;
$Disco: #a8185d;
$Monza: #dc0505;
$redmonza: #de0707;
$lightOrange: #eba346;
$dustygray: #9a9a9a;
$error-crl: #900;
$mainly-blue: #331e53;
$grape: $mainly-blue;
$guardsman-Red: #bd0304;
$dark-pink: #a01558;
$alto-grey: #dedede;
$tulip-tree: #eda43b;
$columbia-blue: #aae1fb;
$navy-blue: #0070f0;
$gallery: #f0f0f0;
$gallery-light: #ebebeb;
$reddish-monza: #d0021b;
$mango-monza: #dc8700;
$old-brick: #951919;
$warm-grey: #7d7d7d;
$dark-grey:#949494;
$manatee: #8d89a5;
$coral-candy: #f3c0c6;
$dodger-blue: #187aec;
$sky-blue: #4a90e2;
$light-grey: #f5f5f5;
$circlegreen: #28a228;
$empress: #756c6c;
$separator-color: #e2e2e2;
$surfie-green: #007a7c;
$purple: #800080;
$mortar-grey: #525252;
$navy-blue: #0750c5;
$CA-rewards-register-anchor:#004ecd;
$plcc-banner-gray: #ABA8BE;
$header-megamenu-seperator: #D8D8D8;
$legal-gray: #808080;
$silver-thick: #525252;
$text-sale: #A70033;
$light-black-three: #343a40;

//Cart page
$heading-black: #131415;
//Romans colors
$grapee: $slight-blue;
$mercuryy: #e1e1e1;
$mineshaft: #363636;
$tuliptree: #eda43b;
$midium-grey: #9d9d9d;
$razzmatazz: #e40742;
$torchred: #f00041;
$salered: #af3434;
$sw-brand-blue: #002554;
$sw-brand-grey: #6a665c;
$sw-heading-bg: #f2f2f2;
/***** background ******/

$light-red: #ff3a51;
$wcag-grey: #666666;
$wcag-pink: #d5007e;

/****** Promo ******/
$promo-green: #28a228;
$promo-red: #b50000;
/****** PDP ******/
$writeReview: #0071bc;
$productPrice: $black;
$productSalePrice: $radical-red;
$caribbean-green: #0ec7af;
$wcag-dark-blue: #3a7e9e;

/****** You may also like ******/
$recommendation-heading: $black;

/****** flag-icon-css ******/

$flag-icon-css-path: "../../../lib/flag-icon-css/flags";

/****** mini cart slot ******/

$minicart-slot-txt-color: $white;
$minicart-slot-bg-color: $lightpink;
$swatch-types: "black" #000000, "beige" #f5f5dc, "gold" gold, "blue" blue, "purple" purple, "red" red, "brown" #783201,
    "green" green, "grey" #8f979d, "pink" #fe249a, "orange" orange, "white" #ffffff, "yellow" #ffff00, "navy" navy;

/******** PLP Review star ********/
$review-star-color: #ffb64a;

/******** Page class name in my account page ********/

$pagenames: "overview" overview, "profile" profile, "order-history" order-history,
    "email-subscription" email-preferences, "addresses" addresses, "catalog-preferences" catalog-preferences,
    "paymentinstruments" payment-methods, "wishlist" wishlists;

/****** font size ******/

$base-font: 12px;

/*****Font-family*****/

$sans-serif: Arial, sans-serif;

/******** global classes ********/

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.menu {
    float: left;
    margin: 0;
    padding: 0;
    li {
        float: left;
        list-style: none outside none !important;
    }
}

$checkout-header-border-color: $alto;
$shipping-progress-active-bg-color: #080808;
$shipping-progress-active-color: $black;
$shipping-progress-active-border-color: #080808;
$shipping-progress-inactive-bg-color: $white;
$shipping-progress-inactive-color: $dark-gray;
$shipping-progress-inactive-border-color: $dark-gray;
$checkout-error-color: $RedRibbon;
$checkout-error-color-thick: $RedRibbon-thick; 
$error_red: $radical-red;
$prefixArray: webkit ms o moz;
@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @-o-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin animations(
    $name: showBlock,
    $time: 0.5s,
    $delay: 0s,
    $iteration: 1,
    $direction: normal,
    $timing: linear,
    $state: running
) {
    @include prefix(animation-name, $name, $prefixArray);
    animation-name: $name;
    @include prefix(animation-duration, $time, $prefixArray);
    animation-duration: $time;
    @include prefix(animation-delay, $delay, $prefixArray);
    animation-delay: $delay;
    @include prefix(animation-iteration-count, $iteration, $prefixArray);
    animation-iteration-count: $iteration;
    @include prefix(animation-direction, $direction, $prefixArray);
    animation-direction: $direction;
    @include prefix(animation-timing-function, $timing, $prefixArray);
    animation-timing-function: $timing;
    @include prefix(animation-play-state, $state, $prefixArray);
    animation-play-state: $state;
}

@mixin transitions($property: all, $time: 0.5s, $delay: 0, $timing: ease) {
    @include prefix(transition-duration, $time, $prefixArray);
    transition-duration: $time;
    @include prefix(transition-delay, $delay, $prefixArray);
    transition-delay: $delay;
    @include prefix(transition-property, $property, $prefixArray);
    transition-property: $property;
    @include prefix(transition-timing-function, $timing, $prefixArray);
    transition-timing-function: $timing;
}

@include keyframes(showBlock) {
    0%,
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(fadeInRight) {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
    }
}

@include keyframes(fadeInLeft) {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@include keyframes(slideInLeft) {
    0% {
        opacity: 0;
        transform: translateX(-2000px);
    }
    0.1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@include keyframes(slideOutLeft) {
    0% {
        opacity: 1;
        transform: translateX(2000px);
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        transform: translateX(0);
    }
}

@include keyframes(slideInRight) {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }
    0.1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@include keyframes(bounceInUp) {
    0% {
        opacity: 0;
        transform: translateY(2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(-30px);
    }

    80% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0);
    }
}
@include keyframes(bounceInDown) {
    0% {
        opacity: 0;
        transform: translateY(-2000px);
    }

    60% {
        opacity: 1;
        transform: translateY(30px);
    }

    80% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}
@include keyframes(bounceInRight) {
    0% {
        opacity: 0;
        -webkit-transform: translateX(2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(-30px);
    }

    80% {
        -webkit-transform: translateX(10px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@include keyframes(bounceInLeft) {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-2000px);
    }

    60% {
        opacity: 1;
        -webkit-transform: translateX(30px);
    }

    80% {
        -webkit-transform: translateX(-10px);
    }

    100% {
        -webkit-transform: translateX(0);
    }
}
@include keyframes(flash) {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}

@mixin sticky-to-mixin($top: 0) {
    position: -webkit-sticky;
    position: sticky;
    top: $top;
}

// Added for S4A design translation SC-3515

$s4a-navy-blue: #002554;
$s4a-grey: #7d7373;
$s4a-quick-view-bg: #61b2eb;
$s4a-green: #008000;

//Product Line Items
$brand-name-color: $dim-gray;

//Bread Crumb variables
$bread-crumb-font-color: $black;

//Cart
$pid-font-color: $black;

//Product Tile
$special-product-messages-color: $surfie-green !default;

$modalBackdropColor: rgba(0, 0, 0, 0.6);
$cobalt-blue : #0449b9;
$gainsboro-white : #E6E6E6;
$cobalt-blue-hover: #003FA3;
$CA-rewards-modal-footer-devider: #949494;
$fire-engine-red: #ba1616;
$CA-point-circle-green: #0DA508;