.emailSubComfirm-img {
    width: 130px;
    margin: auto;
    padding: 20px;
    @media screen and (max-width: 767px) {
        padding: 55px 0 34px;
    }
}
.emailSubComfirm-heading {
    margin: auto;
    text-align: center;
    font-size: $base-font + 24px;
    letter-spacing: 3px;
    font-weight: bold;
    padding: 20px;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: $base-font + 12px;
        span {
            display: block;
        }
    }
}
.emailSubComfirm-message {
    margin: auto;
    text-align: center;
    font-size: $base-font + 12px;
    padding: 20px;
    letter-spacing: 1px;
    @media screen and (max-width: 767px) {
        font-size: $base-font + 2px;
    }
}
.emailSubComfirm-button {
    margin: 20px auto 70px;
    display: block;
    letter-spacing: 6px;
}
