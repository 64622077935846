.top-menu-utility {
	.menu-utility {
		#mini-cart {
			position: relative;
			padding: 0;
			&:hover {
				.mini-cart-link {
					span {
						&.minicart-quantity {
							border: 2px solid $white;
						}
					}
				}
			}
		}
		.slimScrollRail,
		.slimScrollBar {
			-moz-border-radius: 0 !important;
			border-radius: 0 !important;
			-webkit-border-radius: 0 !important;
		}
		.mini-cart-total {
			position: relative;
			display: inline-block;

			a {
				span {
					&.my-bag {
						padding: 6px 0 0 0;
					}
				}
			}

			&:hover,
			&.DelayHover {
				.mini-cart-content {
					display: block;
				}

				.mini-cart-link:not(.mini-cart-empty) {
					color: $citrus;
					text-decoration: none;
					background: $white;

					span {
						&.cart-bag,
						&.cart-bag-qty {
							width: 21px;
							height: 21px;
							padding: 0;
							background: url("../../../images/bag-icon-black.svg") no-repeat;
						}

						&.my-bag {
							color: $black;
						}

						&.minicart-quantity {
							border: 2px solid $white;
						}
					}
				}
			}

			.mini-cart-link {
				color: $white;
				text-transform: uppercase;
				float: left;
				z-index: 25;

				span {
					&.cart-bag {
						width: 21px;
						height: 21px;
						padding: 0;
						position: relative;
						@media screen and (min-width: 768px) {
							top: 3px;
						}
					}

					&.minicart-quantity {
						color: $white;
					}

					&.cart-bag-qty {
						width: 21px;
						height: 21px;
						padding: 0;
						position: relative;
						@media screen and (min-width: 768px) {
							top: 4px;
						}
					}
				}

				&.mini-cart-empty {
					span {
						&.minicart-quantity {
							color: $black;
						}
					}
				}

				.minicart-quantity {
					border-radius: 12px;
					-webkit-border-radius: 12px;
					-moz-border-radius: 12px;
					color: $white;
					padding: 1px 7px 3px 8px;
					position: absolute;
					right: 91px;
					text-align: center;
					top: 6px;
					@media screen and (max-width: 767px) {
						top: 4px;
					}
					line-height: normal;
					border: 2px solid $black;
				}
			}
		}

		.mini-cart-total {
			.mini-cart-link {
				padding: 7px 21px 12px 25px;
				color: $dim-gray;
				transition: color 500ms ease;

				span {
					&.cart-bag {
						background: url("../../../../images/bag-icon.svg") no-repeat;
					}
					&.cart-bag-qty {
						background: url("../../../../images/bag-icon-white.svg") no-repeat;
					}
				}

				&:hover,
				&.minicart_open,
				&.DelayHover {
					color: $citrus;
					text-decoration: none;
					background: $white;

					span {
						&.cart-bag,
						&.cart-bag-qty {
							width: 21px;
							height: 21px;
							padding: 0;
							background: url("../../../../images/bag-icon-black.svg") no-repeat;
						}

						&.my-bag {
							color: $black;
						}

						&.minicart-quantity {
							border: 2px solid $white;
						}
					}
				}

				&.mini-cart-empty {
					&:hover {
						background: none;
						span {
							&.cart-bag,
							&.cart-bag-qty {
								width: 21px;
								height: 21px;
								padding: 0;
								background: url("../../../../images/bag-icon.svg") no-repeat;
							}

							&.my-bag {
								color: $white;
							}

							&.minicart-quantity {
								border: 2px solid $white;
							}
						}
					}
				}
			}

			.mini-cart-empty {
				color: $dim-gray;
				transition: color 500ms ease;

				&:hover {
					color: $citrus;
					text-decoration: none;
				}

				.minicart-quantity {
					background-color: $white;
					border: 1px solid $dusty-gray;
					color: $dusty-gray;
				}
			}
		}

		.mini-cart-content {
			background-color: $white;
			border: none;
			box-shadow: 0 0 24px 0 rgba($black, 0.25);
			display: none;
			position: absolute;
			right: 0;
			top: 40px;
			width: 370px;
			z-index: 23;
			.slimScrollDiv {
				margin: 0 !important;
			}
			#mini-cart:hover & {
				display: block;
			}
			.minicartslot {
				display: inline-block;
				padding-top: 20px;
			}
			.mini-cart-products {
				.mini-cart-product {
					span,
					a {
						color: $black;
						&.price-standard {
							text-decoration: line-through;
							display: inline;
							span {
								display: inline;
							}
						}
						&.price-standard-exist {
							color: $RedRibbon;
						}
						&.on-order {
							color: #c55300;
						}
					}
					.product-price {
						display: inline-block;
					}
				}
			}
		}

		.mini-cart-header {
			font-size: 1.1em;
			font-weight: 700;
			padding: 1em 1em 0.5em;
			text-transform: uppercase;
		}

		.mini-cart-products {
			padding: 0 42.5px 0 38.5px;
			overflow: hidden;

			&::-webkit-scrollbar-track {
				background: $mercury;
			}

			&::-webkit-scrollbar {
				width: 5px;
				padding-top: 20px;
				background: $mercury;
			}

			&::-webkit-scrollbar-thumb {
				background: $gray;
			}
		}

		.mini-cart-product {
			border-bottom: 1px solid $dark-gray;
			overflow: hidden;
			padding: 10px 0 10px 0;
			position: relative;
			text-align: left;

			span {
				&.value,
				&.label {
					padding: 0 0 10px 0;
				}
			}

			&:first-of-type {
				padding-top: 20px;
			}

			&:last-of-type {
				border-bottom: 1px solid $dark-gray;
			}

			&.collapsed {
				.mini-cart-image {
					display: block;
				}
			}

			.mini-cart-toggle {
				cursor: pointer;
				font-size: 1.5em;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
		.special-productset-child {
			clear: both;
			padding: 10px 0;
			display: inline-block;
			width: 100%;

			&.last {
				padding-bottom: 0;
			}
			.mini-cart-image {
				img {
					max-width: 70%;
					float: right;
				}
			}
		}
		.left-content {
			.mini-cart-image {
				width: 81px;
				height: 146px;
			}
		}
		.mini-cart-image {
			float: left;
			width: 81px;
			span.brand-logo {
				display: inline-block;
				width: 80px;
				height: 17px;
				margin: 5px 0;
				&.jl-logo {
					background-position: 0 0;
				}
				&.ro-logo {
					background-position: 0px -70px;
				}
				&.ww-logo {
					background-position: 0px -33px;
				}
			}
			img {
				max-width: 100%;
			}
		}
		.mini-cart-product-info {
			float: right;
			width: 62%;
		}
		.mini-cart-name {
			font-style: normal;
			font-size: $base-font + 4px;
			padding: 0 0 20px 0;

			&.is-product-brand {
				padding: 0 0 10px 0;
			}
		}

		.mini-cart-brand {
			font-family: $WorkSans;
			font-size: $base-font - 2px;
			text-transform: uppercase;
			padding: 0 0 6px 0;
			&.jessicalondon {
				color: $brand-name-color;
			}
		}

		.mini-cart-attributes,
		.mini-cart-product .product-availability-list {
			font-size: $base-font - 1px;
			font-weight: normal;
		}

		.mini-cart-pricing {
			font-size: $base-font - 1px;
			font-family: $HelveticaBold;
		}

		.giftcard {
			&.from,
			&.to {
				.value {
					word-break: break-word;
				}
			}
		}

		.mini-cart-totals {
			text-align: center;
			padding: 0 40px;
			background-color: $white;
			button,
			.button {
				height: auto;
				line-height: normal;
				overflow: inherit;
				margin-bottom: 25px;
				color: $white;
				border: none;
			}

			.mini-cart-subtotals {
				font-weight: 700;
				margin: 0 auto;
				padding: 15px 0 25px 0;
				span {
					color: $black;
				}
			}
		}

		.mini-cart-subtotals {
			.label {
				margin: 0 0.5rem;
			}
			.value {
				margin-right: 0.5rem;
			}
		}

		.mini-cart-link-checkout {
			clear: both;
			display: block;
			margin: 1em 0;
			text-align: center;
		}

		.mini-cart-slot {
			.minicartslot-content {
				border-top: 1px solid #aaaaaa;
				padding: 15px 0 0 0;
				.minicartslot {
					background: $minicart-slot-bg-color;
					color: $minicart-slot-txt-color;
					padding: 15px 10px;
				}
			}
		}
	}
}

#dialog-container {
	.dialog-hide {
		display: none;
	}
	.bonus-discount-container {
		display: none;
	}
	.mini-cart-totals-addtocartoverlay {
		display: none;
	}
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
	.top-menu-utility {
		.menu-utility {
			.mini-cart-content {
				right: 0;
			}
		}
	}
}

#header {
	&:not(.header_fixed) {
		.fixed-header .top-menu-utility .menu-utility {
			.menu-utility-user .right-section {
				.user-info .user-account {
					height: 75px;
					box-sizing: border-box;
					@media screen and (max-width: 767px) {
						height: auto;
						padding-left: 0;
					}
				}
				#mini-cart {
					height: 75px;
					box-sizing: border-box;
					.mini-cart-total {
						.mini-cart-link {
							height: 75px;
							box-sizing: border-box;
							@media screen and (max-width: 767px) {
								height: auto;
								padding-top: 8px;
							}
							.minicart-quantity {
								right: 50px;
								@media screen and (max-width: 767px) {
									right: 25px;
									top: 2px;
								}
							}
						}
					}
					.mini-cart-content {
						top: 75px;
					}
				}
			}
		}
	}
}
.header_fixed .top-menu-utility .menu-utility .menu-utility-user .right-section .info {
	width: auto;
	border: 0;
}
