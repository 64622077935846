//Return Items
.return-items {
	width: 100%;
	display: inline-block;

	.return-inner-items {
		max-width: 963px;
		margin: 0 auto;
	}

	.item-error {
		width: 100%;
		display: inline-block;
		text-align: right;
	}

	.left-section {
		width: 55%;
		float: left;
	}

	.right-section {
		width: 45%;
		float: right;
	}

	.return-header,
	.return-content {
		width: 100%;
		display: inline-block;
	}

	.return-order {
		display: inline-block;
	}

	.return-message {
		display: inline-block;
	}

	.return-sub-header {
		display: inline-block;
		color: $mangotango;
	}

	button {
		&.submit-cancel,
		&.return-cancel {
			border: 0;
			padding: 0;
			text-transform: capitalize;
			letter-spacing: 1px;
			line-height: 50px;
		}

		&.return-list,
		&.return-submit {
			letter-spacing: 1px;
			color: $white;
			float: right;
			border: 0;
		}
	}

	.return-line-items {
		.heading {
			display: inline-block;
		}
	}

	.list-tems {
		width: 100%;
		display: inline-block;
		border-top: 1px solid $alto;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
	}

	.non-eligible-list {
		.cart-row {
			.column-combine {
				display: none;
			}
		}
	}

	input[type="checkbox"] {
		width: 16px;
		height: 16px;
		border: 1px solid $white;
		outline: 1px solid $black;
	}

	.returned-list {
		.line-item-quantity {
			display: none;

			&.returned {
				display: block;
				float: left;

				.label {
					letter-spacing: 1px;
					text-transform: uppercase;
					display: inline-block;
				}
			}
		}
	}

	.line-item-quantity {
		display: inline-block;
	}

	.cart-row {
		border: 0;

		.cart-columns {
			padding: 0;
		}

		.col-1 {
			.item-image {
				height: auto;
			}

			.item-details {
				.attribute {
					margin: 0;
				}

				.label {
					letter-spacing: 1px;
					text-transform: uppercase;
					display: inline-block;
				}

				.value {
					display: inline-block;
				}

				.sku {
					margin: 0;
				}
			}
		}

		.col-2 {
			.item-total {
				.sub-total {
					display: none;
				}
			}
		}

		.column-combine {
			float: right;

			.current_item {
				.selected-option {
					border-bottom: 0;

					&:before {
						background: url(../../../images/select_arrow.png) no-repeat;
						transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-webkit-transform: rotate(180deg);
						background-color: $white;
						top: 15%;
						right: 19px;
					}
				}
				.selection-list {
					border: 1px solid $dim-gray;
					border-top: 0;
					padding: 0;
					z-index: 2;

					li {
						text-transform: capitalize;
					}
				}
			}

			.selected-option {
				color: $dim-gray;
				text-transform: inherit;
				letter-spacing: 1px;
				border: 1px solid $dim-gray;
				padding: 17px 30px 17px 15px;
				line-height: normal;
				&:before {
					top: 41%;
					right: 12px;
				}
				@media screen and (max-width: 767px) {
					padding: 17px 10px;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.return-items {
		.col-1 {
			.item-details {
				.name {
					line-height: 19px;
				}
			}
		}

		.cart-row {
			.cart-columns {
				.column-combine {
					max-width: 100%;
					display: table;

					.col-3:not(.hide) {
						width: 40%;
						float: none;
						max-width: 100%;
						display: table-cell;
					}

					.col-4:not(.hide) {
						width: 60%;
						float: none;
						margin: 0;
						max-width: 100%;
						display: table-cell;
					}
				}
			}
		}
	}
}
