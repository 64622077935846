#wrapper {
	&.quick-order-page {
		.back-to-top {
			display: none !important;
		}
	}
}

.catalog-quick-order {
	.generic-error,
	.generic-error-required {
		display: none;
		color: $RedRibbon;
		padding-bottom: 10px;
	}
	.product-thumbnails .thumb {
		a {
			outline-offset: -3px;
		}
		img {
			margin: 0 auto;
		}
	}
	@media screen and (min-width: 768px) {
		.pdp-main .product-thumbnails {
			width: 100%;

			button.slick-arrow {
				right: -6px;
				z-index: 2;

				&.slick-next {
					top: 40px;
				}

				&.slick-prev {
					top: 69px;
				}
			}

			&.slick-arrow-bar {
				&::before {
					top: 60px;
				}
			}
		}

		.product-thumbnails .thumb {
			a {
				display: inline-block;
				width: 100%;
			}

			img {
				margin-bottom: 0;
			}
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1023px) {
		.pdp-main .product-thumbnails {
			width: 90%;
			button.slick-arrow {
				right: -16px;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.product-image-container a.main-image {
			display: none;
		}

		.catalog-quick-order__main .pdpForm {
			display: inline-block;
			@include prefix(box-sizing, border-box, $prefixArray);
			width: 100%;
		}

		.product-thumbnails {
			.slick-dots li {
				display: inline-block;
				margin-left: 10px;

				button {
					text-indent: -99999px;
					border: 1px solid $very-light-gray;
					background: $very-light-gray;
					width: 10px;
					border-radius: 50%;
					padding: 0;
					height: 10px;
				}

				&.slick-active {
					button {
						border: 1px solid $black;
						background: $black;
					}
				}
			}
		}
	}

	// To be removed after monogramming is fixed
	.product-options {
		clear: both;
		border: 10px solid blue;
	}

	.error-container {
		text-align: center;
		font-size: 18px;
		margin-bottom: 40px;
	}

	.catalog-quick-order-content {
		display: flex;
		flex-direction: row;

		@media screen and (max-width: 1023px) {
			padding: 0 20px;
		}

		@media screen and (max-width: 767px) {
			padding: 0;
		}
	}

	// SM-1941
	.pdp-main {
		.product-image-container .product-primary-image {
			.b_product_icon {
				width: 33px;
				position: absolute;
				top: 7px;
				right: 7px;
				overflow: hidden;
				z-index: 1;
				cursor: pointer;
			}
			.stickerToolTip {
				width: auto;
				height: auto;
				padding: 10px;
				display: none;
				position: absolute;
				top: 25px;
				right: 31px;
				border: 1px solid #d8d8d8;
				box-shadow: 0 0 4px 2px rgba(216, 216, 216, 0.7);
				background: #ffffff;
				font-size: 11px;
				z-index: 1;
			}
		}
		.heading {
			text-align: left;
		}

		.product-col-2 .tab-content,
		.product-col-2 .shippingdrawer {
			padding-top: 0;

			p {
				margin-top: 0;
			}

			ul li {
				line-height: normal;
				list-style-position: inside;
			}

			&.prod-details {
				height: 17px;
				overflow: hidden;
				display: inline-block;
				line-height: 17px;
				padding: 0;
				margin: 0;
				width: 100%;

				@media screen and (min-width: 640px) and (max-width: 767px) {
					height: 20px;
					line-height: 20px;
				}

				@media screen and (max-width: 639px) {
					height: 19px;
					line-height: 19px;
				}
			}

			&.make-height-auto {
				height: auto;

				@media screen and (min-width: 640px) and (max-width: 767px) {
					line-height: normal;
				}
			}
		}

		.productinfo .moreinfo {
			margin: 0 0 10px 0;
		}
		.clickhere,
		.read-more {
			text-transform: uppercase;
			text-decoration: underline;
			cursor: pointer;
			display: inline-block;
			border: 0;
			background: 0;
			font-size: inherit;
			font-family: inherit;
			padding: 0;
			color: $black;
		}
		.read-more {
			margin-top: 10px;
			&.focus-outline {
				outline-offset: -2px;
			}
		}
		#shippinginfoBtn {
			font-weight: normal;
		}

		.shippingdetails {
			margin: 10px 0 0 0;
			display: inline-block;
			width: 100%;
		}
	}

	.catalog-quick-order__leftNav {
		text-align: left;
		display: none;
		margin-right: 30px;
	}

	.catalog-quick-order__main {
		display: inline-block;
		vertical-align: top;
		width: auto;
		margin: 0 auto 40px;
		padding: 0 20px;
		@include prefix(box-sizing, border-box, $prefixArray);

		@media screen and (max-width: 1023px) {
			padding: 0 10px;
			width: 100%;
		}

		@media screen and (max-width: 768px) {
			display: block;
		}

		.pdpForm {
			position: relative;
			border-bottom: 1px solid $gallery-light;
			margin-bottom: 10px;
			padding: 0 0 25px 0;

			@media screen and (max-width: 1023px) {
				padding: 0 0 33px;
			}

			&:last-child {
				border-bottom: 0;
			}

			&.bottom-add-all {
				padding-bottom: 0;
				padding-top: 30px;
				margin: 0;
				border-top: 1px solid $gallery-light;
			}

			&:after {
				content: "";
				display: table;
				clear: both;
			}

			.input-text {
				font-family: $HelveticaMedium, $sans-serif;
				font-size: $base-font + 2px;
				letter-spacing: 0.04em;
			}

			.form-row {
				.field-wrapper {
					margin-bottom: 15px;

					input[type="text"] {
						padding: 19px 0 13px 17px;
					}
				}

				label {
					width: calc(100% - 34px);

					.second-label,
					.option-text {
						position: absolute;
						right: 0;
					}

					.option-text {
						text-transform: lowercase;
					}
				}

				&.blueboxnumberfield {
					label {
						span {
							&.label-text,
							&.option-text {
								color: $navy-blue;
							}
						}
					}

					input {
						border: 1px solid $navy-blue;
						background-color: $columbia-blue;
					}
				}

				&.error {
					color: inherit;
				}

				span {
					&.invalid {
						color: $RedRibbon;
					}
				}
			}
		}

		.hemmingmonograming {
			.pdpForm {
				margin-bottom: 30px;
				padding: 0;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.search-result {
			padding: 18px 40px 25px;
			border-bottom: 1px solid $gallery-light;

			@media screen and (max-width: 768px) {
				padding: 18px 10px 25px;
			}

			.search-text {
				font-size: 24px;
			}

			.search-explain {
				font-size: 18px;
				color: $dim-gray;
				font-family: mreavessanaltr;
				padding-top: 10px;
			}
		}

		.pdp-main {
			margin-top: 30px;

			.product-primary-image {
				margin-top: 5px;
				.b_product_badge {
					position: absolute;
					width: 100%;
					text-align: left;
					top: 0;
					z-index: 1;
				}
				@media screen and (max-width: 767px) {
					max-width: 79%;
					margin-left: auto;
					min-height: 400px;
					margin-right: auto;

					.slick-dots {
						bottom: 0;
						position: relative;
					}
				}
			}

			.product-price {
				font-size: $base-font + 2;
				margin-top: 0;
				display: inline-block;
			}

			.product-variations {
				.attribute {
					margin: 0;
					padding-bottom: 17px;
				}
			}

			.expired-price {
				display: inline-block;
				color: $mangotango;
				font-size: $base-font + 2px;
				margin-left: 23px;
			}
		}

		.product-name {
			width: auto;
			margin: 0 auto;
			float: none;

			.name-text {
				width: 100%;
				display: inline-block;

				&.is-product-brand {
					padding: 0 0 10px 0;
				}

				@media screen and (max-width: 767px) {
					width: 89%;
				}
			}
		}

		.product-brand {
			padding: 0 0 10px 0;
		}

		.product-add-to-cart {
			overflow: visible;
			margin-top: 20px;
			margin-bottom: 25px;
			width: 100%;
			display: inline-block;
			.add-to-cart {
				@media screen and (max-width: 767px) {
					width: 73%;
					max-width: 280px;
				}
			}
		}

		.add-all-to-cart-quick-order,
		.cqo-btn-search {
			width: 100%;
			border: none;
			font-size: 15px;
			letter-spacing: 0.1em;
			color: $white;
			outline-offset: -3px;
		}

		.btn-remove-item {
			position: absolute;
			top: -20px;
			right: 0;
			width: 18px;
			height: 18px;
			text-indent: 100%;
			white-space: nowrap;
			overflow: hidden;
			background: url("../../images/close-button-circled_29x29.svg") no-repeat;
			border-radius: 0;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			outline-offset: -3px;

			@media screen and (max-width: 767px) {
				top: 5px;
				right: auto;
			}
		}
	}

	.form-container {
		padding: 0 0 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid $gallery-light;
	}

	.make-label-absolute {
		.form-row {
			position: relative;
			display: block;

			&.error {
				span {
					letter-spacing: 0;

					&.invalid {
						color: $RedRibbon;
						text-transform: none;
						font-size: 11px;
						position: relative;
						top: 2px;
						left: -1px;
					}

					&.label-text {
						display: none;
					}
				}

				.input-text {
					padding-top: 13px;
				}
			}

			&.error-handle {
				span {
					&.label-text {
						color: $RedRibbon;
						letter-spacing: 0;
					}
				}
			}
		}

		label {
			font-weight: normal;
			font-size: $base-font;
			padding: 0;
			position: absolute;
			-webkit-transform: translate(5px, 20px);
			-ms-transform: translate(5px, 20px);
			transform: translate(5px, 20px);
			left: 11px;
			margin: 0;
			top: -3px;
			width: auto;
			color: $dim-gray;
			z-index: 1;
			-webkit-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;
			transition: transform 0.3s ease, font-size 0.3s, color 0.3s;
			-ms-transition: -webkit-transform 0.3s ease, font-size 0.3s, color 0.3s;

			span {
				color: $dim-gray;
				font-size: $base-font;

				&.required-indicator {
					display: none;
				}

				&.error {
					text-transform: none;
					color: $RedRibbon;
				}
			}

			&.input-focus {
				-webkit-transform: translate(5px, 11px);
				-ms-transform: translate(5px, 11px);
				transform: translate(5px, 11px);
			}
		}
		.custom-select {
			height: auto;
		}
	}

	@media screen and (min-width: 768px) {
		text-align: center;

		.catalog-quick-order__main {
			text-align: left;
			padding: 0;

			.pdpForm {
				.itemnumber,
				.blueboxnumberfield,
				.search-button {
					display: inline-block;
					vertical-align: top;
					width: 48%;
					margin-bottom: 20px;

					.field-wrapper {
						margin-bottom: 0;
					}
				}

				.itemnumber:nth-child(odd),
				.blueboxnumberfield:nth-child(odd) {
					margin-right: 20px;
				}

				.blueboxnumberfield {
					margin-bottom: 0;
				}
			}

			.product-col-1 {
				max-width: 200px;
				width: 28.2%;
				float: left;
			}

			.product-col-2 {
				max-width: 528px;
				padding: 0 0 0 40px;
				margin: 0 auto;
				width: 71.8%;
			}

			.add-all-to-cart-quick-order {
				width: 48%;
			}

			.add-all-to-cart-quick-order {
				float: right;
				padding: 14.5px 32.25px;
				margin-right: 5px;
				width: auto;
			}
		}
	}

	@media screen and (min-width: 1024px) {
		.catalog-quick-order__leftNav {
			display: inline-block;
			vertical-align: top;
			max-width: 200px;
			width: auto;
			margin-bottom: 40px;
			@include prefix(box-sizing, border-box, $prefixArray);
		}

		.catalog-quick-order__main {
			max-width: 840px;
			margin-bottom: 28px;
			width: 80%;

			.product-col-1 {
				max-width: 220px;
				margin: 0 auto;
				float: left;
				width: 26.2%;
			}

			.product-col-2 {
				max-width: 620px;
				width: 73.8%;
			}

			.cqo-btn-search {
				padding: 14.5px 36px;
				max-width: 405px;
			}

			.pdpForm {
				.itemnumber,
				.blueboxnumberfield,
				.search-button {
					max-width: 405px;
					margin-bottom: 30px;
					width: 48%;
				}

				.itemnumber:nth-child(odd),
				.blueboxnumberfield:nth-child(odd) {
					margin-right: 20px;
				}
			}
		}
	}
}
.cqo-container {
	.cqo-product-details {
		border-top: 1px solid $gainsboro;
		border-bottom: 1px solid $gainsboro;
		.heading {
			padding: 17px 5px 15px 0;
			cursor: pointer;
			display: block;
			margin: 0;
			background: url(../../images/pdp-arrow-closed.png) no-repeat right;
			&.expanded {
				background: url(../../images/pdp-arrow-open.png) no-repeat right;
			}
			&:focus {
				outline: 0;
			}
		}
		.toggle-content {
			p {
				letter-spacing: 0.025em;
			}
		}
	}
}
.pdp-main .cqo-container .shippingdetails {
	margin: 17px 0 15px 0;
}
.pdp-main .cqo-container .product-add-to-cart {
	margin-top: 0;
	border: 0;
}
.pdp-main .cqo-container .productmessage {
	margin: 0;
}
.pdp-main .cqo-container .productmessage span {
	padding: 17px 0 15px 0;
	display: block;
	border-bottom: 1px solid $gainsboro;
	width: 100%;
}
.perks-cqo-promo a{
	line-height: 1.4;
	color: $cobalt-blue;
}
