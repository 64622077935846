.pt_account {
	.make-label-absolute {
		.form-row {
			&.error-handle {
				&.password:not(.show-text) {
					label {
						.label-text {
							color: $RedRibbon;
						}
					}
				}
			}

			&.password {
				&.error-handle {
					.form-caption {
						color: $white;
					}
				}
			}
		}
	}

	.inner-block {
		&.check-order {
			h2 {
				font-size: $base-font + 12px;
				font-family: $ImperialRegular;
			}
		}
	}

	.primary-content {
		width: 100%;
	}
	.col-2 {
		h2 {
			font-size: $base-font + 1px;
			font-family: $Helvetica;
		}
		.form-row {
			.field-wrapper {
				width: 100%;
				padding: 0;
				color: $black;
			}
			label {
				width: auto;
				float: none;
				text-align: left;
				margin: 0;
				color: $dark-gray;
			}
		}
		.label-inline {
			.field-wrapper {
				width: auto;
			}
		}
	}
	.check-order {
		margin-top: 20px;
		p {
			font-size: $base-font + 1px;
			font-family: $Helvetica;
		}
		h2 {
			border-bottom: 1px solid $alto;
			padding: 0 0 10px 0;
		}
		.dialog-required {
			display: none;
		}
		.form-row-button {
			text-align: right;
			button {
				padding: 14.5px 63px;
				width: auto;
			}
		}
		.ordererror {
			border: none;
			background-color: $wild-sand;
			padding: 0 0 10px 0;
			color: $RedRibbon;
		}
	}
	.create-login {
		.inner-block {
			.login-box {
				height: auto;
				&.login-order-track {
					@media screen and (max-width: 1023px) {
						margin: 0 0 0 20px;
					}

					@media screen and (max-width: 767px) {
						margin: 0 0 30px 0;
					}
				}
				.error-form {
					color: $RedRibbon;
					padding: 0 0 5px 0px;
				}

				.password.empty-pass {
					label {
						.label-text {
							color: $RedRibbon;
						}
					}
				}
			}
		}
		.login-create-account {
			.rewards-register-checkbox{
					width:9%; margin:0;
				}
			.rewards-register-copy{
				float:left;
				width:86%;
				.label-text{
					line-height:13px;
					letter-spacing:-0.1px;
					font-size:$base-font - 1px;
					a{
						color:$CA-rewards-register-anchor;
					}
					
				}
			}
			.form-row {
				&.customerID{
					margin:0 0 20px;
				}
				&.addtoemaillist {
					label {
						padding-left: 10px;
						width: 87%;
						display: inline-block;
						-webkit-transform: translate(0, 0);
						-ms-transform: translate(0, 0);
						transform: translate(0, 0);

						span {
							text-transform: inherit;
						}
					}
				}
			}
			.custom-select {
				background: transparent;
			}
			.make-label-absolute {
				label {
					& > span.error[style^="display"] ~ span.label-text {
						display: inline;
					}
					.label-text {
						text-transform: none;
					}
				}
			}
		}
	}
	.check-status-order {
		.check-order {
			margin-top: 8px;
		}

		.login-order-track {
			padding: 21px 22px 28px 44px;
		}

		h2 {
			padding: 0 0 15px 0;
		}

		p {
			line-height: 20px;
			margin: 0 0 16px 0;
		}

		.col-1 {
			.inner-block {
				.form-row {
					width: 35%;
					margin: 0px 20px 20px 0;
				}
			}
		}
	}
	.registration-reward {
		h2 {
			font-size: $base-font + 12px;
			font-family: $ImperialRegular;
			text-transform: capitalize;
		}
		h3 {
			text-transform: uppercase;
			font-family: $HelveticaBold;
			font-size: $base-font + 1px;
			letter-spacing: 1px;
		}
		p {
			font-size: $base-font + 1px;
			font-family: $Helvetica;
			margin: 15px 0 30px 0;
		}
	}

	.make-label-absolute {
		.form-row.birthMonth {
			&.optional {
				.field-wrapper {
					.custom-select {
						.selected-option {
							text-indent: -9999px;
						}
						@media screen and (max-width: 1024px) {
							select {
								text-indent: -9999px;
							}
						}
					}
				}
			}
		}
	}
	.make-label-absolute {
		.form-row {
			&.show-text {
				&.field-valid {
					.field-wrapper {
						.selected-option {
							text-indent: 0;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_account {
		.check-status-order {
			.login-order-track {
				padding: 21px 0 28px 20px;
			}

			.col-1 {
				.inner-block {
					.form-row {
						width: 46.9%;
					}
				}
			}
		}
	}
	.checkoutlogin {
		.col-1 {
			#password-reset {
				width: 100%;
				float: right;
				margin: 0;
				text-align: right;
			}
			.spc-login-btn {
				margin: 30px 0 15px;

				@media screen and (max-width: 767px) {
					margin: 20px 0 15px;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_account {
		.create-login {
			.col-1 {
				.form-row {
					&.login-rememberme {
						margin-bottom: 8px;
					}
				}
			}
			.login-oauth {
				button {
					padding: 14px;
				}
			}
			.create-inner-block {
				.login-create {
					.create-account {
						padding: 14px 49px;
					}
				}
			}
			.login-box-content button.view-more {
				padding: 14px 30px;
			}

			.col-2 {
				.login-box {
					-webkit-box-shadow: -0px 0px 4px 5px $white-smoke;
					-moz-box-shadow: 0px 0px 4px 5px $white-smoke;
					box-shadow: 0px 0px 4px 5px $white-smoke;
					background: $white;
					box-sizing: border-box;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
				}

				.grey-login-box {
					background-color: $light-skygrey;
					box-shadow: none;
					-webkit-box-shadow: none;
					-moz-box-shadow: none;

					.or-section {
						.or-link {
							background-color: $light-skygrey;
						}
					}
				}
			}

			.login-create-account {
				.or-section {
					.or-link {
						background-color: $white;
					}
				}

				.form-row {
					&.show-text {
						select {
							text-indent: 0;
						}
					}
					&.show-text {
						&.field-valid {
							select {
								text-indent: 0;
							}
						}
					}
				}
			}
		}
		.col-2 {
			margin: 0 0 20px;
			width: 100%;

			.inner-block {
				width: 100%;
			}

			h2 {
				width: 100%;
			}
		}
		.check-order {
			width: 100%;
			display: inline-block;
			margin-top: 0;
		}
		.check-status-order {
			.col-1 {
				.inner-block {
					.form-row {
						width: 100%;
						margin: 0 0px 20px 0;
					}
				}
			}
		}
		.benfits-content {
			.registration-reward {
				h2 {
					font-size: $base-font + 12px;
					line-height: 26px;
				}
			}
		}
	}
	.pt_wish-list {
		.create-login {
			.col-2 {
				float: left;
				margin: 0 0 30px 0;

				.create-inner-block {
					float: left;
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}
	.checkoutlogin {
		.col-1 {
			.pass-reset-cont {
				width: 49%;
				margin: 30px 0 3px;
				display: inline-block;
				float: none;
				text-align: right;
			}
		}
	}
}
.create-login {
	.inner-block {
		&.check-order {
			.login-box.login-order-track {
				@media screen and (max-width: 1023px) {
					padding: 34px 20px;
					margin-left: 10px;
				}
				.form-row-button button {
					@media screen and (max-width: 768px) {
						width: 100%;
						margin-top: 10px;
					}
				}
			}
		}
		.login-box {
			
			&.login-create-account, &.login-account {
				@media screen and (max-width: 1023px) {
					padding: 34px 30px;
				}

				@media screen and (max-width: 767px) {
					padding: 34px 10px;
				}
			}
			.form-row-button {
				.spc-login-btn {
					width: 100%;
					outline-offset: -3px;
				}
			}
		}
	}
}

@import "login_error_style";

.top-menu-utility {
	.menu-utility {
		.menu-utility-user {
			.info.user-info {
				.user-panel {
					.login-box {
						@import "login_error_style";
						.form-row {
							&.form-row-button {
								float: none;
								button {
									width: 100%;
								}
							}
							&.login-rememberme {
								&.rememberme {
									display: inline-block;
									width: 50%;
									margin: 20px 0px 10px;
									.input-focus {
										transform: none;
										left: 25px;
										top: 2px;
									}
								}
							}
						}
						.float-right #password-reset {
							margin-top: 20px;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}
.checkoutlogin {
	.col-1 {
		button.spc-login-btn {
			width: 100%;
			max-width: 100%;
		}
	}
}
.spc-login {
	.checkoutlogin {
		.col-1 {
			.make-label-absolute {
				.form-row.login-rememberme {
					margin-top: 22px;
					display: inline-block;
					width: 51%;
					float: none;
					top: -2px;
				}
				button.pwd-show {
					top: -24px;
				}
				button.spc-login-btn {
					width: 100%;
					max-width: 100%;
					outline-offset: -3px;
				}
				.pass-reset-cont {
					margin: 22px 0px 0px 0px;
					width: 47%;
					text-align: right;
					display: inline-block;
					float: none;
				}
			}
		}
	}
}
.login-oauth button .apple-icon{
	padding-top: 3px;
	vertical-align: top;
}
