.zoomLens {
    border: none !important;
}
#wrapper {
    background-color: $white;
    border: 0;
    left: 0;
    margin: auto;
    position: relative;
    transition: left 0.2s ease-in;
    width: 100%;

    @media screen and (min-width: 960px) {
        width: 100%;
    }
    &.pt_storefront,
    &.pt_order-confirmation {
        width: 100%;
    }
    &.pt_categorylanding {
        width: 100%;
        .primary-content {
            padding-bottom: 0;
            @media screen and (min-width: 1024px) {
                width: 80%;
            }
        }
        .refinements {
            border-right: 0;
            display: none;
            width: 0;
            @media screen and (min-width: 1024px) {
                display: block;
                width: 20%;
            }
        }
    }
    &.pt_error {
        @media screen and (min-width: 1024px) {
            #main {
                padding: 0 20px;
            }
        }
    }
}

#main {
    max-width: 1260px;
    margin: auto;
    position: relative;
}
.legacy-sitegen .primary-content {
    width: 100%;

    @media screen and (min-width: 1024px) {
        margin-left: -1px;
        padding: 30px 0 20px;
        position: relative;
        .primary-focus & {
            border: none;
            float: left;
            margin: 0;
            width: 76%;
        }
        .full-width & {
            border: none;
            width: 100%;
        }
    }

    @media screen and(max-width: 1023px) {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
}

@media screen and (min-width: 1024px) {
    .pt_product-details{
        .primary-content{
            float: none;
            width: auto;
        }
    }
}

.product-list-page {
    .primary-content {
        width: 100%;

        @media screen and (min-width: 1024px) {
            margin-left: -1px;
            padding: 0 0 20px;
            position: relative;
            width: 100%;
            float:none;

            .primary-focus & {
                border: none;
                float: left;
                margin: 0;
                width: 76%;
            }

            .full-width & {
                border: none;
                width: 100%;
            }
        }

        @media screen and(max-width: 1023px) {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
        }
    }
}

#secondary {
    @media screen and (min-width: 1024px) {
        .primary-focus & {
            background: none;
            border: none;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            float: left;
            padding-left: 0.4rem;
            width: 24%;
        }
    }
    // Remove Refinements for responsive design
    .pt_content-search-result &,
    .pt_order & {
        display: none;
        @media screen and (min-width: 1024px) {
            display: block;
        }
    }
}
.secondary-content {
    clear: both;
}

section.module-el,
.module-studio,
.div-trending-section {
    overflow: hidden;
}

@media only screen and (min-width: 1024px) {
	.js.pdp-page .zoomWindow {
		top: 503px !important;
	}
}

@media screen and (max-width: 767px) {
    .pt_storefront #main {
        clear: both !important;
    }
}
