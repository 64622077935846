.pt_product-details {
	#main {
		.pdp-main {
			.tabs {
				&.tabs-set-prod {
					.tab-content {
						.tab-content-details {
							max-width: 984px;
							padding-top: 0;
							.review-ps-heading {
								margin-bottom: 20px;
							}
							div.TT3ShowMore,
							#TTtraWindow div.TT3ShowMore {
								margin-bottom: 0;
							}
							@media screen and (min-width: 768px) {
								.product-name {
									line-height: normal;
									margin-top: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.pt_product-details #main {
	.tabs-set-prod {
		#TurnToReviewsContent {
			&.review-collapse {
				margin-bottom: 10px;

				#TTsearchSort {
					display: none !important;
				}

				#TTreviewsWrapper {
					display: none !important;
				}

				.TTreviewSummary {
					margin-bottom: 10px;
				}
			}
			.TTreviewDimsSingleSelectValue {
				color: #4b4b4b;
			}
			.TTrevLinkLine {
				a {
					font-size: $base-font;
				}
			}
		}
		.review-main-section {
			position: relative;

			&.review-expanded {
				.review-collapsed-button {
					a {
						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}

			.product-name {
				padding: 0 10px;
				margin: 0;
				box-sizing: border-box;
				font-size: $base-font + 1px;
				line-height: normal;
				@media screen and (max-width: 767px) {
					padding-left: 10px !important;
					padding-right: 10px !important;
				}
			}

			#TTsearchSort {
				padding-left: 30px;
				box-sizing: border-box;

				@media screen and (max-width: 767px) {
					padding-left: 0;
				}
			}

			.TTpoweredby {
				padding-left: 30px;
				display: none;
				@media screen and (max-width: 767px) {
					padding-left: 10px;
				}
			}

			#TT3RightLinks {
				padding-right: 30px;
				display: none;
				@media screen and (max-width: 767px) {
					padding-right: 0;
					float: left;
					padding-left: 10px;
				}
			}

			.TTreviewsCustomDimsSummary {
				margin: 19px 0;

				@media screen and (max-width: 1023px) {
					margin-left: 0;
					margin-top: 30px;
				}
			}

			.TTreviewsCustomDimsSummaryWrap {
				@media screen and (max-width: 767px) {
					width: 100%;
					display: block;
				}
			}

			.TTreviewDimsRange,
			.TTreviewDimsSingleSelectSummary,
			.TTreviewDimsSingleSelectSummary {
				display: inline-block;
				vertical-align: top;
			}

			.TTreviewDimsSingleSelectSummary,
			.TTreviewDimsSingleSelectSummary {
				margin-left: 60px;

				@media screen and (max-width: 767px) {
					margin-left: 0;
					display: block;
				}
			}

			.review-collapsed-button {
				position: absolute;
				top: 82px;
				right: 20px;
				text-transform: uppercase;
				width: 52px;
				font-size: $base-font - 4px;
				line-height: 11px;
				letter-spacing: 0.2px;
				text-align: right;

				@media screen and (max-width: 767px) {
					bottom: 0;
					top: auto;
					left: 45%;
					right: auto;
					text-align: initial;
				}

				a {
					color: #4b4b4b;

					&:after {
						content: "";
						background: url("../../../../images/carat-down.svg") center no-repeat;
						width: 30px;
						height: 30px;
						display: inline-block;
						transition: transform 1s, color 0.2s ease-in-out;
					}

					@media screen and (max-width: 767px) {
						span {
							display: none;
						}
					}
				}
			}
		}
	}
}

.pt_product-details #main {
	.pdp-main {
		&.standardproductset {
			.tabs-set-prod {
				.tab-content {
					.tab-content-details {
						#TurnToReviewsContent {
							#TTwriteReviewBtn,
							#TTwriteReviewBtn-portrait,
							.TTwriteReview a {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

#TTwriteReviewScreen {
	.TTrespDesktopDisp {
		display: block !important;
	}
}

.TTreview {
	.TTmediaBlock {
		.TTvc-icon-md-video {
			z-index: 1;
		}
	}
}

.TTteaser {
	clear: both;
	max-height: 1.3125rem;
	&:empty {
		height: 1.3125rem;
	}
	.TTteaser__rating {        
	    transform: translateY(-4px);
    }
}

//override for reviews buttons on SPS
.legacy-sitegen .tt-c-reviews-list__content .tt-o-button, .legacy-sitegen .tt-c-related-reviews-list__content .tt-o-button {
	background: transparent;
	border: 0;
	padding: 0;
}

//override for pagination on SPS
.legacy-sitegen .tt-o-page-list__link {
	color: $black;
}

//override for Order confirmation buttons
.pt_order-confirmation .tt-o-button.tt-o-button--icon {
    background: none;
    border: none;
}

.tt-c-multi-item-search .tt-o-field-group {
	margin-bottom: 0.5rem;
}