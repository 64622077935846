.marketplace-landing {
    .marketplace-row {
        display: flex;
        justify-content: center;
        flex-flow: column;
        @media screen and (max-width: 767px) {
          padding: 0 10px;
        }
        h5,
        p {
            text-align: center;
            @media screen and (max-width: 767px) {
              margin: 20px auto;
            } 
        }
        .mb-20 {
            margin-bottom: 20px;
        }
        .font-14 {
            font-size: 14px;
        }
        ul {
            padding: 0;
            margin: 10px auto;
            li {
                display: inline-block;
            }
        }
        button {
            border-radius: 5px;
            margin-top: 20px;
        }
        .mp-banner-block {
          &.left{
            float: left;
            width:40%;
            @media screen and (max-width: 767px) {
              float: none;
              width:100%;
            }
          }
          &.right{
            float: right;
            width:40%;
            @media screen and (max-width: 767px) {
              float: none;
              width:100%;
            }
          }
          h5,
          p {
              text-align: left; 
          }
        }
        .mp-selling-ul {
          max-width: 767px;
            .mp-selling-li {
                border: 1px solid $mortar;
                margin: 10px;
                padding: 20px;
                text-align: center;
                width: 24%;
                @media screen and (max-width: 767px) {
                  width: 82%;
                  display: block;
                }
                .selling-img {
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    margin: 0 auto;
                    background: $slightblack;
                    img {
                        width: 100%;
                    }
                }
                h6 {
                    margin: 10px 0;
                    font-size: $base-font + 4;
                }
                p {
                    margin-bottom: 10px;
                    font-weight: 300;
                    &.bold {
                        margin-bottom: 20px;
                        font-weight: 500;
                    }
                }
            }
        }
        .mp-brand-ul {
            li {
                width: 20%;
                padding: 10px 15px;                
                @media screen and (max-width: 767px) {
                  width: 100%;
                  padding: 10px;
                  box-sizing: border-box;
                }
            }
            .marketplace-img-block {
                height: 100px;
                width: 150px;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
        }
        &.marketPlaceForm {
          padding: 10px 40px;
          @media screen and (max-width: 767px) {
            padding: 10px 20px;
          }
            p {
                width: 70%;
                margin: 0 auto 40px auto;
                @media screen and (max-width: 767px) {
                  width: 100%;
                }
            }
            .formCol {
                width: 48%;
                @media screen and (max-width: 767px) {
                  width: 100%;
                }
                &.left {
                  float: left;
                  @media screen and (max-width: 767px) {
                    float: none;
                  }
                }
                &.right {
                  float: right;
                  @media screen and (max-width: 767px) {
                    float: none;
                  }
                }
                .form-row{
                  @media screen and (min-width: 768px) {
                    height:115px;
                  } 
                }
                .field-wrapper {
                    margin-bottom: 20px;
                    padding-left: 0;
                    width: 100%; 
                    @media screen and (max-width: 767px) {
                      margin-bottom: 10px;
                    }
                }
                label {
                    margin-bottom: 10px;
                    text-align: left;
                    width: 100%;
                    .label-text {
                      @media screen and (max-width: 1024px) {
                        font-size: $base-font + 1;
                      } 
                      @media screen and (max-width: 375px) {
                        font-size: $base-font;
                      }                   
                    }
                                       
                }
                .radio-input-block {
                    margin-bottom: 20px;
                    margin-left: 15px;
                    @media screen and (max-width: 767px) {
                      display: inline-block;
                      min-width: 48%;
                      margin-left: 0;
                    }
                }
                .vendor_main_category,
                .vendor_type {
                  span.error {
                    margin-left: 15px;
                  }
                }
                .w46 {
                    width: 48%;
                    @media screen and (max-width: 1024px) {
                      width: 49%;
                    }
                }
            }
        }
    }
}
.mp-confirmation-msg {
  text-align:center;
  font-weight: bold;
  padding:50px 30px;
  margin: 40px auto;
  width: 35%;
  border: 1px solid $mortar;
  background: $alto;
  @media screen and (max-width: 1024px) {
    width: 60%;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
  h5 {
    font-size: $base-font + 18;
    margin: 0 0 20px 0;
    line-height: 1.5;
  }
  a {
    font-size: $base-font + 4;
    text-decoration: underline;
  }
}
